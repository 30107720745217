import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CloseButton,
  SaveButton,
} from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import useConfirm from '../../../../components/common/useConfirm';
import ModuleBody from './components/create/ModuleBody';
const Create = () => {
  const { categoryId } = useParams();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [moduleData, setModuleData] = useState<any>({
    topics: [],
  });
  const [useEffectRender, setUseEffectRender] = useState(false);
  const [DialogClose, confirmClose] = useConfirm(
    'CLOSE',
    'Are you sure you want to leave this page?',
  );

  const [DialogUpdate, confirmUpdate] = useConfirm(
    'SAVE',
    'Are you sure you want to save?',
  );
  useEffect(() => {
    if (categoryId) {
      axios
        .get(`/api/skillEvaluationTopicRoute/getOneTopicPaper/${categoryId}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [categoryId, useEffectRender]);

  const onSubmit = async () => {
    let ans = await confirmUpdate();
    if (!ans) return;
    if (moduleData._id) {
      axios
        .put(
          `/api/skillEvaluationTopicRoute/updateTopicPaper/${moduleData._id}`,
          moduleData,
        )
        .then((res) => {
          if (res.data) {
            alert('document updated successfully');
          }
        })
        .catch((err) => console.log(err));
      return;
    }
    moduleData.category_id = categoryId;
    axios
      .post(`/api/skillEvaluationTopicRoute/createTopicPaper`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('document created successfully');
          setUseEffectRender((prev) => !prev);
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box>
      <Box>
        <ChildHeader text="ADD EDIT TOPIC">
          {!isView && (
            <>
              <SaveButton label="Save & Continue" onClick={() => onSubmit()} />
              <CloseButton
                onClick={async () => {
                  let ans = await confirmClose();
                  if (!ans) return;
                  navigate(-1);
                }}
                label="Close"
              />
            </>
          )}
        </ChildHeader>
        <ModuleBody {...{ moduleData, setModuleData }} />
      </Box>
      <DialogClose isSubmitContent={false} />
      <DialogUpdate isSubmitContent={false} />
    </Box>
  );
};

export default Create;
