import axios from 'axios';
import lodash from 'lodash';
import { useState, useEffect } from 'react';

const useGetData = (pdirId: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    created_date: new Date(),
    list: Array.from({ length: 5 }, (_) => ({ ...lodash.cloneDeep({}) })),
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [machineData, setMachineData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    if (pdirId) {
      axios
        .get(`/api/airborneRoute/${pdirId}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, []);
  return { moduleData, isLoading, error, machineData, setModuleData };
};

export default useGetData;
