import axios from 'axios';
import { useParams } from 'react-router-dom';

import CustomSelect from '../../../../components/common/SelectField';
import { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  TableBody,
  TableCell,
  TableRow,
} from '@mui/material';
import { log } from 'console';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import { Add } from '@mui/icons-material';
import { link } from 'fs';
import React from 'react';
import { CustomIconButton } from '../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../components/common/formatDate';
import { IPart } from '../../../addparts/AddEditPart';
import { TableCustom } from '../../../../components/common';
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Item Number', rowspan: 1, colspan: 1 },
    { text: 'Item Name/Description', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];
const BodyContent = () => {
  const { partId } = useParams();
  const [process, setProcess] = useState([]);
  const [autoCompleteProcess, setAutoCompleteProcess] = useState<any>('');
  const [specs, setSpecs] = useState<any>(null);

  console.log(partId);
  useEffect(() => {
    axios
      .get(`/api/pcdRoute/${partId}`)
      .then((res) => {
        setProcess(res.data.processes);
      })
      .catch((err) => console.log(err));
  }, []);
  console.log(specs);
  return (
    <div>
      <Box sx={{ flexGrow: 1, width: '98%', margin: '2rem auto' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <AutocompleteMuiCustom
              id={'process_name'}
              label="OPERATION"
              option_name={'process_name'}
              value={autoCompleteProcess}
              arrayofObj={process}
              onChange={(e, value) => {
                setAutoCompleteProcess(value);
                setSpecs(value?.specs || '');
              }}
            />
          </Grid>
        </Grid>
      </Box>
      {specs && (
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {specs.map((item: any, index: number) => (
              <React.Fragment key={item?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{item.parameter}</TableCell>
                  <TableCell align="center">{item.control_product}</TableCell>
                  <TableCell align="center">{item.methods_measure}</TableCell>
                  <TableCell align="center">{item.methods_by}</TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </TableCustom>
      )}
    </div>
  );
};

export { BodyContent };
