import React, { useState } from 'react';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';

interface IProps {
  moduleData: any;
  isView: boolean;
  process: any;
  index: number;
}
const TBodyRow = ({ isView, process, index }: IProps) => {
  const [render, setRender] = useState(false);
  return process.lists.map((item: any, itemIndex: any) => (
    <React.Fragment key={index + 10.1 + 'heading '}>
      <tr>
        <th style={{ minWidth: '50px' }} rowSpan={2}>
          {index + 1}
        </th>
        <td rowSpan={2}>{process.contains}</td>
        <td rowSpan={2}>{item.what}</td>
        <td rowSpan={2}>{item.where}</td>
        <td rowSpan={2}>{item.how}</td>
        <td rowSpan={2}>{item.who}</td>
        <td rowSpan={2}>{item.when}</td>
        <td rowSpan={2}>{item.checked_by}</td>
        {Array.from({ length: 31 }, (_, indx) => (
          <td
            style={{
              minWidth: '100px',
              textAlign: 'center',
              fontSize: '2rem',
            }}
            key={index + 2.2 + indx + 'after' + 111.33}
          >
            {isView ? (
              (item.check_1[indx] === 'true' && '✓') ||
              (item.check_1[indx] === 'false' && '⨯')
            ) : (
              <CustomSelect
                name="results[indx]"
                value={item.check_1[indx] || ''}
                onChange={(e) => {
                  item.check_1[indx] = e.target.value;
                  setRender((prev) => !prev);
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="true">✓</MenuItem>
                <MenuItem value="false">⨯</MenuItem>
              </CustomSelect>
            )}
          </td>
        ))}
      </tr>
      <tr>
        {Array.from({ length: 31 }, (_, indx) => (
          <td
            style={{
              minWidth: '100px',
              textAlign: 'center',
              fontSize: '2rem',
            }}
            key={index + 2.2 + indx + 'after' + 111.33}
          >
            {isView ? (
              (item.check_2[indx] === 'true' && '✓') ||
              (item.check_2[indx] === 'false' && '⨯')
            ) : (
              <CustomSelect
                name="results[indx]"
                value={item.check_2[indx] || ''}
                onChange={(e) => {
                  item.check_2[indx] = e.target.value;
                  setRender((prev) => !prev);
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="true">✓</MenuItem>
                <MenuItem value="false">⨯</MenuItem>
              </CustomSelect>
            )}
          </td>
        ))}
      </tr>
    </React.Fragment>
  ));
};

export default TBodyRow;
