import { toNumber } from 'lodash';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import { moduleConstant } from '../constant';
import { monthCalendarClasses } from '@mui/x-date-pickers';
import { GetMonthYear } from '../helpers/utils';
import React, { useState } from 'react';
import { getTotalColsSpan } from '../../../../utils/helpers/utils';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

interface IProps {
  isView: boolean;
}

const THead = ({ isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  const month = date && new Date(date).getMonth();
  const [monthYearData, setMonthYearData] = useState<{ month_year: string }[]>(
    []
  );
  let data = GetMonthYear(date);
  const tableHeaderObj = [
    [
      { text: 'SL No.', style: style1 },
      { text: 'DATE', style: style1 },
      { text: 'PART NO', style: style1 },
      { text: 'ASSY PART', style: style1 },
      { text: 'SUB ASSY PARTt', style: style1 },
      { text: 'BEND TUBE', style: style1 },
      { text: 'CHILD PART', style: style1 },
      { text: 'FIXTURE', style: style1 },
      {
        text: 'DATA PROVIDED (3D/2D)',
        style: style1,
      },
      { text: 'CUSTOMER', style: style1 },
      { text: 'OFFERED BY', style: style1 },
      { text: 'PLANT', style: style1 },
      { text: 'STATUS	', style: style1 },
      { text: 'REMARKS', style: style1 },
      { text: 'ACTION', style: style1 },
    ],
  ];

  return (
    <thead>
      <TableCompHead
        colSpan={getTotalColsSpan(tableHeaderObj)}
        rowSpan={3}
        labels={['F.NO.:', 'REV. NO.', 'Date']}
        values={['HO-F-QAS-69', '01', '14.08.2012']}
        heading={`${moduleConstant?.heading}`}
      />
      <th align="left" colSpan={12 + toNumber(!isView && 1)}>
        {data[toNumber(month)].month_year}
      </th>
      <TableHeaderHtmlCustom sticky={true} tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
