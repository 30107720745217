import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import CustomSelect from '../../../../components/common/SelectField';
import { Chip, Grid, MenuItem } from '@mui/material';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  item: any;
  isView: any;
  handleDelete: any;
  index: number;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  defects: any;
  setIsOpenPopupRejections: any;
  setRejectionsIndx: any;
}
// const getTotalProblematicQty = (defects: any, item: any) => {
//   const totalRejQty = defects.reduce((sum: any, defect: any) => {
//     if (item[defect._id]) {
//       return sum + +item[defect._id];
//     }
//     return sum;
//   }, 0);
//   item.rej_qty = totalRejQty;
//   return totalRejQty;
// };
const getRejectionPercentage = (item: any) => {
  if (
    item?.rej_qty_rejection === undefined ||
    item?.rej_qty_rejection === null ||
    item.checked_qty === undefined ||
    item.checked_qty === null
  ) {
    return;
  }
  const percent =
    ((item.rej_qty_rejection || 0) / (item.checked_qty || 1)) * 100;

  item.rejection_percentage = percent.toFixed(2);
  return percent.toFixed(2);
};

const TBodyRow = ({
  item,
  index,
  isView,
  handleDelete,
  setPartIdx,
  setIsOpenPopup,
  defects,
  setIsOpenPopupRejections,
  setRejectionsIndx,
}: IProps) => {
  const [rowRender, setRowRender] = useState(false);

  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
    setRowRender((prev: any) => !prev);
  };

  return (
    <>
      <tr>
        <td style={{ textAlign: 'center', minWidth: '200px' }}>
          <DatePickerMui
            label=""
            onChange={(date) => {
              item.date = moment(date || null);
              setRowRender((prev: any) => !prev);
            }}
            value={(item?.date && moment(item?.date)) || null}
          />
        </td>
        <td style={{ width: '250px', minWidth: '250px' }}>
          {isView ? (
            item?.part_id?.part_number
          ) : (
            <Grid container xs={12} direction="row" alignItems={'center'}>
              <Grid item xs={10}>
                <p
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  {item?.part_id !== null && item.part_id?.part_number}
                  {item?.part_id === null && 'Pick Part No.'}
                </p>
              </Grid>

              {item.part_id ? (
                <Grid item xs={2}>
                  <RemoveIconButton
                    tooltipTitle="remove part"
                    onClick={() => {
                      item.part_id = null;
                      setRowRender((prev: any) => !prev);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <AddIconButton
                    tooltipTitle="add part"
                    onClick={() => {
                      setIsOpenPopup(true);
                      setPartIdx(index);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </td>
        <td style={{ width: '200px', minWidth: '200px' }}>
          {item?.part_id?.customer_id?.customer_name}
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="number"
            name="checked_qty"
            value={item?.checked_qty ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="number"
            name="ok_qty"
            value={item?.ok_qty ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>

        <td style={{ textAlign: 'center', width: '150px' }}>
          {/* {getTotalProblematicQty(defects, item)} */}
          {item?.rej_qty_rejection}
        </td>
        <td style={{ textAlign: 'center', width: '150px' }}>
          {getRejectionPercentage(item)}
        </td>
        {/* <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="action_plan"
            value={item?.action_plan ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td> */}
        {/* <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="clean_room"
            value={item?.clean_room ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td> */}
        {/* {defects.map((defect: any) => (
          <td
            style={{ textAlign: 'center', minWidth: '150px', width: '100px' }}
          >
            <TableInput
              isView={isView}
              type="number"
              name={defect._id}
              value={item[defect._id] || ''}
              onChange={(e) => {
                handleChange(e);
              }}
            />
          </td>
        ))} */}
        <td
          colSpan={2}
          style={{
            width: '300px',
            minWidth: '300px',
          }}
        >
          <div
            style={{
              ...(!isView && { display: 'flex' }),
              ...(!isView && { justifyContent: 'space-between' }),
            }}
          >
            <div style={{ border: 'none' }}>
              {item?.rejection_details?.map((itm: any) => {
                return itm?.reason ? (
                  <Chip
                    label={`${itm?.reason} - ${itm?.stage} - ${itm?.rej_qty} -${itm?.action_plan}`}
                    style={{ margin: 2 }}
                  />
                ) : null;
              })}
            </div>
            {!isView && (
              <AddIconButton
                tooltipTitle="add reasons and qty"
                onClick={() => {
                  setIsOpenPopupRejections(true);
                  setRejectionsIndx(index);
                }}
              />
            )}
          </div>
        </td>

        {!isView && (
          <td style={{ textAlign: 'center' }}>
            <DeleteIconButton
              tooltipTitle="DELETE"
              onClick={() => {
                handleDelete(item?._id, index);
              }}
            />
          </td>
        )}
      </tr>
    </>
  );
};

export default TBodyRow;
