import { useState } from 'react';
import { TableInput } from '../../../../components/common';

import CustomSelect from '../../../../components/common/SelectField';
import { IconButton, MenuItem, Tooltip } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { useQuery } from '../../../../hooks/UseQuery';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import moment from 'moment';
import { convToTime } from '../../../../utils/helpers';
import { TimePicker } from '@mui/x-date-pickers';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';

interface IProps {
  moduleData: any;
  handelDeleteRows: any;
  tabValue: number;
  isView: boolean;
}
const TBody = ({ moduleData, tabValue, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <tr key={`${index}_rows`}>
          <td>
            {isView ? (
              formatDate(item?.date)
            ) : (
              <DatePickerMui
                label=""
                value={moment(item?.date || null)}
                onChange={(date) => {
                  item.date = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </td>
          <td>
            {isView ? (
              convToTime(item?.time)
            ) : (
              <TimePicker
                value={moment(item?.time || '')}
                onChange={(date) => {
                  item.time = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </td>
          <td>
            <TableInput
              isView={isView}
              name="before_tier_wt"
              type="text"
              value={item.before_tier_wt}
              onChange={(e) => {
                item.before_tier_wt = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              name="after_gross_wt"
              type="text"
              value={item.after_gross_wt}
              onChange={(e) => {
                item.after_gross_wt = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              name="after_gross_wt_2"
              type="text"
              value={item.after_gross_wt_2}
              onChange={(e) => {
                item.after_gross_wt_2 = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              name="b_a_result_mg"
              type="text"
              value={item.b_a_result_mg}
              onChange={(e) => {
                item.b_a_result_mg = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              name="b_a_result"
              type="text"
              value={item.b_a_result}
              onChange={(e) => {
                item.b_a_result = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={isView ? 2 : 1}>
            <TableInput
              isView={isView}
              name="remarks"
              type="text"
              value={item.remarks}
              onChange={(e) => {
                item.remarks = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          {!isView && (
            <td style={{ width: '5px', textAlign: 'center' }}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handelDeleteRows(index)}
              />
            </td>
          )}
        </tr>
      ))}
    </tbody>
  );
};

export default TBody;
