import React, { useState } from 'react';
import SelectCheckUnchek from './SelectCheckUnchek';
import SelectResponsibleDeparment from './SelectResponsibleDepartment';

interface IProps {
  moduleData: { [key: string]: any };
  isView: boolean;
  title?: string;
  phase: any;
}
const TBody = ({ title, phase, moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const handleChange = (e: any, phase: any, index: number) => {
    const { value, name } = e.target;
    moduleData[phase][index][name] = value;
    setRender((prev) => !prev);
  };
  return (
    <tbody>
      <tr style={{ height: '45px' }}>
        <th colSpan={16} style={{ textTransform: 'uppercase' }}>
          {title}
        </th>
      </tr>
      {moduleData[phase].map((item: any, index: number) => (
        <React.Fragment>
          <tr>
            <td>{item.s_no}</td>
            <td colSpan={2}>{item.activity}</td>
            {[
              'development_a',
              'development_b',
              'development_c',
              'development_d',
            ].map((name) => (
              <td>
                <SelectCheckUnchek
                  name={name}
                  {...{
                    moduleData,
                    handleChange,
                    phase,
                    index,
                    isView,
                  }}
                />
              </td>
            ))}

            {['npd_head', 'qa', 'cft', 'pr', 'mn', 'mkt', 'pur', 'hr_head'].map(
              (name) => (
                <td>
                  <SelectResponsibleDeparment
                    name={name}
                    {...{
                      moduleData,
                      handleChange,
                      phase,
                      index,
                      isView,
                    }}
                  />
                </td>
              ),
            )}
          </tr>
        </React.Fragment>
      ))}
    </tbody>
  );
};

export default TBody;
