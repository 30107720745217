import Masterlistofmachine from '../../pages/masterlistofMachine/List';
import MasterlistOfCustomer from '../../pages/clause5/department/List';
import { Clause10 } from '../../pages/clause10Page';
import CreateMachineHistory from '../../pages/masterlistofMachine/machineHistory/CreateMachineHistory';

// main route of clause 10
export const clause10 = [
  {
    path: 'clause10',
    element: <Clause10 />,
  },
  {
    path: 'masterlistofmachine',
    element: <Masterlistofmachine />,
  },
  {
    path: 'machineHistory/:id',
    element: <CreateMachineHistory />,
  },
  {
    path: 'masterlistofdepartment',
    element: <MasterlistOfCustomer />,
  },
];
