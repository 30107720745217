import { useState } from 'react';
import { Grid } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
}

const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <TBodyRow
              {...{
                process,
                index,
                isView,
                handelDeleteRows,
              }}
            />
          ))}
      </tbody>
    </>
  );
};

export default TBody;
