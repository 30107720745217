import TController from './TController';

interface IProps {
  Data: any;
}

const OverviewReport = ({ Data }: IProps) => {
  return Data.length ? <TController Data={Data} /> : <></>;
};

export default OverviewReport;
