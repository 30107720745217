import { Stack } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import {
  DeleteIconButton,
  UploadImageIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import InteractiveIcon from '../../../../../components/common/InteractiveIcon';
import MonthDoubleClick from './MonthDoubleClick';

interface IProps {
  moduleData: { [key: string]: any };
  isView: boolean;
  title?: string;
  phase: any;
  uploadImageFile: any;
  handleRemarks: any;
}
const TBody1 = ({
  handleRemarks,
  title,
  phase,
  moduleData,
  isView,
  uploadImageFile,
}: IProps) => {
  const [render, setRender] = useState(false);
  const handleDoubleClick = async (
    event: any,
    rowIdx: number,
    bool: boolean,
  ) => {
    if (event.detail === 2) {
      moduleData[phase][rowIdx].isRequired = bool;
      setRender((prev) => !prev);
    }
  };

  return (
    <tbody>
      {moduleData[phase].map((item: any, index: number) => (
        <React.Fragment key={index}>
          <tr>
            <td rowSpan={2} style={{ minWidth: '80px' }}>
              {index + 1}
            </td>
            {index === 0 && (
              <td
                style={{ minWidth: '120px' }}
                rowSpan={moduleData[phase].length * 2}
              >
                {title}
              </td>
            )}
            <td rowSpan={2} style={{ minWidth: '300px' }}>
              {item.activity}
            </td>
            <td
              style={{
                padding: '0px',
                margin: '0px',
              }}
              rowSpan={2}
              onClick={(e) => handleDoubleClick(e, index, true)}
            >
              <InteractiveIcon isYellow={item.isRequired === true}>
                {item.isRequired === true && '✓'}{' '}
              </InteractiveIcon>
            </td>
            <td
              style={{
                padding: '0px',
                margin: '0px',
              }}
              rowSpan={2}
              onClick={(e) => handleDoubleClick(e, index, false)}
            >
              <InteractiveIcon isYellow={item.isRequired === false}>
                {item.isRequired === false && 'x'}{' '}
              </InteractiveIcon>
            </td>
            <td rowSpan={2} style={{ minWidth: '150px' }}>
              {item.resp}
            </td>
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="P"
                month="month_1"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="P"
                month="month_2"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="P"
                month="month_3"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="P"
                month="month_4"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            <td
              style={{
                backgroundColor: 'yellow',
                textAlign: 'center',
                minWidth: '50px',
              }}
            >
              P
            </td>
            <td style={{ minWidth: '100px' }}>
              {isView ? (
                formatDate(item.start_date?.P)
              ) : (
                <DatePickerMui
                  sx={{ width: '150px' }}
                  label=""
                  value={moment(item.start_date?.P || null)}
                  onChange={(date) => {
                    if (!item.start_date) item.start_date = {};
                    item.start_date.P = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ minWidth: '100px' }}>
              {isView ? (
                formatDate(item.complete_date?.P)
              ) : (
                <DatePickerMui
                  label=""
                  value={moment(item.complete_date?.P || null)}
                  sx={{ width: '150px' }}
                  onChange={(date) => {
                    if (!item.complete_date) item.complete_date = {};
                    item.complete_date.P = date;
                    handleRemarks(
                      item?.complete_date?.P,
                      item?.complete_date?.A,
                      item.activity,
                      index,
                    );
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td
              rowSpan={2}
              style={{
                minWidth: '120px',
                background:
                  item.remarks === 'DELAYED'
                    ? 'red'
                    : item.remarks === 'ON TRACK'
                      ? 'green'
                      : 'white',
                color: 'white',
              }}
            >
              {item.remarks}
            </td>
            <td rowSpan={2} style={{ minWidth: '160px' }}>
              {moduleData._id ? (
                <Stack
                  direction="row"
                  sx={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  <UploadImageIconButton
                    id={`${phase}-${index}-process-upload`}
                    tooltipTitle="upload"
                    sx={{}}
                    onChange={(e) => {
                      uploadImageFile(e, phase, index);
                    }}
                  />
                  {item.files?.length > 0 && (
                    <>
                      <ViewIconButton
                        tooltipTitle="view image"
                        onClick={() => {
                          window.open(
                            item.files[0].url,
                            '_blank',
                            'noreferrer',
                          );
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle={'delete'}
                        onClick={() => {
                          setRender((prev) => !prev);
                          item.files = [];
                        }}
                      />
                    </>
                  )}
                </Stack>
              ) : (
                <span>Submit only once to enable this field.</span>
              )}
            </td>
          </tr>

          <tr>
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="A"
                month="month_1"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="A"
                month="month_2"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="A"
                month="month_3"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            {Array.from({ length: 5 }).map((_, monthIdx) => (
              <MonthDoubleClick
                planType="A"
                month="month_4"
                {...{ moduleData, monthIdx, phase }}
                rowIdx={index}
              />
            ))}
            <td
              style={{
                backgroundColor: '#2f9e44',
                textAlign: 'center',
                minWidth: '50px',
              }}
            >
              A
            </td>

            <td style={{ minWidth: '100px' }}>
              {isView ? (
                formatDate(item.start_date?.A)
              ) : (
                <DatePickerMui
                  label=""
                  sx={{ width: '150px' }}
                  value={moment(item.start_date?.A || null)}
                  onChange={(date) => {
                    if (!item.start_date) item.start_date = {};
                    item.start_date.A = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ minWidth: '100px' }}>
              {isView ? (
                formatDate(item.complete_date?.P)
              ) : (
                <DatePickerMui
                  label=""
                  value={moment(item.complete_date?.A || null)}
                  sx={{ width: '150px' }}
                  onChange={(date) => {
                    if (!item.complete_date) item.complete_date = {};
                    item.complete_date.A = date;
                    handleRemarks(
                      item?.complete_date?.P,
                      item?.complete_date?.A,
                      item.activity,
                      index,
                    );
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
          </tr>
        </React.Fragment>
      ))}
    </tbody>
  );
};

export default TBody1;
