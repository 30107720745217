import moment from 'moment';
import { useState } from 'react';

import { TableCompHead } from '../../../../../../../components/ui';
import formatDate from '../../../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../../../components/common/DatePickerMui';
import { TableInput } from '../../../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
  area: any;
  category: any;
  customer: any;
  frequency: any;
}

const headings = [
  'S.No.',
  'Check Points ',
  'Observation',
  'OK / Not OK',
  'Remarks',
];
const THead = ({
  moduleData,
  isView,
  area,
  category,
  customer,
  frequency,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={5}
        rowSpan={3}
        labels={['FORMAT NO', 'REV. NO.', 'REVISION DATE']}
        values={
          area === 'Layer - I'
            ? ['FM/XLV/QA/0101a', '00', '20/10/2022']
            : ['FM/XLV/QA/0101b', '00', '20/10/2022']
        }
        heading={
          area === 'Layer - I'
            ? 'Layer-I Process Audit Check List'
            : 'Layer-II Process Audit Check List'
        }
      />
      <tr>
        <th colSpan={2} align="left">
          Plant: P45
        </th>
        <th colSpan={2} align="left">
          Area/Line No.:{' '}
          <TableInput
            isView={isView}
            name="area"
            type="text"
            value={moduleData?.area}
            onChange={(e: any) => {
              moduleData.area = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left">
          Date:
          {isView ? (
            formatDate(moduleData.d1)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d1)}
              onChange={(date: any) => {
                moduleData.d1 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Part No.:{' '}
          <TableInput
            isView={isView}
            name="part_no"
            type="text"
            value={moduleData?.part_no}
            onChange={(e: any) => {
              moduleData.part_no = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          Part Name:{' '}
          <TableInput
            isView={isView}
            name="part_name"
            type="text"
            value={moduleData?.part_name}
            onChange={(e: any) => {
              moduleData.part_name = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left">
          Customer:{' '}
          <TableInput
            isView={isView}
            name="customer"
            type="text"
            value={moduleData?.customer}
            onChange={(e: any) => {
              moduleData.customer = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        {headings.map((heading) => {
          return <th>{heading}</th>;
        })}
      </tr>
    </thead>
  );
};

export default THead;
