import axios from 'axios';
import { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { CustomButton } from '../../../../components/common/button/CustomButtons';
import {
  DeleteIconButton,
  EditIconButton,
} from '../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../components/common/formatDate';
import { MdAssignmentTurnedIn } from 'react-icons/md';

interface IData {
  _id?: string;
  store_part_id?: any;
  date_of_receiving?: Date;
  qty: number;
  balance_qty: string;
  isCompleted?: boolean;
  ri_store_location?: string;
  mrn_no?: string;
}
interface IProps {
  index: number;
  document: IData;
  deleteDocument: (id: string) => void;
  assignRegister: (id: string) => void;
  setId: any;
  setIsOpenPopup: any;
}

const ListTableRow = ({
  index,
  document,
  assignRegister,
  deleteDocument,
  setIsOpenPopup,
  setId,
}: IProps) => {
  const [totalPartQty, setTotalPartQty] = useState(0);
  const [isAssign, setIsAssign] = useState(false);
  useEffect(() => {
    if (document.store_part_id?._id) {
      axios
        .get(
          `/api/storeReceiveRegister/part/totalStoreQty/${document.store_part_id._id}`,
        )
        .then((res) => {
          setTotalPartQty(res.data.totalStoreQty);
          if (
            res.data.totalStoreQty + document.qty >=
            document.store_part_id?.max_store_qty
          ) {
            setIsAssign(true);
          } else {
            setIsAssign(false);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [document]);
  return (
    <TableRow
      style={{
        background: isAssign ? '#8c0101' : '',
        color: isAssign ? 'white' : '',
      }}
    >
      <TableCell
        align="center"
        style={{
          color: isAssign ? 'white' : '',
        }}
      >
        {index + 1}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {document.store_part_id?.ri_part_id?.part_number}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {document?.mrn_no}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {document?.ri_store_location}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {formatDate(document?.date_of_receiving || '')}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {document.qty}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {totalPartQty + (document.qty || 0)}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {!isAssign && (
          <CustomButton
            color="primary"
            size={'small'}
            disabled={document.isCompleted || isAssign}
            sx={{
              margin: '10px',
            }}
            icon={<MdAssignmentTurnedIn />}
            onClick={() => assignRegister(document._id || '')}
          >
            ASSIGN
          </CustomButton>
        )}
        <EditIconButton
          tooltipTitle="edit"
          style={{
            color: isAssign ? 'white' : '',
          }}
          onClick={() => {
            setId(document._id || '');
            setIsOpenPopup(true);
          }}
        />
        <DeleteIconButton
          tooltipTitle="delete"
          style={{
            color: isAssign ? 'white' : '',
          }}
          onClick={() => deleteDocument(document._id || '')}
        />
      </TableCell>
    </TableRow>
  );
};

export default ListTableRow;
