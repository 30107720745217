import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../hooks/UseQuery';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};

const stickStylesRow = {
  position: 'sticky',
  width: '100%',
  top: '-.5rem',
  border: '1px solid black',
  zIndex: '2',
  height: '50px',
} as const;

const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  height: '50px',
  backgroundColor: '#4F81BD',
  color: '#fff',
} as const;
interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const year = useQuery('year');

  return (
    <thead>
      <TableCompHead
        colSpan={13 + createConstant.extracolSpan}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dt']}
        values={['FM/XLV/RI/017', '00', '']}
        heading={`${createConstant.theadHeader} (YEAR : ${year})`}
      />
      <tr style={{ ...stickStylesRow }}>
        <th
          style={{
            ...stickStylesColumn,
            left: 0,
            width: '50px',
            minWidth: '50px',
          }}
        >
          S. No.
        </th>
        <th
          style={{
            ...stickStylesColumn,
            left: 50,
            width: '150px',
            minWidth: '150px',
          }}
        >
          Part Name
        </th>
        <th
          style={{
            ...stickStylesColumn,
            left: 200,
            width: '150px',
            minWidth: '150px',
          }}
        >
          Part No.
        </th>
        <th
          style={{
            ...stickStylesColumn,
            left: 350,
            width: '150px',
            minWidth: '150px',
          }}
        >
          FOX PRO NO. / REF. DRG. NO.
        </th>
        <th
          style={{
            ...stickStylesColumn,
            left: 500,
            width: '150px',
            minWidth: '150px',
          }}
        >
          Customer Name
        </th>
        <th
          style={{
            ...stickStylesColumn,
            left: 650,
            width: '150px',
            minWidth: '150px',
          }}
        >
          Supplier Name
        </th>
        {[
          'JAN',
          'FEB',
          'MAR',
          'APR',
          'MAY',
          'JUN',
          'JUL',
          'AUG',
          'SEP',
          'OCT',
          'NOV',
          'DEC',
        ].map((el, index) => (
          <th
            key={`${index}_headcol`}
            align="center"
            style={{ textAlign: 'center', ...style2 }}
          >
            {el}-{year?.substring(year.length - 2)}
          </th>
        ))}
        <th style={{ textAlign: 'center', ...style1 }}>Remarks</th>
      </tr>
    </thead>
  );
};

export default THead;
