import { useEffect, useState } from 'react';
import { TableInput } from '../../../../../components/common';
import TBodyRight from './TBodyRight';
import formatDate from '../../../../../components/common/formatDate';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';

interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
  employee: any;
  setRowName: any;
}

const managedCategory: any = {
  'Ist Layer': 'Line Engineer',
  '2nd Layer': 'Asst. Manager / Shift Supervisor (QA)',
  '3rd Layer': 'Dy. Manager / Manager  (QA & Prod)',
};
const TBody = ({
  moduleData,
  isView,
  allmonth,
  setRowName,
  employee,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [departmentData, setDepartmentData] = useState<any>([]);
  useEffect(() => {
    axios
      .get(`/api/department/department/departmentForAutoComplete`)
      .then((res) => {
        setDepartmentData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <>
          <tr key={`${index}_table_row`}>
            <td rowSpan={2}>
              <AutocompleteMuiCustom
                id={'department_name'}
                label={'Department Name'}
                option_name={'department_name'}
                arrayofObj={departmentData}
                value={item.a1 || ''}
                onChange={(e, value) => {
                  item.a1 = value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td rowSpan={2}>
              <TableInput
                isView={isView}
                name="a2"
                type="text"
                value={item.a2}
                onChange={(e) => {
                  item.a2 = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td rowSpan={2}>
              <TableInput
                isView={isView}
                name="a3"
                type="text"
                value={item.a3}
                onChange={(e) => {
                  item.a3 = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td rowSpan={2}>
              <TableInput
                isView={isView}
                name="a4"
                type="text"
                value={item.a4}
                onChange={(e) => {
                  item.a4 = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>P</td>
            {/* always constant ⬇️*/}
            <TBodyRight
              item={item}
              isView={isView}
              index={index}
              allmonth={allmonth}
            />
            <td rowSpan={2}>
              <TableInput
                isView={isView}
                style={{ height: '80px' }}
                name="remarks"
                type="text"
                value={item.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>A</td>
            {allmonth.map((month: any, mIdx: number) => (
              <td
                width="80px"
                height="45px"
                style={{
                  backgroundColor: item[month].doneDate && '#37b24d',
                  color: 'white',
                  textTransform: 'capitalize',
                  fontSize: '1.6rem',
                }}
              >
                {item[month].isTask && 'A'}
                {/* {formatDate(item[month].doneDate) || null} */}
              </td>
            ))}
          </tr>
          {/* ---x---- */}
        </>
      ))}
    </tbody>
  );
};

export default TBody;
