import React from 'react';
import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import { TableCustom } from '../../../../components/common';
import formatDate from '../../../../components/common/formatDate';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '../../../../hooks/UseQuery';

interface IProductionData {
  _id?: string;
  production_date?: Date;
  shift?: string;
  document_type?: string;
  line_incharge_id?: string;
}
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'LINE INCHARGE', rowspan: 1, colspan: 1 },
    { text: 'SHIFT', rowspan: 1, colspan: 1 },
    { text: 'PRODUCTION DATE', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

interface IProps {
  deleteDocument: (id: string) => void;
  tableData: {
    [key: string | number]: number | string | null;
  }[];
}
const ProductionLogBookList = ({ tableData, deleteDocument }: IProps) => {
  const navigate = useNavigate();
  const test = useQuery('test') === 'true' ? true : false;
  const firstFloorType = useQuery('firstFloorType') || '';

  return (
    <Box>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {tableData &&
            tableData.map((document: IProductionData, index: number) => (
              <React.Fragment key={document?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {document.line_incharge_id}
                  </TableCell>
                  <TableCell align="center">{document.shift}</TableCell>
                  <TableCell align="center">
                    {formatDate(document?.production_date || '')}
                  </TableCell>
                  <TableCell align="center">
                    <ViewIconButton
                      tooltipTitle="view"
                      onClick={() =>
                        navigate(
                          `/common/createProductionLogBook/${document._id}?isView=true&documentType=${document.document_type}&test=${test}&firstFloorType=${firstFloorType}`
                        )
                      }
                    />
                    <EditIconButton
                      tooltipTitle="edit"
                      onClick={() =>
                        navigate(
                          `/common/createProductionLogBook/${document._id}?isView=false&documentType=${document.document_type}&test=${test}&firstFloorType=${firstFloorType}`
                        )
                      }
                    />
                    <DeleteIconButton
                      tooltipTitle="delete"
                      onClick={() => deleteDocument(document._id || '')}
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
    </Box>
  );
};

export default ProductionLogBookList;
