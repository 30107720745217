export const listConstant = {
  childHeader: 'RAW MATERIAL TEST PLAN - Tubes  List',
  addButtonLable: 'New Tubes PLAN',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'TUBES PLAN',
  theadHeader: 'RAW MATERIAL TEST PLAN - Tubes ',
  extracolSpan: 3,
};

export const checkListList = {
  childHeader: 'TUBES PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'TubesPlan',
  gettableDataurl: 'TubesPlan/monthly',
  getPlanByid: 'TubesPlan',
  createPlanByid: 'TubesPlan',
  updatePlanByid: 'TubesPlan',
  sync: 'TubesPlan/handleSync',
  getAllCheckList: 'TubesPlan/getAllCheckList',
  checkListStatus: 'TubesPlan/handleStatus',
  checklistUpload: 'TubesPlan/handleUpload',
  checklistDeleteImage: 'TubesPlan/handleDeleteImage',
};

export const tilesConstant = {
  childHeaderText: 'Tubes PLAN',
};

export const navigationConstant = {
  createPlanSchedule: '/common/TubesPlanCreate',
};
