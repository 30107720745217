import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    part_id: null,
    customer_id: null,
    job_card_number: '',
    item_code: '',
    operation: '',
    process_engineer: '',
    prod_qty: null,
    rej_qty: null,
    problem: '',
    cause: '',
    action_taken: '',
    remarks: '',
    operations: [],
    date: '',
    shift: ''
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  dailyRejMon_date: moment(),
  shift: '',
  processes: Array.from({ length: 15 }, () => ({
    ...lodash.cloneDeep(processes[0]),
  })),
};
