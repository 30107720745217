import { Production } from '../../pages/ProductionPage';
import UnderConstructionPage from '../../underconstruction-page';

export const productions = [
  {
    path: 'production',
    element: <Production />,
  },
  {
    path: 'rotorHourlyProductionList',
    element: <UnderConstructionPage />,
  },
  {
    path: 'dailyProductionList',
    element: <UnderConstructionPage />,
  },
  {
    path: 'dailyProductionStrokesList',
    element: <UnderConstructionPage />,
  },
];
