interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ preparedBy, approvedBy }: Ifoot) => {
  return (
    <tfoot>
      <tr>
        <th colSpan={3} align="left">
          Prepared By: {preparedBy}
        </th>
        <th colSpan={4} align="left">
          Approved By: {approvedBy}
        </th>
      </tr>
      <tr>
        <th style={{ background: '#40c057' }}>GREEN (G)</th>
        <th style={{ background: '#ffe066' }}>YELLOW (Y)</th>
        <th style={{ background: '#e03131' }}>RED (R)</th>
        <th colSpan={3}></th>
        <th rowSpan={2}>Formate no. :- FMT/XLV/PRD/2.1</th>
      </tr>
      <tr>
        <th>Last time ok</th>
        <th>Send for correction</th>
        <th>Production stop and tool send for repair</th>
        <th colSpan={3}></th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
