import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import MenuItem from '@mui/material/MenuItem';
import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import SelectGridMui from '../../components/common/selectscustom/SelectGridMui';
import useConfirm from '../../components/common/useConfirm';
import GridSelect from '../../components/common/inputs/GridSelect';
import { SelectChangeEvent } from '@mui/material';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [machine, setMachine] = useState<IData>({});
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Tools' : 'Create Tools'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterListOfTools/${id}`)
        .then((res) => {
          setMachine(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleSupplier(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setMachine((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterListOfTools/${id}`, machine)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      machine.is_admin_request = 'pending';
      await axios
        .post('/api/masterListOfTools', machine)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'tool_details'}
        name={'tool_details'}
        html_for={'tool_details'}
        label_name={'Tool Name'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.tool_details}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'tool_id'}
        name={'tool_id'}
        html_for={'tool_id'}
        label_name={'Tool ID'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.tool_id}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'tool_size'}
        name={'tool_size'}
        html_for={'tool_size'}
        label_name={'Tool Size'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.tool_size}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'tool_description'}
        name={'tool_description'}
        html_for={'tool_description'}
        label_name={'Tool Description'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.tool_description}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.remarks}
        onChange={handleSupplier}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
