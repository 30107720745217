import { Box } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

import InspectionStandardList from './components/tablelist/InspectionStandardList';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ModalCustom from '../../../components/common/Modal';
import ChildHeader from '../../../components/ui/ChildHeader';
import CreateInspectionStandardPart from './components/CreateInspectionStandardPart';
import { useQuery } from '../../../hooks/UseQuery';
import useDebounce from '../../../hooks/useDebounce';
import { CustomPagination, SearchBar } from '../../../components/common';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';

const filterOptions = [
  { value: 'part_number', name: 'A-Z' },
  { value: 'createdAt', name: 'Most Recent First' },
  { value: 'createdAt_old', name: 'Oldest First' },
];
const TableList = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [standardId, setStandardId] = useState(null);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [images, setImages] = useState(null);
  // useEffect(() => {
  //   axios
  //     .get(`/api/inspectionStandard`)
  //     .then((res) => {
  //       if (res.data) setTableData(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, [render, isOpenPopup]);

  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [isNewPartModal, setIsNewPartModal] = useState(false);

  const fetchData = async () => {
    const res: any = await axios
      .get(
        `/api/inspectionStandard?page=${page}&sortBy=${
          filter || 'part_number'
        }&search=${search}&perPage=${10}`
      )
      .catch((err) => {
        console.log(err);
      });
    setTableData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };
  useDebounce(fetchData, 1000, [page, search, render, filter, isNewPartModal]);

  const deleteDocument = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?'
    );
    if (isDeleted) {
      axios
        .delete(`/api/inspectionStandard/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const uploadImage = async (id: string) => {
    if (!images) {
      return alert('please select image');
    }
    const formData = new FormData();
    formData.append('image', images[0]);
    axios
      .patch(`/api/inspectionStandard/uploadImage/${id}`, formData)
      .then((res) => {
        alert('image upload successfully');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box>
      <ChildHeader text="INSPECTION STANDARD">
        <AddButton
          label="New Inspection Standard"
          onClick={() => setIsOpenPopup(true)}
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search Part Name" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <InspectionStandardList
        {...{ setImages, setRender, uploadImage, tableData, deleteDocument }}
      />
      <ModalCustom
        title="CREATE INSPECTION STANDARD"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <CreateInspectionStandardPart
          {...{ setIsOpenPopup, standardId, setStandardId }}
        />
      </ModalCustom>
      <CustomPagination totalPage={totalPages} />
    </Box>
  );
};

export default TableList;
