import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';

interface Ifoot {
  isView: any;
  moduleData: any;
}
const TFoot = ({ moduleData, isView }: Ifoot) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <th align="left" colSpan={4}>
          Name of Operator:{' '}
        </th>
        <th colSpan={10} align="left">
          <TableInput
            isView={isView}
            name="operator_1"
            type="text"
            value={moduleData.operator_1}
            onChange={(e) => {
              moduleData.operator_1 = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th colSpan={10} align="left">
          <TableInput
            isView={isView}
            name="operator_2"
            type="text"
            value={moduleData.operator_2}
            onChange={(e) => {
              moduleData.operator_2 = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>

        <th align="left" colSpan={5}>
          Verified Signature:{' '}
        </th>
        <th colSpan={10} align="left">
          <TableInput
            isView={isView}
            name="operator_sign"
            type="text"
            value={moduleData.operator_sign}
            onChange={(e) => {
              moduleData.operator_sign = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={4}>
          Name of Supervisor:{' '}
        </th>
        <th colSpan={10} align="left">
          <TableInput
            isView={isView}
            name="supervisor_1"
            type="text"
            value={moduleData.supervisor_1}
            onChange={(e) => {
              moduleData.supervisor_1 = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th colSpan={10} align="left">
          <TableInput
            isView={isView}
            name="supervisor_2"
            type="text"
            value={moduleData.supervisor_2}
            onChange={(e) => {
              moduleData.supervisor_2 = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>

        <th align="left" colSpan={5}>
          Verified Signature:{' '}
        </th>
        <th colSpan={10} align="left">
          <TableInput
            isView={isView}
            name="supervisor_sign"
            type="text"
            value={moduleData.supervisor_sign}
            onChange={(e) => {
              moduleData.supervisor_sign = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={8}>दिनांक</th>
        <th colSpan={8}>समस्या का ब्यॊरा</th>
        <th colSpan={8}>आपरेटर का नाम</th>
        <th colSpan={8}>मेन्टेनेन्स के द्वारा लिए गये एक्शन का ब्यॊरा</th>
        <th colSpan={7}>समस्या सही करने की दिनांक</th>
      </tr>
      {moduleData.foot_data.map((data: any, idx: number) => (
        <tr>
          <th colSpan={8}>
            {isView ? (
              formatDate(data.date)
            ) : (
              <DatePickerMui
                label=""
                value={moment(data?.date || null)}
                onChange={(date) => {
                  data.date = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </th>
          <th colSpan={8}>
            <TableInput
              isView={isView}
              name="problem"
              type="text"
              value={data.problem}
              onChange={(e) => {
                data.problem = e.target.value;
                setRender((prev: any) => !prev);
              }}
            />
          </th>
          <th colSpan={8}>
            <TableInput
              isView={isView}
              name="operator"
              type="text"
              value={data.operator}
              onChange={(e) => {
                data.operator = e.target.value;
                setRender((prev: any) => !prev);
              }}
            />
          </th>
          <th colSpan={8}>
            <TableInput
              isView={isView}
              name="maint"
              type="text"
              value={data.maint}
              onChange={(e) => {
                data.maint = e.target.value;
                setRender((prev: any) => !prev);
              }}
            />
          </th>
          <th colSpan={7}>
            <TableInput
              isView={isView}
              name="problem_fix"
              type="text"
              value={data.problem_fix}
              onChange={(e) => {
                data.problem_fix = e.target.value;
                setRender((prev: any) => !prev);
              }}
            />
          </th>
        </tr>
      ))}
      <tr>
        <th colSpan={39} align="left">
          निर्देश: . :1. सभी अवलोकन (विचार) लिखे। 1मशीन मे कोई असामान्य आवाज
          होने पर क्रप्य़ा मेन्टेनेन्स डिपार्ट्मेन्ट को सूचित करें यदि खुद ठीक न
          हो ।
        </th>
      </tr>
      <tr>
        <th colSpan={8}>Rev.Date</th>
        <th colSpan={8}>Rev.No.</th>
        <th colSpan={8}>Description of Revision</th>
        <th colSpan={15}>Remarks</th>
      </tr>
      {moduleData.revision_data.map((data: any, idx: number) => (
        <tr>
          <th colSpan={8}>
            {isView ? (
              formatDate(data.date)
            ) : (
              <DatePickerMui
                label=""
                value={moment(data?.date || null)}
                onChange={(date) => {
                  data.date = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </th>
          <th colSpan={8}>
            <TableInput
              isView={isView}
              name="rev_no"
              type="text"
              value={data.rev_no}
              onChange={(e) => {
                data.rev_no = e.target.value;
                setRender((prev: any) => !prev);
              }}
            />
          </th>
          <th colSpan={8}>
            <TableInput
              isView={isView}
              name="desc"
              type="text"
              value={data.desc}
              onChange={(e) => {
                data.desc = e.target.value;
                setRender((prev: any) => !prev);
              }}
            />
          </th>
          <th colSpan={15}>
            <TableInput
              isView={isView}
              name="remarks"
              type="text"
              value={data.remarks}
              onChange={(e) => {
                data.remarks = e.target.value;
                setRender((prev: any) => !prev);
              }}
            />
          </th>
        </tr>
      ))}
      <tr>
        <th colSpan={19} align="left">
          Prepared By:
          <TableInput
            isView={isView}
            name="prepared_by"
            type="text"
            value={moduleData.prepared_by}
            onChange={(e) => {
              moduleData.prepared_by = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th colSpan={20} align="left">
          Approved By:
          <TableInput
            isView={isView}
            name="approved_by"
            type="text"
            value={moduleData.approved_by}
            onChange={(e) => {
              moduleData.approved_by = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
