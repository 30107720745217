import React, { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../../components/common';
import CustomSelect from '../../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData &&
        moduleData?.c?.map((process: any, index: number) => (
          <tr>
            <th>{process.sNo}</th>
            <th style={{ minWidth: '300px', textAlign: 'center' }}>
              {process.checkPoint}
            </th>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="observation"
                type="text"
                value={process.observation}
                onChange={(e: any) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              {isView ? (
                process.status
              ) : (
                <CustomSelect
                  name="status"
                  value={process.status}
                  onChange={(e: any) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">NONE</MenuItem>
                  <MenuItem value="OK">OK</MenuItem>
                  <MenuItem value="NOT OK">NOT OK</MenuItem>
                </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="remark"
                type="text"
                value={process.remark}
                onChange={(e: any) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
