import React, { useEffect, useRef, useState } from 'react';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../components/common/useConfirm';
import GridDatePicker from '../../../../components/common/inputs/GridDatePicker';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import {
  Divider,
  Grid,
  MenuItem,
  SelectChangeEvent,
  Stack,
} from '@mui/material';
import GridSelect from '../../../../components/common/inputs/GridSelect';
import CustomUploadButton from '../../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../../components/common/Loader';
import ModalCustom from '../../../../components/common/Modal';

import AutocompleteGridmui from '../../../../components/common/autocomplete/AutocompleteGridmui';
import { DivStyled } from '../../../../assets/styles/components/DivStyledLableInputs';
import {
  AddIconButton,
  CustomIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import PartTable from './components/PartTable';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { Add } from '@mui/icons-material';
import SparePartList from './components/SparePartList';
import ViewSparePartList from './components/ViewSparePartList';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    // part_id: null,
    /*target_date_24: '',
    did_supplier: '',
    controlplan_recieved: '',
    fmea_recieved: '',
    status: '',*/
  });
  const [files, setFiles] = useState<any>(null);
  const [images, setImages] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Vendor' : 'Create Ventor'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [customerData, setCustomerData] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const [sparePartData, setSparePartData] = useState([]);
  const [isPartModal, setIsPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const moduleIdref = useRef<any>(null);
  const [sparePartIds, setSparePartIds] = useState<string[]>([]);
  const [isModalForViewSpare, setisModalForViewSpare] = useState(false);
  // const [selectedValue, setSelectedValue] = React.useState('');

  // useEffect(() => {
  //   if (id) {
  //     axios
  //       .get(`/api/customerConcernRoutes/${id}`)
  //       .then((res) => {
  //         setData(res.data);
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // }, [id]);
  useEffect(() => {
    axios
      .get('/api/machineRoutes/machineList/machineListForAutoComplete')
      .then((res) => {
        const data = res.data;
        data.forEach((res: any) => {
          res[
            'machine_no_name'
          ] = `${res['machine_no']} - ${res['machine_name']}`;
        });
        setMachineData(data);
      })
      .catch((err) => console.log(err));

    axios
      .get('/api/criticalSparePartsRoute/vendor/forAutoComplete')
      .then((res) => {
        setSparePartData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/maintenaceLogRoute/${id}`)
        .then((res) => {
          const data = res.data;
          if (data.machine_id) {
            data.machine_id.machine_no_name = `${data.machine_id['machine_no']} - ${data.machine_id['machine_name']}`;
          }
          setData(data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    if (!data.machine_id) {
      alert('Please enter Machine Name');
      return;
    }
    if (!data.complaint_recevied_at) {
      alert('Please enter Complaint recevied');
      return;
    }
    if (!data.complaint_rectified_at) {
      alert('Please enter Complaint Rectified');
      return;
    }
    // if (!data.spare_part_id) {
    //   alert('Please enter Spare Part');
    //   return;
    // }
    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);
    const formData = new FormData();

    if (typeof data.machine_id === 'object') {
      data.machine_id = data.machine_id._id;
    }

    for (const key in data) {
      console.log(key, data[key]);
      if (data[key]) {
        formData.append(key, data[key]);
      }
    }

    if (id) {
      await axios
        .put(`/api/maintenaceLogRoute/${id}`, data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/maintenaceLogRoute', data)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };
  return (
    <>
      <Loader loader={isLoading} />
      <Stack spacing={1}>
        <GridDatePicker
          label_name="Date"
          html_for={'date'}
          value={data?.date || null}
          onChange={(date) => {
            data.date = date;
          }}
        />
        <GridInputs
          id={'location'}
          name={'location'}
          html_for={'location'}
          label_name={'Location:'}
          input_type={'text'}
          focusType={'onblur'}
          value={data?.location || ''}
          onChange={handleCustomerData}
        />
        <Grid item xs={3} style={{ marginTop: '10px' }}>
          <AutocompleteMuiCustom
            id={'machine_name'}
            label={'Machine name'}
            option_name={'machine_no_name'}
            arrayofObj={machineData}
            value={data.machine_name || data.machine_id || ''}
            onChange={(e, value) => {
              //data.machine_id = value._id;
              data.machine_id = value;
              setRender((prev) => !prev);
            }}
          />
        </Grid>
        <div
          style={{
            display: 'flex',
            marginLeft: '100px',
            marginRight: '100px',
          }}
        >
          <div style={{ marginRight: '20px' }}>
            <TimePicker
              label="Complaint Recevied At"
              views={['hours', 'minutes']}
              ampm={false}
              value={
                moment(data?.complaint_recevied_at || null)
                //moduleData.pirs[tabValue].times[timeHeadIdx] || ''
                //Complaint Recevied At
              }
              onChange={(date: any) => {
                //moduleData.pirs[tabValue].times[timeHeadIdx] = date;
                data.complaint_recevied_at = date;
                setRender((prev) => !prev);
              }}
            />
          </div>
          <div>
            <TimePicker
              label="Complaint Rectified At"
              views={['hours', 'minutes']}
              ampm={false}
              value={
                moment(data?.complaint_rectified_at || null)
                //moduleData.pirs[tabValue].times[timeHeadIdx] || ''
                ///Complaint Rectified At
              }
              onChange={(date: any) => {
                //moduleData.pirs[tabValue].times[timeHeadIdx] = date;
                data.complaint_rectified_at = date;
                setRender((prev) => !prev);
              }}
            />
          </div>
        </div>
        {/* <div>
        <label htmlFor={'time_taken'}>{'Time Taken'}</label>
        <TimePicker
          value={
            moment(data?.complaint_rectified_at)
            //Time Taken
            //moduleData.pirs[tabValue].times[timeHeadIdx] || ''
          }
          onChange={(date: any) => {
            //moduleData.pirs[tabValue].times[timeHeadIdx] = date;
            data.complaint_rectified_at = date;
            setRender((prev) => !prev);
          }}
        />
      </div> */}
        <GridInputs
          id={'complaint_recevied'}
          name={'complaint_recevied'}
          html_for={'complaint_recevied'}
          label_name={'Complaint Recevied:'}
          input_type={'text'}
          focusType={'onblur'}
          value={data?.complaint_recevied || ''}
          onChange={handleCustomerData}
        />
        <GridInputs
          id={'work_done'}
          name={'work_done'}
          html_for={'work_done'}
          label_name={'Work Done:'}
          input_type={'text'}
          focusType={'onblur'}
          value={data?.work_done || ''}
          onChange={handleCustomerData}
        />
        <GridInputs
          id={'spare_part'}
          name={'spare_part'}
          html_for={'spare_part'}
          label_name={'Add Spare Part'}
          input_type={'text'}
          focusType={'onblur'}
          value={data?.spare_part || ''}
          onChange={handleCustomerData}
        />
        {/* <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={30}
        >
          <div style={{ fontSize: '15px', marginLeft: '20px' }}>
            Add Spare Part
          </div>
          <CustomIconButton
            tooltipTitle=""
            onClick={() => {
              // moduleIdref.current = item._id;
              // setSparePartIds(item.spare_part_ids || []);
              setisModalForViewSpare(true);
            }}
          >
            <Add />
          </CustomIconButton>
        </Stack> */}
        <GridInputs
          id={'spare_qty'}
          name={'spare_qty'}
          html_for={'spare_qty'}
          label_name={'No. of spare used:'}
          input_type={'number'}
          focusType={'onblur'}
          value={data?.spare_qty || ''}
          onChange={handleCustomerData}
        />
        <GridSelect
          last_child={1}
          name="status"
          label_name="Remarks/Status"
          html_for={'status'}
          value={data?.status || ''}
          onChange={handleCustomerData}
        >
          <MenuItem value="OK">OK</MenuItem>
          <MenuItem value="NOT OK">NOT OK</MenuItem>
        </GridSelect>
      </Stack>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        title="CRITICAL SPARE PART LIST"
        openModal={isModalForViewSpare}
        closeModal={() => {
          setisModalForViewSpare(false);
        }}
      >
        <ViewSparePartList
          moduleId={data?.machine_id?._id}
          {...{
            // handleChange,
            // selectedValue,
            data,
          }}
          // closeModal={setisModalForViewSpare}
        />
      </ModalCustom>
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART"
      >
        <PartTable moduleData={data} />
      </ModalCustom>
    </>
  );
};

export default Create;
