import { Box } from '@mui/material';
import { useState } from 'react';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ModalCustom from '../../../components/common/Modal';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import CreateDocument from './components/CreateDocument';
import TableList from './components/TableList';
import ViewFile from './components/ViewFile';

const QualityAlertModule = () => {
  const { state } = useAuth();
  const heading = useQuery('heading') || '';
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);

  return (
    <Box>
      <ChildHeader text={heading && heading}>
        {state.user?.userType === 2 && (
          <AddButton
            label={`New ${heading}`}
            onClick={() => setIsOpenPopup(true)}
          />
        )}
      </ChildHeader>
      <TableList
        {...{ isOpenPopup, setIsFileOpenPopup, setIsOpenPopup, setId }}
      />
      <ModalCustom
        title={`CREATE ${heading} `}
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
          setId(null);
        }}
      >
        <CreateDocument {...{ id, setId, setIsOpenPopup }} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} />
      </ModalCustom>
    </Box>
  );
};

export default QualityAlertModule;
