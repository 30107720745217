import React, { useEffect, useState } from 'react';
import { Stack, Typography, Box, styled } from '@mui/material';
import axios from 'axios';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';

const ModernStack = styled(Stack)(({ theme }) => ({
  width: '100%',
  minWidth: '600px',
  maxWidth: '700px',
  margin: 'auto',
  gap: theme.spacing(2),
}));

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number }
) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            height: '20px',
            borderRadius: '3px',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#1a90ff', // Color for the filled part of the bar
            },
            backgroundColor: '#e0e0e0',
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.primary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
};

const partsName = ['INSPECTION STANDARD'];

const Aimodal = ({ partId, setAiModal }: any) => {
  const [counter, setCounter] = useState<any>({
    'INSPECTION STANDARD': 0,
  });

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;
    axios
      .put(`/api/inspectionStandard/createAutoSpec/openAI/${partId}`)
      .then((res) => {
        partsName.forEach((param) => {
          if (counter[param] !== 100) {
            setCounter((prev: any) => ({ ...prev, [param]: 100 }));
          }
        });
        timer = setTimeout(() => {
          setAiModal(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
        alert("Something's wrong please try again later");
        setAiModal(false);
      });
    return () => clearTimeout(timer);
  }, [partId]);

  const updateCounter = (key: string, interval: number) => {
    const intervalId = setInterval(() => {
      setCounter((prev: any) => {
        const newValue = prev[key] < 100 ? prev[key] + 1 : prev[key];
        if (prev[key] >= 100) {
          clearInterval(intervalId);
        }

        return { ...prev, [key]: newValue };
      });
    }, interval);
    return intervalId;
  };

  useEffect(() => {
    const intervals = partsName.map((item, index) =>
      updateCounter(item, 120 * (index + 1))
    );
    return () => {
      intervals.forEach(clearInterval);
    };
  }, []);

  return (
    <>
      <ModernStack gap={2}>
        {partsName?.map((item, index) => (
          <Box key={item} sx={{ width: '100%' }}>
            <h2 style={{ margin: 0, marginBottom: '5px' }}>{item}</h2>
            <LinearProgressWithLabel value={counter?.[item]} />
          </Box>
        ))}
      </ModernStack>
    </>
  );
};

export default Aimodal;
