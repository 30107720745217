import React from 'react';
import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import { TableCustom } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';

interface IProductionData {
  _id?: string;
  date?: Date;
}
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'DATE', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

interface IProps {
  deleteDocument: (id: string) => void;
  tableData: {
    [key: string | number]: number | string | null;
  }[];
}
const OeeCalculationList = ({ tableData, deleteDocument }: IProps) => {
  const navigate = useNavigate();

  return (
    <Box>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {tableData &&
            tableData.map((document: IProductionData, index: number) => (
              <React.Fragment key={document?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {formatDate(document?.date || '')}
                  </TableCell>
                  <TableCell align="center">
                    <ViewIconButton
                      tooltipTitle="view"
                      onClick={() =>
                        navigate(
                          `/common/createOeeCalculation/${document._id}?isView=true`
                        )
                      }
                    />
                    <EditIconButton
                      tooltipTitle="edit"
                      onClick={() =>
                        navigate(
                          `/common/createOeeCalculation/${document._id}?isView=false`
                        )
                      }
                    />
                    <DeleteIconButton
                      tooltipTitle="delete"
                      onClick={() => deleteDocument(document._id || '')}
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
    </Box>
  );
};

export default OeeCalculationList;
