import { useState } from 'react';
import { SearchBar } from '../../../../components/common/SearchBar';
import FilterBy from '../../../../components/common/FilterBy';
import { SearchFilterContainer } from '../../../../assets/styles/layout/searchFilter.styled';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { TableBody, TableCell, TableRow, TextField } from '@mui/material';
import axios from 'axios';
import ModalCustom from '../../../../components/common/Modal';
import {
  AddButton,
  CustomButton,
} from '../../../../components/common/button/CustomButtons';
import TableCustom from '../../../../components/common/customTable/TableCustom';
import useDebounce from '../../../../hooks/useDebounce';
import React from 'react';
import formatDate from '../../../../components/common/formatDate';
import {
  DeleteIconButton,
  EditIconButton,
} from '../../../../components/common/button/CustomIconButton';

import { useQuery } from '../../../../hooks/UseQuery';
import CustomPagination from '../../../../components/common/CustomPagination';
import useConfirm from '../../../../components/common/useConfirm';
import AddEditPart from './components/AddEditPart';
import { RiFileExcel2Line } from 'react-icons/ri';
import CustomUploadButton from '../../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../../components/common/Loader';

const filterOptions = [
  { value: 'part_name', name: 'A-Z' },
  { value: 'createdAt', name: 'Most Recent First' },
  { value: 'createdAt_old', name: 'Oldest First' },
];

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'ITEM Desc(ODXTHICKNESSXLENGTH)', rowspan: 1, colspan: 1 },
    { text: 'Item Code', rowspan: 1, colspan: 1 },
    { text: 'Item Rev No', rowspan: 1, colspan: 1 },
    { text: 'Material', rowspan: 1, colspan: 1 },
    { text: 'Outer Diameter (OD±0.1mm)', rowspan: 1, colspan: 1 },
    { text: 'Thickness (OD±0.1mm)', rowspan: 1, colspan: 1 },
    { text: 'Length (L±1mm)', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const PipePartList = () => {
  const [data, setData] = useState<any>([]);
  const [isNewPartModal, setIsNewPartModal] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [partId, setPartId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to DELETE this Document?',
  );
  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;
  const fetchData = async () => {
    const res: any = await axios
      .get(
        `/api/pipePartRoute?page=${page}&sortBy=${
          filter || 'part_name'
        }&search=${search}&perPage=${10}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  const deletePart = async (id: string) => {
    const isDelete = await confirmDelete();
    if (!isDelete) {
      return;
    }
    await axios.delete(`/api/pipePartRoute/${id}`);
    setRender((prev) => !prev);
  };

  useDebounce(fetchData, 1000, [page, search, render, filter, isNewPartModal]);

  const handleMultiplePipeImport = (e: any) => {
    window.alert(
      'Ensure Valid Numeric / String Input, avoid unsolved numerical values. Exp: 810+1.0 (correct way:811.0) else it will discard those entry.',
    );
    setIsLoading(true);

    const formData = new FormData();
    formData.append('file', e.target.files[0], 'pipe.xlsx');
    axios
      .post(`/api/excelUpload/pipe`, formData)
      .then((res) => {
        if (!res.data.isExcel) {
          window.alert(
            'Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.',
          );
        } else {
          window.alert(res.data.msg);
        }
        setIsLoading(false);
        setRender((prev) => !prev);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else {
          window.alert(
            'Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.',
          );
        }
      });
  };
  return (
    <>
      <Loader loader={isLoading} />

      <ChildHeader text="Pipe List">
        <CustomUploadButton
          lable={'Upload Excel'}
          onChange={handleMultiplePipeImport}
        />
        <AddButton onClick={() => setIsNewPartModal(true)} label="New Pipe" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search Item Desc or Item Code" />
        <FilterBy label="Sort" options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.part_name}</TableCell>
                  <TableCell align="center">{item?.part_number}</TableCell>
                  <TableCell align="center">{item?.rev_no}</TableCell>
                  <TableCell align="center">{item?.material}</TableCell>
                  <TableCell align="center">{item?.outer_dia}</TableCell>
                  <TableCell align="center">{item?.thickness}</TableCell>
                  <TableCell align="center">{item?.length}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>

                  <TableCell align="center">
                    <EditIconButton
                      tooltipTitle="EDIT"
                      onClick={() => {
                        setPartId(item?._id!);
                        setIsNewPartModal(true);
                      }}
                    />
                    <DeleteIconButton
                      tooltipTitle="DELETE"
                      onClick={() => {
                        if (item._id) {
                          deletePart(item._id);
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />

      <ModalCustom
        openModal={isNewPartModal}
        closeModal={() => {
          setPartId(null);
          setIsNewPartModal(false);
        }}
        title="PIPE"
      >
        <AddEditPart
          setIsNewPartModal={setIsNewPartModal}
          id={partId}
          setPartId={setPartId}
        />
      </ModalCustom>
      <DialogDelete />
    </>
  );
};

export default PipePartList;
