import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import GridDatePicker from '../../components/common/inputs/GridDatePicker';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';
import { Checkbox, Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import GridSelect from '../../components/common/inputs/GridSelect';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';
import { Loader } from '../../components/common/Loader';

const Create = ({
  id,
  setIsModal,
  disable,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  disable: boolean;
}) => {
  const [data, setData] = useState<any>({
    target_date_24: '',
    did_supplier: '',
    controlplan_recieved: '',
    fmea_recieved: '',
    status: '',
  });
  const [files, setFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Vendor' : 'Create Ventor'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/suppplierComplaint/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
    axios
      .get(`/api/vendorRoute/vendor/forAutoComplete`)
      .then((res) => {
        console.log(res);
        setVendors(res.data);
      })
      .catch((err) => console.log(err));
  }, [id]);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>,
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    const formData = new FormData();
    if (typeof data.vendor_id === 'object') {
      data.vendor_id = data.vendor_id._id;
    }
    for (const key in data) {
      if (data[key] || typeof data[key] == 'boolean') {
        if (key !== 'files') formData.append(key, data[key]);
      }
    }
    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }
    if (id) {
      await axios
        .put(`/api/suppplierComplaint/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/suppplierComplaint', formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };
  return (
    <>
      <Loader loader={isLoading} />
      <Grid item xs={3}>
        <AutocompleteMuiCustom
          id={'vendor_name'}
          label={'Supplier Name'}
          option_name={'vender_name'}
          value={data.vendor_name || data.vendor_id || ''}
          arrayofObj={vendors}
          onChange={(e, value) => {
            data.vendor_id = value._id;
            data.vendor_name = value;
          }}
          //readOnly={disable}
        />
      </Grid>
      <GridInputs
        id={'plant_code'}
        name={'plant_code'}
        html_for={'plant_code'}
        label_name={'Plant Code:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.plant_code || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridDatePicker
        label_name="Defect Occurence Date"
        html_for={'defect_occ_date'}
        value={data?.defect_occ_date || null}
        onChange={(date) => {
          data.defect_occ_date = date;
        }}
        disabled={disable}
      />
      <GridInputs
        id={'stage_of_occ'}
        name={'stage_of_occ'}
        html_for={'stage_of_occ'}
        label_name={
          'Stage of Occurrence (R.I /In-Process/ Customer Compalint):'
        }
        input_type={'text'}
        focusType={'onblur'}
        value={data?.stage_of_occ || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'item_code'}
        name={'item_code'}
        html_for={'item_code'}
        label_name={'Item Code as per ERP:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.item_code || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'latest_drg_no'}
        name={'latest_drg_no'}
        html_for={'latest_drg_no'}
        label_name={'Latest Drawing No.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.latest_drg_no || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridDatePicker
        label_name="Invoice Date (DD/MM/YY)"
        html_for={'invoice_date'}
        value={data?.invoice_date || null}
        onChange={(date) => {
          data.invoice_date = date;
        }}
        disabled={disable}
      />
      <GridInputs
        id={'invoice_no'}
        name={'invoice_no'}
        html_for={'invoice_no'}
        label_name={'Invoice No:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.invoice_no || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'lot_read_qty'}
        name={'lot_read_qty'}
        html_for={'lot_read_qty'}
        label_name={'Lot Recd.Qty. (No.):'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.lot_read_qty || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'defective_sampling'}
        name={'defective_sampling'}
        html_for={'defective_sampling'}
        label_name={'Defectives Found in Sampling (No.):'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.defective_sampling || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'total_defective'}
        name={'total_defective'}
        html_for={'total_defective'}
        label_name={'Total Defectives Found in Recd. Lot (No.):'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.total_defective || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'defect_desc'}
        name={'defect_desc'}
        html_for={'defect_desc'}
        label_name={'Defect Description:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.defect_desc || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />

      <GridSelect
        name="target_date_24"
        label_name="Target Date (Containment Action) Within 24 hrs"
        html_for={'target_date_24'}
        value={data?.target_date_24}
        onChange={handleCustomerData}
        disabled={disable}
      >
        <MenuItem disabled={disable}>NONE</MenuItem>
        <MenuItem disabled={disable} value="YES">
          YES
        </MenuItem>
        <MenuItem disabled={disable} value="NO">
          NO
        </MenuItem>
      </GridSelect>
      <GridDatePicker
        html_for={'target_date_7'}
        label_name={'Target Date within 7 days (8D closure):'}
        value={data.target_date_7 || null}
        onChange={(date) => {
          data.target_date_7 = date;
        }}
        disabled={disable}
      />
      <GridInputs
        id={'action_plan'}
        name={'action_plan'}
        html_for={'action_plan'}
        label_name={'Action plan Received (YES/NO):'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.action_plan || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'repeat_plan'}
        name={'repeat_plan'}
        html_for={'repeat_plan'}
        label_name={'Repeated Problem (No.of Times):'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.repeat_plan || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridSelect
        name="did_supplier"
        label_name="Did Supplier Submit CAPA in the Past for This Problem:"
        html_for={'did_supplier'}
        value={data?.did_supplier}
        onChange={handleCustomerData}
        disabled={disable}
      >
        <MenuItem disabled={disable}>NONE</MenuItem>
        <MenuItem disabled={disable} value="YES">
          YES
        </MenuItem>
        <MenuItem disabled={disable} value="NO">
          NO
        </MenuItem>
      </GridSelect>
      <GridSelect
        name="controlplan_recieved"
        label_name="Control Plan Received:"
        html_for={'controlplan_recieved'}
        value={data?.controlplan_recieved}
        onChange={handleCustomerData}
        disabled={disable}
      >
        <MenuItem disabled={disable}>NONE</MenuItem>
        <MenuItem disabled={disable} value="RECEIVED">
          RECEIVED
        </MenuItem>
        <MenuItem disabled={disable} value="NOT RECEIVED DD">
          NOT RECEIVED
        </MenuItem>
      </GridSelect>
      <GridSelect
        name="fmea_recieved"
        label_name="PFMEA Received:"
        html_for={'fmea_recieved'}
        value={data?.fmea_recieved}
        onChange={handleCustomerData}
        disabled={disable}
      >
        <MenuItem disabled={disable}>NONE</MenuItem>
        <MenuItem disabled={disable} value="RECEIVED">
          RECEIVED
        </MenuItem>
        <MenuItem disabled={disable} value="NOT RECEIVED">
          NOT RECEIVED
        </MenuItem>
      </GridSelect>
      <GridSelect
        name="status"
        label_name="STATUS (OPEN/CLOSED):"
        html_for={'status'}
        value={data?.status}
        onChange={handleCustomerData}
        disabled={disable}
      >
        <MenuItem disabled={disable}>NONE</MenuItem>
        <MenuItem disabled={disable} value="CLOSED">
          CLOSED
        </MenuItem>
        <MenuItem disabled={disable} value="OPEN">
          OPEN
        </MenuItem>
      </GridSelect>
      <GridInputs
        id={'suspected_qty'}
        name={'suspected_qty'}
        html_for={'suspected_qty'}
        label_name={'Suspected Qty.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.suspected_qty || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridDatePicker
        label_name="Date"
        html_for={'date'}
        value={data?.date || null}
        onChange={(date) => {
          data.date = date;
        }}
        disabled={disable}
      />
      <GridInputs
        id={'total_qty_checked'}
        name={'total_qty_checked'}
        html_for={'total_qty_checked'}
        label_name={'Total Qty Checked:'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.total_qty_checked || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'ok_qty'}
        name={'ok_qty'}
        html_for={'ok_qty'}
        label_name={'Ok Qty:'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.ok_qty || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'reject_qty'}
        name={'reject_qty'}
        html_for={'number'}
        label_name={'Reject Qty:'}
        input_type={'text'}
        focusType={'onblur'}
        disabled={true}
        value={
          data.total_qty_checked &&
          data.ok_qty &&
          data.total_qty_checked - data?.ok_qty
        }
      />
      <GridInputs
        id={'rejection_br'}
        name={'rejection_br'}
        html_for={'rejection_br'}
        label_name={'REJECTION LR/RR  INVOICE NO. - Date:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.rejection_br || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'invoice_date_lot1'}
        name={'invoice_date_lot1'}
        html_for={'invoice_date_lot1'}
        label_name={'Invoice Date &num/Lot quantity 1:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.invoice_date_lot1 || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'status_lot1'}
        name={'status_lot1'}
        html_for={'status_lot1'}
        label_name={'Status 1:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.status_lot1 || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'invoice_date_lot2'}
        name={'invoice_date_lot2'}
        html_for={'invoice_date_lot2'}
        label_name={'Invoice Date &num/Lot quantity 2:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.invoice_date_lot2 || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'status_lot2'}
        name={'status_lot2'}
        html_for={'status_lot2'}
        label_name={'Status 2:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.status_lot2 || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />

      <GridInputs
        id={'invoice_date_lot3'}
        name={'invoice_date_lot3'}
        html_for={'invoice_date_lot3'}
        label_name={'Invoice Date &num/Lot quantity 3:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.invoice_date_lot3 || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'status_lot3'}
        name={'status_lot3'}
        html_for={'status_lot3'}
        label_name={'Status 3:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.status_lot3 || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleCustomerData}
        last_child={2}
        disabled={disable}
      />
      {!disable && (
        <Grid item xs={3}>
          <CustomUploadButton
            lable={'UPLOAD DOCUMENT'}
            multiple
            accept="image/png,image/jpeg,image/jpg,application/pdf"
            onChange={(e) => setFiles(e.target.files)}
            // disabled={disable}
          />{' '}
        </Grid>
      )}
      <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report || ''}
          onChange={(e) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>
      {!disable && (
        <div
          className="btn_save"
          style={{
            position: 'absolute',
            right: '140px',
            top: '-0.6px',
          }}
        >
          <SubmitButton
            label={`${id ? 'update' : 'submit'}`}
            type="button"
            onClick={handleSumbit}
          />
        </div>
      )}
      <DialogSubmit />
    </>
  );
};

export default Create;
