import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
}

const allmonth = [
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'january',
  'february',
  'march',
];

const TController = ({ moduleData, isView }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, isView }} />
        <TBody {...{ moduleData, isView, allmonth }} />
        <TFoot {...{ moduleData, isView, allmonth }} />
      </RenderTable>
    </Box>
  );
};

export default TController;
