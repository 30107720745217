import { Grid } from '@mui/material';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { cpImg } from '../../../img/imgConstant';
import formatDate from '../../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
}
const colSpanBodyHeader = (head: string) => {
  if (head === 'Characteristics') return 3;
  if (head === 'Methods') return 5;
  return 1;
};

const THead = ({ moduleData }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={15}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="Manufacturing  control plan"
      />
      <tr>
        {moduleData.part_type === 'prototype' ? (
          <td
            colSpan={2}
            style={{
              border: '1px solid black',
            }}
            align="left"
          >
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              ☑
            </span>
            <span>Proto Type</span>
          </td>
        ) : (
          <td colSpan={2} align="left" style={{ border: '1px solid black' }}>
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              □
            </span>
            <span>Proto Type</span>
          </td>
        )}

        {moduleData.part_type === 'pre-Launch' ? (
          <td
            colSpan={2}
            style={{
              border: '1px solid black',
            }}
            align="left"
          >
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              ☑
            </span>
            <span>Pre-Launch</span>
          </td>
        ) : (
          <td colSpan={2} align="left" style={{ border: '1px solid black' }}>
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              □
            </span>
            <span>Pre-Launch</span>
          </td>
        )}

        {moduleData.part_type === 'production' ? (
          <td
            colSpan={2}
            align="left"
            style={{
              border: '1px solid black',
            }}
          >
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              ☑
            </span>
            <span>Production</span>
          </td>
        ) : (
          <td colSpan={2} align="left" style={{ border: '1px solid black' }}>
            <span
              style={{
                fontSize: '2.5rem',
                marginRight: '0.6rem',
              }}
            >
              □
            </span>
            <span>Production</span>
          </td>
        )}
        <td colSpan={6}></td>
        <th colSpan={3} align="left">
          Rev. No. :- {moduleData?.revision_no}
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Control Plan No. :- {moduleData?.control_plan_no}
        </th>
        <th colSpan={3} align="left">
          Key Contact :- {moduleData?.part_id?.key_contact}
        </th>
        <th colSpan={3} align="left">
          Contact No. :{moduleData?.part_id?.phone}
        </th>
        <th colSpan={3} align="left">
          Date (Orig.) :-{formatDate(moduleData?.part_id?.date_orig)}
        </th>
        <th colSpan={2} align="left">
          REV. Date :- {formatDate(moduleData?.revision_date)}
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Part No. :- {moduleData?.part_id?.part_number}
        </th>
        <th colSpan={3} align="left">
          Latest Change Level:- {moduleData?.latest_change_level}
        </th>
        <th colSpan={3} align="left">
          Cross Functional Team :-{moduleData?.part_id?.core_team}
        </th>
        <th colSpan={5} align="left">
          Customer Engg. Approval Date (If Reqd.){' :-'}
          {formatDate(moduleData?.customer_engg_approval_date)}
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Part Name / Description :- {moduleData?.part_id?.part_name}
        </th>
        <th colSpan={6} align="left">
          Supplier / Plant Approval / Date:-{' '}
          {formatDate(moduleData?.supplier_approval_date)}
        </th>
        <th colSpan={5} align="left">
          Customer Quality Approval Date (If Reqd.):-
          {formatDate(moduleData?.customer_quality_approval_date)}
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Customer: {moduleData?.part_id?.customer_id?.customer_name}
        </th>
        <th colSpan={3} align="left">
          Supplier Code/DUNS No:-{' '}
          {moduleData?.part_id?.supplier_id?.supplier_code}
        </th>
        <th colSpan={3} align="left">
          Other Approval / Date (If Reqd.):-
          {formatDate(moduleData?.supplier_other_approval_date)}
        </th>
        <th colSpan={5} align="left">
          Other Approval Date (If Reqd.){':- '}
          {formatDate(moduleData?.customer_other_approval_date)}
        </th>
      </tr>
      <tr>
        {[
          'Part / Process No.',
          'Process Name Operation / Description',
          'M/C Device JIG Tool For Mfg.',
          'Characteristics',
          'Special Char. Class.',
          'Methods',
          'Error Proofing',
          'Control Method',
          'Reaction Plan',
          // 'Corrective Action',
          // 'Process Capability',
        ].map((head, index) => (
          <th
            colSpan={colSpanBodyHeader(head)}
            rowSpan={head === 'Characteristics' || head === 'Methods' ? 1 : 3}
            key={index + head}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr>
        {[
          'No.',
          'Product',
          'Process',
          'Product / Process Specification / Tolerance',
          'Evaluation Measurement Technique',
          'Sample',
        ].map((head, index) => (
          <th
            colSpan={head === 'Sample' ? 3 : 1}
            rowSpan={head === 'Sample' ? 1 : 2}
            key={index + head}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr>
        <th>Size</th>
        <th>Freq.</th>
        <th>Resp.</th>
      </tr>
    </thead>
  );
};

export default THead;
