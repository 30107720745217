import React, { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData &&
        moduleData?.c?.map((process: any, index: number) => (
          <tr>
            <td>{process?.s_no}</td>
            <td>{process?.heading}</td>
            <td style={{ minWidth: '300px', textAlign: 'center' }}>
              {process?.check_point}
            </td>
            <td style={{ minWidth: '200px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="specification"
                type="text"
                value={process.specification}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="obs"
                type="text"
                value={process.obs}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '300px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="result"
                type="text"
                value={process.result}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={process.remarks}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
