import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';
import { convToTime } from '../../../../../../utils/helpers';

interface IProps {
  reason: String;
}
const THead = ({ reason }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead style={{ background: '#9775fa', color: 'white' }}>
      <tr>
        <th
          colSpan={5}
          style={{ background: 'orange', color: 'black', fontWeight: 'bold' }}
        >
          {reason}
        </th>
      </tr>
      <tr>
        {['PART NO.', 'CUSTOMER', 'REJ. QTY.'].map((headingData) => (
          <th>{headingData}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
