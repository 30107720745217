interface IProps {
  moduleData: any;
}

const StyledCircle = ({ item }: { item: any }) => {
  let fontSize = '2rem';
  let icon = '';
  if (item.topic_type === 'L1') {
    fontSize = '3rem';
    icon = '◔';
  }
  if (item.topic_type === 'L2') {
    fontSize = '2rem';
    icon = '◑';
  }

  if (item.topic_type === 'L3') {
    fontSize = '3rem';
    icon = '◕';
  }
  if (item.topic_type === 'L4') {
    fontSize = '4rem';
    icon = '●';
  }
  return (
    <td align="center" height="50px" style={{ fontSize }}>
      {icon}
    </td>
  );
};
const TBody = ({ moduleData }: IProps) => {
  return (
    <tbody>
      {moduleData &&
        moduleData?.topics.map((item: any, index: number) => (
          <>
            <tr>
              <td align="center">{item.s_no}</td>
              <StyledCircle {...{ item }} />
              <td align="center" height="50px">
                {item.name}
              </td>
            </tr>
          </>
        ))}
    </tbody>
  );
};

export default TBody;
