import { useEffect, useState } from 'react';
import TController from './components/create/TController';
import axios from 'axios';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { Loader } from '../../../components/common/Loader';
import ModalCustom from '../../../components/common/Modal';
import PickStorePart from './components/PickStorePart';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import { Paper, Tab, Tabs } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';

let issueAddresses: string[] = [];
const wings = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

// picking color on the basis of partNO
const pickColor = (data: any, partNo: any) => {
  const colorsObj: any = {
    1: ['#51cf66'], // green
    2: ['#51cf66', '#fa5252'], // green,red
    3: ['#51cf66', '#ffe066', '#fa5252'], // green,yellow,red
    4: ['#51cf66', '#ffe066', '##ffc078', '#fa5252'], // green,yellow,orange,red
  };
  let totalLength = 0;
  // finding part length

  data.map((address: any) => {
    if (partNo) {
      if (
        address.store_part_id?.ri_part_id?.part_number
          .toLowerCase()
          .includes(partNo.toLowerCase())
      ) {
        totalLength += 1;
      }
    }
  });
  // finding part length
  let colorCounter = 0;
  let colors = colorsObj[totalLength];
  data.forEach((address: any) => {
    if (partNo && colors) {
      if (
        address.store_part_id?.ri_part_id?.part_number
          .toLowerCase()
          .includes(partNo.toLowerCase())
      ) {
        address.color = colors[colorCounter] || ''; // red
        colorCounter += 1;
      }
    }
  });
  return data;
};

const Create = () => {
  const [moduleData, setModuleData] = useState<any>([Array(26).fill([])]);
  const isView = useQuery('isView') === 'true' ? true : false;
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [partIdx, setPartIdx] = useState(0);
  const storeAssignId = useQuery('storeAssignId');
  const partNo = useQuery('partNo');
  const riPartId = useQuery('riPartId');
  const storeIssueId = useQuery('storeIssueId') || '';
  const [undoData, setUndoData] = useState<{ [key: string]: any }>({});
  const [assignData, setAssignData] = useState<any>(null);
  const [wingsName, setWingsName] = useState([]);
  const [filterValue, setFilterValue] = useState({ _id: riPartId });
  const [partData, setPartData] = useState([]);
  const navigate = useNavigate();
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this partWingData?',
  );
  const [DialogReset, confirmReset] = useConfirm(
    'Reset Data',
    'Are you sure you want to reset the data? This action will discard all unsaved changes and restore the previous state.',
  );

  useEffect(() => {
    // finding parts for auto complete
    axios
      .get('/api/riDepartment/riPart/riPartList/autoComplete')
      .then((res) => {
        if (res.data) {
          setPartData(res.data);
        }
      })
      .catch((err) => console.log(err));

    // finding wings tab
    axios
      .get(`/api/storeBinCreationRoute/wingName/forTab`)
      .then(async (res) => {
        const data = res.data;
        if (data) {
          setWingsName(data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  // finding store data
  useEffect(() => {
    issueAddresses = [];
    setIsLoading(true);
    axios
      .get(`/api/storeSetup?riPartId=${filterValue?._id || ''}`)
      .then(async (res) => {
        const data = res.data;
        if (data) {
          const modifiedData = pickColor(data, partNo);
          const wingFilterData = wings.map((wing) =>
            modifiedData.filter((address: any) => address.wing === wing),
          );
          setModuleData(wingFilterData);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [assignData, filterValue]);

  // finding data using storeAssginId and storeIssueId
  useEffect(() => {
    if (storeAssignId) {
      axios
        .get(`/api/storeReceiveRegister/${storeAssignId}`)
        .then((res) => {
          setAssignData(res.data);
        })
        .catch((err) => console.log(err));
    }
    if (storeIssueId) {
      axios
        .get(`/api/storeIssueRegister/${storeIssueId}`)
        .then((res) => {
          setAssignData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [filterValue]);

  // submit function
  const onSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (storeAssignId || storeIssueId) {
      if (assignData.qty !== 0) {
        return alert('quantity must be zero before updating?');
      }
      setIsLoading(true);
      await axios
        .put(`/api/storeSetup`, moduleData.flat())
        .then((res) => {
          if (res.data.status === 'success') {
            if (storeIssueId) {
              axios
                .patch(
                  `/api/storeSetup/${storeIssueId}?documentType=issueRegister`,
                  issueAddresses,
                )
                .then((res) => {
                  if (res.data) {
                    issueAddresses = [];
                    navigate(-1);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            if (storeAssignId) {
              axios
                .patch(
                  `/api/storeSetup/${storeAssignId}?documentType=assignRegister`,
                )
                .then((res) => {
                  if (res.data) {
                    navigate(-1);
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
            alert('Updated Successfully');
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
      return;
    }

    setIsLoading(true);
    await axios
      .put(`/api/storeSetup`, moduleData.flat())
      .then((res) => {
        if (res.data.status === 'success') {
          setIsLoading(false);
          alert('Updated Successfully');
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // assign part qty to store
  const assignRegister = (partWingData: any, index: number) => {
    if (assignData.qty <= 0) {
      return alert('Quantity limit exceed.');
    }
    if (!partWingData.store_part_id) {
      let values = [...moduleData];
      undoData[tabValue + '' + index] = {
        prevQty: 0,
        assignQty: assignData?.qty,
      };
      values[tabValue][index].qty = assignData.qty;
      values[tabValue][index].store_part_id = assignData.store_part_id?._id;
      values[tabValue][index].partNumber =
        assignData.store_part_id?.ri_part_id?.part_number;
      assignData.qty = 0;
      setModuleData(values);
      return;
    }
    if (assignData.store_part_id?._id === partWingData.store_part_id._id) {
      const maxBinQty = partWingData.store_part_id?.max_bin_qty;
      const remainingBinQty = maxBinQty - (partWingData?.qty || 0);
      if (remainingBinQty > assignData.qty || !maxBinQty) {
        let values = [...moduleData];
        undoData[tabValue + '' + index] = {
          prevQty: values[tabValue][index].qty,
          assignQty: assignData?.qty,
        };
        values[tabValue][index].qty =
          (values[tabValue][index].qty || 0) + assignData?.qty;
        assignData.qty = 0;
        setModuleData(values);
        return;
      }
      let values = [...moduleData];
      undoData[tabValue + '' + index] = {
        prevQty: values[tabValue][index].qty,
        assignQty: remainingBinQty,
      };
      values[tabValue][index].qty =
        (values[tabValue][index].qty || 0) + remainingBinQty;
      assignData.qty = assignData?.qty - remainingBinQty;
      setModuleData(values);
      return;
    }
    return alert('Part number not match.');
  };

  // issue part qty from store
  const issueRegister = (partWingData: any, index: number) => {
    if (assignData.qty <= 0) {
      return alert('Quantity limit exceed.');
    }
    if (isNaN(partWingData.qty) || partWingData.qty <= 0) {
      return alert('Address must have quantity.');
    }
    const issueQuantity = assignData?.qty;
    if (issueQuantity > partWingData.qty) {
      let values = [...moduleData];
      undoData[tabValue + '' + index] = {
        prevQty: values[tabValue][index].qty,
        assignQty: values[tabValue][index].qty,
      };
      assignData.qty = assignData.qty - values[tabValue][index].qty;
      values[tabValue][index].qty = 0;
      issueAddresses.push(partWingData.address);
      setModuleData(values);
      return;
    }
    let values = [...moduleData];
    undoData[tabValue + '' + index] = {
      prevQty: values[tabValue][index].qty,
      assignQty: assignData?.qty,
    };
    values[tabValue][index].qty = values[tabValue][index].qty - assignData.qty;
    issueAddresses.push(partWingData.address);
    assignData.qty = 0;
    setModuleData(values);
  };

  // undo the qty which is issue accidently
  const undoIssue = (index: number) => {
    const values = [...moduleData];
    let addressData = undoData[tabValue + '' + index];
    values[tabValue][index].qty = addressData.prevQty;
    assignData.qty += addressData.assignQty;
    delete undoData[tabValue + '' + index];
    // deleting issue addresses
    let partWingData = values[tabValue][index];
    if (partWingData.address) {
      const addressIndex = issueAddresses.indexOf(partWingData.address);
      if (addressIndex !== -1) {
        issueAddresses.splice(addressIndex, 1);
      }
    }
    setModuleData(values);
  };

  // undo the qty which is assign accidently
  const undoAssign = (index: number) => {
    const values = [...moduleData];
    let addressData = undoData[tabValue + '' + index];
    values[tabValue][index].qty = addressData.prevQty;
    if (typeof values[tabValue][index].store_part_id === 'string') {
      delete values[tabValue][index].store_part_id;
      delete values[tabValue][index].partNumber;
    }
    assignData.qty += addressData.assignQty;
    delete undoData[tabValue + '' + index];
    setModuleData(values);
  };
  return (
    <>
      <Loader loader={isLoading} />
      <Box>
        <ChildHeader text="STORE SETUP">
          {!isView && (
            <SubmitButton label="Update" onClick={() => onSumbit()} />
          )}
        </ChildHeader>
      </Box>
      {(storeAssignId || storeIssueId) && (
        <ChildHeader
          text={assignData && assignData.store_part_id?.ri_part_id?.part_number}
        >
          <p>QTY:{assignData?.qty}</p>
        </ChildHeader>
      )}
      <Paper elevation={2}>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          scrollButtons="auto"
          textColor="secondary"
          variant="fullWidth"
        >
          {wingsName.map((wing) => (
            <Tab label={`Wing ${wing}`} />
          ))}
        </Tabs>
      </Paper>

      <Box marginLeft={4} marginRight={4} marginTop={2} marginBottom={2}>
        <AutocompleteMuiCustom
          id="Part"
          label="Filter By Part"
          option_name="part_number"
          arrayofObj={partData}
          value={filterValue}
          onChange={async (e, value) => {
            await confirmReset();
            setFilterValue(value); // Update filter value only after submit is complete
          }}
        />
      </Box>
      <TController
        {...{
          moduleData,
          assignData,
          tabValue,
          isView,
          setIsOpenPopup,
          storeIssueId,
          setPartIdx,
          undoData,
          undoAssign,
          undoIssue,
          assignRegister,
          issueRegister,
        }}
      />
      <ModalCustom
        title="STORE PART LIST"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PickStorePart moduleData={moduleData[tabValue][partIdx]} />
      </ModalCustom>

      <DialogSubmit />
      <DialogReset />
    </>
  );
};

export default Create;
