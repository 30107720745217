export const export_1 = [
  {
    process_name: 'LOCKING & UNLOCKING',
    applicable_ppe: [
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },

      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
  {
    process_name: 'LEAKAGE TESTING ',
    applicable_ppe: [
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },

      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
  {
    process_name: 'LASER PUNCHING ',
    applicable_ppe: [
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'GOOGLES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'APRON',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },

      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
  {
    process_name: 'FINAL INSPECTION',
    applicable_ppe: [
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },

      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
];
