import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
} from 'chart.js';

import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
);

interface IProps {
  apiData: any;
  // year: number;
}
const ParetoChart = ({ apiData }: IProps) => {
  let cumulativeFrequency = 0;
  const cumulativePercentageData = apiData.map(
    (item: { percentage: number }) => {
      cumulativeFrequency += +item.percentage;
      return cumulativeFrequency;
    }
  );

  const chartData: any = {
    labels: apiData.map((item: { defectName: any }) => item.defectName),
    datasets: [
      {
        label: 'Rej. Qty.',
        data: apiData.map((item: { frequency: any }) => item.frequency),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Rej. Qty. Cumulative Percentage',
        data: cumulativePercentageData,
        type: 'line',
        borderColor: 'rgba(255, 99, 132, 0.6)',
        fill: false,
        yAxisID: 'y-axis-percentage',
      },
    ],
  };

  const chartOptions: any = {
    plugins: {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'Defect wise pareto analysis',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Defects',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Rej. Qty.',
        },
      },
      'y-axis-percentage': {
        beginAtZero: true,
        position: 'right',
        title: {
          display: true,
          text: 'Rej. Qty. Cumulative Percentage',
        },
        grid: {
          display: false,
        },
        max: 100,
      },
    },
  };

  return (
    <div>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default ParetoChart;
/*
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: 'Customer wise status 2023',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
        stepSize: 5,
      },
    },
  },
};

interface IProps {
  apiData: any;
  year: number;
}
const ParetoBarChart = ({ apiData, year }: IProps) => {
  const { dashBoardMonthCounts, totalOfMonth } = apiData;
  const labels = dashBoardMonthCounts.map((data: any) => data.customerName);
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const backgroundColors = [
    '#4c6ef5',
    '#fd7e14',
    '#868e96',
    '#fab005',
    '#82c91e',
    '#15aabf',
    '#12b886',
    '#7950f2',
    '#be4bdb',
    '#e64980',
    '#fa5252',
    '#40c057',
  ];
  const datasets = [];
  for (const monthIndex in totalOfMonth) {
    if (totalOfMonth[monthIndex] > 0) {
      datasets.push({
        label: `${monthNames[+monthIndex - 1]}-${year % 100}`,
        data: dashBoardMonthCounts.map((data: any) => data[+monthIndex]),
        backgroundColor: backgroundColors[+monthIndex - 1],
        borderWidth: 1,
        barThickness: 50,
        //barHeigth: 10,
        //barPercentage: 0.5,
        //categoryPercentage: 0.1,
      });
    }
  }

  const data = {
    labels,
    datasets: [{
      type: 'bar',
      label: 'Bar Dataset',
      data: [10, 20, 30, 40],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.2)'
    }, {
      type: 'line',
      label: 'Line Dataset',
      data: [50, 50, 50, 50],
      fill: false,
      borderColor: 'rgb(54, 162, 235)'
    }],
  };
  return <Bar options={options} data={data} />;
};

export default ParetoBarChart;
*/
