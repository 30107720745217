import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import moment from 'moment';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../../components/common/FormControlMui';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import useConfirm from '../../../../components/common/useConfirm';
interface Iprop {
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
  standardId?: string | object | null;
  setStandardId: React.Dispatch<React.SetStateAction<null>>;
}

export interface InspectionPart {
  _id?: string;
  part_id?: string;
  drawing_no: string | null;
  rev_no: string | null;
  description: string | null;
  ref_drg_no: string | null;
  createdAt?: Date;
  insp_standard_date: moment.Moment;
}

const CreateInspectionStandardPart = ({
  setIsOpenPopup,
  standardId,
  setStandardId,
}: Iprop) => {
  const [partData, setPartData] = useState<InspectionPart>({
    drawing_no: null,
    rev_no: null,
    description: null,
    ref_drg_no: null,
    insp_standard_date: moment(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [render, setRender] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'INSPECTION STANDARD',
    `Are you sure you want to ${standardId ? 'update' : 'save'} this part?`
  );

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPartData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (standardId) {
      axios
        .get(`/api/addPartRoute/${standardId}`)
        .then((res) => {
          setPartData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setPartData((prev) => prev);
    }
  }, [standardId]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    if (standardId) {
      await axios
        .put(`/api/inspectionStandard/${standardId}`, partData)
        .then((res) => {
          setIsLoading(false);
          setIsOpenPopup(false);
          setStandardId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      await axios
        .post('/api/inspectionStandard', partData)
        .then((res) => {
          setIsLoading(false);
          setIsOpenPopup(false);
          setStandardId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Box>
        <FormControlMui handleSubmit={onSubmit}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton
              label={`${standardId ? 'update' : 'submit'}`}
              type="submit"
              disabled={isLoading}
            />
          </div>
          <StyledField>
            <InputMuiCustom
              type="text"
              name="drawing_no"
              placeholder="Drawing Number"
              required={true}
              onChange={handlePartData}
              value={partData?.drawing_no || ''}
            />
            <InputMuiCustom
              type="text"
              name="description"
              required={true}
              placeholder="Description"
              onChange={handlePartData}
              value={partData?.description || ''}
            />
            <InputMuiCustom
              type="text"
              name="ref_drg_no"
              placeholder="Ref. Drg. No."
              onChange={handlePartData}
              value={partData?.ref_drg_no || ''}
            />
            <DatePickerMui
              label="Inspection Standard Date"
              onChange={(date) => {
                partData.insp_standard_date = date;
                setRender((prev) => !prev);
              }}
              value={moment(partData.insp_standard_date) || null}
            />
          </StyledField>
        </FormControlMui>
      </Box>
      <DialogSubmit />
    </>
  );
};

export default CreateInspectionStandardPart;
