export const titration_test = [
  {
    spec: 'Top up and water change',
    method: 'As Per WI',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    spec: 'pH Value 7~9',
    method: 'PH Paper',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    spec: '3%-5%',
    method: 'As Per WI',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    spec: 'Is Pink colour after sulfuric acid',
    method: 'As Per WI',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    spec: 'Is area Clean',
    method: 'Visual',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
];
