import { Grid, MenuItem } from '@mui/material';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import CustomSelect from '../../../../components/common/SelectField';
import { TableHeading } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import { groundFloor, firstFloor } from '../../helpers/operationListData';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import { convToTime } from '../../../../utils/helpers';
import moment from 'moment';
import { TimePicker } from '@mui/x-date-pickers';
import { calcRej } from '../../../rejectionNote/helpers/calcRej';
import formatDate from '../../../../components/common/formatDate';
import DatePickerMui from '../../../../components/common/DatePickerMui';

interface IProps {
  moduleData: any;
  machineData: any;
  isView: boolean;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
  setHeadingType: React.Dispatch<React.SetStateAction<string>>;
setRenderController:any
}
const operationListProcessData: { [key: string]: any[] } = {
  groundFloor: groundFloor,
  firstFloor: firstFloor,
};
const TBody1 = ({
  setIsOpenPopup,
  setPartIdx,
  setHeadingType,
  moduleData,
  isView,
  machineData,
setRenderController
}: IProps) => {
  const documentType = useQuery('documentType') || '';
  const [render, setRender] = useState(false);
  const test = useQuery('test') === 'true' ? true : false;
  // console.log(documentType);
  return (
    <>
      <TableHeading colSpan={test ? 25 : 21} heading="List of Operation" />
      {documentType === 'firstFloor' && (
        <>
          <tr style={{ textTransform: 'uppercase' }}>
            <th style={{ textTransform: 'uppercase' }}>S.NO</th>
            <th style={{ textTransform: 'uppercase', minWidth: '100px' }}>
              STAGE
            </th>
            <th style={{ textTransform: 'uppercase' }} colSpan={test ? 6 : 3}>
              PART NO.
            </th>
            <th style={{ textTransform: 'uppercase' }} colSpan={15}>
              PROCESS SELECTION
            </th>
          </tr>
          {moduleData.operation_list.map((item: any, index: number) => (
            <>
              <tr style={{ height: '33px' }}>
                <td rowSpan={3}>{index + 1}</td>
                <td rowSpan={3}>
                  <TableInput
                    isView={isView}
                    name="stage"
                    type="text"
                    value={item.stage}
                    onChange={(e) => {
                      item.stage = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td
                  rowSpan={3}
                  colSpan={test ? 6 : 3}
                  style={{ width: '280px', minWidth: '280px' }}
                >
                  {isView ? (
                    item?.part_id && item.part_id?.part_number
                  ) : (
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      alignItems={'center'}
                    >
                      <Grid item xs={10}>
                        <p
                          style={{
                            fontSize: '1.2rem',
                          }}
                        >
                          {typeof item?.part_id === 'object' &&
                          item?.part_id !== null
                            ? item.part_id?.part_number
                            : (item?.partNumber && item?.partNumber) || ''}
                          {!item?.part_id && 'Pick Part No.'}
                        </p>
                      </Grid>

                      {item.part_id ? (
                        <Grid item xs={2}>
                          <RemoveIconButton
                            tooltipTitle="remove part"
                            onClick={() => {
                              item.part_id = null;
                              item.partNumber = null;
                              item.partName = null;
                              setRenderController((prev:any) => !prev);
                            }}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={2}>
                          <AddIconButton
                            tooltipTitle="add part"
                            onClick={() => {
                              setIsOpenPopup(true);
                              setPartIdx(index);
                              setHeadingType('operation_list');
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                </td>
                {[
                  'drop_1',
                  'drop_2',
                  'drop_3',
                  'drop_4',
                  'drop_5',
                  'drop_6',
                  'drop_7',
                  'drop_8',
                  'drop_9',
                  'drop_10',
                  'drop_11',
                  'drop_12',
                  'drop_13',
                  'drop_14',
                  'drop_15',
                ].map((head, index) => (
                  <td style={{ textTransform: 'uppercase' }}>
                    {isView ? (
                      item?.operation_drops[index]
                    ) : (
                      <CustomSelect
                        name={head}
                        value={item?.operation_drops[index] || ''}
                        onChange={(e) => {
                          item.operation_drops[index] = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      >
                        <MenuItem value="">None</MenuItem>
                        {operationListProcessData[documentType].map(
                          (reason: string) => (
                            <MenuItem value={reason}>{reason}</MenuItem>
                          ),
                        )}
                      </CustomSelect>
                    )}
                  </td>
                ))}
              </tr>
              {/* {documentType === 'groundFloor' && (
                <tr>
                  {[
                    'drop_1',
                    'drop_2',
                    'drop_3',
                    'drop_4',
                    'drop_5',
                    'drop_6',
                    'drop_7',
                    'drop_8',
                    'drop_9',
                    'drop_10',
                    'drop_11',
                    'drop_12',
                    'drop_13',
                    'drop_14',
                    'drop_15',
                  ].map((key, index) => (
                    <td style={{ minWidth: '150px', textAlign: 'center' }}>
                      <TableInput
                        isView={isView}
                        label="Machine Name"
                        name={key}
                        type="text"
                        value={item.machine_values[index]}
                        onChange={(e) => {
                          item.machine_values[index] = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                  ))}
                </tr>
              )} */}
              <tr>
                {[
                  'drop_1',
                  'drop_2',
                  'drop_3',
                  'drop_4',
                  'drop_5',
                  'drop_6',
                  'drop_7',
                  'drop_8',
                  'drop_9',
                  'drop_10',
                  'drop_11',
                  'drop_12',
                  'drop_13',
                  'drop_14',
                  'drop_15',
                ].map((key, index) => (
                  <td style={{ minWidth: '150px', textAlign: 'center' }}>
                    <TableInput
                      isView={isView}
                      name={key}
                      label={'Operator Name'}
                      type="text"
                      value={item.operator_values[index]}
                      onChange={(e) => {
                        item.operator_values[index] = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    />
                  </td>
                ))}
              </tr>
              <tr>
                {[
                  'drop_1',
                  'drop_2',
                  'drop_3',
                  'drop_4',
                  'drop_5',
                  'drop_6',
                  'drop_7',
                  'drop_8',
                  'drop_9',
                  'drop_10',
                  'drop_11',
                  'drop_12',
                  'drop_13',
                  'drop_14',
                  'drop_15',
                ].map((key, index) => (
                  <td style={{ minWidth: '150px', textAlign: 'center' }}>
                    {isView ? (
                      formatDate(item.date)
                    ) : (
                      <DatePickerMui
                        value={
                          item.date ? moment(item.date[index] || null) : null
                        }
                        onChange={(date) => {
                          if (!item.date) {
                            item.date = [];
                          }
                          item.date[index] = date;
                          setRender((prev) => !prev);
                        }}
                      />
                    )}
                  </td>
                ))}
              </tr>
            </>
          ))}
        </>
      )}
      {documentType === 'groundFloor' && (
        <>
          <tr>
            {[
              'S. No',
              'Part No.',
              'Customer Name',
              'Machine Name',
              'Process Name',
              'Operator Name',
              'Time',
              'Total Lot Qty',
              'OK Qty',
              'Rej Qty',
              'Batch',
              'Quality',
              'Reason',
            ].map((heading, index) => (
              <th
                key={heading}
                colSpan={
                  index === 4 || index === 5 || index === 6 || index === 12
                    ? 2
                    : 1
                }
                rowSpan={index === 6 ? 1 : 2}
              >
                {heading}
              </th>
            ))}
            <th colSpan={3} rowSpan={2}>Remarks</th>
          </tr>
          <tr>
            <th>Start</th>
            <th>Stop</th>
          </tr>
          {moduleData.operation_list.map((item: any, index: number) => (
            <>
              <tr style={{ height: '33px' }}>
                <td>{index + 1}</td>

                <td style={{ width: '280px', minWidth: '280px' }}>
                  {isView ? (
                    item?.part_id && item.part_id?.part_number
                  ) : (
                    <Grid
                      container
                      xs={12}
                      direction="row"
                      alignItems={'center'}
                    >
                      <Grid item xs={10}>
                        <p
                          style={{
                            fontSize: '1.2rem',
                          }}
                        >
                          {typeof item?.part_id === 'object' &&
                          item?.part_id !== null
                            ? item.part_id?.part_number
                            : (item?.partNumber && item?.partNumber) || ''}
                          {!item?.part_id && 'Pick Part No.'}
                        </p>
                      </Grid>

                      {item.part_id ? (
                        <Grid item xs={2}>
                          <RemoveIconButton
                            tooltipTitle="remove part"
                            onClick={() => {
                              item.part_id = null;
                              item.partNumber = null;
                              item.partName = null;
                              item.customerName = null;
                              setRender((prev) => !prev);
                            }}
                          />
                        </Grid>
                      ) : (
                        <Grid item xs={2}>
                          <AddIconButton
                            tooltipTitle="add part"
                            onClick={() => {
                              setIsOpenPopup(true);
                              setPartIdx(index);
                              setHeadingType('operation_list');
                            }}
                          />
                        </Grid>
                      )}
                    </Grid>
                  )}
                </td>
                <td>
                  {item?.customerName ||
                    item?.part_id?.customer_id?.customer_name}
                </td>
                <td colSpan={1}>
                  {isView ? (
                    item.machine_id?.machine_name
                  ) : (
                    <AutocompleteMuiCustom
                      id="machine_name"
                      option_name="machine_name"
                      arrayofObj={machineData}
                      value={item.machine_autocomplete || item.machine_id || ''}
                      onChange={(e, value) => {
                        item.machine_id = value?._id;
                        item.machine_autocomplete = value;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </td>
                <td colSpan={2} style={{ textTransform: 'uppercase' }}>
                  {isView ? (
                    item?.process_name
                  ) : (
                    <CustomSelect
                      name={'process_name'}
                      value={item?.process_name || ''}
                      onChange={(e) => {
                        item.process_name = e.target.value;
                        setRender((prev) => !prev);
                      }}
                    >
                      <MenuItem value="">None</MenuItem>
                      {operationListProcessData[documentType].map(
                        (reason: string) => (
                          <MenuItem value={reason}>{reason}</MenuItem>
                        ),
                      )}
                    </CustomSelect>
                  )}
                </td>
                <td colSpan={2} style={{ textAlign: 'center' }}>
                  <TableInput
                    isView={isView}
                    name="operator_name"
                    label={''}
                    type="text"
                    value={item.operator_name}
                    onChange={(e) => {
                      item.operator_name = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td style={{ minWidth: '150px' }}>
                  {isView ? (
                    convToTime(item.start_time)
                  ) : (
                    <TimePicker
                      value={moment(item.start_time || '')}
                      onChange={(date) => {
                        item.start_time = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </td>
                <td style={{ minWidth: '150px' }}>
                  {isView ? (
                    convToTime(item.stop_time)
                  ) : (
                    <TimePicker
                      value={moment(item.stop_time || '')}
                      onChange={(date) => {
                        item.stop_time = date;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </td>
                <td
                  colSpan={1}
                  style={{ textAlign: 'center', minWidth: '150px' }}
                >
                  <TableInput
                    isView={isView}
                    name="total_lot_qty"
                    label={''}
                    type="number"
                    value={item.total_lot_qty}
                    onChange={(e) => {
                      item.total_lot_qty = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td
                  colSpan={1}
                  style={{ textAlign: 'center', minWidth: '150px' }}
                >
                  <TableInput
                    isView={isView}
                    name="ok_qty"
                    label={''}
                    type="number"
                    value={item.ok_qty}
                    onChange={(e) => {
                      item.ok_qty = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td style={{ textAlign: 'center', minWidth: '150px' }}>
                  {calcRej(item.total_lot_qty, item.ok_qty)}
                </td>
                <td
                  colSpan={1}
                  style={{ textAlign: 'center', minWidth: '150px' }}
                >
                  <TableInput
                    isView={isView}
                    name="batch"
                    label={''}
                    type="text"
                    value={item.batch}
                    onChange={(e) => {
                      item.batch = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td
                  colSpan={1}
                  style={{ textAlign: 'center', minWidth: '150px' }}
                >
                  <TableInput
                    isView={isView}
                    name="quality"
                    label={''}
                    type="text"
                    value={item.quality}
                    onChange={(e) => {
                      item.quality = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td
                  colSpan={2}
                  style={{ textAlign: 'center', minWidth: '150px' }}
                >
                  <TableInput
                    isView={isView}
                    name="reason"
                    label={''}
                    type="text"
                    value={item.reason}
                    onChange={(e) => {
                      item.reason = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                <td
                  colSpan={3}
                  style={{ textAlign: 'center', minWidth: '150px' }}
                >
                  <TableInput
                    isView={isView}
                    name="remarks"
                    label={''}
                    type="text"
                    value={item.remarks}
                    onChange={(e) => {
                      item.remarks = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              </tr>
            </>
          ))}
        </>
      )}
    </>
  );
};

export default TBody1;
