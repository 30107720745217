import React, { useEffect, useState } from 'react';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../components/common/useConfirm';
import GridDatePicker from '../../../../components/common/inputs/GridDatePicker';
import { apiConstant } from './constant';
import moment from 'moment';
import AutocompleteGridmui from '../../../../components/common/autocomplete/AutocompleteGridmui';

const initialData = {
  list: [
    {
      item: 'No. of working days',
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Planned Production Time per shift (hrs)',
      april: 8.5,
      may: 8.5,
      june: 8.5,
      july: 8.5,
      august: 8.5,
      september: 8.5,
      october: 8.5,
      november: 8.5,
      december: 8.5,
      january: 8.5,
      february: 8.5,
      march: 8.5,
      remarks: '',
    },
    {
      item: 'No. of Shifts planned/ day',
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Planned Production Time per machine/ day',
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Total No. of Machines',
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Planned Production Time or Run Time (hrs)',
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Loss Time (Lunch+ Tea+ B/D)',
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Total Operation Time (hrs)',
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Total No. of Failures',
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'MTBF',
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
  ],
  target_april: 0,
  target_may: 0,
  target_june: 0,
  target_july: 0,
  target_august: 0,
  target_september: 0,
  target_october: 0,
  target_november: 0,
  target_december: 0,
  target_january: 0,
  target_february: 0,
  target_march: 0,
};

const ModalInput = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [moduleData, setMoudleData] = useState<any>(initialData);
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);

  const [customer, setCustomer] = useState([]);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update MTBF' : 'Create MTBF'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/${apiConstant.getPlanByid}/${id}`)
        .then((res) => setMoudleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  // useEffect(() => {
  //   axios
  //     .get('/api/customerRoutes/customerList/customerForAutoComplete')
  //     .then((res) => {
  //       setCustomer(res.data);
  //     })
  //     .catch((err) => console.log(err));
  // }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setMoudleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/${apiConstant.getPlanByid}/${id}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId('');
        })
        .catch((err) => console.log(err));
    } else {
      console.log('hhhhhhhhhhhhhhhhh', moduleData);
      await axios
        .post(`/api/${apiConstant.createPlanByid}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId('');
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };
  //console.log('ggggggggggggggggggggggggg', tableData);
  return (
    <>
      <GridInputs
        id={'title'}
        name={'title'}
        html_for={'title'}
        label_name={'Title'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.title || ''}
        onChange={handleData}
      />
      <GridDatePicker
        label_name="Date"
        html_for={'date_range'}
        views={['year']}
        value={moduleData?.date_range || null}
        onChange={(date) => {
          moduleData.date_range = moment(date._d).add(10, 'hours');
          setRender((prev) => !prev);
        }}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default ModalInput;
