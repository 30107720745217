import axios from 'axios';
import React, { useEffect, useState } from 'react';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import { apiConstant } from '../constant';

const PercentageModal = ({ item, setOpenPercModal }: any) => {
  const [render, setRender] = useState(false);
  const [moduleData, setModuleData] = useState<any>({
    ...item,
  });

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    if (item?._id) {
      await axios
        .put(
          `/api/${apiConstant.checkListStatus}/${item.docId}/${item._id}/${item.month}`,
          moduleData
        )
        .then((res) => {
          setOpenPercModal(false);
          alert('Succesfully updated');
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'customer_satisfaction_percentage'}
        name={'customer_satisfaction_percentage'}
        html_for={'customer_satisfaction_percentage'}
        label_name={'Customer Satisfaction Percentage:'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleData?.customer_satisfaction_percentage || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'target_percentage'}
        name={'target_percentage'}
        html_for={'target_percentage'}
        label_name={'Target Percentage:dd'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleData?.target_percentage || ''}
        onChange={handleData}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
    </>
  );
};

export default PercentageModal;
