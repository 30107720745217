import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';

import { Bar, Line } from 'react-chartjs-2';
import { GraphHead } from '../../../../../../components/common/GraphHeading';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  LinearScale
);

interface IProps {
  apiData: any;
  year: any;
  month: number;
  target: number;
}
const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const ParetoChart = ({ apiData, year, month, target }: IProps) => {
  const chartData: any = {
    labels:
      apiData &&
      apiData.length > 0 &&
      apiData?.map((item: any) => item.vender_name),
    datasets: [
      {
        label: 'PPM',
        data:
          apiData &&
          apiData.length > 0 &&
          apiData?.map((item: any) => item.ppm_qty),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Target PPM : 2000',
        data:
          apiData &&
          apiData.length > 0 &&
          new Array(apiData.length).fill(target),
        type: 'line',
        borderColor: 'rgba(255, 99, 132, 0.6)',
        fill: false,
      },
    ],
  };

  const chartOptions: any = {
    plugins: {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: ``,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Vendor Name',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'PPM',
        },
      },
    },
  };
  const title =
    // month <= 8
    //   ?
    `SUPPLIER PPM - ${monthNames[month]} ${year}`;
  // : `SUPPLIER PPM - ${monthNames[month]} ${year + 1}`;
  return (
    <div>
      <GraphHead style={{ width: '80vw', margin: '14rem' }}>
        <p>{title}</p>
        <Bar data={chartData} options={chartOptions} />
      </GraphHead>
    </div>
  );
};

export default ParetoChart;
