import { useState } from 'react';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import useConfirm from '../../../../components/common/useConfirm';
import InteractiveIcon from '../../../../components/common/InteractiveIcon';

interface IProps {
  item: any;
  isView: boolean;
  index: number;
  moduleData: any;
}
const allmonth = [
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'january',
  'february',
  'march',
];
const TBodyRight = ({ item, isView, index, moduleData }: IProps) => {
  const [render, setRender] = useState(false);
  const [DialogisCheck, setDialogisCheck] = useConfirm(
    'ADD',
    'Are you sure you want to ADD PLAN?'
  );
  const [DialogRemoveCheck, setDialogRemoveCheck] = useConfirm(
    'Remove',
    'Are you sure you want remove you cant roll back?'
  );

  const handleDateClick = async (date: any, month: any, idx: any) => {
    if (date) {
      item[month].p = true;
      item[month].created_date = date;
      setRender((prev) => !prev);
    } else {
      item[month].p = false;
      item[month].created_date = date;
      item[month].isTask = false;
      delete item[month].doneDate;
      setRender((prev) => !prev);
    }
  };
  const handleDoubleClick = async (event: any, month: string, idx: any) => {
    if (event.detail === 2) {
      if (item[month].p) {
        const ans = await setDialogRemoveCheck();
        if (!ans) return;
        item[month].p = false;
        setRender((prev) => !prev);
      } else {
        const ans = await setDialogisCheck();
        if (!ans) return;
        item[month].p = true;
        setRender((prev) => !prev);
      }
    }
  };

  return (
    <>
      {/* {[
        ['april', 4, new Date(moduleData.date_range).getFullYear()],
        ['may', 5, new Date(moduleData.date_range).getFullYear()],
        ['june', 6, new Date(moduleData.date_range).getFullYear()],
        ['july', 7, new Date(moduleData.date_range).getFullYear()],
        ['august', 8, new Date(moduleData.date_range).getFullYear()],
        ['september', 9, new Date(moduleData.date_range).getFullYear()],
        ['october', 10, new Date(moduleData.date_range).getFullYear()],
        ['november', 11, new Date(moduleData.date_range).getFullYear()],
        ['december', 12, new Date(moduleData.date_range).getFullYear()],
        ['january', 1, new Date(moduleData.date_range).getFullYear() + 1],
        ['february', 2, new Date(moduleData.date_range).getFullYear() + 1],
        ['march', 3, new Date(moduleData.date_range).getFullYear() + 1],
      ].map((month: any) => (
        <td
          style={{
            padding: '0px',
            margin: '0px',
          backgroundColor: `${item[month[0]]?.p ? '#ffd43b' : '#f1f3f5'}`,
          }}
        >
            <DatePickerMui
              label=""
              sx={{
                width: '150px',
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  border: 'none',
                },
              }}
              minDate={moment(new Date(`${month[1]}/01/${month[2]}`))}
              maxDate={moment(
                new Date(
                  `${month[1]}/${new Date(+month[2], +month[1], 0).getDate()}/${
                    month[2]
                  }`,
                ),
              )}
              value={moment(item[month[0]]?.created_date || null)}
              onChange={(date: any) => {
                handleDateClick(date, month[0], index);
              }}
            />
        </td>
      ))} */}
      {allmonth.map((month: string) => (
        <td
          style={{
            padding: '0px',
            margin: '0px',
            backgroundColor: `${item[month]?.p ? '#ffd43b' : '#f1f3f5'}`,
          }}
          onClick={(e) => handleDoubleClick(e, month, index)}
        >
          <InteractiveIcon isYellow={item[month].p}>
            {item[month]?.p && 'P'}{' '}
          </InteractiveIcon>
        </td>
      ))}
      <DialogisCheck isSubmitContent={false} />
      <DialogRemoveCheck isSubmitContent={false} />
    </>
  );
};

export default TBodyRight;
