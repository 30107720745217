import { Box } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PersitedDatePicker } from '../../../components/common';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import OeeList from './components/tableList/OeeCalculationList';

const List = () => {
  const date = useQuery('date');
  const [tableData, setTableData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // fetch data here
    axios
      .get(`/api/oeeCalculation/monthly/${date}`)
      .then((res) => {
        if (res.data) setTableData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render, date]);

  const deleteDocument = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?'
    );
    if (isDeleted) {
      axios
        .delete(`/api/oeeCalculation/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  return (
    <Box>
      <ChildHeader text={'OEE CALCULATION'}>
        <AddButton
          label="New OEE Calculation"
          onClick={() => navigate(`/common/createOeeCalculation`)}
        />
      </ChildHeader>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box>
      <OeeList
        {...{
          deleteDocument,
          tableData,
        }}
      />
    </Box>
  );
};

export default List;
