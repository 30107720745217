import moment from 'moment';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { useQuery } from '../../../../../hooks/UseQuery';
import InputMuiCustom from '../../../../../components/common/inputs/InputMuiCustom';
import { TableInput } from '../../../../../components/common';
import { useState } from 'react';

interface IProps {
  moduleData: any;
  isView: boolean;
  tabValue: any;
}

const theadData = [
  'Sr. No.',
  'Date of Diesel Receiving',
  'Received Qty.',
  'Remark',
  'Delete',
];
const OthertheadData = [
  'Sr. No.',
  'Date of Issue',
  'Consumed Qty.',
  'DG Detail',
  'Remark',
  'Delete',
];

const THead = ({ moduleData, isView, tabValue }: IProps) => {
  const date = useQuery('annual_date');
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={tabValue === 0 ? 5 : 6}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['', '', '']}
        heading={`Diesel Consumption Monitoring Sheet - (${date})`}
      />
      <tr>
        <th colSpan={tabValue === 0 ? 5 : 6} align="left">
          For the Month of : - {date}
        </th>
      </tr>
      <tr>
        <th colSpan={tabValue === 0 ? 3 : 3} align="left">
          Minimum Qty. to be Maintained (litre)
          <TableInput
            isView={isView}
            name="min_qty"
            type="number"
            value={moduleData.min_qty}
            onChange={(e) => {
              // handleChangeModuleData(e, process);
              moduleData.min_qty = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th colSpan={tabValue === 0 ? 2 : 3} align="left">
          Maximum Qty. to be Maintained (litre)
          <TableInput
            isView={isView}
            name="min_qty"
            type="number"
            value={moduleData.max_qty}
            onChange={(e) => {
              // handleChangeModuleData(e, process);
              moduleData.max_qty = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={tabValue === 0 ? 2 : 2} align="left">
          Total Diesel Received = {moduleData.total_diesel_received}
        </th>
        <th colSpan={tabValue === 0 ? 1 : 2} align="left">
          Total Diesel Consumed = {moduleData.total_diesel_consumed}
        </th>
        <th
          colSpan={tabValue === 0 ? 2 : 3}
          align="left"
          style={
            moduleData.total_diesel_remaining < moduleData.min_qty ||
            moduleData.total_diesel_remaining > moduleData.max_qty
              ? { background: 'red' }
              : { background: 'green' }
          }
        >
          Total Diesel Remaing = {moduleData.total_diesel_remaining}
        </th>
      </tr>
      <tr>
        {tabValue === 0 &&
          theadData.map((heading, index) => (
            <th key={heading + index} rowSpan={1} colSpan={1}>
              {heading}
            </th>
          ))}
        {tabValue === 1 &&
          OthertheadData.map((heading, index) => (
            <th key={heading + index} rowSpan={1} colSpan={1}>
              {heading}
            </th>
          ))}
      </tr>
    </thead>
  );
};

export default THead;
