import { useState } from 'react';
import TBodyRow from './TBodyRow';
import { ModuleDataHandler } from '../helpers/handleReportCalc ';

interface IProps {
  moduleData: any;
  handleDelete: any;
  setParentRender: any;
  isView: boolean;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
}

const TBody = ({
  moduleData,
  isView,
  handleDelete,
  setIsOpenPopup,
  setPartIdx,
  setParentRender,
}: IProps) => {
  const [render, setRender] = useState(false);
  const dataHandler = new ModuleDataHandler(moduleData);

  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <TBodyRow
          {...{
            moduleData,
            item,
            index,
            setRender,
            isView,
            handleDelete,
            setIsOpenPopup,
            setPartIdx,
            dataHandler,
            setParentRender,
          }}
          key={`${index}-table-waterfall`}
        />
      ))}
    </tbody>
  );
};

export default TBody;
