import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../hooks/UseQuery';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const year = useQuery('year');

  return (
    <thead>
      <TableCompHead
        colSpan={13 + createConstant.extracolSpan}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dt']}
        values={['FM/XLV/RI/017', '00', '']}
        heading={`${createConstant.theadHeader} (YEAR : ${year})`}
      />

      {[
        'S. No.',
        'Part Name',
        'Part No.',
        'Customer Name',
        'Raw Material Specifications',
      ].map((el, index) => (
        <th
          key={`${index}_head`}
          align="center"
          style={{
            textAlign: 'center',
            ...style1,
            minWidth: `${
              (el === 'INSTRUMENTS/GAUGE' && '250px') ||
              (el === 'S. No.' && '50px') ||
              style1.minWidth
            }`,
          }}
        >
          {el}
        </th>
      ))}

      {[
        'JAN',
        'FEB',
        'MAR',
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
      ].map((el, index) => (
        <th
          key={`${index}_headcol`}
          align="center"
          style={{ textAlign: 'center', ...style2 }}
        >
          {el}-{year?.substring(year.length - 2)}
        </th>
      ))}
      <th style={{ textAlign: 'center', ...style1 }}>Remarks</th>
    </thead>
  );
};

export default THead;
