import { useEffect, useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const month = [
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec',
  'jan',
  'feb',
  'mar',
];
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const [vendorData, setVendor] = useState({});

  useEffect(() => {
    axios
      .get('/api/vendorRoute/vendor/forAutoComplete')
      .then((res) => {
        if (res.data) {
          setVendor(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const handelDeleteRows = async (index: number) => {
    const ans = window.confirm('Are you sure you want to delete this row');
    if (!ans) return;
    moduleData?.list.splice(index, 1);
    setRender((prev) => !prev);
  };

  const handleChange = (e: any, item: any, key: any) => {
    const { name, value } = e.target;
    item[key][name] = value;
    item[key].ok_qty = item[key].inco_qty - item[key].rej_qty;
    item[key].ppm_qty = +(
      (item[key].rej_qty * 1000000) /
      item[key].inco_qty
    ).toFixed(2);
    item.total_inco_qty = month.reduce(
      (curr, monthName) => curr + +item[monthName].inco_qty,
      0
    );
    item.total_rej_qty = month.reduce(
      (curr, monthName) => curr + +item[monthName].rej_qty,
      0
    );
    item.total_ok_qty = item.total_inco_qty - item.total_rej_qty;
    item.total_ppm_qty = +(
      (item.total_rej_qty * 1000000) /
      item.total_inco_qty
    ).toFixed(2);

    setRender((prev) => !prev);
  };
  const getMonthQty = (monthName: any, key1: any, key2: any) => {
    moduleData[monthName][key1] =
      moduleData?.list?.reduce(
        (sum: any, item: any) => sum + +item?.[monthName]?.[key2],
        0
      ) || 0;
    return moduleData[monthName][key1];
  };
  const getMonthPpmQty = (monthName: any, key: any) => {
    moduleData[monthName][key] = +(
      (getMonthQty(monthName, 'total_rej_qty', 'rej_qty') * 1000000) /
      getMonthQty(monthName, 'total_inco_qty', 'inco_qty')
    ).toFixed(2);
    return moduleData[monthName][key];
  };
  const getTotalQtyNew = (key: any) => {
    moduleData[key] =
      moduleData?.list?.reduce((sum: any, item: any) => sum + +item[key], 0) ||
      0;
    return moduleData[key];
  };
  const getTotalPPMQtyNew = (key: any) => {
    moduleData[key] = +(
      (getTotalQtyNew('total_rej_qty') * 1000000) /
      getTotalQtyNew('total_inco_qty')
    ).toFixed(2);
    return moduleData[key];
  };

  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <>
          <tr key={`${index}_table_row`}>
            <td>{index + 1}</td>
            <td style={{ minWidth: '200px' }}>
              {isView ? (
                item?.vendor_id?.vender_name
              ) : (
                <AutocompleteMuiCustom
                  id="Vendor"
                  label=""
                  option_name="vender_name"
                  arrayofObj={vendorData}
                  value={item.vendor_id_temp || item.vendor_id || ''}
                  onChange={(e, value) => {
                    item.vendor_id = value?._id;
                    item.vendor_id_temp = value;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ minWidth: '200px' }}>
              {item?.vendor_id_temp?.vender_code ||
                item?.vendor_id?.vender_code}
            </td>
            {month.map((objKey, ind) => {
              return (
                <>
                  <th style={{ minWidth: '100px' }}>
                    <TableInput
                      isView={isView}
                      name="inco_qty"
                      type="number"
                      value={item[objKey].inco_qty}
                      onChange={(e) => {
                        handleChange(e, item, objKey);
                        // item.remarks = e.target.value;
                        // setRender((prev) => !prev);
                      }}
                    />
                  </th>
                  <th style={{ minWidth: '100px' }}>
                    <TableInput
                      isView={isView}
                      name="rej_qty"
                      type="number"
                      value={item[objKey].rej_qty}
                      onChange={(e) => {
                        handleChange(e, item, objKey);
                      }}
                    />
                  </th>
                  <th style={{ minWidth: '100px' }}>{item[objKey].ok_qty}</th>
                  <th style={{ minWidth: '100px' }}>{item[objKey].ppm_qty}</th>
                </>
              );
            })}
            <th style={{ minWidth: '100px' }}>{item.total_inco_qty}</th>
            <th style={{ minWidth: '100px' }}>{item.total_rej_qty}</th>
            <th style={{ minWidth: '100px' }}>{item.total_ok_qty}</th>
            <th style={{ minWidth: '100px' }}>{item.total_ppm_qty}</th>
            {!isView && (
              <td rowSpan={1} style={{ minWidth: '50px' }}>
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handelDeleteRows(index)}
                />
              </td>
            )}
          </tr>
        </>
      ))}
      <tr>
        <th style={{ minWidth: '100px' }}></th>
        <th style={{ minWidth: '200px' }}></th>
        <th style={{ minWidth: '200px' }}>Total-{'>'}</th>
        {moduleData?.list &&
          month.map((monthName: string, index: number) => {
            return (
              <>
                <th style={{ minWidth: '100px' }}>
                  {getMonthQty(monthName, 'total_inco_qty', 'inco_qty')}
                </th>
                <th style={{ minWidth: '100px' }}>
                  {getMonthQty(monthName, 'total_rej_qty', 'rej_qty')}
                </th>
                <th style={{ minWidth: '100px' }}>
                  {getMonthQty(monthName, 'total_ok_qty', 'ok_qty')}
                </th>
                <th style={{ minWidth: '100px' }}>
                  {getMonthPpmQty(monthName, 'total_ppm_qty')}
                </th>
              </>
            );
          })}
        <th style={{ minWidth: '100px' }}>
          {getTotalQtyNew('total_inco_qty')}
        </th>
        <th style={{ minWidth: '100px' }}>{getTotalQtyNew('total_rej_qty')}</th>
        <th style={{ minWidth: '100px' }}>{getTotalQtyNew('total_ok_qty')}</th>
        <th style={{ minWidth: '100px' }}>
          {getTotalPPMQtyNew('total_ppm_qty')}
        </th>
      </tr>
    </tbody>
  );
};

export default TBody;
