import { BsJournalBookmarkFill } from 'react-icons/bs';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
const tiles = [
  {
    sNo: 1,
    link: '/common/productionLogGroundFloorHome?documentType=groundFloor',
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="black" />,
    p: 'GROUND',
    span: 'FLOOR',
    isIcon: true,
  },
  {
    sNo: 2,
    link: `/common/productionLogFirstFloorHome?documentType=firstFloor`,
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="black" />,
    p: 'FIRST',
    span: 'FLOOR',
    isIcon: true,
  },
  {
    sNo: 2,
    link: `/common/productionLogBookList?documentType=firstFloor&test=true`,
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="black" />,
    p: 'FIRST',
    span: 'FLOOR TEST',
    isIcon: true,
  },
];

const ProductionLogBookHome = () => {
  return (
    <>
      <ChildHeader text="PRODUCTION LOG BOOK" />
      <RenderTiles height="100vh" tilesObj={tiles} />
    </>
  );
};

export default ProductionLogBookHome;
