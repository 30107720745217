import React, { useState } from 'react';
import { ISpec } from '../../../helpers/Interface';
import { Divider, Grid, MenuItem, Stack } from '@mui/material';
import InputMuiCustom from '../../../../../components/common/inputs/InputMuiCustom';
import CustomSelect from '../../../../../components/common/SelectField';
import Button from '@mui/material/Button';
import criticalParameters from '../../../img/critical.jpg';
import significantParameters from '../../../img/significant.jpg';
import PIS from '../../../img/pis.jpg';
// images
import critical from '../../../img/cp1.png';
import major from '../../../img/cp2.png';
import fit from '../../../img/cp3.png';
import error from '../../../img/cp4.png';

interface Iprop {
  specModalData: ISpec;
  openModal: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  handleSpec: (e: any, specs: ISpec) => void;
  handleAppearance: (e: any, spec: ISpec) => void;
}
const SpecsInputs = (props: Iprop) => {
  const {
    specModalData,
    openModal,
    setOpenModal,
    handleSpec,
    handleAppearance,
  } = props;
  const [render, setRender] = useState<boolean>(false);

  return (
    <>
      <Grid
        container
        gap={3}
        columnGap={4}
        xs={12}
        sx={{ width: '80vw', alignItems: 'center', marginLeft: '4rem' }}
      >
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="machine"
            placeholder="M/C Device JIG Tool For Mfg." // machine tool
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.machine}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="char_product"
            placeholder="Product " // char product
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.char_product}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="char_process"
            placeholder="Process " // char process
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.char_process}
          />
        </Grid>
        <Grid item xs={2}>
          <CustomSelect
            name={'special_character'}
            value={specModalData.special_character}
            label={'Special Char. Class.'}
            onChange={(e) => handleSpec(e, specModalData)}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="CC">
              <img
                src={critical}
                alt="critical characteristics"
                height={'auto'}
                width={30}
              />
              {' - '}
              Critical
            </MenuItem>
            <MenuItem value="MC">
              <img src={major} alt="major safety" height={'auto'} width={30} />
              {' - '}
              Major/Safety
            </MenuItem>
            <MenuItem value="FF">
              <img src={fit} alt="fit function" height={'auto'} width={30} />
              {' - '}
              Fit /Function
            </MenuItem>
            <MenuItem value="EP">
              <img
                src={error}
                alt="error proofing"
                height={'auto'}
                width={30}
              />
              {' - '}
              Error Proofing
            </MenuItem>
          </CustomSelect>
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="methods_measure"
            placeholder="Evaluation Measurement Technique"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.methods_measure}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="methods_size"
            placeholder="Sample Size"
            // className="section-input"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.methods_size}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="methods_by"
            placeholder="Cheq. Frequency"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.methods_by}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="methods_process" // control method or current process control for imperial
            placeholder="Control Method"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.methods_process}
          />
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="responsibility"
            placeholder="Responsibility"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.responsibility}
          />
        </Grid>
        <Grid item xs={2}>
          <CustomSelect
            name={'conf_level'}
            value={specModalData.conf_level}
            label={'Conf Level'}
            onChange={(e) => handleSpec(e, specModalData)}
          >
            <MenuItem value="">None</MenuItem>
            <MenuItem value="PT">
              △{'-'}
              Production Tech
            </MenuItem>
            <MenuItem value="PTL"> □ - Production Team Leader</MenuItem>
            <MenuItem value="PS"> ⬭ - Production Sup'r</MenuItem>
            <MenuItem value="PM"> ◇ - Production Man'r</MenuItem>
            <MenuItem value="QT">
              ▲{'-'}
              Quality Tech
            </MenuItem>
            <MenuItem value="QTL">■ - Quality Team Leader</MenuItem>
            <MenuItem value="QS"> ⬬ - Quality Sup'r</MenuItem>
            <MenuItem value="QM">◆ - Quality Man'r</MenuItem>
          </CustomSelect>
        </Grid>
        <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="reaction_plan"
            placeholder="Reaction Plan"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.reaction_plan}
          />
        </Grid>
        {/* <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="corrective_action"
            placeholder="Corrective Action"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.corrective_action}
          />
        </Grid> */}
        {/* <Grid item xs={2}>
          <InputMuiCustom
            type="text"
            name="process_capability"
            placeholder="Process Capability"
            onBlur={(e) => handleSpec(e, specModalData)}
            defaultValue={specModalData.process_capability}
          />
        </Grid> */}
      </Grid>

      <p
        style={{
          marginLeft: '0.5rem',
          marginTop: '2.3rem',
          fontWeight: 600,
          fontSize: '1.8rem',
        }}
      >
        Product/Process Specification/Tolerance
      </p>
      <Divider />
      <Stack
        mt={2}
        ml={4.2}
        p={1}
        spacing={2}
        direction="row"
        alignItems="center"
      >
        <InputMuiCustom
          type="text"
          sx={{ width: '50vw' }}
          name="control_product"
          placeholder="Specification/Tolerance"
          onChange={(e) => handleSpec(e, specModalData)}
          value={specModalData.control_product}
        />
        <Button
          sx={{
            fontSize: '2rem',
            backgroundColor: '#e9ecef',
          }}
          onClick={(e) => {
            if (specModalData.control_product) {
              specModalData.control_product += ' ± ';
              setRender((prev) => !prev);
            }
          }}
        >
          ±
        </Button>
        <Button
          sx={{
            fontSize: '2rem',
            backgroundColor: '#e9ecef',
          }}
          onClick={(e) => {
            if (specModalData.control_product) {
              specModalData.control_product += ' ~ ';
              setRender((prev) => !prev);
            }
          }}
        >
          ~
        </Button>
        <Button
          sx={{
            fontSize: '2rem',
            backgroundColor: '#e9ecef',
          }}
          onClick={(e) => {
            specModalData.control_product === null &&
              (specModalData.control_product = '');
            if (!/\b(Max)\b/gi.test(specModalData.control_product)) {
              specModalData.control_product += 'Min ';
              setRender((prev) => !prev);
            }
          }}
        >
          Min
        </Button>
        <Button
          sx={{
            fontSize: '2rem',
            backgroundColor: '#e9ecef',
          }}
          onClick={(e) => {
            specModalData.control_product === null &&
              (specModalData.control_product = '');
            if (!/\b(Min)\b/gi.test(specModalData.control_product)) {
              specModalData.control_product += 'Max ';
              setRender((prev) => !prev);
            }
          }}
        >
          Max
        </Button>
      </Stack>
    </>
  );
};

export default SpecsInputs;
