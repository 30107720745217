import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['FM/XLV/MT/001', '', '']}
        heading="MACHINE MAINTENANCE HISTORY CARD"
      />
      <tr>
        <th colSpan={4} align="left">
          Department:
          <TableInput
            name="department"
            type="text"
            value={moduleData.department}
            onChange={(e) => {
              moduleData.department = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={3} align="left">
          Issue Date:
          {isView ? (
            formatDate(moduleData.issue_date)
          ) : (
            <DatePickerMui
              value={moment(moduleData.issue_date)}
              onChange={(date) => {
                moduleData.issue_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Description:
          {moduleData.machine_name}
        </th>
        <th colSpan={3} align="left">
          Machine No.:
          {moduleData.machine_no}
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Make:{moduleData.make}
        </th>
        <th colSpan={3} align="left">
          Capacity:
          <TableInput
            name="capacity"
            type="text"
            value={moduleData.capacity}
            onChange={(e) => {
              moduleData.capacity = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Installation Date:{moduleData.make}
        </th>
        <th colSpan={3} align="left">
          Power:
          <TableInput
            name="power"
            type="text"
            value={moduleData.power}
            onChange={(e) => {
              moduleData.power = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Operating Manual:
          <TableInput
            name="operating_manual"
            type="text"
            value={moduleData.operating_manual}
            onChange={(e) => {
              moduleData.operating_manual = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={3} align="left">
          Available/Not Available
        </th>
      </tr>
      <tr>
        <th
          colSpan={7}
          style={{
            fontSize: '2rem',
            textAlign: 'center',
            fontWeight: 600,
            backgroundColor: '#63C4EF',
            color: '#343a40',
            border: '1px solid black',
            textTransform: 'uppercase',
          }}
        >
          PREVENTIVE MAINTENANCE/ BREAKDOWN MAINTENANCE
        </th>
      </tr>
      <tr style={{ textTransform: 'uppercase' }}>
        {[
          'S.NO',
          'DATE',
          'TYPE OF MAINTENANCE',
          'MAINTENANCE DETAILS',
          'DETAILS OF PART CHANGED',
          'REMARKS',
        ].map((head) => (
          <th
            style={{ textTransform: 'uppercase' }}
            colSpan={head === 'REMARKS' ? 2 : 1}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr></tr>
    </thead>
  );
};

export default THead;
