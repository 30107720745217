import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import moment from 'moment';
import { SubmitButton } from '../../../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../../../components/common/FormControlMui';
import useConfirm from '../../../../../components/common/useConfirm';

interface Iprop {
  setIsNewPartModal: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setPartId: React.Dispatch<React.SetStateAction<string | null>>;
}

const AddEditPart = ({ setIsNewPartModal, id, setPartId }: Iprop) => {
  const [partData, setPartData] = useState<any>({
    date_orig: moment(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Pipe' : 'Create Pipe'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this part?`,
  );

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPartData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/pipePartRoute/${id}`)
        .then((res) => {
          setPartData(res?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    if (id) {
      await axios
        .put(`/api/pipePartRoute/${id}`, partData)
        .then((res) => {
          setIsLoading(false);
          setIsNewPartModal(false);
          setPartId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      await axios
        .post('/api/pipePartRoute', partData)
        .then((res) => {
          setIsLoading(false);
          setIsNewPartModal(false);
          setPartId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Box>
        <FormControlMui handleSubmit={onSubmit}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton
              label={`${id ? 'update' : 'submit'}`}
              type="submit"
              disabled={isLoading}
            />
          </div>
          <StyledField>
            <InputMuiCustom
              type="text"
              name="part_number"
              placeholder="Item Code"
              required={true}
              onChange={handlePartData}
              value={partData?.part_number || ''}
            />
            <InputMuiCustom
              type="number"
              name="rev_no"
              placeholder="Item Rev No"
              onChange={handlePartData}
              value={partData?.rev_no}
            />
            <InputMuiCustom
              type="text"
              name="part_name"
              placeholder="ITEM Desc(ODXTHICKNESSXLENGTH)"
              required={true}
              onChange={handlePartData}
              value={partData?.part_name || ''}
            />
            <InputMuiCustom
              type="text"
              name="material"
              placeholder="Material"
              onChange={handlePartData}
              value={partData?.material || ''}
            />
            <InputMuiCustom
              type="number"
              name="outer_dia"
              placeholder="Outer Diameter
              (OD±0.1mm)"
              required={true}
              onChange={handlePartData}
              value={partData?.outer_dia}
            />
            <InputMuiCustom
              type="number"
              name="thickness"
              placeholder="Thickness 
              (OD±0.1mm)"
              required={true}
              onChange={handlePartData}
              value={partData?.thickness}
            />
            <InputMuiCustom
              type="number"
              name="length"
              placeholder="Length 
              (L±1mm)"
              required={true}
              onChange={handlePartData}
              value={partData?.length}
            />

            {/* <DatePickerMui
              label="DATE (Original)"
              onChange={(date) => {
                partData.date_orig = date;
                setRender((prev) => !prev);
              }}
              value={moment(partData.date_orig) || null}
            /> */}
          </StyledField>
        </FormControlMui>
      </Box>
      <DialogSubmit />
    </>
  );
};

export default AddEditPart;
