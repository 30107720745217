export const listConstant = {
  childHeader: 'KRA',
  addButtonLable: 'New KRA',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW KRA',
};

export const createConstant = {
  childHeader: 'KRA list',
  theadHeader: 'KRA',
  extracolSpan: 3,
};

export const checkListList = {
  childHeader: 'LAYOUT STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'kra',
  gettableDataurl: 'kra/yearly',
  getPlanByid: 'kra',
  createPlanByid: 'kra',
  updatePlanByid: 'kra',
  sync: 'layoutplanRoute/handleSync',
  getAllCheckList: 'layoutplanRoute/getAllCheckList',
  checkListStatus: 'layoutplanRoute/handleStatus',
};

export const navigationConstant = {
  createPlanSchedule: '/common/kraCreate',
};
