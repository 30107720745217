import moment from 'moment';
export const newSubProcesses = Object.seal([{
  part_id:null,
  no_of_bend:0,
  pipe_dia:'',
  no_shift_planned:0,
  total_shift_duration:0,
  break_times:0,
  prev_aut_maint_time:0,
  changeover_time:0,
  break_up_time:0,
  set_up_time:0,
  break_down_time:0,
  material_not_avl_time:0,
  manpower_not_avl_time:0,
  power_failure_time:0,
  process_time:0,
  others_time:0,
  ideal_run_rate:0,
  total_production:0,
  no_rejection:0,
}]);
export const newProcesses = Object.seal([{
    machine_id:null,
    remark:'',
    subProcesses:newSubProcesses
}]);