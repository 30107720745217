import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { Grid, MenuItem } from '@mui/material';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../components/common/button/CustomIconButton';
import ModalCustom from '../../../components/common/Modal';
import PickPart from '../../../components/ui/PickPart';

const Create = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [data, setData] = useState<any>({});
  const [categoryData, setCategoryData] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer' : 'Create Customer'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [department, setDepartment] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [isPartModal, setIsPartModal] = useState(false);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterlistofFixures/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => setDepartment(res.data.results))
      .catch((err) => console.log(err));

    axios
      .get('/api/masterlistofFixures/instrumentcategory')
      .then((res) => {
        if (res.data.result) {
          setCategoryData(res.data.result);
        }
      })
      .catch((err) => console.log(err));

    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomerData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterlistofFixures/${id}`, data)
        .then((res) => {
          setIsSubmit(false);
          setListId(null);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/masterlistofFixures', data)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId(null);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };
  return (
    <>
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Number'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.part_id === 'object' && data?.part_id !== null
                  ? data?.part_id?.part_number
                  : data?.partNumber || ''}
              </p>
            </Grid>

            {data.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    data.part_id = null;
                    data.partNumber = null;
                    data.partName = null;

                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>
      <GridInputs
        id={'customer'}
        name={'customer'}
        html_for={'part_no'}
        label_name={'Customer'}
        input_type={'text'}
        focusType={'onblur'}
        value={
          data?.customerName || data?.part_id?.customer_id?.customer_name || ''
        }
      />
      <AutocompleteGridmui
        label={'Fixture Name'}
        id={'name'}
        option_name={'name'}
        value={data?.category_id || ''}
        arrayofObj={categoryData}
        onChange={(e, value) => {
          data.category_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <GridInputs
        id={'description'}
        name={'description'}
        html_for={'description'}
        label_name={'DESCRIPTION:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.description || ''}
        onChange={handleData}
      />

      <SelectGridMui
        name="frequency_cycle"
        value={data?.frequency_cycle || ''}
        lable={'Frequency:'}
        onChange={(e: any) => handleData(e)}
      >
        <MenuItem value="">
          <em>NONE</em>
        </MenuItem>
        <MenuItem value={'1 Month'}>1 Month</MenuItem>
        <MenuItem value={'2 Month'}>2 Month</MenuItem>
        <MenuItem value={'3 Month'}>3 Month</MenuItem>
        <MenuItem value={'6 Month'}>6 Month</MenuItem>
        <MenuItem value={'12 Month'}>12 Month</MenuItem>
      </SelectGridMui>

      {/* <AutocompleteGridmui
        id={'customer_name'}
        label={'Customer Name'}
        option_name={'customer_name'}
        arrayofObj={customerData}
        value={data.customer_id || ''}
        onChange={(e, value) => {
          data.customer_id = value;
          setRender((prev) => !prev);
        }}
      /> */}

      {/* <GridInputs
        id={'function'}
        name={'function'}
        html_for={'function'}
        label_name={'Function:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.function || ''}
        onChange={handleData}
        last_child={2}
      /> */}

      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleData}
        last_child={2}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART"
      >
        <PickPart moduleData={data} />
      </ModalCustom>
    </>
  );
};

export default Create;
