import { Grid } from '@mui/material';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../../../components/common/button/CustomIconButton';
import { BorderClear } from '@mui/icons-material';
import Styled from 'styled-components';

interface IProps {
  Data: any;
}

const TBody = ({ Data }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        <tr>
          <th
            style={{
              fontSize: '2rem',
              textAlign: 'center',
              fontWeight: 600,
              backgroundColor: '#63C4EF',
              color: '#343a40',
              border: '1px solid black',
              textTransform: 'uppercase',
            }}
            colSpan={4}
          >
            STORE OVERVIEW REPORT
          </th>
        </tr>
        <tr style={{ height: '40px', fontSize: '20px' }}>
          <th> SR. NO.</th>
          <th>PART NO.</th>
          <th>BIN NO.</th>
          <th>QTY</th>
        </tr>
        {Data &&
          Data.map((part: any, index: number) => (
            <tr key={index + 1}>
              <td>{index + 1}</td>
              <td>{part?.store_part_id?.ri_part_id?.part_number}</td>
              <td>{part?.address}</td>
              <td>{part?.qty}</td>
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
