//pir
import { Pir, PirTableList } from '../../pages/pir';

//pdir
import PdirList from '../../pages/pdir';
import Pdir from '../../pages/pdir/Create';
//setup
import { Setup, SetupList } from '../../pages/setup';
//incomingReport
import { IncomingReport, IncomingReportList } from '../../pages/incomingReport';
//material incoming
import {
  MaterialIncomingList,
  MaterialIncomingReport,
} from '../../pages/materialIncoming';
import {
  SupervisorPpapTiles,
  SupervisorViewPpap,
} from '../../pages/supervisorViewPpap';
import PdirTiles from '../../pages/pdiDepartment/pdirIAI/PdirTiles';
import {
  PdirFinalCreate,
  PdirFinalList,
} from '../../pages/pdiDepartment/pdirIAI/pdirFinalList';
import {
  PdirGeneralCreate,
  PdirGeneralList,
} from '../../pages/pdiDepartment/pdirIAI/pdirGneneral';

const pirRoute = [
  {
    path: 'pirList',
    element: <PirTableList />,
  },
  {
    path: 'pir/:partId/:pirId?',
    element: <Pir />,
  },
];
const materialIncomingRoute = [
  {
    path: 'materialIncomingList',
    element: <MaterialIncomingList />,
  },
  {
    path: 'materialIncoming/:partId/:incomingId?',
    element: <MaterialIncomingReport />,
  },
];
const incomingRoute = [
  {
    path: 'incomingReportList',
    element: <IncomingReportList />,
  },
  {
    path: 'incomingReport/:partId/:incomingId?',
    element: <IncomingReport />,
  },
];
const pdirRoute = [
  {
    path: 'pdirList',
    element: <PdirList />,
  },
  {
    path: 'pdir/:partId/:pdirId?',
    element: <Pdir />,
  },
];
const setupRoute = [
  {
    path: 'setupList',
    element: <SetupList />,
  },
  {
    path: 'setup/:partId/:setupId?',
    element: <Setup />,
  },
];

export const supervisor = [
  {
    path: 'viewPpap',
    element: <SupervisorViewPpap />,
  },
  {
    path: 'ppapTiles/:partId',
    element: <SupervisorPpapTiles />,
  },
  ...pirRoute,
  ...pdirRoute,
  ...setupRoute,
  ...incomingRoute,
  ...materialIncomingRoute,
  {
    path: 'pdirTiles',
    element: <PdirTiles />,
  },
  {
    path: 'pdirfinallist',
    element: <PdirFinalList />,
  },
  {
    path: 'Pdirfinalcreate/:partId/:pdirId?',
    element: <PdirFinalCreate />,
  },

  {
    path: 'pdirGeneralList',
    element: <PdirGeneralList />,
  },
  {
    path: 'PdirGeneralcreate/:partId/:pdirId?',
    element: <PdirGeneralCreate />,
  },
];
