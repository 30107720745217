import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import TBodyRight from './TBodyRight';
import formatDate from '../../../../../components/common/formatDate';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import TBodyRightPDate from './TBodyRightPDate';

interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
  masterModuleDataForAutoComplete: any;
  handleDeleteRows: any;
}
const TBody = ({
  moduleData,
  isView,
  allmonth,
  masterModuleDataForAutoComplete,
  handleDeleteRows,
}: IProps) => {
  const [tbodyRender, setTBodyRender] = useState(false);
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <>
          <tr key={`${index}_table_row`}>
            <td rowSpan={2}>{index + 1}</td>
            <td rowSpan={2}>
              {isView ? (
                item?.machine_id?.vender_name
              ) : (
                <AutocompleteMuiCustom
                  id="a1"
                  label=""
                  option_name="customer_name"
                  key={index + 'customer_name'}
                  arrayofObj={masterModuleDataForAutoComplete}
                  value={item?.a1 || ''}
                  onChange={(e, value) => {
                    item.a1 = value;
                    setTBodyRender((prev) => !prev);
                  }}
                />
              )}
            </td>

            <th>P</th>
            {/* always constant ⬇️*/}
            <TBodyRight
              item={item}
              isView={isView}
              index={index}
              allmonth={allmonth}
              setTBodyRender={setTBodyRender}
            />
            <td rowSpan={2} style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                style={{ height: '80px' }}
                name="a4"
                type="text"
                value={item.a4}
                onChange={(e) => {
                  item.a4 = e.target.value;
                  setTBodyRender((prev) => !prev);
                }}
              />
            </td>
            <td rowSpan={2} style={{ minWidth: '100px', textAlign: 'center' }}>
              {!isView && (
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handleDeleteRows(index)}
                />
              )}
            </td>
          </tr>

          <tr>
            <th>A</th>
            {allmonth.map((month: any, mIdx: number) => (
              <td
                width="80px"
                height="45px"
                style={{
                  ...(item[month].isTask &&
                    item[month].status === 'not_done' && {
                      backgroundColor: '#F319E1',
                      color: '#fff',
                    }),
                  ...(item[month].isTask &&
                    item[month].status === 'completed' && {
                      backgroundColor: '#37b24d',
                      color: '#fff',
                    }),
                  color: 'white',
                  textTransform: 'capitalize',
                  fontSize: '1.6rem',
                }}
              >
                {item[month].isTask && item[month].status === 'not_done' && 'N'}
                {item[month].isTask &&
                  item[month].status === 'completed' &&
                  formatDate(item[month]?.doneDate)}
              </td>
            ))}
          </tr>
          {/* ---x---- */}
        </>
      ))}
    </tbody>
  );
};

export default TBody;
