import axios from 'axios';
import { useState, useEffect } from 'react';
import { apiConstant } from '../../../constant';
import lodash from 'lodash';
import {
  checklistDataL1,
  checklistDataL2,
  checklistDataL3,
  checklistDataL4A,
  checklistDataL4B,
} from './checklist';

const useGetData = (
  id: string | undefined,
  area: string | undefined,
  category: string | undefined
) => {
  const [moduleData, setModuleData] = useState<any>({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log('category', category);
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/${apiConstant.checklistData}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      if (area === 'Ist Layer') {
        setModuleData({ c: lodash.cloneDeep(checklistDataL1) });
      } else if (area === '2nd Layer') {
        setModuleData({ c: lodash.cloneDeep(checklistDataL2) });
      } else if (
        area === '3rd Layer' ||
        (area === '4th Layer' && category === 'QA Manager / Section Head (QA)')
      ) {
        setModuleData({ c: lodash.cloneDeep(checklistDataL3) });
      } else if (area === '4th Layer' && category === 'Plant Head') {
        setModuleData({ c: lodash.cloneDeep(checklistDataL4B) });
      } else {
        setModuleData({ c: lodash.cloneDeep(checklistDataL4A) });
      }
    }
  }, []);

  return { moduleData, isLoading, error };
};

export default useGetData;
