import React from 'react';
// icon
import { TbReportAnalytics } from 'react-icons/tb';
import { FcInspection } from 'react-icons/fc';
// module
import ChildHeader from '../../../components/ui/ChildHeader';
import { RenderTiles } from '../../../components/common';
import { useAuth } from '../../../services/auth/AuthProvider';

const PdirTiles = () => {
  const { state } = useAuth();
  const { user } = state;
  const tiles = [
    {
      sNo: 1,
      link: `/${user?.userType === 2 ? 'admin' : 'supervisor'}/pdirfinallist`,
      isLink: true,
      icon: <FcInspection size="5rem" color="#a12222" />,
      p: 'PDIR',
      span: 'AMG',
      isIcon: true,
    },
    {
      sNo: 2,
      link: `/${user?.userType === 2 ? 'admin' : 'supervisor'}/pdirGeneralList`,
      isLink: true,
      icon: <TbReportAnalytics size="5rem" color="#2b8a3e" />,
      p: 'PDIR',
      span: 'General',
      isIcon: true,
    },
  ];
  return (
    <>
      <ChildHeader text="PDIR" />
      <RenderTiles
        height="100vh"
        tilesObj={tiles}
        justify="start"
        columns="0fr"
      />
      ;
    </>
  );
};

export default PdirTiles;
