// import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  moduleData: any;
  isView: boolean;
  machineData: any;
}

const theadData = [
  'S. No.',
  'Part Name',
  'Part No',
  'Time Duration',
  'Operation',
  'Total Qty',
  'OK Qty',
  'Total Rej',
  'Rej Qty - Defect Wise',
  'Any Other Observation',
  'Action Taken ',
  'Remarks',
];

function calcColSpan(heading: string) {
  if (heading === 'Rej Qty - Defect Wise') return 10;
  if (heading === 'Time Duration' || heading === 'Remarks') return 2;
  return 1;
}
const THead = ({ moduleData, isView, machineData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={23}
        rowSpan={2}
        labels={['DOC. NO', 'REV NO & DATE:']}
        values={['', '']}
        heading="HOURLY MONITORING RECORD"
      />
      {/* 'DOC. NO', 'REV NO & DATE:'  , '', ''*/}
      <tr>
        <th colSpan={2} align="left">
          DATE / SHIFT
        </th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.hourlyMonitoring_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.hourlyMonitoring_date)}
              onChange={(date) => {
                moduleData.hourlyMonitoring_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={1} align="left">
          <TableInput
            isView={isView}
            name="shift"
            type="text"
            value={moduleData.shift}
            onChange={(e) => {
              moduleData.shift = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          M/C NO:
        </th>
        <th colSpan={3} style={{ minWidth: '200px', textAlign: 'center' }}>
          {isView ? (
            moduleData?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="machine_no"
              option_name="machine_no"
              arrayofObj={machineData}
              value={
                moduleData.machine_autocomplete || moduleData.machine_id || ''
              }
              onChange={(e, value) => {
                moduleData.machine_id = value?._id;
                moduleData.machine_autocomplete = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left">
          OPERATOR:
        </th>
        <th colSpan={4} align="left">
          <TableInput
            isView={isView}
            name="operator"
            type="text"
            value={moduleData.operator}
            onChange={(e) => {
              moduleData.operator = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          AREA:
        </th>
        <th colSpan={5} align="left">
          <TableInput
            isView={isView}
            name="area"
            type="text"
            value={moduleData.area}
            onChange={(e) => {
              moduleData.area = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th
            key={heading + index}
            colSpan={calcColSpan(heading)}
            rowSpan={
              heading === 'Time Duration' || heading === 'Rej Qty - Defect Wise'
                ? 1
                : 2
            }
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr>
        <th>From</th>
        <th>To</th>
        {[
          'defect wise ',
          'defect wise ',
          'defect wise ',
          'defect wise ',
          'defect wise ',
          'defect wise ',
          'defect wise ',
          'defect wise ',
          'defect wise ',
          'defect wise ',
        ].map((heading, ind) => (
          <th>
            <TableInput
              isView={isView}
              name="rej_defect_name"
              type="text"
              value={moduleData?.rej_defect_name[ind]}
              onChange={(e) => {
                moduleData.rej_defect_name[ind] = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
