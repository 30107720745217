import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridSelect from '../../../components/common/inputs/GridSelect';
import { MenuItem } from '@mui/material';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import DateGridMui from '../../../components/common/dateCustom/DateGridMui';
import moment from 'moment';
import { apiConstant } from '../constant';

const Create = ({
  item,
  setIsModal,
  setRender,
}: {
  item?: any;
  setRender?: any;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [moduleData, setModuleData] = useState<any>({
    status: item.status,
  });
  const [isSubmit, setIsSubmit] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `update`,
    `Are you sure you want to ${'update'} this?`
  );

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    moduleData.p = true;

    if (moduleData.status === 'completed') {
      moduleData.isTask = true;
      if (!moduleData.doneDate) {
        alert('Done Date is require');
        return;
      }
    } else {
      moduleData.isTask = false;
      moduleData.doneDate = null;
    }

    if (!ans) return;
    if (item._id) {
      await axios
        .put(
          `/api/${apiConstant.checkListStatus}/${item.docId}/${item._id}/${item.month}`,
          moduleData
        )
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setRender((prev: any) => !prev);
        })
        .catch((err) => {
          console.log(err);
          setRender((prev: any) => !prev);
        });
    }
  };

  return (
    <>
      <SelectGridMui
        name="frequency_cycle"
        value={moduleData?.status || ''}
        lable={'SET STATUS'}
        onChange={(e) => {
          moduleData.status = e.target.value;
          setModuleData((prev: any) => ({ ...prev, status: e.target.value }));
        }}
      >
        <MenuItem value="">NONE</MenuItem>
        <MenuItem value="pending">NOT STARTED</MenuItem>
        <MenuItem value="overdue">OVERDUE</MenuItem>
        <MenuItem value="started">ONGOING</MenuItem>
        <MenuItem value="completed">COMPLETED</MenuItem>
      </SelectGridMui>
      {moduleData?.status === 'completed' && (
        <DateGridMui
          lable="LAST MAINTENANCE DATE"
          onChange={(date: any) => {
            moduleData.doneDate = date;
            setModuleData((prev: any) => ({
              ...prev,
              doneDate: date,
            }));
          }}
          value={moment(moduleData?.doneDate || item.doneDate || null) || null}
        />
      )}

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${'update'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
