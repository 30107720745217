import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={10}
        rowSpan={3}
        labels={['Format no:-', 'Rev no. :-', 'Effective Date:-']}
        values={['FM/XLV/RI/010', '01', '09.09.2022']}
        heading="LUX LEVEL OBSERVATION SHEET"
      />
      <tr>
        <th colSpan={2}>Date : </th>
        <th colSpan={4} align="left">
          {isView ? (
            formatDate(moduleData.moduleData_Date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.moduleData_Date)}
              onChange={(date) => {
                moduleData.moduleData_Date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={4}></th>
      </tr>
      <tr>
        <th colSpan={2}>Frequency </th>
        <th colSpan={4}>
          <TableInput
            isView={isView}
            type="text"
            name="freq"
            value={moduleData?.freq || null}
            onChange={(e) => {
              moduleData.freq = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2}>Month</th>
        <th colSpan={2}>
          {moment(moduleData?.moduleData_Date || null).format('MMMM')}
        </th>
      </tr>
      <tr>
        {['S.no', 'Location -RI', 'Specification'].map((heading, index) => (
          <th key={heading + index} rowSpan={2}>
            {heading}
          </th>
        ))}
        {[1, 2, 3, 4].map((heading, index) => (
          <th key={heading + index} align="center" style={{ width: '180px' }}>
            {isView ? (
              `Date : ${formatDate(moduleData?.obs_date?.[index])}`
            ) : (
              <DatePickerMui
                label=""
                value={moment(moduleData?.obs_date?.[index])}
                onChange={(date) => {
                  moduleData.obs_date[index] = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </th>
        ))}
        <th rowSpan={2}>Status</th>
        <th colSpan={2} rowSpan={2}>
          Remarks
        </th>
      </tr>
      <tr>
        <th>Observation 1st</th>
        <th>Observation 2nd</th>
        <th>Observation 3rd</th>
        <th>Observation 4th</th>
      </tr>
    </thead>
  );
};

export default THead;
