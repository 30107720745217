import { Checkbox, MenuItem, Tooltip } from '@mui/material';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import useConfirm from '../../../../../components/common/useConfirm';
import { IModule, ISpec } from '../../interface';
import CustomSelect from '../../../../../components/common/SelectField';

interface IProps {
  isView: boolean;
  moduleData: IModule;
}
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );

  const handelDeleteRows = async (index: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    moduleData.specs.splice(index, 1);
    setRender((prev) => !prev);
  };
  return (
    <tbody>
      {moduleData.specs.map((item: ISpec, index: number) => (
        <tr>
          <td style={{ width: '100px' }}>
            <TableInput
              name="s_no"
              type="text"
              value={item.s_no}
              onChange={(e) => {
                item.s_no = e.target.valueAsNumber;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td style={{ width: '280px' }}>
            <TableInput
              name="parameter"
              type="text"
              value={item.parameter}
              onChange={(e) => {
                item.parameter = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td style={{ width: '500px' }}>
            <TableInput
              name="specification"
              type="text"
              value={item.specification}
              onChange={(e) => {
                item.specification = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td style={{ width: '280px' }}>
            <TableInput
              name="methods"
              type="text"
              value={item.methods}
              onChange={(e) => {
                item.methods = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td style={{ width: '280px' }}>
            <TableInput
              name="freq"
              type="text"
              value={item.freq}
              onChange={(e) => {
                item.freq = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          <td style={{ width: '280px' }}>
            {isView ? (
              item.spec_to_inspection && '✓'
            ) : (
              <>
                <Tooltip title="Add To Inspection Report">
                  <Checkbox
                    checked={item.spec_to_inspection}
                    onChange={(e) => {
                      item.spec_to_inspection = e.target.checked;
                      setRender((prev) => !prev);
                    }}
                  />
                </Tooltip>
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handelDeleteRows(index)}
                />
              </>
            )}
          </td>
        </tr>
      ))}
      <DialogDeleteRow isSubmitContent={false} />
    </tbody>
  );
};

export default TBody;
