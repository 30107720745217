import React, { useRef, useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { MenuItem, Stack } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import {
  CustomIconButton,
  DeleteIconButton,
  UploadImageIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import Add from '@mui/icons-material/Add';
import { useAuth } from '../../../../../services/auth/AuthProvider';

interface IProps {
  moduleData: any;
  isView: boolean;
  process: any;
  index: number;
  handelDeleteRows: any;
  handleSumbit: any;
  uploadImageFile: any;
}
const TBodyRow = ({
  isView,
  process,
  index,
  handelDeleteRows,
  handleSumbit,
  uploadImageFile,
  moduleData,
}: IProps) => {
  const [render, setRender] = useState(false);
  const { state } = useAuth();
  return (
    <React.Fragment key={index + 10.1 + 'heading '}>
      <tr>
        <th style={{ minWidth: '50px', textAlign: 'center' }}>{index + 1}</th>
        <td style={{ minWidth: '200px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="poke_yoke_name"
            type="text"
            value={process.poke_yoke_name}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '200px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="machine_name_and_location"
            type="text"
            value={process.machine_name_and_location}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '200px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="method_of_verification"
            type="text"
            value={process.method_of_verification}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ minWidth: '200px', textAlign: 'center' }}>
          {moduleData._id && (
            <Stack
              direction="row"
              sx={{ justifyContent: 'center', alignItems: 'center' }}
            >
              <UploadImageIconButton
                id={`${index}-process-upload`}
                tooltipTitle="upload"
                sx={{}}
                onChange={(e) => {
                  uploadImageFile(e, index);
                }}
              />
              {process.files?.length > 0 ? (
                <>
                  <ViewIconButton
                    tooltipTitle="view image"
                    onClick={() => {
                      window.open(process.files[0].url, '_blank', 'noreferrer');
                    }}
                  />
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => {
                      if (
                        window.confirm(
                          'Are you sure u want to delete this image!!!',
                        )
                      ) {
                        setRender((prev) => !prev);
                        process.files = [];
                      }
                    }}
                  />
                </>
              ) : (
                <DeleteIconButton
                  sx={{
                    opacity: '0',
                    PointerEvent: 'none',
                    visibility: 'hidden',
                  }}
                  tooltipTitle=""
                />
              )}
            </Stack>
          )}
        </td>
        {Array.from({ length: 31 }, (_, indx) => (
          <td
            style={{
              minWidth: '100px',
              textAlign: 'center',
              fontSize: '2rem',
            }}
            key={index + 2.2 + indx + 'after' + 111.33}
          >
            {isView ? (
              (process.results[indx] === 'true' && '✓') ||
              (process.results[indx] === 'false' && '⨯')
            ) : (
              <CustomSelect
                name="results[indx]"
                value={process.results[indx]}
                onChange={(e) => {
                  process.results[indx] = e.target.value;
                  setRender((prev) => !prev);
                }}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="true">✓</MenuItem>
                <MenuItem value="false">⨯</MenuItem>
              </CustomSelect>
            )}
          </td>
        ))}
        <td
          style={{ minWidth: '100px', textAlign: 'center' }}
          colSpan={isView ? 2 : 1}
        >
          <TableInput
            isView={isView}
            name="remarks"
            type="text"
            value={process.remarks}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        {!isView && (
          <td style={{ minWidth: '100px', textAlign: 'center' }}>
            <DeleteIconButton
              tooltipTitle={'delete'}
              onClick={() => handelDeleteRows(index)}
            />
          </td>
        )}
      </tr>
    </React.Fragment>
  );
};

export default TBodyRow;
