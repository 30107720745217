// import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';

interface IProps {
  moduleData: any;
  isView: boolean;
  headerTitle: string;
}

const numberCol = Array.from({ length: 31 }, (_, index) => index + 1);

const theadData = [
  'Sr.No.',
  'Specification',
  'Method',
  ...numberCol,
  'Remarks',
];

const THead = ({ moduleData, isView, headerTitle }: IProps) => {
  const [render, setRender] = useState(false);
  const [machineData, setMachineData] = useState([]);

  useEffect(() => {
    axios
      .get('/api/machineRoutes/machineList/machineListForAutoComplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <thead>
      <TableCompHead
        colSpan={35}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading={headerTitle}
      />
      <tr>
        <th colSpan={35}>
          <img height="100%" width="50%" src={moduleData.image} alt="image" />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Date :{' '}
        </th>
        <th colSpan={4} align="left">
          {isView ? (
            formatDate(moduleData.checkSheet_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.checkSheet_date)}
              onChange={(date) => {
                moduleData.checkSheet_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={24} align="left">
          Machine No.:
          {isView ? (
            moduleData?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={moduleData.machine_id || ''}
              onChange={(e, value) => {
                moduleData.machine_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left">
          Month :{' '}
        </th>
        <th colSpan={3} align="left">
          <TableInput
            isView={isView}
            name="month"
            type="text"
            value={moduleData.month}
            onChange={(e) => {
              moduleData.month = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
