import { GoTools } from 'react-icons/go';
import { HiDocumentReport } from 'react-icons/hi';
import { TbReportAnalytics } from 'react-icons/tb';
import { TbReport } from 'react-icons/tb';
const tiles = [
  {
    sNo: 1,
    link: '/admin/rotorHourlyProductionList',
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'DAILY PRODUCTION',
    span: 'REPORT',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/admin/dailyProductionList',
    isLink: true,
    icon: <TbReport size="5rem" color="#a12222" />,
    p: 'ROTOR HOURLY',
    span: 'PRODUCTION REPORT',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/admin/dailyProductionStrokesList',
    isLink: true,
    icon: <TbReportAnalytics size="5rem" color="#064a29" />,
    p: 'DAILY PRODUCTION STROKES',
    span: 'REPORT',
    isIcon: true,
  },
];

export default tiles;
