import { useEffect, useState } from 'react';
import TController from './components/create/TController';
import axios from 'axios';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { GoSync } from 'react-icons/go';
import { Loader } from '../../../components/common/Loader';

const Create = () => {
  const [moduleData, setModuleData] = useState<any>([]);
  const [render, setRender] = useState(false);
  const isView = useQuery('isView') === 'true' ? true : false;
  const [isLoading, setIsLoading] = useState(false);

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this Row Permanently?',
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?',
  );
  const [DialogSync, confirmSync] = useConfirm(
    'Sync',
    'Are you sure you want to sync this document with RI Part?',
  );
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(`/api/storePart`)
      .then(async (res) => {
        const data = res.data;
        if (data) {
          setModuleData(data);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  }, [render]);
  const handleSync = async () => {
    const ans = await confirmSync();
    if (!ans) return;
    setIsLoading(true);
    await axios
      .get(`/api/storePart/sync`)
      .then((res) => {
        if (res.data.status === 'success') {
          alert('Updated Successfully');
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const onSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    await axios
      .put(`/api/storePart`, moduleData)
      .then((res) => {
        if (res.data.status === 'success') {
          setRender((prev) => !prev);
          setIsLoading(false);
          alert('Updated Successfully');
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handelDeleteRows = async (id: string) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    await axios
      .delete(`/api/storePart/${id}`)
      .then((res) => {
        if (res.data.status === 'success') {
          setRender((prev) => !prev);
          alert('deleted Successfully');
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Loader loader={isLoading} />
      <Box>
        <ChildHeader text="STORE PART LIST">
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#228be6',
                  color: '#e7f5ff',
                  mr: 2,
                }}
                icon={<GoSync />}
                size="large"
                onClick={() => handleSync()}
              >
                Sync All RI Parts
              </CustomButton>
              <SubmitButton label="Update" onClick={() => onSumbit()} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handelDeleteRows,
        }}
      />

      <DialogDeleteRow isSubmitContent={false} />
      <DialogSubmit />
      <DialogSync />
    </>
  );
};

export default Create;
