import { useRef, useState } from 'react';
import CustomSelect from '../../../../../components/common/SelectField';
import { Grid, MenuItem, Tooltip } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import formatDate from '../../../../../components/common/formatDate';
import { calcRej } from '../../helpers/calcRej';

interface IProps {
  moduleData: any;
  customerData: any;
  isView: boolean;
  handelDeleteRows: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setIsOpenPopupRejections: any;
  setRejectionsIndx: any;
}

const TBody = ({
  moduleData,
  customerData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
  setIsOpenPopupRejections,
  setRejectionsIndx,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr>
              <th
                style={{
                  width: '160px',
                  minWidth: '160px',
                  textAlign: 'center',
                }}
              >
                {isView ? (
                  formatDate(process.date)
                ) : (
                  <DatePickerMui
                    label=""
                    value={moment(process.date)}
                    onChange={(date) => {
                      process.date = date;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </th>
              <td
                style={{
                  width: '280px',
                  minWidth: '280px',
                  textAlign: 'center',
                }}
              >
                {isView ? (
                  process?.part_id && process?.part_id?.part_number
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.2rem',
                        }}
                      >
                        {typeof process?.part_id === 'object' &&
                        process?.part_id !== null
                          ? process?.part_id?.part_number
                          : process?.partNumber || ''}
                        {!process?.part_id && 'Pick Part Number'}
                      </p>
                    </Grid>

                    {process.part_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={() => {
                            process.part_id = null;
                            process.partNumber = null;
                            process.partName = null;
                            setRender((prev) => !prev);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </td>
              <td style={{ minWidth: '200px', textAlign: 'center' }}>
                {isView ? (
                  process.customer_id?.customer_name
                ) : (
                  <AutocompleteMuiCustom
                    id="customer_name"
                    option_name="customer_name"
                    arrayofObj={customerData}
                    value={
                      process.customer_autocomplete || process.customer_id || ''
                    }
                    onChange={(e, value) => {
                      process.customer_id = value?._id;
                      process.customer_autocomplete = value;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="checked_qty"
                  type="number"
                  value={process.checked_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    process.rejection_qty = calcRej(
                      process.checked_qty,
                      process.ok_qty
                    );
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="ok_qty"
                  type="number"
                  value={process.ok_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    process.rejection_qty = calcRej(
                      process.checked_qty,
                      process.ok_qty
                    );
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                {isView ? (
                  process.shift
                ) : (
                  <CustomSelect
                    name="shift"
                    value={process.shift}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="A">A</MenuItem>
                    <MenuItem value="B">B</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '140px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="stage"
                  type="text"
                  value={process.stage}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td
                style={{
                  width: '280px',
                  minWidth: '280px',
                  textAlign: 'center',
                }}
              >
                <Grid container xs={12} direction="row" alignItems={'center'}>
                  <Grid item xs={10}>
                    <p
                      style={{
                        fontSize: '1.2rem',
                        fontWeight: 'bold',
                      }}
                    >
                      Click on + icon for reason and qty
                    </p>
                  </Grid>
                  <Grid item xs={2}>
                    <AddIconButton
                      tooltipTitle="add reasons and qty"
                      onClick={() => {
                        setIsOpenPopupRejections(true);
                        setRejectionsIndx(index);
                      }}
                    />
                  </Grid>
                </Grid>
              </td>
              <td
                style={{ minWidth: '80px', textAlign: 'center' }}
                colSpan={isView ? 2 : 1}
              >
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
