import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../components/ui';
import THead from './THead';
import TBody1 from './TBody1';
import TBody2 from './TBody2';

interface IProps {
  moduleData: any;
  handelDeleteRows: any;
  isView: boolean;
}

const TController = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, isView }} />
        <TBody1 {...{ moduleData, isView, handelDeleteRows }} />
        <TBody2 {...{ moduleData, isView, handelDeleteRows }} />

        {/* <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        /> */}
      </RenderTable>
    </Box>
  );
};

export default TController;
