import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { PersitedDatePicker } from '../../../../../../components/common';
import { useQuery } from '../../../../../../hooks/UseQuery';
import axios from 'axios';
import BarChart from './BarChart';
interface IProps {
  totalOfDomesticPerMonth: any;
  year: number;
  //tabValue: number;
  // isView: boolean;
}

const Domestic = ({ totalOfDomesticPerMonth, year }: IProps) => {
  const options = {
    plugins: {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'Domestic (TCL and VW)',
      },
    },
    responsive: true,
    scales: {
      /* x: {
        stacked: true,
      },*/
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 5,
        },
      },
    },
  };
  const monthNames = [
    `Jan-${year % 100}`,
    `Feb-${year % 100}`,
    `Mar-${year % 100}`,
    `Apr-${year % 100}`,
    `May-${year % 100}`,
    `Jun-${year % 100}`,
    `Jul-${year % 100}`,
    `Aug-${year % 100}`,
    `Sep-${year % 100}`,
    `Oct-${year % 100}`,
    `Nov-${year % 100}`,
    `Dec-${year % 100}`,
  ];
  const backgroundColors = ['#4c6ef5'];

  let domesticCountPerMonth = [];
  for (const monthIndex in totalOfDomesticPerMonth) {
    if (totalOfDomesticPerMonth?.[+monthIndex - 1] > 0)
      domesticCountPerMonth.push(totalOfDomesticPerMonth?.[+monthIndex - 1]);
    else domesticCountPerMonth.push(0);
  }

  const datasets = [
    {
      label: 'My First Dataset(Domestic)',
      data: domesticCountPerMonth,
      backgroundColor: backgroundColors,
      borderWidth: 1,
      barThickness: 50,
    },
  ];
  return (
    <div>
      <Box style={{ paddingBottom: '3rem', height: '50vh', width: '50vw' }}>
        <BarChart
          datasets={datasets}
          monthNames={monthNames}
          options={options}
        />
      </Box>
    </div>
  );
};

export default Domestic;
