import { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import InputMuiCustom from '../../../../../components/common/inputs/InputMuiCustom';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  tabValue: any;
  setOtherRender: any;
}

const getTotalDieselReceived = (processes: any) => {
  return (
    processes.reduce(
      (total: any, item: any) => total + +item.received_qty,
      0
    ) || 0
  );
};
const getTotalDieselConsumed = (processes: any) => {
  return (
    processes.reduce(
      (total: any, item: any) => total + +item.consumed_qty,
      0
    ) || 0
  );
};

//   for (let i = index; i < processesTwo.length; i++) {
//     const day = new Date(processesTwo[i].date_issue).getDate();
//     const s = process.reduce(
//       (total: any, item: any) => {
//         if()
//         return total + item.received_qty
//       },
//       0
//     );
//     const sum = processesTwo.reduce(
//       (total: any, item: any) => {
//         return total + item.consumed_qty
//       },
//       0
//     );
//   }
// };
const TBody = ({
  moduleData,
  isView,
  handelDeleteRows,
  tabValue,
  setOtherRender,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        {tabValue === 0 &&
          moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td
                width={'100px'}
                style={{
                  minWidth: '100px',
                  fontWeight: 'bold',
                  background: '#f3d9fa',
                }}
              >
                {index + 1}
              </td>
              <td style={{ minWidth: '200px' }}>
                <DatePickerMui
                  label=""
                  value={moment(process.date_diesel_received || '')}
                  onChange={(date) => {
                    process.date_diesel_received = date;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="received_qty"
                  type="number"
                  value={process.received_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);

                    moduleData.total_diesel_received = getTotalDieselReceived(
                      moduleData.processes
                    );
                    moduleData.total_diesel_remaining =
                      moduleData.total_diesel_received -
                      moduleData.total_diesel_consumed;
                    setOtherRender((prev: any) => !prev);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="remark"
                  type="text"
                  value={process.remark}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}

        {tabValue === 1 &&
          moduleData &&
          moduleData.processesTwo.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td
                width={'100px'}
                style={{
                  minWidth: '100px',
                  fontWeight: 'bold',
                  background: '#f3d9fa',
                }}
              >
                {index + 1}
              </td>
              <td style={{ minWidth: '200px' }}>
                <DatePickerMui
                  label=""
                  value={moment(process.date_issue || '')}
                  onChange={(date) => {
                    process.date_issue = date;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="consumed_qty"
                  type="number"
                  value={process.consumed_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    moduleData.total_diesel_consumed = getTotalDieselConsumed(
                      moduleData.processesTwo
                    );
                    moduleData.total_diesel_remaining =
                      moduleData.total_diesel_received -
                      moduleData.total_diesel_consumed;
                    // getRemainingQty(
                    //   index,
                    //   moduleData.processes,
                    //   moduleData.processesTwo
                    // );
                    setOtherRender((prev: any) => !prev);
                    setRender((prev) => !prev);
                  }}
                />
              </td>

              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="dg_detail"
                  type="text"
                  value={process.dg_detail}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px' }}>
                <TableInput
                  isView={isView}
                  name="remark"
                  type="text"
                  value={process.remark}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
