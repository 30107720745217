import { TableCompHead } from '../../../../../components/ui';
import { useQuery } from '../../../../../hooks/UseQuery';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '200px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
  tniTopic: any;
}
const THead = ({ isView, tniTopic, moduleData }: IProps) => {
  const yearRange = useQuery('yearRange');
  const category = useQuery('category');
  const date = new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  return (
    <thead>
      <TableCompHead
        colSpan={9 + tniTopic.length}
        rowSpan={3}
        labels={['DOC. NO.', 'REV. NO.', 'DATE.']}
        values={['F03 HRD 01', '01', '04.04.19']}
        heading={`TNI FOR ${category} (YEAR : ${yearRange})`}
      />

      <tr>
        {[
          'S. No.',
          'Name of Employee',
          'Designation',
          'Department',
          'Training Topics',
          'H.O.D NAME',
          'DOJ',
          'DOL',
          'Remarks',
          'Action',
        ].map((el, index) => (
          <th
            key={`${index}_head`}
            align="center"
            colSpan={el === 'Training Topics' ? tniTopic.length : 1}
            rowSpan={el === 'Training Topics' ? 1 : 2}
            style={{
              textAlign: 'center',
              ...style1,
              minWidth: `${(el === 'S. No.' && '50px') || style1.minWidth}`,
            }}
          >
            {el}
          </th>
        ))}
      </tr>
      <tr>
        {tniTopic.map((el: any, index: number) => (
          <th
            key={`${index}_headcol`}
            align="center"
            style={{ textAlign: 'center', ...style2, minWidth: '220px' }}
          >
            {el.name}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
