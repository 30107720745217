export const listConstant = {
  childHeader: 'TRAINING CALENDAR',
  addButtonLable: 'New TRAINING CALENDAR',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW TRAINING CALENDAR',
};

export const createConstant = {
  childHeader: 'TRAINING CALENDAR PLAN',
  theadHeader: 'TRAINING CALENDAR PLAN',
  extracolSpan: 6,
};

export const checkListList = {
  childHeader: 'TRAINING CALENDAR PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'tniTrainigPlanRoute',
  gettableDataurl: 'tniTrainigPlanRoute/monthly',
  getPlanByid: 'tniTrainigPlanRoute',
  createPlanByid: 'tniTrainigPlanRoute',
  updatePlanByid: 'tniTrainigPlanRoute',
  sync: 'tniTrainigPlanRoute/handleSync',
  getAllCheckList: 'tniTrainigPlanRoute/getAllCheckList',
  checkListStatus: 'tniTrainigPlanRoute/handleStatus',
  checklistUpload: 'tniTrainigPlanRoute/handleUpload',
  checklistDeleteImage: 'tniTrainigPlanRoute/handleDeleteImage',
  checklistData: 'tniTrainigPlanRoute/checklistRoute',
  categoryTopic:'tni/getTopicByCategory'
};

export const navigationConstant = {
  createPlanSchedule: '/common/tniTrainingPlanCreate',
  createChecklist: '/common/tniTrainingPlanChecklistCreate',
};
