import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { IProcess } from '../../../helpers/Interface';
import { spclChar } from '../../../helpers/SpecialCharacters';
import { confLevel } from '../../../helpers/confLevel';

interface IProps {
  moduleData: any;
}
let isView = false;
const colSpanBodyHeader = (head: string) => {
  if (head === 'Characteristics') return 3;
  if (head === 'Methods') return 5;
  return 1;
};

const TBody = ({ moduleData }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      <tr>
        {[
          'Part / Process No.',
          'Process Name Operation / Description',
          'M/C Device JIG Tool For Mfg.',
          'Characteristics',
          'Special Char. Class.',
          'Methods',
          'Control Method',
          'Reaction Plan',
          // 'Corrective Action',
          // 'Process Capability',
        ].map((head, index) => (
          <th
            colSpan={colSpanBodyHeader(head)}
            rowSpan={head === 'Characteristics' || head === 'Methods' ? 1 : 3}
            key={index + head}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr>
        {[
          'No.',
          'Product',
          'Process',
          'Product / Process Specification / Tolerance',
          'Evaluation Measurement Technique',
          'Sample',
        ].map((head, index) => (
          <th
            colSpan={head === 'Sample' ? 3 : 1}
            rowSpan={head === 'Sample' ? 1 : 2}
            key={index + head}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr>
        <th>Size</th>
        <th>Freq.</th>
        <th>Resp.</th>
      </tr>
      {moduleData.processes &&
        moduleData.processes.map((process: IProcess, index: number) => (
          <React.Fragment key={index}>
            {process.specs.map((spec, indx) => (
              <React.Fragment key={indx}>
                <tr>
                  {indx === 0 && (
                    <>
                      <td rowSpan={process.specs.length} align="center">
                        {process.process_no}
                      </td>
                      <td rowSpan={process.specs.length} align="center">
                        {process.process_name}
                      </td>
                    </>
                  )}
                  {spec?.machine_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.machine_rowSpan as number) || 1}
                    >
                      {spec?.machine}
                      {spec?.machine && spec?.tool_no ? '/' : ''}
                      {spec?.tool_no}
                    </td>
                  )}

                  <td align="center">{indx + 1}</td>

                  {/* product or process */}
                  {spec?.char_product_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.char_product_rowSpan as number) || 1}
                    >
                      {spec?.char_product}
                    </td>
                  )}
                  {spec?.char_process_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.char_process_rowSpan as number) || 1}
                    >
                      {spec?.char_process}
                    </td>
                  )}
                  <td align="center">
                    {spec.special_character && spclChar[spec.special_character]}
                  </td>
                  {/* specification and tolerance values */}
                  <td align="center">
                    {spec.control_product}
                    {spec.control_process && spec.control_product ? '/' : ''}
                    {spec.control_process}
                    {/* {spec.control_product_remark &&
                      `* ${spec.control_product_remark}`} */}
                  </td>
                  {/* Check method or evaluation measurement technique */}
                  <td align="center">{spec.methods_measure}</td>

                  {/* Sample Size	 */}
                  {spec?.methods_size_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.methods_size_rowSpan as number) || 1}
                    >
                      {spec?.methods_size}
                    </td>
                  )}
                  {spec?.methods_by_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.methods_by_rowSpan as number) || 1}
                    >
                      {spec?.methods_by}
                    </td>
                  )}
                  {spec?.responsibility_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.responsibility_rowSpan as number) || 1}
                    >
                      {spec?.responsibility}
                    </td>
                  )}
                  {/* CONTROL METHOD	 */}
                  {spec?.methods_process_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.methods_process_rowSpan as number) || 1}
                    >
                      {spec?.methods_process}
                    </td>
                  )}
                  {/* <td
                    align="center"
                    style={{ border: '1px solid black', fontSize: '2rem' }}
                  >
                    {spec?.conf_level && confLevel[spec.conf_level]}
                  </td> */}
                  {spec?.reaction_plan_include && (
                    <td
                      align="center"
                      style={{ border: '1px solid black' }}
                      rowSpan={(spec?.reaction_plan_rowSpan as number) || 1}
                    >
                      {spec?.reaction_plan}
                    </td>
                  )}
                </tr>
              </React.Fragment>
            ))}
          </React.Fragment>
        ))}
    </tbody>
  );
};

export default TBody;
