import { useState } from 'react';
import { IconButton, MenuItem, Tooltip } from '@mui/material';
import CustomSelect from '../../../../components/common/SelectField';
import ObservationInput from './ObservationInput';
import { handleChangeColorOnRemarks } from '../../helpers/handleChangeColorOnRemarks';
import DeleteIcon from '@mui/icons-material/Delete';
import { TableInput } from '../../../../components/common';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
}
const TBody = ({ isView, moduleData, tabValue }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData.setups[tabValue] &&
        moduleData.setups[tabValue].setup_list &&
        moduleData.setups[tabValue].setup_list.map(
          (spec: any, index: number) => (
            <tr>
              <td>{index + 1}</td>
              <td>{spec.parameter}</td>
              <td>{spec.specification}</td>
              <td>{spec.methods}</td>

              {Array.from({ length: 4 }, (_, obsIndex) => (
                <ObservationInput
                  {...{ spec, isView, obsIndex }}
                  obsNumber={0}
                  checkType={spec.appearance}
                />
              ))}
              <th style={{ textTransform: 'uppercase' }}>
                {isView &&
                  (spec.remarks === 'other'
                    ? spec.remarks_other
                    : spec.remarks)}
                {!isView &&
                  (spec.remarks === 'other' ? (
                    <>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                        }}
                      >
                        <TableInput
                          isView={isView}
                          name="cycle_time"
                          type="text"
                          defaultValue={spec.remarks_other}
                          onChange={(e) => {
                            spec.remarks_other = e.target.value;
                            setRender((prev) => !prev);
                          }}
                        />
                        <Tooltip title="Delete">
                          <IconButton
                            aria-label="delete"
                            onClick={(e) => {
                              spec.remarks_other = '';
                              spec.remarks = '';
                              setRender((prev) => !prev);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </div>
                    </>
                  ) : (
                    <CustomSelect
                      name="dropdown"
                      value={spec.remarks || ''}
                      onChange={(e) => {
                        spec.remarks = e.target.value;
                        handleChangeColorOnRemarks(spec, spec.remarks);
                        setRender((prev) => !prev);
                      }}
                    >
                      <MenuItem value="none">NONE</MenuItem>
                      <MenuItem value="ok">OK</MenuItem>
                      <MenuItem value="under deviation">
                        UNDER DEVIATION
                      </MenuItem>
                      <MenuItem value="tbc">TBC</MenuItem>
                      <MenuItem value="other">OTHER</MenuItem>
                    </CustomSelect>
                  ))}
              </th>
            </tr>
          )
        )}
    </tbody>
  );
};

export default TBody;
