import moment from 'moment';

export const processes = [
  {
    process_date: moment(),
    vendor_id: null,
    delivery_adherance_perc:0,
    delivery_adherance_marks:0,
    delivery_customer_message:'',
    delivery_customer_marks:0,
    delivery_otd_days:0,
    delivery_otd_marks:0,
    quality_lot_rejection_perc:0,
    quality_lot_rejection_marks:0,
    quality_customer_status_qty:0,
    quality_customer_status_marks:0,
    quality_customer_disruption_qty:0,
    quality_customer_disruption_marks:0,
    quality_recall_qty:0,
    quality_recall_marks:0,
    premium_freight_qty:0,
    premium_freight_marks:0,
    customer_complaint_qty:0,
    customer_complaint_marks:0,
    warranty_qty:0,
    warranty_marks:0,
    line_rejection_qty:0,
    line_rejection_marks:0,
    remark:''
  },
];