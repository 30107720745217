import { useState } from 'react';
//mport ObservationInput from './ObservationInput';
import CustomSelect from '../../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../../components/common';

interface IProps {
  apiData: any;
}
const TBody = ({ apiData }: IProps) => {
  const [render, setRender] = useState(false);
  const { reasonCounts } = apiData;
  return (
    <tbody>
      {reasonCounts.map((data: any, index: number) => {
        const { customerName, qty, partNumber } = data;

        return (
          <tr>
            <td>{partNumber}</td>
            <td>{customerName}</td>
            <td>{qty}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TBody;
