import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import formatDate from '../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

// const shiftResult = {
//   true: '✓',
//   false: '⨯',
// };

interface IProps {
  moduleData: any;
  isView: boolean;
  process: any;
  index: number;
  // handelDeleteRows: any;
  // setIsOpenPopup: any;
  // setPartIdx: any;
}
const TBodyRow3 = ({
  moduleData,
  isView,
  process,
  index,
}: // handelDeleteRows,
// setIsOpenPopup,
// setPartIdx,
IProps) => {
  const [render, setRender] = useState(false);

  return (
    <React.Fragment key={index + 10.1 + 'heading '}>
      <tr>
        <th colSpan={2}>
          {' '}
          {isView ? (
            formatDate(process?.prob_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(process?.prob_date)}
              onChange={(date) => {
                process.prob_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <td colSpan={8}>
          <TableInput
            isView={isView}
            name="checking_of_problem"
            type="text"
            value={process.checking_of_problem}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td colSpan={5}>
          <TableInput
            isView={isView}
            name="operator"
            type="text"
            value={process.operator}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td colSpan={20}>
          <TableInput
            isView={isView}
            name="maintenance_action"
            type="text"
            value={process.maintenance_action}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        <th colSpan={5}>
          {' '}
          {isView ? (
            formatDate(process?.solved_problem_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(process?.solved_problem_date)}
              onChange={(date) => {
                process.solved_problem_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
    </React.Fragment>
  );
};

export default TBodyRow3;
