import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import moment from 'moment';
import AlertMui from '../../../../components/common/AlertMui';

import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../../components/common/FormControlMui';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import useConfirm from '../../../../components/common/useConfirm';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
interface Iprop {
  setIsNewPartModal: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setPartId: React.Dispatch<React.SetStateAction<string | null>>;
}

interface Vendor {
  supplier_name: string;
  supplier_code: string;
}

export interface IPart {
  _id?: string;
  vendor_code?: string | null;
  erp_drawing_no: string | null;
  fox_pro_drg_no: string | null;
  part_number: string | null;
  part_name: string | null;
  vendor_id: any;
  date_orig: moment.Moment;
  createdAt?: Date;
  vendor_code_two?: string | null;
  customer_id?: any;
  vendor_id_two: any;
  vendor_code_three?: string | null;
  vendor_id_three: any;
  vendor_code_four?: string | null;
  vendor_id_four: any;
  vendor_code_five?: string | null;
  vendor_id_five: any;
}

const AddEditPart = ({ setIsNewPartModal, id, setPartId }: Iprop) => {
  const [partData, setPartData] = useState<IPart>({
    part_number: null,
    part_name: null,
    vendor_id: null,
    vendor_id_two: null,
    vendor_id_three: null,
    vendor_id_four: null,
    vendor_id_five: null,
    erp_drawing_no: null,
    fox_pro_drg_no: null,
    customer_id: null,
    date_orig: moment(),
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [supplierData, setSupplierData] = useState([]);
  const [riPartData, setRiPartData] = useState([]);
  const [autoCompleteSupplierValue, setAutoCompleteVendorValue] = useState({});
  const [autoCompleteSupplierValueTwo, setAutoCompleteVendorValueTwo] =
    useState({});
  const [autoCompleteSupplierValueThree, setAutoCompleteVendorValueThree] =
    useState({});
  const [autoCompleteSupplierValueFour, setAutoCompleteVendorValueFour] =
    useState({});
  const [autoCompleteSupplierValueFive, setAutoCompleteVendorValueFive] =
    useState({});
  const [render, setRender] = useState(false);
  const [alert, setAlert] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'DRAFT PART',
    `Are you sure you want to ${id ? 'update' : 'save'} this part?`
  );
  const [customerData, setCustomerData] = useState([]);

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPartData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    axios
      .get('/api/vendorRoute/')
      .then((res) => {
        setSupplierData(res.data.results);
      })
      .catch((err) => console.log(err));
    axios
      .get('/api/riDepartment/riPart/riPartList/autoComplete')
      .then((res) => {
        setRiPartData(res.data);
      })
      .catch((err) => console.log(err));
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomerData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/riDepartment/riPart/${id}`)
        .then((res) => {
          setPartData({
            ...res.data,
            vendor_code: res.data?.vendor_id?.vender_code,
            vendor_code_two: res.data?.vendor_id?.vender_code,
            vendor_code_three: res.data?.vendor_id?.vender_code,
            vendor_code_four: res.data?.vendor_id?.vender_code,
            vendor_code_five: res.data?.vendor_id?.vender_code,
          });
          setAutoCompleteVendorValue(res.data.vendor_id);
          setAutoCompleteVendorValueTwo(res.data.vendor_id_two);
          setAutoCompleteVendorValueThree(res.data.vendor_id_three);
          setAutoCompleteVendorValueFour(res.data.vendor_id_four);
          setAutoCompleteVendorValueFive(res.data.vendor_id_five);
        })
        .catch((err) => console.log(err));
    } else {
      setPartData((prev) => prev);
    }
  }, [id]);

  const onSubmit = async (e: any) => {
    e.preventDefault();

    const ans = await confirmSubmit();
    if (!ans) return;
    setIsSubmit(true);
    if (id) {
      const PartNoIndex = riPartData.findIndex(
        (item: any) =>
          item.part_number.toLowerCase() ===
            partData.part_number?.toLowerCase() &&
          item._id.toString() !== id.toString()
      );
      if (PartNoIndex !== -1) {
        window.alert('Part No. already exist !!');
        return;
      }
      await axios
        .put(`/api/riDepartment/riPart/${id}`, partData)
        .then((res) => {
          setIsSubmit(false);
          setIsNewPartModal(false);
          setPartId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsSubmit(false);
        });
    } else {
      const PartNoIndex = riPartData.findIndex(
        (item: any) =>
          item.part_number.toLowerCase() === partData.part_number?.toLowerCase()
      );
      if (PartNoIndex !== -1) {
        window.alert('Part No. already exist !!');
        return;
      }
      // const PartNameIndex = riPartData.findIndex(
      //   (item: any) =>
      //     item.part_name.toLowerCase() === partData.part_name?.toLowerCase()
      // );
      // if (PartNameIndex !== -1) {
      //   window.alert('Part Name already exist !!');
      //   return;
      // }
      await axios
        .post('/api/riDepartment/riPart', partData)
        .then((res) => {
          setIsSubmit(false);
          setIsNewPartModal(false);
          setPartId(null);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.err) {
            window.alert(err.response.data.err);
          }
          setIsSubmit(false);
        });
    }
  };

  return (
    <>
      <Box>
        <FormControlMui handleSubmit={onSubmit}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton
              label={`${id ? 'update' : 'submit'}`}
              type="submit"
              disabled={isSubmit}
            />
          </div>
          <StyledField>
            <InputMuiCustom
              type="text"
              name="part_number"
              placeholder="Part Number"
              required={true}
              onChange={handlePartData}
              value={partData?.part_number || ''}
            />
            <InputMuiCustom
              type="text"
              name="part_name"
              placeholder="Part Name"
              onChange={handlePartData}
              value={partData?.part_name || ''}
              required={true}
            />
            <InputMuiCustom
              type="text"
              name="erp_drawing_no"
              placeholder="ERP No. / Drawing No."
              onChange={handlePartData}
              value={partData?.erp_drawing_no || ''}
              // required={true}
            />
            <InputMuiCustom
              type="text"
              name="fox_pro_drg_no"
              placeholder="Fox Pro No. / Ref. Drg. No."
              onChange={handlePartData}
              value={partData?.fox_pro_drg_no || ''}
              // required={true}
            />
            <AutocompleteMuiCustom
              id="Vendor"
              label="Vendor 1"
              option_name="vender_name"
              arrayofObj={supplierData}
              value={autoCompleteSupplierValue}
              onChange={(e, value) => {
                if (!value) {
                  partData.vendor_code = null;
                  partData.vendor_id = null;
                }
                partData.vendor_id = value?._id;
                partData.vendor_code = value?.vender_code;
                setAutoCompleteVendorValue(value);
                setRender((prev) => !prev);
              }}
            />
            <AutocompleteMuiCustom
              id="Vendor_2"
              label="Vendor 2"
              option_name="vender_name"
              arrayofObj={supplierData}
              value={autoCompleteSupplierValueTwo}
              onChange={(e, value) => {
                if (!value) {
                  partData.vendor_code_two = null;
                  partData.vendor_id_two = null;
                }
                partData.vendor_id_two = value?._id;
                partData.vendor_code_two = value?.vender_code;
                setAutoCompleteVendorValueTwo(value);
                setRender((prev) => !prev);
              }}
            />
            <AutocompleteMuiCustom
              id="Vendor_3"
              label="Vendor 3"
              option_name="vender_name"
              arrayofObj={supplierData}
              value={autoCompleteSupplierValueThree}
              onChange={(e, value) => {
                if (!value) {
                  partData.vendor_code_three = null;
                  partData.vendor_id_three = null;
                }
                partData.vendor_id_three = value?._id;
                partData.vendor_code_three = value?.vender_code;
                setAutoCompleteVendorValueThree(value);
                setRender((prev) => !prev);
              }}
            />
            <AutocompleteMuiCustom
              id="Vendor_4"
              label="Vendor 4"
              option_name="vender_name"
              arrayofObj={supplierData}
              value={autoCompleteSupplierValueFour}
              onChange={(e, value) => {
                if (!value) {
                  partData.vendor_code_four = null;
                  partData.vendor_id_four = null;
                }
                partData.vendor_id_four = value?._id;
                partData.vendor_code_four = value?.vender_code;
                setAutoCompleteVendorValueFour(value);
                setRender((prev) => !prev);
              }}
            />
            <AutocompleteMuiCustom
              id="Vendor_5"
              label="Vendor 5"
              option_name="vender_name"
              arrayofObj={supplierData}
              value={autoCompleteSupplierValueFive}
              onChange={(e, value) => {
                if (!value) {
                  partData.vendor_code_five = null;
                  partData.vendor_id_five = null;
                }
                partData.vendor_id_five = value?._id;
                partData.vendor_code_five = value?.vender_code;
                setAutoCompleteVendorValueFive(value);
                setRender((prev) => !prev);
              }}
            />

            <AutocompleteMuiCustom
              id={'customer_name'}
              label={'Customer'}
              option_name={'customer_name'}
              arrayofObj={customerData}
              value={partData.customer_id || ''}
              onChange={(e, value) => {
                partData.customer_id = value;
                setRender((prev) => !prev);
              }}
            />
            <InputMuiCustom
              type="text"
              name="vendor_code"
              placeholder="Vendor Code 1"
              // disabled
              value={partData?.vendor_code || ''}
            />
            <InputMuiCustom
              type="text"
              name="vendor_code_two"
              placeholder="Vendor Code 2"
              // disabled
              value={partData?.vendor_code_two || ''}
            />
            <InputMuiCustom
              type="text"
              name="vendor_code_three"
              placeholder="Vendor Code 3"
              // disabled
              value={partData?.vendor_code_three || ''}
            />
            <InputMuiCustom
              type="text"
              name="vendor_code_four"
              placeholder="Vendor Code 4"
              // disabled
              value={partData?.vendor_code_four || ''}
            />
            <InputMuiCustom
              type="text"
              name="vendor_code_five"
              placeholder="Vendor Code 5"
              // disabled
              value={partData?.vendor_code_five || ''}
            />
            <DatePickerMui
              label="DATE (Original)"
              onChange={(date) => {
                partData.date_orig = date;
                setRender((prev) => !prev);
              }}
              value={moment(partData.date_orig) || null}
            />
          </StyledField>
        </FormControlMui>
      </Box>
      <DialogSubmit />
      <AlertMui
        alert={alert}
        message={
          'Please select a customer from the list. The customer field cannot be left empty'
        }
        setAlert={setAlert}
        severity={'warning'}
      />
    </>
  );
};

export default AddEditPart;
