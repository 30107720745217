import moment from 'moment';
import { allMonths } from '../../../../utils/helpers/allMonths';

export class ModuleDataHandler {
  moduleData: any;

  constructor(moduleData: any) {
    this.moduleData = moduleData;
  }

  calculateCumulative() {
    const {
      change_freq,
      list,
      starting_qty = 0,
      change_freq_days,
    } = this.moduleData;

    const daysToAdd = change_freq_days;
    let starting_date = (list.length > 0 && moment(list[0].date)) || null;
    let next_date = starting_date && starting_date.add(daysToAdd, 'days');
    console.log(next_date);
    let counter = starting_qty || 0;

    list.length > 0 &&
      list.length > 0 &&
      list?.forEach((item: any, index: number) => {
        counter += Number(item.checked_qty || 0);
        item.cumm_qty = counter;

        if (
          item.cumm_qty >= change_freq ||
          (next_date && next_date.isSame(moment(item.date), 'day'))
        ) {
          counter = 0;
          item.status = 'LIMIT EXCEEDED';
          item.action_taken = 'CHANGE PENDING';
          starting_date = moment(item.date);
          next_date = starting_date && starting_date.add(daysToAdd, 'days');
        } else if (item.action_taken === 'CHANGE COMPLETED') {
          if (item.status === 'LIMIT EXCEEDED') {
            counter = 0;
            starting_date = moment(item.date);
            next_date = starting_date && starting_date.add(daysToAdd, 'days');
          } else {
            counter = 0;
            starting_date = moment(item.date);
            item.status = '';
            next_date = starting_date && starting_date.add(daysToAdd, 'days');
          }
        } else if (
          item.cumm_qty < change_freq &&
          next_date &&
          !next_date.isSame(moment(item.date), 'day')
        ) {
          item.status = '';
        }

        // if (
        //   (item.cumm_qty >= change_freq ||
        //     (next_date && next_date.isSame(moment(item.date), 'day'))) &&
        //   item.action_taken !== 'CHANGE COMPLETED'
        // ) {
        //   counter = 0;
        //   item.action_taken = 'CHANGE PENDING';
        //   item.status = 'LIMIT EXCEEDED';
        //   item.hide_status_action = true;
        // }

        // if (
        //   item.cumm_qty >= change_freq &&
        //   item.action_taken === 'CHANGE COMPLETED'
        // ) {
        //   counter = 0;
        //   item.action_taken = 'CHANGE COMPLETED';
        //   item.status = 'LIMIT EXCEEDED';
        //   item.hide_status_action = true;
        //   starting_date = moment(item.date);
        //   next_date = starting_date && starting_date.add(daysToAdd, 'days');
        // }

        // if (
        //   item.cumm_qty < change_freq &&
        //   next_date &&
        //   !next_date.isSame(moment(item.date), 'day') &&
        //   (item.action_taken === 'CHANGE PENDING' ||
        //     item.action_taken === 'CHANGE COMPLETED')
        // ) {
        //   console.log(item.cumm_qty);
        //   item.action_taken = '';
        //   item.status = '';
        //   item.hide_status_action = false;
        // }

        // if (
        //   next_date &&
        //   next_date.isSame(moment(item.date), 'day') &&
        //   item.action_taken === 'CHANGE COMPLETED'
        // ) {
        //   counter = 0;
        //   starting_date = moment(item.date);
        //   next_date = starting_date && starting_date.add(daysToAdd, 'days');
        // }
      });
  }

  // calculateChangeFrequency() {}
}
