import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
  tabValue: number;
}
const THead = ({ moduleData, isView, tabValue }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading=""
      />
      <tr>
        <th colSpan={12}>Plant-45 Faridabad</th>
      </tr>
      <tr>
        <th colSpan={12} style={{ fontSize: '1.6rem' }}>
          PDIR
        </th>
      </tr>
      <tr>
        <th colSpan={12} style={{ fontSize: '1.6rem' }}>
          FINAL PRODUCT
        </th>
      </tr>

      <tr>
        <th colSpan={3} align="left">
          Customer:
          {moduleData.customer_name
            ? moduleData?.customer_name
            : moduleData?.part_id?.customer_id?.customer_name}
        </th>
        <th colSpan={2} align="left">
          Date of Inspection:-
        </th>
        <th
          colSpan={2}
          align="left"
          data-val={formatDate(moduleData?.pdir_date)}
        >
          {isView ? (
            formatDate(moduleData.pdir_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.pdir_date)}
              onChange={(date) => {
                moduleData.pdir_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={5} align="left">
          Quality Plan No. :{moduleData?.pdirs[tabValue]?.quality_plan_no}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Part No:{' '}
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.part_id?.part_number}
        </th>
        <th colSpan={2} align="left">
          Invoice No.:
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="invoice_no"
            type="text"
            value={moduleData?.pdirs[tabValue]?.invoice_no}
            onChange={(e) => {
              moduleData.pdirs[tabValue].invoice_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={5} align="left">
          Drawing Rev No. :{moduleData?.pdirs[tabValue]?.drawing_rev_no}
          {/* Drawing No.: */}
          {/* {moduleData?.pdirs[tabValue]?.drawing_no} */}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Part Description:{' '}
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.part_id?.part_name}
        </th>
        <th colSpan={2} align="left">
          Lot Qty:
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="qty"
            type="text"
            value={moduleData?.pdirs[tabValue]?.qty}
            onChange={(e) => {
              moduleData.pdirs[tabValue].qty = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={2} align="left">
          Batch Code:
        </th>
        <th colSpan={3} align="left">
          <TableInput
            name="batch_code"
            type="text"
            value={moduleData?.pdirs[tabValue]?.batch_code}
            onChange={(e) => {
              moduleData.pdirs[tabValue].batch_code = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={7} align="left">
        </th>
        <th colSpan={2} align="left">
          Report No:
        </th>
        <th colSpan={3} align="left">
          <TableInput
            name="report_no"
            type="text"
            value={moduleData?.pdirs[tabValue]?.report_no}
            onChange={(e) => {
              moduleData.pdirs[tabValue].report_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={12}>Sketch :</th>
      </tr>
      <tr>
        <th colSpan={12} data-img={moduleData.pdirs[tabValue]?.file}>
          <img
            src={moduleData.pdirs[tabValue]?.file}
            alt="image"
            height={400}
            width={'70%'}
          />
        </th>
      </tr>
      <tr>
        {['S.NO', 'Parameter', 'specification', 'Inspection Method'].map(
          (head) => (
            <th style={{ textTransform: 'uppercase' }}>{head}</th>
          )
        )}
        {Array.from({ length: 5 }, (_, index) => (
          <th style={{ width: '90px' }} key={index + 'Heading'}>
            {index + 1}
          </th>
        ))}

        <th style={{ textTransform: 'uppercase' }}>Status</th>
        <th style={{ textTransform: 'uppercase' }} colSpan={2}>
          Remarks
        </th>
      </tr>
    </thead>
  );
};

export default THead;
