export const listConstant = {
  childHeader: 'Material Testing Plan (BOP Child Parts) List',
  addButtonLable: 'New Material TEST PLAN',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Material Testing Plan (BOP Child Parts)',
  theadHeader: 'Material Testing Plan - BOP Child Parts',
  extracolSpan: 6,
};

export const checkListList = {
  childHeader: 'MATERIAL TEST PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'materialTestPlanOther',
  gettableDataurl: 'materialTestPlanOther/monthly',
  getPlanByid: 'materialTestPlanOther',
  createPlanByid: 'materialTestPlanOther',
  updatePlanByid: 'materialTestPlanOther',
  sync: 'materialTestPlanOther/handleSync',
  getAllCheckList: 'materialTestPlanOther/getAllCheckList',
  checkListStatus: 'materialTestPlanOther/handleStatus',
  checklistUpload: 'materialTestPlanOther/handleUpload',
  checklistDeleteImage: 'materialTestPlanOther/handleDeleteImage',
};

export const tilesConstant = {
  childHeaderText: 'Material Testing Plan (BOP Child Parts)',
};

export const navigationConstant = {
  createPlanSchedule: '/common/materialTestPlanOtherCreate',
};
