export const listConstant = {
  childHeader: 'Layered Audit Plan',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE Plan',
};

export const createConstant = {
  childHeader: 'Layered Audit Plan ',
  theadHeader: 'Layered Audit Plan ',
  extracolSpan: 5,
};

export const checkListList = {
  childHeader: 'Layered Audit Plan  STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'layeredAuditPlanRoute',
  gettableDataurl: 'layeredAuditPlanRoute/monthly',
  getPlanByid: 'layeredAuditPlanRoute',
  createPlanByid: 'layeredAuditPlanRoute',
  updatePlanByid: 'layeredAuditPlanRoute',
  sync: 'layeredAuditPlanRoute/handleSync',
  getAllCheckList: 'layeredAuditPlanRoute/getAllCheckList',
  checkListStatus: 'layeredAuditPlanRoute/handleStatus',
  checklistUpload: 'layeredAuditPlanRoute/handleUpload',
  checklistDeleteImage: 'layeredAuditPlanRoute/handleDeleteImage',
  checklistData: 'layeredAuditPlanRoute/checklistRoute',
};

export const navigationConstant = {
  createPlanSchedule: '/common/layeredAuditPlanCreate',
  createChecklist: '/common/layeredAuditPlanChecklistCreate',
};
