import moment from 'moment';
import { useState } from 'react';

import { TableCompHead } from '../../../../../../components/ui';
import formatDate from '../../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import { TableInput } from '../../../../../../components/common';
import CategoryList from '../../../../../attributeGaugesPlan/masterlistofGauges/CategoryList';

interface IProps {
  moduleData: any;
  isView: boolean;
  area: any;
  category: any;
  customer: any;
  frequency: any;
}

const headings = [
  'S.No.',
  'Audit point',
  'Yes',
  'No',
  'If Marked N "Corrective Actions"',
  'Responsibility',
  'Due Date',
  'Remarks',
];
const THead = ({
  moduleData,
  isView,
  area,
  category,
  customer,
  frequency,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={8}
        rowSpan={3}
        labels={['FORMAT NO', 'REV. NO.', 'REVISION DATE']}
        values={
          area === 'Layer - III'
            ? ['FM/XLV/QA/0101c', '00', '20.10.2022']
            : ['FM/XLV/QA/0101d', '00', '20.10.2022']
        }
        heading={
          area === 'Layer - III'
            ? 'Layer-III Process Audit'
            : area === 'LAYER-IV' && category === 'Plant Head'
            ? 'LAYER-IV AUDIT CHECK SHEET'
            : 'Layer-IV Process Audit'
        }
      />
      <tr>
        <th colSpan={2} align="left">
          DATE:{' '}
          {isView ? (
            formatDate(moduleData.d1)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d1)}
              onChange={(date: any) => {
                moduleData.d1 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left">
          Audit by:{' '}
          <TableInput
            isView={isView}
            name="audit_by"
            type="text"
            value={moduleData?.audit_by}
            onChange={(e: any) => {
              moduleData.audit_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          Duration :
          <TableInput
            isView={isView}
            name="duration"
            type="text"
            value={moduleData.duration}
            onChange={(e: any) => {
              moduleData.duration = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          Frequency:{' '}
          <TableInput
            isView={isView}
            name="frequency"
            type="text"
            value={moduleData.frequency}
            onChange={(e: any) => {
              moduleData.frequency = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        {headings.map((heading) => {
          return <th>{heading}</th>;
        })}
      </tr>
    </thead>
  );
};

export default THead;
