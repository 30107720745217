import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { useQuery } from '../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const test = useQuery('test') === 'true' ? true : false;
  return (
    <>
      <tr>
        <td colSpan={3} style={{ textTransform: 'uppercase' }}>
          Line Incharge(prod):
        </td>
        <td colSpan={test ? 2 : 1}>
          <TableInput
            isView={isView}
            name="line_incharge_prod1"
            type="text"
            value={moduleData.line_incharge_prod1}
            onChange={(e) => {
              moduleData.line_incharge_prod1 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td colSpan={2} style={{ textTransform: 'uppercase' }}>
          Line Incharge(QA):
        </td>
        <td colSpan={test ? 4 : 3}>
          <TableInput
            isView={isView}
            name="line_incharge_qa1"
            type="text"
            value={moduleData.line_incharge_qa1}
            onChange={(e) => {
              moduleData.line_incharge_qa1 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td colSpan={2} style={{ textTransform: 'uppercase' }}>
          Line Incharge(prod):
        </td>
        <td colSpan={test ? 4 : 3}>
          <TableInput
            isView={isView}
            name="line_incharge_prod2"
            type="text"
            value={moduleData.line_incharge_prod2}
            onChange={(e) => {
              moduleData.line_incharge_prod2 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td colSpan={2} style={{ textTransform: 'uppercase' }}>
          Line Incharge(QA):
        </td>
        <td colSpan={test ? 7 : 6}>
          <TableInput
            isView={isView}
            name="line_incharge_qa1"
            type="text"
            value={moduleData.line_incharge_qa2}
            onChange={(e) => {
              moduleData.line_incharge_qa2 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
    </>
  );
};

export default TFoot;
