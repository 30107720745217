import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../components/ui';
import TBody from './TBody';
//import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  apiData: any;
  reason: String;
}
const TController = ({ apiData, reason }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <>
          <THead reason={reason} />
          <TBody apiData={apiData} />{' '}
          {/*apiData.length > 0 && (
            <TFoot total={apiData[apiData.length - 1]['cumulativeFrequency']} />
          )*/}
        </>
      </RenderTable>
    </Box>
  );
};

export default TController;
