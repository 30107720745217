import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
// import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
// import { SearchBar } from '../../components/common/SearchBar';
import {
  CustomPagination,
  PersitedDatePicker,
  // TableCustom,
} from '../../../components/common';
// import useDebounce from '../../hooks/useDebounce';
// import {
//   EditIconButton,
//   DeleteIconButton,
// } from '../../components/common/button/CustomIconButton';
// import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/interface';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../../services/auth/AuthProvider';
// import ApprovalSelect from './components/tablelist/ApprovalSelect';
import SupervisorTable from './components/tablelist/SupervisorTable';
import AdminTable from './components/tablelist/AdminTable';

const statusObj = {
  pending: 1,
  accepted: 2,
  rejected: 3,
};

const List = () => {
  const { state } = useAuth();
  const date = useQuery('date');
  const [tableData, setTableData] = useState<IData[]>([]);
  const [render, setRender] = useState(false);
  const { user, IS } = state;
  const navigate = useNavigate();
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE ',
    'Are you sure you want to delete this document?',
  );

  useEffect(() => {
    let userType = '';
    user?.userType === IS.Admin
      ? (userType = 'admin')
      : (userType = 'supervisor');

    axios
      .get(`/api/jdReworkRoute/monthly/${userType}/${date}`)
      .then((res) => {
        if (res.data) setTableData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render, date]);

  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    axios
      .delete(`/api/jdReworkRoute/${id}`)
      .then(() => {
        alert('document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => {
        console.log(err);
      });
    return;
  };

  const updateStatus = async (id: string, documentIndex: number) => {
    let isConfirm = window.confirm('Are you sure you want to update status');
    if (isConfirm && tableData.length > 0) {
      axios
        .patch(`/api/jdReworkRoute/updateStatus/${id}`, {
          is_admin_request_no: tableData[documentIndex].is_admin_request_no,
          report_approved_by: user?.name,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Status Change successfully');
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleChangeStatus = (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number,
  ) => {
    const values = [...tableData];
    values[documentIndex].is_admin_request = null;
    values[documentIndex].is_admin_request_no = +e.target.value;
    setTableData(values);
  };

  return (
    <>
      <ChildHeader text="JD REWORK REGISTER">
        <AddButton
          onClick={() => navigate(`/common/createJdRework`)}
          label="NEW REPORT"
        />
      </ChildHeader>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box>
      {user?.userType === IS.Admin && (
        <AdminTable
          {...{
            handleChangeStatus,
            deleteDocument,
            updateStatus,
            statusObj,
            tableData,
          }}
        />
      )}
      {user?.userType === IS.Supervisor && (
        <SupervisorTable {...{ deleteDocument, tableData }} />
      )}
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
