import React, { useState, useEffect } from 'react';
import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import {
  CustomPagination,
  PersitedDatePicker,
  TableCustom,
  TextAreaInput,
} from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/interfaces';
import ViewFile from './components/ViewFile';
import formatDate from '../../../components/common/formatDate';
import TableScroll from '../../../components/ui/renderTable/TableScroll';

const tableHeaderObj = [
  [
    {
      text: 'S.NO.',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'CATEGORY',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '150px' },
    },
    {
      text: 'PRODUCT FAMILY',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '150px' },
    },
    {
      text: 'ISSUE REPORTED DATE',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '150px' },
    },
    {
      text: 'CUSTOMER NAME',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '150px' },
    },
    {
      text: 'PART NUMBER',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'PART NAME',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'DEFECT REPORTED',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '250px' },
    },
    {
      text: 'DEFECT PHOTO',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'DEFECT CATEGORY',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '250px' },
    },
    {
      text: 'CUSTOMER END QTY.',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '250px' },
    },
    {
      text: '1ST TIME/REPEATED/NDP',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '250px' },
    },
    {
      text: 'ROOT CAUSE',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '250px' },
    },
    {
      text: 'CORRECTIVE AND PREVENTIVE ACTION',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '250px' },
    },
    {
      text: 'WK-3',
      rowspan: 1,
      colspan: 2,
      style: { background: '#38d9a9', minWidth: '200px' },
    },
    {
      text: 'WK-4',
      rowspan: 1,
      colspan: 2,
      style: { background: '#38d9a9', minWidth: '200px' },
    },
    {
      text: 'WK-5',
      rowspan: 1,
      colspan: 2,
      style: { background: '#38d9a9', minWidth: '200px' },
    },
    {
      text: 'ACTION',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
  ],
  [
    {
      text: 'SCORE',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'REMARKS',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'SCORE',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'REMARKS',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'SCORE',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'REMARKS',
      rowspan: 1,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
  ],
];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [complaintId, setComplaintId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [filterCustomer, setFilterCustomer] = useState<any>([]);
  const [filterDefectsReported, setFilterDefectsReported] = useState<any>([]);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const customer = useQuery('customer');
  const defect = useQuery('defects');
  const date = useQuery('date');

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?',
  );

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.customer_name,
            value: customer.customer_name,
          }));
          setFilterCustomer([...formattedData]);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get('/api/defectRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((defect: any) => ({
            name: defect.defect_name,
            value: defect.defect_name,
          }));
          setFilterDefectsReported([...formattedData]);
        }
      })
      .catch((err) => console.log(err));
  }, []);
  const fetchData = async () => {
    if (date) {
      const res = await axios
        .get(
          `/api/pastTroubleDatabaseRoutes/${date}/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&customer=${customer}&defects=${defect}`,
        )
        .catch((err) => {
          console.log(err);
        });

      setData(res?.data?.results);
      setPerPage(res?.data?.perPage);
      setTotalPages(res?.data?.pages);
    }
  };

  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/pastTroubleDatabaseRoutes/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    isModal,
    filter,
    customer,
    defect,
    date,
  ]);
  return (
    <>
      <ChildHeader text="PAST TROUBLE DATABASE"></ChildHeader>
      <SearchFilterContainer filter={true}>
        <Box style={{ display: 'flex', margin: 2 }}>
          <PersitedDatePicker
            label={`Search by Year`}
            views={['year']}
            sx={{ minWidth: 300 }}
          />

          <FilterBy
            sx={{ minWidth: 500, marginLeft: 15 }}
            name="customer"
            label="Filter By Customer"
            options={filterCustomer}
          />
          <FilterBy
            sx={{ minWidth: 300, marginLeft: 2 }}
            name="defects"
            label="Filter By Defects Reprted"
            options={filterDefectsReported}
          />
        </Box>
      </SearchFilterContainer>

      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: IData, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">{item.category}</TableCell>
                    <TableCell align="center">{item.product_family}</TableCell>
                    <TableCell align="center">
                      {item.issue_reported_date &&
                        formatDate(item.issue_reported_date)}
                    </TableCell>
                    <TableCell align="center">
                      {item.customer_id?.customer_name}
                    </TableCell>
                    <TableCell align="center">
                      {item.part_id?.part_number}
                    </TableCell>
                    <TableCell align="center">
                      {item.part_id?.part_name}
                    </TableCell>
                    <TableCell align="center">
                      <TextAreaInput
                        value={item.defect_id?.defect_name}
                        style={{ minWidth: '250px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <ViewIconButton
                        tooltipTitle="view"
                        onClick={() => {
                          setId(item._id || null);
                          setIsFileOpenPopup(true);
                        }}
                      />
                    </TableCell>
                    <TableCell align="center">{item.defect_category}</TableCell>
                    <TableCell align="center">
                      {item.customer_end_qty}
                    </TableCell>
                    <TableCell align="center">
                      {item.firstTime_repeated_ndp}
                    </TableCell>
                    <TableCell align="center">
                      <TextAreaInput
                        value={item.root_cause}
                        style={{ minWidth: '250px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextAreaInput
                        value={item.corrective}
                        style={{ minWidth: '250px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell align="center">{item.score_1}</TableCell>
                    <TableCell align="center">{item.remarks_1}</TableCell>
                    <TableCell align="center">{item.score_2}</TableCell>
                    <TableCell align="center">{item.remarks_2}</TableCell>
                    <TableCell align="center">{item.score_3}</TableCell>
                    <TableCell align="center">{item.remarks_3}</TableCell>
                    <TableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setComplaintId(item?._id || '');
                            setIsModal(true);
                          }}
                        />

                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteDocument(item?._id || '');
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setComplaintId(null);
          setIsModal(false);
        }}
        title="Past Trouble Data"
      >
        <Create id={complaintId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
