import React from 'react';
import TBodyRow2 from './TBodyRow2';
import { useQuery } from '../../../../../hooks/UseQuery';
import TBodyRow3 from './TBodyRow3';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody3 = ({ moduleData, isView }: IProps) => {
  const checkSheetType = useQuery('checkSheetType');
  return (
    <>
      <tbody>
        <tr style={{ height: '50px' }}>
          <td colSpan={40}></td>
        </tr>
        <tr>
          <th colSpan={2}>दिनांक</th>
          <th colSpan={8}>समस्या का ब्यॊरा</th>
          <th colSpan={5}>आपरेटर का नाम</th>
          <th colSpan={20}>मेन्टेनेन्स के द्वारा लिए गये एक्शन का ब्यॊरा</th>
          <th colSpan={5}>समस्या सही करने की दिनांक</th>
        </tr>
        {moduleData &&
          checkSheetType &&
          moduleData?.[checkSheetType]?.third_data?.map(
            (process: any, index: number) => (
              <React.Fragment key={index + 1 + 'process'}>
                <TBodyRow3
                  {...{
                    moduleData,
                    process,
                    index,
                    isView,
                    // handelDeleteRows,
                    // setIsOpenPopup,
                    // setPartIdx,
                  }}
                />
              </React.Fragment>
            )
          )}
        <tr>
          <th colSpan={40} align="left">
            निर्देश: . :1. सभी अवलोकन (विचार) लिखे। 2. √ सही मार्क चैक पाईंट ठीक
            होने पर । 3. X क्रास मार्क चैक पाईंट न ऒके होने पर । 4. क्रप्य़ा
            मेन्टेनेन्स डिपार्ट्मेन्ट को सूचित करें यदि खुद ठीक न हो ।
          </th>
        </tr>
      </tbody>
    </>
  );
};

export default TBody3;
