import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import CustomSelect from '../../../../components/common/SelectField';
import { Grid, MenuItem } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  item: any;
  isView: any;
  handleDelete: any;
  index: number;
  firstDayOfMonth: any;
  lastDayOfMonth: any;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const TBodyRow = ({
  item,
  index,
  isView,
  handleDelete,
  setPartIdx,
  setIsOpenPopup,
  firstDayOfMonth,
  lastDayOfMonth,
}: IProps) => {
  const [_, setRowRender] = useState(false);

  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
    setRowRender((prev: any) => !prev);
  };

  return (
    <>
      <tr>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="number"
            name="s_no"
            value={item?.s_no ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center', minWidth: '200px' }}>
          <DatePickerMui
            label=""
            onChange={(date) => {
              item.date = moment(date || null);
              setRowRender((prev: any) => !prev);
            }}
            value={(item?.date && moment(item?.date)) || null}
            minDate={firstDayOfMonth}
            maxDate={lastDayOfMonth}
          />
        </td>

        <td style={{ width: '250px', minWidth: '250px' }}>
          {isView ? (
            item?.part_id && item.part_id?.part_name
          ) : (
            <Grid container xs={12} direction="row" alignItems={'center'}>
              <Grid item xs={10}>
                <p
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  {typeof item?.part_id === 'object' && item?.part_id !== null
                    ? item.part_id?.part_name
                    : item?.partName}
                  {!item?.part_id && 'Pick Part Name'}
                </p>
              </Grid>

              {item.part_id ? (
                <Grid item xs={2}>
                  <RemoveIconButton
                    tooltipTitle="remove part"
                    onClick={() => {
                      item.part_id = null;
                      item.partNumber = null;
                      item.partName = null;
                      setRowRender((prev: any) => !prev);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <AddIconButton
                    tooltipTitle="add part"
                    onClick={() => {
                      setIsOpenPopup(true);
                      setPartIdx(index);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="assy_part"
            value={item?.assy_part ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="sub_assy_part"
            value={item?.sub_assy_part ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="bend_tube"
            value={item?.bend_tube ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="child_part"
            value={item?.child_part ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="fixure"
            value={item?.fixure ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="data_provided"
            value={item?.data_provided ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="customer"
            value={item?.customer ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="offered_by"
            value={item?.offered_by ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="plant"
            value={item?.plant ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="status"
            value={item?.status ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="remarks"
            value={item?.remarks ?? null}
            onChange={(e) => {
              handleChange(e);
            }}
          />
        </td>
        {!isView && (
          <td style={{ textAlign: 'center' }}>
            <DeleteIconButton
              tooltipTitle="DELETE"
              onClick={() => {
                handleDelete(item?._id, index);
              }}
            />
          </td>
        )}
      </tr>
    </>
  );
};

export default TBodyRow;
