export const confLevel: { [key: string]: string } = {
  PT: '△',
  PTL: '□',
  PS: '⬭',
  PM: '◇',
  QT: '▲',
  QTL: '■',
  QS: '⬬',
  QM: '◆',
};
