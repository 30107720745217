import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { IModule } from '../../interface';

interface IProps {
  isView: boolean;
  moduleData: IModule;
}
const TFoot = ({ isView, moduleData }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <th>FM/XLV/QA/05</th>
        <th>Issue No.: 00</th>
        <th colSpan={4}></th>
      </tr>
      <tr>
        <th>Rev No.</th>
        <th>Rev Date</th>
        <th align="left">
          PREPARED BY:
          <TableInput
            name="prepared_by"
            type="text"
            value={moduleData.prepared_by}
            onChange={(e) => {
              moduleData.prepared_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left">
          Verified By:
          <TableInput
            name="verified_by"
            type="text"
            value={moduleData.verified_by}
            onChange={(e) => {
              moduleData.verified_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={2}>
          Approved By:
          <TableInput
            name="approved_by"
            type="text"
            value={moduleData.approved_by}
            onChange={(e) => {
              moduleData.approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      {moduleData.revisionData &&
        new Array(5).fill({}).map((_, index) => (
          <tr>
            <th align="left" colSpan={1}>
              <TableInput
                name="rev_no"
                type="text"
                value={moduleData.revisionData[index]?.rev_no}
                onChange={(e) => {
                  if (!moduleData.revisionData[index])
                    moduleData.revisionData[index] = {};
                  moduleData.revisionData[index].rev_no = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </th>
            <th align="left" colSpan={1}>
              {isView ? (
                formatDate(moduleData.revisionData[index]?.rev_date)
              ) : (
                <DatePickerMui
                  value={moment(
                    moduleData.revisionData[index]?.rev_date || null
                  )}
                  onChange={(date) => {
                    if (!moduleData.revisionData[index])
                      moduleData.revisionData[index] = {};
                    moduleData.revisionData[index].rev_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </th>
            <th align="left" colSpan={4}>
              <TableInput
                name="reason"
                type="text"
                value={moduleData.revisionData[index]?.reason}
                onChange={(e) => {
                  if (!moduleData.revisionData[index])
                    moduleData.revisionData[index] = {};
                  moduleData.revisionData[index].reason = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </th>
          </tr>
        ))}
      <tr>
        <th colSpan={1} align="left">
          REMARKS
        </th>
        <th colSpan={5}>
          <TableInput
            name="remarks"
            type="text"
            value={moduleData.remarks}
            onChange={(e) => {
              moduleData.remarks = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
