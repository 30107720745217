import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  apiData: any;
}
const BarChart = ({ apiData}: IProps) => {
const defectNames = apiData.map((item: { defectName: any }) => item.defectName).slice(0, 10);
const rejQty = apiData.map((item: { frequency: any }) => item.frequency).slice(0, 10);

  const options = {
    plugins: {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'Defect Wise Bar Graph Analysis',
      },
    },
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: `${'Defects'}`,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          //stepSize: 5,
        },
        title: {
          display: true,
          text: 'Rej. Qty.',
        },
      },
    },
  };

  const backgroundColors = ['#4c6ef5'];
  const datasets = [
    {
      label: '',
      data: rejQty,
      backgroundColor: backgroundColors,
      borderWidth: 1,
      barThickness: 50,
    },
  ];
  const data = {
    labels: defectNames,
    datasets: datasets,
  };
  return <Bar options={options} data={data} />;
};

export default BarChart;
