import { BsJournalBookmarkFill } from 'react-icons/bs';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
const tiles = [
  {
    sNo: 1,
    link: `/common/productionLogBookList?documentType=groundFloor&groundFloorType=tig_welding`,
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="black" />,
    p: 'TIG WELDING',
    span: '',
    isIcon: true,
  },
  {
    sNo: 2,
    link: `/common/productionLogBookList?documentType=groundFloor&groundFloorType=spot_welding`,
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="black" />,
    p: 'SPOT WELDING',
    span: '',
    isIcon: true,
  },
  {
    sNo: 3,
    link: `/common/productionLogBookList?documentType=groundFloor&groundFloorType=induction_brazing`,
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="black" />,
    p: 'INDUCTION BRAZING',
    span: '',
    isIcon: true,
  },
  {
    sNo: 4,
    link: `/common/productionLogBookList?documentType=groundFloor&groundFloorType=furnace_brazing`,
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="black" />,
    p: 'FURNACE BRAZING',
    span: '',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/productionLogBookList?documentType=groundFloor&groundFloorType=cnc_bending',
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="black" />,
    p: 'CNC BENDING',
    span: '',
    isIcon: true,
  },
  {
    sNo: 6,
    link: `/common/productionLogBookList?documentType=groundFloor&groundFloorType=flaring_forming`,
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="black" />,
    p: 'FLARING / END FORMING',
    span: '',
    isIcon: true,
  },
];

const GroundFloorTiles = () => {
  return (
    <>
      <ChildHeader text="Ground Floor" />
      <RenderTiles height="100vh" tilesObj={tiles} />
    </>
  );
};

export default GroundFloorTiles;
