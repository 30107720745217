export const listConstant = {
  childHeader: 'APPEARANCE MSA plan list',
  addButtonLable: 'New APPEARANCE',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'APPEARANCE MSA plan list',
  theadHeader: 'Appearance Plan for MSA',
  extracolSpan: 5,
};

export const checkListList = {
  childHeader: 'APPEARANCE MSA STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'appearancePlanRoute',
  gettableDataurl: 'appearancePlanRoute/monthly',
  getPlanByid: 'appearancePlanRoute',
  createPlanByid: 'appearancePlanRoute',
  updatePlanByid: 'appearancePlanRoute',
  sync: 'appearancePlanRoute/handleSync',
  getAllCheckList: 'appearancePlanRoute/getAllCheckList',
  checkListStatus: 'appearancePlanRoute/handleStatus',
  checklistUpload: 'appearancePlanRoute/handleUpload',
  checklistDeleteImage: 'appearancePlanRoute/handleDeleteImage',
};

export const navigationConstant = {
  createPlanSchedule: '/common/appearanceplancreate',
};
