import { TbAspectRatio } from 'react-icons/tb';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { BsReverseListColumnsReverse } from 'react-icons/bs';
import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';
import { apiConstant, tilesConstant } from './constant';

const tiles = [
  {
    sNo: 1,
    link: '/common/materialTestPlanHoseList',
    isLink: true,
    icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
    p: 'Material Testing Plan',
    span: 'Hose',
    isIcon: true,
  },

  {
    sNo: 2,
    link: '/common/materialTestPlanHoseChecklistList',
    isLink: true,
    icon: <BsReverseListColumnsReverse size="5rem" color="#087f5b" />,
    p: 'Material Testing STATUS',
    span: 'HOSE',
    isIcon: true,
  },
];

const Tiles = () => {
  return (
    <>
      <ChildHeader text={tilesConstant.childHeaderText} />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0"
      />
    </>
  );
};

export default Tiles;
