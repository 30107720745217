import { TableInput } from '../../../../components/common';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { useQuery } from '../../../../hooks/UseQuery';
import { ModuleDataHandler } from '../helpers/handleReportCalc ';
interface IProps {
  moduleData: any;
  setModuleData: any;
  setParentRender: any;
  isView: boolean;
  machineData: any;
  setRenderforUpdate: any;
}
const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

const tableHeaderObj = [
  [
    { text: 'S.NO', style: style1 },
    { text: 'DATE', style: style1 },
    { text: 'PRO QTY PARTS', style: style1 },
    { text: 'CHECKED QTY.', style: style1 },
    { text: 'QTY CUMM', style: style1 },
    { text: 'CHANGE DATE', style: style1 },
    { text: 'FILTER CHANGE DATE', style: style1 },
    { text: 'STATUS', style: style1 },
    { text: 'STATUS ACTION', style: style1 },
    { text: 'REMARKS', style: style1, colspan: 2 },
  ],
];

const THead = ({
  moduleData,
  isView,
  setModuleData,
  setParentRender,
  machineData,
  setRenderforUpdate,
}: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  const dataHandler = new ModuleDataHandler(moduleData);

  return (
    <thead>
      <tr>
        <th style={{ background: '#495057', color: '#f8f9fa' }} colSpan={11}>
          {`FLUSHING OIL CHANGE CHECK SHEET - ${year}`}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Machine No.
        </th>
        <th colSpan={3} align="left">
          <AutocompleteMuiCustom
            id="machine_no"
            option_name="machine_no"
            arrayofObj={machineData || []}
            value={moduleData.machine_id || ''}
            onChange={(e, value) => {
              moduleData.machine_id = value;
              setRenderforUpdate((prev: any) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          Machine Discription
        </th>
        <th colSpan={4} align="left">
          {moduleData?.machine_id?.machine_name}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Month Starting Qty.
        </th>
        <th align="left">
          <TableInput
            isView={isView}
            type="number"
            name="starting_qty"
            disabled={moduleData?._id}
            value={moduleData?.starting_qty}
            onChange={(e) => {
              moduleData.starting_qty = e.target.valueAsNumber;
              dataHandler.calculateCumulative();
              setParentRender((prev: any) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          Change Over Days
        </th>
        <th colSpan={2} align="left">
          <TableInput
            isView={isView}
            type="number"
            name="change_freq_days"
            disabled={moduleData?._id}
            value={moduleData?.change_freq_days}
            onChange={(e) => {
              moduleData.change_freq_days = e.target.valueAsNumber;
              dataHandler.calculateCumulative();
              setParentRender((prev: any) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          Change Freq
        </th>
        <td colSpan={3} align="left">
          <TableInput
            isView={isView}
            type="number"
            name="change_freq"
            disabled={moduleData?._id}
            value={moduleData?.change_freq}
            onChange={(e) => {
              moduleData.change_freq = e.target.valueAsNumber;
              dataHandler.calculateCumulative();
              setParentRender((prev: any) => !prev);
            }}
          />
        </td>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
