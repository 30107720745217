import { RenderTiles } from '../../components/common';
import { MdSummarize } from 'react-icons/md';
import ChildHeader from '../../components/ui/ChildHeader';
export type tilesType = {
  sNo: number;
  link: string;
  isLink: boolean;
  isIcon: boolean;
  clauseNo?: string | number;
  p: string;
  span: string;
  icon?: any;
  style?: {};
}[];
const tiles = [
  {
    sNo: 19,
    link: '/common/4mSummaryList',
    isLink: true,
    icon: <MdSummarize size="5rem" color="#003566" />,
    p: '4M',
    span: 'SUMMARY',
    isIcon: true,
  },
];

const Clause = () => {
  return (
    <>
      <ChildHeader text="CHANGING MANAGEMENT" />
      <RenderTiles tilesObj={tiles} height="100vh" />
    </>
  );
};

export default Clause;
