export const checklistDataL1 = [
  {
   sNo:'1',
   checkPoint:'Set up approval record as per Control Plan/Job Card',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'2',
   checkPoint:'Is inspection done as per Control Plan/Job Card',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'3',
   checkPoint:'Is Process Tags available at stages',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'4',
   checkPoint:'Is work instruction of Q.A. activities available',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'5',
   checkPoint:'Is operator instructions displayed on each process stage',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'6',
   checkPoint:'Is limit sample available for visual inspection stages',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'7',
   checkPoint:'Is master sample available with latest modification level',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'8',
   checkPoint:'Is identification available on product',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'9',
   checkPoint:'Is 5s maintain on every station',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'10',
   checkPoint:'Is beans are clean and not over filled',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'11',
   checkPoint:'Is SPC Done on special characteristics and CP & CPK value calculated',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'12',
   checkPoint:'Is operation checked on each setup',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'13',
   checkPoint:'Is WIP maintained',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'14',
   checkPoint:'Is in process rejection data analysis available',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'15',
   checkPoint:'All fixtures & Gauge are calibrated',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'16',
   checkPoint:'Operator training done',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'17',
   checkPoint:'Is Quality alert followed',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'18',
   checkPoint:'Is Machine parameters are as per Control Plan/Job Card',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'19',
   checkPoint:'Is Instruments/Gauges calibrated',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'20',
   checkPoint:'Is operator aware about PPE & using on shop floor',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'21',
   checkPoint:'Is updated skill matrix available on station & operator working accordingly',
   observation:'',
   status:'',
   remark:'',
  },
  {
   sNo:'22',
   checkPoint:'Others',
   observation:'',
   status:'',
   remark:'',
  },
  ]

export const checklistDataL2 = [
    {
     sNo:'1',
     checkPoint:'Set up Approval record as per Control Plan',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'2',
     checkPoint:'Is Hourly Inspection Done',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'3',
     checkPoint:'Is process tag available at all stages',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'4',
     checkPoint:'Is Work Instruction available',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'5',
     checkPoint:'Is Operator Instruction displayed on each process stage',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'6',
     checkPoint:'Is Limit Sample available for visual inspection stages',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'7',
     checkPoint:'Is Master Sample available for with latest modification level',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'8',
     checkPoint:'Is identification available on product',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'9',
     checkPoint:'Is 5S available on every stage',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'10',
     checkPoint:'Is Bins are clean and not over filled',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'11',
     checkPoint:'Is SPC done on special characteristics & Cp & Cpk value calculated',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'12',
     checkPoint:'Is Operation checked on every station',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'13',
     checkPoint:'Not More then 10 nos on every station',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'14',
     checkPoint:'Is in Process rejection data analysis available',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'15',
     checkPoint:'All Fixture & Gauge are calibrated',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'16',
     checkPoint:'Operator Training done',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'17',
     checkPoint:'Is operator aware about PPE & using on shop floor',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'18',
     checkPoint:'Is updated skill matrix available on station & operator working accordingly',
     observation:'',
     status:'',
     remark:'',
    },
    {
     sNo:'19',
     checkPoint:'Other',
     observation:'',
     status:'',
     remark:'',
    },
]

export const checklistDataL3 = [
  {
   sNo:'1',
   auditPoint:'Quality targets deployed correctly and monitored continuously',
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
   sNo:'2',
   auditPoint:'Customer complaints addresses effectively?',
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
   sNo:'3',
   auditPoint:'Corrective actions implementation & effectiveness monitored?',
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
   sNo:'4',
   auditPoint:'Set up verification performed regularly?',
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
   sNo:'5',
   auditPoint:'Machine work condition checked before start of production?',
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
   sNo:'6',
   auditPoint:`Process and Product audits planned and executed. Results analysed and corrective actions taken by personnel.`,
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
   sNo:'7',
   auditPoint:'Customer complaints -',
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
   sNo:'7.1',
   auditPoint:'Internal and External, addressed effectively',
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
  sNo:'7.2',
   auditPoint:'Weekly customer complaints meeting - System in place',
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
  sNo:'7.3',
   auditPoint:`Corrective actions planned, implemented and effective. (One complaint to be tracked till verification of effectiveness)`,
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
  sNo:'8',
   auditPoint:'Concessions handling and close looping',
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
   sNo:'9',
   auditPoint:'"Q Alert" in place at stations where Customer complaint has originated ?',
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
   sNo:'10',
   auditPoint:`Are Q initiatives effective ? - SPC, QA Matrix. (Select one sample from each initiative)`,
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
   sNo:'11',
   auditPoint:'Escalation procedure in place/Practiced?',
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
   sNo:'12',
   auditPoint:'Is operator aware about PPE & using on shop floor',
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
  {
   sNo:'13',
   auditPoint:'Is updated skill matrix available on station & operator working accordingly',
   responsibility:'',
   remark:'',
   dueDate:'',
   yes:false,
   no:false,
   action:'',
  },
]

export const checklistDataL4A = [
    {
     sNo:'1',
     auditPoint:'Targets for shop monitored & under control? \n (Type wise & quantity wise fulfillment)',
     responsibility:'',
     remark:'',
     dueDate:'',
     yes:false,
   no:false,
     action:'',
    },
    {
     sNo:'2',
     auditPoint:'Effectiveness of SPC in the Range ?',
     responsibility:'',
     remark:'',
     dueDate:'',
     yes:false,
   no:false,
     action:'',
    },
    {
     sNo:'3',
     auditPoint:'Do It Right (DIR) Station effectively run ? \nCorrective Actions implementation & effectiveness monitored?',
     responsibility:'',
     remark:'',
     dueDate:'',
     yes:false,
   no:false,
     action:'',
    },
    {
     sNo:'4',
     auditPoint:'Setup Verification performed regularly?',
     responsibility:'',
     remark:'',
     dueDate:'',
     yes:false,
     no:false,
     action:'',
    },
    {
     sNo:'5',
     auditPoint:'Poka Yoke (PY) validation done before start of production?',
     responsibility:'',
     remark:'',
     dueDate:'',
     yes:false,
     no:false,
     action:'',
    },
    {
     sNo:'6',
     auditPoint:'Are process improvements taking place? \n - Tool improvements/Cycle Time Reduction/Cost Reduction',
     responsibility:'',
     remark:'',
     dueDate:'',
     yes:false,
   no:false,
     action:'',
    },
    {
     sNo:'7',
     auditPoint:'Absenteesim captured analysed & close loped',
     responsibility:'',
     remark:'',
     dueDate:'',
     yes:false,
   no:false,
     action:'',
    },
    {
     sNo:'8',
     auditPoint:'Customer (Internal/External) complaints related to shop -\ncaptured, analysed, corrective actions taken, \neffectiveness, monitored. "Q Alert" in place ?',
     responsibility:'',
     remark:'',
     dueDate:'',
     yes:false,
   no:false,
     action:'',
    },
    {
    sNo:'9',
     auditPoint:'Is QA matrix made ? Firewall in place ?',
     responsibility:'',
     remark:'',
     dueDate:'',
     yes:false,
   no:false,
     action:'',
    },
    {
    sNo:'10',
     auditPoint:'Internal rejections - Scrap and repair, captured, \n analysed, corrective actions taken, effectiveness \nmonitored ?',
     responsibility:'',
     remark:'',
     dueDate:'',
     yes:false,
   no:false,
     action:'',
    },
    {
     sNo:'11',
     auditPoint:'Workmen motivation : \n- Voluntary Learnstatt (VLT) concept understood by workmen?\nParticipation?\n- Employee participation in Suggestion scheme? \n(Feedback from workmen)',
     responsibility:'',
     remark:'',
     dueDate:'',
     yes:false,
   no:false,
     action:'',
    },
    {
     sNo:'12',
     auditPoint:'Overall house keeping satisfactory More than 90%',
     responsibility:'',
     remark:'',
     dueDate:'',
     yes:false,
   no:false,
     action:'',
    },
    {
     sNo:'13',
     auditPoint:'Is operator aware about PPE & using on shop floor',
     responsibility:'',
     remark:'',
     dueDate:'',
     yes:false,
   no:false,
     action:'',
    },
    {
     sNo:'14',
     auditPoint:'Is updated skill matrix available on station & operator working\naccordingly',
     responsibility:'',
     remark:'',
     dueDate:'',
     yes:false,
   no:false,
     action:'',
    },
]

export const checklistDataL4B = [
      {
       sNo:'1',
       auditPoint:'Clarity in targets (Quality and production) set for the shop',
       responsibility:'',
       remark:'',
       dueDate:'',
       yes:false,
       no:false,
       action:'',
      },
      {
       sNo:'2',
       auditPoint:'Effective monitoring of Policy Deployment targets evidenced ?',
       responsibility:'',
       remark:'',
       dueDate:'',
       yes:false,
       no:false,
       action:'',
      },
      {
       sNo:'3',
       auditPoint:'Customer complaints related to shop - captured, \nanalysed, corrective actions taken, effectiveness\nmonitored. "Q Alert" in place ?',
       responsibility:'',
       remark:'',
       dueDate:'',
       yes:false,
   no:false,
       action:'',
      },
      {
       sNo:'4',
       auditPoint:'Is Quality Assurance (QA) matrix in place ?\nMonitored ?',
       responsibility:'',
       remark:'',
       dueDate:'',
       yes:false,
       no:false,
       action:'',
      },
      {
       sNo:'5',
       auditPoint:'Is capability of processes monitored ?',
       responsibility:'',
       remark:'',
       dueDate:'',
       yes:false,
   no:false,
       action:'',
      },
      {
       sNo:'6',
       auditPoint:'"Q" Initiatives deployed ?\n(i) 8-D\n(ii) SPC\n(iii) FMEA\n(iv) Poka-Yoke',
       responsibility:'',
       remark:'',
       dueDate:'',
       yes:false,
       no:false,
       action:'',
      },
      {
       sNo:'7',
       auditPoint:'Internal rejections : Scrap and repair captured,\nanalysed, corrective actions taken, effectiveness\nmonitored ?',
       responsibility:'',
       remark:'',
       dueDate:'',
       yes:false,
   no:false,
       action:'',
      },
      {
       sNo:'8',
       auditPoint:'Workmen motivation :\n- Number of Quality Problem Solving (QPS)\nworkshops in the current year.\n- Number of suggestions/ employee in current year.',
       responsibility:'',
       remark:'',
       dueDate:'',
       yes:false,
   no:false,
       action:'',
      },
      {
      sNo:'9',
       auditPoint:'Overall house keeping satisfactory.',
       responsibility:'',
       remark:'',
       dueDate:'',
       yes:false,
   no:false,
       action:'',
      },
      {
      sNo:'10',
       auditPoint:'Customer audit open points closed?',
       responsibility:'',
       remark:'',
       dueDate:'',
       yes:false,
       no:false,
       action:'',
      },
      {
       sNo:'11',
       auditPoint:'Future plans for improvement ?',
       responsibility:'',
       remark:'',
       dueDate:'',
       yes:false,
       no:false,
       action:'',
      },
      {
       sNo:'12',
       auditPoint:'Is operator aware about PPE & using on shop floor.',
       responsibility:'',
       remark:'',
       dueDate:'',
       yes:false,
       no:false,
       action:'',
      },
      {
       sNo:'13',
       auditPoint:'operator working accordingly',
       responsibility:'',
       remark:'',
       dueDate:'',
       yes:false,
       no:false,
       action:'',
      },
]
      
  

    
  
