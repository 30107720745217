import React, { useRef, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  DeleteIconButton,
  EditIconButton,
} from '../../../components/common/button/CustomIconButton';
import { useQuery } from '../../../hooks/UseQuery';
import formatDate from '../../../components/common/formatDate';
import ModalCustom from '../../../components/common/Modal';
import Create from './components/Create';
import {
  AddButton,
  UpdateButton,
} from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import NpdPartCountTableCell from './components/NpdPartCountTableCell';

const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'NAME', rowspan: 1, colspan: 1 },
    { text: 'START DATE', rowspan: 1, colspan: 1 },
    { text: 'PARTS ASSIGNED', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
    { text: 'CREATE TIMEPLAN AND MATRIX', rowspan: 1, colspan: 1 },
  ],
];
const filterOptions = [{ value: 'createdAt', name: 'A-Z' }];

const List = () => {
  const [data, setData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const sort = useQuery('sort');
  const page = useQuery('page') || 1;

  const moduleIdref = useRef<any>(null);
  const [isModal, setIsModal] = useState(false);
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?',
  );
  const [DialogDeletePublish, confirmPublishPart] = useConfirm(
    'PARTS',
    'Are you sure you want to create NPD Timeplan and Matrix?',
  );
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/masterListOfProjectRoute?page=${page}&sortBy=${sort}&search=${search}&perPage=${10}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 500, [page, search, render, sort, isModal]);

  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/masterListOfProjectRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };
  const publishNpdPart = async (projectId: string) => {
    const ans = await confirmPublishPart();
    if (!ans) return;
    await axios
      .patch(`/api/masterListOfProjectRoute/npdPartPublish/${projectId}`)
      .then((res) => {
        alert('Document publish successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <ChildHeader text="MASTER LIST OF PROJECT">
        <AddButton onClick={() => setIsModal(true)} label="New Project" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <Stack direction={'row'} gap={2} flex="1">
          <FilterBy
            sx={{ width: 200 }}
            name="sort"
            label="Sort"
            options={filterOptions}
          />
        </Stack>
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item.name}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.start_date || '')}
                  </TableCell>
                  <NpdPartCountTableCell projectId={item._id} />
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          moduleIdref.current = item._id;
                          setIsModal(true);
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteDocument(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell align="center">
                    <UpdateButton
                      label="PUBLISH"
                      sx={{ margin: '10px' }}
                      size="small"
                      onClick={() => publishNpdPart(item._id)}
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          moduleIdref.current = null;
          setIsModal(false);
        }}
        title="PROJECT"
      >
        <Create id={moduleIdref.current} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
      <DialogDeletePublish isSubmitContent={false} />
    </>
  );
};

export default List;
