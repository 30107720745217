import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
}
const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.histories &&
        moduleData.histories.map((spec: any, index: number) => (
          <tr>
            <td>{index + 1}</td>
            <td>
              {isView ? (
                formatDate(spec.date)
              ) : (
                <DatePickerMui
                  value={moment(spec.date)}
                  onChange={(date) => {
                    spec.date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td>
              <TableInput
                isView={isView || spec.isAutoCreated}
                name="type_of_maintenance"
                type="text"
                value={spec.type_of_maintenance}
                onChange={(e) => {
                  spec.type_of_maintenance = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="maintenance_details"
                type="text"
                value={spec.maintenance_details}
                onChange={(e) => {
                  spec.maintenance_details = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="details_of_part"
                type="text"
                value={spec.details_of_part}
                onChange={(e) => {
                  spec.details_of_part = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={spec.remarks}
                onChange={(e) => {
                  spec.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            {!isView && (
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handelDeleteRows(index)}
                />
              </td>
            )}
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
