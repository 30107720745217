import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../hooks/UseQuery';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '200px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData }: IProps) => {
  const yearRange = useQuery('yearRange');
  const date = new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  return (
    <thead>
      <TableCompHead
        colSpan={17 + createConstant.extracolSpan}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading={`${createConstant.theadHeader} (YEAR : ${yearRange})`}
      />

      {['S. No.', 'CUSTOMER NAME', 'Plan/Actual'].map((el, index) => (
        <th
          key={`${index}_head`}
          align="center"
          style={{
            textAlign: 'center',
            ...style1,
            minWidth: `${(el === 'S. No.' && '50px') || style1.minWidth}`,
          }}
        >
          {el}
        </th>
      ))}
      {[
        'APR',
        'MAY',
        'JUN',
        'JUL',
        'AUG',
        'SEP',
        'OCT',
        'NOV',
        'DEC',
        'JAN',
        'FEB',
        'MAR',
      ].map((el, index) => (
        <th
          key={`${index}_headcol`}
          align="center"
          style={{ textAlign: 'center', ...style2 }}
        >
          {el}-{index > 8 ? +date + 1 : date}
        </th>
      ))}
      <th style={{ textAlign: 'center', ...style1 }}>REMARKS</th>
      <th style={{ textAlign: 'center', ...style1 }}>ACTION</th>
    </thead>
  );
};

export default THead;
