import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/Interface';
import ViewFile from './ViewFile';
import formatDate from '../../../components/common/formatDate';

const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'SUPPLIER NAME', rowspan: 1, colspan: 1 },
    { text: 'SUPPLIER CODE', rowspan: 1, colspan: 1 },
    { text: 'CONTACT PERSON', rowspan: 1, colspan: 1 },
    { text: 'CONTACT NO.', rowspan: 1, colspan: 1 },
    { text: 'EMAIL ID ', rowspan: 1, colspan: 1 },
    { text: 'ISO 9001:2015 ', rowspan: 1, colspan: 1 },
    { text: 'ISO 9001:2015 Certificate Expiry Date', rowspan: 1, colspan: 1 },
    { text: 'ISO 14001:2015', rowspan: 1, colspan: 1 },
    { text: 'ISO 14001:2015 Certificate Expiry Date', rowspan: 1, colspan: 1 },
    { text: 'IATF ', rowspan: 1, colspan: 1 },
    { text: 'IATF CERTIFICATE EXPIRY DATE', rowspan: 1, colspan: 1 },
    { text: 'STATE', rowspan: 1, colspan: 1 },
    { text: 'DISTRICT', rowspan: 1, colspan: 1 },
    { text: 'PINCODE', rowspan: 1, colspan: 1 },
    { text: 'COMMODITY', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'createdAt', name: 'A-Z' }];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [vendorId, setVendorId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const [all, setAll] = useState(false);

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/vendorRoute/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&all=${all}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomer = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/vendorRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, filter, all]);

  const calcutateRed = (item: any) => {
    const iso_9001_expiry_date = new Date(item?.iso_9001_expiry_date).getTime();
    const iso_14001_expiry_date = new Date(
      item?.iso_14001_expiry_date
    ).getTime();
    const cert_exp_date = new Date(item?.cert_exp_date).getTime();

    const currenDate = Date.now();
    if (currenDate > iso_9001_expiry_date) return true;
    if (currenDate > iso_14001_expiry_date) return true;
    if (currenDate > cert_exp_date) return true;
    return false;
  };
  return (
    <>
      <ChildHeader text="VENDOR LIST">
        <AddButton
          onClick={() => {
            setAll((value) => !value);
          }}
          label={all ? 'Hide' : 'Save'}
        />
        <AddButton onClick={() => setIsModal(true)} label="New VENDOR" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj} overflow={true}>
        <TableBody>
          {data &&
            data.map((item: IData, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow
                  style={{
                    background: `${calcutateRed(item) ? '#ffe3e3' : '#fff'}`,
                  }}
                >
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item.vender_name}</TableCell>
                  <TableCell align="center">{item.vender_code}</TableCell>
                  <TableCell align="center">{item.contact_person}</TableCell>
                  <TableCell align="center">
                    {item.contact_no ? item.contact_no : ''}
                  </TableCell>
                  <TableCell align="center">{item.email_id}</TableCell>
                  <TableCell align="center">{item.iso_9001}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.iso_9001_expiry_date || '')}
                  </TableCell>
                  <TableCell align="center">{item.iso_14001}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.iso_14001_expiry_date || '')}
                  </TableCell>

                  <TableCell align="center">{item.iatf}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.cert_exp_date || '')}
                  </TableCell>
                  <TableCell align="center">{item.state}</TableCell>
                  <TableCell align="center">{item.district}</TableCell>
                  <TableCell align="center">{item.pincode}</TableCell>
                  <TableCell align="center">{item.commodity}</TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setVendorId(item?._id || '');
                          setIsModal(true);
                        }}
                      />
                      <ViewIconButton
                        tooltipTitle="view"
                        onClick={() => {
                          setId(item._id || null);
                          setIsFileOpenPopup(true);
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteCustomer(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setVendorId(null);
          setIsModal(false);
        }}
        title="VENDOR"
      >
        <Create
          id={vendorId}
          setIsModal={setIsModal}
          setVendorId={setVendorId}
        />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
