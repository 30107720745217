import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import CustomSelect from '../../../../components/common/SelectField';
import { IconButton, MenuItem, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { spclChar } from '../../../cp/helpers/SpecialCharacters';
import InteractiveIcon from '../../../../components/common/InteractiveIcon';
import useConfirm from '../../../../components/common/useConfirm';

interface IProps {
  item: any;
  isView: boolean;
  index: number;
  allmonth: any;
}
const TBodyRight = ({ item, isView, index, allmonth }: IProps) => {
  const [render, setRender] = useState(false);
  const [DialogisCheck, setDialogisCheck] = useConfirm(
    'Chnage Plan',
    'Are you sure you want to Change Plan?'
  );

  //  1 double click -> plan 2 | click double -> replan | 3 double click -> nothing
  const stateTransitions: any = {
    // [current p, current r] => [next p, next r]
    'false,false': { p: true, r: false }, //first p
    'true,false': { p: false, r: true }, //second R
    'false,true': { p: false, r: false }, //third remove
  };

  const handleDoubleClick = async (event: any, month: string) => {
    if (event.detail === 2) {
      const ans = await setDialogisCheck();
      if (!ans) return;
      const currentStateKey = `${item[month].p},${item[month].r}`;
      const nextState = stateTransitions[currentStateKey];

      if (nextState) {
        item[month].p = nextState.p;
        item[month].r = nextState.r;
        setRender((prev: any) => !prev);
      }
    }
  };

  return (
    <>
      {allmonth.map((month: string) => (
        <td
          style={{
            padding: '0px',
            margin: '0px',
          }}
          onClick={(e) => handleDoubleClick(e, month)}
        >
          <InteractiveIcon isYellow={item[month].p} isBlue={item[month].r}>
            {item[month].p && 'P'}
            {item[month].r && 'R'}
          </InteractiveIcon>
        </td>
      ))}
      <DialogisCheck isSubmitContent={false} />
    </>
  );
};

export default TBodyRight;
