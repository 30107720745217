import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import VisibilityIcon from '@mui/icons-material/Visibility';
import lodash from 'lodash';
import ChildHeader from '../../components/ui/ChildHeader';
import {
  SaveButton,
  CloseButton,
} from '../../components/common/button/CustomButtons';
import HeaderInputs from './components/create/HeaderInputs';
import ModuleBody from './components/create/ModuleBody';
import { IModuleData, IProcess } from './helpers/Interface';
import { initialStat } from './helpers/initialState';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomButton } from '../../components/common/button/CustomButtons';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';
import axios from 'axios';
import ECNModal from '../ecn/ECNModal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import { Loader } from '../../components/common/Loader';
import moment from 'moment';
import AlertMui from '../../components/common/AlertMui';

const Create = () => {
  const [moduleData, setModuleData] = useState<IModuleData>(
    lodash.cloneDeep(initialStat)
  );
  const { partId, controlPlanId } = useParams();
  const [useEffectRender, setUseEffectRender] = useState(false);
  const [isECNModal, setIsECNModal] = useState(false);
  const [prevControlPlanData, setPrevControlPlanData] = useState(null);
  const partName = useQuery('part_name');
  const partNumber = useQuery('part_number');
  const customer_name = useQuery('customer_name');
  const [render, setRender] = useState(false);
  const navigate = useNavigate();
  const [alertCustom, setAlert] = useState(false);

  const [loader, setLoader] = useState<boolean>(false);
  const [DialogRevision, confirmRevision] = useConfirm(
    'UPDATE PART',
    'Revision no. already exists! Are you sure you want to overwrite?'
  );
  const [DialogClose, confirmClose] = useConfirm(
    'CLOSE',
    'Are you sure you want to leave this page?'
  );

  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to save?'
  );

  useEffect(() => {
    axios
      .get(`/api/controlPlan/${partId}/${controlPlanId}`)
      .then((res) => {
        if (res.data) {
          setModuleData(res.data);
          setPrevControlPlanData(lodash.cloneDeep(res.data));
        }
      })
      .catch((err) => console.log(err));
    return () => {
      setModuleData(lodash.cloneDeep(initialStat));
    };
  }, [useEffectRender]);

  const handleControlPlanImport = (e: any) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    axios
      .post(`/api/uploadPart/controlPlan?part_id=${partId}`, formData)
      .then((res) => {
        const uploadData = res.data.newPart.processes;
        uploadData.forEach(
          (item: IProcess, index: number) =>
            (item.s_no = moduleData.processes.length + index + 1)
        );
        moduleData.processes = [...moduleData.processes, ...uploadData];
        e.target.value = '';
        setLoader(false);
        setRender((prev) => !prev);
      })
      .catch((err) => {
        setLoader(false);
        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else {
          window.alert(
            'Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.'
          );
        }
      });
  };

  const handleRevisionImport = (e: any) => {
    setLoader(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    axios
      .post(`/api/controlPlan/uploadRevision`, formData)
      .then((res) => {
        const uploadData = res.data.partsArray;
        moduleData.revisionData = [
          ...(moduleData.revisionData || []),
          ...uploadData,
        ];
        moduleData.revision_date =
          res.data?.partsArray[res.data.partsArray.length - 1].rev_date || null;
        moduleData.revision_no =
          +res.data?.partsArray[res.data.partsArray.length - 1].rev_no || null;

        e.target.value = '';
        setLoader(false);
        setRender((prev) => !prev);
      })
      .catch((err) => {
        setLoader(false);
        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else {
          window.alert(
            'Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.'
          );
        }
      });
  };
  const handleControlPlanSubmit = async () => {
    if (!moduleData.latest_change_level) {
      setAlert(true);
      return;
    }

    if (moduleData._id) {
      let isUpdate = false;
      let isPost = false;
      const isRev: any = await axios
        .get(
          `/api/revision/isrevno/controlplan/${partId}/${moduleData.revision_no}`
        )
        .catch((err) => console.log(err));
      if (isRev.data.status === 'success') {
        if (controlPlanId) {
          isUpdate = await confirmRevision();
        } else {
          isUpdate = true;
        }
      }
      if (isRev.data.status === 'fail') {
        isPost = true;
      }
      if (isUpdate) {
        let isSave = await confirmSave();

        if (isSave) {
          axios
            .put(`/api/controlPlan/${moduleData._id}`, moduleData)
            .then(() => {
              alert('Control Plan changes saved successfully');
            })
            .catch((err) => console.log(err));
        }
      }
      if (isPost) {
        setIsECNModal(true);
      }
    } else {
      let ans = await confirmSave();
      if (ans) {
        partId && (moduleData.part_id = partId);
        axios
          .post('/api/controlPlan/', moduleData)
          .then(() => {
            alert('Control Plan submitted successfully');
            setUseEffectRender((prev) => !prev);
          })
          .catch((err) => console.log(err));
      }
    }
  };

  const handleSubmitRevision = (revisionData: any) => {
    // if (lodash.isEmpty(revisionData)) {
    //   alert("you can't create revision");
    //   return;
    // }

    delete moduleData._id;
    delete moduleData.createdAt;
    delete moduleData.updatedAt;
    delete moduleData.__v;
    moduleData.processes.forEach((process) => {
      delete process._id;
      process.specs.forEach((spec) => delete spec._id);
    });
    revisionData = Object.keys(revisionData).map((key) => ({
      rev_no: moduleData.revision_no,
      summary: revisionData[key].value,
      approved_by: revisionData[key].approved_by,
      revised_by: revisionData[key].revised_by,
      section: revisionData[key].section,
    }));

    if (moduleData.revisionData) {
      moduleData.revisionData = [...moduleData.revisionData, ...revisionData];
    } else {
      moduleData.revisionData = [...revisionData];
    }

    axios
      .post('/api/controlPlan', moduleData)
      .then(() => {
        alert('control plan submitted successfully');
        navigate(
          `/admin/revisionTable/controlplan/${partId}?part_name=${partName}&part_number=${partNumber}`
        );
      })
      .catch((err) => console.log(err));
  };

  return (
    <Box>
      <Loader loader={loader} />
      <Box>
        <ChildHeader text="CONTROL PLAN">
          <CustomUploadButton
            lable={'Upload Cp'}
            onChange={handleControlPlanImport}
          />
          <CustomUploadButton
            id="upload_revision"
            lable={'Upload Revision'}
            onChange={handleRevisionImport}
          />
          <CustomButton
            icon={<VisibilityIcon />}
            sx={{ margin: '10px', backgroundColor: '#fff', color: '#111' }}
            onClick={() =>
              navigate(
                `/admin/viewControlplan/${partId}/${moduleData._id}?customer_name=${customer_name}`
              )
            }
          >
            VIEW
          </CustomButton>
          <SaveButton
            label="Save & Continue"
            onClick={handleControlPlanSubmit}
          />
          <CloseButton
            label="Close"
            onClick={async () => {
              const ans = await confirmClose();
              if (!ans) return;
              navigate(-1);
            }}
          />
        </ChildHeader>
        <HeaderInputs
          {...{ moduleData, setRender, setModuleData, controlPlanId }}
        />
        <ModuleBody {...{ moduleData, setModuleData }} />
      </Box>
      {isECNModal && (
        <ECNModal
          openPopup={isECNModal}
          closeModal={() => setIsECNModal(false)}
          partData={moduleData}
          documentType="controlplan"
          ecnTitle="ECN - CONTROL PLAN CHANGES"
          partDataForRevision={prevControlPlanData}
          submit={handleSubmitRevision}
        />
      )}
      <DialogSave isSubmitContent={true} />
      <DialogRevision />
      <DialogClose isSubmitContent={false} />
      <AlertMui
        alert={alertCustom}
        message={'The The latest change level field cannot be left empty'}
        setAlert={setAlert}
        severity={'warning'}
      />
    </Box>
  );
};

export default Create;
