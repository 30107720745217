import { Box, Paper, Tab, Tabs } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { PersitedDatePicker } from '../../../components/common';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ModalCustom from '../../../components/common/Modal';
import { SupervisorPartTable } from '../../../components/ui';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import Summary from './components/dashboard/summary/Summary';
import Type from './components/dashboard/typeOfComplaint/Type';
import DefectWise from './components/dashboard/defectwise/DefectWise';
const statusObj = {
  pending: 1,
  accepted: 2,
  rejected: 3,
};

const CustomerConcernDashboardTabs = () => {
  const { state } = useAuth();
  const { user } = state;
  const date = useQuery('date') || '';
  const [tabValue, setTabValue] = useState(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [data, setData] = useState({
    dashBoardMonthCounts: [],
    totalOfMonth: {},
  });
  const [type, setType] = useState({
    totalOfDomesticPerMonth: {},
    totalOfExportsPerMonth: {},
  });
  const [defects, setDefects] = useState({
    defectsCountResult: [],
    defectsCountColumnResult: { TotalColumn: [] },
    defectsName: [],
  });
  const [year, setYear] = useState(0);

  const fetchData = async () => {
    console.log('date', date);
    if (date) {
      if (tabValue == 0) {
        const res = await axios
          .get(`/api/customerConcernRoutes/dashboard/getByMonth/?date=${date}`)
          .catch((err) => {
            console.log(err);
          });

        setData(res?.data);
        setYear(new Date(date).getFullYear());
      } else if (tabValue == 1) {
        const res = await axios
          .get(
            `/api/customerConcernRoutes/dashboard/getByTypeOfComplaint/?date=${date}`
          )
          .catch((err) => {
            console.log(err);
          });

        setType(res?.data);
        setYear(new Date(date).getFullYear());
      } else {
        const res = await axios
          .get(
            `/api/customerConcernRoutes/dashboard/getByDefects/?date=${date}`
          )
          .catch((err) => {
            console.log(err);
          });
        setDefects(res?.data);
        setYear(new Date(date).getFullYear());
      }
    }
  };
  useEffect(() => {
    fetchData();
  }, [date, tabValue]);

  return (
    <Box>
      <ChildHeader text="CUSTOMER CONCERN DASHBOARD"></ChildHeader>
      <Paper elevation={2}>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          scrollButtons="auto"
          textColor="secondary"
          variant="fullWidth"
        >
          <Tab label="Summary" />
          <Tab label="Domestic Vs Exports" />
          <Tab label="DefectWise New" />
        </Tabs>
      </Paper>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Year" views={['year']} />
      </Box>
      {
        <section>
          {tabValue === 0 && <Summary apiData={data} year={year} />}
          {tabValue === 1 && <Type apiData={type} year={year} />}
          {tabValue === 2 && <DefectWise apiData={defects} year={year} />}
        </section>
      }
    </Box>
  );
};

export default CustomerConcernDashboardTabs;
