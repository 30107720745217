import { useState } from 'react';
import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  isView: boolean;
  setIsOpenPopup: any;
  setPartIdx: any;
  assignData: any;
  tabValue: number;
  assignRegister: (document: any, index: number) => void;

  issueRegister: (document: any, index: number) => void;
  undoData: { [key: string]: any };
  undoAssign: (index: number) => void;
  undoIssue: (index: number) => void;
  storeIssueId: string;
}

const TBody = ({
  setPartIdx,
  setIsOpenPopup,
  moduleData,
  isView,
  assignData,
  tabValue,
  assignRegister,
  issueRegister,
  undoData,
  undoIssue,
  undoAssign,
  storeIssueId,
}: IProps) => {
  return (
    <>
      <tbody>
        {moduleData[tabValue] &&
          moduleData[tabValue].map((part: any, index: number) => (
            <TBodyRow
              {...{
                setPartIdx,
                setIsOpenPopup,
                isView,
                moduleData,
                assignData,
                tabValue,
                assignRegister,
                issueRegister,
                undoData,
                undoIssue,
                undoAssign,
                storeIssueId,
                part,
                index,
              }}
            />
          ))}
      </tbody>
    </>
  );
};

export default TBody;
