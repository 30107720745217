import { Checkbox, Tooltip } from '@mui/material';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import { DeleteIconButton } from '../../../../../../components/common/button/CustomIconButton';
import useConfirm from '../../../../../../components/common/useConfirm';
import moment from 'moment';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';

interface IProps {
  isView: boolean;
  moduleData: any;
}
const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );

  const handelDeleteRows = async (index: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    moduleData.specs.splice(index, 1);
    setRender((prev) => !prev);
  };
  return (
    <>
      <tbody>
        {moduleData?.specs?.map((item: any, index: number) => (
          <tr>
            <td style={{ width: '120px' }}>{index + 1}</td>
            <td>{item?.parameter}</td>
            <td>{item?.cof_c}</td>
            <td>{item?.specification}</td>
            <td>{item?.instrument_type}</td>
            <td>{item?.sample_freq}</td>
            <td>{item?.format_no}</td>
            <td>{item?.responsibility}</td>
            <td style={{ width: '180px' }}>
              {isView ? (
                item.spec_to_inspection && '✓'
              ) : (
                <>
                  <Tooltip title="Add To Inspection Report">
                    <Checkbox
                      checked={item.spec_to_inspection === true}
                      onChange={(e) => {
                        item.spec_to_inspection = e.target.checked;
                        setRender((prev) => !prev);
                      }}
                    />
                  </Tooltip>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </>
              )}
            </td>
          </tr>
        ))}
        <tr>
          <th colSpan={9} align="left">
            REFERENCE : IS : 2500 ( PART I & II )
          </th>
        </tr>
        <tr>
          <th colSpan={9} align="left">
            REMARKS:{' '}
            <TableInput
              name="remarks"
              type="text"
              value={moduleData.remarks}
              onChange={(e) => {
                moduleData.remarks = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </th>
        </tr>
        <tr>
          <th colSpan={9} align="left">
            REVISION SUMMARY
          </th>
        </tr>

        {moduleData?.revision_history?.map((revData: any, index: number) => (
          <tr key={'rev' + index}>
            <td style={{ width: '120px' }}>
              <TableInput
                name="rev_no"
                type="number"
                value={revData.rev_no}
                onChange={(e) => {
                  revData.rev_no = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>
            <td style={{ width: '250px' }}>
              {isView ? (
                formatDate(revData.rev_date || null)
              ) : (
                <DatePickerMui
                  value={moment(revData.rev_date)}
                  onChange={(date) => {
                    revData.rev_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td colSpan={5}>
              <TableInput
                name="rev_desc"
                type="text"
                value={revData.rev_desc}
                onChange={(e) => {
                  revData.rev_desc = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>
            <td>
              <TableInput
                name="prepared_by"
                type="text"
                value={revData.prepared_by}
                onChange={(e) => {
                  revData.prepared_by = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>
            <td>
              <TableInput
                name="approved_by"
                type="text"
                value={revData.approved_by}
                onChange={(e) => {
                  revData.approved_by = e.target.value;
                  setRender((prev) => !prev);
                }}
                isView={isView}
              />
            </td>
          </tr>
        ))}
        <tr>
          {[
            'S.NO.',
            'DATE',
            'MODIFICATION DETAIL',
            'PREP. BY.',
            'APPR. BY.',
          ].map((heading) => (
            <th
              key={heading}
              colSpan={heading === 'MODIFICATION DETAIL' ? 5 : 1}
            >
              {heading}
            </th>
          ))}
        </tr>
        <tr>
          <th colSpan={3}>FM/XLV/RI/020</th>
          <th colSpan={3}>ISSUE NO. '00' </th>
          <th colSpan={3}>ISSUE DATE- 10.01.2022</th>
        </tr>
      </tbody>
      <DialogDeleteRow isSubmitContent={false} />
    </>
  );
};

export default TBody;
