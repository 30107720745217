import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { TableCustom } from '../../../../components/common';

interface IProps {
  tableData: {
    [key: string | number]: string | number;
  }[];
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },

    {
      text: 'NAME',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'DEPARTMENT',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'USERNAME',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const UserManagementList = ({ tableData }: IProps) => {
  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header} size="medium">
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                <TableCell align="center" style={{ fontSize: '1.6rem' }}>
                  {document.name}
                </TableCell>
                <TableCell align="center" style={{ fontSize: '1.6rem' }}>
                  {document.department_id?.department_name}
                </TableCell>
                <TableCell align="center" style={{ fontSize: '1.6rem' }}>
                  {document.username}
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </TableCustom>
    </React.Fragment>
  );
};

export default UserManagementList;
