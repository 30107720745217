import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  handleDelete: any;
  isView: boolean;
  firstDayOfMonth: any;
  lastDayOfMonth: any;
  workerListData: any;
  employees: any;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
}

const TBody = ({
  moduleData,
  isView,
  handleDelete,
  setPartIdx,
  setIsOpenPopup,
  firstDayOfMonth,
  lastDayOfMonth,
  workerListData,
  employees,
}: IProps) => {
  return (
    <tbody>
      {moduleData?.map((item: any, index: number) => (
        <TBodyRow
          key={index}
          {...{
            item,
            index,
            isView,
            handleDelete,
            setPartIdx,
            setIsOpenPopup,
            firstDayOfMonth,
            lastDayOfMonth,
            workerListData,
            employees,
          }}
        />
      ))}
    </tbody>
  );
};

export default TBody;
