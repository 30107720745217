import { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
  UpdateButton,
} from '../../../components/common/button/CustomButtons';
import { useAuth } from '../../../services/auth/AuthProvider';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import TController from './components/TController';

const CreateMachineHistory = () => {
  const [moduleData, setModuleData] = useState<any>({});
  const navigate = useNavigate();
  const [render, setRender] = useState(false);
  const { state } = useAuth();
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [machineData, setMachineData] = useState([]);
  const [customerData, setCustomerData] = useState([]);

  useEffect(() => {
    axios
      .get('/api/customerRoutes/')
      .then((res) => {
        setCustomerData(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get('/api/machineRoutes/')
      .then((res) => {
        setMachineData(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);

  const calculateCumulative = () => {
    const values = { ...moduleData };
    let temp = 0;
    values.list.length > 0 &&
      values.list.forEach((item: any, index: number) => {
        item.status = '';
        temp += item.qty;
        item.comulative = temp;

        if (temp >= values.changed_frequncy) {
          temp = 0;
          item.status = 'RED (R)';
        }

        // if (index === 0) {
        //   item.comulative = item.qty;
        // } else {
        //   item.comulative =
        //     (+item.qty || 0) + (values.list[index - 1]?.comulative || 0);
        //   if (values.changed_frequncy <= item.comulative) {
        //     item.comulative = item.qty;
        //   }
        // }
      });

    setModuleData(values);
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterListOfTools/${id}`)
        .then((res) => {
          const data = res.data;
          setModuleData(data);
          calculateCumulative();
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );

  const onSubmit = async (isSubmitted: boolean) => {
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;

    const ans = await confirmSubmit();
    if (!ans) return;
    await axios
      .put(`/api/masterListOfTools/${id}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('Updated Successfully');
        }
      })
      .catch((err) => console.log(err));
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.histories.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text="HISTORY-CARD FOR TOOLINGS">
          {!isView && (
            <>
              {/* <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  moduleData.list.push({});
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton> */}
              {state.user?.userType === 3 && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => onSubmit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => onSubmit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handelDeleteRows,
          machineData,
          customerData,
          calculateCumulative,
          setModuleData,
        }}
      />
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
      <DialogSubmit />
    </>
  );
};

export default CreateMachineHistory;
