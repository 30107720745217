import { Grid, MenuItem } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TableInput } from '../../../../components/common';
import { calculateDuration } from '../helpers/utils';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import CustomSelect from '../../../../components/common/SelectField';
import { useQuery } from '../../../../hooks/UseQuery';
import { actionTakens, actionTakenTickMarks, reasons } from '../helpers/data';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import axios from 'axios';
import OperationSelect from './OperationSelect';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: (index: number) => void;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
  onSubmit: () => void;
  pictorial: any;
  setPictorial: any;
}
const TBody = ({
  handelDeleteRows,
  moduleData,
  isView,
  setIsOpenPopup,
  onSubmit,
  setPartIdx,
  pictorial,
  setPictorial,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const navigate = useNavigate();
  const annual_month = useQuery('annual_date') || '';
  const [dateLimit, setDateLimit] = useState<{
    start: Date | null;
    end: Date | null;
  }>({ start: null, end: null });
  const redirect = async (
    process: any,
    reportId: any,
    documentType: string
  ) => {
    await onSubmit();
    if (reportId) {
      if (process.part_id?._id) {
        navigate(
          `/common/create4msetup/${process.part_id?._id}/${process._id}/${reportId._id}?annual_date=${annual_month}&documentType=${documentType}&machine_name=${process.machine_id?.machine_name}&operation_name=${process.operation_name}&summary_date=${process.date}`
        );
        return;
      }
      navigate(
        `/common/create4msetup/${process.part_id}/${process._id}/${reportId._id}?annual_date=${annual_month}&documentType=${documentType}&machine_name=${process.machine_id?.machine_name}&operation_name=${process.operation_name}&summary_date=${process.date}`
      );
      return;
    }
    if (process.part_id?._id) {
      navigate(
        `/common/create4msetup/${process.part_id?._id}/${process._id}?annual_date=${annual_month}&documentType=${documentType}&machine_name=${process.machine_id?.machine_name}&operation_name=${process.operation_name}&summary_date=${process.date}`
      );
      return;
    }
    navigate(
      `/common/create4msetup/${process.part_id}/${process._id}?annual_date=${annual_month}&documentType=${documentType}&machine_name=${process.machine_id?.machine_name}&operation_name=${process.operation_name}&summary_date=${process.date}`
    );
  };

  const parseStartOfMonth = (monthYearString: string): Date | null => {
    const [monthString, yearString] = monthYearString.split('-');
    const monthIndex = getMonthIndex(monthString);
    if (monthIndex !== -1) {
      return new Date(parseInt(yearString, 10), monthIndex, 1);
    }
    return null;
  };

  const parseEndOfMonth = (monthYearString: string): Date | null => {
    const [monthString, yearString] = monthYearString.split('-');
    const monthIndex = getMonthIndex(monthString);
    if (monthIndex !== -1) {
      return new Date(parseInt(yearString, 10), monthIndex + 1, 0);
    }
    return null;
  };

  const getMonthIndex = (monthString: string): number => {
    const months: string[] = [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ];
    return months.indexOf(monthString.toUpperCase());
  };

  useEffect(() => {
    axios
      .get('/api/machineRoutes//machineList/machineListForAutoComplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));

    setDateLimit({
      start: parseStartOfMonth(annual_month),
      end: parseEndOfMonth(annual_month),
    });
  }, []);
  return (
    <tbody>
      {moduleData.processes.map((process: any, index: number) => (
        <tr>
          <td>{index + 1}</td>
          <td>
            <CustomSelect
              name="change_type"
              key="change_type"
              value={process?.change_type}
              onChange={(e) => {
                if (process.date) {
                  let prevDate = new Date(process.date).getDate();
                  let values: any = { ...pictorial };
                  if (values?.[process.change_type]?.[prevDate - 1]) {
                    values[process.change_type][prevDate - 1] = false;
                  }
                  setPictorial(values);
                }
                process.change_type = e.target.value;
                process.date = null;
                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="man">Man</MenuItem>
              <MenuItem value="machine">Machine</MenuItem>
              <MenuItem value="material">Material</MenuItem>
              <MenuItem value="method">Method</MenuItem>
            </CustomSelect>
          </td>
          <td>
            {process.change_type && (
              <DatePickerMui
                sx={{ width: '150px' }}
                onChange={(date) => {
                  if (process.change_type) {
                    let currentDate = new Date(date).getDate();
                    let prevDate = new Date(process.date).getDate();
                    let values: any = { ...pictorial };
                    if (values[process.change_type][prevDate - 1]) {
                      values[process.change_type][prevDate - 1] = false;
                    }
                    values[process.change_type][currentDate - 1] = true;
                    setPictorial(values);
                  }
                  process.date = date;
                  setRender((prev) => !prev);
                }}
                value={moment(process.date || null)}
                minDate={dateLimit.start ? moment(dateLimit.start) : null}
                maxDate={dateLimit.end ? moment(dateLimit.end) : null}
              />
            )}
          </td>
          <td>
            {process.change_type && (
              <TimePicker
                sx={{ width: '150px' }}
                onChange={(date) => {
                  process.breakdown_from_time = date;
                  setRender((prev) => !prev);
                }}
                value={
                  process?.breakdown_from_time
                    ? moment(process.breakdown_from_time)
                    : null
                }
              />
            )}
          </td>
          <td>
            {process.change_type && (
              <TimePicker
                sx={{ width: '150px' }}
                onChange={(date) => {
                  process.breakdown_to_time = date;
                  setRender((prev) => !prev);
                }}
                value={
                  process?.breakdown_to_time
                    ? moment(process.breakdown_to_time)
                    : null
                }
              />
            )}
          </td>
          <td>
            {calculateDuration(
              process?.breakdown_from_time,
              process?.breakdown_to_time
            )}
          </td>
          <td>
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={process.machine_id || ''}
              onChange={(e, value) => {
                process.machine_id = value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td style={{ width: '250px', minWidth: '250px' }}>
            {isView ? (
              process?.part_id && process?.part_id?.part_name
            ) : (
              <Grid container xs={12} direction="row" alignItems={'center'}>
                <Grid item xs={10}>
                  <p
                    style={{
                      fontSize: '1.2rem',
                    }}
                  >
                    {typeof process?.part_id === 'object' &&
                    process?.part_id !== null
                      ? process?.part_id?.part_name
                      : process?.partName || ''}
                    {!process?.part_id && 'Pick Part Name'}
                  </p>
                </Grid>

                {process.part_id ? (
                  <Grid item xs={2}>
                    <RemoveIconButton
                      tooltipTitle="remove part"
                      onClick={() => {
                        process.part_id = null;
                        process.partNumber = null;
                        process.partName = null;
                        setRender((prev) => !prev);
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={2}>
                    <AddIconButton
                      tooltipTitle="add part"
                      onClick={() => {
                        setIsOpenPopup(true);
                        setPartIdx(index);
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </td>
          <td style={{ minWidth: '200px' }}>
            <OperationSelect
              {...{ process, isView, setRender }}
              partId={
                typeof process.part_id === 'string'
                  ? process?.part_id
                  : process?.part_id?._id
              }
            />
          </td>
          <td>
            {process.reason && process.action_taken
              ? actionTakenTickMarks[
                  `${process?.reason}${process?.action_taken}`
                ]?.expected
              : actionTakenTickMarks[`${process?.reason}`]?.expected}
          </td>
          <td>
            {process.reason && process.action_taken
              ? actionTakenTickMarks[
                  `${process?.reason}${process?.action_taken}`
                ]?.unexpected
              : actionTakenTickMarks[`${process?.reason}`]?.unexpected}
          </td>
          <td>
            {process.reason && process.action_taken
              ? actionTakenTickMarks[
                  `${process?.reason}${process?.action_taken}`
                ]?.abnormal
              : actionTakenTickMarks[`${process?.reason}`]?.abnormal}
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ width: '120px' }}
              name="current_level"
              type="text"
              value={process.current_level}
              onChange={(e) => {
                process.current_level = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ width: '120px' }}
              name="new_level"
              type="text"
              value={process.new_level}
              onChange={(e) => {
                process.new_level = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td style={{ minWidth: '150px' }}>
            <CustomSelect
              name="reason"
              value={process?.reason}
              onChange={(e) => {
                process.reason = e.target.value;
                process.action_taken = '';
                setRender((prev) => !prev);
              }}
            >
              {reasons[process.change_type] &&
                reasons[process.change_type].map((reason: any) => (
                  <MenuItem value={reason.value}>{reason.name}</MenuItem>
                ))}
            </CustomSelect>
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ width: '120px' }}
              name="suspected_qty"
              type="number"
              value={process.suspected_qty}
              onChange={(e) => {
                process.suspected_qty = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td style={{ minWidth: '150px' }}>
            {actionTakens[process?.reason] && (
              <CustomSelect
                name="reason"
                key="reason"
                value={process?.action_taken}
                onChange={(e) => {
                  process.action_taken = e.target.value;
                  setRender((prev) => !prev);
                }}
              >
                <MenuItem>None</MenuItem>
                {actionTakens[process.reason].map((action: any) => (
                  <MenuItem value={action.value}>{action.name}</MenuItem>
                ))}
              </CustomSelect>
            )}
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ width: '120px' }}
              name="ok"
              type="text"
              value={process.ok}
              onChange={(e) => {
                process.ok = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              style={{ width: '120px' }}
              name="ng"
              type="text"
              value={process.ng}
              onChange={(e) => {
                process.ng = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td>
            <CustomSelect
              name="remark_status"
              label=""
              value={process?.remark_status}
              onChange={(e) => {
                process.remark_status = e.target.value;
                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="">NONE</MenuItem>
              <MenuItem value="C">C</MenuItem>
              <MenuItem value="NC">NC</MenuItem>
            </CustomSelect>
          </td>
          <td>
            {process.reason && process.action_taken
              ? actionTakenTickMarks[
                  `${process?.reason}${process?.action_taken}`
                ]?.procedure
              : actionTakenTickMarks[`${process?.reason}`]?.procedure}
          </td>
          <td style={{ minWidth: '100px' }}>
            {!isView && (
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handelDeleteRows(index)}
              />
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TBody;
