import { FaCloudUploadAlt, FaLayerGroup } from 'react-icons/fa';
import { TbAspectRatio } from 'react-icons/tb';

const tiles = [
  {
    sNo: 1,
    link: '/common/internalQualityAuditPlanTiles',
    isLink: true,
    clauseNo: 4,
    icon: <FaLayerGroup size="5rem" color="#495057" />,
    p: 'Internal Quality Audit',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/vdaProcessAuditPlanTiles',
    isLink: true,
    clauseNo: 4,
    icon: <FaLayerGroup size="5rem" color="#495057" />,
    p: 'Special Audit',
    span: 'Plan',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/masterListOfProcessVda?filter=createdAt',
    isLink: true,
    icon: <TbAspectRatio size="5rem" color="#343a40" />,
    p: 'Master list of',
    span: 'Process',
    isIcon: true,
  },
  {
    sNo: 24,
    link: `/common/listOfProceduresMRMUploads/?documentType=clause6Mrm&heading=MRM Uploads`,
    isLink: true,
    icon: <FaCloudUploadAlt size="5rem" color="#003566" />,
    p: 'MRM',
    span: 'Uploads',
    isIcon: true,
  },
];

export default tiles;
