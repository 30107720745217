import React, { useEffect, useState } from 'react';
import { Grid, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import { SearchBar } from '../../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
  AddIconButton,
  CardIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import formatDate from '../../../components/common/formatDate';
import { useNavigate } from 'react-router-dom';
import TrainingTopicList from './TrainingTopicList';

const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'Employee Name', rowspan: 1, colspan: 1 },
    { text: 'Employee Id', rowspan: 1, colspan: 1 },
    { text: 'Designation', rowspan: 1, colspan: 1 },
    { text: 'Qualification', rowspan: 1, colspan: 1 },
    { text: 'Joining Date', rowspan: 1, colspan: 1 },
    { text: 'Date of Leaving', rowspan: 1, colspan: 1 },
    { text: 'Exp. years', rowspan: 1, colspan: 1 },
    { text: 'Department', rowspan: 1, colspan: 1 },
    { text: 'Current Level', rowspan: 1, colspan: 1 },
    { text: 'Attach JD', rowspan: 1, colspan: 1 },
    { text: 'Competence Matrix', rowspan: 1, colspan: 1 },
    { text: 'STATUS', rowspan: 1, colspan: 1 },
    { text: 'Training Log', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'Most Recent' },
  { value: 'employee_name', name: 'Employee Name' },
  { value: 'employee_id', name: 'Employee Id' },
];
const levelText = (text: string) => {
  if (!text) return;
  return text.replace('_', ' ');
};

const List = () => {
  const [data, setData] = useState<any[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [employeeId, setEmployeeId] = useState<string | null>(null);
  const [filterDepartment, setFilterDepartment] = useState([]);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [isOpenTrainingLog, setIsOpenTrainingLog] = useState(false);
  const [listId, setListId] = useState('');
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const department = useQuery('department');
  const page = useQuery('page') || 1;
  const navigate = useNavigate();
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE EMPLOYEE',
    'Are you sure you want to delete this?',
  );
  useEffect(() => {
    axios
      .get('/api/department/department/departmentForAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((department: any) => ({
            name: department.department_name,
            value: department.department_name,
          }));
          setFilterDepartment(formattedData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/employeeRoute?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&department=${department}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/employeeRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    isModal,
    filter,
    department,
  ]);

  return (
    <>
      <ChildHeader text="EMPLOYEE LIST">
        <AddButton onClick={() => setIsModal(true)} label="New Employee" />
      </ChildHeader>
      <SearchFilterContainer filter={true} style={{ display: 'flex' }}>
        <SearchBar label="Search" sx={{ width: 900 }} />
        <FilterBy label="Sort By" options={filterOptions} sx={{ width: 300 }} />
        <FilterBy
          sx={{ width: 300 }}
          name="department"
          label="Filter By Department"
          options={filterDepartment}
        />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item.employee_name}</TableCell>
                  <TableCell align="center">{item.employee_id}</TableCell>
                  <TableCell align="center">
                    {item?.designation_id?.designation}
                  </TableCell>
                  <TableCell align="center">{item.qualification}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.data_of_joining)}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item.date_of_leave)}
                  </TableCell>
                  <TableCell align="center">{item.exp_years}</TableCell>
                  <TableCell align="center">
                    {item?.area_of_work?.department_name}
                  </TableCell>
                  <TableCell align="center">
                    {levelText(item?.current_level)}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      {item?.is_job_description ? (
                        <ViewIconButton
                          tooltipTitle="View JD"
                          sx={{
                            fontColor: '#343a40',
                          }}
                          onClick={() => {
                            navigate(
                              `/common/employeeJD/${item._id}?designation_id=${item?.designation_id?._id}&is_job_description=${item?.is_job_description}`,
                            );
                          }}
                        />
                      ) : (
                        <AddIconButton
                          tooltipTitle="Attach JD"
                          onClick={() => {
                            if (
                              !item?.designation_id?._id &&
                              !item?.department_id?._id
                            ) {
                              alert('Designation and Department Required');
                              return;
                            }
                            navigate(
                              `/common/employeeJD/${item._id}?designation_id=${item?.designation_id?._id}&departmentId=${item?.area_of_work?._id}&is_job_description=${item?.is_job_description}`,
                            );
                          }}
                        />
                      )}
                      {/* <ViewIconButton tooltipTitle="View" onClick={() => {}} /> */}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <CardIconButton
                        tooltipTitle="add competenc matrix"
                        onClick={() => {
                          navigate(`/common/competenceMatrix/${item._id}`);
                        }}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{
                      textTransform: 'uppercase',
                      minWidth: '240px',
                      ...(item.status === 'draft' && {
                        backgroundColor: '#3a86ff',
                      }),
                      ...(item.status === 'pending' && {
                        backgroundColor: '#FDD835',
                      }),
                      ...(item.status === 'approved' && {
                        backgroundColor: '#2b8a3e',
                        color: '#fff',
                      }),
                    }}
                  >
                    {item?.status === 'pending'
                      ? 'pending for approval'
                      : item.status}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <AddIconButton
                        tooltipTitle="Training Log"
                        onClick={() => {
                          setListId(item);
                          setIsOpenTrainingLog(true);
                        }}
                      />
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <ViewIconButton
                        tooltipTitle="View"
                        onClick={() => {
                          window.open(item?.photo, '_blank');
                        }}
                      />
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setEmployeeId(item?._id || '');
                          setIsModal(true);
                        }}
                      />

                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteDocument(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setEmployeeId(null);
          setIsModal(false);
        }}
        title="EMPLOYEE"
      >
        <Create
          {...{ setEmployeeId }}
          id={employeeId}
          setIsModal={setIsModal}
        />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
      <ModalCustom
        title={`TRAINING LOG LIST`}
        openModal={isOpenTrainingLog}
        closeModal={() => {
          setListId('');
          setIsOpenTrainingLog(false);
        }}
      >
        <TrainingTopicList setIsModal={isOpenTrainingLog} listId={listId} />
      </ModalCustom>
    </>
  );
};

export default List;
