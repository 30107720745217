import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import { Checkbox } from '@mui/material';
import { CustomButton } from '../../../../../../components/common/button/CustomButtons';
import { DeleteIconButton } from '../../../../../../components/common/button/CustomIconButton';
interface IProps {
  moduleData: any;
  handelDeleteRows: any;
  isView: boolean;
}
const TBody1 = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  // Track the selected option

  const options = ['Begginer', 'Practitioner', 'Experts'];
  return (
    <tbody>
      <tr>
        <th colSpan={2}>
          {!isView && (
            <CustomButton
              sx={{
                backgroundColor: '#1c7ed6',
              }}
              onClick={async () => {
                // const ans = await confirmAddRow();
                // if (!ans) return;
                moduleData.matrix.process_1.push({});
                setRender((prev) => !prev);
              }}
            >
              ADD ROWS
            </CustomButton>
          )}
        </th>
        <th colSpan={4}>Proficiency Level </th>
        <th></th>
        <th>Beginner</th>
        <th>Practitioner</th>
        <th>Experts</th>
        <th>Remarks</th>
        <th>Action</th>
      </tr>
      <tr></tr>
      {moduleData?.matrix &&
        moduleData?.matrix?.process_1.map((item: any, index: number) => (
          <>
            <tr key={index + 'specs'}>
              {index === 0 && (
                <td rowSpan={moduleData?.matrix?.process_1?.length * 2}>
                  Technical Competency
                </td>
              )}
              <td rowSpan={2}>{index + 1}</td>

              <td colSpan={4} rowSpan={2}>
                <TableInput
                  isView={isView}
                  name="technical_competencies"
                  type="text"
                  value={item.technical_competencies}
                  onChange={(e) => {
                    item.technical_competencies = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <th>Ideal</th>
              {options.map((option) => (
                <td>
                  <Checkbox
                    checked={item.proficiency_level_ideal === option}
                    onChange={() => {
                      item.proficiency_level_ideal = option;
                      setRender((prev) => !prev);
                    }}
                    disabled={isView}
                  />
                </td>
              ))}
              <td rowSpan={2}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={item.remarks}
                  onChange={(e) => {
                    item.remarks = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td
                rowSpan={2}
                style={{ minWidth: '100px', textAlign: 'center' }}
              >
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handelDeleteRows('process_1', index)}
                />
              </td>
            </tr>
            <tr>
              <th>Actual</th>
              {options.map((option) => (
                <td>
                  <Checkbox
                    checked={item.proficiency_level_actual === option}
                    onChange={() => {
                      item.proficiency_level_actual = option;
                      setRender((prev) => !prev);
                    }}
                    disabled={isView}
                  />
                </td>
              ))}
            </tr>
          </>
        ))}
    </tbody>
  );
};

export default TBody1;
