import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';

const TController = ({ Data }: { Data: any }) => {
  return (
    <Box>
      <RenderTable>
        <THead />
        <TBody Data={Data} />
      </RenderTable>
    </Box>
  );
};

export default TController;
