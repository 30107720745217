// NOTE : always update this serial no when you add any condition  = level20
const reasons: { [key: string]: any } = {
  man: [
    { name: 'Operator On leave with information', value: 'level1' },
    {
      name: 'Operator On leave without information (during start of shift)',
      value: 'level2',
    },

    {
      name: 'Operator suddenly leave work place due to illness (after start of production)',
      value: 'level3',
    },
    {
      name: 'Operator return from Long leave (More than 30 days)',
      value: 'level5',
    },
    {
      name: 'New Operator',
      value: 'level6',
    },
  ],
  material: [
    {
      name: 'Material received from other approved source',
      value: 'level7',
    },
    {
      name: 'Material used from unapproved source without intimation',
      value: 'level8',
    },
    
  ],
  method: [
    {
      name: 'Any change in Process Parameter',
      value: 'level9',
    },
    {
      name: 'Any change in process method (ECN/PCN)',
      value: 'level10',
    },
    {
      name: 'Any change in tool or machine after customer complaint',
      value: 'level11',
    },
    {
      name: 'Any change in process sequence (Layout Change)',
      value: 'level12',
    },
  ],
  machine: [
    {
      name: 'Machine under preventive maintenance',
      value: 'level13',
    },
    {
      name: 'Machine under breakdown',
      value: 'level14',
    },
    {
      name: 'Die/Tool under break down',
      value: 'level15',
    },
    {
      name: 'Die/Tool Grinding as per frequency',
      value: 'level16',
    },
    {
      name: 'Power Failure',
      value: 'level17',
    },
  ],
};

const actionTakens: { [key: string]: any } = {
  level1: [
    {
      name: 'Operator of same skill deputed',
      value: 'action1',
    },
    {
      name: 'Operator of under skill level deputed',
      value: 'action2',
    },
  ],
  level2: [
    {
      name: 'Operator of same skill deputed',
      value: 'action1',
    },
    {
      name: 'Operator of under skill deputed ',
      value: 'action2',
    },
  ],
  level3: [
    {
      name: 'Operator of same skill deputed',
      value: 'action1',
    },
    {
      name: 'Operator of under skill deputed ',
      value: 'action2',
    },
  ],
  level4: [
    {
      name: 'Operator of same skill level deputed',
      value: 'action1',
    },
  ],
  level5: [
    {
      name: 'Consider as a new operator ( Provide Training as per Operation Standard)',
      value: 'action1',
    },
  ],
  level6: [
    {
      name: 'Provide Training as per Operation Standard',
      value: 'action1',
    },
  ],
  level7: [
    {
      name: 'Start production after set up approval',
      value: 'action1',
    },
  ],
  level8: [
    {
      name: 'Check with MTC & get approval from customer',
      value: 'action1',
    },
    {
      name: 'Operator of same skill level deputed',
      value: 'action2',
    },
  ],

  level9: [
    {
      name: 'Start production after taking approval from quality & Customer',
      value: 'action1',
    },
    {
      name: 'Machine Parameters found out of spec during routine checking',
      value: 'action2',
    },
  ],
  level10: [
    {
      name: 'Start production after set up approval from quality & Customer',
      value: 'action1',
    },
  ],
  level11: [
    {
      name: 'Take action as per counter measure action sheet',
      value: 'action1',
    },
  ],
  level12: [
    {
      name: 'Start production after set up approval from quality & Customer',
      value: 'action1',
    },
  ],
  level13: [
    {
      name: 'Working on same m/c after preventive Maintenance',
      value: 'action1',
    },
    {
      name: 'Production shifted to same capacity m/c',
      value: 'action2',
    },
  ],
  level14: [
    {
      name: 'Working on same m/c after repair',
      value: 'action1',
    },
    {
      name: 'Production shifted to same capacity m/c',
      value: 'action2',
    },
  ],
  level15: [
    {
      name: 'Working with new Die/Tool Or Working on same tool after repair',
      value: 'action1',
    },
  ],
  level16: [
    {
      name: 'Start production after Grinding',
      value: 'action1',
    },
  ],
  level17: [
    {
      name: 'Re-start the machine',
      value: 'action1',
    },
  ],
};

const actionTakenTickMarks: { [key: string]: any } = {
  level1action1: {
    expected: 'Planned Change',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
    procedure: `A. Take set up approval`,
  },
  level1action2: {
    abnormal:'Abnorrmal situation',
    setup: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `A. Take set up approval
    C. Check allthe parts 1OO% visually 10% dimensionaly & | keep record of 05 Pcs.`,
  },
  level2action1: {
    expected: 'Planned',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
    procedure: `A. Take set up approval`,
  },
  level2action2: {
    abnormal: 'Abnormal Situation',
    setup: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `A. Take set up approval
    C. Check allthe parts 1OO% visually 10% dimensionaly & | keep record of 05 Pcs.`,
  },
  level3action1: {
    unexpected: 'Unplanned',
    setup: true,
    training: false,
    retro: true,
    suspected: false,
    procedure: `A. Take set up approval B 1. Take retroactive B 2. Check allthe parts 1OO% visually 10% dimensionaly. Since last inspection and keep record of 05 Pcs & link with invoice.`,
  },
  level3action2: {
    abnormal: 'Abnormal Situation',
    setup: true,
    training: false,
    retro: true,
    suspected: true,
    procedure: `A. Take set up approval B. Take retroactive C. Check allthe parts 1OO% visually 10% dimensionaly. Since last inspection and keep record of 05 Pcs & link with invoice.`,
  },
  level4action1: {
    unexpected: 'Unplanned',
    setup: true,
    training: false,
    retro: true,
    suspected: false,
    procedure: `A. Take set up approval B. Take retroactive C. Check allthe parts 1OO% visually 10% dimensionaly and keep record of 05 Pcs`,
  },
  level5action1: {
    abnormal: 'Planned Change',
    setup: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `A. Take set up approval. C. Provide Training to new operator as per Operation standard & Inprocess inspection per hours`,
  },
  level6action1: {
    expected: 'Planned Change',
    setup: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `1. Take set up approval 2. Provide training to new operator as per Operation standard & inprocess inspection per hours`,
  },
  // material
  level7action1: {
    expected: 'Planned',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
    procedure: `A. Take set up approval`,
  },
  level8action1: {
    unexpected: 'Unplanned',
    setup: true,
    training: false,
    retro: true,
    suspected: true,
    procedure: `. Take set up approval B. Take retroactive C. Dispatch material after getting approval from customer`,
  },
  level8action2: {
    abnormal: 'Abnormal Situation',
    setup: true,
    training: false,
    retro: true,
    suspected: true,
    procedure: `. Take set up approval B. Take retroactive C. Dispatch material after getting approval from customer`,
  },
  // method
  level9action1: {
    expected: 'Planned',
    setup: true,
    training: false,
    retro: true,
    suspected: true,
    procedure: `A. Take set up approval B. Iake retroactive C. Check all the parts 100% visually & 1O% dimensionaly produced since last check of parameters & keep record of 05 Pcs.`,
  },
  level9action2: {
    abnormal:'Abnormal Situation',
    setup: true,
    training: false,
    retro: true,
    suspected: true,
    procedure: `A. Take set up approval B. Iake retroactive C. Check all the parts 100% visually & 1O% dimensionaly produced since last check of parameters & keep record of 05 Pcs.`,
  },
  level10action1: {
    expected: 'Planned',
    setup: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `A. Take set up approval C. Update related doc's if required`,
  },
  level11action1: {
    expected: 'Planned',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
    procedure: `A. Take set up approval`,
  },
  level12action1: {
    expected: 'Planned',
    setup: true,
    training: false,
    retro: false,
    suspected: true,
    procedure: `A. Take set up approval C. Update related doc's if required`,
  },
  // machine
  level13action1: {
    expected: 'Planned',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
    procedure: `A. Take set up approval`,
  },
  level13action2: {
    expected: 'Planned',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
    procedure: `A. Take set up approval`,
  },
  level14action1: {
    unexpected: 'Unplanned',
    setup: true,
    training: false,
    retro: true,
    suspected: false,
    procedure: `A. Take set up approval B 1. Take retroactive B 2. Check allthe parts 1OO% visually 10% dimensionaly. Since last inspection and keep record of 05 Pcs & link with invoice.`,
  },
  level14action2: {
    unexpected: 'Unplanned',
    setup: true,
    training: false,
    retro: true,
    suspected: false,
    procedure: `A. Take set up approval B 1. Take retroactive B 2. Check allthe parts 1OO% visually 10% dimensionaly. Since last inspection and keep record of 05 Pcs & link with invoice.`,
  },
  level15action1: {
    unexpected: 'Unplanned',
    setup: true,
    training: false,
    retro: true,
    suspected: false,
    procedure: `A. Take set up approval B 1. Take retroactive B 2. Check allthe parts 1OO% visually 10% dimensionaly. Since last inspection and keep record of 05 Pcs & link with invoice.`,
  },
  level16action1: {
    expected: 'planned',
    setup: true,
    training: false,
    retro: false,
    suspected: false,
    procedure: `A. Take set up approval`,
  },
  level17action1: {
    unexpected: 'unplanned',
    setup: false,
    training: false,
    retro: false,
    suspected: true,
    procedure: `C. Put last piece in Red bin.`,
  },
};
export { reasons, actionTakenTickMarks, actionTakens };
