import React from 'react';
import { useQuery } from '../../../../../hooks/UseQuery';
import TBodyRow4 from './TBodyRow4';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody4 = ({ moduleData, isView }: IProps) => {
  const checkSheetType = useQuery('checkSheetType');
  return (
    <>
      <tbody>
        <tr>
          <th colSpan={2}>Rev.Date</th>
          <th colSpan={8}>Rev.No.</th>
          <th colSpan={25}>Description of Revision</th>
          <th colSpan={5}>Remarks</th>
        </tr>
        {moduleData &&
          checkSheetType &&
          moduleData?.[checkSheetType]?.revision_data?.map(
            (process: any, index: number) => (
              <React.Fragment key={index + 1 + 'process'}>
                <TBodyRow4
                  {...{
                    moduleData,
                    process,
                    index,
                    isView,
                  }}
                />
              </React.Fragment>
            )
          )}
      </tbody>
    </>
  );
};

export default TBody4;
