import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
}

const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.map((part: any, index: number) => (
            <tr key={index + 10.1 + 'heading '}>
              <th style={{ minWidth: '50px', textAlign: 'center' }}>
                {index + 1}
              </th>
              <td
                style={{
                  width: '280px',
                  minWidth: '280px',
                  textAlign: 'center',
                }}
              >
                {part.ri_part_id?.part_number}
              </td>
              <td
                style={{
                  textAlign: 'center',
                }}
              >
                {part.ri_part_id?.erp_drawing_no}
              </td>
              <td
                style={{
                  textAlign: 'center',
                }}
              >
                {part.ri_part_id?.fox_pro_drg_no}
              </td>
              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="max_store_qty"
                  type="number"
                  style={{ textAlign: 'center' }}
                  value={part.max_store_qty}
                  onChange={(e) => {
                    part[e.target.name] = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="min_store_qty"
                  style={{ textAlign: 'center' }}
                  type="number"
                  value={part.min_store_qty}
                  onChange={(e) => {
                    part[e.target.name] = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="max_bin_qty"
                  type="number"
                  style={{ textAlign: 'center' }}
                  value={part.max_bin_qty}
                  onChange={(e) => {
                    part[e.target.name] = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(part._id)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
