import { AiFillTool, AiOutlinePartition } from 'react-icons/ai';
import { BsFuelPumpDieselFill } from 'react-icons/bs';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { GoTools } from 'react-icons/go';
import { TbBuildingWarehouse } from 'react-icons/tb';
const tiles = [
  {
    sNo: 24,
    link: `/common/listOfProcedures/?documentType=clause10MaintenanceSopDocument&heading=MAINTENANCE SOP DOCUMENT`,
    isLink: true,
    icon: <FaCloudUploadAlt size="5rem" color="#003566" />,
    p: 'MAINTENANCE',
    span: 'SOP DOCUMENT',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/admin/masterlistofmachine?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <AiOutlinePartition size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'Machine',
    isIcon: true,
  },
  {
    sNo: 2,
    link: 'https://iai.secure.aqua.mushinlabs.com',
    isLink: true,
    clauseNo: 4,
    target: '_blank',
    icon: <AiFillTool size="5rem" color="#a12222" />,
    p: 'MAINTENANCE',
    span: 'DEPARTMENT',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/masterListOfCriticalSparePart?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <TbBuildingWarehouse size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'Critical Spare Parts',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/maintenanceLog',
    isLink: true,
    clauseNo: 4,
    icon: <TbBuildingWarehouse size="5rem" color="#a12222" />,
    p: 'Maintenance',
    span: 'Log',
    isIcon: true,
  },
  {
    sNo: 25,
    link: '/common/dieselMonitoringSheetList',
    isLink: true,
    icon: <BsFuelPumpDieselFill size="5rem" color="#1864ab" />,
    p: 'Diesel Consumption',
    span: 'Monitoring Sheet',
    isIcon: true,
  },
];

export default tiles;
