import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import { TableCompHead } from '../../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={6}
        rowSpan={2}
        labels={['Doc No', 'Issued On :']}
        values={['FM/XLV/TR026', '06.01.2022']}
        heading="JOB DESCRIPTION"
      />
      <tr>
        <th colSpan={3} align="left">
          NAME
        </th>
        <th colSpan={3}>{moduleData.employee_name}</th>
      </tr>
      <tr>
        <th align="left">Man Profile</th>
        <th align="left">Designation</th>
        <th align="left">
          {moduleData?.designation_id?.designation || moduleData?.designation}
        </th>
        <th align="left">Qualification</th>
        <th align="left" colSpan={2}>
          {moduleData.qualification}
        </th>
      </tr>
      <tr>
        <th align="left">Department</th>
        <th colSpan={2} align="left">
          {moduleData?.area_of_work?.department_name}
        </th>
        <th align="left">Experience</th>
        <th align="left" colSpan={2}>
          {moduleData?.exp_years}
        </th>
      </tr>
      <tr>
        <th align="left">Level</th>
        <th colSpan={2} align="left">
          {moduleData?.current_level}
        </th>
        <th align="left">Preferred age</th>
        <th align="left" colSpan={2}>
          <TableInput
            isView={isView}
            name="preferred_age"
            type="text"
            value={moduleData.preferred_age}
            onChange={(e) => {
              moduleData.preferred_age = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3}>Reporting to whom: </th>
        <th colSpan={3}>
          <TableInput
            isView={isView}
            name="report_to_whom"
            type="text"
            value={moduleData.report_to_whom}
            onChange={(e) => {
              moduleData.report_to_whom = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr style={{ height: '40px' }}>
        <th colSpan={6}></th>
      </tr>
    </thead>
  );
};

export default THead;
