import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  apiData: any;
  year: number;
}
const TController = ({ apiData, year }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <>
          <THead year={year} />
          <TBody apiData={apiData} />{' '}
        </>
      </RenderTable>
    </Box>
  );
};

export default TController;
