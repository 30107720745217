import TController from './create/TController';
import ParetoChart from './ParetoChart';
interface IProps {
  apiData: any;
  year: number;
  month: number;
  target: number;
}
const SupplierPpmDashboard = ({ apiData, year, month, target }: IProps) => {
  return (
    <div>
      <TController
        apiData={apiData}
        year={year}
        month={month}
        target={target}
      />
      <ParetoChart
        apiData={apiData}
        year={year}
        month={month}
        target={target}
      />
    </div>
  );
};

export default SupplierPpmDashboard;
