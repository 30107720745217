import React, { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData &&
        moduleData?.c1?.map((process: any, index: number) => (
          <tr>
            <td>{index + 1}</td>
            <td style={{ minWidth: '300px', textAlign: 'center' }}>
              {process.check_point}
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="mode_of_inspection"
                type="text"
                value={process.mode_of_inspection}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="obs"
                type="text"
                value={process.obs}
                onChange={(e) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>

            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              {isView ? (
                process.status
              ) : (
                <CustomSelect
                  name="status"
                  value={process.status}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">NONE</MenuItem>
                  <MenuItem value="OK">OK</MenuItem>
                  <MenuItem value="NOT OK">NOT OK</MenuItem>
                </CustomSelect>
              )}
            </td>
          </tr>
        ))}
      <tr>
        <th colSpan={5} style={{ textAlign: 'left' }}>
          NC Details:{' '}
          <TableInput
            isView={isView}
            name="s6"
            type="text"
            value={moduleData.s6}
            onChange={(e) => {
              moduleData.s6 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tbody>
  );
};

export default TBody;
