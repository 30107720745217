import { Box } from '@mui/system';
import axios from 'axios';
import { useState } from 'react';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './create/TController';
import useGetData from './helpers/useGetData';
import { Loader } from '../../../components/common/Loader';
import HeaderCreate from './HeaderCreate';
import { apiConstantAddParts } from './constant';
import ModalCustom from '../../../components/common/Modal';
import PickPart from '../../../components/ui/PickPart';

interface MyData {
  moduleData: any;
  isLoading: boolean;
  DialogSync: any;
  machineData: any;
  setModuleData: any;
  DialogDelete: any;
  confirmDelete: any;
  setRenderforUpdate: any;
  confirmSync: any;
  setParentRender: React.Dispatch<React.SetStateAction<boolean>>;
  error?: string | null;
}
const Create = () => {
  const date = useQuery('date');
  // const isView = useQuery('isView') === 'true' ? true : false;
  const [islock, setIsLock] = useState<boolean>(true);
  const [isView, setisView] = useState<boolean>(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [partIdx, setPartIdx] = useState<number>(0);
  const {
    error,
    moduleData,
    setModuleData,
    isLoading,
    DialogSync,
    DialogDelete,
    confirmDelete,
    machineData,
    confirmSync,
    setParentRender,
    setRenderforUpdate,
  }: MyData = useGetData();

  const handleUpdate = async () => {
    if (!date) {
      alert('date required');
      return;
    }
    const list = moduleData?.list;
    if (list?.length == 0) {
      return;
    }
    // have to change the name because of query
    const { date: checkDate, checked_qty } = list?.[list.length - 1];
    if (
      checkDate === null ||
      checkDate._isValid === false ||
      checked_qty === null ||
      checked_qty === undefined ||
      checked_qty === ''
    ) {
      alert('Please fill all the field first');
      return;
    }

    const ans = await confirmSync();
    if (!ans) return;
    if (moduleData?._id) {
      axios
        .put(
          `${apiConstantAddParts.apiConstant}/${moduleData?._id}`,
          moduleData,
        )
        .then((res) => {
          setRenderforUpdate((prev: any) => !prev);
          alert('successfully updated');
        })
        .catch((err) => console.error(err));
    } else {
      axios
        .post(`${apiConstantAddParts.apiConstant}`, moduleData)
        .then((res) => {
          setRenderforUpdate((prev: any) => !prev);
          alert('successfully updated');
        })
        .catch((err) => console.error(err));
    }
  };

  const handleReportDelete = async () => {
    const ans = await confirmDelete();
    if (!ans) return;
    axios
      .delete(`${apiConstantAddParts.apiConstant}/${moduleData?._id}`)
      .then((res) => {
        setRenderforUpdate((prev: any) => !prev);
        alert('successfully Deleted');
      })
      .catch((err) => console.error(err));
  };

  const handleDelete = (list_index: number) => {
    setModuleData((prev: any) => ({
      ...prev,
      list: prev.list.filter((_: any, index: number) => index !== list_index),
    }));
  };

  return (
    <Box>
      <Loader loader={isLoading} />
      <HeaderCreate
        {...{
          handleUpdate,
          moduleData,
          setModuleData,
          handleReportDelete,
        }}
      />
      <TController
        {...{
          moduleData,
          setRenderforUpdate,
          isView,
          handleDelete,
          setIsOpenPopup,
          setPartIdx,
          setModuleData,
          setParentRender,
          machineData,
        }}
      />

      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PickPart moduleData={moduleData?.list?.[partIdx]} />
      </ModalCustom>

      <DialogSync isSubmitContent={false} />
      <DialogDelete isSubmitContent={false} />
    </Box>
  );
};

export default Create;
