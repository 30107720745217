export const pallMachineData = [
  {
    contains: '5S VERIFICATION',
    lists: [
      {
        what: 'मशीन की सफ़ाई करें',
        where: 'मशीन पर',
        how: 'कपडे से',
        who: 'ओपरेटर',
        when: 'प्रतिदिन',
        checked_by: 'Operator',
        check_1: new Array(31),
        check_2: new Array(31),
      },
      {
        what: 'चैक करें कि तेल  साफ़ हो',
        where: 'मशीन पर',
        how: 'हाथ से',
        who: 'ओपरेटर',
        when: 'प्रतिदिन',
        checked_by: 'Operator',
        check_1: new Array(31),
        check_2: new Array(31),
      },
    ],
  },
  {
    contains: 'MACHINE VERIFICATION',
    lists: [
      {
        what: 'चैक करें कि हवा का प्रेसर वाल्व सही काम कर रहे हों',
        where: 'मशीन पर',
        how: 'विजुअल',
        who: 'ओपरेटर',
        when: 'प्रतिदिन',
        checked_by: 'Operator',
        check_1: new Array(31),
        check_2: new Array(31),
      },
      {
        what: 'चेक करे कि कन्ट्रोल पेनल  सही से काम कर रहे हो',
        where: 'मशीन पर',
        how: 'विजुअल',
        who: 'ओपरेटर',
        when: 'प्रतिदिन',
        checked_by: 'Operator',
        check_1: new Array(31),
        check_2: new Array(31),
      },
      {
        what: 'चैक करे कि  तेल का लेवल सही हो',
        where: 'मशीन पर',
        how: 'विजुअल',
        who: 'ओपरेटर',
        when: 'प्रतिदिन',
        checked_by: 'Operator',
        check_1: new Array(31),
        check_2: new Array(31),
      },
    ],
  },
  {
    contains: 'SAFETY VERIFICATION',
    lists: [
      {
        what: 'ON / OFF बटन सही काम कर रहे हों',
        where: 'मशीन पर',
        how: 'हाथ से',
        who: 'ओपरेटर',
        when: 'प्रतिदिन',
        checked_by: 'Operator',
        check_1: new Array(31),
        check_2: new Array(31),
      },
      {
        what: 'सभी नट बोल्ट ठीक तरह से टाईट हो',
        where: 'मशीन पर',
        how: 'हाथ से',
        who: 'ओपरेटर',
        when: 'प्रतिदिन',
        checked_by: 'Operator',
        check_1: new Array(31),
        check_2: new Array(31),
      },
      {
        what: 'इलेक्ट्रिकल कनैक्शन ढीले न हों',
        where: 'मशीन पर',
        how: 'विजुअल',
        who: 'ओपरेटर',
        when: 'प्रतिदिन',
        checked_by: 'Operator',
        check_1: new Array(31),
        check_2: new Array(31),
      },
      {
        what: 'मशीन मे कोई असामान्य आवाज न हों',
        where: 'मशीन पर',
        how: 'विजुअल',
        who: 'ओपरेटर',
        when: 'प्रतिदिन',
        checked_by: 'Operator',
        check_1: new Array(31),
        check_2: new Array(31),
      },
    ],
  },
];
