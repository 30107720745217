import axios from 'axios';
import { useState, useEffect } from 'react';
import lodash from 'lodash';
import { matrixActivity, npdTimePlanConstant } from './constants';

const useGetData = (partId: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    phase_1: [],
    phase_2: [],
    phase_3: [],
    phase_4: [],
    phase_5: [],
    phase_1_employees_id: [],
    phase_2_employees_id: [],
    phase_3_employees_id: [],
    phase_4_employees_id: [],
    phase_5_employees_id: [],
    phase_1_employees: [],
    phase_2_employees: [],
    phase_3_employees: [],
    phase_4_employees: [],
    phase_5_employees: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [useEffectRender, setUseEffectRender] = useState(false);

  const syncActivity = async (data: any, partId: string | undefined) => {
    let res: any = await axios
      .get(`/api/npdMatrixRoute/${partId}`)
      .catch((err) => console.log(err));
    if (res.data) {
      const apqpMatrix = res.data;

      ['phase_1', 'phase_2', 'phase_3', 'phase_4', 'phase_5'].map((phase) => {
        apqpMatrix[phase].forEach((item: any, index: number) => {
          data[phase][index].resp = '';
          matrixActivity.forEach((matrix) => {
            if (!item[matrix.param]) {
              return;
            }
            if (item[matrix.param] && item[matrix.param]) {
              data[phase][index].resp += ` ${matrix.name} `;
            }
          });
        });
      });
    }
    return data;
  };
  useEffect(() => {
    axios
      .get(`/api/EmployeeRoute/employee/customerForAutoComplete`)
      .then((res) => {
        setEmployee(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`/api/npdTimePlanRoute/${partId}`)
      .then(async (res) => {
        if (res.data?.msg === 'document not found') {
          const rawData = {
            phase_months: [],
            ...lodash.cloneDeep(npdTimePlanConstant),
            phase_1_employees_id: [],
            phase_2_employees_id: [],
            phase_3_employees_id: [],
            phase_4_employees_id: [],
            phase_5_employees_id: [],
            phase_1_employees: [],
            phase_2_employees: [],
            phase_3_employees: [],
            phase_4_employees: [],
            phase_5_employees: [],
            phase_1_remarks: [],
            phase_2_remarks: [],
            phase_3_remarks: [],
            phase_4_remarks: [],
            phase_5_remarks: [],
          };
          const convertedApqpTimePlan = await syncActivity(rawData, partId);
          setModuleData(convertedApqpTimePlan);
          return;
        }
        res.data.phase_1_employees = [];
        res.data.phase_2_employees = [];
        res.data.phase_3_employees = [];
        res.data.phase_4_employees = [];
        res.data.phase_5_employees = [];
        const convertedApqpTimePlan = await syncActivity(res.data, partId);
        setModuleData(convertedApqpTimePlan);
      })
      .catch((err) => console.log(err));
  }, [employee, useEffectRender]);
  return {
    moduleData,
    employee,
    isLoading,
    setIsLoading,
    setUseEffectRender,
    error,
  };
};

export default useGetData;
