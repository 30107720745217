import { MenuItem } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import CustomSelect from '../../../../components/common/SelectField';
import { TableCompHead, TableHeading } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
  documentType: string;
}

const THead = ({ moduleData, isView, documentType }: IProps) => {
  const [render, setRender] = useState(false);
  const test = useQuery('test') === 'true' ? true : false;
  return (
    <thead>
      <TableCompHead
        colSpan={test ? 24 : 20}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="production log book"
      />
      <tr>
        <th colSpan={9} align="left">
          Date:
          {isView ? (
            formatDate(moduleData.production_date)
          ) : (
            <DatePickerMui
              value={moment(moduleData.production_date)}
              onChange={(date) => {
                moduleData.production_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={test ? 15 : 11} align="left">
          Shift:
          {isView ? (
            moduleData.shift
          ) : (
            <CustomSelect
              name="shift"
              value={moduleData.shift}
              onChange={(e) => {
                moduleData.shift = e.target.value;
                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="">NONE</MenuItem>
              <MenuItem value="A">A</MenuItem>
              <MenuItem value="B">B</MenuItem>
            </CustomSelect>
          )}
        </th>
      </tr>
      {documentType !== 'groundFloor' && (
        <tr>
          <th colSpan={9} align="left">
            Line Incharge:
            <TableInput
              isView={isView}
              name="line_incharge_id"
              type="text"
              value={moduleData.line_incharge_id}
              onChange={(e) => {
                moduleData.line_incharge_id = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </th>

          <th colSpan={test ? 15 : 11} align="left">
            Line No:
            {isView ? (
              moduleData.line_no
            ) : (
              <CustomSelect
                name="line_no"
                value={moduleData.line_no}
                onChange={(e) => {
                  moduleData.line_no = e.target.value;
                  setRender((prev) => !prev);
                }}
              >
                <MenuItem value="">NONE</MenuItem>

                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((menu) => (
                  <MenuItem value={menu}>{menu}</MenuItem>
                ))}
              </CustomSelect>
            )}
          </th>
        </tr>
      )}
      <tr>
        <th colSpan={9} align="left">
          Shift Incharge:
          <TableInput
            isView={isView}
            name="shift_incharge"
            type="text"
            value={moduleData.shift_incharge}
            onChange={(e) => {
              moduleData.shift_incharge = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>

        <th colSpan={test ? 16 : 12} align="left">
          Quality Incharge:
          <TableInput
            isView={isView}
            name="quality_incharge_id"
            type="text"
            value={moduleData.quality_incharge_id}
            onChange={(e) => {
              moduleData.quality_incharge_id = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </thead>
  );
};

export default THead;
