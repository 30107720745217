import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { IData } from '../../helpers/interface';
import { useEffect, useState } from 'react';
import axios from 'axios';

interface IProps {
  moduleData: IData;
  isView: boolean;
  handelDeleteRows: any;
}

const TController = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [machineData, setMachine] = useState({});

  useEffect(() => {
    axios
      .get('/api/vendorRoute/vendor/forAutoComplete')
      .then((res) => {
        if (res.data) {
          setMachine(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            machineData,
            handelDeleteRows,
          }}
        />
        <TFoot {...{ moduleData, isView }} />
      </RenderTable>
    </Box>
  );
};

export default TController;
