import { HiDocumentReport } from 'react-icons/hi';
import { BsCardChecklist, BsFuelPumpDieselFill } from 'react-icons/bs';
import { SiTestcafe } from 'react-icons/si';
import { MdAir } from 'react-icons/md';
import { MdRoomPreferences } from 'react-icons/md';

import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { AiOutlineLineChart } from 'react-icons/ai';
import { CgPlayListSearch } from 'react-icons/cg';
import { FaCloudUploadAlt } from 'react-icons/fa';

const tiles = [
  {
    sNo: 7,
    link: `/common/listOfProcedures/?documentType=qualityDepartmentWiUpload&heading=WI UPLOAD`,
    isLink: true,
    icon: <FaCloudUploadAlt size="5rem" color="#003566" />,
    p: 'WI',
    span: 'UPLOAD',
    isIcon: true,
  },
  {
    sNo: 21,
    link: `/common/qualityDepartmentUpload/?documentType=ovenLieca&heading=OVEN- LIECA`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'OVEN -',
    span: 'LIECA',
    isIcon: true,
  },
  {
    sNo: 26,
    link: '/common/milliporeList',
    isLink: true,
    icon: <SiTestcafe size="5rem" color="#27292c" />,
    p: 'MILLIPORE',
    span: 'TEST',
    isIcon: true,
  },
  {
    sNo: 27,
    link: '/common/milliporeRunChart',
    isLink: true,
    icon: <AiOutlineLineChart size="5rem" color="#27292c" />,
    p: 'MILLIPORE RUN',
    span: 'CHART',
    isIcon: true,
  },
  {
    sNo: 24,
    link: '/common/qualitydepartment/titrationCheckSheet',
    isLink: true,
    icon: <BsCardChecklist size="5rem" color="#003566" />,
    p: 'Titration & Flushing',
    span: 'CHECK SHEET',
    isIcon: true,
  },
  {
    sNo: 24,
    link: '/common/qualitydepartment/leicaRoomCheckSheet',
    isLink: true,
    icon: <MdRoomPreferences size="5rem" color="#003566" />,
    p: 'LEICA ROOM',
    span: 'CHECK SHEET',
    isIcon: true,
  },
  {
    sNo: 25,
    link: '/common/airbornList',
    isLink: true,
    icon: <MdAir size="5rem" color="#2b5da7" />,
    p: 'Airborne & White Patch ',
    span: 'Wipe TEST',
    isIcon: true,
  },
];

const LeicaHome = () => {
  return (
    <>
      <ChildHeader text="LEICA LAB" />
      <RenderTiles tilesObj={tiles} />
    </>
  );
};

export default LeicaHome;
