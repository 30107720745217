import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';

import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../../components/common/FormControlMui';
import useConfirm from '../../../../components/common/useConfirm';
import CustomUploadButton from '../../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../../components/common/Loader';
import { useQuery } from '../../../../hooks/UseQuery';
import ModalCustom from '../../../../components/common/Modal';
import PickPart from '../../../../components/ui/PickPart';
import { Grid } from '@mui/material';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
interface Iprop {
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
}

interface IData {
  _id?: string;
  part_id?: any;
  partNumber?: string | null;
  customerName?: string | null;
  date?: Date;
  description?: string;
  createdAt?: Date;
}
const CreateDrawing = ({ setIsOpenPopup, id, setId }: Iprop) => {
  const documentType = useQuery('documentType') || '';
  const [data, setData] = useState<IData>({});
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState(null);
  const [isPartModal, setIsPartModal] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'SUBMIT DOCUMENT',
    `Are you sure you want to ${id ? 'update' : 'save'} this document?`
  );
  const [render, setRender] = useState(false);
  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/qualityAlert/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setData((prev) => prev);
    }
  }, [id]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) return;
    let formData = new FormData();
    if (!['qualityAlertModule'].includes(documentType)) {
      alert('Document type is invalid');
      return;
    }
    setIsLoading(true);
    if (documentType) formData.append('document_type', documentType);
    if (typeof data.part_id === 'string')
      formData.append('part_id', data.part_id);
    if (data.date) formData.append('date', data.date.toString());
    if (data.description) formData.append('description', data.description);
    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }
    if (id) {
      await axios
        .put(`/api/qualityAlert/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsOpenPopup(false);
          setId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      await axios
        .post('/api/qualityAlert', formData)
        .then((res) => {
          setIsLoading(false);
          setIsOpenPopup(false);
          setId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <Box>
        <FormControlMui handleSubmit={onSubmit} style={{ height: '200px' }}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton
              label={`${id ? 'update' : 'submit'}`}
              type="submit"
              disabled={isLoading}
            />
          </div>
          <StyledField>
            <Grid
              container
              xs={12}
              direction="row"
              alignItems={'center'}
              sx={{ border: '1px solid black' }}
            >
              <Grid item xs={10}>
                <p
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  {typeof data?.part_id === 'object' && data?.part_id !== null
                    ? data.part_id?.part_number
                    : data?.partNumber || ''}
                  {!data?.part_id && 'Pick Part No.'}
                </p>
              </Grid>

              {data.part_id ? (
                <Grid item xs={2}>
                  <RemoveIconButton
                    tooltipTitle="remove part"
                    onClick={() => {
                      data.part_id = null;
                      data.partNumber = null;
                      data.customerName = null;
                      setRender((prev) => !prev);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <AddIconButton
                    tooltipTitle="add part"
                    onClick={() => {
                      setIsPartModal(true);
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <InputMuiCustom
              type="text"
              name="customer_name"
              disabled={true}
              placeholder="Customer"
              onChange={handlePartData}
              value={
                data?.customerName || data.part_id?.customer_id?.customer_name
              }
            />
            <DatePickerMui
              label="Date"
              onChange={(date) => {
                data.date = date;
                setRender((prev) => !prev);
              }}
              value={moment(data.date || null)}
            />
            <InputMuiCustom
              type="text"
              name="description"
              placeholder="Description"
              onChange={handlePartData}
              value={data?.description || ''}
            />
            <CustomUploadButton
              lable={'UPLOAD DOCUMENT'}
              multiple
              accept="image/png,image/jpeg,image/jpg,application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              onChange={(e) => setFiles(e.target.files)}
            />
          </StyledField>
        </FormControlMui>

        <ModalCustom
          title="Part List"
          openModal={isPartModal}
          closeModal={() => {
            setIsPartModal(false);
          }}
        >
          <PickPart moduleData={data} />
        </ModalCustom>
      </Box>
      <DialogSubmit />
    </>
  );
};

export default CreateDrawing;
