import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { MenuItem } from '@mui/material';

const Create = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [data, setData] = useState<any>({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${
      id
        ? 'Update Master List of Instrument MSA'
        : 'Create Master List of Instrument MSA'
    }`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [department, setDepartment] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterListOfInstrumentMsa/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => setDepartment(res.data.results))
      .catch((err) => console.log(err));
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterListOfInstrumentMsa/${id}`, data)
        .then((res) => {
          setIsSubmit(false);
          setListId(null);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/masterListOfInstrumentMsa', data)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId(null);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'visual_defects'}
        name={'visual_defects'}
        html_for={'visual_defects'}
        label_name={'VISUALLY DEFECTS'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.visual_defects || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'description'}
        name={'description'}
        html_for={'description'}
        label_name={'DESCRIPTION:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.description || ''}
        onChange={handleData}
      />
      <AutocompleteGridmui
        label={'Department Name'}
        id="Departmtn"
        option_name={'department_name'}
        value={data?.department_id || ''}
        arrayofObj={department}
        onChange={(e, value) => {
          data.department_id = value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'function'}
        name={'function'}
        html_for={'function'}
        label_name={'Function:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.function || ''}
        onChange={handleData}
        last_child={2}
      />
      <GridInputs
        id={'location'}
        name={'location'}
        html_for={'location'}
        label_name={'Location:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.location || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleData}
        last_child={2}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
