import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <th align="left" colSpan={3}>
          Auditor Name & Sign:{' '}
          <TableInput
            isView={isView}
            name="auditor_name_sign"
            type="text"
            value={moduleData.auditor_name_sign}
            onChange={(e: any) => {
              moduleData.auditor_name_sign = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left" colSpan={4}>
          Auditee Name & Sign:{' '}
          <TableInput
            isView={isView}
            name="auditee_name_sign"
            type="text"
            value={moduleData.auditee_name_sign}
            onChange={(e: any) => {
              moduleData.auditee_name_sign = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
