import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { TableHeading } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import { convToTime } from '../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TBody4 = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const test = useQuery('test') === 'true' ? true : false;
  return (
    <>
      <TableHeading colSpan={test ? 24 : 20} heading="Down time Status" />
      <tr style={{ textTransform: 'uppercase' }}>
        <th>S.NO</th>
        <th colSpan={5}>REASON OF DOWN TIME</th>
        <th colSpan={4}>TIME</th>
        <th colSpan={test ? 14 : 10}>Remarks</th>
      </tr>
      {moduleData.down_time_status.map((item: any, index: number) => (
        <tr>
          <td>{index + 1}</td>
          <td colSpan={5}>
            <TableInput
              isView={isView}
              name="reason_down_time"
              type="text"
              value={item.reason_down_time}
              onChange={(e) => {
                item.reason_down_time = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={4}>
            {isView ? (
              convToTime(item.time)
            ) : (
              <TimePicker
                value={moment(item.time || '')}
                onChange={(date) => {
                  item.time = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </td>
          {index === 0 && (
            <td colSpan={test ? 14 : 10} rowSpan={5}>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={item.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          )}
        </tr>
      ))}
    </>
  );
};

export default TBody4;
