import { Box } from '@mui/system';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
import { apiConstant, createConstant } from './constant';
import { GoSync } from 'react-icons/go';
import useConfirm from '../../../components/common/useConfirm';
import { Loader } from '../../../components/common/Loader';

interface MyData {
  moduleData: any;
  Sync: any;
  isLoading: boolean;
  error?: string | null;
  addRow: any;
  DialogDeleteRow: any;
  handleDeleteRows: any;
  tniTopic: any;
}

const Create = () => {
  const { id } = useParams();
  const { state } = useAuth();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?',
  );
  const {
    DialogDeleteRow,
    tniTopic,
    handleDeleteRows,
    moduleData,
    isLoading,
    error,
    addRow,
    Sync,
  }: MyData = useGetData(id);

  const onSubmit = async (isSubmitted: boolean) => {
    moduleData.isSubmitted = isSubmitted;
    moduleData.report_prepared_by = state.user?.name;

    const ans = await confirmSave();
    if (!ans) return;

    axios
      .put(`/api/${apiConstant.updatePlanByid}/${id}`, moduleData)
      .then((res) => alert('updated successfully '))
      .catch((err) => console.log(err));
  };

  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text={`${createConstant.childHeader}`}>
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#228be6',
                  color: '#e7f5ff',
                  mr: 2,
                }}
                icon={<GoSync />}
                size="large"
                disabled={!moduleData._id}
                onClick={() => addRow()}
              >
                ADD ROW
              </CustomButton>
              <SubmitButton label="Submit" onClick={() => onSubmit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController {...{ handleDeleteRows, moduleData, tniTopic, isView }} />
      <Sync isSubmitContent={false} />
      <DialogSave isSubmitContent={false} />
      <DialogDeleteRow isSubmitContent={false} />
    </Box>
  );
};

export default Create;
