import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import { initialState } from './helpers/initialState';
import lodash, { reject } from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useAuth } from '../../../services/auth/AuthProvider';
import { useQuery } from '../../../hooks/UseQuery';
import { processes } from './helpers/initialState';
import PartTable from './components/view/PartTable';
import ModalCustom from '../../../components/common/Modal';
import useConfirm from '../../../components/common/useConfirm';
import { RiFileExcel2Line } from 'react-icons/ri';
import moment from 'moment';

const View = () => {
  const [moduleData, setModuleData] = useState<IData>(
    lodash.cloneDeep(initialState),
  );
  const [render, setRender] = useState(false);
  const [partIdx, setPartIdx] = useState(0);
  const navigate = useNavigate();
  const { state } = useAuth();
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  // const [machineData, setMachineData] = useState([]);
  const [customerData, setCustomerData] = useState([]);

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?',
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?',
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?',
  );
  const setOperationByPartId = (partId: string) => {
    if (partId) {
      return new Promise((resolve, reject) => {
        axios
          .get(
            `/api/dailyRejectionMonitoringQtyDept/controlPlan/getOperations/${partId}`,
          )
          .then((res) => {
            if (res.data) {
              resolve(res.data);
            }
          })
          .catch((err) => reject(err));
      });
    }
  };
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/dailyRejectionMonitoringQtyDept/${id}`)
        .then(async (res) => {
          const data = res.data;
          if (data) {
            for (let process = 0; process <= data.processes.length; process++) {
              if (data.processes[process]?.part_id?._id) {
                try {
                  const operations = await setOperationByPartId(
                    data.processes[process]?.part_id?._id,
                  );
                  data.processes[process].operations = operations;
                } catch (error) {
                  console.log(error);
                }
              }
            }
            setModuleData(data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    if (typeof moduleData.processes[partIdx]?.part_id === 'string') {
      axios
        .get(
          `/api/dailyRejectionMonitoringQtyDept/controlPlan/getOperations/${moduleData.processes[partIdx]?.part_id}`,
        )
        .then((res) => {
          if (res.data) {
            const values: any = { ...moduleData };
            values.processes[partIdx].operations = res.data;
            setModuleData(values);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [moduleData.processes[partIdx]?.part_id]);

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomerData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSumbit = async (isSubmitted: boolean) => {
    const ans = await confirmSubmit();
    if (!ans) return;
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;
    if (id) {
      await axios
        .put(`/api/dailyRejectionMonitoringQtyDept/${id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/dailyRejectionMonitoringQtyDept', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };

  function excelDownload() {
    const data = document.getElementById('tableDownloadId')?.innerHTML;
    // console.log(data);
    axios
      .post(
        `/api/createExcelRoute/dailyRejectionMonitoringSheetExcelDownload`,
        { table: data },
        { responseType: 'blob' },
      )
      .then((response) => {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `DAILY REJECTION MONITORING SHEET  ${moment().format(
            'dddd, MMMM Do YYYY, h:mm:ss a',
          )}.xlsx`,
        );
        // document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  return (
    <>
      <Box>
        <ChildHeader text="Daily Rejection Monitoring Sheet">
          {isView && (
            <>
              <CustomButton
                color="primary"
                sx={{ margin: '10px' }}
                icon={<RiFileExcel2Line />}
                disabled={false}
                size="large"
                onClick={() => excelDownload()}
              >
                Excel Download
              </CustomButton>
            </>
          )}
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  moduleData.processes.push(lodash.cloneDeep(processes[0]));
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton>
              {state.user?.userType === state.IS.Supervisor && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => handleSumbit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => handleSumbit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          customerData,
          isView,
          handleSumbit,
          handelDeleteRows,
          isOpenPopup,
          setIsOpenPopup,
          setPartIdx,
        }}
      />

      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PartTable moduleData={moduleData.processes[partIdx]} />
      </ModalCustom>
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
      <DialogSubmit />
    </>
  );
};

export default View;
