import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  SaveButton,
  SubmitButton,
} from '../../../../components/common/button/CustomButtons';
import formatDate from '../../../../components/common/formatDate';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
import { moduleConstant } from './helpers/constant';
interface MyData {
  moduleData: any; // replace PdirType with the type of your `${moduleConstant.api}` data
  // isLoading: boolean;
  setModuleData: any;
  error?: string | null;
}
const Create = () => {
  const { moduleId } = useParams();
  const { state } = useAuth();
  const isView = useQuery('isView') === 'true' ? true : false;
  const navigate = useNavigate();
  const { moduleData, setModuleData }: MyData = useGetData(moduleId);

  const onSubmit = async (status: string) => {
    let response: any;
    moduleData.status = status;
    if (moduleData._id) {
      response = await axios
        .put(`/api/${moduleConstant.api}/${moduleData._id}`, moduleData)
        .catch((err) => console.log(err));
    } else {
      response = await axios
        .post(`/api/${moduleConstant.api}`, moduleData)
        .catch((err) => console.log(err));
    }
    if (moduleData._id) {
      alert('updated successfully ');
      navigate(-1);
      return;
    }
    alert('created successfully');
    navigate(-1);
  };

  const handelDeleteRows = async (process_idx: string, rowIdx: number) => {
    // if (!ans) return;
    let data = { ...moduleData };
    data[process_idx].splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <Box>
      <Box>
        <ChildHeader text="JOB CARD">
          {!isView && (
            <>
              <SaveButton
                label="Save & Continue"
                onClick={() => onSubmit('draft')}
              />
              <SubmitButton
                label="Send For Approval"
                onClick={() => onSubmit('pending')}
              />
            </>
          )}
        </ChildHeader>
      </Box>

      <TController {...{ moduleData, isView, handelDeleteRows }} />
    </Box>
  );
};

export default Create;
