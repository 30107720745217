import { Box } from '@mui/system';
import formatDate from '../../components/common/formatDate';
import { RenderTable, TableCompHead } from '../../components/ui';

const MilliporeGraphTable = ({ graphData }: { graphData: any }) => {
  return (
    <Box>
      <RenderTable>
        <tr>
          <th>CUSTOMER NAME</th>
          <th>PART NAME</th>
          <th>REPORT DATE</th>
          <th>WEIGHT OF CONTAMINANT</th>
          <th>SPECIFICATION</th>
        </tr>
        {graphData.map((data: any) => (
          <tr>
            <td>{data.part_id?.customer_id?.customer_name}</td>
            <td>{data.part_id?.part_name}</td>
            <td>{formatDate(data.created_date)}</td>
            <td>{data.weight_contaminats}</td>
            <td>{data.specificaton}</td>
          </tr>
        ))}
      </RenderTable>
    </Box>
  );
};

export default MilliporeGraphTable;
