import { SelectChangeEvent } from '@mui/material';

const handleResult = (spec: any) => {
  const isNotOk = spec[`colors`].find((item: any) => item === '#f7aeae');
  const isOk = spec[`colors`].find((item: any) => item === '#a4d1a4');
  spec.result = (isNotOk && 'ng') || (isOk && 'ok') || '';
};
export const handleChangeModuleData = (
  e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>,
  process: any,
  obsIndex?: any
) => {
  const { name, value } = e.target;
  if (name === 'remarks') {
    process[name] = value;
    return;
  }
  process['values'][obsIndex] = value;
  if (value === undefined || value === '' || value === null) {
    process['colors'][obsIndex] = null;

    return;
  }
  if (+value > 1000 || +value < 800) {
    process['colors'][obsIndex] = '#f7aeae';
  } else {
    process['colors'][obsIndex] = '#a4d1a4';
    console.log(process?.colors);
  }
  handleResult(process);
};
