import Admin from '../pages/adminHome/AdminHome';
import { clause4 } from './admin/clause4';
import { clause10 } from './admin/clause10';
import { clause6 } from './admin/clause6';
import { clause7 } from './admin/clause7';
import { clause8 } from './admin/clause8';
import { other } from './admin/other';
import { productions } from './admin/production';
import UnderConstructionPage from '../underconstruction-page';
import { Clause3Home } from '../pages/clause3';
interface routes {
  path: string;
  element: JSX.Element;
  errorElement?: string;
}

export const IndexAdmin: routes[] = [
  {
    path: 'home',
    element: <Admin />,
  },
  {
    path: '/admin/modules',
    element: <UnderConstructionPage />,
  },
  {
    path: 'clause3',
    element: <Clause3Home />,
  },
  ...productions,
  ...clause4,
  ...clause6,
  ...clause7,
  ...clause8,
  ...clause10,
  ...clause8,
  ...other,
];
