import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    poke_yoke_name: '',
    machine_name_and_location: '',
    method_of_verification: '',
    img: '',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  pokeYoke_date: moment(),
  month: '',
  processes: Array.from({ length: 15 }, () => ({
    ...lodash.cloneDeep(processes[0]),
  })),
};
