export const moduleConstant = {
  api: 'designationRoute',
  listRoute: '/common/designation',
  label: 'Designation',
  tilesPHeading: 'List Of',
  tilesSpanHeading: 'Designation',
};

export const moduleListHeader = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Designation', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

export const renderData = [null, 'designation'];
