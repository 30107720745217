interface IDocument {
  [type: string]: any;
}
interface IProps {
  handleChangeStatus: (
    e: React.ChangeEvent<HTMLSelectElement>,
    index: number,
  ) => void;
  document: IDocument;
  documentIndex: number;
}
const ApprovalSelect = ({
  handleChangeStatus,
  document,
  documentIndex,
}: IProps) => {
  return (
    <>
      <select
        style={{
          fontSize: '1.2rem',
          outline: 'none',
          border: 'none',
          padding: '5px',
          marginLeft: '0.5rem',
          width: '150px',
          textAlign: 'center',
          textTransform: 'capitalize',
          ...(document.status === 'draft' && {
            backgroundColor: '#FDD835',
          }),
          ...(document.status === 'pending' && {
            backgroundColor: '#FDD835',
          }),
          ...(document.status === 'approved' && {
            backgroundColor: '#2b8a3e',
            color: '#fff',
          }),
        }}
        name="status"
        value={document.status}
        onChange={(e) => handleChangeStatus(e, documentIndex)}
      >
        <option value="pending">PENDING</option>
        <option value="approved">APPROVED</option>
      </select>
    </>
  );
};

export default ApprovalSelect;
