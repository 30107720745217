import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
// import { IData } from './helpers/interface';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  // CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useAuth } from '../../../services/auth/AuthProvider';
import { useQuery } from '../../../hooks/UseQuery';
// import { processes } from './helpers/initialState';
// import PartTable from './components/view/PartTable';
// import ModalCustom from '../../../components/common/Modal';
import useConfirm from '../../../components/common/useConfirm';
import moment from 'moment';
import { cleaning_room_export } from './helpers/cleaningRoomExport';
import { assembly } from './helpers/assembly';
import { export_1 } from './helpers/exportTs';
import { cleaning_room } from './helpers/cleaningRoom';

const View = () => {
  const navigate = useNavigate();
  const { state } = useAuth();
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const checkSheetType = useQuery('checkSheetType');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );
  const initialState: any = {
    cleaning_room_export: cleaning_room_export,
    export: export_1,
    assembly: assembly,
    cleaning_room: cleaning_room,
  };

  const showHeading: any = {
    cleaning_room_export: 'Cleaning Room Export',
    assembly: 'Assembly',
    export: 'Export',
    cleaning_room: 'Cleaning Room',
  };
  const headerTitle: string = `PPE Verification Checksheet -  ${
    showHeading[checkSheetType as string]
  }`;
  const [moduleData, setModuleData] = useState<any>({
    isSubmitted: false,
    is_admin_request_no: null,
    is_admin_request: null,
    report_prepared_by: null,
    report_approved_by: null,
    checkSheetType: checkSheetType,
    checkSheet_date: moment(),
    [checkSheetType as string]: lodash.cloneDeep(
      initialState[checkSheetType as string]
    ),
  });

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/ppeChkShtRouteQtyDeptRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async (isSubmitted: boolean) => {
    const ans = await confirmSubmit();
    if (!ans) return;
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;
    if (id) {
      await axios
        .put(`/api/ppeChkShtRouteQtyDeptRoute/${id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/ppeChkShtRouteQtyDeptRoute', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  // const handelDeleteRows = async (rowIdx: number) => {
  //   const ans = await confirmDeleteRow();
  //   if (!ans) return;
  //   let data = { ...moduleData };
  //   data.processes.splice(rowIdx, 1);
  //   setModuleData(data);
  // };

  return (
    <>
      <Box>
        <ChildHeader text={headerTitle}>
          {!isView && (
            <>
              {/* <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  moduleData.processes.push(lodash.cloneDeep(processes[0]));
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton> */}
              {state.user?.userType === state.IS.Supervisor && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => handleSumbit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => handleSumbit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          // customerData,
          isView,
          handleSumbit,
          headerTitle,
          // handelDeleteRows,
          // isOpenPopup,
          // setIsOpenPopup,
          // setPartIdx,
        }}
      />

      <DialogSubmit />
    </>
  );
};

export default View;
