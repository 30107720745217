import formatDate from '../../../../../../components/common/formatDate';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
  moduleDate: Date;
}
const TFoot = ({ preparedBy, approvedBy, moduleDate }: Ifoot) => {
  return (
    <tfoot>
      <tr>
        <th colSpan={2} align="left">
          Date:{formatDate(moduleDate)}
        </th>
        <th colSpan={5} align="left">
          Inspector Signature: {approvedBy}
        </th>
        <th colSpan={6} align="left">
          Auth. Signature: {preparedBy}
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={2}>
          FM/XLV/QA/05A
        </th>
        <th align="left" colSpan={11}>
          Issue No:- 00
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={13}>
          Remarks : If one piece found rejected in any parameter then whole lot
          to be quarantined & segregated.
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
