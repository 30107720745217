export const cleaning_room = [
  {
    process_name: ' ULTRA SONIC CLEANING',
    applicable_ppe: [
      {
        name: 'HEAD CAP',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'GOGGLE',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'EARPLUG',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'MASK',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'Apron',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'Shoe Cover ',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
    // results: Array.from({ length: 31 }, () => ''),
    // remarks: '',
  },
  {
    process_name: ' FLUSHING',
    applicable_ppe: [
      {
        name: 'HEAD CAP',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'GOGGLE',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'EARPLUG',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'MASK',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'Apron',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'Shoe Cover ',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
    // results: Array.from({ length: 31 }, () => ''),
    // remarks: '',
  },
  {
    process_name: ' OVEN PROCESS',
    applicable_ppe: [
      {
        name: 'HEAD CAP',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'Apron',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'Shoe Cover ',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
    // results: Array.from({ length: 31 }, () => ''),
    // remarks: '',
  },
  {
    process_name: 'INSERTION (O-RING, CAP)',
    applicable_ppe: [
      {
        name: 'HEAD CAP',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'Apron',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'Shoe Cover ',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
    // results: Array.from({ length: 31 }, () => ''),
    // remarks: '',
  },
  {
    process_name: ' VISUAL INSPECTION',
    applicable_ppe: [
      {
        name: 'HEAD CAP',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'Apron',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'Shoe Cover ',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
    // results: Array.from({ length: 31 }, () => ''),
    // remarks: '',
  },
  {
    process_name: 'PRIMARY PACKING',
    applicable_ppe: [
      {
        name: 'HEAD CAP',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'Apron',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'Shoe Cover ',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
    // results: Array.from({ length: 31 }, () => ''),
    // remarks: '',
  },
];
