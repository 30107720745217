import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../components/common/selectscustom/SelectGridMui';
import { Checkbox, MenuItem } from '@mui/material';

const Create = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [data, setData] = useState<any>({ exclude_report: false });
  const [categoryData, setCategoryData] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer' : 'Create Customer'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [department, setDepartment] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/masterlistinstrument/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => setDepartment(res.data.results))
      .catch((err) => console.log(err));

    axios
      .get('/api/masterlistinstrument/instrumentcategory')
      .then((res) => {
        if (res.data.result) {
          setCategoryData(res.data.result);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/masterlistinstrument/${id}`, data)
        .then((res) => {
          setIsSubmit(false);
          setListId(null);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/masterlistinstrument', data)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId(null);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };
  console.log(data);
  return (
    <>
      <AutocompleteGridmui
        label={'INSTRUMENT TYPE'}
        id={'name'}
        option_name={'name'}
        value={data?.category_id || ''}
        arrayofObj={categoryData}
        onChange={(e, value) => {
          data.category_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      />
      <GridInputs
        id={'description'}
        name={'description'}
        html_for={'description'}
        label_name={'DESCRIPTION:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.description || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'type_of_industry'}
        name={'type_of_industry'}
        html_for={'type_of_industry'}
        label_name={'Type Of Industry:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.type_of_industry || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'id_no'}
        name={'id_no'}
        html_for={'id_no'}
        label_name={'UNIQUE ID NO.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.id_no || ''}
        onChange={handleData}
      />
      <AutocompleteGridmui
        label={'Department Name'}
        id="Departmtn"
        option_name={'department_name'}
        value={data?.department_id || ''}
        arrayofObj={department}
        onChange={(e, value) => {
          data.department_id = value;
          setRender((prev) => !prev);
        }}
      />
      <GridInputs
        id={'range'}
        name={'range'}
        html_for={'range'}
        label_name={'RANGE'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.range || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'least_count'}
        name={'least_count'}
        html_for={'least_count'}
        label_name={'LEAST COUNT:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.least_count || ''}
        onChange={handleData}
      />

      <SelectGridMui
        name="frequency_cycle"
        value={data?.frequency_cycle || ''}
        lable={'CALIBRATION FREQ.:'}
        onChange={(e: any) => handleData(e)}
      >
        <MenuItem value="">
          <em>NONE</em>
        </MenuItem>
        <MenuItem value={1}>1 Month</MenuItem>
        <MenuItem value={3}>3 Month</MenuItem>
        <MenuItem value={6}>6 Month</MenuItem>
        <MenuItem value={12}>12 Month</MenuItem>
      </SelectGridMui>

      <GridInputs
        id={'function'}
        name={'function'}
        html_for={'function'}
        label_name={'Function:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.function || ''}
        onChange={handleData}
        last_child={2}
      />

      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleData}
        last_child={2}
      />
      <div>
        <Checkbox
          name="exclude_report"
          checked={data?.exclude_report}
          onChange={(e: any) => {
            setData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
