import React from 'react';
import { GrDocumentText } from 'react-icons/gr';
import { ImStatsDots } from 'react-icons/im';
import { MdSmsFailed } from 'react-icons/md';
import { AiOutlinePartition } from 'react-icons/ai';
import { RenderTiles } from '../../components/common';
import { useQuery } from '../../hooks/UseQuery';
import { useParams } from 'react-router-dom';
import ChildHeader from '../../components/ui/ChildHeader';
import PartDetailsHeader from '../../components/ui/PartDeatailsHeader';
import { TbReport } from 'react-icons/tb';

const TilesPpap = () => {
  const { partId } = useParams();
  const partName = useQuery('part_name');
  const partNumber = useQuery('part_number');
  const customer_name = useQuery('customer_name');
  const ppapTilesObj = [
    // {
    //   sNo: 1,
    //   link: `https://iai2.aqua.mushinlabs.com/revisedTable/pfd/645d15be0ce317f5b71afa36?partNumber=Pipe%20Assy%20Front&partName=RY63-7W093-AA`,
    //   isLink: true,
    //   clauseNo: 4,
    //   p: 'PROCESS FLOW',
    //   span: 'DIAGRAM',
    //   isIcon: true,
    //   icon: <AiOutlinePartition size="5rem" color="#c80202" />,
    // },
    // {
    //   sNo: 1,
    //   link: `https://iai2.aqua.mushinlabs.com/revisedTable/fmea/645d15be0ce317f5b71afa36?partNumber=Pipe%20Assy%20Front&partName=RY63-7W093-AA`,
    //   isLink: true,
    //   clauseNo: 4,
    //   p: 'FMEA',
    //   span: 'FORMATS',
    //   isIcon: true,
    //   icon: <MdSmsFailed size="5rem" color="#c80202" />,
    // },
    {
      sNo: 1,
      link: `/admin/revisionTable/controlplan/${partId}?part_name=${partName}&part_number=${partNumber}&customer_name=${customer_name}`,
      isLink: true,
      clauseNo: 4,
      p: 'CONTROL',
      span: 'PLAN',
      isIcon: true,
      icon: <GrDocumentText size="5rem" color="#c80202" />,
    },
    // {
    //   sNo: 2,
    //   link: `/admin/spc/${partId}?part_name=${partName}&part_number=${partNumber}`,
    //   isLink: true,
    //   clauseNo: 4,
    //   p: 'SPC',
    //   span: 'REPORTS',
    //   isIcon: true,
    //   icon: <ImStatsDots size="5rem" />,
    // },
    {
      sNo: 2,
      link: `/admin/pcd/${partId}?part_name=${partName}&part_number=${partNumber}`,
      isLink: true,
      clauseNo: 4,
      p: 'Process Control',
      span: 'Drawing',
      isIcon: true,
      icon: <AiOutlinePartition size="5rem" color="#c80202" />,
    },
    {
      sNo: 3,
      link: `/common/drawingManagement/${partId}?part_name=${partName}&part_number=${partNumber}`,
      isLink: true,
      clauseNo: 4,
      p: 'DRAWING',
      span: 'Management',
      isIcon: true,
      icon: <AiOutlinePartition size="5rem" color="#c80202" />,
    },
  ];
  return (
    <>
      <ChildHeader text="DOCUMENT LIST" />
      <PartDetailsHeader
        partName={partName || ''}
        partNumber={partNumber || ''}
      />
      <RenderTiles height="100vh" tilesObj={ppapTilesObj} />;
    </>
  );
};

export default TilesPpap;
