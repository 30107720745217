interface Ispec {
  [key: string | number | `${string}_rowSpan` | `${string}_include`]: any;
}
interface IRowSpan {
  [key: string]: number | boolean;
}
interface IcpParams {
  [key: string]: string;
}

interface IProcess {
  [key: string]: [] | Ispec;
}

interface IcontrolPlanData {
  processes: IProcess[];
}

export const mergerRowsControlPlan = (controlPlanData: IcontrolPlanData) => {
  const cpParams = [
    { param: 'machine' },
    { param: 'char_product' },
    { param: 'char_process' },
    { param: 'methods_size' },
    { param: 'methods_by' },
    { param: 'recording' },
    { param: 'error_proofing' },
    { param: 'responsibility' },
    { param: 'methods_process' },
    { param: 'reaction_plan' },
    { param: 'corrective_action' },
    { param: 'process_capability' },
  ];

  for (let [processIndex, process] of controlPlanData.processes.entries()) {
    let prevRowValue: {
      [key: string]: any;
    } = {};

    cpParams.forEach((item: IcpParams) => (prevRowValue[item.param] = null));

    let idxOfParmas: { [key: string]: number } = {};

    // Check whether process.specs is an array and whether it has an entries method
    if (Array.isArray(process.specs) && process.specs.entries) {
      for (let [cpIndex, spec] of process.specs.entries()) {
        cpParams.forEach((item: IcpParams, index) => {
          if (Array.isArray(spec[item.param])) {
            spec[item.param] = spec[item.param][0];
          }
          if (
            prevRowValue[item.param] === spec[item.param] &&
            prevRowValue[item.param] !== null &&
            prevRowValue[item.param] !== undefined
          ) {
            controlPlanData.processes[processIndex].specs[
              idxOfParmas[item.param]
            ][`${item.param}_rowSpan`] += 1;
            spec[`${item.param}_include`] = false;
          } else {
            idxOfParmas[item.param] = cpIndex;
            spec[`${item.param}_rowSpan`] = 1;
            spec[`${item.param}_include`] = true;
            prevRowValue[item.param] = spec[item.param] || '';
          }
        });
      }
    }
  }
  return controlPlanData;
};
