import axios from 'axios';
import { useState, useEffect } from 'react';

const useGetData = (
  partId: string | undefined,
  setupId: string | undefined
) => {
  const [moduleData, setModuleData] = useState<any>({
    setup_date: new Date(),
    setups: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [machineData, setMachineData] = useState([]);
  const [autoCompleteMachineValue, setAutoCompleteMachineValue] = useState({});
  useEffect(() => {
    axios
      .get('/api/machineRoutes/')
      .then((res) => {
        setMachineData(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setIsLoading(true);
    if (setupId) {
      axios
        .get(`/api/setup/${setupId}`)
        .then((res) => {
          setModuleData(res.data);
          setAutoCompleteMachineValue(res.data.machine_id);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      axios
        .get(`/api/setup/getProcessFromControlPlan/${partId}`)
        .then((res) => {
          const response = res.data;
          setModuleData((item: any) => {
            item.part_id = partId;
            item.part_name = response.part_id?.part_name;
            item.part_number = response.part_id?.part_number;
            item.customer_id = response.part_id?.customer_id;
            item.customer_name = response.part_id?.customer_id?.customer_name;
            // for temp basic
            item.revision_no = response.revision_no;
            item.revision_date = response.revision_date;
            item.setup_date = new Date();
            item.setups = response.processes.map((process: any) => {
              return {
                process_name: process.process_name,
                times: Array.from(Array(5)),
                setup_list: process.specs.map((spec: any) => ({
                  parameter: spec.char_product || spec.char_process,
                  check4_symbol: spec.check4_symbol,
                  checkbox4_num: spec.checkbox4_num,
                  specification: spec.control_product,
                  methods: spec.methods_measure,
                  appearance: spec.appearance,
                  mean: spec.mean,
                  utolerance: spec.utolerance,
                  ltolerance: spec.ltolerance,
                  utl: spec.utl,
                  ltl: spec.ltl,
                  check_0_colors: Array.from(Array(5)),
                  check_0_results: Array.from(Array(5)),
                  check_0_values: Array.from(Array(5)),
                })),
              };
            });
            return { ...item };
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
  }, []);
  return {
    moduleData,
    isLoading,
    error,
    machineData,
    autoCompleteMachineValue,
    setAutoCompleteMachineValue,
  };
};

export default useGetData;
