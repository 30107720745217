// import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
// import { TableInput } from '../../../../components/common';
// import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
// import { convToTime } from '../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'DATE',
  'PART NO.',
  'CUSTOMER',
  'CHECKED QTY.',
  'OK QTY.',
  'SHIFT',
  'STAGE',
  // 'REASON',
  // 'REJECTED QTY',
  'REJECTIONS',
  'REMARKS',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={11}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="Rejection Note"
      />
      <tr>
        <th colSpan={2} align="left">
          Date:{' '}
        </th>
        <th colSpan={9} align="left">
          {isView ? (
            formatDate(moduleData.rejectionNote_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.rejectionNote_date)}
              onChange={(date) => {
                moduleData.rejectionNote_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={heading === 'REMARKS' ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
