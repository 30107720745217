import { HiDocumentReport } from 'react-icons/hi';
import { TbReport } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import { RenderTiles } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';

const SupplierDocumentHome = () => {
  const { vendorId } = useParams();
  const tiles = [
    {
      sNo: 1,
      link: `/common/supplierDocument/${vendorId}?documentType=supplierSpcPlanList`,
      isLink: true,
      icon: <HiDocumentReport size="5rem" color="#003566" />,
      p: 'SUPPLIER SPC',
      span: 'PLAN',
      isIcon: true,
    },
    {
      sNo: 2,
      link: `/common/supplierDocument/${vendorId}?documentType=supplierPpapDocument`,
      isLink: true,
      icon: <HiDocumentReport size="5rem" color="#003566" />,
      p: 'SUPPLIER PPAP',
      span: 'DOCUMENT',
      isIcon: true,
    },
  ];
  return (
    <>
      <ChildHeader text="SUPPLIER DOCUMENTS" />
      <RenderTiles height="100vh" tilesObj={tiles} />
    </>
  );
};

export default SupplierDocumentHome;
