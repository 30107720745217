import React, { useEffect, useState } from 'react';
import TableCustom from '../../../components/common/customTable/TableCustom';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import { SearchBar } from '../../../components/common/SearchBar';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import ChildHeader from '../../../components/ui/ChildHeader';
import axios from 'axios';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import { useQuery } from '../../../hooks/UseQuery';
import { useNavigate } from 'react-router-dom';
import { CustomPagination } from '../../../components/common';
import FilterBy from '../../../components/common/FilterBy';
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Item Number', rowspan: 1, colspan: 1 },
    { text: 'Item Description', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'part_number', name: 'A-Z' },
  { value: 'createdAt', name: 'Most Recent First' },
  { value: 'createdAt_old', name: 'Oldest First' },
];

const SelectNpdPart = ({ link }: { link: string }) => {
  const navigate = useNavigate();
  const [data, setData] = useState<any[]>([]);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search');
  const page = useQuery('page') || 1;
  const filter = useQuery('filter');

  const fetchData = async () => {
    const res: any = await axios
      .get(
        `/api/npdPartRoute/getPublishPart/ForTimePlanAndMatrix?page=${page}&sortBy=${
          filter || 'part_number'
        }&search=${search}&perPage=${10}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 1000, [page, search]);
  return (
    <div>
      <ChildHeader text="PART LIST"></ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search Part" />
        <FilterBy label="SortBy " options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell align="center" width={10}>
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.part_number}</TableCell>
                  <TableCell align="center">{item?.part_name}</TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      {/* <ViewIconButton */}
                      {/*   tooltipTitle="VIEW" */}
                      {/*   onClick={() => { */}
                      {/*     navigate( */}
                      {/*       `${link}/${item?._id}?partName=${item.part_name}&partNumber=${item.part_number}&isView=true`, */}
                      {/*     ); */}
                      {/*   }} */}
                      {/* /> */}
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          navigate(
                            `${link}/${item?._id}?partName=${item.part_name}&partNumber=${item.part_number}&isView=false`,
                          );
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
    </div>
  );
};

export default SelectNpdPart;
