import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
}
const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData.list &&
        moduleData.list.map((item: any, index: number) => (
          <tr key={`${index}_row`}>
            <td>{index + 1}</td>
            <td>
              {isView ? (
                formatDate(item.date)
              ) : (
                <DatePickerMui
                  value={moment(item.date)}
                  onChange={(date) => {
                    item.date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td>
              <TableInput
                isView={true}
                name="qty"
                type="number"
                value={item.qty}
              />
              {}
            </td>
            <td>
              <TableInput
                isView={true}
                name="comulative"
                type="text"
                value={item.comulative}
              />
            </td>
            <td
              style={{
                ...(item.status === 'RED (R)' && { background: '#e03131' }),
              }}
            >
              {item.status}
              {/* {isView ? (
                item.status
              ) : (
                <CustomSelect
                  name="status"
                  value={item.status}
                  sx={{
                    background: `
                      ${
                        (item.status === 'GREEN (G)' && '#40c057') ||
                        (item.status === 'YELLOW (Y)' && '#ffe066') ||
                        (item.status === 'RED (R)' && '#e03131')
                      }
                    `,
                  }}
                  onChange={(e) => {
                    item.status = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">NONE</MenuItem>
                  <MenuItem value="GREEN (G)">GREEN (G)</MenuItem>
                  <MenuItem value="YELLOW (Y)">YELLOW (Y)</MenuItem>
                  <MenuItem value="RED (R)">RED (R)</MenuItem>
                </CustomSelect>
              )} */}
            </td>
            <td colSpan={2}>
              <TableInput
                isView={isView}
                name="reason"
                type="text"
                value={item.reason}
                onChange={(e) => {
                  item.reason = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            {/* {!isView && (
              <td style={{ width: '5px', textAlign: 'center' }}>
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handelDeleteRows(index)}
                />
              </td>
            )} */}
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
