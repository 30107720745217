interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ preparedBy, approvedBy }: Ifoot) => {
  return (
    <tfoot>
      <tr>
        <th align="left" colSpan={9}>
          Specification:
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={9}>
          White Patch Wipe Test:
        </th>
      </tr>
      <tr>
        <td style={{ textAlign: 'left' }} colSpan={9}>
          1.The patch used to wipe the surface should be a standard 47 mm
          Millipore patch,
        </td>
      </tr>
      <tr>
        <td style={{ textAlign: 'left' }} colSpan={9}>
          2.The patch should be placed squarely on the surface to be audited and
          wiped across the surface for 2 inches
        </td>
      </tr>
      <tr>
        <td style={{ textAlign: 'left' }} colSpan={9}>
          3. The white patch should be audited per CES 16599test method I and
          achieve class V
        </td>
      </tr>
      <tr>
        <td style={{ textAlign: 'left' }} colSpan={9}>
          4.Maximum debris for any size part =4.0mg
        </td>
      </tr>
      <tr>
        <th align="left" colSpan={9}>
          Airborne debrus Test
        </th>
      </tr>
      <tr>
        <td style={{ textAlign: 'left' }} colSpan={9}>
          1.Standard 47 mm Millipore patch
        </td>
      </tr>
      <tr>
        <td style={{ textAlign: 'left' }} colSpan={9}>
          2.airborne particles just as the parts would be for a period of 24
          hours
        </td>
      </tr>
      <tr>
        <td style={{ textAlign: 'left' }} colSpan={9}>
          3.patch should be evaluted to CES16599 method -1 & achieve class V
        </td>
      </tr>
      <tr>
        <td style={{ textAlign: 'left' }} colSpan={9}>
          4.Maximum debris for any size part = 4.0mg
        </td>
      </tr>
      <tr>
        <th align="left" colSpan={9}>
          Frequency: Once A Week on Every Monday
        </th>
      </tr>
      <tr>
        <th colSpan={5} align="left">
          Prepared By: {preparedBy}
        </th>
        <th colSpan={6} align="left">
          Approved By: {approvedBy}
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
