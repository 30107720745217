import React, { useState } from 'react';
import TBodyRow from './TBodyRow';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  // customerData: any;
  isView: boolean;
  // handelDeleteRows: any;
  // setIsOpenPopup: any;
  // setPartIdx: any;
}

const TBody = ({
  moduleData,
  // customerData,
  isView,
}: // handelDeleteRows,
// setIsOpenPopup,
// setPartIdx,
IProps) => {
  const [render, setRender] = useState(false);
  const checkSheetType = useQuery('checkSheetType');
  return (
    <>
      <tbody>
        {moduleData &&
          checkSheetType &&
          moduleData?.[checkSheetType]?.map((process: any, index: number) => (
            <React.Fragment key={index + 1 + 'process'}>
              {process?.applicable_ppe?.map((item: any, idx: number) => (
                <TBodyRow
                  {...{
                    moduleData,
                    process,
                    index,
                    item,
                    idx,
                    isView,
                    // handelDeleteRows,
                    // setIsOpenPopup,
                    // setPartIdx,
                  }}
                />
              ))}
            </React.Fragment>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
