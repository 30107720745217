import { useState } from 'react';
import TBodyRight from './TBodyRight';
import { DeleteIconButton } from '../../../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../../../components/common/AutocompleteMuiCustom';
import { TableInput } from '../../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleDeleteRows: any;
  employeeData: any;
  tniTopic: any;
}

const StyledCircle = ({ skillLevel }: { skillLevel: any }) => {
  if (skillLevel === 'Level 1') {
    return (
      <td
        align="center"
        style={{
          fontSize: '3rem',
        }}
      >
        ◔
      </td>
    );
  }
  if (skillLevel === 'Level 2') {
    return (
      <td
        align="center"
        style={{
          fontSize: '2rem',
        }}
      >
        ◑
      </td>
    );
  }
  if (skillLevel === 'Level 3') {
    return (
      <td
        align="center"
        style={{
          fontSize: '3rem',
        }}
      >
        ◕
      </td>
    );
  }
  if (skillLevel === 'Level 4') {
    return (
      <td
        align="center"
        style={{
          fontSize: '4rem',
        }}
      >
        ●
      </td>
    );
  }
  return <td></td>;
};
const TBody = ({
  moduleData,
  isView,
  handleDeleteRows,
  employeeData,
  tniTopic,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        {moduleData?.list?.map((item: any, index: number) => (
          <>
            <tr key={`${index}_table_row`}>
              <td>{index + 1}</td>
              <td>
                {isView ? (
                  item?.employee_id?.employee_name
                ) : (
                  <AutocompleteMuiCustom
                    id="Customer"
                    label=""
                    option_name="employee_name"
                    arrayofObj={employeeData}
                    value={item.employee_temp || item.employee_id || ''}
                    onChange={(e, value) => {
                      item.employee_id = value?._id;
                      item.employee_temp = value;
                      setRender((prev) => !prev);
                    }}
                  />
                )}
              </td>
              <td>
                {item.employee_temp?.area_of_work?.department_name ||
                  item.employee_id?.area_of_work?.department_name}
              </td>
              <StyledCircle
                skillLevel={
                  item.employee_temp?.skill_level ||
                  item.employee_id?.skill_level
                }
              />
              <td></td>
              <TBodyRight item={item} tniTopic={tniTopic} isView={isView} />
              <td>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={item.remarks}
                  onChange={(e) => {
                    item.remarks = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                {!isView && (
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handleDeleteRows(index)}
                  />
                )}
              </td>
            </tr>
          </>
        ))}
      </tbody>
      <tr>
        <th>Prepared By:</th>
        <th align="left" colSpan={6 + tniTopic.length}>
          <TableInput
            name="prepapred_by"
            type="text"
            value={moduleData.prepapred_by}
            onChange={(e) => {
              moduleData.prepapred_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th>Approved By:</th>
        <th align="left" colSpan={6 + tniTopic.length}>
          <TableInput
            name="approved_by"
            type="text"
            value={moduleData.approved_by}
            onChange={(e) => {
              moduleData.approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
    </>
  );
};

export default TBody;
