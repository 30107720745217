import { useState } from 'react';
import { TableInput, TextAreaInput } from '../../../../../../components/common';
import { CustomButton } from '../../../../../../components/common/button/CustomButtons';
import { DeleteIconButton } from '../../../../../../components/common/button/CustomIconButton';
interface IProps {
  moduleData: any;
  handelDeleteRows: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      <tr style={{ background: '#fff2cc' }}>
        <th colSpan={1} align="left">
          <TableInput
            isView={isView}
            name="body0_head_1"
            type="text"
            style={{
              fontWeight: '600',
              background: '#fff2cc',
            }}
            value={moduleData.body0_head_1}
            onChange={(e) => {
              moduleData.body0_head_1 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={4} align="left">
          <TableInput
            isView={isView}
            name="body0_head_2"
            type="text"
            value={moduleData.body0_head_2}
            style={{
              fontWeight: '600',
              background: '#fff2cc',
            }}
            onChange={(e) => {
              moduleData.body0_head_2 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th>
          {!isView && (
            <CustomButton
              sx={{
                backgroundColor: '#1c7ed6',
              }}
              onClick={async () => {
                // const ans = await confirmAddRow();
                // if (!ans) return;
                moduleData.process_1.push({});
                setRender((prev) => !prev);
              }}
            >
              ADD ROWS
            </CustomButton>
          )}
        </th>
      </tr>
      {moduleData?.process_1 &&
        moduleData.process_1.map((item: any, index: number) => (
          <tr key={index + 'specs'}>
            <td>{index + 1}</td>
            <td colSpan={4}>
              <TextAreaInput
                isView={isView}
                name="responsibility"
                value={item.responsibility}
                onChange={(e: any) => {
                  item.responsibility = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handelDeleteRows('process_1', index)}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
