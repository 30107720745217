import { TbReport } from 'react-icons/tb';
import { VscServerProcess } from 'react-icons/vsc';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { useAuth } from '../../services/auth/AuthProvider';
import { GiHarborDock } from 'react-icons/gi';

const PdiDepartmentHome = () => {
  const { state } = useAuth();
  const { user } = state;
  const tiles = [
    {
      sNo: 4,
      link: `/common/PdiStandardList`,
      isLink: true,
      icon: <TbReport size="5rem" color="#a12222" />,
      p: 'PDI',
      span: 'STANDARD',
      isIcon: true,
    },

  {
    sNo: 20,
    link: '/common/dockAuditPlanTiles',
    isLink: true,
    clauseNo: 4,
    icon: <GiHarborDock size="5rem" color="#495057" />,
    p: 'Dock Audit',
    span: 'Plan',
    isIcon: true,
  },
    {
      sNo: 5,
      link: `/${user?.userType === 2 ? 'admin' : 'supervisor'}/pdirTiles`,
      isLink: true,
      icon: <VscServerProcess size="5rem" color="#135089" />,
      p: 'PDIR',
      span: 'Report',
      isIcon: true,
    },
  ];
  return (
    <>
      <ChildHeader text="PDI DEPARTMENT" />
      <RenderTiles height="100vh" tilesObj={tiles} justify="start" />
    </>
  );
};

export default PdiDepartmentHome;
