export const flushing_machine = [
  {
    spec: ' Machine',
    method: 'Visual',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    spec: '2%-5%',
    method: 'As Per WI',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
  {
    spec: 'Is area Clean',
    method: 'Visual',
    results: Array.from({ length: 31 }, () => ''),
    remarks: '',
  },
];
