import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={12}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="COMPETENCE MATRIX"
      />
      <tr>
        <th colSpan={2}>DATE:</th>
        <th colSpan={10} align="left">
          {isView ? (
            formatDate(moduleData.date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.date)}
              onChange={(date) => {
                moduleData.date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={2} rowSpan={3}>
          Man Profile
        </th>
        <th colSpan={3} align="left">
          NAME : {moduleData?.employee_name}
        </th>
        <th colSpan={4} align="left">
          Department: {moduleData?.area_of_work?.department_name}
        </th>
        <th colSpan={3}>
          Designation : {moduleData?.designation_id?.designation}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Required Experience :{' '}
          <TableInput
            isView={isView}
            name="required_exp"
            type="text"
            value={moduleData?.matrix?.required_exp}
            onChange={(e) => {
              moduleData.matrix.required_exp = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={4} align="left">
          Required Qualification :
          <TableInput
            isView={isView}
            name="required_qualification"
            type="text"
            value={moduleData?.matrix?.required_qualification}
            onChange={(e) => {
              moduleData.matrix.required_qualification = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={3} rowSpan={2} align="left">
          Age :
          <TableInput
            isView={isView}
            name="age"
            type="text"
            value={moduleData?.matrix?.age}
            onChange={(e) => {
              moduleData.matrix.age = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Experience: {moduleData?.exp_years}
        </th>
        <th colSpan={4} align="left">
          Actual Qualification: {moduleData?.qualification}
        </th>
      </tr>

      <tr>
        <th colSpan={2}>Role:</th>
        <th colSpan={10}>
          Accountable for minimal Rejection & responsible for resolving customer
          complaints & warranty analysis.{' '}
        </th>
      </tr>
    </thead>
  );
};

export default THead;
