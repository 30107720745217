const headFirst = [
  {
    name: 'Job Card No',
    type: 'text',
    param: 'job_card_no',
    colSpan: 3,
  },
  {
    name: 'Ref No.',
    param: 'ref_no',
    type: 'text',
    colSpan: 3,
  },

  {
    name: 'Area Code',
    param: 'area_code',
    type: 'text',
    colSpan: 3,
  },
  {
    name: 'Lot Qty',
    param: 'lot_qty',
    type: 'text',
    colSpan: 3,
  },
  {
    name: 'Prod Group',
    param: 'prod_group',
    type: 'text',
    colSpan: 2,
  },
];
const headSecond = [
  {
    name: 'Item Code',
    type: 'text',
    param: 'item_code',
  },
  {
    name: 'Rev No.',
    param: 'job_rev_no',
    type: 'text',
  },
  {
    name: 'Description',
    param: 'description',
    type: 'text',
  },
  {
    name: 'Store Code',
    param: 'store_code',
    type: 'text',
  },
  {
    name: 'Bin No.',
    param: 'bin_no',
    type: 'text',
  },
  {
    name: 'Quantity',
    param: 'quantity',
    type: 'text',
  },
  {
    name: 'UOM',
    param: 'uom',
    type: 'text',
  },
  {
    name: 'Imp. No',
    param: 'imp_no',
    type: 'text',
  },
];

const headThird = [
  {
    name: 'MRM No.',
    type: 'text',
    param: 'mrm_no',
    colSpan: 3,
  },
  {
    name: 'Supp code',
    param: 'supp_code',
    type: 'text',
    colSpan: 3,
  },
  {
    name: 'Remarks/Bat.Cd.',
    param: 'remarks_bat',
    type: 'text',
    colSpan: 3,
  },
  {
    name: 'Last Pc.',
    param: 'last_pc',
    type: 'text',
    colSpan: 3,
  },
  {
    name: 'Prod. Qty.',
    param: 'prod_qty',
    type: 'text',
    colSpan: 2,
  },
  {
    name: 'Acc. Qty.',
    param: 'acc_qty',
    type: 'text',
    colSpan: 2,
  },
];
export {headFirst, headSecond, headThird };
