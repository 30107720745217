import { Grid, MenuItem } from '@mui/material';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import { TableHeading } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
  customerData: any;
  setIsOpenPopup: any;
  setPartIdx: any;
  setHeadingType: React.Dispatch<React.SetStateAction<string>>;
}
const TBody3 = ({
  setPartIdx,
  setIsOpenPopup,
  customerData,
  setHeadingType,
  moduleData,
  isView,
}: IProps) => {
  const [render, setRender] = useState(false);
  const test = useQuery('test') === 'true' ? true : false;
  return (
    <>
      <TableHeading colSpan={test ? 24 : 20} heading="REJECTION DETAILS" />
      <tr style={{ textTransform: 'uppercase' }}>
        <th>S.NO</th>
        <th colSpan={3}>PART NO.</th>
        <th colSpan={1}>JOB CARD NO.</th>
        <th colSpan={1}>LOT QTY.</th>
        <th colSpan={4}>CUSTOMER</th>
        <th colSpan={1}>Batch</th>
        <th colSpan={1}>QUALITY</th>
        <th colSpan={4}>REASON</th>
        <th colSpan={test ? 8 : 4}>REMARKS</th>
      </tr>
      {moduleData.rejection_details.map((item: any, index: number) => (
        <tr>
          <td>{index + 1}</td>
          <td colSpan={3} style={{ width: '280px', minWidth: '280px' }}>
            {typeof moduleData.operation_list[index]?.part_id === 'object' &&
            moduleData.operation_list[index]?.part_id !== null
              ? moduleData.operation_list[index].part_id?.part_number
              : (moduleData.operation_list[index]?.partNumber &&
                  moduleData.operation_list[index]?.partNumber) ||
                ''}
          </td>
          <td colSpan={1}>
            <TableInput
              isView={isView}
              name="job_card_no"
              type="text"
              value={item.job_card_no}
              onChange={(e) => {
                item.job_card_no = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={1}>
            <TableInput
              isView={isView}
              name="lot_qty"
              type="number"
              value={item.lot_qty}
              onChange={(e) => {
                item.lot_qty = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={4}>
            {isView ? (
              item.customer_id?.customer_name
            ) : (
              <AutocompleteMuiCustom
                id="customer_name"
                option_name="customer_name"
                arrayofObj={customerData}
                value={item.customer_autocomplete || item.customer_id || ''}
                onChange={(e, value) => {
                  item.customer_id = value?._id;
                  item.customer_autocomplete = value;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </td>
          <td>
            <TableInput
              isView={isView}
              name="batch"
              type="text"
              value={item.batch}
              onChange={(e) => {
                item.batch = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={1}>
            <TableInput
              isView={isView}
              name="quality_id"
              type="text"
              value={item.quality_id}
              onChange={(e) => {
                item.quality_id = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={4}>
            <TableInput
              isView={isView}
              name="reason"
              type="text"
              value={item.reason}
              onChange={(e) => {
                item.reason = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={test ? 8 : 4}>
            <TableInput
              isView={isView}
              name="remarks"
              type="text"
              value={item.remarks}
              onChange={(e) => {
                item.remarks = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
        </tr>
      ))}
    </>
  );
};

export default TBody3;
