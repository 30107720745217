import { useState } from 'react';
import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../hooks/UseQuery';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
}
const headArr = ['Inco Qty', 'Rej Qty', 'OK Qty.', 'PPM'];
const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};
const THead = ({ moduleData, isView }: IProps) => {
  const created_year = useQuery('created_year');
  const yearRange = getYearRange(created_year);

  const date = new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  return (
    <thead>
      <TableCompHead
        colSpan={56}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dt']}
        values={['', '', '']}
        heading={`${createConstant.theadHeader} (YEAR : ${yearRange})`}
      />
      <tr>
        {[
          '',
          '',
          'Months --->',
          `APR - ${date}`,
          `MAY - ${date}`,
          `JUNE - ${date}`,
          `JULY - ${date}`,
          `AUG - ${date}`,
          `SEP - ${date}`,
          `OCT - ${date}`,
          `NOV - ${date}`,
          `DEC - ${date}`,
          `JAN - ${+date + 1}`,
          `FEB - ${+date + 1}`,
          `MAR - ${+date + 1}`,
          `SUPPLIER AVERAGE PPM APR'${date} ~MAR'${+date + 1}`,
          '',
        ].map((el, index) => (
          <th
            key={`${index}_head`}
            align="center"
            style={{
              textAlign: 'center',
            }}
            colSpan={index >= 3 ? 4 : 1}
          >
            {el}
          </th>
        ))}
      </tr>
      <tr>
        {[
          'S.No.',
          'Vendor Name',
          'Vendor Code',
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          ...headArr,
          'Delete',
        ].map((el, index) => (
          <th
            key={`${index}_headcol`}
            align="center"
            style={{ textAlign: 'center' }}
          >
            {el}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
