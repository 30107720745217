import { MdOutlineSafetyDivider } from 'react-icons/md';

const tiles = [
  {
    sNo: 1,
    link: '/common/safetyCommittee',
    isLink: true,
    p: 'SAFETY COMMITTEE',
    clauseNo: 5,
    span: '',
    isIcon: true,
    icon: <MdOutlineSafetyDivider size="5rem" color="#135089" />,
  },
];

export default tiles;
