import { Box } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { PersitedDatePicker } from '../../components/common';
import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { useQuery } from '../../hooks/UseQuery';
import ProductionLogBookList from './components/tableList/ProductionLogBookList';
const heading: { [key: string]: string } = {
  groundFloor: 'GROUND FLOOR',
  firstFloor: 'FIRST FLOOR',
};
const TableList = () => {
  const date = useQuery('date');
  const documentType = useQuery('documentType') || '';
  const firstFloorType = useQuery('firstFloorType') || '';
  const groundFloorType = useQuery('groundFloorType') || '';
  const [tableData, setTableData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const test = useQuery('test') === 'true' ? true : false;
  const navigate = useNavigate();
  useEffect(() => {
    // fetch data here
    axios
      .get(
        `/api/productionLogBook/monthly/${date}?documentType=${documentType}&firstFloorType=${firstFloorType}&groundFloorType=${groundFloorType}`
      )
      .then((res) => {
        if (res.data) setTableData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render, date]);

  const deleteDocument = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?'
    );
    if (isDeleted) {
      axios
        .delete(`/api/productionLogBook/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  return (
    <Box>
      <ChildHeader
        text={
          heading[documentType] +
          ' ' +
          firstFloorType.replace('_', ' ') +
          ' ' +
          groundFloorType.replace('_', ' ')
        }
      >
        <AddButton
          label="New Production Log Book"
          onClick={() =>
            navigate(
              `/common/createProductionLogBook?documentType=${documentType}&test=${test}&firstFloorType=${firstFloorType}&groundFloorType=${groundFloorType}`
            )
          }
        />
      </ChildHeader>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box>
      <ProductionLogBookList
        {...{
          deleteDocument,
          tableData,
        }}
      />
    </Box>
  );
};

export default TableList;
