import React, { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../../../components/common';
import CustomSelect from '../../../../../../../components/common/SelectField';
import { Checkbox, MenuItem } from '@mui/material';
import formatDate from '../../../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData &&
        moduleData?.c?.map((process: any, index: number) => (
          <tr>
            <th>{process.sNo}</th>
            <th style={{ minWidth: '300px', textAlign: 'center' }}>
              {process.auditPoint}
            </th>
            <td>
              <Checkbox
                name="yes"
                checked={process?.yes}
                onChange={(e: any) => {
                  process.yes = e.target.checked;
                  if (process.yes === true) process.no = false;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              <Checkbox
                name="no"
                checked={process?.no}
                onChange={(e: any) => {
                  process.no = e.target.checked;
                  if (process.no === true) process.yes = false;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="action"
                type="text"
                value={process.action}
                onChange={(e: any) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="responsibilty"
                type="text"
                value={process.responsibilty}
                onChange={(e: any) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td>
              {isView ? (
                formatDate(process?.dueDate)
              ) : (
                <DatePickerMui
                  label=""
                  value={moment(process?.dueDate)}
                  onChange={(date: any) => {
                    process.dueDate = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="remark"
                type="text"
                value={process.remark}
                onChange={(e: any) => {
                  handleChangeModuleData(e, process);
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
