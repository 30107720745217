import axios from 'axios';
import { useEffect, useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { CustomButton } from '../../../../components/common/button/CustomButtons';
import formatDate from '../../../../components/common/formatDate';
import { MdAssignmentReturn, MdAssignmentTurnedIn } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

interface IData {
  _id?: string;
  store_part_id?: any;
  date_of_receiving?: Date;
  qty: number;
  balance_qty: string;
  isCompleted?: boolean;
  mrn_no?: string;
  ri_store_location?: string;
}
interface IProps {
  index: number;
  document: IData;
  assignRegister: (id: string) => void;
}

const ListTableRow = ({ index, document, assignRegister }: IProps) => {
  const [totalPartQty, setTotalPartQty] = useState(0);
  const [isAssign, setIsAssign] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (document.store_part_id?._id && !document.isCompleted) {
      axios
        .get(
          `/api/storeAssignRegister/part/totalStoreQty/${document.store_part_id._id}`,
        )
        .then((res) => {
          setTotalPartQty(res.data.totalStoreQty);
          if (
            res.data.totalStoreQty + document.qty >=
            document.store_part_id?.max_store_qty
          ) {
            setIsAssign(true);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [document.store_part_id?._id]);
  return (
    <TableRow
      style={{
        background: isAssign ? '#8c0101' : '',
        color: isAssign ? 'white' : '',
      }}
    >
      <TableCell
        align="center"
        style={{
          color: isAssign ? 'white' : '',
        }}
      >
        {index + 1}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {document.store_part_id?.ri_part_id?.part_number}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {document?.mrn_no}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {document?.ri_store_location}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {formatDate(document?.date_of_receiving || '')}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {document.qty}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        {totalPartQty + (document.qty || 0)}
      </TableCell>
      <TableCell
        style={{
          color: isAssign ? 'white' : '',
        }}
        align="center"
      >
        <CustomButton
          color="primary"
          size={'small'}
          disabled={document.isCompleted}
          sx={{
            margin: '10px',
          }}
          icon={<MdAssignmentReturn />}
          onClick={() => assignRegister(document._id || '')}
        >
          UNASSIGN
        </CustomButton>
        <CustomButton
          color="primary"
          size={'small'}
          disabled={document.isCompleted || isAssign}
          sx={{
            margin: '10px',
          }}
          icon={<MdAssignmentTurnedIn />}
          onClick={() =>
            navigate(
              `/common/storeSetupCreate?storeAssignId=${document._id}&riPartId=${document.store_part_id?.ri_part_id._id || ''}`,
            )
          }
        >
          ASSIGN
        </CustomButton>
      </TableCell>
    </TableRow>
  );
};

export default ListTableRow;
