import React, { useEffect, useState } from 'react';
import TController from './TController';
import { Box } from '@mui/material';
import { PersitedDatePicker } from '../../../../../../components/common';
import { useQuery } from '../../../../../../hooks/UseQuery';
import axios from 'axios';
import BarChart from './BarChart';
interface IProps {
  apiData: any;
  year: number;
  month: string;
  data: any;
  reason: string;
}
const PartWiseTop = ({ apiData, year, month, data, reason }: IProps) => {
  return (
    <div>
      {reason && (
        <>
          <TController reason={reason} apiData={apiData} />
          {
            <Box
              style={{ paddingBottom: '3rem', height: '40vh', width: '50vw' }}
            >
              <BarChart apiData={apiData} reason={reason} />
            </Box>
          }
        </>
      )}
    </div>
  );
};

export default PartWiseTop;
