import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  apiData: any;
  totalProducedQty: number;
}
const BarChart = ({ apiData, totalProducedQty }: IProps) => {
  const customerNames = apiData.map((data: any) => data.customerName);
  const ppmData = apiData.map((item: { defectCount: number }) => {
    return ((item.defectCount * 1000000) / totalProducedQty).toFixed(0);
  });
  const options = {
    plugins: {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'CUSTOMER WISE SCRAP PPM',
      },
    },
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'CUSTOMER',
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          //stepSize: 5,
        },
        title: {
          display: true,
          text: 'PPM',
        },
      },
    },
  };

  const backgroundColors = ['#4c6ef5'];
  const datasets = [
    {
      label: '',
      data: ppmData,
      backgroundColor: backgroundColors,
      borderWidth: 1,
      barThickness: 50,
    },
  ];
  const data = {
    labels: customerNames,
    datasets: datasets,
  };
  return <Bar options={options} data={data} />;
};

export default BarChart;
