import React, { useState } from 'react';
import Heading from './moduleBody/Heading';
import { IModuleData, IProcess } from '../../helpers/Interface';
import Process from './moduleBody/Process';
import useConfirm from '../../../../components/common/useConfirm';

interface IProps {
  moduleData: IModuleData;
  setModuleData: React.Dispatch<React.SetStateAction<any>>;
}

const ModuleBody = ({ moduleData, setModuleData }: IProps) => {
  const [expandState, setExpandState] = useState([]);
  const [render, setRender] = useState(false);
  const [DialogRemoveProcess, confirmRemoveProcess] = useConfirm(
    'REMOVE',
    'Are you sure you want to remove this process?'
  );
  const removeProcess = async (index: number) => {
    const isDelete = await confirmRemoveProcess();
    if (!isDelete) return;
    // if (isDelete) {
    const values = { ...moduleData };
    values.processes && values.processes.splice(index, 1);
    setModuleData(values);
    // }
  };
  const handleChangeProcess = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = e.target;
    moduleData.processes[index][name as keyof IProcess] = value;
    moduleData.processes?.sort((a, b) => Number(a.s_no) - Number(b.s_no));
    setRender((prev) => !prev);
  };

  return (
    <>
      <Heading {...{ setExpandState, moduleData, setRender, setModuleData }} />
      {moduleData.processes &&
        moduleData.processes.map((process, index) => (
          <Process
            key={index + 'module process'}
            {...{
              moduleData,
              setModuleData,
              removeProcess,
              process,
              index,
              handleChangeProcess,
              setExpandState,
              expandState,
            }}
          />
        ))}
      <DialogRemoveProcess />
    </>
  );
};

export default ModuleBody;
