// import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { useQuery } from '../../../../../hooks/UseQuery';
import { TableInput } from '../../../../../components/common';
import axios from 'axios';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const numberCol = Array.from({ length: 31 }, (_, index) => index + 1);

const theadData = [
  'Sr.No.',
  'CONTAINS',
  'WHAT',
  'WHERE',
  'HOW',
  'WHO',
  'WHEN',
  'CHECK By',
  ...numberCol,
];
// colspan 38

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const annual_month = useQuery('annual_date') || '';
  const [machineData, setMachineData] = useState([]);

  useEffect(() => {
    axios
      .get('/api/machineRoutes/machineList/machineListForAutoComplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <thead>
      <TableCompHead
        colSpan={39}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="PALL MACHINE CHECK SHEET"
      />
      <tr>
        <th colSpan={7} align="left">
          Date :{' '}
          {isView ? (
            formatDate(moduleData.pokeYoke_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.pokeYoke_date)}
              onChange={(date) => {
                moduleData.pokeYoke_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={32}></th>
      </tr>
      <tr>
        <th>S.NO.</th>
        <th colSpan={26}>INSTRUCTION TO OPERATOR</th>
        <th colSpan={4}>LEVEL</th>
        <th colSpan={4}>RESPONSIBILITY</th>
        <th colSpan={4}>RESPONSE TIME</th>
      </tr>
      <tr>
        <th>1</th>
        <th colSpan={26}>
          Operator will check the parameters given in this checksheet and inform
          to team leader if any abnormality observed.
        </th>
        <th colSpan={4}>1</th>
        <th colSpan={4}>Quality Engineer</th>
        <th colSpan={4}>8 hrs.</th>
      </tr>
      <tr>
        <th>2</th>
        <th colSpan={26}>
          Team leader will correct the abnormality immediately and allow
          operator to run the machine.
        </th>
        <th colSpan={4}>2</th>
        <th colSpan={4}>Q.A Head</th>
        <th colSpan={4}>24 hours</th>
      </tr>
      <tr>
        <th>3</th>
        <th colSpan={26}>Operator will sign. after inspection</th>
        <th colSpan={4}>3</th>
        <th colSpan={4}>Plant Head</th>
        <th colSpan={4}>24 hours</th>
      </tr>
      <tr>
        <th colSpan={12} align="left">
          मशीन का नाम :{moduleData.machine_id?.machine_name}
        </th>
        <th colSpan={13} align="left"></th>
        <th colSpan={5} align="left">
          मशीन न.:{' '}
          {isView ? (
            moduleData?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={moduleData.machine_id || ''}
              onChange={(e, value) => {
                moduleData.machine_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={5} align="left">
          एरिया :
          <TableInput
            isView={isView}
            name="operator_1"
            type="text"
            value={moduleData.area}
            onChange={(e) => {
              moduleData.area = e.target.value;
              setRender((prev: any) => !prev);
            }}
          />
        </th>
        <th colSpan={5} align="left">
          महीना:
          {moduleData.annual_month || annual_month}
        </th>
      </tr>
      <tr>
        <th colSpan={39}>
          <img height={300} width={400} src={moduleData.img} alt={'image'} />
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading} colSpan={heading === 'Remarks' ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
