export const listConstant = {
  childHeader: 'Special Audit Plan',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE Plan',
};

export const createConstant = {
  childHeader: 'Special Audit Plan',
  theadHeader: 'Special Audit Plan',
  extracolSpan: 2,
};

export const checkListList = {
  childHeader: 'Special Audit Plan STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'vdaProcessAuditPlanRoute',
  gettableDataurl: 'vdaProcessAuditPlanRoute/monthly',
  getPlanByid: 'vdaProcessAuditPlanRoute',
  createPlanByid: 'vdaProcessAuditPlanRoute',
  updatePlanByid: 'vdaProcessAuditPlanRoute',
  sync: 'vdaProcessAuditPlanRoute/handleSync',
  getAllCheckList: 'vdaProcessAuditPlanRoute/getAllCheckList',
  checkListStatus: 'vdaProcessAuditPlanRoute/handleStatus',
  checklistUpload: 'vdaProcessAuditPlanRoute/handleUpload',
  checklistDeleteImage: 'vdaProcessAuditPlanRoute/handleDeleteImage',
  checklistData: 'vdaProcessAuditPlanRoute/checklistRoute',
};

export const navigationConstant = {
  createPlanSchedule: '/common/vdaProcessAuditPlanCreate',
  createChecklist: '/common/vdaProcessAuditPlanChecklistCreate',
};
