import moment from 'moment';
import { pallMachineData } from './pallMachineData';

export const initialState = {
    pall_machine_date: moment(),
    month: '',
    processes: pallMachineData,
    foot_data: Array.from({ length: 5 }, () => ({})),
    revision_data: Array.from({ length: 5 }, () => ({})),
};
