// import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { intructionHeading } from '../../helpers/instructionHeading';

interface IProps {
  moduleData: any;
  isView: boolean;
  headerTitle: string;
}

const numberCol = Array.from({ length: 31 }, (_, index) => index + 1);

const theadData = [
  'क्रम सं.',
  'CONTAINS',
  'WHAT',
  'WHERE',
  'HOW',
  'WHO',
  'WHEN',
  'Checked by',
  ...numberCol,
  'Remarks',
];

// const intructionHeading = []

const THead = ({ moduleData, isView, headerTitle }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={40}
        rowSpan={3}
        labels={['DOC. NO.:', 'REV. NO.:', 'DATE:']}
        values={['FM/XLV/MT/004', '01', '17.07.2021']}
        heading={headerTitle}
      />
      {intructionHeading?.map((heading, index) => (
        <tr key={index + 'intru'}>
          <th colSpan={2}>{heading.sr_no}</th>
          <th colSpan={27} align="left">
            {heading.instrution}
          </th>
          <th colSpan={3}>{heading.level}</th>
          <th colSpan={5} align="left">
            {heading.resp}
          </th>
          <th colSpan={3}>{heading.response_time}</th>
        </tr>
      ))}
      <tr>
        <th colSpan={22}>PHOTO / फ़ोटो . </th>
        <th colSpan={3} align="left">
          Area / एरिया :
        </th>
        <th colSpan={4} align="left">
          LEICA ROOM{' '}
        </th>
        <th colSpan={2} align="left">
          Date :{' '}
        </th>
        <th colSpan={3} align="left">
          {isView ? (
            formatDate(moduleData.checkSheet_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.checkSheet_date)}
              onChange={(date) => {
                moduleData.checkSheet_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3} align="left">
          Month :{' '}
        </th>
        <th colSpan={3} align="left">
          <TableInput
            isView={isView}
            name="month"
            type="text"
            value={moduleData.month}
            onChange={(e) => {
              moduleData.month = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr style={{ height: '200px' }}>
        <th colSpan={40}></th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
