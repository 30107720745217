import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../../../components/common/button/CustomIconButton';
import { BorderClear } from '@mui/icons-material';
import Styled from 'styled-components';

interface IStyle {
  [key: string]: any;
}

const Table = Styled.table`
	width:100%;
  border-spacing:10px;
  padding-left:100px;
  padding-right:100px;
  padding-top:40px;
  padding-bottom:40px;
  
  td, th,tr {
    border:none;
    height:55px;
  
    border-radius:15px;

  };
`;

const TBody = ({ Data }: { Data: any }) => {
  const [render, setRender] = useState(false);
  const shelfColor = 'yellow';
  const searchShelfColor = 'green';
  const notSearchedShelfColor = 'blue';
  const [style, setStyle] = useState<IStyle>({
    A4: { backgroundColor: notSearchedShelfColor },
    A3: { backgroundColor: notSearchedShelfColor },
    A2: { backgroundColor: notSearchedShelfColor },
    A1: { backgroundColor: notSearchedShelfColor },

    B4: { backgroundColor: notSearchedShelfColor },
    B3: { backgroundColor: notSearchedShelfColor },
    B2: { backgroundColor: notSearchedShelfColor },
    B1: { backgroundColor: notSearchedShelfColor },

    C1: { backgroundColor: notSearchedShelfColor },
    C2: { backgroundColor: notSearchedShelfColor },
    C3: { backgroundColor: notSearchedShelfColor },
    C4: { backgroundColor: notSearchedShelfColor },
  });
  const [defStyle, setDefStyle] = useState<IStyle>(style);

  const rows = [4, 3, 2, 1];

  useEffect(() => {
    const set = {} as IStyle;
    Data.forEach((data: any) => {
      set[(data?.address).split(',')[1]] = {
        backgroundColor: searchShelfColor,
      };
    });
    setStyle({ ...defStyle, ...set });
  }, [Data]);
  return (
    <>
      <Table>
        <tbody>
          {rows.map((row: number, index: number) => (
            <React.Fragment key={index + 1}>
              <tr>
                <td width={'17%'} style={style[`A${row}`]}></td>
                <td
                  width={'5%'}
                  style={{ backgroundColor: shelfColor, fontWeight: 'bold' }}
                >
                  {' '}
                  {`A${row}`}
                </td>
                <td width={'17%'}></td>
                <td width={'17%'} style={style[`B${row}`]}></td>
                <td
                  width={'5%'}
                  style={{ backgroundColor: shelfColor, fontWeight: 'bold' }}
                >{`B${row}`}</td>
                <td width={'17%'}></td>
                <td width={'17%'} style={style[`C${row}`]}></td>
                <td
                  width={'5%'}
                  style={{ backgroundColor: shelfColor, fontWeight: 'bold' }}
                >{`C${row}`}</td>
                <td width={'17%'}></td>
              </tr>
              <tr></tr>
            </React.Fragment>
          ))}
          <tr>
            <td
              style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}
              colSpan={2}
            >
              A- Wing
            </td>
            <td></td>
            <td
              style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}
              colSpan={2}
            >
              B - Wing
            </td>
            <td></td>
            <td
              style={{ backgroundColor: 'yellow', fontWeight: 'bold' }}
              colSpan={2}
            >
              C - Wing
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default TBody;
