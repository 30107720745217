import { Radio } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: { [key: string]: any };
  isView: boolean;
  title?: string;
  phase: any;
}
const THead = ({ title, phase, moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const handleRadioButton = (e: any) => {
    moduleData.product_details_radio = e.target.value;
    setRender((prev) => !prev);
  };

  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const handleChange = (e: any) => {
    moduleData[e.target.name] = e.target.value;
    setRender((prev) => !prev);
  };
  return (
    <thead>
      <tr>
        <th colSpan={31} align="left">
          PART NAME : {partName}
        </th>
      </tr>
      <tr>
        <th colSpan={31} align="left">
          PART NUMBER & MODEL : {partNumber}
        </th>
      </tr>
      <tr>
        <th style={{ background: '#bde0fe' }}>A</th>
        <th colSpan={5} align="left">
          Any Similar type of product has not been produced earlier .
        </th>
        <th colSpan={2}>
          <Radio
            checked={moduleData.product_details_radio === 'a'}
            onChange={(e) => {
              handleRadioButton(e);
            }}
            value={'a'}
            name="product_details_radio"
          />
        </th>
        <th colSpan={11}></th>
        <th colSpan={7}></th>
        <th
          colSpan={2}
          rowSpan={4}
          style={{ textAlign: 'center', fontSize: '2rem' }}
        >
          ✓
        </th>
        <th colSpan={4} rowSpan={4}>
          ACTIVITY REQUIRED
        </th>
      </tr>
      <tr>
        <th style={{ background: '#bde0fe' }}>B</th>
        <th colSpan={5} align="left">
          A Similar type of product has been produced earlier .
        </th>
        <th colSpan={2}>
          <Radio
            checked={moduleData.product_details_radio === 'b'}
            onChange={(e) => {
              handleRadioButton(e);
            }}
            value={'b'}
            name="product_details_radio"
          />
        </th>
        <th colSpan={11}></th>
        <th colSpan={7}></th>
      </tr>
      <tr>
        <th style={{ background: '#bde0fe' }}>C</th>
        <th colSpan={5} align="left">
          Existing Product ECN{' '}
        </th>
        <th colSpan={2}>
          <Radio
            checked={moduleData.product_details_radio === 'c'}
            onChange={(e) => {
              handleRadioButton(e);
            }}
            value={'c'}
            name="product_details_radio"
          />
        </th>
        <th colSpan={11}></th>
        <th colSpan={7}></th>
      </tr>
      <tr>
        <th style={{ background: '#bde0fe' }}>D</th>
        <th colSpan={5} align="left">
          Location Change/PCN{' '}
        </th>
        <th colSpan={2}>
          <Radio
            checked={moduleData.product_details_radio === 'd'}
            onChange={(e) => {
              handleRadioButton(e);
            }}
            value={'d'}
            name="product_details_radio"
          />
        </th>
        <th colSpan={11}></th>
        <th colSpan={7}></th>
      </tr>
      <tr style={{ height: '50px' }}>
        <th colSpan={6} align="left">
          REVIEWED BY PROJECT LEADER (PL)
        </th>
        <th colSpan={2}>WEEKLY </th>
        <th colSpan={11}></th>
        <th colSpan={7}></th>
        <th
          colSpan={2}
          rowSpan={3}
          style={{ textAlign: 'center', fontSize: '2rem' }}
        >
          x
        </th>
        <th colSpan={4} rowSpan={3}>
          ACTIVITY NOT REQUIRED
        </th>
      </tr>
      <tr style={{ height: '50px' }}>
        <th colSpan={6} align="left">
          REVIEWED BY CFT
        </th>
        <th colSpan={2}>WEEKLY </th>
        <th colSpan={11}></th>
        <th colSpan={7}></th>
      </tr>
      <tr style={{ height: '50px' }}>
        <th colSpan={6} align="left">
          REVIEWED BY TOP MANAGEMENT(TM )
        </th>
        <th colSpan={2}>WEEKLY </th>
        <th colSpan={11}></th>
        <th colSpan={7}></th>
      </tr>
      <tr>
        <th colSpan={2} align="left" rowSpan={3}>
          CUSTOMER EVENTS:
        </th>
        <th colSpan={1} align="left">
          CUSTOMER DRAWING:
        </th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.customer_drawing_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.customer_drawing_date || null)}
              onChange={(date) => {
                moduleData.customer_drawing_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={1} align="left">
          CUSTOMER PO:
        </th>
        <th colSpan={2} align="left">
          <TableInput
            isView={isView}
            name="customer_po"
            type="text"
            value={moduleData.customer_po}
            onChange={handleChange}
          />
        </th>
        <th colSpan={2} align="left">
          CUSTOMER PO DATE:
        </th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.customer_po_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.customer_po_date || null)}
              onChange={(date) => {
                moduleData.customer_po_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left">
          T2 (IF REQUIRED):
        </th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.t2_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.t2_date || null)}
              onChange={(date) => {
                moduleData.t2_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={1} align="left">
          QTY:
        </th>
        <th colSpan={2} align="left">
          <TableInput
            isView={isView}
            name="t2_qty"
            type="text"
            value={moduleData.t2_qty}
            onChange={handleChange}
          />
        </th>
        <th colSpan={7}></th>
        <th style={{ backgroundColor: 'yellow' }} colSpan={2}>
          PLAN
        </th>
        <th colSpan={3} style={{ backgroundColor: '#2f9e44' }}>
          DONE
        </th>
      </tr>
      <tr>
        <th colSpan={1} align="left">
          QUOTATION:
        </th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.quatation_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.quatation_date || null)}
              onChange={(date) => {
                moduleData.quatation_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={1} align="left">
          T1:
        </th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.t1_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.t1_date || null)}
              onChange={(date) => {
                moduleData.t1_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left">
          QTY:
        </th>
        <th colSpan={2} align="left">
          <TableInput
            isView={isView}
            name="t1_qty"
            type="text"
            value={moduleData.t1_qty}
            onChange={handleChange}
          />
        </th>
        <th colSpan={2} align="left">
          T3 (IF REUIRED):
        </th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.t3_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.t3_date || null)}
              onChange={(date) => {
                moduleData.t3_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={1} align="left">
          QTY:
        </th>
        <th colSpan={2} align="left">
          <TableInput
            isView={isView}
            name="t3_qty"
            type="text"
            value={moduleData.t3_qty}
            onChange={handleChange}
          />
        </th>
        <th colSpan={7}></th>
        <th style={{ backgroundColor: 'yellow' }} colSpan={2} align="left"></th>
        <th
          style={{ backgroundColor: '#2f9e44' }}
          colSpan={3}
          align="left"
        ></th>
      </tr>
      <tr>
        <th colSpan={1} align="left">
          PPAP SUBMISSION:
        </th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.ppap_submission_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.ppap_submission_date || null)}
              onChange={(date) => {
                moduleData.ppap_submission_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th align="left">SOP:</th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.sop_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.sop_date || null)}
              onChange={(date) => {
                moduleData.sop_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={18}></th>
        <th colSpan={2} style={{ backgroundColor: 'yellow' }}>
          P- PLAN
        </th>
        <th colSpan={3} style={{ backgroundColor: '#2f9e44' }}>
          A- ACTUAL
        </th>
      </tr>
      <tr style={{ background: '#d0f4de' }}>
        {[
          'S.N.',
          'PHASE',
          'ACTIVITY',
          'REQUIRED',
          'NOT REQUIRED',
          'RESPONSIBILITY',
          'MONTHS',
          '',
          'START DATE',
          'COMPLETE DATE',
          'REMARKS',
          'UPLOAD PROOF',
        ].map((heading, index) => (
          <th
            key={index + 'heading'}
            rowSpan={index === 6 ? 1 : 3}
            colSpan={index === 6 ? 20 : 1}
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr style={{ background: '#d0f4de' }}>
        {Array(4)
          .fill(0)
          .map((_, index) => (
            <th colSpan={5}>
              {isView ? (
                formatDate(moduleData?.phase_months?.[index])
              ) : (
                <DatePickerMui
                  label=""
                  value={moment(moduleData?.phase_months?.[index] || null)}
                  onChange={(date) => {
                    moduleData.phase_months[index] = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </th>
          ))}
      </tr>
      <tr style={{ background: '#d0f4de' }}>
        {[
          'W1',
          'W2',
          'W3',
          'W4',
          'W5',
          'W1',
          'W2',
          'W3',
          'W4',
          'W5',
          'W1',
          'W2',
          'W3',
          'W4',
          'W5',
          'W1',
          'W2',
          'W3',
          'W4',
          'W5',
        ].map((heading) => (
          <th>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
