import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import formatDate from '../../components/common/formatDate';
import ChildHeader from '../../components/ui/ChildHeader';
import { useQuery } from '../../hooks/UseQuery';
import { useAuth } from '../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
import useConfirm from '../../components/common/useConfirm';
interface MyData {
  moduleData: any; // replace PdirType with the type of your `pdir` data
  isLoading: boolean;
  machineData: any;
  error?: string | null;
  setModuleData: any;
}

const Create = () => {
  const { pdirId } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const { moduleData, isLoading, error, machineData, setModuleData }: MyData =
    useGetData(pdirId);
  const [render, setRender] = useState(false);
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const onSubmit = async (isSubmitted: boolean) => {
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;
    let response: any;
    if (moduleData._id) {
      response = await axios
        .put(`/api/airborneRoute/${moduleData._id}`, moduleData)
        .catch((err) => console.log(err));
    } else {
      response = await axios
        .post(`/api/airborneRoute`, moduleData)
        .catch((err) => console.log(err));
    }
    if (moduleData._id && !isSubmitted) {
      alert('updated successfully ');
      return;
    }
    alert('created successfully');
    navigate(-1);
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.list.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <Box>
      <Box>
        <ChildHeader text="AIRBORNE & WHITE PATCH WIPE TEST REPORT">
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  moduleData.list.push({});
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton>

              {state.user?.userType === 3 && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => onSubmit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => onSubmit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{ moduleData, tabValue, isView, machineData, handelDeleteRows }}
      />
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
    </Box>
  );
};

export default Create;
