import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridSelect from '../../../components/common/inputs/GridSelect';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import GridInputs from '../../../components/common/inputs/GridInputs';

const wings = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({});

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Document' : 'Create Document'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/storeBinCreationRoute/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleChange(e: SelectChangeEvent<unknown>) {
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    if (!data.wing) return alert('Please choose wing first');
    if (data.floor < 0 || data.block < 0)
      return alert('Floor and Bin not be negative');
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/storeBinCreationRoute/${id}`, data)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/storeBinCreationRoute', data)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      {!id && (
        <GridSelect
          name="wing"
          label_name="Wing:"
          html_for={'wing'}
          value={data.wing || ''}
          onChange={handleChange}
          disabled={id ? true : false}
        >
          {wings.map((wing) => (
            <MenuItem value={wing}>{wing}</MenuItem>
          ))}
        </GridSelect>
      )}
      {id && (
        <>
          <GridInputs
            id={'wing_type'}
            name={'wing_type'}
            html_for={'wing_type'}
            label_name={'Rack:'}
            input_type={'text'}
            disabled={id ? true : false}
            focusType={'onblur'}
            value={data?.wing_type || ''}
            onChange={handleChange}
          />
          <GridInputs
            id={'floor'}
            name={'floor'}
            html_for={'floor'}
            label_name={'Floor:'}
            input_type={'number'}
            focusType={'onblur'}
            value={data?.floor || ''}
            onChange={handleChange}
          />
          <GridInputs
            id={'block'}
            name={'block'}
            html_for={'block'}
            label_name={'Bin:'}
            input_type={'number'}
            focusType={'onblur'}
            value={data?.block || ''}
            onChange={handleChange}
          />
        </>
      )}
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
