import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

interface Ifoot {
  moduleData: any;
  isView: boolean;
  tabValue: any;
}
const TFoot = ({ moduleData, isView, tabValue }: Ifoot) => {
  const [render, setRender] = useState(false);

  return (
    <tfoot>
      <tr>
        <th colSpan={tabValue === 0 ? 5 : 7}></th>
      </tr>
      <tr>
        <th colSpan={tabValue === 0 ? 3 : 4} rowSpan={3} align="left">
          Prepared By:{' '}
          <TableInput
            isView={isView}
            name="report_prepared_by"
            type="text"
            value={moduleData.report_prepared_by}
            onChange={(e) => {
              moduleData.report_prepared_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>

        <th colSpan={tabValue === 0 ? 2 : 3} align="left">
          Approved By:
          <TableInput
            isView={isView}
            name="report_approved_by"
            type="text"
            value={moduleData.report_approved_by}
            onChange={(e) => {
              moduleData.report_approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
