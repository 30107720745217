import { useState } from 'react';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <tr style={{ height: '45px' }}>
        <th></th>
        <th colSpan={9}>
          CRITERIA FOR IDENTIFYING CATEGORY OF PRODUCT DEVELOPMENT HAS BEEN
          DEFINED AS BELOW:
        </th>
        <th colSpan={5}>NOTES:</th>
      </tr>
      <tr style={{ height: '45px' }}>
        <th colSpan={1} style={{ minWidth: '80px' }}>
          S NO.
        </th>
        <th colSpan={6}>TYPE OF DEVELOPMENT</th>
        <th colSpan={3}>CATEGORY</th>
        <th style={{ background: '#bde0fe' }} rowSpan={5} colSpan={5}>
          DEPARTMENT CODES:
          <br />
          NPD HEAD- NEW PRODUCT DEVELOPMENT
          <br />
          QA-QUALITY APPRAISER,
          <br />
          CFT-CROSS FUNCTIONAL TEAM,
          <br />
          PR-Production,
          <br />
          MN-MAINTENANCE
          <br />
          MKT-MARKETING
          <br />
          PUR-PURCHASE
          <br />
          HR HEAD:- HUMAN RESOURCES
        </th>
      </tr>
      <tr style={{ height: '45px' }}>
        <th colSpan={1}>1</th>
        <th colSpan={6}>
          ANY SIMILAR TYPE OF PRODUCT HAS NOT BEEN PRODUCED EARLIER
        </th>
        <th colSpan={3}>A</th>
      </tr>

      <tr style={{ height: '45px' }}>
        <th colSpan={1}>2</th>
        <th colSpan={6}>
          A SIMILAR TYPE OF PRODUCT HAS BEEN PRODUCED EARLIER.
        </th>
        <th colSpan={3}>B</th>
      </tr>
      <tr style={{ height: '45px' }}>
        <th colSpan={1}>3</th>
        <th colSpan={6}>EXISTING PRODUCT ECN</th>
        <th colSpan={3}>C</th>
      </tr>
      <tr style={{ height: '45px' }}>
        <th colSpan={1}>4</th>
        <th colSpan={6}>LOCATION CHANGE /PCN </th>
        <th colSpan={3}>E</th>
      </tr>

      <tr>
        <th rowSpan={2} colSpan={1}>
          S NO.
        </th>
        <th rowSpan={2} colSpan={2} style={{ minWidth: '500px' }}>
          ACTIVITY
        </th>
        <th colSpan={4}>CATEGORY OF DEVELOPMENT</th>
        <th colSpan={9}>RESPONSIBLE DEPARTMENTS (P-PRIMARY, S-SECONDARY)</th>
      </tr>
      <tr>
        <th>A</th>
        <th>B</th>
        <th>C</th>
        <th>D</th>
        <th>NPD HEAD</th>
        <th>QA</th>
        <th>CFT PR</th>
        <th> PR</th>
        <th>MN</th>
        <th>MKT</th>
        <th>PUR</th>
        <th>HR HEAD</th>
      </tr>
    </thead>
  );
};

export default THead;
