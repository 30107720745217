import React from 'react';
import { Box } from '@mui/material';
import TController from './TController';
import LineChart from './LineChart';
import BarChart from './BarChart';
interface IProps {
  apiData: any;
  year: number;
  data: any;
}
const MonthlyPpm = ({ apiData, year, data }: IProps) => {
  const { rejectionMonthlyCounts } = apiData;
  const { total_produced_qty } = data;
  const average =
    rejectionMonthlyCounts.reduce((result: number, data: any) => {
      return result + (data.rejectionCount * 1000000) / total_produced_qty;
    }, 0) / 12;

  return (
    <>
      {data?.total_produced_qty && data?.target && (
        <>
          <TController
            apiData={apiData}
            year={year}
            totalProducedQty={data?.total_produced_qty}
            target={data?.target}
            average={average}
          />
          <Box style={{ paddingBottom: '3rem', height: '40vh', width: '50vw' }}>
            <LineChart
              apiData={apiData}
              totalProducedQty={data?.total_produced_qty}
            />
          </Box>
          <Box style={{ paddingBottom: '3rem', height: '40vh', width: '50vw' }}>
            <BarChart
              apiData={apiData}
              average={average}
              target={data?.target}
            />
          </Box>
        </>
      )}
    </>
  );
};

export default MonthlyPpm;
