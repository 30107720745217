import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';

interface IProps {
  Data: any;
}

const TController = ({ Data }: IProps) => {
  return (
    <Box>
      <RenderTable>
        {/* <THead /> */}
        <TBody Data={Data} />
      </RenderTable>
    </Box>
  );
};

export default TController;
