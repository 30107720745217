import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import AutocompleteMuiCustom from '../../../components/common/AutocompleteMuiCustom';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import GridSelect from '../../../components/common/inputs/GridSelect';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import ModalCustom from '../../../components/common/Modal';
import { DivStyled } from '../../../assets/styles/components/DivStyledLableInputs';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../components/common/button/CustomIconButton';
import PartTable from './components/PartTable';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [data, setData] = useState<any>({
    exclude_report: false,
  });
  const [images, setImages] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Vendor' : 'Create Ventor'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );
  const [customerData, setCustomerData] = useState([]);
  const [defectData, setDefectData] = useState([]);
  const [isPartModal, setIsPartModal] = useState(false);
  const [render, setRender] = useState(false);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/pastTroubleDatabaseRoutes/getById/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomerData(res.data);
      })
      .catch((err) => console.log(err));

    axios
      .get('/api/defectRoutes/defectList/defectForAutoComplete')
      .then((res) => {
        setDefectData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>,
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const handleSumbit = async () => {
    if (!data.customer_id) {
      alert('Please enter customer name');
      return;
    }
    if (!data.defect_id) {
      alert('Please enter defect name');
      return;
    }

    const ans = await confirmSubmit();
    if (!ans) return;

    setIsLoading(true);
    const formData = new FormData();

    if (typeof data.customer_id === 'object') {
      data.customer_id = data.customer_id._id;
    }
    if (typeof data.defect_id === 'object') {
      data.defect_id = data.defect_id._id;
    }
    if (typeof data.part_id === 'object') {
      data.part_id = data.part_id._id;
    }
    for (const key in data) {
      if (data[key] || typeof data[key] == 'boolean') {
        if (key !== 'files' && key !== 'images') {
          formData.append(key, data[key]);
        }
      }
    }

    if (images) {
      for (const key of Object.keys(images)) {
        formData.append('images', images[key]);
      }
    }

    if (id) {
      await axios
        .put(`/api/pastTroubleDatabaseRoutes/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/pastTroubleDatabaseRoutes', formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <GridDatePicker
        label_name="Issue Reported Date"
        html_for={'issue_reported_date'}
        value={data?.issue_reported_date || null}
        onChange={(date) => {
          data.issue_reported_date = date;
        }}
      />
      <GridInputs
        id={'product_family'}
        name={'product_family'}
        html_for={'product_family'}
        label_name={'Product Family:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.product_family || ''}
        onChange={handleCustomerData}
      />
      <Grid item xs={3} style={{ marginTop: '10px' }}>
        <AutocompleteMuiCustom
          id={'customer_name'}
          label={'Customer Name'}
          option_name={'customer_name'}
          arrayofObj={customerData}
          value={data.customer_name || data.customer_id || ''}
          onChange={(e, value) => {
            data.customer_id = value._id;
            data.customer_name = value;
            setRender((prev) => !prev);
          }}
        />
      </Grid>
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Number'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.part_id === 'object' && data?.part_id !== null
                  ? data?.part_id?.part_number
                  : data?.partNumber || ''}
              </p>
            </Grid>

            {data.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    data.part_id = null;
                    data.partNumber = null;
                    data.partName = null;

                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsPartModal(true);
                  }}
                />
              </Grid>
            )}
          </Grid>
        </div>
      </DivStyled>
      <DivStyled style={{ alignItems: 'center' }}>
        <label>{'Part Name'}</label>
        <div
          style={{
            borderLeft: '2px solid #ced4da',
            paddingLeft: '1rem',
            width: '100%',
          }}
        >
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.6rem',
                }}
              >
                {typeof data?.part_id === 'object' && data?.part_id !== null
                  ? data?.part_id?.part_name
                  : data?.partName || ''}
              </p>
            </Grid>
          </Grid>
        </div>
      </DivStyled>
      <Grid item xs={3} style={{ marginTop: '10px' }}>
        <AutocompleteMuiCustom
          id={'defect_name'}
          label={'Reason of Rejection/ Defect Reported'}
          option_name={'defect_name'}
          arrayofObj={defectData}
          value={data.defect_name || data.defect_id || ''}
          onChange={(e, value) => {
            data.defect_id = value._id;
            data.defect_name = value;
            setRender((prev) => !prev);
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <CustomUploadButton
          lable={'Defect photo'}
          multiple
          accept="image/png,image/jpeg,image/jpg"
          onChange={(e) => {
            if (images) {
              setImages([...images, ...e.target.files]);
            } else {
              setImages(e.target.files);
            }
          }}
        />{' '}
      </Grid>
      <GridSelect
        name="defect_category"
        label_name="Defect Category"
        html_for={'defect_category'}
        value={data?.defect_category || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="Fit">Fit</MenuItem>
        <MenuItem value="Function">Function</MenuItem>
        <MenuItem value="Form">Form</MenuItem>
      </GridSelect>
      <GridInputs
        id={'customer_end_qty'}
        name={'customer_end_qty'}
        html_for={'customer_end_qty'}
        label_name={'Customer End Qty.'}
        input_type={'number'}
        focusType={'onblur'}
        value={data?.customer_end_qty || ''}
        onChange={handleCustomerData}
      />
      <GridSelect
        name="firstTime_repeated_ndp"
        label_name="1st Time/Repeated/NDP"
        html_for={'firstTime_repeated_ndp'}
        value={data?.firstTime_repeated_ndp || ''}
        onChange={handleCustomerData}
      >
        <MenuItem value="First Time">First Time</MenuItem>
        <MenuItem value="Repeated">Repeated</MenuItem>
        <MenuItem value="NDP">NDP</MenuItem>
      </GridSelect>
      <GridInputs
        id={'root_cause'}
        name={'root_cause'}
        html_for={'root_cause'}
        label_name={'Root Cause(Occurrence and Detection):'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.root_cause || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'corrective'}
        name={'corrective'}
        html_for={'corrective'}
        label_name={'Corrective and Preventive Action:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.corrective || ''}
        onChange={handleCustomerData}
      />
      {[1, 2, 3].map((item) => (
        <>
          <GridInputs
            id={`score_${item}`}
            name={`score_${item}`}
            html_for={`score_${item}`}
            label_name={'Score:'}
            input_type={'text'}
            focusType={'onblur'}
            value={data[`score_${item}`] || ''}
            onChange={handleCustomerData}
          />
          <GridInputs
            id={`remarks_${item}`}
            name={`remarks_${item}`}
            html_for={`remarks_${item}`}
            label_name={'Remarks:'}
            input_type={'text'}
            focusType={'onblur'}
            value={data[`remarks_${item}`] || ''}
            onChange={handleCustomerData}
          />
        </>
      ))}
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
      <ModalCustom
        openModal={isPartModal}
        closeModal={() => {
          setIsPartModal(false);
        }}
        title="PART"
      >
        <PartTable moduleData={data} />
      </ModalCustom>
    </>
  );
};

export default Create;
