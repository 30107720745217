import { Box, Divider, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Loader } from '../../components/common/Loader';
import {
  CustomButton,
  SaveButton,
  CloseButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import PartDetailsHeader from '../../components/ui/PartDeatailsHeader';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';
import axios from 'axios';
import { IData } from './interface';
import InputMuiCustom from '../../components/common/inputs/InputMuiCustom';
import AlertMui from '../../components/common/AlertMui';

const CreateEdit = () => {
  const [loader, setLoader] = useState<boolean>(false);
  let { partId, id } = useParams();
  const partName = useQuery('part_name');
  const partNumber = useQuery('part_number');
  const navigate = useNavigate();

  const [DialogClose, confirmClose] = useConfirm(
    'CLOSE',
    'Are you sure you want to leave this page?'
  );
  const [spcData, setSpcData] = useState<IData>({
    part_id: '',
    process_id: '',
    supplier: '',
    spec_id: '',
    customer: '',
    specification: '',
    control_product: '',
    process_name: '',
    instrument: '',
    l_count: '',
    no_of_dec: null,
    spc_array: Array.from({ length: 50 }, () => null),
  });
  const [process, setProcess] = useState([]);
  const [render, setRender] = useState(false);
  const [spec, setSpec] = useState([]);
  const [alert, setAlert] = useState(false);
  const [alertforSubmmit, setAlertforSubmmit] = useState(false);
  const [autoCompleteProcess, setAutoCompleteProcess] = useState<any>('');
  const [autoCompleteSpec, setAutoCompleteSpec] = useState<any>('');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update SPC' : 'Create SPC'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(`/api/spcRoute/controlplan/${partId}`);
        spcData.customer = res.data.customer;
        spcData.supplier = res.data.supplier;
        setProcess(res.data.processes);

        //
        if (id) {
          const dataspc: any = await axios.get(`/api/spcRoute/${id}`);
          setSpcData(dataspc.data);
          let getProcess = res.data.processes.filter((item: any) => {
            return item._id === dataspc.data.process_id;
          });
          getProcess = getProcess[0];
          const getSpec = getProcess?.specs.filter((item: any) => {
            return item._id === dataspc.data.spec_id;
          });
          setAutoCompleteProcess(getProcess);
          setSpec(getProcess.specs);
          setAutoCompleteSpec(getSpec[0]);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getData();
  }, [id]);

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;

    if (
      !autoCompleteSpec?.charecteristics ||
      !autoCompleteProcess?.process_name
    ) {
      setAlert(true);
      return;
    }

    spcData.specification = autoCompleteSpec?.charecteristics;
    spcData.control_product = autoCompleteSpec?.control_product;
    spcData.process_name = autoCompleteProcess?.process_name;
    spcData.part_id = partId || null;

    if (id) {
      await axios
        .put(`/api/spcRoute/${id}`, spcData)
        .then((res) => {
          window.alert('succesfully submitted');
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/spcRoute', spcData)
        .then((res) => {
          navigate(
            `/common/createEditSpc/${partId}/${res.data.id}?part_name=${partName}&part_number=${partNumber}`
          );
          window.alert('succesfully submitted');
        })
        .catch((err) => console.log(err));
    }
  };

  const viewSpcSheet = async () => {
    if (
      !autoCompleteSpec?.charecteristics ||
      !autoCompleteProcess?.process_name
    ) {
      setAlert(true);
      return;
    }

    if (!id) {
      window.alert('please submitt the document first');
      return;
    }
    setAlertforSubmmit(true);
    setLoader(true);
    axios
      .post(`/api/spcRoute/viewSheet/${id}`, {
        part_name: partName,
        part_no: partNumber,
        spec: autoCompleteSpec?.charecteristics,
        control_product: autoCompleteSpec?.control_product,
        operation: autoCompleteProcess?.process_name,
      })
      .then((res) => {
        if (res.status === 200)
          window.open(
            'https://docs.google.com/spreadsheets/d/1JD7nLzcHlMx491YRdyUycKUEJKxLxpNukvzNC_RWZzw/edit?usp=sharing ',
            '_blank'
          );
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  return (
    <Box>
      <Loader loader={loader} />
      <Box>
        <ChildHeader text="STATISTICAL PROCESS CONTROL STUDY">
          <CustomButton
            sx={{
              margin: '10px',
              backgroundColor: '#f8f9fa',
              color: '#343a40',
            }}
            icon={<VisibilityIcon sx={{ color: '#343a40' }} />}
            disabled={false}
            size="large"
            onClick={() => viewSpcSheet()}
          >
            View SPC
          </CustomButton>
          <SubmitButton
            label="Sumbit"
            onClick={() => {
              handleSumbit();
            }}
          />
          <CloseButton
            label="Close"
            onClick={async () => {
              const ans = await confirmClose();
              if (!ans) return;
              navigate(-1);
            }}
          />
        </ChildHeader>
        <PartDetailsHeader
          partName={partName || ''}
          partNumber={partNumber || ''}
        />

        <Box sx={{ flexGrow: 1, width: '98%', margin: '2rem auto' }}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <AutocompleteMuiCustom
                id={'process_name'}
                label="OPERATION"
                option_name={'process_name'}
                value={autoCompleteProcess}
                arrayofObj={process}
                onChange={(e, value) => {
                  value?.process_name
                    ? (spcData.process_id = value._id)
                    : (spcData.process_id = '');
                  setAutoCompleteProcess(value);
                  value?.specs ? setSpec(value.specs) : setSpec([]);
                  setAutoCompleteSpec('');
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <AutocompleteMuiCustom
                id={'charecteristics'}
                label={'SPECIFIC. / PARAM'}
                option_name={'charecteristics'}
                value={autoCompleteSpec}
                arrayofObj={spec}
                onChange={(e, value) => {
                  value?._id
                    ? (spcData.spec_id = value._id)
                    : (spcData.spec_id = '');
                  setAutoCompleteSpec(value);
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <InputMuiCustom
                type="string"
                name="part_name"
                variant={'outlined'}
                placeholder={'Tolerance'}
                value={autoCompleteSpec?.control_product}
                disabled={true}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 0.2 }}>
            <Grid item xs={3}>
              <InputMuiCustom
                variant={'outlined'}
                type="text"
                name="instrument"
                placeholder={'Instrument'}
                onChange={(e) => {
                  spcData.instrument = e.target.value;
                  setRender((prev) => !prev);
                }}
                value={spcData.instrument}
              />
            </Grid>

            <Grid item xs={3}>
              <InputMuiCustom
                variant={'outlined'}
                type="text"
                name="l_count"
                placeholder={`L.COUNT`}
                onChange={(e) => {
                  spcData.l_count = e.target.value;
                  setRender((prev) => !prev);
                }}
                value={spcData.l_count}
              />
            </Grid>
            <Grid item xs={3}>
              <InputMuiCustom
                variant={'outlined'}
                type="number"
                name="no_of_dec"
                placeholder={`No.Of Decimals`}
                onChange={(e) => {
                  spcData.no_of_dec = +e.target.value;
                  setRender((prev) => !prev);
                }}
                value={spcData.no_of_dec}
              />
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ flexGrow: 1, width: '98%', margin: '2rem auto' }}>
          <Grid container spacing={2}>
            {spcData?.spc_array?.length > 0 &&
              spcData?.spc_array.map((_, sopidx) => (
                <Grid item xs={1.2} key={`${sopidx}+uud`}>
                  <InputMuiCustom
                    type="number"
                    name="part_name"
                    placeholder={`${sopidx + 1}`}
                    onChange={(e) => {
                      spcData.spc_array[sopidx] = +e.target.value;
                      setRender((prev) => !prev);
                    }}
                    value={spcData.spc_array[sopidx]}
                  />
                </Grid>
              ))}
          </Grid>
        </Box>
        <AlertMui
          alert={alert}
          message={'operarion and specification are empty'}
          setAlert={setAlert}
          severity={'warning'}
        />
        <AlertMui
          alert={alertforSubmmit}
          message={'Remember to submit any changes to see the updated sheet.'}
          setAlert={setAlertforSubmmit}
          severity={'info'}
        />
      </Box>
      <DialogClose isSubmitContent={false} />
      <DialogSubmit isSubmitContent={false} />
    </Box>
  );
};

export default CreateEdit;
