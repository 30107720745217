import React, { useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { StatePagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import formatDate from '../../../components/common/formatDate';

const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'Training Topic', rowspan: 1, colspan: 1 },
    { text: 'Date of Training', rowspan: 1, colspan: 1 },
  ],
];

const TrainingTopicList = ({
  listId,
  setIsModal,
}: {
  listId: any;
  setIsModal: any;
}) => {
  const [data, setData] = useState<any[]>([]);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(1);

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/tniTrainigPlanRoute/getAllTrainingLog/${listId._id}?page=${page}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useDebounce(fetchData, 500, [page]);

  return (
    <>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.a1}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.doneDate)}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <StatePagination page={page} totalPage={totalPages} setPage={setPage} />
    </>
  );
};
export default TrainingTopicList;
