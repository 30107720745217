import { GrUser } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { TbReportAnalytics } from 'react-icons/tb';
import { GrDatabase } from 'react-icons/gr';
import { SiGooglesheets } from 'react-icons/si';
import { TbFileSpreadsheet, TbFaceIdError } from 'react-icons/tb';
import { RiDeleteBin4Line } from 'react-icons/ri';
import { TbNotebook } from 'react-icons/tb';
import { MdPlaylistAdd } from 'react-icons/md';
import { CgPlayListSearch } from 'react-icons/cg';
import { ImStatsDots } from 'react-icons/im';
import { BsCardChecklist, BsFuelPumpDieselFill } from 'react-icons/bs';
import { BsUiChecks } from 'react-icons/bs';
import { SiTestcafe } from 'react-icons/si';
import { MdAir } from 'react-icons/md';
import { MdRoomPreferences } from 'react-icons/md';
import { GiWaterTank } from 'react-icons/gi';

import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { CiBarcode } from 'react-icons/ci';
import { AiOutlineLineChart } from 'react-icons/ai';
import { TbAirConditioning } from 'react-icons/tb';
import { GiWaterPolo } from 'react-icons/gi';
import { GiSonicBoom } from 'react-icons/gi';
import { MdOutlineOilBarrel } from 'react-icons/md';
import { FaFileInvoice } from 'react-icons/fa';
import { VscCombine } from 'react-icons/vsc';
import { BsJournalBookmarkFill } from 'react-icons/bs';

const tiles = [
  {
    sNo: 1,
    link: '/common/qualityPirList',
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'PIR',
    span: 'REPORT',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/qualityDepartmentSetUpApprovalList',
    isLink: true,
    icon: <TbReportAnalytics size="5rem" color="#003566" />,
    p: 'SET - UP APPROVAL',
    span: 'REPORT',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/qualityDepartmentHourlyMonitoringSheetList',
    isLink: true,
    icon: <GrDatabase size="5rem" color="#003566" />,
    p: 'HOURLY MONITORING',
    span: 'SHEET',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/qualityDepartmentDailyRejMonitoringSheetList',
    isLink: true,
    icon: <SiGooglesheets size="5rem" color="#003566" />,
    p: 'DAILY REJECTION MONITORING',
    span: 'SHEET',
    isIcon: true,
  },
  {
    sNo: 6,
    link: '/admin/barcodeList',
    isLink: true,
    icon: <CiBarcode size="5rem" color="#343a40" />,
    p: 'BARCODE',
    span: 'APPROVAL SHEET',
    isIcon: true,
  },
  {
    sNo: 5,
    link: '/common/qualityDepartmentAbnSitCheckSheetList',
    isLink: true,
    icon: <TbFileSpreadsheet size="5rem" color="#003566" />,
    p: 'ABNORMAL SITUATION',
    span: 'CHECK SHEET',
    isIcon: true,
  },
  {
    sNo: 6,
    link: '/common/qualityDepartmentDailyBinCleaningCheckSheetList',
    isLink: true,
    icon: <RiDeleteBin4Line size="5rem" color="#a12222" />,
    p: 'DAILY BIN CLEANING',
    span: 'CHECK SHEET',
    isIcon: true,
  },
  {
    sNo: 7,
    link: '/common/qualityDepartmentListOfParts',
    isLink: true,
    icon: <MdPlaylistAdd size="5rem" color="#003566" />,
    p: 'LIST OF',
    span: 'PART',
    isIcon: true,
  },
  {
    sNo: 8,
    link: '/common/pallMachineCheckSheetList',
    isLink: true,
    icon: <CgPlayListSearch size="5rem" color="#003566" />,
    p: 'PALL MACHINE',
    span: 'CHECK SHEET',
    isIcon: true,
  },
  {
    sNo: 8,
    link: '/common/qualityDepartmentPokaYokeCheckSheetList',
    isLink: true,
    icon: <CgPlayListSearch size="5rem" color="#003566" />,
    p: 'POKA YOKA ',
    span: 'CHECK SHEET',
    isIcon: true,
  },
  {
    sNo: 8,
    link: '/common/leicaHome',
    isLink: true,
    icon: <FaFileInvoice size="5rem" color="#003566" />,
    p: 'LEICA',
    span: 'LAB',
    isIcon: true,
  },
  // spc different routing for iai
  {
    sNo: 9,
    link: '/common/qualityDept/spc/partlist',
    isLink: true,
    icon: <ImStatsDots size="5rem" color="#003566" />,
    p: 'SPC',
    span: 'REPORT',
    isIcon: true,
  },
  {
    sNo: 9,
    link: '/common/reworkRegisterHome',
    isLink: true,
    icon: <TbNotebook size="5rem" color="#a12222" />,
    p: 'REWORK',
    span: 'REGISTER',
    isIcon: true,
  },
  // rejection note common for all
  {
    sNo: 9,
    link: '/common/rejectionNoteHome',
    isLink: true,
    icon: <TbNotebook size="5rem" color="#a12222" />,
    p: 'REJECTION',
    span: 'NOTE',
    isIcon: true,
  },
  {
    sNo: 27,
    link: '/common/qualitydepartment/dashboard',
    isLink: true,
    icon: <MdRoomPreferences size="5rem" color="#003566" />,
    p: 'REJECTION NOTE',
    span: 'DASHBOARD',
    isIcon: true,
  },
  {
    sNo: 10,
    link: `/common/qualityDepartmentUpload/?documentType=luxLevelMonitoring&heading=LUX LEVEL MONITORING SHEET`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'LUX LEVEL',
    span: 'MONITORING SHEET',
    isIcon: true,
  },
  {
    sNo: 11,
    link: `/common/qualityDepartmentUpload/?documentType=oRingMachineCheckSheet&heading=O-RING MACHINE CHECK SHEET`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'O-RING MACHINE',
    span: 'CHECK SHEET',
    isIcon: true,
  },
  {
    sNo: 11,
    link: `/common/qualityDepartmentUpload/?documentType=primaryAndFinalPacking&heading=PRIMARY & FINAL PACKING CHECK SHEET`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'PRIMARY & FINAL',
    span: 'PACKING CHECK SHEET',
    isIcon: true,
  },
  {
    sNo: 12,
    link: `/common/qualityDepartmentUpload/?documentType=airDecayCheckSheet&heading=AIR DECAY CHECK SHEET`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'AIR DECAY',
    span: 'CHECK SHEET',
    isIcon: true,
  },
  {
    sNo: 13,
    link: `/common/qualityDepartmentUpload/?documentType=airLeakTestingMachine&heading=AIR LEAK TESTING MACHINE`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'AIR LEAK',
    span: 'TESTING MACHINE',
    isIcon: true,
  },
  {
    sNo: 14,
    link: `/common/qualityDepartmentUpload/?documentType=blockageTestingMachine&heading=BLOCKAGE TESTING MACHINE`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'BLOCKAGE',
    span: 'TESTING MACHINE',
    isIcon: true,
  },
  {
    sNo: 15,
    link: `/common/qualityDepartmentUpload/?documentType=clampingMachine&heading=CLAMPING MACHINE`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'CLAMPING',
    span: 'MACHINE',
    isIcon: true,
  },
  {
    sNo: 16,
    link: `/common/qualityDepartmentUpload/?documentType=feruleFittingMachine&heading=FERULE FITTING MACHINE`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'FERULE FITTING',
    span: 'MACHINE',
    isIcon: true,
  },
  {
    sNo: 17,
    link: `/common/qualityDepartmentUpload/?documentType=highPressureLeakageTesting&heading=HIGH PRESSURE LEAKAGE TESTING MACHINE`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'HIGH LEAKAGE',
    span: 'TESTING MACHINE',
    isIcon: true,
  },
  {
    sNo: 18,
    link: `/common/qualityDepartmentUpload/?documentType=lazerMarkingMachine&heading=LAZER MARKING MACHINE`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'LAZER MARKING',
    span: 'MACHINE',
    isIcon: true,
  },
  {
    sNo: 19,
    link: `/common/qualityDepartmentUpload/?documentType=flushingMachine&heading=FLUSHING MACHINE`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'FLUSHING',
    span: 'MACHINE',
    isIcon: true,
  },
  {
    sNo: 20,
    link: `/common/qualityDepartmentUpload/?documentType=ovenChildPart&heading=OVEN- CHILD PART`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'OVEN -',
    span: 'CHILD PART',
    isIcon: true,
  },
  {
    sNo: 21,
    link: `/common/qualityDepartmentUpload/?documentType=ovenAssembly&heading=OVEN- ASSEMBLY`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'OVEN -',
    span: 'ASSEMBLY',
    isIcon: true,
  },
  {
    sNo: 21,
    link: `/common/qualityDepartmentUpload/?documentType=ultraSonicMachinChildPart&heading=ULTRASONIC MACHINE - CHILD PART`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'ULTRASONIC MACHINE -',
    span: 'CHILD PART',
    isIcon: true,
  },
  {
    sNo: 22,
    link: `/common/qualityDepartmentUpload/?documentType=ultraSonicMachinAssembly&heading=ULTRASONIC MACHINE-ASSEMBLY`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'ULTRASONIC MACHINE -',
    span: 'ASSEMBLY',
    isIcon: true,
  },
  {
    sNo: 23,
    link: `/common/qualityDepartmentUpload/?documentType=wiUpload&heading=WI UPLOAD`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'WI',
    span: 'UPLOAD',
    isIcon: true,
  },
  {
    sNo: 25,
    link: '/common/qualitydepartment/PPECheckSheet',
    isLink: true,
    icon: <BsUiChecks size="5rem" color="#003566" />,
    p: 'PPE',
    span: 'CHECK SHEET',
    isIcon: true,
  },
  {
    sNo: 24,
    link: '/common/waterChangeFrequency',
    isLink: true,
    icon: <GiWaterTank size="5rem" color="#1864ab" />,
    p: 'Water Change',
    span: 'Frequency',
    isIcon: true,
  },
  {
    sNo: 24,
    link: '/common/ultraSonicWaterChangeCheckSheet',
    isLink: true,
    icon: <GiSonicBoom size="5rem" color="#1864ab" />,
    p: 'Ultra Sonic Water',
    span: 'Change Check Sheet',
    isIcon: true,
  },
  {
    sNo: 24,
    link: '/common/flushingOilChangeCheckSheet',
    isLink: true,
    icon: <MdOutlineOilBarrel size="5rem" color="#1864ab" />,
    p: 'Flushing Oil',
    span: 'Change Check Sheet',
    isIcon: true,
  },
  {
    sNo: 24,
    link: '/common/airdecayChecksheet',
    isLink: true,
    icon: <TbAirConditioning size="5rem" color="#1864ab" />,
    p: 'Leakage Testing',
    span: 'Air Decay Validation',
    isIcon: true,
  },
  {
    sNo: 24,
    link: '/common/airUnderWaterChecksheet',
    isLink: true,
    icon: <GiWaterPolo size="5rem" color="#1864ab" />,
    p: 'Leakage Testing',
    span: 'Air Under Water Validation',
    isIcon: true,
  },
  {
    sNo: 23,
    link: `/common/processWiseWiUpload`,
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#003566" />,
    p: 'Process Wise WI',
    span: 'UPLOAD',
    isIcon: true,
  },
  {
    sNo: 23,
    link: `/common/qualityDefects`,
    isLink: true,
    icon: <TbFaceIdError size="5rem" color="#a12222" />,
    p: 'Master List Of',
    span: 'Quality Defects',
    isIcon: true,
  },
  {
    sNo: 23,
    link: `/common/amgRejectionNote`,
    isLink: true,
    icon: <VscCombine size="5rem" color="#a12222" />,
    p: 'AMG Rejection Note',
    span: '',
    isIcon: true,
  },
  {
    sNo: 27,
    link: '/common/qualitydepartment/amgRejectionNoteDashboard',
    isLink: true,
    icon: <MdRoomPreferences size="5rem" color="#003566" />,
    p: 'AMG REJECTION NOTE',
    span: 'DASHBOARD',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/cmmLogBookList',
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="#343a40" />,
    p: 'CMM Log',
    span: 'Book',
    isIcon: true,
  },
];

const QualityDeparmentHome = () => {
  return (
    <>
      <ChildHeader text="QUALITY DEPARTMENT" />
      <RenderTiles tilesObj={tiles} />
    </>
  );
};

export default QualityDeparmentHome;
