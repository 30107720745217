import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import lodash, { forEach } from 'lodash';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import { processes, processesTwo } from './helpers/initialState';
import useConfirm from '../../../components/common/useConfirm';
import { Paper, Tab, Tabs } from '@mui/material';
import InputMuiCustom from '../../../components/common/inputs/InputMuiCustom';

const View = () => {
  const [moduleData, setModuleData] = useState<any>({
    min_qty: 0,
    max_qty: 0,
    total_diesel_received: 0,
    total_diesel_consumed: 0,
    total_diesel_remaining: 0,
    processes: Array.from({ length: 1 }, () => ({ ...processes[0] })),
    processesTwo: Array.from({ length: 1 }, () => ({ ...processesTwo[0] })),
  });
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const navigate = useNavigate();
  const annual_month = useQuery('annual_date') || '';
  const isView = useQuery('isView') === 'true' ? true : false;
  const [tabValue, setTabValue] = useState(0);

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );

  useEffect(() => {
    axios
      .get(`/api/dieselMonitoringSheet/monthly?annualMonth=${annual_month}`)
      .then((res) => {
        if (res.data) setModuleData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render]);

  useEffect(() => {
    if (moduleData?._id) {
      axios
        .get(`/api/dieselMonitoringSheet/${moduleData?._id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [moduleData?._id]);

  const handleSumbit = async () => {
    if (moduleData?._id) {
      await axios
        .put(`/api/dieselMonitoringSheet/${moduleData?._id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
            setRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    } else {
      if (!annual_month) {
        return alert('Please pick annual year?');
      }
      moduleData.annual_month = annual_month;
      await axios
        .post('/api/dieselMonitoringSheet', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            setRender((prev) => !prev);
            // navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    if (tabValue === 0) data.processes.splice(rowIdx, 1);
    if (tabValue === 1) data.processesTwo.splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <>
      <Box>
        <ChildHeader text={'Diesel Consumption Monitoring Sheet'}>
          <Paper elevation={2}></Paper>
          {!isView && (
            <>
              {tabValue === 0 && (
                <CustomButton
                  sx={{
                    backgroundColor: '#1c7ed6',
                  }}
                  onClick={async () => {
                    const ans = await confirmAddRow();
                    if (!ans) return;
                    Array(1)
                      .fill(null)
                      .forEach(() => {
                        moduleData.processes.push(
                          lodash.cloneDeep(processes[0])
                        );
                      });
                  }}
                >
                  ADD ROWS
                </CustomButton>
              )}
              {tabValue === 1 && (
                <CustomButton
                  sx={{
                    backgroundColor: '#1c7ed6',
                  }}
                  onClick={async () => {
                    const ans = await confirmAddRow();
                    if (!ans) return;
                    Array(1)
                      .fill(null)
                      .forEach(() => {
                        moduleData.processesTwo.push(
                          lodash.cloneDeep(processesTwo[0])
                        );
                      });
                  }}
                >
                  ADD ROWS
                </CustomButton>
              )}
              <SubmitButton
                label={moduleData?._id ? `UPDATE` : `SUBMIT`}
                onClick={() => handleSumbit()}
              />
            </>
          )}
        </ChildHeader>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          scrollButtons="auto"
          textColor="secondary"
          variant="fullWidth"
        >
          <Tab label="Received Quantity" />
          <Tab label="Consumed Quantity" />
        </Tabs>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handleSumbit,
          handelDeleteRows,
          isOpenPopup,
          setIsOpenPopup,
          tabValue,
        }}
      />
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
    </>
  );
};

export default View;
