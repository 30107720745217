import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    part_id: null,
    time_from: null,
    time_to: null,
    operation: '',
    total_qty: null,
    ok_qty: null,
    rej_qty_defect_wise: [null, null, null, null, null, null, null, null, null],
    any_other_obs: '',
    operations:[],
    action_taken: '',
    remarks: '',
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  hourlyMonitoring_date: moment(),
  shift: '',
  machine_id: null,
  operator: '',
  rej_defect_name: ['', '', '', '', '', '', '', '', ''],
  area: '',
  processes: Array.from({ length: 10 }, () => ({
    ...lodash.cloneDeep(processes[0]),
  })),
};
