import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  area: any;
  category: any;
  customer: any;
  frequency: any;
}

const TController = ({
  moduleData,
  isView,
  area,
  category,
  customer,
  frequency,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{ moduleData, isView, area, category, customer, frequency }}
        />
        <TBody {...{ moduleData, isView }} />
        <TFoot {...{ moduleData, isView, category, area }} />
      </RenderTable>
    </Box>
  );
};

export default TController;
