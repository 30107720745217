import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../components/ui';
import TBody from './TBody';
// import TFoot from './TFoot';
import THead from './THead';
import TBody1 from './TBody1';
import TBody2 from './TBody2';
import TBody3 from './TBody3';
import TBody4 from './TBody4';
interface IProps {
  moduleData: any;
  handelDeleteRows: any;
  isView: boolean;
}

const TController = ({ moduleData, isView, handelDeleteRows }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, isView }} />
        <TBody {...{ moduleData, isView, handelDeleteRows }} />
        <TBody1 {...{ moduleData, isView, handelDeleteRows }} />
        <TBody2 {...{ moduleData, isView, handelDeleteRows }} />
        <TBody3 {...{ moduleData, isView, handelDeleteRows }} />
        <TBody4 {...{ moduleData, isView, handelDeleteRows }} />
        {/* <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        /> */}
      </RenderTable>
    </Box>
  );
};

export default TController;
