import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import moment from 'moment';

import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
  ListIconButton,
} from '../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import { IData } from './helpers/interfaces';
import ViewFile from './components/ViewFile';
import formatDate from '../../components/common/formatDate';
import { BsFileEarmarkTextFill } from 'react-icons/bs';
import TextAreaInput from '../../components/common/TextAreaInput';
import DatePickerMui from '../../components/common/DatePickerMui';

const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'USER ID', rowspan: 1, colspan: 1 },
    { text: 'DATE OF COMPLAINT', rowspan: 1, colspan: 1 },
    { text: 'PROBLEM CLAUSE/DOCUMENT', rowspan: 1, colspan: 1 },
    // { text: 'URL ADDRESS', rowspan: 1, colspan: 1 },
    { text: 'COMPLAINT DESCRIPTION', rowspan: 1, colspan: 1 },
    { text: 'MUSHIN REMARKS', rowspan: 1, colspan: 1 },
    { text: 'CLOSED BY', rowspan: 1, colspan: 1 },

    { text: 'CLOSURE DATE', rowspan: 1, colspan: 1 },
    { text: 'CLOSURE STATUS', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const sortOptions = [
  { value: 'user_id', name: 'A-Z' },
  { value: 'createdAt', name: 'Most Recent First' },
  { value: 'createdAt_old', name: 'Oldest First' },
];
const filterOptions = [
  { value: 'OPEN', name: 'OPEN' },
  { value: 'WORK IN PROGRESS', name: 'WORK IN PROGRESS' },
  { value: 'CLOSED', name: 'CLOSED' },
];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [complaintId, setComplaintId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [isDisable, setDisable] = useState(false);
  const search = useQuery('search') || '';
  const sort = useQuery('sort') || 'createdAt';
  const filter = useQuery('filter') || '';
  const page = useQuery('page') || 1;

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?',
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/mushinHelpDesk/?page=${page}&sortBy=${sort}&filterBy=${filter}&search=${search}&perPage=${perPage}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomer = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/mushinHelpDesk/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, sort, filter]);

  return (
    <>
      <ChildHeader text="MUSHIN HELP DESK">
        <AddButton onClick={() => setIsModal(true)} label="ADD COMPLAINT" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search Problem Clause/Document" />
        <Stack direction={'row'} gap={2} flex="1">
          <FilterBy sx={{ width: 130 }} options={filterOptions} />
          <FilterBy
            name="sort"
            label="Sort By"
            sx={{ width: 130 }}
            options={sortOptions}
          />
        </Stack>
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IData, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.user_id}</TableCell>
                  <TableCell align="center">
                    {item?.date_of_complaint}
                  </TableCell>
                  <TableCell align="center">{item?.problem}</TableCell>
                  {/* <TableCell align="center">{item?.url}</TableCell> */}
                  <TableCell align="center">
                    <TextAreaInput disabled={true} value={item?.description} />
                  </TableCell>
                  <TableCell align="center">
                    <TextAreaInput
                      disabled={true}
                      value={item?.mushin_remarks}
                    />
                  </TableCell>
                  <TableCell align="center">{item?.closed_by}</TableCell>

                  <TableCell align="center">{item?.closure_date}</TableCell>
                  <TableCell
                    style={{
                      background:
                        item?.status === 'CLOSED'
                          ? 'green'
                          : item?.status === 'OPEN'
                            ? 'red'
                            : item?.status === 'UNDER REVIEW'
                              ? 'orange'
                              : 'yellow',
                    }}
                    align="center"
                  >
                    {item?.status}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <ListIconButton
                        tooltipTitle="FILE LIST"
                        onClick={() => {
                          setId(item._id || null);
                          setIsFileOpenPopup(true);
                        }}
                      />
                      <ViewIconButton
                        tooltipTitle="VIEW"
                        onClick={() => {
                          setComplaintId(item?._id || '');
                          setDisable(true);
                          setIsModal(true);
                        }}
                      />
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setComplaintId(item?._id || '');
                          setDisable(false);
                          setIsModal(true);
                        }}
                      />

                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteCustomer(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setComplaintId(null);
          setIsModal(false);
        }}
        title="COMPLAINT REGISTER"
      >
        <Create id={complaintId} setIsModal={setIsModal} disable={isDisable} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
