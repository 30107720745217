import { Stack } from '@mui/material';
import {
  CustomButton,
  UpdateButton,
} from '../../../components/common/button/CustomButtons';
import { PersitedDatePicker } from '../../../components/common';
import { useQuery } from '../../../hooks/UseQuery';
import lodash from 'lodash';
const HeaderCreate = ({ handleUpdate, setModuleData }: any) => {
  const date = useQuery('date');

  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          pl: 2,
          pb: 1,
          pt: 2,
          gap: 2,
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: 'start',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <PersitedDatePicker
            label="Search By Year"
            views={['year', 'month']}
          />
        </Stack>

        <Stack
          direction="row"
          sx={{
            gap: 2,
          }}
        >
          <CustomButton
            sx={{
              backgroundColor: '#1c7ed6',
            }}
            onClick={() => {
              setModuleData((prev: any) => {
                const value = [
                  ...prev,
                  {
                    month: date && new Date(date).getMonth() + 1,
                    year: date && new Date(date).getFullYear(),
                    date: new Date(),
                    part_id: null,
                    rej_qty_rejection: null,
                    rejection_details: Array.from({ length: 10 }, () =>
                      lodash.cloneDeep({
                        reason: null,
                        stage: '',
                        rej_qty: null,
                        action_plan: '',
                      })
                    ),
                  },
                ];
                return value;
              });
            }}
          >
            ADD ROWS
          </CustomButton>

          <UpdateButton
            label="update"
            sx={{ mr: '37px', fontSize: '2rem' }}
            size="large"
            onClick={() => {
              handleUpdate();
            }}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default HeaderCreate;
