import React, { useEffect, useState } from 'react';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import useConfirm from '../../../../components/common/useConfirm';
import { moduleConstant } from './helpers/constant';

const Create = ({
  id,
  setIsModal,
  setModuleId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setModuleId: any;
}) => {
  const [moduleData, setModuleData] = useState<IData>({
    designation: '',
  });
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Supplier' : 'Create Supplier'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/${moduleConstant.api}/${id}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleModuleData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/${moduleConstant.api}/${id}`, moduleData)
        .then((res) => {
          setIsModal(false);
          setModuleId(null);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post(`/api/${moduleConstant.api}`, moduleData)
        .then((res) => {
          setIsModal(false);
          setModuleId(null);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'designation'}
        name={'designation'}
        html_for={'designation'}
        label_name={'Designation :'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.designation}
        onChange={handleModuleData}
      />

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
