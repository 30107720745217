import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../hooks/UseQuery';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
interface IProps {
  moduleData: any;
  isView: boolean;
  setRender: any;
}

const getYearRange = (currentDate: any) => {
  if (!currentDate) return;
  const year = new Date(currentDate).getFullYear();
  return `${year} - ${year + 1}`;
};
const THead = ({ moduleData, isView, setRender }: IProps) => {
  const date = new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  const yearRange = getYearRange(new Date(moduleData?.date_range));
  return (
    <thead>
      <TableCompHead
        colSpan={34 + createConstant.extracolSpan}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading={`${createConstant.theadHeader} (YEAR : ${yearRange})`}
      />
      <tr>
        <th colSpan={16} rowSpan={2} style={{ background: '#da77f2' }}>
          <h1>KRA DASHBOARD</h1>
        </th>
        {[0, 1, 2, 3, 4, 5, 6].map((data) => {
          return (
            <th colSpan={3}>
              <TableInput
                isView={false}
                style={{
                  height: '80px',
                  textAlign: 'center',
                  paddingLeft: '10px',
                }}
                name=""
                type="number"
                value={moduleData?.targets?.[data] || 0}
                onChange={(e: any) => {
                  moduleData.targets[data] = e.target.value;
                  setRender((prev: any) => !prev);
                }}
              />
            </th>
          );
        })}
      </tr>
      <tr>
        {/* <th colSpan={16}></th> */}
        {['YTD', 'Q1', 'Q2', 'Q3', 'Q4', 'H1', 'H2'].map((el, index) => (
          <th
            key={`${index}_headcol`}
            align="center"
            style={{ textAlign: 'center', ...style2, background: '#20c997' }}
            colSpan={3}
          >
            {el}
          </th>
        ))}
      </tr>
      <tr>
        {['S. No.', 'Parameter', 'UOM', `FY ${yearRange} target`].map(
          (el, index) => (
            <th
              rowSpan={2}
              key={`${index}_head`}
              align="center"
              style={{
                textAlign: 'center',
                ...style1,
                minWidth: `${
                  (el === 'INSTRUMENTS/GAUGE' && '250px') ||
                  (el === 'S. No.' && '50px') ||
                  style1.minWidth
                }`,
              }}
            >
              {el}
            </th>
          )
        )}

        {[
          'APR',
          'MAY',
          'JUN',
          'JUL',
          'AUG',
          'SEP',
          'OCT',
          'NOV',
          'DEC',
          'JAN',
          'FEB',
          'MAR',
        ].map((el, index) => (
          <th
            rowSpan={2}
            key={`${index}_headcol`}
            align="center"
            style={{ textAlign: 'center', ...style2, minWidth: '100px' }}
          >
            {el}-{index > 8 ? +date + 1 : date}
          </th>
        ))}
        {/* 'Q3', 'Q4', 'Q5', 'Q6', 'H1', 'H2' */}
        {[
          'Target',
          'Avg. / Total',
          'Achievement',
          'Target',
          'Avg. / Total',
          'Achievement',
          'Target',
          'Avg. / Total',
          'Achievement',
          'Target',
          'Avg. / Total',
          'Achievement',
          'Target',
          'Avg. / Total',
          'Achievement',
          'Target',
          'Avg. / Total',
          'Achievement',
          'Target',
          'Avg. / Total',
          'Achievement',
        ].map((el, index) => (
          <th
            rowSpan={2}
            key={`${index}_headcol`}
            align="center"
            style={{ textAlign: 'center', ...style2, minWidth: '100px' }}
          >
            {el}
          </th>
        ))}
      </tr>
      {/* <th style={{ textAlign: 'center', ...style1 }}>Average</th>
      <th style={{ textAlign: 'center', ...style1 }}>Remarks</th> */}
    </thead>
  );
};

export default THead;
