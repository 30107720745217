import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import { initialState } from './helpers/initialState';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  // CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useAuth } from '../../../services/auth/AuthProvider';
import { useQuery } from '../../../hooks/UseQuery';
// import { processes } from './helpers/initialState';
// import PartTable from './components/view/PartTable';
// import ModalCustom from '../../../components/common/Modal';
import useConfirm from '../../../components/common/useConfirm';

const View = () => {
  const [moduleData, setModuleData] = useState<IData>(
    lodash.cloneDeep(initialState)
  );
  // const [render, setRender] = useState(false);
  const navigate = useNavigate();
  const { state } = useAuth();
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  // const [isOpenPopup, setIsOpenPopup] = useState(false);

  // const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
  //   'DELETE',
  //   'Are you sure you want to delete Row?'
  // );
  // const [DialogAddRow, confirmAddRow] = useConfirm(
  //   'Add Row',
  //   'Are you sure you want to Add Row?'
  // );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?'
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/dailyBinCleaningCheckSheetQtyDept/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async (isSubmitted: boolean) => {
    const ans = await confirmSubmit();
    if (!ans) return;
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;
    if (id) {
      await axios
        .put(`/api/dailyBinCleaningCheckSheetQtyDept/${id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/dailyBinCleaningCheckSheetQtyDept', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  // const handelDeleteRows = async (rowIdx: number) => {
  //   const ans = await confirmDeleteRow();
  //   if (!ans) return;
  //   let data = { ...moduleData };
  //   data.processes.splice(rowIdx, 1);
  //   setModuleData(data);
  // };

  return (
    <>
      <Box>
        <ChildHeader text="DAILY BIN CLEANING CHECK SHEET">
          {!isView && (
            <>
              {/* <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  moduleData.processes.push(lodash.cloneDeep(processes[0]));
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton> */}
              {state.user?.userType === state.IS.Supervisor && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => handleSumbit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => handleSumbit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          // customerData,
          isView,
          handleSumbit,
          // handelDeleteRows,
          // isOpenPopup,
          // setIsOpenPopup,
          // setPartIdx,
        }}
      />

      {/* <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PartTable moduleData={moduleData.processes[partIdx]} />
      </ModalCustom> */}
      {/* <DialogDeleteRow isSubmitContent={false} /> */}
      {/* <DialogAddRow /> */}
      <DialogSubmit />
    </>
  );
};

export default View;
