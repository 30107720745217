import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import MenuItem from '@mui/material/MenuItem';
import { IData } from './Interface';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import SelectGridMui from '../../components/common/selectscustom/SelectGridMui';
import useConfirm from '../../components/common/useConfirm';
import GridSelect from '../../components/common/inputs/GridSelect';
import { SelectChangeEvent } from '@mui/material';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [machine, setMachine] = useState<IData>({});
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Machine' : 'Create Machine'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/machineRoutes/${id}`)
        .then((res) => {
          setMachine(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleSupplier(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setMachine((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/machineRoutes/${id}`, machine)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/machineRoutes', machine)
        .then((res) => {
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'machine_name'}
        name={'machine_name'}
        html_for={'machine_name'}
        label_name={'Machine Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.machine_name}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'model'}
        name={'model'}
        html_for={'model'}
        label_name={'Model:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.model}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'make'}
        name={'make'}
        html_for={'make'}
        label_name={'Make:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.make}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'machine_no'}
        name={'machine_no'}
        html_for={'machine_no'}
        label_name={'Machine No.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.machine_no}
        onChange={handleSupplier}
      />

      <GridInputs
        id={'year_of_built'}
        name={'year_of_built'}
        html_for={'year_of_built'}
        label_name={'Year of Built:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.year_of_built}
        onChange={handleSupplier}
      />

      <GridInputs
        id={'range_capacity'}
        name={'range_capacity'}
        html_for={'range_capacity'}
        label_name={'Range/Capacity:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.range_capacity}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'area'}
        name={'area'}
        html_for={'area'}
        label_name={'Area:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.area}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'power_load'}
        name={'power_load'}
        html_for={'power_load'}
        label_name={'Power Load:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.power_load}
        onChange={handleSupplier}
      />
      <GridInputs
        id={'numatic_load'}
        name={'numatic_load'}
        html_for={'numatic_load'}
        label_name={'Numatic Load:'}
        input_type={'text'}
        focusType={'onblur'}
        value={machine?.numatic_load}
        onChange={handleSupplier}
      />
      <GridSelect
        name="type"
        label_name="Type"
        html_for={'type'}
        value={machine?.type || ''}
        onChange={handleSupplier}
      >
        <MenuItem>NONE</MenuItem>
        <MenuItem value="CRITICAL">CRITICAL</MenuItem>
        <MenuItem value="NORMAL">NORMAL</MenuItem>
      </GridSelect>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
