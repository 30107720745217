import { useState } from 'react';

import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  // customerData: any;
  isView: boolean;
  handelDeleteRows: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TBody = ({
  moduleData,
  // customerData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <TBodyRow
              {...{
                moduleData,
                process,
                index,
                isView,
                handelDeleteRows,
                setIsOpenPopup,
                setPartIdx,
              }}
            />
          ))}
      </tbody>
    </>
  );
};

export default TBody;
