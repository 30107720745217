export const listConstant = {
  childHeader: 'Suplier PPM list',
  addButtonLable: 'New Suplier PPM',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW Suplier PPM',
};

export const createConstant = {
  childHeader: 'Supplier PPM',
  theadHeader: 'Supplier PPM',
  extracolSpan: 3,
};

export const checkListList = {
  childHeader: 'Fixture Validation STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'supplierPpmRoute',
  gettableDataurl: 'supplierPpmRoute/monthly',
  getPlanByid: 'supplierPpmRoute',
  createPlanByid: 'supplierPpmRoute',
  updatePlanByid: 'supplierPpmRoute',
  fileUpload: 'supplierPpmRoute/handleUpload',
  fileDeleteImage: 'supplierPpmRoute/handleDeleteImage',
};

export const tilesConstant = {
  childHeaderText: 'Supplier PPM',
};

export const navigationConstant = {
  createPlanSchedule: '/common/supplierPpmCreate',
};
 
