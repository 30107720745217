import lodash from 'lodash';
export const air_under_water = {
  main_data: [
    {
      contain: 'Cleanroom Temperature',
      what: '22 To 25 º C',
      where: 'मशीन पर',
      how: 'विजुअल',
      who: 'Supervisor',
      when: 'प्रतिदिन',
      checked_by: '',
      results: Array.from({ length: 31 }, () => ''),
      remarks: '',
    },
    {
      contain: 'Cleanrooom  Pressure In Pascle',
      what: '5-10 pa',
      where: 'मशीन पर',
      how: 'विजुअल',
      who: 'Supervisor',
      when: 'प्रतिदिन',
      checked_by: '',
      results: Array.from({ length: 31 }, () => ''),
      remarks: '',
    },
  ],

  second_data: [
    {
      name: 'Name of Operator',
      name_1: '',
      name_2: '',
      verified_name: 'Verified Signature',
      verified_sign_1: '',
      verified_sign_2: '',
      checked_by: 'Operator',
      results: Array.from({ length: 31 }, () => ''),
      remarks: '',
    },
    {
      name: 'Name of Supervisor',
      name_1: '',
      name_2: '',
      verified_name: 'Verified Signature',
      verified_sign_1: '',
      verified_sign_2: '',
      checked_by: 'Supervisor',
      results: Array.from({ length: 31 }, () => ''),
      remarks: '',
    },
  ],
  third_data: Array.from({ length: 5 }, (_) => ({
    ...lodash.cloneDeep({
      prob_date: new Date(),
      checking_of_problem: '',
      operator: '',
      maintenance_action: '',
      solved_problem_date: new Date(),
    }),
  })),
  revision_data: Array.from({ length: 5 }, (_) => ({
    ...lodash.cloneDeep({
      rev_date: new Date(),
      rev_no: '',
      desc: '',
      remarks: '',
    }),
  })),
};
