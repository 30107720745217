import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { TableCompHead } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import { moduleConstant } from '../constant';
type TextTransform = 'uppercase' | 'lowercase' | 'capitalize' | 'none';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
  textTransform: 'uppercase' as TextTransform,
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

interface IProps {
  isView: boolean;
  defects: any;
}

const THead = ({ defects, isView }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();
  // Define the common part of the header
  const commonHeaders = [
    { text: 'DATE', style: style1 },
    { text: 'PART NO.', style: style1 },
    { text: 'CUSTOMER', style: style1 },
    { text: 'CHECKED QTY.', style: style1 },
    { text: 'OK QTY .', style: style1 },
    { text: 'REJ. QTY', style: style1 },
    { text: '%age', style: style1 },
    // { text: 'ACTION PLAN', style: style1 },
    { text: 'REJECTION DETAIL', style: style1, colspan: 2 },
  ];

  // Add 'ACTION' header if not in view mode
  const headers = isView
    ? commonHeaders
    : [...commonHeaders, { text: 'ACTION', style: style1 }];

  const tableHeaderObj = [headers];
  // { text: 'CLEAN ROOM', style: style1 },
  /*!isView && { text: 'ACTION', style: style1 },*/

  return (
    <thead>
      <TableCompHead
        colSpan={9 + +(!isView && 1)}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading={`${moduleConstant?.heading} - ${year}`}
      />

      <TableHeaderHtmlCustom
        tableHeaderObj={tableHeaderObj}
        customStyle={{
          position: 'sticky',
          width: '100%',
          top: '-.5rem',
          border: '1px solid black',
          zIndex: '1',
          backgroundColor: '#38d9a9',
          color: 'black',
        }}
      />
    </thead>
  );
};

export default THead;
