import React, { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import formatDate from '../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {moduleData &&
        moduleData?.c?.map((process: any, index: number) =>
          process.full_row ? (
            <tr>
              <th>{process?.s_no}</th>
              <th colSpan={8} style={{ textAlign: 'left' }}>
                {process?.full_row}
              </th>
            </tr>
          ) : (
            <tr>
              <td>{process?.s_no}</td>
              <td style={{ minWidth: '300px', textAlign: 'center' }}>
                {process?.description}
              </td>
              <td style={{ minWidth: '200px', textAlign: 'center' }}>
                {process?.spec_mm}
              </td>
              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                {process?.measurement_equipments}
              </td>

              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="obs_1"
                  type="text"
                  value={process.obs_1}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="obs_2"
                  type="text"
                  value={process.obs_2}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="reference_std_doc"
                  type="text"
                  value={process.reference_std_doc}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="result"
                  type="text"
                  value={process.result}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '150px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            </tr>
          )
        )}
      <tr>
        <th colSpan={2}>Customer concern if Any:</th>
        <th colSpan={7} style={{ textAlign: 'left' }}>
          <TableInput
            isView={isView}
            name="s5"
            type="text"
            value={moduleData.s5}
            onChange={(e) => {
              moduleData.s5 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2}>Implemented Corrective Action:</th>
        <th colSpan={7} style={{ textAlign: 'left' }}>
          <TableInput
            isView={isView}
            name="s6"
            type="text"
            value={moduleData.s6}
            onChange={(e) => {
              moduleData.s6 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2}>Action implemented / going on (Yes/No):</th>
        <th colSpan={7} style={{ textAlign: 'left' }}>
          <TableInput
            isView={isView}
            name="s7"
            type="text"
            value={moduleData.s7}
            onChange={(e) => {
              moduleData.s7 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2}>Observations against corrective action:-</th>
        <th colSpan={7} style={{ textAlign: 'left' }}>
          <TableInput
            isView={isView}
            name="s8"
            type="text"
            value={moduleData.s8}
            onChange={(e) => {
              moduleData.s8 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2}>Auditor Sign.:-</th>
        <th colSpan={7} style={{ textAlign: 'left' }}>
          <TableInput
            isView={isView}
            name="s8"
            type="text"
            value={moduleData.s9}
            onChange={(e) => {
              moduleData.s9 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tbody>
  );
};

export default TBody;
