import moment from 'moment';

const months = [
  'JAN',
  'FEB',
  'MAR',
  'APR',
  'MAY',
  'JUN',
  'JUL',
  'AUG',
  'SEP',
  'OCT',
  'NOV',
  'DEC',
];

const getDailyProductionYearly = (year: any) => {
  return months.map((month) => ({
    month_year: `${month}-${new Date(year).getFullYear()}`,
  }));
};

const calculateDuration = (start: any, end: any) => {
  if (!start || !end) {
    return '0'; // Return zero duration if either input is null
  }
  const date_1 = moment(start);
  const date_2 = moment(end);
  // Check if time2 is earlier than or equal to time1
  if (date_2.isSameOrBefore(date_1)) {
    date_2.add(1, 'day'); // Add 1 day to time2 to make it later than time1
  }
  const duration = moment.duration(moment(date_2).diff(date_1));
  const hours = duration.hours();
  const minutes = duration.minutes();
  const formattedResult = `${hours} hr ${minutes} min`;
  return formattedResult;
};

export { getDailyProductionYearly, calculateDuration };
