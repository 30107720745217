import { useState } from 'react';

interface IProps {
  apiData: any;
  target: number;
}
const TBody = ({ apiData, target }: IProps) => {
  return (
    <tbody>
      {apiData &&
        apiData.length > 0 &&
        apiData?.map((item: any, index: number) => {
          const { vender_name, ppm_qty } = item;
          return (
            <tr>
              <td>{index + 1}</td>
              <td>{vender_name}</td>
              <td>{ppm_qty}</td>
              <td>{target}</td>
            </tr>
          );
        })}
    </tbody>
  );
};

export default TBody;
