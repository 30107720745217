import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { BiRegistered } from 'react-icons/bi';
const tiles = [
  // rejection note common for all
  {
    sNo: 1,
    link: '/common/amgReworkList',
    isLink: true,
    icon: <BiRegistered size="5rem" color="#a12222" />,
    p: 'AMG',
    span: 'REGISTER',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/jdReworkList',
    isLink: true,
    icon: <BiRegistered size="5rem" color="#a12222" />,
    p: 'JD',
    span: 'REGISTER',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/tubeReworkList',
    isLink: true,
    icon: <BiRegistered size="5rem" color="#a12222" />,
    p: 'TUBE',
    span: 'REGISTER',
    isIcon: true,
  },
];

const RejectionNoteHome = () => {
  return (
    <>
      <ChildHeader text="REWORK REGISTER" />
      <RenderTiles
        height="100vh"
        justify="start"
        columns="0"
        tilesObj={tiles}
      />
    </>
  );
};

export default RejectionNoteHome;
