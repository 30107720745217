import React, { useState } from 'react';
import TBodyRow2 from './TBodyRow2';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  // customerData: any;
  isView: boolean;
  // handelDeleteRows: any;
  // setIsOpenPopup: any;
  // setPartIdx: any;
}

const TBody2 = ({
  moduleData,
  // customerData,
  isView,
}: // handelDeleteRows,
// setIsOpenPopup,
// setPartIdx,
IProps) => {
  const checkSheetType = useQuery('checkSheetType');
  return (
    <>
      <tbody>
        <tr style={{ height: '50px' }}>
          <td colSpan={40}></td>
        </tr>
        {moduleData &&
          checkSheetType &&
          moduleData?.[checkSheetType]?.second_data?.map(
            (process: any, index: number) => (
              <React.Fragment key={index + 1 + 'process'}>
                <TBodyRow2
                  {...{
                    moduleData,
                    process,
                    index,
                    isView,
                    // handelDeleteRows,
                    // setIsOpenPopup,
                    // setPartIdx,
                  }}
                />
              </React.Fragment>
            )
          )}
      </tbody>
    </>
  );
};

export default TBody2;
