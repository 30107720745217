export const assembly = [
  {
    process_name: 'ULTRASONIC CLEANING OF CHILD PARTS',
    applicable_ppe: [
      {
        name: 'GOGGLE',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
  {
    process_name: 'ASSEMBLY',
    applicable_ppe: [
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
  {
    process_name: 'ORIENTATION',
    applicable_ppe: [
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
  {
    process_name: 'CLAMPING',
    applicable_ppe: [
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
  {
    process_name: 'PROFILE CHECKING',
    applicable_ppe: [
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
  {
    process_name: 'LOCKING & UNLOCKING',
    applicable_ppe: [
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
  {
    process_name: 'LEAKAGE TESTING ',
    applicable_ppe: [
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
  {
    process_name: 'FINAL PACKING',
    applicable_ppe: [
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
  {
    process_name: 'MATERIAL MOVEMENT',
    applicable_ppe: [
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
  {
    process_name: 'PALLETING',
    applicable_ppe: [
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
  {
    process_name: 'PALLETING LOADING & UNLOADING',
    applicable_ppe: [
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'SAFETY SHOES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
  {
    process_name: 'LEAICA LAB',
    applicable_ppe: [
      {
        name: 'HEAD CAP',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'SHOE COVER ',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'HAND GLOVES',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
      {
        name: 'APRON',
        results: Array.from({ length: 31 }, () => ''),
        remarks: '',
      },
    ],
  },
];
