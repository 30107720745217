import { AiFillTool, AiOutlinePartition } from 'react-icons/ai';
import { GoTools } from 'react-icons/go';
import { TbBuildingWarehouse } from 'react-icons/tb';
import ChildHeader from '../../components/ui/ChildHeader';
import { RenderTiles } from '../../components/common';
const tiles = [
  {
    sNo: 5,
    link: '/common/kraList',
    isLink: true,
    clauseNo: 4,
    icon: <TbBuildingWarehouse size="5rem" color="#a12222" />,
    p: 'KRA',
    span: 'REVIEW',
    isIcon: true,
  },
];

const Tiles = () => {
  return (
    <>
      <ChildHeader text={'KRA DASHBOARD'} />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0"
      />
    </>
  );
};

export default Tiles;
