import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import moment from 'moment';

import { SubmitButton } from '../../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../../components/common/FormControlMui';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import useConfirm from '../../../../components/common/useConfirm';
import ModalCustom from '../../../../components/common/Modal';
import PickStorePart from '../../storeSetup/components/PickStorePart';
import { Grid } from '@mui/material';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
interface Iprop {
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
}

interface IData {
  _id?: string;
  store_part_id?: any;
  partNumber?: string | null;
  date_of_issue?: Date;
  qty?: string;
}
const CreateDocument = ({ setIsOpenPopup, id, setId }: Iprop) => {
  const [data, setData] = useState<IData>({});
  const [render, setRender] = useState(false);
  const [isOpenPopupPart, setIsOpenPopupPart] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'DRAFT PART',
    `Are you sure you want to ${id ? 'update' : 'save'} this data?`,
  );

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/storeIssueRegister/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    if (!data.store_part_id) {
      return alert('please choose part fist?');
    }
    const ans = await confirmSubmit();
    if (!ans) return;

    if (id) {
      await axios
        .put(`/api/storeIssueRegister/${id}`, data)
        .then((res) => {
          setIsOpenPopup(false);
          setId(null);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .post('/api/storeIssueRegister', data)
        .then((res) => {
          setIsOpenPopup(false);
          setId(null);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Box width={900}>
        <FormControlMui handleSubmit={onSubmit}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton label={`${id ? 'update' : 'submit'}`} type="submit" />
          </div>
          <StyledField>
            <Grid
              container
              xs={12}
              direction="row"
              alignItems={'center'}
              sx={{
                background: '#f0f0f0',
                border: '1px solid grey',
                width: '300px',
                borderRadius: '5px',
              }}
            >
              <Grid item xs={10}>
                <p
                  style={{
                    fontSize: '1.2rem',
                    marginLeft: '0.5rem',
                  }}
                >
                  {typeof data?.store_part_id === 'object' &&
                  data?.store_part_id !== null
                    ? data?.store_part_id?.ri_part_id?.part_number
                    : data?.partNumber || ''}
                  {!data?.store_part_id && 'Pick Part Number'}
                </p>
              </Grid>

              {data.store_part_id ? (
                <Grid item xs={2}>
                  <RemoveIconButton
                    tooltipTitle="remove part"
                    onClick={() => {
                      data.store_part_id = null;
                      data.partNumber = null;
                      setRender((prev) => !prev);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <AddIconButton
                    tooltipTitle="add part"
                    onClick={() => {
                      setIsOpenPopupPart(true);
                    }}
                  />
                </Grid>
              )}
            </Grid>
            <InputMuiCustom
              type="number"
              name="qty"
              required
              placeholder="qty"
              onChange={handlePartData}
              value={data?.qty || ''}
            />
            <DatePickerMui
              label="Date of Issue"
              onChange={(date) => {
                data.date_of_issue = date;
                setRender((prev) => !prev);
              }}
              value={moment(data.date_of_issue || null)}
            />
          </StyledField>
        </FormControlMui>
      </Box>
      <ModalCustom
        title="STORE PART LIST"
        openModal={isOpenPopupPart}
        closeModal={() => {
          setIsOpenPopupPart(false);
        }}
      >
        <PickStorePart moduleData={data} />
      </ModalCustom>
      <DialogSubmit />
    </>
  );
};

export default CreateDocument;
