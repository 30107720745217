import React, { useState } from 'react';

import { TableInput } from '../../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import ObservationInput from './ObservationInput';

interface IProps {
  process: any;
  isView: any;
  handelDeleteRows: any;
  index: number;
}
const TBodyRow = ({ process, index, isView, handelDeleteRows }: IProps) => {
  const [rowRender, setRender] = useState(false);
  return (
    <tr>
      <td style={{ textAlign: 'center' }}>{index + 1}</td>
      <td style={{ minWidth: '120px' }}>{process?.location_ri}</td>
      <td style={{ minWidth: '200px' }}>{process?.specification}</td>
      {Array.from({ length: 4 }, (_, obsIndex) => (
        <React.Fragment key={process?.location_ri + obsIndex}>
          <ObservationInput {...{ process, setRender, isView, obsIndex }} />
        </React.Fragment>
      ))}
      <td
        style={{
          textAlign: 'center',
          textTransform: 'uppercase',
          ...(process?.result === 'ok' && { background: '#a4d1a4' }),
          ...(process?.result === 'ng' && { background: '#f7aeae' }),
          minWidth: '100px',
        }}
      >
        {process?.result}
      </td>
      <td style={{ textAlign: 'center' }} colSpan={isView ? 2 : 1}>
        <TableInput
          isView={isView}
          type="text"
          name="remarks"
          value={process?.remarks || null}
          onChange={(e) => {
            handleChangeModuleData(e, process);
            setRender((prev) => !prev);
          }}
        />
      </td>
      {!isView && (
        <td style={{ minWidth: '100px' }}>
          <DeleteIconButton
            tooltipTitle={'delete'}
            onClick={() => handelDeleteRows(index)}
          />
        </td>
      )}
    </tr>
  );
};

export default TBodyRow;
