import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Grid, MenuItem } from '@mui/material';
import { useAuth } from '../../../../services/auth/AuthProvider';
import CustomSelect from '../../../../components/common/SelectField';

const SelectCheckSheet = ({ link }: { link: string }) => {
  const [checkSheetType, setCheckSheetType] = useState('none');
  const navigate = useNavigate();
  const { state } = useAuth();
  const { user } = state;

  const checkSheetArr = [
    'none',
    'cleaning_room_export',
    'export',
    'assembly',
    'cleaning_room',
  ];

  return (
    <Grid container xs={12} columnGap={3} width={600} alignItems="center">
      <Grid item xs={8}>
        <CustomSelect
          label="select type"
          name="checkSheetType"
          value={checkSheetType}
          onChange={(e: any) => {
            setCheckSheetType(e.target.value);
          }}
        >
          {checkSheetArr?.map((item: any, index: number) => {
            return (
              <MenuItem
                key={index}
                value={item}
                style={{ textTransform: 'uppercase' }}
              >
                {item.replaceAll('_', ' ')}
              </MenuItem>
            );
          })}
        </CustomSelect>
      </Grid>
      <Grid item xs={2}>
        <Button
          type="button"
          size="large"
          variant="contained"
          color="success"
          onClick={() => {
            if (
              checkSheetType === 'none' ||
              checkSheetType === undefined ||
              checkSheetType === ' '
            ) {
              alert('Please select a check sheet type');
              return;
            }
            navigate(`${link}?checkSheetType=${checkSheetType}`);
          }}
        >
          OK
        </Button>
      </Grid>
    </Grid>
  );
};

export default SelectCheckSheet;
