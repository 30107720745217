import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';

// const shiftResult = {
//   true: '✓',
//   false: '⨯',
// };

interface IProps {
  moduleData: any;
  isView: boolean;
  process: any;
  index: number;
  // handelDeleteRows: any;
  // setIsOpenPopup: any;
  // setPartIdx: any;
}
const TBodyRow = ({
  moduleData,
  isView,
  process,
  index,
}: // handelDeleteRows,
// setIsOpenPopup,
// setPartIdx,
IProps) => {
  const [render, setRender] = useState(false);

  return (
    <React.Fragment key={index + 10.1 + 'heading '}>
      <tr>
        <th style={{ minWidth: '50px', textAlign: 'center' }}>{index + 1}</th>
        <td style={{ minWidth: '250px', textAlign: 'center' }}>
          {process?.contain}
        </td>
        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          {process?.what}
        </td>
        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          {process?.where}
        </td>
        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          {process?.how}
        </td>
        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          {process?.who}
        </td>
        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          {process?.when}
        </td>
        <td style={{ minWidth: '200px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="checked_by"
            type="text"
            value={process.checked_by}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
        {Array.from({ length: 31 }, (_, indx) => (
          <td
            style={{
              minWidth: '100px',
              textAlign: 'center',
              fontSize: '2rem',
            }}
            key={index + 2.2 + indx + 'after' + 111.33}
          >
            {isView ? (
              (process.results[indx] === 'true' && '✓') ||
              (process.results[indx] === 'false' && '⨯')
            ) : (
              <CustomSelect
                name="results[indx]"
                value={process.results[indx]}
                onChange={(e) => {
                  process.results[indx] = e.target.value;
                  setRender((prev) => !prev);
                }}
              >
                <MenuItem value="true">✓</MenuItem>
                <MenuItem value="false">⨯</MenuItem>
              </CustomSelect>
            )}
          </td>
        ))}

        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="remarks"
            type="text"
            value={process.remarks}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
    </React.Fragment>
  );
};

export default TBodyRow;
