import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { IModule } from '../../interface';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import useConfirm from '../../../../../components/common/useConfirm';

interface IProps {
  isView: boolean;
  moduleData: IModule;
  setModuleData: any;
}

const TFoot = ({ isView, moduleData, setModuleData }: IProps) => {
  const [render, setRender] = useState(false);
  const addRows = () => {
    const values = { ...moduleData };
    values.revision_summary.push({});
    setModuleData(values);
  };
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?',
  );
  const handelDeleteRows = async (index: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    moduleData.revision_summary.splice(index, 1);
    setRender((prev) => !prev);
  };
  return (
    <tfoot>
      <tr>
        <th colSpan={2} align="left">
          REFERENCE : IS : 2500 ( PART I & II )
        </th>
        <th colSpan={7}>
          <TableInput
            name="reference"
            type="text"
            value={moduleData.reference}
            onChange={(e) => {
              moduleData.reference = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          REMARKS
        </th>
        <th colSpan={7}>
          <TableInput
            name="remarks"
            type="text"
            value={moduleData.remarks}
            onChange={(e) => {
              moduleData.remarks = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          MOD. NO.
        </th>
        <th colSpan={2}></th>
        <th align="left">APPROVED BY:</th>
        <th align="left" colSpan={2}>
          <TableInput
            name="approved_by"
            type="text"
            value={moduleData.approved_by}
            onChange={(e) => {
              moduleData.approved_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left">APPROVED DATE:</th>
        <th align="left">
          {isView ? (
            formatDate(moduleData.approved_date || '')
          ) : (
            <DatePickerMui
              value={moment(moduleData.approved_date)}
              onChange={(date) => {
                moduleData.approved_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={2}>
          DATE
        </th>
        <th align="left" colSpan={2}>
          {isView ? (
            formatDate(moduleData.date || '')
          ) : (
            <DatePickerMui
              value={moment(moduleData.date)}
              onChange={(date) => {
                moduleData.date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th align="left">CHECKED BY:</th>
        <th align="left" colSpan={2}>
          <TableInput
            name="checked_by"
            type="text"
            value={moduleData.checked_by}
            onChange={(e) => {
              moduleData.checked_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left">CHECKED DATE:</th>
        <th align="left">
          {isView ? (
            formatDate(moduleData.checked_date || '')
          ) : (
            <DatePickerMui
              value={moment(moduleData.checked_date)}
              onChange={(date) => {
                moduleData.checked_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          MODIFIED BY:
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="modified_by"
            type="text"
            value={moduleData.modified_by}
            onChange={(e) => {
              moduleData.modified_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left">PREPARED BY:</th>
        <th align="left" colSpan={2}>
          <TableInput
            name="prepared_by"
            type="text"
            value={moduleData.prepared_by}
            onChange={(e) => {
              moduleData.prepared_by = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left">PREPARED DATE:</th>
        <th align="left">
          {isView ? (
            formatDate(moduleData.prepared_date || '')
          ) : (
            <DatePickerMui
              value={moment(moduleData.prepared_date)}
              onChange={(date) => {
                moduleData.prepared_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={2}>
          ISSUE NO:
        </th>
        <th align="left" colSpan={2}>
          <TableInput
            name="issue_no"
            type="text"
            value={moduleData.issue_no}
            onChange={(e) => {
              moduleData.issue_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={3}>
          ISSUE DATE:
        </th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.issue_date || '')
          ) : (
            <DatePickerMui
              value={moment(moduleData.issue_date)}
              onChange={(date) => {
                moduleData.issue_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={9}>REVISION SUMMARY</th>
      </tr>
      <tr>
        <th>Rev. No.</th>
        <th>Revision Date</th>
        <th colSpan={isView ? 7 : 6}>Revision Details</th>
        {!isView && (
          <th>
            <CustomButton
              sx={{
                backgroundColor: '#1c7ed6',
              }}
              onClick={addRows}
            >
              ADD ROWS
            </CustomButton>
          </th>
        )}
      </tr>
      {moduleData.revision_summary.map((item: any, index: number) => (
        <tr>
          <td>
            <TableInput
              name="rev_no"
              type="text"
              value={index + 1}
              isView={isView}
            />
          </td>
          <td>
            {isView ? (
              formatDate(item?.revision_date || '')
            ) : (
              <DatePickerMui
                value={moment(item?.revision_date)}
                onChange={(date) => {
                  item.revision_date = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </td>
          <td colSpan={isView ? 7 : 6}>
            <TableInput
              name="revision_detail"
              type="text"
              value={item.revision_detail}
              onChange={(e) => {
                item.revision_detail = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </td>
          {!isView && (
            <td>
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handelDeleteRows(index)}
              />
            </td>
          )}
        </tr>
      ))}
      <tr>
        <th colSpan={2}>FM/XLV/RI/020</th>
        <th colSpan={6}>ISSUE NO. '00'</th>
        <th colSpan={1}>ISSUE DATE- 10.01.2022</th>
      </tr>
      <DialogDeleteRow isSubmitContent={false} />
    </tfoot>
  );
};

export default TFoot;
