import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    date: moment(),
    part_id: null,
    abnoramal_sitution: '',
    operation: '', // will come from cp operation or process
    type_of_change: '',
    lot_qty: null,
    ok_qty_after_insp: null,
    rej_qty: null,
    parameter: '',
    before: Array.from({ length: 5 }, (_, ind) => ''),
    after: Array.from({ length: 5 }, (_, ind) => ''),
    insp_by: '',
    remarks: '',
    operations: [], // temp data for process name from cp selecct field
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  abnSitCheck_date: moment(),
  processes: Array.from({ length: 30 }, () => ({
    ...lodash.cloneDeep(processes[0]),
  })),
};
