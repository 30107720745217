import React, { useState } from 'react';
import { Checkbox, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import Add from '@mui/icons-material/Add';
import StateCustomTable from '../../../../components/common/StateCustomTable';
import {
  CustomPagination,
  // StatePagination,
  TableCustom,
} from '../../../../components/common';
// import formatDate from '../../../../components/common/formatDate';
// import { CustomIconButton } from '../../../../components/common/button/CustomIconButton';
import useDebounce from '../../../../hooks/useDebounce';
// import { useQuery } from '../../../../hooks/UseQuery';

interface Vendor {
  vender_name: string;
}
interface IPart {
  _id?: string;
  erp_code: string | null;
  spare_part_desc: string | null;
  used_for_machine: string | null;
  opening_stock_qty: number | null;
  opening_stock_UOM: string | null;
  min_stock_qty: number | null;
  max_stock_qty: number | null;
  closing_stock_qty: number | null;
  location: string | null;
  remarks: string | null;
}
const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'ERP Code', rowspan: 1, colspan: 1 },
    { text: 'Spare Part Description', rowspan: 1, colspan: 1 },
    { text: 'Used For machine', rowspan: 1, colspan: 1 },
    { text: 'Stock Opening Qty', rowspan: 1, colspan: 1 },
    { text: 'Stock Opening UOM', rowspan: 1, colspan: 1 },
    { text: 'Stock Minimum Qty ', rowspan: 1, colspan: 1 },
    { text: 'Stock Maximum Qty', rowspan: 1, colspan: 1 },
    { text: 'Stock Closing Qty', rowspan: 1, colspan: 1 },
    { text: 'Location', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'Most Recent' },
  { value: 'spare_part_desc', name: 'A-Z' },
];

const SparePartList = ({
  link,
  type,
  sparePartIds,
  handleUpdateSparePart,
}: {
  link?: string;
  type?: string;
  handleUpdateSparePart?: (id: string) => void;
  sparePartIds?: string[];
}) => {
  const [page, setPage] = useState(1);
  const [partData, setPartData] = useState<IPart[]>([]);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('createdAt');
  const navigate = useNavigate();

  const fetchPartData = () => {
    axios
      .get(
        `/api/criticalSparePartsRoute/?page=${page}&sortBy=${filter}&search=${search}&perPage=${10}`
      )
      .then((res) => {
        setPerPage(res.data?.perPage);
        setPartData(res.data?.results);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, [page, filter, search]);
  // console.log('spare', sparePartIds);
  return (
    <>
      <StateCustomTable
        filterOptions={filterOptions}
        {...{ search, setSearch, filter, setFilter }}
        sx={{ width: '100%', maxWidth: '100%' }}
        label="Search By ERP And Spare Part "
      >
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {partData &&
              partData.map((item: IPart, index: number) => (
                <React.Fragment key={item?._id}>
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{item.erp_code}</TableCell>
                    <TableCell align="center">{item.spare_part_desc}</TableCell>
                    <TableCell align="center">
                      {item.used_for_machine}
                    </TableCell>
                    <TableCell align="center">
                      {item.opening_stock_qty}
                    </TableCell>
                    <TableCell align="center">
                      {item.opening_stock_UOM}
                    </TableCell>
                    <TableCell align="center">{item.min_stock_qty}</TableCell>
                    <TableCell align="center">{item.max_stock_qty}</TableCell>
                    <TableCell align="center">
                      {item.closing_stock_qty}
                    </TableCell>
                    <TableCell align="center">{item.location}</TableCell>
                    <TableCell align="center">
                      {sparePartIds && handleUpdateSparePart && (
                        <Checkbox
                          checked={sparePartIds.includes(item._id || '')}
                          onChange={() => handleUpdateSparePart(item._id || '')}
                        />
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
        <CustomPagination totalPage={totalPages} />
      </StateCustomTable>
    </>
  );
};

export default SparePartList;
