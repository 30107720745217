import { SelectChangeEvent } from '@mui/material';
import React from 'react';

export const handleCpHeaderInputs = (
  e:
    | React.ChangeEvent<HTMLInputElement>
    | React.ChangeEvent<HTMLSelectElement>
    | SelectChangeEvent<unknown>,
  moduleData: any
) => {
  moduleData[e.target.name] = e.target.value;
};
