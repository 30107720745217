export const listConstant = {
  childHeader: "RM TEST PLAN (RUBBER & O'RING  PARTS) List",
  addButtonLable: 'New TEST PLAN',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: "RM TEST PLAN (RUBBER & O'RING  PARTS)",
  theadHeader: 'RAW MATERIAL TEST PLAN - BOP',
  extracolSpan: 5,
};

export const checkListList = {
  childHeader: 'TEST PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'RawMaterialOringPlan',
  gettableDataurl: 'RawMaterialOringPlan/monthly',
  getPlanByid: 'RawMaterialOringPlan',
  createPlanByid: 'RawMaterialOringPlan',
  updatePlanByid: 'RawMaterialOringPlan',
  sync: 'RawMaterialOringPlan/handleSync',
  getAllCheckList: 'RawMaterialOringPlan/getAllCheckList',
  checkListStatus: 'RawMaterialOringPlan/handleStatus',
  checklistUpload: 'RawMaterialOringPlan/handleUpload',
  checklistDeleteImage: 'RawMaterialOringPlan/handleDeleteImage',
};

export const tilesConstant = {
  childHeaderText: "RM TEST PLAN (RUBBER & O'RING  PARTS)",
};

export const navigationConstant = {
  createPlanSchedule: '/common/RubberoringPartsPlanCreate',
};
