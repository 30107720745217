export const calcRej = (qty: any, okQty: any) => {
  if (
    okQty === undefined ||
    okQty === null ||
    qty === undefined ||
    qty === null
  )
    return null;

  return qty - okQty;
};
