import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import TBody2 from './TBody2';
import TBody3 from './TBody3';
import TBody4 from './TBody4';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleSumbit: any;
  headerTitle: string;
}

const TController = ({ moduleData, isView, headerTitle }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            headerTitle,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody2
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody3
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody4
          {...{
            moduleData,
            isView,
          }}
        />
        <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
