export const listConstant = {
  childHeader: 'Customer Satisfaction Survey List',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Customer Satisfaction Survey List',
  theadHeader: 'Customer Satisfaction Survey Plan',
  extracolSpan: 1,
};

export const checkListList = {
  childHeader: 'Customer Satisfaction Survey STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'customerSatisfactionSurveyPlanRoute',
  gettableDataurl: 'customerSatisfactionSurveyPlanRoute/monthly',
  getPlanByid: 'customerSatisfactionSurveyPlanRoute',
  createPlanByid: 'customerSatisfactionSurveyPlanRoute',
  updatePlanByid: 'customerSatisfactionSurveyPlanRoute',
  sync: 'customerSatisfactionSurveyPlanRoute/handleSync',
  getAllCheckList: 'customerSatisfactionSurveyPlanRoute/getAllCheckList',
  checkListStatus: 'customerSatisfactionSurveyPlanRoute/handleStatus',
  checklistUpload: 'customerSatisfactionSurveyPlanRoute/handleUpload',
  checklistDeleteImage: 'customerSatisfactionSurveyPlanRoute/handleDeleteImage',
  checklistData: 'customerSatisfactionSurveyPlanRoute/checklistRoute',
  getMasterDataAutocomplete:
    'customerRoutes/customerList/customerForAutoComplete',
};

export const tilesConstant = {
  childHeaderText: 'Customer Satisfaction Survey plan',
};

export const navigationConstant = {
  createPlanSchedule: '/common/customerSatisfactionSurveyPlanCreate',
  createChecklist: '/common/customerSatisfactionSurveyPlanChecklistList',
};
