import { RenderTiles } from '../../components/common';
// import { VscServerProcess } from 'react-icons/vsc';
// import { FcInspection } from 'react-icons/fc';
// import { MdApproval } from 'react-icons/md';
import { TbReport } from 'react-icons/tb';
// import { TbReportAnalytics } from 'react-icons/tb';
import { HiDocumentReport } from 'react-icons/hi';
import { SlNotebook } from 'react-icons/sl';
import { AiOutlineFundView } from 'react-icons/ai';
import { BsJournalBookmarkFill } from 'react-icons/bs';
// import { TbNotebook } from 'react-icons/tb';
import { TbEyeTable } from 'react-icons/tb';
import { MdOutlineLiveHelp } from 'react-icons/md';
import { FaFireExtinguisher } from 'react-icons/fa';
import { GiFirstAidKit } from 'react-icons/gi';

const supervisorTilesObj = [
  // {
  //   sNo: 1,
  //   link: '/supervisor/pirList',
  //   isLink: true,
  //   icon: <VscServerProcess size="5rem" color="#a12222" />,
  //   p: 'LINE',
  //   span: 'INSPECTION',
  //   isIcon: true,
  // },
  {
    sNo: 1,
    link: '/supervisor/pdirList',
    isLink: true,
    icon: <TbReport size="5rem" color="#a12222" />,
    p: 'JOB',
    span: 'CARD',
    isIcon: true,
  },
  // {
  //   sNo: 7,
  //   link: '/supervisor/pdirTiles',
  //   isLink: true,
  //   icon: <VscServerProcess size="5rem" color="#135089" />,
  //   p: 'PDIR',
  //   span: 'REPORT',
  //   isIcon: true,
  // },
  {
    sNo: 2,
    link: '/common/pdiDepartment',
    isLink: true,
    icon: <TbEyeTable size="5rem" color="#a12222" />,
    p: 'PDI',
    span: 'DEPARTMENT',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/qualityDepartmentHome',
    isLink: true,
    icon: <SlNotebook size="5rem" color="#a12222" />,
    p: 'QUALITY',
    span: 'DEPARTMENT',
    isIcon: true,
  },
  // {
  //   sNo: 1,
  //   link: '/supervisor/setupList',
  //   isLink: true,
  //   icon: <MdApproval size="5rem" color="#a12222" />,
  //   p: 'FPA/',
  //   span: 'LPA',
  //   isIcon: true,
  // },
  // {
  //   sNo: 1,
  //   link: '/supervisor/incomingReportList',
  //   isLink: true,
  //   icon: <FcInspection size="5rem" color="#a12222" />,
  //   p: 'INCOMING INSPECTION',
  //   span: 'STANDARD',
  //   isIcon: true,
  // },
  // {
  //   sNo: 1,
  //   link: '/supervisor/materialIncomingList',
  //   isLink: true,
  //   icon: <FcInspection size="5rem" color="#a12222" />,
  //   p: 'MATERIAL INCOMING',
  //   span: 'INSPECTION',
  //   isIcon: true,
  // },
  {
    sNo: 2,
    link: '/common/riDepartment',
    isLink: true,
    icon: <HiDocumentReport size="5rem" color="#a12222" />,
    p: 'RI',
    span: 'DEPARTMENT',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/supervisor/viewPpap?filter=part_number',
    isLink: true,
    clauseNo: 4,
    p: 'VIEW',
    span: 'PPAP DOCUMENTS',
    icon: <AiOutlineFundView size="5rem" color="#343a40" />,
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/common/productionLogBookHome',
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="black" />,
    p: 'PRODUCTION',
    span: 'LOG BOOK',
    isIcon: true,
  },

  {
    sNo: 21,
    link: `/common/mushinHelpDesk`,
    isLink: true,
    icon: <MdOutlineLiveHelp size="5rem" color="#003566" />,
    p: 'MUSHIN HELP',
    span: 'DESK',
    isIcon: true,
  },
  {
    sNo: 22,
    link: `/common/firstAidBox`,
    isLink: true,
    icon: <GiFirstAidKit size="5rem" color="#003566" />,
    p: 'FIRST AID',
    span: 'BOX',
    isIcon: true,
  },
  {
    sNo: 22,
    link: `/common/listOfFireExtinguisher`,
    isLink: true,
    icon: <FaFireExtinguisher size="5rem" color="#003566" />,
    p: 'LIST OF',
    span: 'FIRE EXTINGUISHER',
    isIcon: true,
  },
  // {
  //   sNo: 5,
  //   link: '/common/rejectionNoteList',
  //   isLink: true,
  //   icon: <TbNotebook size="5rem" color="#a12222" />,
  //   p: 'REJECTION',
  //   span: 'NOTE',
  //   isIcon: true,
  // },
];

const SupervisorHome = () => {
  return (
    <>
      <RenderTiles
        height="100vh"
        justify="start"
        tilesObj={supervisorTilesObj}
      />
    </>
  );
};

export default SupervisorHome;
