interface Ifoot {
  total: number;
}
const TFoot = ({ total }: Ifoot) => {
  return (
    <tfoot>
      <tr style={{ background: 'yellow' }}>
        <th colSpan={2}>Total</th>
        <th>{total}</th>
        <th></th>
        <th></th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
