import moment from 'moment';
import lodash from 'lodash';

export const process = {
  location_ri: 'Table No.',
  lower_range: 800,
  upper_range: 1000,
  unit: 'lux',
  specification: '800 - 1000 lux',
  reason: '',
  colors: [null, null, null, null],
  values: [null, null, null, null],
  result: '',
  remarks: '',
};

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  moduleData_Date: moment(),
  freq: '',
  obs_date: [null, null, null, null],
  processes: Array.from({ length: 12 }, (_, index) => ({
    ...lodash.cloneDeep(process),
    location_ri: `Table No.${index + 1 > 9 ? index + 1 : `0${index + 1}`}`,
  })),
};
