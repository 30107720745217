const months = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC',
  ];
  
  const GetMonthYear = (year: any) => {
    return months.map((month) => ({
      month_year: `${month}-${new Date(year).getFullYear()}`,
    }));
  };

  export {GetMonthYear};
  