import { useEffect, useState } from 'react';
import { TableInput } from '../../../../components/common';
import { useAuth } from '../../../../services/auth/AuthProvider';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import CustomSelect from '../../../../components/common/SelectField';
import { Grid, MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  item: any;
  isView: any;
  handleDelete: any;
  setParentRender: any;
  dataHandler: any;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  index: number;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
}
const nextyear = ['january', 'february', 'march'];

const TBodyRow = ({
  moduleData,
  item,
  index,
  setRender,
  isView,
  handleDelete,
  setIsOpenPopup,
  setPartIdx,
  dataHandler,

  setParentRender,
}: IProps) => {
  const { state } = useAuth();
  const [rowRender, setRowRender] = useState(false);

  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
  };

  const disableField = (isDisable: boolean = false) => {
    if (index === 0) {
      return true;
    }
    if (!isDisable) return false;
    if (item._id && item.action_taken !== 'CHANGE PENDING') {
      return true;
    }
    return false;
  };

  return (
    <>
      <tr>
        <td style={{ textAlign: 'center' }}>{index + 1}</td>
        <td style={{ textAlign: 'center', width: '180px' }}>
          <DatePickerMui
            minDate={item?.startOfMonth}
            maxDate={item?.endOfMonth}
            label=""
            disabled={disableField(true) && item._id}
            onChange={(date) => {
              item.date = moment(date || null);
              dataHandler.calculateCumulative();
              setParentRender((prev: any) => !prev);
            }}
            value={(item?.date && moment(item?.date)) || null}
          />
        </td>
        <td style={{ width: '280px', minWidth: '280px' }}>
          {isView || disableField() ? (
            item?.part_id && item.part_id?.part_number
          ) : (
            <Grid container xs={12} direction="row" alignItems={'center'}>
              <Grid item xs={10}>
                <p
                  style={{
                    fontSize: '1.2rem',
                  }}
                >
                  {typeof item?.part_id === 'object' && item?.part_id !== null
                    ? item.part_id?.part_number
                    : item?.partNumber}
                  {!item?.part_id && 'Pick Part No.'}
                </p>
              </Grid>

              {item.part_id ? (
                <Grid item xs={2}>
                  <RemoveIconButton
                    tooltipTitle="remove part"
                    onClick={() => {
                      item.part_id = null;
                      item.partNumber = null;
                      item.partName = null;
                      setRowRender((prev: any) => !prev);
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={2}>
                  <AddIconButton
                    tooltipTitle="add part"
                    onClick={() => {
                      setIsOpenPopup(true);
                      setPartIdx(index);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            disabled={disableField(true)}
            type="number"
            name="checked_qty"
            value={item?.checked_qty}
            onChange={(e) => {
              handleChange(e);
              dataHandler.calculateCumulative();
              setParentRender((prev: any) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>{item?.cumm_qty}</td>
        <td style={{ textAlign: 'center' }}>
          <DatePickerMui
            minDate={item?.startOfMonth}
            maxDate={item?.endOfMonth}
            label=""
            disabled={disableField(true) && item._id}
            onChange={(date) => {
              item.change_date = moment(date || null);
              setParentRender((prev: any) => !prev);
            }}
            value={(item?.change_date && moment(item?.change_date)) || null}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <DatePickerMui
            minDate={item?.startOfMonth}
            maxDate={item?.endOfMonth}
            label=""
            disabled={disableField(true) && item._id}
            onChange={(date) => {
              item.filter_change_date = moment(date || null);
              setParentRender((prev: any) => !prev);
            }}
            value={
              (item?.filter_change_date && moment(item?.filter_change_date)) ||
              null
            }
          />
        </td>
        <td
          style={{
            ...(item.status === 'LIMIT EXCEEDED' && {
              background: '#f03e3e',
              color: '#fff5f5',
            }),
          }}
        >
          {item.status}
        </td>

        <td>
          <CustomSelect
            name={'action_taken'}
            disabled={disableField(true)}
            value={item?.action_taken || ''}
            onChange={(e) => {
              item.action_taken = e.target.value;
              dataHandler.calculateCumulative();
              setParentRender((prev: any) => !prev);
            }}
            sx={{
              width: '210px',
              ...(item.action_taken === 'CHANGE PENDING' && {
                background: '#f03e3e',
                color: '#fff5f5',
              }),
              ...(item.action_taken === 'CHANGE COMPLETED' && {
                background: '#37b24d',
                color: '#fff5f5 !important',
              }),
            }}
          >
            <MenuItem value="">NONE</MenuItem>
            <MenuItem value="CHANGE PENDING">CHANGE PENDING</MenuItem>
            <MenuItem value="CHANGE COMPLETED">CHANGE COMPLETED</MenuItem>
          </CustomSelect>
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            disabled={disableField()}
            isView={isView}
            type="text"
            name="remarks"
            style={{ minWidth: '113px' }}
            value={item?.remarks || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <DeleteIconButton
            disabled={disableField(true)}
            style={{
              color: `${disableField(true) ? '#495057' : '#c92a2a'}`,
            }}
            tooltipTitle="DELETE"
            onClick={() => {
              handleDelete(index);
            }}
          />
        </td>
      </tr>
    </>
  );
};

export default TBodyRow;
