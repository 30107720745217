import { MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { TableInput } from '../../../../components/common';
import CustomSelect from '../../../../components/common/SelectField';
import handleChangeSpec from '../../helpers/handleChangeSpec';
import { handleChangeColorOnRemarks } from '../../helpers/handleChangeColorOnRemarks';
interface IProps {
  spec: any;
  isView: boolean;
  checkType: string;
  obsIndex: number;
  obsNumber: number;
}
const ObservationInput = ({
  spec,
  isView,
  checkType,
  obsIndex,
  obsNumber,
}: IProps) => {
  const [render, setRender] = useState(false);
  if (checkType === 'check3') {
    return (
      <>
        <td>
          <TableInput
            name="observation"
            type="text"
            value={spec[`check_${obsNumber}_values`][obsIndex] || ''}
            onChange={(e) => {
              handleChangeSpec(e, spec, obsIndex, obsNumber);
              handleChangeColorOnRemarks(spec, spec.remarks);
              console.log(spec);
              setRender((prev) => !prev);
            }}
            style={{
              backgroundColor: spec[`check_${obsNumber}_colors`][obsIndex],
              textAlign: 'center',
            }}
            isView={isView}
          />
        </td>
        <td style={{ textTransform: 'uppercase' }}>
          {isView ? (
            spec[`check_${obsNumber}_results`][obsIndex] || ''
          ) : (
            <CustomSelect
              name="dropdown"
              value={spec[`check_${obsNumber}_results`][obsIndex] || ''}
              onChange={(e) => {
                spec[`check_${obsNumber}_results`][obsIndex] = e.target.value;
                setRender((prev) => !prev);
              }}
              sx={{
                textAlign: 'center',
              }}
            >
              <MenuItem value="">NONE</MenuItem>
              <MenuItem value="ok">OK</MenuItem>
              <MenuItem value="ng">NG</MenuItem>
            </CustomSelect>
          )}
        </td>
        {/* <td style={{ textTransform: 'uppercase' }}>
          {spec[`check_${obsNumber}_results`][obsIndex]}
        </td> */}
      </>
    );
  }
  if (
    checkType === 'check1' ||
    checkType === 'check2' ||
    checkType === 'check4'
  ) {
    return (
      <>
        <td>
          <TableInput
            name="observation"
            type="number"
            value={spec[`check_${obsNumber}_values`][obsIndex] || ''}
            onChange={(e) => {
              handleChangeSpec(e, spec, obsIndex, obsNumber);
              handleChangeColorOnRemarks(spec, spec.remarks);
              console.log(spec);
              setRender((prev) => !prev);
            }}
            style={{
              backgroundColor: spec[`check_${obsNumber}_colors`][obsIndex],
              textAlign: 'center',
            }}
            isView={isView}
          />
        </td>
        <td style={{ textTransform: 'uppercase' }}>
          {spec[`check_${obsNumber}_results`][obsIndex]}
        </td>
      </>
    );
  }
  return <React.Fragment></React.Fragment>;
};
export default ObservationInput;
