import moment from 'moment';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { useQuery } from '../../../../../hooks/UseQuery';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import { useState } from 'react';
import formatDate from '../../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'Sr. No.',
  'Machine Code/ Number',
  'M/c Description',
  'Part No.',
  'NO. OF BEND',
  'PIPE DIA',
  'No. of Shifts Planned',
  'Total Shift Duration',
  'Planned Losses',
  'Planned Production Time',
  'Unplanned Losses',
  'Operating Time',
  'Total Availabilty',
  'Ideal Run Rate',
  'Total Production',
  'Rejection',
  'OK Production',
  'Partwise performance',
  'Total Performance',
  'Partwise Quality',
  'Total Quality',
  'OEE',
  'Remarks',
  'Delete',
];
const theadDataOne = [
  'Break Times',
  'Prev /Aut Maint.',
  'Changeover Time',
  'Total',
  'Break-up',
  'Set-up Time',
  'Break-down Time',
  'Material not avl.',
  'Manpower not avl.',
  'Power/ Utility Failure',
  'Process/ Quality Issues/ Dev trial',
  'Others',
  'Total Loss Time',
  'Total',
  'Break-up',
];
const theadDataTwo = [
  'Mins',
  'Mins',
  'Mins',
  'Mins',
  'Mins',
  'Mins',
  'Mins',
  'Mins',
  'Mins',
  'Mins',
  'Mins',
  'Mins',
  'Mins',
  'Mins',
  'Mins',
  'Mins',
  '%',
  'Pieces/ Min',
  'Nos',
  'Nos',
  'Nos',
  '%',
  '%',
  '%',
  '%',
  '%',
];
const theadDataThree = [
  'a',
  'b',
  'c',
  'd',
  'h = a-b-c-d',
  'e',
  'x',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l = f+g+h+i+j+k+x',
  'm = h-l',
  'n = e-l',
  'o = n/ h',
  'p',
  'q',
  'r',
  's = q-r',
  't = q/n/p',
  'l = avg. t',
  'u = s/q',
  'v = avg. u',
  'w = o x l x v',
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={35}
        rowSpan={3}
        labels={['Plant/ Line :', '', '']}
        values={['P-45 IMT', '', '']}
        heading={`OEE CALCULATION`}
      />
      <tr>
        <th colSpan={35} align="left">
          Date : -
          {isView ? (
            formatDate(moduleData.date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.date || '')}
              onChange={(date) => {
                moduleData.date = date;
                setRender((prev: any) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index: number) => (
          <th
            key={heading + index}
            rowSpan={
              index <= 6 || index >= 22 ? 4 : index == 7 || index >= 12 ? 2 : 1
            }
            colSpan={
              index == 8
                ? 3
                : index == 9 || index == 11
                ? 2
                : index == 10
                ? 8
                : 1
            }
            style={{ background: '#38d9a9' }}
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr>
        {theadDataOne.map((heading, index) => (
          <th
            key={heading + index}
            rowSpan={1}
            colSpan={1}
            style={{ background: '#38d9a9' }}
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr>
        {theadDataTwo.map((heading, index) => (
          <th
            key={heading + index}
            rowSpan={1}
            colSpan={1}
            style={{ background: '#38d9a9' }}
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr>
        {theadDataThree.map((heading, index) => (
          <th key={heading + index} style={{ background: '#38d9a9' }}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
