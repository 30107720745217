import { MenuItem } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CustomSelect from '../../../../components/common/SelectField';

interface IProps {
  process: any;
  isView: boolean;
  partId: string;
}
const ReasonOfRejection = ({ process, partId, isView }: IProps) => {
  const [reasons, setReasons] = useState([]);
  const [render, setRender] = useState(false);
  useEffect(() => {
    if (partId) {
      axios
        .get(`/api/pfmea/get/PfmeaReasonForProudction/${partId}`)
        .then((res) => {
          if (res.data) {
            setReasons(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [partId]);
  return (
    <>
      {isView ? (
        process.reason_of_rejection
      ) : (
        <CustomSelect
          name="reason_of_rejection"
          value={process.reason_of_rejection || ''}
          onChange={(e) => {
            process.reason_of_rejection = e.target.value;
            setRender((prev) => !prev);
          }}
        >
          <MenuItem value="">None</MenuItem>
          {reasons.map((name) => (
            <MenuItem value={name}>{name}</MenuItem>
          ))}
        </CustomSelect>
      )}
    </>
  );
};

export default ReasonOfRejection;
