import React, { ChangeEvent, WheelEvent } from 'react';
import Styled from 'styled-components';
interface IProps {
  isView: boolean;
  name?: string;
  label?: string;
  value?: number | string;
  type: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  style?: any;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  defaultValue?: string | number;
}
const Input = Styled.input`
    outline: none;
    width: 100%;
    border: none;
    height: 50px;
`;
const TableInput = ({
  defaultValue,
  isView,
  name,
  value,
  disabled,
  type,
  label,
  onChange,
  style,
  min,
  max,
  step,
}: IProps) => {
  const handleWheel = (event: WheelEvent<HTMLInputElement>) => {
    event.currentTarget.blur();
  };

  return (
    <div
      style={{
        border: isView ? 'none' : '2px solid #b2f2bb',
        padding: '0.1rem',
        display: isView ? 'inline-block' : 'block',
        textAlign: 'center',
      }}
    >
      {isView ? (
        <span
          style={{
            padding: '0.1rem',
            display: isView ? 'inline-block' : 'block',
            textAlign: 'center',
          }}
        >
          {value || value === 0
            ? value
            : defaultValue || defaultValue === 0
            ? defaultValue
            : ''}
        </span>
      ) : (
        <Input
          type={type}
          name={name}
          value={value ?? (value === 0 ? '0' : '')}
          autoComplete="off"
          style={style}
          defaultValue={defaultValue ?? (defaultValue === 0 ? '0' : '')}
          onChange={onChange}
          onWheel={handleWheel}
          placeholder={label}
          disabled={disabled}
          step={step}
          min={min}
          max={max}
        />
      )}
    </div>
  );
};

export default TableInput;
