export const listConstant = {
  childHeader: 'Old FVP Plan list',
  addButtonLable: 'New FVP',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Old FVP Plan list',
  theadHeader: 'Old FVP Plan',
  extracolSpan: 5,
};

export const checkListList = {
  childHeader: 'Old FVP STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'oldFvpPlanRoute',
  gettableDataurl: 'oldFvpPlanRoute/monthly',
  getPlanByid: 'oldFvpPlanRoute',
  createPlanByid: 'oldFvpPlanRoute',
  updatePlanByid: 'oldFvpPlanRoute',
  sync: 'oldFvpPlanRoute/handleSync',
  getAllCheckList: 'oldFvpPlanRoute/getAllCheckList',
  checkListStatus: 'oldFvpPlanRoute/handleStatus',
  checklistUpload: 'oldFvpPlanRoute/handleUpload',
  checklistDeleteImage: 'oldFvpPlanRoute/handleDeleteImage',
};

export const tilesConstant = {
  childHeaderText: 'Old FVP PLAN',
};

export const navigationConstant = {
  createPlanSchedule: '/common/oldFvpPlanCreate',
};
