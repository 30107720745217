import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { Grid, MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import formatDate from '../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';

interface IProps {
  moduleData: any;
  isView: boolean;
  process: any;
  index: number;
  handelDeleteRows: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}
const TBodyRow = ({
  moduleData,
  isView,
  process,
  index,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tr key={index + 10.1 + 'heading '}>
      <th style={{ minWidth: '50px', textAlign: 'center' }}>{index + 1}</th>
      <th
        style={{
          width: '160px',
          minWidth: '160px',
          textAlign: 'center',
        }}
      >
        {isView ? (
          formatDate(process.date)
        ) : (
          <DatePickerMui
            label=""
            value={moment(process.date)}
            onChange={(date) => {
              process.date = date;
              setRender((prev) => !prev);
            }}
          />
        )}
      </th>
      <td style={{ minWidth: '200px', textAlign: 'center' }}>
        <TableInput
          isView={isView}
          name="abnoramal_sitution"
          type="text"
          value={process.abnoramal_sitution}
          onChange={(e) => {
            handleChangeModuleData(e, process);
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td
        style={{
          width: '280px',
          minWidth: '280px',
          textAlign: 'center',
        }}
      >
        {isView ? (
          process?.part_id && process?.part_id?.part_name
        ) : (
          <Grid container xs={12} direction="row" alignItems={'center'}>
            <Grid item xs={10}>
              <p
                style={{
                  fontSize: '1.2rem',
                }}
              >
                {typeof process?.part_id === 'object' &&
                process?.part_id !== null
                  ? process?.part_id?.part_name
                  : process?.partName || ''}
                {!process?.part_id && 'Pick Part Name'}
              </p>
            </Grid>

            {process.part_id ? (
              <Grid item xs={2}>
                <RemoveIconButton
                  tooltipTitle="remove part"
                  onClick={() => {
                    process.part_id = null;
                    process.partNumber = null;
                    process.partName = null;
                    process.operations = [];
                    setRender((prev) => !prev);
                  }}
                />
              </Grid>
            ) : (
              <Grid item xs={2}>
                <AddIconButton
                  tooltipTitle="add part"
                  onClick={() => {
                    setIsOpenPopup(true);
                    setPartIdx(index);
                  }}
                />
              </Grid>
            )}
          </Grid>
        )}
      </td>
      <td style={{ minWidth: '220px', textAlign: 'center' }}>
        {process?.part_id?.part_number || process.partNumber}
      </td>
      <td style={{ minWidth: '300px', textAlign: 'center' }}>
        {isView ? (
          process.operation
        ) : (
          <CustomSelect
            name="operation"
            value={process.operation}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          >
            <MenuItem value="">None</MenuItem>
            {process.operations?.map((operation: any, ind: number) => (
              <MenuItem value={operation.process_name}>
                {operation.process_name}
              </MenuItem>
            ))}
          </CustomSelect>
        )}
      </td>
      <td style={{ minWidth: '200px', textAlign: 'center' }}>
        <TableInput
          isView={isView}
          name="type_of_change"
          type="text"
          value={process.type_of_change}
          onChange={(e) => {
            handleChangeModuleData(e, process);
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td style={{ minWidth: '100px', textAlign: 'center' }}>
        <TableInput
          isView={isView}
          name="lot_qty"
          type="number"
          value={process.lot_qty}
          onChange={(e) => {
            handleChangeModuleData(e, process);
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td style={{ minWidth: '100px', textAlign: 'center' }}>
        <TableInput
          isView={isView}
          name="ok_qty_after_insp"
          type="number"
          value={process.ok_qty_after_insp}
          onChange={(e) => {
            handleChangeModuleData(e, process);
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td style={{ minWidth: '100px', textAlign: 'center' }}>
        <TableInput
          isView={isView}
          name="rej_qty"
          type="number"
          value={process.rej_qty}
          onChange={(e) => {
            handleChangeModuleData(e, process);
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td style={{ minWidth: '150px', textAlign: 'center' }}>
        <TableInput
          isView={isView}
          name="parameter"
          type="text"
          value={process.parameter}
          onChange={(e) => {
            handleChangeModuleData(e, process);
            setRender((prev) => !prev);
          }}
        />
      </td>
      {[1, 2, 3, 4, 5].map((obs, indx) => (
        <td
          style={{ minWidth: '100px', textAlign: 'center' }}
          key={obs + (index + 100) + indx + 'before' + 333.33}
        >
          {isView ? (
            process.before[indx]
          ) : (
            <CustomSelect
              name="before[indx]"
              value={process.before[indx]}
              onChange={(e) => {
                process.before[indx] = e.target.value;
                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="OK">OK</MenuItem>
              <MenuItem value="NOT OK">NOT OK</MenuItem>
            </CustomSelect>
          )}
        </td>
      ))}
      {[1, 2, 3, 4, 5].map((obs, indx) => (
        <td
          style={{ minWidth: '100px', textAlign: 'center' }}
          key={obs + (index + 2.2) + indx + 'after' + 111.33}
        >
          {isView ? (
            process.after[indx]
          ) : (
            <CustomSelect
              name="after[indx]"
              value={process.after[indx]}
              onChange={(e) => {
                process.after[indx] = e.target.value;
                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="">None</MenuItem>
              <MenuItem value="OK">OK</MenuItem>
              <MenuItem value="NOT OK">NOT OK</MenuItem>
            </CustomSelect>
          )}
        </td>
      ))}
      <td style={{ minWidth: '150px', textAlign: 'center' }}>
        <TableInput
          isView={isView}
          name="insp_by"
          type="text"
          value={process.insp_by}
          onChange={(e) => {
            handleChangeModuleData(e, process);
            setRender((prev) => !prev);
          }}
        />
      </td>
      <td
        style={{ minWidth: '100px', textAlign: 'center' }}
        colSpan={isView ? 2 : 1}
      >
        <TableInput
          isView={isView}
          name="remarks"
          type="text"
          value={process.remarks}
          onChange={(e) => {
            handleChangeModuleData(e, process);
            setRender((prev) => !prev);
          }}
        />
      </td>
      {!isView && (
        <td style={{ minWidth: '100px', textAlign: 'center' }}>
          <DeleteIconButton
            tooltipTitle={'delete'}
            onClick={() => handelDeleteRows(index)}
          />
        </td>
      )}
    </tr>
  );
};

export default TBodyRow;
