import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import moment from 'moment';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { convToTime } from '../../../../utils/helpers';
import { TimePicker } from '@mui/x-date-pickers';

interface IProps {
  item: any;
  isView: any;
  handleDelete: any;
  index: number;
}

const TBodyRow = ({ item, index, isView, handleDelete }: IProps) => {
  const [rowRender, setRowRender] = useState(false);

  const handleChange = (e: any) => {
    item[e.target.name] = e.target.value;
  };

  return (
    <>
      <tr>
        <td style={{ textAlign: 'center' }}>{index + 1}</td>
        <td style={{ textAlign: 'center', width: '180px' }}>
          <DatePickerMui
            label=""
            onChange={(date) => {
              item.date = moment(date || null);
              setRowRender((prev: any) => !prev);
            }}
            value={(item?.date && moment(item?.date)) || null}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="leakage_sample_part"
            value={item?.leakage_sample_part || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="pipe_size"
            value={item?.pipe_size || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="testing_pressure_required"
            value={item?.testing_pressure_required || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="testing_time_required"
            value={item?.testing_time_required || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="leakage_sample_total_cycle_time"
            value={item?.leakage_sample_total_cycle_time || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td>
          <CustomSelect
            name={'status'}
            value={item?.status}
            sx={{ width: '100px' }}
            onChange={(e) => {
              item.status = e.target.value;
              setRowRender((prev) => !prev);
            }}
          >
            <MenuItem value="">NONE</MenuItem>
            <MenuItem value="OK">OK</MenuItem>
            <MenuItem value="NOT OK">NOT OK</MenuItem>
          </CustomSelect>
        </td>

        <td style={{ textAlign: 'center' }}>
          <TableInput
            isView={isView}
            type="text"
            name="operator_name"
            style={{ minWidth: '113px' }}
            value={item?.operator_name || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        <td style={{ textAlign: 'center' }}>
          {isView ? (
            convToTime(item.checking_time)
          ) : (
            <TimePicker
              // ampm={false}
              value={moment(item.checking_time)}
              onChange={(date: any) => {
                item.checking_time = date;
                setRowRender((prev) => !prev);
              }}
            />
          )}
        </td>
        <td style={{ textAlign: 'center' }} colSpan={isView ? 2 : 1}>
          <TableInput
            isView={isView}
            type="text"
            name="remarks"
            value={item?.remarks || null}
            onChange={(e) => {
              handleChange(e);
              setRowRender((prev) => !prev);
            }}
          />
        </td>
        {!isView && (
          <td style={{ textAlign: 'center' }}>
            <DeleteIconButton
              tooltipTitle="DELETE"
              onClick={() => {
                handleDelete(index);
              }}
            />
          </td>
        )}
      </tr>
    </>
  );
};

export default TBodyRow;
