import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { TbFaceIdError } from 'react-icons/tb';
import { RiDeleteBin4Fill } from 'react-icons/ri';
import { TiArrowLoopOutline } from 'react-icons/ti';
import { HiDocumentReport } from 'react-icons/hi';
import { MdOutlineProductionQuantityLimits } from 'react-icons/md';
import { BsJournalBookmarkFill } from 'react-icons/bs';
import { FaCalculator } from 'react-icons/fa';
import { GoTools } from 'react-icons/go';

const tiles = [
  {
    sNo: 1,
    link: '/common/productionLogBookHome',
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="black" />,
    p: 'PRODUCTION',
    span: 'LOG BOOK',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/oeeCalculationList',
    isLink: true,
    icon: <FaCalculator size="5rem" color="purple" />,
    p: 'OEE',
    span: 'CALCULATION',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/common/masterlistofTools?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    icon: <GoTools size="5rem" color="#343a40" />,
    p: 'Master List Of',
    span: 'Tools',
    isIcon: true,
  },
];

const Home = () => {
  return (
    <>
      <ChildHeader text="Production Department" />
      <RenderTiles
        height="100%"
        tilesObj={tiles}
        justify="start"
        columns="0fr"
      />
    </>
  );
};

export default Home;
