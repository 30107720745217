import { AiOutlinePartition } from 'react-icons/ai';
import { RenderTiles } from '../../components/common';
import { useQuery } from '../../hooks/UseQuery';
import { useParams } from 'react-router-dom';
import ChildHeader from '../../components/ui/ChildHeader';
import PartDetailsHeader from '../../components/ui/PartDeatailsHeader';

const Tiles = () => {
  const { partId } = useParams();
  const partName = useQuery('part_name');
  const partNumber = useQuery('part_number');
  const ppapTilesObj = [
    {
      sNo: 3,
      link: `/common/drawingManagement/${partId}?part_name=${partName}&part_number=${partNumber}`,
      isLink: true,
      clauseNo: 4,
      p: 'DRAWING',
      span: 'Management',
      isIcon: true,
      icon: <AiOutlinePartition size="5rem" color="#c80202" />,
    },
  ];
  return (
    <>
      <ChildHeader text="DOCUMENT LIST" />
      <PartDetailsHeader
        partName={partName || ''}
        partNumber={partNumber || ''}
      />
      <RenderTiles height="100vh" tilesObj={ppapTilesObj} />;
    </>
  );
};

export default Tiles;
