import { Box } from '@mui/system';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
import { apiConstant, createConstant } from './constant';
import useConfirm from '../../../components/common/useConfirm';
import { Loader } from '../../../components/common/Loader';
import lodash from 'lodash';
import { list } from './helpers/initialState';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { ViewIconButton } from '../../../components/common/button/CustomIconButton';
import { useRef, useState } from 'react';
import ModalCustom from '../../../components/common/Modal';
import ViewFile from './ViewFile';
import useDebounce from '../../../hooks/useDebounce';

interface MyData {
  moduleData: any;
  isLoading: boolean;
  error?: string | null;
  setIsLoading: any;
  setRender: any;
}

const Create = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [docFiles, setDocFiles] = useState<any>({});
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isOpenFiles, setIsOpenFiles] = useState(false);
  const idRef = useRef<any>({});
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?'
  );
  const { moduleData, isLoading, error, setIsLoading, setRender }: MyData =
    useGetData(id);

  const onUpdate = async () => {
    const ans = await confirmSave();
    if (!ans) return;

    axios
      .put(`/api/${apiConstant.updatePlanByid}/${id}`, moduleData)
      .then((res) => alert('updated successfully '))
      .catch((err) => console.log(err));
  };
  const uploadFile = async (e: any) => {
    if (!window.confirm('Are you sure you want upload this Files')) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
    setIsLoading(true);

    axios
      .patch(`/api/${apiConstant.fileUpload}/${id}`, formData)
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
          setRender((prev: any) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text={`${createConstant.childHeader}`}>
          {!isView && (
            <>
              <CustomUploadButton
                accept="image/png,image/jpeg,image/jpg,application/pdf"
                id={`files`}
                multiple={true}
                lable="Upload Document"
                onChange={(e) => {
                  uploadFile(e);
                  setRender((prev: any) => !prev);
                }}
              />
              {moduleData.files?.length > 0 ? (
                <>
                  <ViewIconButton
                    tooltipTitle="view document"
                    onClick={() => {
                      setDocFiles(moduleData.files);
                      setIsOpenFiles(true);
                      setRender((prev: any) => !prev);
                    }}
                  />
                </>
              ) : (
                <ViewIconButton
                  sx={{
                    opacity: '0',
                    PointerEvent: 'none',
                    visibility: 'hidden',
                  }}
                  tooltipTitle=""
                />
              )}

              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  Array(1)
                    .fill(null)
                    .forEach(() => {
                      moduleData.list.push(lodash.cloneDeep(list));
                    });
                }}
              >
                ADD ROWS
              </CustomButton>
              <SubmitButton label="Update" onClick={() => onUpdate()} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController {...{ moduleData, isView }} />
      <DialogDeleteRow isSubmitContent={false} />
      <DialogSave isSubmitContent={false} />
      <DialogAddRow />
      <ModalCustom
        title="FILE LIST"
        openModal={isOpenFiles}
        closeModal={() => {
          setIsOpenFiles(false);
        }}
      >
        <ViewFile
          id={id}
          docFiles={docFiles}
          setIsOpenFiles={setIsOpenFiles}
          setRender={setRender}
        />
      </ModalCustom>
    </Box>
  );
};

export default Create;
