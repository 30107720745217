import { Box } from '@mui/system';
import axios from 'axios';
import lodash from 'lodash';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import ModalCustom from '../../components/common/Modal';
import useConfirm from '../../components/common/useConfirm';
import ChildHeader from '../../components/ui/ChildHeader';
import PickPart from '../../components/ui/PickPart';
import { useQuery } from '../../hooks/UseQuery';
import TController from './components/create/TController';
const Create = () => {
  const { productionLogBookId } = useParams();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [partIdx, setPartIdx] = useState<number>(0);
  const [processes, setProcesses] = useState([]);
  const [headingType, setHeadingType] = useState('production_status');
  const documentType = useQuery('documentType') || '';
  const firstFloorType = useQuery('firstFloorType') || '';
  const groundFloorType = useQuery('groundFloorType') || '';

  const [moduleData, setModuleData] = useState<any>({
    production_date: new Date(),
    // operation_list :
    operation_list: Array.from({ length: 5 }, () => ({
      operation_drops: [],
      machine_values: [],
      operator_values: [],
      date: [],
    })),
    production_status: Array.from({ length: 5 }, () => ({
      ...lodash.cloneDeep({
        tools: [
          { tool_id: null },
          { tool_id: null },
          { tool_id: null },
          { tool_id: null },
          { tool_id: null },
        ],
      }),
    })),
    rejection_details: Array.from({ length: 5 }, () => ({})),
    down_time_status: Array.from({ length: 5 }, () => ({})),
    shift: '',
    line_no: '',
  });

  const [render, setRender] = useState(false);
  const [customerData, setCustomerData] = useState([]);
  const [toolsData, setToolsData] = useState([]);
  const [machineData, setMachineData] = useState([]);
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const addRow = async () => {
    const ans = await confirmAddRow();
    if (!ans) return;
    moduleData.operation_list.push(
      lodash.cloneDeep({
        operation_drops: [],
        machine_values: [],
        operator_values: [],
        date: [],
      })
    );
    moduleData.production_status.push(
      lodash.cloneDeep({
        tools: [
          { tool_id: null },
          { tool_id: null },
          { tool_id: null },
          { tool_id: null },
          { tool_id: null },
        ],
      })
    );
    moduleData.rejection_details.push({});
    moduleData.down_time_status.push({});
    setRender((prev) => !prev);
  };
  useEffect(() => {
    axios
      .get('/api/pfmea/get/ProcessForSelect')
      .then((res) => {
        setProcesses(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        // console.log(res.data);
        setCustomerData(res.data);
      })
      .catch((err) => console.log(err));
    axios
      .get('/api/machineRoutes/machineList/machineListForAutoComplete')
      .then((res) => {
        setMachineData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    axios
      .get('/api/masterListOfTools/toolsList/toolsForAutoComplete')
      .then((res) => {
        // console.log(res?.data);
        setToolsData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (productionLogBookId) {
      axios
        .get(`/api/productionLogBook/${productionLogBookId}`)
        .then((res) => {
          if (res.data) {
            setModuleData(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  const onSubmit = async () => {
    if (!['groundFloor', 'firstFloor'].includes(documentType)) {
      alert('document type is not valid');
      return;
    }
    if (moduleData._id) {
      await axios
        .put(`/api/productionLogBook/${moduleData._id}`, moduleData)
        .then((res) => {
          if (res.data) {
            alert('updated successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      moduleData.document_type = documentType;
      moduleData.firstFloorType = firstFloorType;
      moduleData.groundFloorType = groundFloorType;
      await axios
        .post(`/api/productionLogBook`, moduleData)
        .then((res) => {
          alert('submited successfully');
        })
        .catch((err) => console.log(err));
    }

    alert('created successfully');
    navigate(-1);
  };
  return (
    <Box>
      <Box>
        <ChildHeader text="Production Log Book">
          {!isView && (
            <>
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  addRow();
                }}
              >
                ADD ROWS
              </CustomButton>
              <SubmitButton
                label={`${moduleData._id ? 'update' : 'submit'}`}
                onClick={() => onSubmit()}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          customerData,
          machineData,
          toolsData,
          processes,
          moduleData,
          isView,
          setIsOpenPopup,
          setPartIdx,
          setHeadingType,
          documentType,
        }}
      />

      <DialogAddRow />
      <ModalCustom
        title="Part List"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <PickPart moduleData={moduleData[headingType][partIdx]} />
      </ModalCustom>
    </Box>
  );
};

export default Create;
