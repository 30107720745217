import React, { useEffect, useState } from 'react';
import TController from './TController';
import { Box } from '@mui/material';
import { PersitedDatePicker } from '../../../../../../components/common';
import { useQuery } from '../../../../../../hooks/UseQuery';
import axios from 'axios';
import StackedBarChart from './StackedBarChart';
interface IProps {
  apiData: any;
  year: number;
}
const Summary = ({ apiData, year }: IProps) => {
  return (
    <div>
      <TController apiData={apiData} year={year} />
      <Box style={{ paddingBottom: '3rem', height: '45vh', width: '100vw' }}>
        <StackedBarChart apiData={apiData} year={year} />
      </Box>
    </div>
  );
};

export default Summary;
