export const intructionHeading = [
  {
    sr_no: 'S.NO',
    instrution: 'Instruction to operator',
    level: 'LEVEL',
    resp: 'Responsibility',
    response_time: 'Response Time',
  },
  {
    sr_no: '1',
    instrution:
      'Operator will check the parameters given in this checksheet and inform to team leader if any abnormality  observed.',
    level: '1',
    resp: 'Quality Engineer',
    response_time: '8 hrs.',
  },
  {
    sr_no: '2',
    instrution:
      'Team leader will correct the abnormality immediately and allow operator to run the machine.',
    level: '2',
    resp: 'Q.A Head',
    response_time: '24 hours',
  },
  {
    sr_no: '3',
    instrution: 'Operator will sign. after inspection',
    level: '3',
    resp: 'Plant Head',
    response_time: '24 hours',
  },
];
