import React, { useEffect, useState } from 'react';
import TController from './TController';
import { Box } from '@mui/material';
import { PersitedDatePicker } from '../../../../../../components/common';
import { useQuery } from '../../../../../../hooks/UseQuery';
import axios from 'axios';
import BarChart from './BarChart';
interface IProps {
  apiData: any;
  year: number;
  month: string;
  data: any;
}
const CustomerPpm = ({ apiData, year, month, data }: IProps) => {
  return (
    <div>
      {data?.total_produced_qty && (
        <>
          <TController
            apiData={apiData}
            year={year}
            month={month}
            totalProducedQty={data?.total_produced_qty}
          />
          <Box style={{ paddingBottom: '3rem', height: '40vh', width: '50vw' }}>
            <BarChart
              apiData={apiData}
              totalProducedQty={data?.total_produced_qty}
            />
          </Box>
        </>
      )}
    </div>
  );
};

export default CustomerPpm;
