import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { GraphHead } from '../../components/common/GraphHeading';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
);

interface IProps {
  labels: any[];
  milliporeData: any[];
  lcl: number;
  ucl: number;
  cl: number;
  title1: string;
  title2: string;
}
const MultipleLineChart = ({
  title1,
  title2,
  labels,
  milliporeData,
  lcl,
  ucl,
  cl,
}: IProps) => {
  const options: any = {
    plugins: {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: '',
      },
    },
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'DATE',
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          //stepSize: 5,
        },
        title: {
          display: true,
          text: title2,
        },
      },
    },
  };
  const data: any = {
    labels: labels,
    datasets: [
      {
        id: 1,
        label: title2,
        data: milliporeData,
        borderColor: '#87c38f',
        backgroundColor: '#87c38f',
      },
      {
        id: 2,
        label: 'UCL',
        data: new Array(milliporeData.length).fill(ucl),
        borderColor: '#fcbf49',
        backgroundColor: '#fcbf49',
      },
      {
        id: 3,
        label: 'LCL',
        data: new Array(milliporeData.length).fill(lcl),
        borderColor: '#fcbf49',
        backgroundColor: '#fcbf49',
      },
      {
        id: 3,
        label: 'CL',
        data: new Array(milliporeData.length).fill(cl),
        borderColor: '#ff595e',
        backgroundColor: '#ff595e',
      },
    ],
  };
  return (
    <GraphHead style={{ width: '80vw', margin: '14rem' }}>
      <p>{title1}</p>
      <Line data={data} options={options} />
    </GraphHead>
  );
};

export default MultipleLineChart;
