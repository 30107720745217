import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    date: moment(),
    part_id: null,
    customer_id: null,
    checked_qty: null,
    ok_qty: null,
    shift: '',
    stage: null,
    // bead_over_size: null,
    // bead_under_size: null,
    // bend: null,
    // bending_ng: null,
    // black_spot: null,
    // blockage: null,
    // barcode_ng: null,
    // bracket_ng: null,
    // burr_chips: null,
    // coating_ng: null,
    // clamp_out: null,
    // collapses: null,
    // connector_damage: null,
    // crack: null,
    // damage: null,
    // dent: null,
    // double_punching: null,
    // extra_material: null,
    // face_dent: null,
    // face_taper: null,
    // face_uneven: null,
    // fitment_out: null,
    // flaring_ng: null,
    // flaring_short_over_size_crack: null,
    // gap: null,
    // no_go_pass: null,
    // hose_cut: null,
    // hose_leakage: null,
    // hose_ng: null,
    // hose_shift: null,
    // leakage: null,
    // leakage_from_brazing: null,
    // leakage_near_clamp: null,
    // lining: null,
    // line_on_pipe: null,
    // miss_printing_ng: null,
    // miss_brazing: null,
    // nut_damage: null,
    // nut_dent: null,
    // nut_jaam: null,
    // nut_tight: null,
    // orientation_out: null,
    // oring_area_damage: null,
    // ovality: null,
    // over_brazing: null,
    // over_length: null,
    // over_size: null,
    // paraflaring_ng: null,
    // paraflaring_over_size: null,
    // pin_hole: null,
    // pin_out: null,
    // pipe_damage: null,
    // poor_plating: null,
    // punching_ng: null,
    // reverse_nut: null,
    // rubber_cut: null,
    // rust: null,
    // short_brazing: null,
    // short_length: null,
    // sleeve_loose: null,
    // sleeve_ng: null,
    // small_hole: null,
    // taper: null,
    // tapper: null,
    // thread_tight: null,
    // t_marking_missing: null,
    // tool_mark: null,
    // under_size: null,
    // welding_ng: null,
    // with_out_bracket: null,
    // wrinkle: null,
    // wrong_nut: null,
    total_rej: null,
    rejection_qty: null,
    reasons_for_rej: Array.from({ length: 15 }, () => ({
      reason: '',
      rej_qty: null,
    })),
    reason: '',

    remarks: null,
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  rejectionNote_date: moment(),
  total_rej_date_wise: null,
  processes: Array.from({ length: 20 }, () => ({
    ...lodash.cloneDeep(processes[0]),
  })),
};
