import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
import { useState } from 'react';
interface IProps {
  moduleData: any;
  isView: boolean;
}

const allmonth = [
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'january',
  'february',
  'march',
];

const TController = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, isView, setRender }} />
        <TBody {...{ moduleData, isView, allmonth }} />
        {/* <TFoot
          preparedBy={moduleData.report_prepared_by}
          approvedBy={moduleData.report_approved_by}
        /> */}
      </RenderTable>
    </Box>
  );
};

export default TController;
