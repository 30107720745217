import moment from 'moment';
import { useState } from 'react';

import { TableCompHead } from '../../../../../components/ui';
import formatDate from '../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
  customer: any;
}

const heading = [
  'S.No.',
  'Checkpoint',
  'Mode of Inspection',
  'Observation',
  'Status',
];
const THead = ({ moduleData, isView, customer }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={5}
        rowSpan={3}
        labels={['Doc No', 'REV. NO.', 'ISSUE DATE']}
        values={['FM/XLV/QA/03', '00', '04/01/2023']}
        heading="Dock Audit "
      />
      <tr>
        <th colSpan={5}>IMPERIAL AUTO INDUSTRIES LTD.</th>
      </tr>
      <tr>
        <th colSpan={5}>Plant- 45 Faridabad</th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          CUSTOMER : {customer}
        </th>
        <th colSpan={3} align="left">
          AUDIT DATE:{' '}
          {isView ? (
            formatDate(moduleData.d1)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d1)}
              onChange={(date) => {
                moduleData.d1 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          PART NAME :{' '}
          <TableInput
            isView={isView}
            name="s1"
            type="text"
            value={moduleData.s1}
            onChange={(e) => {
              moduleData.s1 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={3} align="left">
          VERIFIED BY:{' '}
          <TableInput
            isView={isView}
            name="s3"
            type="text"
            value={moduleData.s3}
            onChange={(e) => {
              moduleData.s3 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          PART No. :{' '}
          <TableInput
            isView={isView}
            name="s2"
            type="text"
            value={moduleData.s2}
            onChange={(e) => {
              moduleData.s2 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={3} align="left">
          APPROVED BY:
          <TableInput
            isView={isView}
            name="s4"
            type="text"
            value={moduleData.s4}
            onChange={(e) => {
              moduleData.s4 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        {heading.map((header) => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
