export const listConstant = {
  childHeader: 'Fixture Validation Plan list',
  addButtonLable: 'New Fixture Validation',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Fixture Validation Plan list',
  theadHeader: 'Fixture Validation PLAN',
  extracolSpan: 5,
};

export const checkListList = {
  childHeader: 'Fixture Validation STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'fixturesPlanRoute',
  gettableDataurl: 'fixturesPlanRoute/monthly',
  getPlanByid: 'fixturesPlanRoute',
  createPlanByid: 'fixturesPlanRoute',
  updatePlanByid: 'fixturesPlanRoute',
  sync: 'fixturesPlanRoute/handleSync',
  getAllCheckList: 'fixturesPlanRoute/getAllCheckList',
  checkListStatus: 'fixturesPlanRoute/handleStatus',
  checklistUpload: 'fixturesPlanRoute/handleUpload',
  checklistDeleteImage: 'fixturesPlanRoute/handleDeleteImage',
};

export const tilesConstant = {
  childHeaderText: 'FIXURES PLAN',
};

export const navigationConstant = {
  createPlanSchedule: '/common/FixturesPlanCreate',
};
