import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController
);

interface IProps {
  moduleData: any;
  ParameterIndex: number;
}
const BarLineChart = ({ moduleData, ParameterIndex }: IProps) => {
  const options: any = {
    plugins: {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: '',
      },
    },
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: moduleData?.list?.[ParameterIndex]?.item || '',
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          //stepSize: 5,
        },
        title: {
          display: true,
          text: 'DATA',
        },
      },
    },
  };
  const kraData = [
    moduleData?.list?.[ParameterIndex].april,
    moduleData?.list?.[ParameterIndex].may,
    moduleData?.list?.[ParameterIndex].june,
    moduleData?.list?.[ParameterIndex].july,
    moduleData?.list?.[ParameterIndex].august,
    moduleData?.list?.[ParameterIndex].september,
    moduleData?.list?.[ParameterIndex].october,
    moduleData?.list?.[ParameterIndex].november,
    moduleData?.list?.[ParameterIndex].december,
    moduleData?.list?.[ParameterIndex].january,
    moduleData?.list?.[ParameterIndex].february,
    moduleData?.list?.[ParameterIndex].march,
  ];
  const data: any = {
    labels: [
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'Jan',
      'Feb',
      'Mar',
    ],
    datasets: [
      {
        type: 'line',
        label: 'KRA per month',
        fill: false,
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 2,
        data: kraData,
      },
    ],
  };
  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarLineChart;
