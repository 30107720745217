import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import { headFirst, headSecond, headThird } from '../../helpers/headData';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={16}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Dated']}
        values={['F-QA-12', '01', '22-03-2023']}
        heading="JOB CARD"
      />
      <tr>
        <th colSpan={16} align="left">
          Item Code:
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.part_id?.part_number}
        </th>
      </tr>
      <tr>
        <th colSpan={16} align="left">
          Customer Name:
          {moduleData.customer_name
            ? moduleData.customer_name
            : moduleData.customer_id?.customer_name}
        </th>
      </tr>
      <tr style={{ height: '40px', backgroundColor: '#63C4EF' }}>
        <th colSpan={16}></th>
      </tr>
      <tr>
        {headFirst.map((head) => (
          <th colSpan={head.colSpan}>{head.name}</th>
        ))}
        <th colSpan={2}>Date</th>
      </tr>
      <tr>
        {headFirst.map((head) => (
          <th colSpan={head.colSpan} align="left">
            <TableInput
              name={head.param}
              type={head.type}
              value={moduleData[head.param]}
              onChange={(e) => {
                moduleData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </th>
        ))}
        <th colSpan={2}>
          {isView ? (
            formatDate(moduleData.pdir_date)
          ) : (
            <DatePickerMui
              value={moment(moduleData.pdir_date)}
              onChange={(date) => {
                moduleData._date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr style={{ height: '40px', backgroundColor: '#63C4EF' }}>
        <th colSpan={16}></th>
      </tr>
      <tr>
        {headSecond.map((head) => (
          <th colSpan={2}>{head.name}</th>
        ))}
      </tr>
      <tr>
        {headSecond.map((head) => (
          <th colSpan={2} align="left">
            <TableInput
              name={head.param}
              type={head.type}
              value={moduleData[head.param]}
              onChange={(e) => {
                moduleData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </th>
        ))}
      </tr>
      <tr style={{ height: '40px', backgroundColor: '#63C4EF' }}>
        <th colSpan={16}></th>
      </tr>
      <tr>
        {headThird.map((head) => (
          <th colSpan={head.colSpan}>{head.name}</th>
        ))}
      </tr>
      <tr>
        {headThird.map((head) => (
          <th colSpan={head.colSpan} align="left">
            <TableInput
              name={head.param}
              type={head.type}
              value={moduleData[head.param]}
              onChange={(e) => {
                moduleData[e.target.name] = e.target.value;
                setRender((prev) => !prev);
              }}
              isView={isView}
            />
          </th>
        ))}
      </tr>

      <tr style={{ height: '40px', backgroundColor: '#63C4EF'}}>
      <th colSpan={16}></th>
      </tr>
      <tr>
        {[
          'S.NO',
          'M/C DEVICE JIG TOOL FOR MFG./ OPERATOR',
          'CALIB./ REF. NO.',
          'PRODUCT PROCESS',
          'SP CHR',
          'PRODUCT PROCESS SPECIFICATION TOLERANCE',
          'INSPECTION TECHNIQUE',
          'CALIB./REF NO.',
          'SMPL QTY./FREQ.',
        ].map((head) => (
          <th rowSpan={2} style={{ textTransform: 'uppercase' }}>
            {head}
          </th>
        ))}
        <th colSpan={6} style={{ textTransform: 'uppercase' }}>
          Observations
        </th>
        <th style={{ textTransform: 'uppercase' }} rowSpan={2}>
          Remarks
        </th>
      </tr>
      <tr>
        {Array.from({ length: 5 }, (_, index) => (
          <th style={{ width: '90px' }} key={index + 'Heading'}>
            {index + 1}
          </th>
        ))}
        <th style={{ textTransform: 'uppercase' }}>Status</th>
      </tr>
    </thead>
  );
};

export default THead;
