interface Ifoot {
  apiData: any;
  totalProducedQty: number;
  target: number;
  average: number;
}
const TFoot = ({ apiData, totalProducedQty, average }: Ifoot) => {
  return (
    <tfoot>
      <tr style={{ background: 'yellow' }}>
        <th>Average</th>
        <th>{average}</th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
