import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
} from 'chart.js';

import { Bar, Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  LineController,
  LinearScale
);

interface IProps {
  apiData: any;
  totalProducedQty: number;
  // year: number;
}
const ParetoChart = ({ apiData, totalProducedQty }: IProps) => {
  let cumulativeFrequency = 0;
  const cumulativePercentageData = apiData.map(
    (item: { percentage: number }) => {
      cumulativeFrequency += +item.percentage;
      return cumulativeFrequency;
    }
  );
  const ppmData = apiData.map((item: { frequency: number }) => {
    return ((item.frequency * 1000000) / totalProducedQty).toFixed(0);
  });

  const chartData: any = {
    labels: apiData.map((item: { defectName: any }) => item.defectName),
    datasets: [
      {
        label: 'PPM',
        data: ppmData,
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
      {
        label: 'Cumulative Percentage',
        data: cumulativePercentageData,
        type: 'line',
        borderColor: 'rgba(255, 99, 132, 0.6)',
        fill: false,
        yAxisID: 'y-axis-percentage',
      },
    ],
  };

  const chartOptions: any = {
    plugins: {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'Defect wise pareto analysis',
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Defects',
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'PPM',
        },
      },
      'y-axis-percentage': {
        beginAtZero: true,
        position: 'right',
        title: {
          display: true,
          text: 'Cumulative Percentage',
        },
        grid: {
          display: false,
        },
        max: 100,
      },
    },
  };

  return (
    <div>
      <Bar data={chartData} options={chartOptions} />
    </div>
  );
};

export default ParetoChart;
