import { BsJournalBookmarkFill } from 'react-icons/bs';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
const tiles = [
  {
    sNo: 1,
    link: '/common/productionLogBookList?documentType=firstFloor&firstFloorType=tube_section',
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="black" />,
    p: 'TUBE SECTION',
    span: '',
    isIcon: true,
  },
  {
    sNo: 2,
    link: `/common/productionLogBookList?documentType=firstFloor&firstFloorType=amg`,
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="black" />,
    p: 'AMG',
    span: '',
    isIcon: true,
  },
  {
    sNo: 2,
    link: `/common/productionLogBookList?documentType=firstFloor&firstFloorType=jd_line`,
    isLink: true,
    icon: <BsJournalBookmarkFill size="5rem" color="black" />,
    p: 'JD LINE',
    span: '',
    isIcon: true,
  },
];

const FirstFloorTiles = () => {
  return (
    <>
      <ChildHeader text="First Floor" />
      <RenderTiles height="100vh" tilesObj={tiles} />
    </>
  );
};

export default FirstFloorTiles;
