import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import {
  SaveButton,
  SubmitButton,
} from '../../../../components/common/button/CustomButtons';
import formatDate from '../../../../components/common/formatDate';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useConfirm from '../../../../components/common/useConfirm';

interface MyData {
  moduleData: any; // replace PdirType with the type of your `${moduleConstant.api}` data
  // isLoading: boolean;
  setModuleData: any;
  error?: string | null;
}
const Create = () => {
  const [moduleData, setModuleData] = useState<any>({
    matrix: {
      required_exp: '',
      required_qualification: '',
      age: '',
      process_1: [
        {
          technical_competencies: '',
          proficiency_level_ideal: '',
          proficiency_level_actual: '',
          remarks: '',
        },
      ],
      process_2: [
        {
          behavioural_competencies: '',
          proficiency_level_ideal: '',
          proficiency_level_actual: '',
          remarks: '',
        },
      ],
    },
  });
  const { id } = useParams();
  const { state } = useAuth();
  const isView = useQuery('isView') === 'true' ? true : false;
  const navigate = useNavigate();
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?',
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/employeeRoute/${id}`)
        .then((res) => {
          const data = res?.data;
          if (
            data?.matrix?.process_1?.length === 0 &&
            data?.matrix?.process_2?.length === 0
          ) {
            data.matrix = {
              process_1: [
                {
                  technical_competencies: '',
                  proficiency_level_ideal: '',
                  proficiency_level_actual: '',
                  remarks: '',
                },
              ],
              process_2: [
                {
                  behavioural_competencies: '',
                  proficiency_level_ideal: '',
                  proficiency_level_actual: '',
                  remarks: '',
                },
              ],
            };
          }
          setModuleData(data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async (status: string) => {
    const ans = await confirmSubmit();
    if (!ans) return;
    moduleData.status = status;
    await axios
      .put(`/api/employeeRoute/${id}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('Updated Successfully');
          navigate(-1);
        }
      })
      .catch((err) => console.log(err));
  };

  const handelDeleteRows = async (process_idx: string, rowIdx: number) => {
    // if (!ans) return;
    let data = { ...moduleData };
    data.matrix[process_idx].splice(rowIdx, 1);
    setModuleData(data);
  };

  return (
    <Box>
      <Box>
        <ChildHeader text="COMPETENCE MATRIX">
          {!isView && (
            <>
              <SaveButton
                label="Save & Continue"
                onClick={() => handleSumbit('draft')}
              />
              <SubmitButton
                label="Send For Approval"
                onClick={() => handleSumbit('pending')}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController {...{ moduleData, isView, handelDeleteRows }} />
      <DialogSubmit />
    </Box>
  );
};

export default Create;
