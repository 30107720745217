import { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';

interface IProps {
  moduleData: any;
  isView: boolean;
  machineData: any;
  handelDeleteRows: any;
}

const getAdheranceMarks = (process: any) => {
  return +((process.delivery_adherance_perc * 15) / 100).toFixed(2) || 0;
};
const getMessageMarks = (process: any) => {
  if (process.delivery_customer_message === 'A') return 5;
  if (process.delivery_customer_message === 'B') return 0;
  if (process.delivery_customer_message === 'C') return -30;
  return '';
};
const getOtdMarks = (process: any) => {
  if (process.delivery_otd_days == 0) return 10;
  if (process.delivery_otd_days > 0 && process.delivery_otd_days <= 4) return 5;
};
const getTotalDeliveryMarks = (process: any) => {
  return (
    process.delivery_adherance_marks +
    process.delivery_customer_marks +
    process.delivery_otd_marks
  );
};
const getQualityeMarks = (process: any) => {
  return +((process.quality_lot_rejection_perc * 20) / 100).toFixed(2) || 0;
};
const getQualityStatusMarks = (process: any) => {
  if (process.quality_customer_status_qty == 0) return 5;
  if (process.quality_customer_status_qty > 0) return -25;
};
const getQualityDisruptionMarks = (process: any) => {
  if (process.quality_customer_disruption_qty == 0) return 5;
  if (process.quality_customer_disruption_qty > 0) return -25;
};
const getQualityRecallMarks = (process: any) => {
  if (process.quality_recall_qty == 0) return 5;
  if (process.quality_recall_qty > 0) return -35;
};
const getTotalQualityMarks = (process: any) => {
  return (
    process.quality_lot_rejection_marks +
    process.quality_customer_status_marks +
    process.quality_customer_disruption_marks +
    process.quality_recall_marks
  );
};
const getPremiumFreightMarks = (process: any) => {
  if (process.premium_freight_qty == 0) return 5;
  if (process.premium_freight_qty == 1) return 3;
  return 0;
};
const getCustomerComplaintMarks = (process: any) => {
  if (process.customer_complaint_qty == 0) return 10;
  if (process.customer_complaint_qty == 1) return 5;
  if (process.customer_complaint_qty > 1) return -10;
  return 0;
};
const getLineRejectionMarks = (process: any) => {
  if (process.line_rejection_qty == 0) return 10;
  if (process.line_rejection_qty >= 1 && process.line_rejection_qty <= 10)
    return 5;
  return 0;
};
const getOverallRating = (process: any) => {
  return (
    getTotalDeliveryMarks(process) +
    getTotalQualityMarks(process) +
    process.premium_freight_marks +
    process.customer_complaint_marks +
    process.line_rejection_marks +
    process.warranty_marks
  );
};
const getWarantyMarks = (process: any) => {
  if (process.warranty_qty == 0) return 10;
  if (process.warranty_qty == 1) return 5;
  if (process.warranty_qty > 1) return -10;
  return 0;
};
const getOverAllGrade = (process: any) => {
  const marks = getOverallRating(process);
  if (marks >= 90) return 'A';
  if (marks >= 80) return 'B';
  if (marks >= 60) return 'C';

  return 'D';
};
const TBody = ({
  moduleData,
  isView,
  machineData,
  handelDeleteRows,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 'module data'}>
              <td
                width={'100px'}
                style={{
                  minWidth: '100px',
                  fontWeight: 'bold',
                  background: '#f3d9fa',
                }}
              >
                {index + 1}
              </td>
              <td style={{ minWidth: '200px', background: '#f3d9fa' }}>
                <DatePickerMui
                  label=""
                  value={moment(process.process_date || '')}
                  onChange={(date) => {
                    process.process_date = date;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td
                style={{ minWidth: '200px', background: '#f3d9fa' }}
                colSpan={1}
              >
                <AutocompleteMuiCustom
                  id="Vendor"
                  label=""
                  option_name="vender_name"
                  arrayofObj={machineData}
                  value={process.vendor_id_temp || process.vendor_id || ''}
                  onChange={(e, value) => {
                    process.vendor_id = value?._id;
                    process.vendor_id_temp = value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', background: '#f3d9fa' }}>
                {process?.vendor_id_temp?.vender_code ||
                  process?.vendor_id?.vender_code}
              </td>
              <td style={{ minWidth: '100px', background: '#e5dbff' }}>
                <TableInput
                  isView={isView}
                  name="delivery_adherance_perc"
                  type="number"
                  value={process.delivery_adherance_perc}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    process.delivery_adherance_marks =
                      getAdheranceMarks(process) || 0;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', background: '#e5dbff' }}>
                {process.delivery_adherance_marks}
              </td>
              <td style={{ minWidth: '100px', background: '#fff3bf' }}>
                {isView ? (
                  process.delivery_customer_message
                ) : (
                  <CustomSelect
                    name="delivery_customer_message"
                    value={process.delivery_customer_message || ''}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      process.delivery_customer_marks =
                        getMessageMarks(process) || 0;
                      setRender((prev) => !prev);
                    }}
                  >
                    <MenuItem value={'A'}>A</MenuItem>
                    <MenuItem value={'B'}>B</MenuItem>
                    <MenuItem value={'C'}>C</MenuItem>
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '100px', background: '#fff3bf' }}>
                {process.delivery_customer_marks}
              </td>
              <td style={{ minWidth: '100px', background: '#ffe8cc' }}>
                <TableInput
                  isView={isView}
                  name="delivery_otd_days"
                  type="number"
                  value={process.delivery_otd_days}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    process.delivery_otd_marks = getOtdMarks(process) || 0;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', background: '#ffe8cc' }}>
                {process.delivery_otd_marks}
              </td>
              <td style={{ minWidth: '100px', background: '#d3f9d8' }}>
                {getTotalDeliveryMarks(process)}
              </td>
              <td style={{ minWidth: '100px', background: '#e5dbff' }}>
                <TableInput
                  isView={isView}
                  name="quality_lot_rejection_perc"
                  type="number"
                  value={process.quality_lot_rejection_perc}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    process.quality_lot_rejection_marks =
                      getQualityeMarks(process) || 0;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', background: '#e5dbff' }}>
                {process.quality_lot_rejection_marks}
              </td>
              <td style={{ minWidth: '100px', background: '#fff3bf' }}>
                <TableInput
                  isView={isView}
                  name="quality_customer_status_qty"
                  type="number"
                  value={process.quality_customer_status_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    process.quality_customer_status_marks =
                      getQualityStatusMarks(process) || 0;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', background: '#fff3bf' }}>
                {process.quality_customer_status_marks}
              </td>

              <td style={{ minWidth: '100px', background: '#ffe8cc' }}>
                <TableInput
                  isView={isView}
                  name="quality_customer_disruption_qty"
                  type="number"
                  value={process.quality_customer_disruption_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    process.quality_customer_disruption_marks =
                      getQualityDisruptionMarks(process) || 0;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', background: '#ffe8cc' }}>
                {process.quality_customer_disruption_marks}
              </td>

              <td style={{ minWidth: '100px', background: '#ffe3e3' }}>
                <TableInput
                  isView={isView}
                  name="quality_recall_qty"
                  type="number"
                  value={process.quality_recall_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    process.quality_recall_marks =
                      getQualityRecallMarks(process) || 0;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', background: '#ffe3e3' }}>
                {process.quality_recall_marks}
              </td>
              <td style={{ minWidth: '100px', background: '#d3f9d8' }}>
                {getTotalQualityMarks(process)}
              </td>
              <td style={{ minWidth: '100px', background: '#e5dbff' }}>
                <TableInput
                  isView={isView}
                  name="premium_freight_qty"
                  type="number"
                  value={process.premium_freight_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    process.premium_freight_marks =
                      getPremiumFreightMarks(process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', background: '#e5dbff' }}>
                {process.premium_freight_marks}
              </td>

              <td style={{ minWidth: '100px', background: '#fff3bf' }}>
                <TableInput
                  isView={isView}
                  name="customer_complaint_qty"
                  type="number"
                  value={process.customer_complaint_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    process.customer_complaint_marks =
                      getCustomerComplaintMarks(process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', background: '#fff3bf' }}>
                {process.customer_complaint_marks}
              </td>

              <td style={{ minWidth: '100px', background: '#ffe8cc' }}>
                <TableInput
                  isView={isView}
                  name="warranty_qty"
                  type="number"
                  value={process.warranty_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    process.warranty_marks = getWarantyMarks(process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', background: '#ffe8cc' }}>
                {process.warranty_marks}
              </td>

              <td style={{ minWidth: '100px', background: '#ffe3e3' }}>
                <TableInput
                  isView={isView}
                  name="line_rejection_qty"
                  type="number"
                  value={process.line_rejection_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    process.line_rejection_marks =
                      getLineRejectionMarks(process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', background: '#ffe3e3' }}>
                {process.line_rejection_marks}
              </td>
              <td style={{ minWidth: '100px', background: '#f3d9fa' }}>
                {getOverallRating(process)}
              </td>
              <td style={{ minWidth: '100px', background: '#d3f9d8' }}>
                {getOverAllGrade(process)}
              </td>
              <td style={{ minWidth: '100px', background: '#f3d9fa' }}>
                <TableInput
                  isView={isView}
                  name="remark"
                  type="text"
                  value={process.remark}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
