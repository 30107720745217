import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { convToTime } from '../../../../../utils/helpers';

interface IProps {
  moduleData: any;
  isView: boolean;
  tabValue: number;
}
const THead = ({ tabValue, moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={21}
        rowSpan={3}
        labels={['Doc No', 'Rev No.', 'Rev Date']}
        values={['FM/QA/V004', '03', '']}
        heading="PIR REPORT"
      />
      <tr>
        <th colSpan={7} align="left">
          ITEM CODE:
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.part_id?.part_number}
        </th>
        <th colSpan={7} align="left">
          CUSTOMER NAME:
          {moduleData.customer_name
            ? moduleData.customer_name
            : moduleData.customer_id?.customer_name}
        </th>
        <th colSpan={7} align="left">
          LOT SIZE:
          <TableInput
            name="lot_size"
            type={moduleData.lot_size}
            value={moduleData.lot_size}
            onChange={(e) => {
              moduleData[e.target.name] = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={7}>
          DATE:
          {isView ? (
            formatDate(moduleData.pir_date)
          ) : (
            <DatePickerMui
              value={moment(moduleData.pir_date)}
              onChange={(date) => {
                moduleData.pir_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={7} align="left">
          ACCEPETED QTY:
          <TableInput
            name="accepted_qty"
            type={moduleData.accepted_qty}
            value={moduleData.accepted_qty}
            onChange={(e) => {
              moduleData[e.target.name] = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={7} align="left">
          REJECTED QTY:
          <TableInput
            name="rejected_qty"
            type={moduleData.rejected_qty}
            value={moduleData.rejected_qty}
            onChange={(e) => {
              moduleData[e.target.name] = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        {['S.NO', 'PARAMETER', 'SPECIFICATION', 'METHOD'].map((head) => (
          <th rowSpan={2} style={{ textTransform: 'uppercase' }}>
            {head}
          </th>
        ))}
        <th colSpan={5} style={{ textTransform: 'uppercase' }}>
          SAMPLE INSPECTION REPORT
        </th>
        <th colSpan={10} style={{ textTransform: 'uppercase' }}>
          HOURLY
        </th>
        <th
          style={{ textTransform: 'uppercase', minWidth: '100px' }}
          rowSpan={2}
        >
          STATUS
        </th>
        <th
          style={{ textTransform: 'uppercase', minWidth: '100px' }}
          rowSpan={2}
        >
          REMARKS
        </th>
      </tr>
      <tr>
        {[1, 2, 3, 4, 5].map((head) => (
          <th style={{ minWidth: '100px' }}>{head}</th>
        ))}
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((head, timeHeadIdx) => (
          <th style={{ minWidth: '140px' }}>
            {isView ? (
              convToTime(moduleData.pirs[tabValue].times[timeHeadIdx])
            ) : (
              <TimePicker
                value={moment(
                  moduleData.pirs[tabValue].times[timeHeadIdx] || ''
                )}
                onChange={(date: any) => {
                  moduleData.pirs[tabValue].times[timeHeadIdx] = date;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
