export const listConstant = {
  childHeader: 'Material Testing Plan (O-ring & Grommet) List',
  addButtonLable: 'New Material TEST PLAN',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Material Testing Plan (O-ring & Grommet)',
  theadHeader: 'Material Testing Plan - O-ring & Grommet',
  extracolSpan: 6,
};

export const checkListList = {
  childHeader: 'MATERIAL TEST PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'materialTestPlanOringAndGrommet',
  gettableDataurl: 'materialTestPlanOringAndGrommet/monthly',
  getPlanByid: 'materialTestPlanOringAndGrommet',
  createPlanByid: 'materialTestPlanOringAndGrommet',
  updatePlanByid: 'materialTestPlanOringAndGrommet',
  sync: 'materialTestPlanOringAndGrommet/handleSync',
  getAllCheckList: 'materialTestPlanOringAndGrommet/getAllCheckList',
  checkListStatus: 'materialTestPlanOringAndGrommet/handleStatus',
  checklistUpload: 'materialTestPlanOringAndGrommet/handleUpload',
  checklistDeleteImage: 'materialTestPlanOringAndGrommet/handleDeleteImage',
};

export const tilesConstant = {
  childHeaderText: 'Material Testing Plan (O-ring & Grommet)',
};

export const navigationConstant = {
  createPlanSchedule: '/common/materialTestPlanOringAndGrommetCreate',
};
