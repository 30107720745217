export const listConstant = {
  childHeader: 'BOP PLAN List',
  addButtonLable: 'New BOP PLAN',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'BOP PLAN',
  theadHeader: 'RAW MATERIAL TEST PLAN - BOP',
  extracolSpan: 5,
};

export const checkListList = {
  childHeader: 'BOP PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'BOPPlan',
  gettableDataurl: 'BOPPlan/monthly',
  getPlanByid: 'BOPPlan',
  createPlanByid: 'BOPPlan',
  updatePlanByid: 'BOPPlan',
  sync: 'BOPPlan/handleSync',
  getAllCheckList: 'BOPPlan/getAllCheckList',
  checkListStatus: 'BOPPlan/handleStatus',
  checklistUpload: 'BOPPlan/handleUpload',
  checklistDeleteImage: 'BOPPlan/handleDeleteImage',
};

export const tilesConstant = {
  childHeaderText: 'BOP PLAN',
};

export const navigationConstant = {
  createPlanSchedule: '/common/BOPPlanPlanCreate',
};
