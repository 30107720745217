import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { IModule } from '../../interface';

interface IProps {
  isView: boolean;
  moduleData: IModule;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={10}
        rowSpan={3}
        labels={[]}
        values={[]}
        heading="INSPECTION STANDARD "
      />
      <tr>
        <th colSpan={2} align="left">
          Date:
        </th>
        <th colSpan={7} style={{ textAlign: 'left' }}>
          {isView ? (
            formatDate(moduleData.insp_standard_date)
          ) : (
            <DatePickerMui
              value={moment(moduleData.insp_standard_date)}
              onChange={(date) => {
                moduleData.insp_standard_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          ERP No. / Drawing No.
        </th>
        <th colSpan={7} align="left">
          <TableInput
            name="drawing_no"
            type="text"
            value={moduleData.drawing_no}
            onChange={(e) => {
              moduleData.drawing_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Rev No.:
        </th>
        <th colSpan={7} align="left">
          <TableInput
            name="rev_no"
            type="text"
            value={moduleData.rev_no}
            onChange={(e) => {
              moduleData.rev_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Description:
        </th>
        <th colSpan={7} align="left">
          <TableInput
            name="description"
            type="text"
            value={moduleData.description}
            onChange={(e) => {
              moduleData.description = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Insp. Std. Rev No.:
        </th>
        <th colSpan={7} align="left">
          <TableInput
            name="insp_std_rev_no"
            type="text"
            value={moduleData.insp_std_rev_no}
            onChange={(e) => {
              moduleData.insp_std_rev_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Fox Pro No. / Ref. Drg. No.
        </th>
        <th colSpan={7} align="left">
          <TableInput
            name="ref_drg_no"
            type="text"
            value={moduleData.ref_drg_no}
            onChange={(e) => {
              moduleData.ref_drg_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        {[
          'S.NO',
          'PARAMETER',
          'COF C',
          'SPECIFICATION',
          'INSTRUMENT TYPE',
          'FREQUENCY',
          'FORMAT NO.',
          'RESPONSIBLITY',
          'ACTION',
        ].map((heading) => (
          <th>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
