import { StyledTableHeaderRow } from '../../../../../components/common/rowFreeze';
import TableCompHead from '../../../../../components/ui/TableCompHead';

interface IProps {
  moduleData: any;
  isView: boolean;
  assignData: any;
}

const theadData = [
  'SR. NO.',
  'LOCK',
  'BIN NO.',
  'PART NO.',
  'ERP No. / Drawing No.',
  'Fox Pro No. / Ref. Drg. No.',
  'QTY',
];
const theadData2 = ['SR. NO.', 'BIN NO.', 'PART NO.', 'QTY', 'ACTION'];

const THead = ({ moduleData, isView, assignData }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={assignData ? 7 : 7}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="STORE SETUP"
      />
      {!assignData && (
        <StyledTableHeaderRow>
          {theadData.map((heading, index) => (
            <th key={heading + index}>{heading}</th>
          ))}
        </StyledTableHeaderRow>
      )}
      {assignData && (
        <>
          <tr>
            <th style={{ background: '#51cf66' }}>Pick Me First</th>
            <th style={{ background: '#ffe066' }}>Pick Me Second</th>
            <th style={{ background: '#ffc078' }}>Pick Me Third</th>
            <th colSpan={2} style={{ background: '#fa5252' }}>
              Pick Me In The End
            </th>
          </tr>
          <StyledTableHeaderRow>
            {theadData2.map((heading, index) => (
              <th key={heading + index}>{heading}</th>
            ))}
          </StyledTableHeaderRow>
        </>
      )}
    </thead>
  );
};

export default THead;
