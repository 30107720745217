import { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { initialState } from './helpers/initialState';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import { DeleteIconButton } from '../../../components/common/button/CustomIconButton';

const View = () => {
  const [moduleData, setModuleData] = useState<any>(
    lodash.cloneDeep(initialState),
  );
  const navigate = useNavigate();
  const { id } = useParams();
  const annual_month = useQuery('annual_date') || '';
  const isView = useQuery('isView') === 'true' ? true : false;

  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?',
  );
  const [loader, setLoader] = useState<boolean>(false);

  useEffect(() => {
    if (annual_month) {
      axios
        .get(`/api/pallMachineChecklistRoute/${annual_month}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async () => {
    return new Promise(async (resolve, reject) => {
      const ans = await confirmSubmit();
      if (!ans) return;
      if (moduleData._id) {
        await axios
          .put(`/api/pallMachineChecklistRoute/${moduleData._id}`, moduleData)
          .then((res) => {
            if (res.data.status === 'success') {
              alert('Updated Successfully');

              resolve(1);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        if (!annual_month) {
          return alert('Please pick annual year?');
        }
        moduleData.annual_month = annual_month;
        await axios
          .post('/api/pallMachineChecklistRoute', moduleData)
          .then((res) => {
            if (res.data.status === 'success') {
              alert('created Successfully');
              resolve(1);
              navigate(-1);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      }
    });
  };

  const uploadImage = (e: any) => {
    if (!e.target.files.length) return;
    setLoader(true);
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    axios
      .patch(
        `/api/pallMachineChecklistRoute/uploadImage/${moduleData._id}`,
        formData,
      )
      .then((res) => {
        if (res.data) {
          alert('image uploaded successfully');
          const values = { ...moduleData };
          values.img = res.data?.url;
          setModuleData(values);
          e.target.fileInput.value = '';
        }
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  return (
    <>
      <Box>
        <Loader loader={loader} />
        <ChildHeader text="PALL MACHINE CHECK SHEET">
          {!isView && (
            <>
              {moduleData._id && (
                <CustomUploadButton
                  lable={'Upload Image'}
                  accept="image/png,image/jpeg,image/jpg"
                  onChange={uploadImage}
                />
              )}
              {moduleData.img && (
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => {
                    const values = { ...moduleData };
                    values.img = '';
                    setModuleData(values);
                  }}
                />
              )}
              <SubmitButton label="Submit" onClick={() => handleSumbit()} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handleSumbit,
        }}
      />

      <DialogSubmit />
    </>
  );
};

export default View;
