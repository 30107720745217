import axios from 'axios';
import React, { useEffect, useState } from 'react';
import GridInputs from '../../../../components/common/inputs/GridInputs';
import { SubmitButton } from '../../../../components/common/button/CustomButtons';

const PercentageModal = ({ id, setOpenPercModal }: any) => {
  const [render, setRender] = useState(false);
  const [moduleData, setModuleData] = useState<any>({});

  useEffect(() => {
    axios
      .get(`/api/customerSatisfactionSurvey/${id}`)
      .then((res) => {
        if (res.data) {
          setModuleData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setModuleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    if (id) {
      await axios
        .put(`/api/customerSatisfactionSurvey/${id}`, moduleData)
        .then((res) => {
          setOpenPercModal(false);
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <GridInputs
        id={'customer_satisfaction_percentage'}
        name={'customer_satisfaction_percentage'}
        html_for={'customer_satisfaction_percentage'}
        label_name={'Customer Satisfaction Percentage:'}
        input_type={'number'}
        focusType={'onblur'}
        value={moduleData?.customer_satisfaction_percentage || ''}
        onChange={handleData}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
    </>
  );
};

export default PercentageModal;
