export const ImtWork = [
  //IMT WORK
  {
    s_no: 'A',
    full_row: 'At  Reciept Inspection stage',
  },
  {
    s_no: '1',
    description: 'Banjo I.D',
    spec_mm: 'As Per Incoming Inspection Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: '2',
    description: 'Sub Assy',
    spec_mm: 'As Per Incoming Inspection Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: '3',
    description: 'Washer',
    spec_mm: 'As Per Incoming Inspection Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: '4',
    description: 'Washer',
    spec_mm:
      'Free from damage,crack and fitmnet should be smoothly with mating part.',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: '4',
    description: 'Grommet',
    spec_mm: 'As Per Incoming Inspection Standard',
    measurement_equipments: 'Vernier Caliper',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: '4',
    description: 'Grommet',
    spec_mm:
      'Free from damage,crack and fitmnet should be smoothly with mating part.',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: '5',
    description: 'Grommet',
    spec_mm: 'As Per Incoming Inspection Standard',
    measurement_equipments: 'Vernier Caliper',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: '5',
    description: 'Grommet',
    spec_mm: 'As Per Incoming Inspection Standard',
    measurement_equipments: 'Vernier Caliper',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: '6',
    description: 'Oetiker',
    spec_mm: 'As Per Incoming Inspection Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: '7',
    description: 'Araymond Connector',
    spec_mm: 'As Per Incoming Inspection Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: '8',
    description: 'O-ring',
    spec_mm: 'As Per Incoming Inspection Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: '9',
    description: 'Oetiker',
    spec_mm:
      'Sleeve,Bracket,Clamp,Wire Mesh, Cap,Protecting cap in sleeve area/rolling area  etc if applicable',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: '10',
    description: 'Nut/Flange/Bush/Ring',
    spec_mm: 'As Per Incoming Inspection Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: '11',
    description: 'Pipe OD/Tube OD',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'Vernier Caliper',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },

  {
    s_no: 'B',
    full_row: 'At  Sub-Assembly stage',
  },
  {
    s_no: 12,
    description: 'Washer',
    spec_mm: 'As Per Incoming Inspection Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 13,
    description: 'End Cap Type',
    spec_mm:
      'Free from damage,crack and fitmnet should be smoothly with mating part.',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 14,
    description: 'Grommet ',
    spec_mm: 'As Per Incoming Inspection Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 15,
    description: 'O-ring',
    spec_mm: 'As Per Incoming Inspection Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 16,
    description: 'Sleeve,Bracket,Clamp etc if applicable',
    spec_mm: 'As Per Incoming Inspection Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 17,
    description: 'Pipe OD/Tube OD',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'Vernier Caliper',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 18,
    description: 'Pipe/Tube Wall thickness ',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'Vernier Caliper',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 19,
    description: 'Hose I.D',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'Vernier Caliper',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 20,
    description: 'Hose Wall thickness',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'Low Pressure Vernier',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 21,
    description: 'Hose Length',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'By Fixture',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 22,
    description: 'Hose Type',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 23,
    description: 'Hose Marking ',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 24,
    description: 'Part Profile /Assy. Length',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'By Fixture',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 25,
    description: 'Bend Collapse',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'Vernier Caliper',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },

  {
    s_no: 'C',
    full_row: 'At Final Assembly Stage',
  },

  {
    s_no: 26,
    description: 'Appearance',
    spec_mm: 'Free From Damage,Crack,Scratch,Tool Marks,Wrinkles etc.',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 27,
    description: 'Orientation ',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: '  As per Fixture ',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 28,
    description: 'Supplier Identification (Batch Code /Part No)',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 29,
    description: 'Leakage Tesing / Rate ',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 30,
    description: 'Blockage',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'Ball Pass from one end to other.',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 31,
    description: 'Assembly Length ',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: '  As per Fixture ',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 32,
    description: 'Cap Plug',
    spec_mm:
      'Free from damage,crack and fitmnet should be smoothly with mating part.',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 32,
    description: 'Final Inspection ',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 'D',
    description: 'At Packing Stage',
    spec_mm: null,
    measurement_equipments: null,
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 33,
    description: 'VCI Bag',
    spec_mm: 'Free from burst and should be properly sealed',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 34,
    description: 'Qty of  hose Assembly in Poly/VCI Bag',
    spec_mm: 'As Per Job Card/Packing Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 35,
    description: 'Labeling on Poly/VCI bag',
    spec_mm: 'As Per Job Card/Packing Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 36,
    description: 'Assembly. Qty in box.',
    spec_mm: 'As Per Job Card/Packing Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 37,
    description: 'Labeling on corrugrated box',
    spec_mm: 'As Per Job Card/Packing Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 38,
    description: 'Sticker  ',
    spec_mm: 'As Per Job Card/Packing Standard',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 39,
    description: 'Millipore/ Leica',
    spec_mm: 'As per drawing/Job Card',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },

  {
    s_no: 'E',
    full_row: 'Dispatch',
  },
  {
    s_no: 40,
    description: 'Condition of pallet',
    spec_mm: 'Free From Damage,Crack etc.',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 'F',
    full_row: 'Transportation Mode',
  },
];

export const IMT = [
  {
    s_no: 'A',
    full_row: 'At  Reciept Inspection stage',
  },
  {
    s_no: 1,
    description: 'Banjo I.D',
    spec_mm: null,
    measurement_equipments: 'TPG/VC/PPG',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 2,
    description: 'Sub Assy',
    spec_mm: null,
    measurement_equipments: 'As per Incoming STD',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 3,
    description: 'Washer',
    spec_mm: null,
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 4,
    description: 'End Cap Type',
    spec_mm: null,
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 5,
    description: 'Grommet ',
    spec_mm: null,
    measurement_equipments: 'Vernier Caliper',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 6,
    description: 'Oetiker',
    spec_mm: null,
    measurement_equipments: 'As per Incoming STD',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 7,
    description: 'Araymond Connector',
    spec_mm: null,
    measurement_equipments: 'As per Incoming STD',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 8,
    description: 'O-ring',
    spec_mm: null,
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 9,
    description:
      'Sleeve,Bracket,Clamp.Protecting cap in sleeve area/rollingarea etc if applicable',
    spec_mm: null,
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 10,
    description: 'Nut',
    spec_mm: null,
    measurement_equipments: null,
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 11,
    description: 'Pipe OD/Tube OD',
    spec_mm: null,
    measurement_equipments: null,
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 'B',
    full_row: 'At  Sub-Assembly stage',
  },
  {
    s_no: 12,
    description: 'Washer',
    spec_mm: null,
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 13,
    description: 'End Cap Type',
    spec_mm: null,
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 14,
    description: 'Grommet ',
    spec_mm: null,
    measurement_equipments: 'Vernier Caliper',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 15,
    description: 'O-ring',
    spec_mm: null,
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 16,
    description: 'Sleeve,Bracket,Clamp etc if applicable',
    spec_mm: null,
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 17,
    description: 'Pipe OD/Tube OD',
    spec_mm: null,
    measurement_equipments: 'Vernier Caliper',
    obs_1: null,
    obs_2: null,
    reference_std_doc: '',
    result: null,
    remarks: null,
  },
  {
    s_no: 18,
    description: 'Pipe/Tube Wall thickness ',
    spec_mm: null,
    measurement_equipments: 'Vernier Caliper',
    obs_1: null,
    obs_2: null,
    reference_std_doc: '',
    result: null,
    remarks: null,
  },
  {
    s_no: 19,
    description: 'Hose I.D',
    spec_mm: null,
    measurement_equipments: 'Vernier Caliper',
    obs_1: null,
    obs_2: null,
    reference_std_doc: '',
    result: null,
    remarks: null,
  },
  {
    s_no: 20,
    description: 'Hose Wall thickness',
    spec_mm: null,
    measurement_equipments: 'Low Pressure Vernier',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 21,
    description: 'Hose Length',
    spec_mm: null,
    measurement_equipments: 'VC/Scale',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 22,
    description: 'Hose Type',
    spec_mm: 'VMQ/EPDM',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: '',
    result: null,
    remarks: null,
  },
  {
    s_no: 23,
    description: 'Hose Marking ',
    spec_mm: '    As per Fixture ',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 24,
    description: 'Part Profile /Assy. Length',
    spec_mm: null,
    measurement_equipments: null,
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 25,
    description: 'Bend Collapse',
    spec_mm: null,
    measurement_equipments: null,
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 'C',
    full_row: 'At Final Assembly Stage',
  },
  {
    s_no: 26,
    description: 'Appearance',
    spec_mm: null,
    measurement_equipments: 'Vernier Caliper/Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: '',
    result: null,
    remarks: null,
  },
  {
    s_no: 27,
    description: 'Orientation ',
    spec_mm: '    As per Fixture ',
    measurement_equipments: '  As per Fixture ',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 28,
    description: 'Supplier Identification (Batch Code /Part No)',
    spec_mm: '(Batch Code /Part No)',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 29,
    description: 'Leakage Tesing / Rate ',
    spec_mm: ' Should not Leakage  at PSI   CC/M',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 30,
    description: 'Blockage',
    spec_mm: null,
    measurement_equipments: 'Ball Pass from one end to other.',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 31,
    description: 'Assembly Length as per drawing',
    spec_mm: 'Profile should be match as per fixture.',
    measurement_equipments: '  As per Fixture ',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 32,
    description: 'Cap Plug',
    spec_mm: 'Visual',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 32,
    description: 'Final Inspection ',
    spec_mm: 'As per Control Plan ',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 'D',
    full_row: 'At Packing Stage',
  },
  {
    s_no: 33,
    description: 'VCI Bag',
    spec_mm: 'Part should packed in VCI (in case of export)',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 34,
    description: 'Qty of hose Assembly in Poly/VCI Bag',
    spec_mm: 'As per packing std.',
    measurement_equipments: 'Manual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 35,
    description: 'Labeling on Poly/VCI bag',
    spec_mm: 'Part no, Qty',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 36,
    description: 'Hose assly. Qty in box.',
    spec_mm: 'As per packing std.',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 37,
    description: 'Labeling on corrugrated box',
    spec_mm: 'On corrugate box/bag  ',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 38,
    description: 'Sticker  ',
    spec_mm: 'Part No,Weight,Part Name, Protecting cap on banjo (End cap A/B)',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 39,
    description: 'Millipore/ Leica',
    spec_mm: 'As per drawing',
    measurement_equipments: 'Millipore/ Leica report.',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 'E',
    full_row: 'Dispatch',
  },
  {
    s_no: 40,
    description: 'Condition of pallet',
    spec_mm: 'no physical damage /proper wrapping',
    measurement_equipments: 'Visual',
    obs_1: null,
    obs_2: null,
    reference_std_doc: null,
    result: null,
    remarks: null,
  },
  {
    s_no: 'F',
    full_row: 'Transportation Mode',
  },
];
