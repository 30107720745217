import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    maintainAspectRatio: false,
    title: {
      display: true,
      text: 'Customer wise status 2023',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      ticks: {
        stepSize: 5,
      },
    },
  },
};

interface IProps {
  apiData: any;
  year: number;
}
const StackedBarChart = ({ apiData, year }: IProps) => {
  const labels = apiData?.dashBoardMonthCounts?.map(
    (data: any) => data.customerName
  );
  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const backgroundColors = [
    '#4c6ef5',
    '#fd7e14',
    '#868e96',
    '#fab005',
    '#82c91e',
    '#15aabf',
    '#12b886',
    '#7950f2',
    '#be4bdb',
    '#e64980',
    '#fa5252',
    '#40c057',
  ];
  let datasets = [];
  for (const monthIndex in apiData?.totalOfMonth) {
    if (apiData?.totalOfMonth[monthIndex] > 0) {
      datasets.push({
        label: `${monthNames[+monthIndex - 1]}-${year % 100}`,
        data: apiData?.dashBoardMonthCounts?.map(
          (data: any) => data[+monthIndex]
        ),
        backgroundColor: backgroundColors[+monthIndex - 1],
        borderWidth: 1,
        barThickness: 50,
      });
    }
  }

  const data = {
    labels,
    datasets: datasets,
  };
  return <Bar options={options} data={data} />;
};

export default StackedBarChart;
