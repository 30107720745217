import axios from 'axios';
import { useState, useEffect } from 'react';
import useConfirm from '../../../../components/common/useConfirm';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import { apiConstantAddParts } from '../constant';

const useGetData = () => {
  const date = useQuery('date');
  const [moduleData, setModuleData] = useState<any>({
    month: date && new Date(date).getMonth() + 1,
    year: date && new Date(date).getFullYear(),
    list: [],
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [render, setParentRender] = useState(false);
  const [renderforUpdate, setRenderforUpdate] = useState(false);
  const [filterData, setFilterData] = useState<any>(null);
  const [machineData, setMachineData] = useState([]);

  const [DialogSync, confirmSync] = useConfirm(
    'Update Confirmation',
    'Are you sure you want to update?',
  );
  const [DialogDelete, confirmDelete] = useConfirm(
    'Reset Confirmation',
    'Are you sure you want to reset the whole Report!!?',
  );

  useEffect(() => {
    axios
      .get('/api/machineRoutes/machineList/machineListForAutoComplete')
      .then((res) => {
        setMachineData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (!date) return;
    const handleApi = async () => {
      setIsLoading(true);
      axios
        .get(
          `${apiConstantAddParts.apiConstant}/${date}/${moduleData.machine_id?._id}`,
        )
        .then((response) => {
          setModuleData(response?.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          setModuleData({
            month: date && new Date(date).getMonth() + 1,
            machine_id: moduleData.machine_id,
            year: date && new Date(date).getFullYear(),
            list: [],
          });
          setError(err);
        });
    };

    handleApi();
    setIsLoading(false);
  }, [date, renderforUpdate]);

  return {
    error,
    moduleData,
    setModuleData,
    isLoading,
    DialogSync,
    DialogDelete,
    machineData,
    confirmSync,
    confirmDelete,
    setParentRender,
    setRenderforUpdate,
  };
};

export default useGetData;
