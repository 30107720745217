import { Box } from '@mui/material';
import { useState } from 'react';
import { RenderTable } from '../../../../components/ui';
import TBody1 from './TBody1';
import TBody2 from './TBody2';
import TBody3 from './TBody3';
import TBody4 from './TBody4';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  customerData: any;
  toolsData: any;
  machineData: any;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
  setHeadingType: React.Dispatch<React.SetStateAction<string>>;
  documentType: any;
  processes: any;
}

const TController = ({
  setPartIdx,
  setIsOpenPopup,
  setHeadingType,
  customerData,
  toolsData,
  moduleData,
  isView,
  documentType,
  machineData,
  processes,
}: IProps) => {
    const [renderController,setRenderController] = useState(false)
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            documentType,
          }}
        />
        <TBody1
          {...{
            moduleData,
            isView,
            setIsOpenPopup,
            setPartIdx,
            setHeadingType,
            documentType,
            setRenderController,
            machineData,
          }}
        />
        <TBody2
          {...{
            moduleData,
            processes,
            setIsOpenPopup,
            setPartIdx,
            isView,
            setHeadingType,
            customerData,
            documentType,
            toolsData,
          }}
        />
        {documentType === 'firstFloor' && (
          <TBody3
            {...{
              moduleData,
              isView,
              setHeadingType,
              setIsOpenPopup,
              setPartIdx,
              customerData,
            }}
          />
        )}

        <TBody4
          {...{
            moduleData,
            isView,
            documentType,
          }}
        />
        <TFoot
          {...{
            moduleData,
            isView,
            documentType,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
