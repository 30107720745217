import React, { useEffect, useRef, useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import Create from './Create';
import { UpdateButton } from '../../../../components/common/button/CustomButtons';
import ChildHeader from '../../../../components/ui/ChildHeader';
import {
  CustomPagination,
  PersitedDatePicker,
  SearchBar,
  TableCustom,
} from '../../../../components/common';
import useDebounce from '../../../../hooks/useDebounce';
import {
  DeleteIconButton,
  ViewIconButton,
} from '../../../../components/common/button/CustomIconButton';
import ModalCustom from '../../../../components/common/Modal';
import { useQuery } from '../../../../hooks/UseQuery';
import useConfirm from '../../../../components/common/useConfirm';
import { apiConstant, checkListList } from '../constant';
import formatDate from '../../../../components/common/formatDate';
import CustomUploadButton from '../../../../components/common/button/CustomUploadButton';
import ViewFile from './ViewFile';
import FilterBy from '../../../../components/common/FilterBy';
import { Loader } from '../../../../components/common/Loader';

const tableHeaderObj = [
  [
    { text: 'S. No.', rowspan: 1, colspan: 1 },
    { text: 'Part Name', rowspan: 1, colspan: 1 },
    { text: 'Part  No.', rowspan: 1, colspan: 1 },
    { text: 'Supplier Name', rowspan: 1, colspan: 1 },
    { text: 'Supplier Code', rowspan: 1, colspan: 1 },
    { text: 'Layout Due Date', rowspan: 1, colspan: 1 },
    { text: 'Last Layout Date', rowspan: 1, colspan: 1 },
    { text: 'Status', rowspan: 1, colspan: 1 },
    { text: 'Attach Proof', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const getCurrentStatus = (status: any) => {
  const statusMap: any = {
    overdue: [{ bgColor: '#e03131', width: '300px', text: 'LAYOUT OVERDUE' }],
    pending: [
      { bgColor: '#fcc419', width: '300px', text: 'LAYOUT NOT STARTED' },
    ],
    started: [{ bgColor: '#5c7cfa', width: '300px', text: 'LAYOUT ONGOING' }],
    completed: [{ bgColor: '#2f9e44', width: '300px', text: 'COMPLETED' }],
  };

  const [{ bgColor, text, width }] = statusMap[status] || [
    { bgColor: '', width: '300px', text: '' },
  ];
  return bgColor ? (
    <TableCell align="center" style={{ backgroundColor: bgColor, width }}>
      {text}
    </TableCell>
  ) : null;
};

const List = () => {
  const [data, setData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const date = useQuery('date');
  const customer = useQuery('customer');
  const idRef = useRef<any>({});
  const [isOpenFiles, setIsOpenFiles] = useState(false);
  const page = useQuery('page') || 1;
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );
  const [filterVendor, setFilterVendor] = useState([]);

  const [listId, setListId] = useState('');
  const [docFiles, setDocFiles] = useState<any>({});

  const fetchData = async () => {
    setIsLoading(true);
    const res = await axios
      .get(
        `/api/${apiConstant.getAllCheckList}/${date}/?page=${page}&search=${search}&perPage=${perPage}&customer=${customer}`
      )
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    setIsLoading(false);
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  useEffect(() => {
    axios
      .get('/api/vendorRoute/vendor/forAutoComplete')
      .then((res) => {
        if (res.data) {
          const formattedData = res.data.map((vendor: any) => ({
            name: vendor.vender_name,
            value: vendor._id,
          }));
          setFilterVendor(formattedData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDelete = async (item: any) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .put(
        `/api/${apiConstant.checkListStatus}/${item.docId}/${item._id}/${item.month}`,
        {
          p: false,
          isTask: false,
          doneDate: null,
          status: 'pending',
        }
      )
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    customer,
    date,
    render,
    isOpenFiles,
  ]);

  const uploadFile = async (e: any, item: any) => {
    if (!window.confirm('Are you sure you want upload this Files')) return;
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    for (const key of Object.keys(files)) {
      formData.append('files', files[key]);
    }
    setIsLoading(true);

    axios
      .patch(
        `/api/${apiConstant.checklistUpload}/${item.docId}/${item._id}/${item.month}`,
        formData
      )
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  return (
    <>
      <Loader loader={isLoading} />
      <ChildHeader text={`${checkListList.childHeader}`}></ChildHeader>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        sx={{
          justifyItems: 'center',
          alignItems: 'center',
          margin: '15px',
          width: '100%',
          gap: 2,
        }}
      >
        <SearchBar
          label="Search Part"
          sx={{ width: { xs: '100%', sm: '67%' } }}
        />
        <Stack direction={{ xs: 'column', sm: 'row' }} sx={{ gap: 2 }}>
          <FilterBy
            sx={{ minWidth: '190px', maxWidth: '190px' }}
            name="customer"
            label="Filter By Vendor"
            options={filterVendor}
            variant={'outlined'}
          />
          <PersitedDatePicker
            label={`${checkListList.datePickerLabel}`}
            views={['year']}
          />
        </Stack>
      </Stack>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data?.length > 0 &&
            data.map((item: any, index: number) => (
              <React.Fragment>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.a1}</TableCell>
                  <TableCell align="center">{item?.a2}</TableCell>
                  <TableCell align="center">{item?.a3}</TableCell>
                  <TableCell align="center">{item?.a4}</TableCell>
                  <TableCell align="center">{item?.a5}</TableCell>
                  <TableCell align="center">{item?.dueDate}</TableCell>
                  <TableCell align="center">
                    {formatDate(item.doneDate)}
                  </TableCell>
                  {getCurrentStatus(item?.status)}
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <CustomUploadButton
                        accept="image/png,image/jpeg,image/jpg,application/pdf"
                        id={`${index}_files_`}
                        multiple={true}
                        onChange={(e) => {
                          uploadFile(e, item);
                        }}
                      />
                      {item.files?.length > 0 ? (
                        <>
                          <ViewIconButton
                            tooltipTitle="view"
                            onClick={() => {
                              idRef.current = {
                                docId: item.docId,
                                _id: item._id,
                                month: item.month,
                              };
                              setDocFiles(item.files);
                              setIsOpenFiles(true);
                            }}
                          />
                        </>
                      ) : (
                        <ViewIconButton
                          sx={{
                            opacity: '0',
                            PointerEvent: 'none',
                            visibility: 'hidden',
                          }}
                          tooltipTitle=""
                        />
                      )}
                    </Stack>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          handleDelete(item);
                        }}
                      />
                      <UpdateButton
                        label="update status"
                        sx={{ margin: '10px' }}
                        size="small"
                        onClick={() => {
                          setListId(item);
                          setIsOpenPopup(true);
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <ModalCustom
        title={`${checkListList.modalTile}`}
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
          setListId('');
        }}
      >
        <Create
          setIsModal={setIsOpenPopup}
          item={listId}
          setRender={setRender}
        />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isOpenFiles}
        closeModal={() => {
          setIsOpenFiles(false);
        }}
      >
        <ViewFile
          id={idRef.current}
          docFiles={docFiles}
          setIsOpenFiles={setIsOpenFiles}
        />
      </ModalCustom>
      <CustomPagination totalPage={totalPages} />
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
