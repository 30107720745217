import moment from 'moment';
import { useState } from 'react';

import { TableCompHead } from '../../../../../components/ui';
import formatDate from '../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import { TableInput } from '../../../../../components/common';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
  customer: any;
}

const heading = [
  'S. No.',
  'Description',
  'SPEC(MM)',
  'Measurement Equipements',
  'Observations',
  'Reference STD / DOC',
  'Result (OK/NC/SOI)',
  'Remarks',
];

const THead = ({ moduleData, isView, customer }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={9}
        rowSpan={3}
        labels={['Initial Issue:', 'Rev. No.:', 'Eff. Date:']}
        values={['01.07.2022', '00', '01-04-2023']}
        heading="Product Audit Report"
      />
      <tr>
        <th colSpan={2} align="left">
          Product Name :
        </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            name="s1"
            type="text"
            value={moduleData.s1 || ''}
            onChange={(e) => {
              moduleData[e.target.name] = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          Auditor:
        </th>
        <th colSpan={3} align="left">
          <TableInput
            isView={isView}
            name="s2"
            type="text"
            value={moduleData.s2 || ''}
            onChange={(e) => {
              moduleData[e.target.name] = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>

      <tr>
        <th colSpan={1} align="left">
          Part No. :
        </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            name="s3"
            type="text"
            value={moduleData.s3 || ''}
            onChange={(e) => {
              moduleData[e.target.name] = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          Rev. No.
        </th>
        <th colSpan={1} align="left">
          <TableInput
            isView={isView}
            name="s4"
            type="text"
            value={moduleData.s4 || ''}
            onChange={(e) => {
              moduleData[e.target.name] = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={1} align="left">
          Date: -
        </th>
        <th colSpan={2}>
          {isView ? (
            formatDate(moduleData.d1)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d1)}
              onChange={(date) => {
                moduleData.d1 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={1} align="left">
          Drg.No. :
        </th>
        <th colSpan={2}>
          <TableInput
            isView={isView}
            name="s10"
            type="text"
            value={moduleData.s10 || ''}
            onChange={(e) => {
              moduleData[e.target.name] = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>

        <th colSpan={1} align="left">
          Issue. Date.
        </th>
        <th colSpan={1}>
          {isView ? (
            formatDate(moduleData.d2)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d2)}
              onChange={(date) => {
                moduleData.d2 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>

        <th colSpan={2} align="left">
          Shift :
        </th>
        <th colSpan={2} align="left">
          <TableInput
            isView={isView}
            name="s11"
            type="text"
            value={moduleData.s11 || ''}
            onChange={(e) => {
              moduleData[e.target.name] = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>

      <tr>
        {heading.map((header) => (
          <th
            key={header}
            colSpan={(header === 'Observations' && 2) || 1}
            rowSpan={1}
          >
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
