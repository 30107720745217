import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
// import { headFirst, headSecond, headThird } from '../../helpers/headData';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={19}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="INSPECTION REPORT"
      />
      <tr>
        <th colSpan={3} align="left">
          Date :{' '}
        </th>
        <th colSpan={16} align="left">
          {isView ? (
            formatDate(moduleData.inspection_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.inspection_date)}
              onChange={(date) => {
                moduleData.inspection_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          MRN NO. :{' '}
        </th>
        <th colSpan={16} align="left">
          <TableInput
            isView={isView}
            name="mrn_no"
            type="text"
            value={moduleData.mrn_no}
            onChange={(e) => {
              moduleData.mrn_no = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Vendor Name:
        </th>
        <th colSpan={2} align="left">
          <TableInput
            isView={isView}
            name="vendor_id"
            type="text"
            value={moduleData.vendor_id}
            onChange={(e) => {
              moduleData.vendor_id = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={5} align="left">
          Qty:{' '}
          <TableInput
            isView={isView}
            name="qty"
            type="number"
            value={moduleData.qty}
            onChange={(e) => {
              moduleData.qty = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={5} align="left">
          Part No.:
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.ri_part_id?.part_number}
        </th>
        <th colSpan={5} align="left">
          Part Name:
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.ri_part_id?.part_name}
        </th>
      </tr>
      <tr>
        {[
          'S.no',
          'Parameter',
          'Specification',
          'Frequency',
          'Inst.type',
          'Sample qty.',
          'Observation',
        ].map((head) => (
          <th
            rowSpan={head === 'Observation' ? 1 : 2}
            colSpan={head === 'Observation' ? 13 : 1}
            style={{ textTransform: 'uppercase' }}
          >
            {head}
          </th>
        ))}
      </tr>
      <tr>
        {Array.from({ length: 10 }, (_, index) => (
          <th key={index + 'Heading'} style={{ minWidth: '120px' }}>
            {index + 1}
          </th>
        ))}
        <th style={{ textTransform: 'uppercase', minWidth: '120px' }}>P/F</th>
        <th
          colSpan={2}
          style={{ textTransform: 'uppercase', minWidth: '150px' }}
        >
          Remarks
        </th>
      </tr>
    </thead>
  );
};

export default THead;
