import moment from 'moment';

export const processes = [
  {
    part_id: null,
    customer_id: null,
    machine_id: null,
    pipe_spec: '',
    process_stage: '',
    operator: '',
    quality_inspector: '',
    prod_incharge: '',
    prod_qty: null,
    rej_qty: null,
    reason_of_rej: '',
    remarks: '',
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  setupAppr_date: moment(),
  shift: '',
  processes: Array.from({ length: 15 }, () => ({ ...processes[0] })),
};
