import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Box } from '@mui/system';
import { Stack } from '@mui/material';

const SlideShow = () => {
  const { id } = useParams();
  const [images, setImages] = useState([]);
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/listOfSlideShow/${id}`)
        .then((res) => {
          setImages(res.data.files);
        })
        .catch((err) => console.log(err));
    }
  }, []);
  return (
    <Stack justifyContent={'center'} alignItems={'center'}>
      <Box width={1000}>
        <Carousel
          autoPlay={true}
          interval={2000}
          transitionTime={500}
          infiniteLoop={true}
        >
          {images.map((img: any, index: number) => (
            <div key={index}>
              <img src={img.url} alt={`Slide ${index}`} />
            </div>
          ))}
        </Carousel>
      </Box>
    </Stack>
  );
};

export default SlideShow;
