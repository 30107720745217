import { useState } from 'react';
//mport ObservationInput from './ObservationInput';
import CustomSelect from '../../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../../components/common';

interface IProps {
  apiData: any;
  totalProducedQty: number;
}
const TBody = ({ apiData, totalProducedQty }: IProps) => {
  const [render, setRender] = useState(false);
  const { rejectionMonthlyCounts } = apiData;

  return (
    <tbody>
      {rejectionMonthlyCounts.map((data: any, index: number) => {
        const { monthName, rejectionCount } = data;

        const ppm = ((rejectionCount * 1000000) / totalProducedQty).toFixed(0);
        return (
          <tr>
            <td>{monthName}</td>
            <td>{ppm}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TBody;
