import moment from 'moment';
import React, { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../components/common/SelectField';

interface IProps {
  moduleData: any;
  machineData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView, machineData }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={9}
        rowSpan={3}
        labels={['DocNo: ', 'Rev No: ', 'Date']}
        values={['FM/XLV/AW/01', '00', '28.04.2023']}
        heading="White Patch wipe Test/Airborne debris Test Check Sheet"
      />
      <tr>
        <th>Date</th>
        <th>
          {isView ? (
            formatDate(moduleData.created_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.created_date)}
              onChange={(date) => {
                moduleData.created_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <td colSpan={3}>plant</td>
        <td colSpan={1}>
          <TableInput
            isView={isView}
            name="plant"
            type="text"
            value={moduleData.plant}
            onChange={(e) => {
              moduleData.plant = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
        <td colSpan={5}>Observation</td>
      </tr>
      <tr>
        {[
          'Date',
          'Time',
          'Before ( Tier Wt (a) MG',
          'After ( Gross Wt (Mg)',
          'After ( Gross Wt (Mg)',
          'B-A = Result Mg ',
          'B-A=Result',
          'Remarks',
        ].map((item, index) => (
          <React.Fragment key={`${index}_ 1`}>
            <th colSpan={item === 'Remarks' ? 2 : 1}>{item}</th>
          </React.Fragment>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
