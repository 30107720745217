import { Grid } from '@mui/material';
import { useState } from 'react';
import { BiUndo } from 'react-icons/bi';
import { MdAssignmentTurnedIn, MdLock, MdLockOpen } from 'react-icons/md';
import { TableInput } from '../../../../../components/common';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';
import {
    AddIconButton,
    CustomIconButton,
    RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
interface IProps {
    moduleData: any;
    isView: boolean;
    setIsOpenPopup: any;
    setPartIdx: any;
    assignData: any;
    tabValue: number;
    assignRegister: (document: any, index: number) => void;
    issueRegister: (document: any, index: number) => void;
    undoData: { [key: string]: any };
    undoAssign: (index: number) => void;
    undoIssue: (index: number) => void;
    storeIssueId: string;
    index: number;
    part: any;
}
const TBodyRow = ({
    setPartIdx,
    setIsOpenPopup,
    moduleData,
    isView,
    assignData,
    tabValue,
    assignRegister,
    issueRegister,
    undoData,
    undoIssue,
    undoAssign,
    storeIssueId,
    part,
    index,
}: IProps) => {
    const [render, setRender] = useState(false);
    return (
        <tr key={index + 10.1 + 'heading '} style={{ background: part.color }}>
            <th style={{ minWidth: '50px', textAlign: 'center' }}>{index + 1}</th>
            {!assignData && (
                <td style={{ minWidth: '50px', textAlign: 'center' }}>
                    <CustomIconButton
                        sx={{ p: 0 }}
                        tooltipTitle={'Lock'}
                        onClick={() => {
                            if (part.isLock === true) {
                                part.isLock = false;
                                return setRender((prev) => !prev);
                            }
                            part.isLock = true;
                            return setRender((prev) => !prev);
                        }}
                    >
                        {part.isLock ? (
                            <MdLock color="#ef233c" />
                        ) : (
                            <MdLockOpen color="#2b8a3e" />
                        )}
                    </CustomIconButton>
                </td>
            )}
            <td
                style={{
                    minWidth: '200px',
                }}
            >
                {part.address}
            </td>
            <td
                style={{
                    width: '280px',
                    minWidth: '280px',
                    textAlign: 'center',
                }}
            >
                {isView || assignData ? (
                    part?.partNumber ? (
                        part?.partNumber
                    ) : (
                        part?.store_part_id?.ri_part_id?.part_number
                    )
                ) : (
                    <Grid container xs={12} direction="row" alignItems={'center'}>
                        <Grid item xs={10}>
                            <p
                                style={{
                                    fontSize: '1.2rem',
                                }}
                            >
                                {typeof part?.store_part_id === 'object' &&
                                    part?.store_part_id !== null
                                    ? part?.store_part_id?.ri_part_id?.part_number
                                    : part?.partNumber || ''}
                                {!part?.store_part_id && 'Pick Part Number'}
                            </p>
                        </Grid>

                        {part.store_part_id ? (
                            <Grid item xs={2}>
                                <RemoveIconButton
                                    tooltipTitle="remove part"
                                    onClick={() => {
                                        part.store_part_id = null;
                                        part.partNumber = null;
                                        part.qty = 0;
                                        part.erp_drawing_no = null;
                                        part.fox_pro_drg_no = null;
                                        setRender((prev) => !prev);
                                    }}
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={2}>
                                <AddIconButton
                                    tooltipTitle="add part"
                                    onClick={() => {
                                        setIsOpenPopup(true);
                                        setPartIdx(index);
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}
            </td>
            {!assignData && (
                <>
                    <td>
                        {' '}
                        {part.erp_drawing_no ||
                            part.store_part_id?.ri_part_id?.erp_drawing_no}
                    </td>
                    <td>
                        {' '}
                        {part.fox_pro_drg_no ||
                            part.store_part_id?.ri_part_id?.fox_pro_drg_no}
                    </td>
                </>
            )}

            <td style={{ minWidth: '150px', textAlign: 'center' }}>
                <TableInput
                    isView={isView || assignData}
                    name="qty"
                    type="number"
                    style={{ textAlign: 'center' }}
                    value={part.qty}
                    disabled={!part.store_part_id}
                    onChange={(e) => {
                        part[e.target.name] = e.target.value;
                        setRender((prev) => !prev);
                    }}
                />
            </td>
            {assignData &&
                (storeIssueId ? (
                    <td>
                        {undoData[tabValue + '' + index] ? (
                            <CustomButton
                                size={'small'}
                                color="primary"
                                disabled={part.isLock}
                                sx={{
                                    margin: '10px',
                                    backgroundColor: '#265988',
                                    color: '#fff',
                                }}
                                icon={<BiUndo />}
                                onClick={() => undoIssue(index)}
                            >
                                undo
                            </CustomButton>
                        ) : (
                            <CustomButton
                                size={'small'}
                                color="secondary"
                                sx={{
                                    margin: '10px',
                                    backgroundColor: '#c92a2a',
                                    color: '#fff',
                                }}
                                disabled={
                                    part.store_part_id?._id !== assignData.store_part_id?._id ||
                                    part.isLock
                                }
                                icon={<MdAssignmentTurnedIn />}
                                onClick={() => issueRegister(part, index)}
                            >
                                ISSUE
                            </CustomButton>
                        )}
                    </td>
                ) : (
                    <td>
                        {undoData[tabValue + '' + index] ? (
                            <CustomButton
                                size={'small'}
                                color="primary"
                                sx={{
                                    margin: '10px',
                                    backgroundColor: '#265988',
                                    color: '#fff',
                                }}
                                disabled={part.isLock}
                                icon={<BiUndo />}
                                onClick={() => undoAssign(index)}
                            >
                                undo
                            </CustomButton>
                        ) : (
                            <CustomButton
                                size={'small'}
                                color="secondary"
                                sx={{
                                    margin: '10px',
                                    backgroundColor: '#c92a2a',
                                    color: '#fff',
                                }}
                                disabled={
                                    (part.qty || 0) >= part.store_part_id?.max_bin_qty ||
                                    part.isLock ||
                                    (part.store_part_id &&
                                        part?.store_part_id?._id !== assignData?.store_part_id?._id)
                                }
                                icon={<MdAssignmentTurnedIn />}
                                onClick={() => assignRegister(part, index)}
                            >
                                ASSIGN
                            </CustomButton>
                        )}
                    </td>
                ))}
        </tr>
    );
};

export default TBodyRow;
