import React, { useEffect, useState } from 'react';
import TController from './TController';
import { Box } from '@mui/material';
import { PersitedDatePicker } from '../../../../../../components/common';
import { useQuery } from '../../../../../../hooks/UseQuery';
import axios from 'axios';
import ParetoChart from './ParetoChart';
import BarChart from "./BarChart";
interface IProps {
  apiData: any;
  year: number;
  month: string;
  tabValue:any;
}
const DefectWiseQty = ({ apiData, year, month ,tabValue}: IProps) => {
  return (
    <div>
      <TController apiData={apiData} year={year} month={month} />
      {
        <Box style={{ paddingBottom: '3rem', width: '80vw' ,marginLeft:"auto",marginRight:"auto" }}>
          {tabValue==0 &&<ParetoChart apiData={apiData} />}
          {tabValue==2 &&<BarChart apiData={apiData} />}
        </Box>
      }
    </div>
  );
};

export default DefectWiseQty;
