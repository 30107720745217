import { useState } from 'react';
import TBodyRight from './TBodyRight';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import formatDate from '../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
  handleDeleteRows: any;
  employeeData: any;
  tniTopic: any;
}
const TBody = ({
  moduleData,
  isView,
  handleDeleteRows,
  employeeData,
  tniTopic,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <>
          <tr key={`${index}_table_row`}>
            <td>{index + 1}</td>
            <td>
              {isView ? (
                item?.employee_id?.employee_name
              ) : (
                <AutocompleteMuiCustom
                  id="Customer"
                  label=""
                  option_name="employee_name"
                  arrayofObj={employeeData}
                  value={item.employee_temp || item.employee_id || ''}
                  onChange={(e, value) => {
                    item.employee_id = value?._id;
                    item.employee_temp = value;
                    console.log('item', item);
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td>
              {item.employee_temp?.designation_id?.designation ||
                item.employee_id?.designation_id?.designation}
            </td>
            <td>
              {item.employee_temp?.area_of_work?.department_name ||
                item.employee_id?.area_of_work?.department_name}
            </td>
            <TBodyRight item={item} tniTopic={tniTopic} isView={isView} />
            <td>
              {item.employee_temp?.area_of_work?.user_id?.name ||
                item.employee_id?.area_of_work?.user_id?.name}
            </td>
            <td>
              {formatDate(item.employee_temp?.date_of_joining) ||
                formatDate(item.employee_id?.data_of_joining)}
            </td>
            <td>
              {isView ? (
                formatDate(moduleData.date)
              ) : (
                <DatePickerMui
                  value={moment(item.date_of_leave || null)}
                  onChange={(date) => {
                    item.date_of_leave = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
            <td>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={item.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              {!isView && (
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handleDeleteRows(index)}
                />
              )}
            </td>
          </tr>
        </>
      ))}
    </tbody>
  );
};

export default TBody;
