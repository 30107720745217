const firstFloor = [
  'Child part Ultrasonic Cleaning',
  'Ferrule Insertion',
  'Assembly 1',
  'Assembly 2',
  'Orientation',
  'Clamping',
  'Profile Checking',
  'Gauging',
  'Visual',
  'Blockage Testing',
  'Leakage Testing',
  'Laser Punching',
  'Ultrasonic Cleaning',
  'Flushing',
  'Oven',
  'O-Ring Insertion',
  'Goormet Insertion',
  'Capping Insertion',
  'CCDC',
  'Primary Packing',
  'Final Packing',
];
const groundFloor = [
  'Buldging',
  'Flaring',
  'Spooling',
  'Clip Insertion',
  'Sleeve Insertion',
  'Nut Insertion',
  'Press fitting',
  'Clinching',
  'Furnace Brazing',
  'Induction Brazing',
  'Manual Brazing',
  'TIG Welding',
  'Sqeezing / Resizing',
  'T-Drill',
  'End Cutting',
  'Manual Bending',
  'CNC Bending',
  'Expanding',
  'Deburring',
  'Spot welding',
  'Buffing',
  'Drilling',
  'Fitment',
];
export { groundFloor, firstFloor };
