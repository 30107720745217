import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';

interface IProps {
  moduleData: any;
  isView: boolean;
  tabValue: number;
}
const THead = ({ moduleData, isView, tabValue }: IProps) => {
  const [render, setRender] = useState(false);
  console.log(moduleData.pdirs[tabValue]?.file);

  return (
    <thead>
      <TableCompHead
        colSpan={13}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="Pre - Despatch Inspection Report"
      />

      <tr>
        <th colSpan={2} rowSpan={2} align="left">
          Customer:
          {moduleData.customer_name
            ? moduleData?.customer_name
            : moduleData?.part_id?.customer_id?.customer_name}
        </th>
        <th rowSpan={5} colSpan={6} data-img={moduleData.pdirs[tabValue]?.file}>
          <img
            src={moduleData.pdirs[tabValue]?.file}
            height={300}
            width={600}
            alt="image"
          />
        </th>
        <th colSpan={2} rowSpan={2} align="left" style={{ fontSize: '1.6rem' }}>
          Supplier
        </th>
        <th
          colSpan={3}
          align="left"
          style={{ fontSize: '1.6rem', fontWeight: 600 }}
        >
          {moduleData.supplier_name
            ? moduleData?.supplier_name
            : moduleData?.part_id?.supplier_id?.supplier_name}
        </th>
      </tr>
      <tr>
        <td colSpan={3} style={{ textAlign: 'left' }}>
          Plant 45 - Faridabad
        </td>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Part Name:{' '}
          {moduleData.part_name
            ? moduleData.part_name
            : moduleData.part_id?.part_name}
        </th>
        <th colSpan={2} align="left">
          Batch Code :{' '}
          <TableInput
            name="batch_code"
            type="text"
            value={moduleData?.pdirs[tabValue]?.batch_code}
            onChange={(e) => {
              moduleData.pdirs[tabValue].batch_code = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={3} align="left">
          Invoice No.:
          <TableInput
            name="invoice_no"
            type="text"
            value={moduleData?.pdirs[tabValue]?.invoice_no}
            onChange={(e) => {
              moduleData.pdirs[tabValue].invoice_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>

      <tr>
        <th colSpan={2} align="left">
          Part No:{' '}
          {moduleData.part_number
            ? moduleData.part_number
            : moduleData.part_id?.part_number}
        </th>
        <th colSpan={2} align="left">
          Report No.
          <TableInput
            name="report_no"
            type="text"
            value={moduleData?.pdirs[tabValue]?.report_no}
            onChange={(e) => {
              moduleData.pdirs[tabValue].report_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1}>Date</th>
        <th></th>
        <th colSpan={1} style={{minWidth:'100px'}}>Qty </th>
      </tr>

      <tr>
        <th colSpan={2} align="left">
          Drg. Mod No.
          <TableInput
            name="drg_mod_no"
            type="text"
            value={moduleData?.pdirs[tabValue]?.drg_mod_no}
            onChange={(e) => {
              moduleData.pdirs[tabValue].drg_mod_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={2} align="left">
          Job Card:
          <TableInput
            name="job_card"
            type="text"
            value={moduleData?.pdirs[tabValue]?.job_card}
            onChange={(e) => {
              moduleData.pdirs[tabValue].job_card = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th
          colSpan={1}
          style={{ minWidth: '180px' }}
          data-val={formatDate(moduleData?.pdir_date)}
        >
          {isView ? (
            formatDate(moduleData?.pdir_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData?.pdir_date)}
              onChange={(date) => {
                moduleData.pdir_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th></th>
        <th colSpan={1}>
          <TableInput
            name="qty"
            type="text"
            value={moduleData?.pdirs[tabValue]?.qty}
            onChange={(e) => {
              moduleData.pdirs[tabValue].qty = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        {[
          'S.NO',
          'Parameter',
          'specification',
          'Mode of Inspection',
          'FREQ.',
        ].map((head) => (
          <th rowSpan={2} style={{ textTransform: 'uppercase' }}>
            {head}
          </th>
        ))}
        <th colSpan={5}>Manufacturer's Observation</th>
        <th colSpan={1} rowSpan={2}>
          Customer's Observation
        </th>
        <th style={{ textTransform: 'uppercase' }} rowSpan={2} colSpan={2}>
          Remarks
        </th>
      </tr>
      <tr>
        {Array.from({ length: 5 }, (_, index) => (
          <th style={{ width: '90px' }} key={index + 'Heading'}>
            {index + 1}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
