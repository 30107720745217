import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { MenuItem } from '@mui/material';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import moment from 'moment';

const Create = ({
  id,
  setIsModal,
  setEmployeeId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEmployeeId: any;
}) => {
  const [data, setData] = useState<any>({
    data_of_joining: new Date(),
  });
  const [departmentData, setDepartmentData] = useState<any>([]);
  const [designationData, setDesignationtData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState<any>(null);
  const [render, setRender] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Employee' : 'Create Employee'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/employeeRoute/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);
  useEffect(() => {
    axios
      .get(`/api/department/department/departmentForAutoComplete`)
      .then((res) => {
        setDepartmentData(res?.data);
        // console.log(res?.data);
      })
      .catch((err) => console.log(err));
    axios
      .get(`/api/autocompleteRoute/getDesignation`)
      .then((res) => {
        setDesignationtData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    setIsLoading(true);
    if (!data.area_of_work) {
      alert('Please select department');
      return;
    }
    const ans = await confirmSubmit();
    if (!ans) return;
    const formData = new FormData();

    if (typeof data.area_of_work === 'object') {
      data.area_of_work = data.area_of_work._id;
    }
    if (typeof data.designation_id === 'object') {
      data.designation_id = data.designation_id._id;
    }

    if (data.job_description && typeof data.job_description === 'object') {
      formData.append('job_description', JSON.stringify(data.job_description));
      delete data.job_description;
    }
    if (data.matrix && typeof data.matrix === 'object') {
      formData.append('matrix', JSON.stringify(data.matrix));
      delete data.matrix;
    }

    for (const key in data) {
      formData.append(key, data[key]);
    }

    if (image) {
      formData.append('image', image);
    }

    if (id) {
      await axios
        .put(`/api/employeeRoute/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
          setEmployeeId(null);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      await axios
        .post('/api/employeeRoute', formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
          setEmployeeId(null);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'employee_name'}
        name={'employee_name'}
        html_for={'employee_name'}
        label_name={'Employee Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.employee_name || ''}
        onChange={handleData}
      />
      <AutocompleteGridmui
        id={'designation'}
        label={'Designation'}
        option_name={'designation'}
        arrayofObj={designationData}
        value={data.designation_id || ''}
        onChange={(e, value) => {
          data.designation_id = value;
          setRender((prev) => !prev);
        }}
      />
      <GridDatePicker
        label_name="Date"
        html_for={'date_range'}
        value={data?.data_of_joining || null}
        onChange={(date) => {
          data.data_of_joining = date;
          setRender((prev) => !prev);
        }}
      />
      <SelectGridMui
        name="category"
        value={data?.category || ''}
        lable={'Category:'}
        onChange={(e: any) => handleData(e)}
      >
        <MenuItem value="">
          <em>NONE</em>
        </MenuItem>
        <MenuItem value="White Collar">White Collar</MenuItem>
        <MenuItem value="Blue Collar">Blue Collar</MenuItem>
      </SelectGridMui>
      <SelectGridMui
        name="sub_category"
        value={data?.sub_category || ''}
        lable={'Sub-Category:'}
        onChange={(e: any) => handleData(e)}
      >
        <MenuItem value="">
          <em>NONE</em>
        </MenuItem>
        <MenuItem value="IAI Roll">IAI Roll</MenuItem>
        <MenuItem value="Team Lease">Team Lease</MenuItem>
        <MenuItem value="Contractual">Contractual</MenuItem>
        <MenuItem value="Operator">Operator</MenuItem>
      </SelectGridMui>
      <GridInputs
        id={'qualification'}
        name={'qualification'}
        html_for={'qualification'}
        label_name={'Qualification:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.qualification || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'employee_id'}
        name={'employee_id'}
        html_for={'employee_id'}
        label_name={'Employee Id:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.employee_id || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'exp_years'}
        name={'exp_years'}
        html_for={'exp_years'}
        label_name={'Exp. Years:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.exp_years || ''}
        onChange={handleData}
      />
      <SelectGridMui
        name="current_level"
        value={data?.current_level || ''}
        lable={'Current Level:'}
        onChange={(e: any) => handleData(e)}
      >
        <MenuItem value="">
          <em>NONE</em>
        </MenuItem>
        <MenuItem value="Level_1">Level 1</MenuItem>
        <MenuItem value="Level_2">Level 2</MenuItem>
        <MenuItem value="Level_3">Level 3</MenuItem>
        <MenuItem value="Level_4">Level 4</MenuItem>
        <MenuItem value="Level_5">Level 5</MenuItem>
      </SelectGridMui>
      <AutocompleteGridmui
        id={'area_of_work'}
        label={'Department Name'}
        option_name={'department_name'}
        arrayofObj={departmentData}
        value={data.area_of_work_temp || data.area_of_work || ''}
        onChange={(e, value) => {
          data.area_of_work = value._id;
          data.area_of_work_temp = value;
          setRender((prev) => !prev);
        }}
      />
      <SelectGridMui
        name="employee_left"
        value={data?.employee_left || ''}
        lable={'Employee Left:'}
        onChange={(e: any) => handleData(e)}
      >
        <MenuItem value="">
          <em>NONE</em>
        </MenuItem>
        <MenuItem value="YES">YES</MenuItem>
        <MenuItem value="NO">NO</MenuItem>
      </SelectGridMui>
      <GridDatePicker
        label_name="Date Of Leave"
        html_for={'date_of_leave'}
        value={data?.date_of_leave || null}
        onChange={(date) => {
          data.date_of_leave = date;
          setRender((prev) => !prev);
        }}
      />
      {/* <Grid item xs={3}> */}
      <CustomUploadButton
        lable={'UPLOAD PROFILE'}
        multiple
        accept="image/png,image/jpeg,image/jpg"
        onChange={(e) => setImage(e.target.files[0])}
        // disabled={disable}
      />{' '}
      {/* </Grid> */}
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
