export const listConstant = {
  childHeader: 'Supplier Layout Plan list',
  addButtonLable: 'New Supplier Layout',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Supplier Layout Plan list',
  theadHeader: 'Supplier Layout PLAN',
  extracolSpan: 5,
};

export const checkListList = {
  childHeader: 'Supplier Layout STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'supplierLayoutPlanRoute',
  gettableDataurl: 'supplierLayoutPlanRoute/monthly',
  getPlanByid: 'supplierLayoutPlanRoute',
  createPlanByid: 'supplierLayoutPlanRoute',
  updatePlanByid: 'supplierLayoutPlanRoute',
  sync: 'supplierLayoutPlanRoute/handleSync',
  getAllCheckList: 'supplierLayoutPlanRoute/getAllCheckList',
  checkListStatus: 'supplierLayoutPlanRoute/handleStatus',
  checklistUpload: 'supplierLayoutPlanRoute/handleUpload',
  checklistDeleteImage: 'supplierLayoutPlanRoute/handleDeleteImage',
};

export const tilesConstant = {
  childHeaderText: 'SUPPLIER Layout PLAN',
};

export const navigationConstant = {
  createPlanSchedule: '/common/supplierLayoutPlanCreate',
};
