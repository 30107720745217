export const listConstant = {
  childHeader: 'Dock Audit Plan list',
  addButtonLable: 'New Dock',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Dock Audit Plan list',
  theadHeader: ' Dock Audit Plan',
  extracolSpan: 2,
};

export const checkListList = {
  childHeader: 'Dock STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'dockAuditPlanRoute',
  gettableDataurl: 'dockAuditPlanRoute/monthly',
  getPlanByid: 'dockAuditPlanRoute',
  createPlanByid: 'dockAuditPlanRoute',
  updatePlanByid: 'dockAuditPlanRoute',
  sync: 'dockAuditPlanRoute/handleSync',
  getAllCheckList: 'dockAuditPlanRoute/getAllCheckList',
  checkListStatus: 'dockAuditPlanRoute/handleStatus',
  checklistUpload: 'dockAuditPlanRoute/handleUpload',
  checklistDeleteImage: 'dockAuditPlanRoute/handleDeleteImage',
  checklistData: 'dockAuditPlanRoute/checklistRoute',
};

export const tilesConstant = {
  childHeaderText: 'DOCK AUDIT PLAN',
};

export const navigationConstant = {
  createPlanSchedule: '/common/dockAuditPlanPlanCreate',
  createChecklist: '/common/dockAuditPlanChecklistCreate',
};
