import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';

import { SubmitButton } from '../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../components/common/FormControlMui';
import useConfirm from '../../../components/common/useConfirm';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { useParams } from 'react-router-dom';
import { Loader } from '../../../components/common/Loader';
import { useQuery } from '../../../hooks/UseQuery';
import DatePickerMui from '../../../components/common/DatePickerMui';
import moment from 'moment';
interface Iprop {
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
}

const CreateCustomerStandard = ({ setIsOpenPopup, id, setId }: Iprop) => {
  const { customerId } = useParams();
  const documentType = useQuery('documentType') || '';
  const [data, setData] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState(null);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'SUBMIT DRAWING',
    `Are you sure you want to ${id ? 'update' : 'save'} this document?`
  );

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/customerStandards/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setData((prev: any) => prev);
    }
  }, [id]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) return;
    let formData = new FormData();
    setIsLoading(true);
    if (customerId) formData.append('customer_id', customerId);
    if (data.standard_name)
      formData.append('standard_name', data.standard_name);
    if (data.std_no) formData.append('std_no', data.std_no);
    if (data.rev_no) formData.append('rev_no', data.rev_no);
    if (data.revision_date)
      formData.append('revision_date', data.revision_date);
    if (data.iai_reviewed) formData.append('iai_reviewed', data.iai_reviewed);
    if (data.next_reviewed)
      formData.append('next_reviewed', data.next_reviewed);
    if (data.hand_over) formData.append('hand_over', data.hand_over);
    if (data.customer_standard_date)
      formData.append(
        'customer_standard_date',
        data.customer_standard_date.toString()
      );
    if (data.next_reviewed_date)
      formData.append('next_reviewed_date', data.next_reviewed_date.toString());
    if (data.iai_reviewed_date)
      formData.append('iai_reviewed_date', data.iai_reviewed_date.toString());
    if (data.remarks) formData.append('remarks', data.remarks);
    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }
    if (id) {
      await axios
        .put(`/api/customerStandards/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsOpenPopup(false);
          setId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      await axios
        .post('/api/customerStandards', formData)
        .then((res) => {
          setIsLoading(false);
          setIsOpenPopup(false);
          setId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <Box>
        <FormControlMui handleSubmit={onSubmit} style={{ height: '400px' }}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton
              label={`${id ? 'update' : 'submit'}`}
              type="submit"
              disabled={isLoading}
            />
          </div>
          <StyledField>
            <InputMuiCustom
              type="text"
              name="standard_name"
              placeholder="Standard Name"
              required={true}
              onChange={handlePartData}
              value={data?.standard_name || ''}
            />
            <InputMuiCustom
              type="string"
              name="std_no"
              placeholder="STD No."
              onChange={handlePartData}
              value={data?.std_no || ''}
            />

            <InputMuiCustom
              type="number"
              name="rev_no"
              placeholder="Rev No."
              onChange={handlePartData}
              value={data?.rev_no || ''}
            />

            <InputMuiCustom
              type="string"
              name="revision_date"
              placeholder="Revision Date"
              onChange={handlePartData}
              value={data?.revision_date || ''}
            />
            <InputMuiCustom
              type="string"
              name="iai_reviewed"
              placeholder="IAI Reviwed"
              onChange={handlePartData}
              value={data?.iai_reviewed || ''}
            />
            <DatePickerMui
              label="IAI Reviwed Date"
              onChange={(date) => {
                data.iai_reviewed_date = date;
                setRender((prev) => !prev);
              }}
              value={moment(data.iai_reviewed_date || null)}
            />
            <InputMuiCustom
              type="string"
              name="next_reviewed"
              placeholder="Next review"
              onChange={handlePartData}
              value={data?.next_reviewed || ''}
            />
            <DatePickerMui
              label="Next review Date"
              onChange={(date) => {
                data.next_reviewed_date = date;
                setRender((prev) => !prev);
              }}
              value={moment(data.next_reviewed_date || null)}
            />
            <InputMuiCustom
              type="string"
              name="hand_over"
              placeholder="Hand Over"
              onChange={handlePartData}
              value={data?.hand_over || ''}
            />
            <InputMuiCustom
              type="string"
              name="remarks"
              placeholder="Remarks"
              onChange={handlePartData}
              value={data?.remarks || ''}
            />
            <DatePickerMui
              label="DATE"
              onChange={(date) => {
                data.customer_standard_date = date;
                setRender((prev) => !prev);
              }}
              value={moment(data.customer_standard_date || null)}
            />
            <CustomUploadButton
              lable={'UPLOAD DRAWING'}
              multiple
              accept="image/png,image/jpeg,image/jpg,application/pdf"
              onChange={(e) => setFiles(e.target.files)}
            />
          </StyledField>
        </FormControlMui>
      </Box>
      <DialogSubmit />
    </>
  );
};

export default CreateCustomerStandard;
