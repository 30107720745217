import moment from 'moment';
import lodash from 'lodash';

export const processes = [
  {
    point: 'BIN SHOULD BE FREE FROM DUST AND DIRT',
    checking_method: 'VISUAL',
    shift_A: 'A',
    shift_B: 'B',
    shift_A_results: Array.from({ length: 31 }, () => ''),
    shift_B_results: Array.from({ length: 31 }, () => ''),
    remarks_1: '',
    remarks_2: '',
  },
  {
    point: 'BIN SHOULD BEFREE FROM OIL & GREASE',
    checking_method: 'VISUAL',
    shift_A: 'A',
    shift_B: 'B',
    shift_A_results: Array.from({ length: 31 }, () => ''),
    shift_B_results: Array.from({ length: 31 }, () => ''),
    remarks_1: '',
    remarks_2: '',
  },
  {
    point: 'BIN SHOULD BE NOT BROKEN',
    checking_method: 'VISUAL',
    shift_A: 'A',
    shift_B: 'B',
    shift_A_results: Array.from({ length: 31 }, () => ''),
    shift_B_results: Array.from({ length: 31 }, () => ''),
    remarks_1: '',
    remarks_2: '',
  },
  {
    point: 'BIN SHOULD NOT DAMAGE FROM ANY WHERE',
    checking_method: 'VISUAL',
    shift_A: 'A',
    shift_B: 'B',
    shift_A_results: Array.from({ length: 31 }, () => ''),
    shift_B_results: Array.from({ length: 31 }, () => ''),
    remarks_1: '',
    remarks_2: '',
  },
  {
    point: 'EACH BIN SHOULD HAVE IDENTIFICATION TAG AT EACH STATION',
    checking_method: 'VISUAL',
    shift_A: 'A',
    shift_B: 'B',
    shift_A_results: Array.from({ length: 31 }, () => ''),
    shift_B_results: Array.from({ length: 31 }, () => ''),
    remarks_1: '',
    remarks_2: '',
  },
  {
    point: 'ONE TYPE OF PART PLACED IN ONE BIN',
    checking_method: 'VISUAL',
    shift_A: 'A',
    shift_B: 'B',
    shift_A_results: Array.from({ length: 31 }, () => ''),
    shift_B_results: Array.from({ length: 31 }, () => ''),
    remarks_1: '',
    remarks_2: '',
  },
  {
    point: 'REJECTED PART KEEP IN RED BIN HOLD PART KEEP IN YELLOW BIN',
    checking_method: 'VISUAL',
    shift_A: 'A',
    shift_B: 'B',
    shift_A_results: Array.from({ length: 31 }, () => ''),
    shift_B_results: Array.from({ length: 31 }, () => ''),
    remarks_1: '',
    remarks_2: '',
  },
  {
    point: 'CHECKED BY',
    checking_method: 'OPERATOR',
    shift_A: 'A',
    shift_B: 'B',
    shift_A_results: Array.from({ length: 31 }, () => ''),
    shift_B_results: Array.from({ length: 31 }, () => ''),
    remarks_1: '',
    remarks_2: '',
  },
  {
    point: 'VERIFIED BY',
    checking_method: 'TEAM LEADER /SUPERVISOR',
    shift_A: 'A',
    shift_B: 'B',
    shift_A_results: Array.from({ length: 31 }, () => ''),
    shift_B_results: Array.from({ length: 31 }, () => ''),
    remarks_1: '',
    remarks_2: '',
  },
];

export const initialState = {
  isSubmitted: false,
  is_admin_request_no: null,
  is_admin_request: null,
  report_prepared_by: null,
  report_approved_by: null,
  dailyBin_date: moment(),
  station: '',
  processes: lodash.cloneDeep(processes),
};
