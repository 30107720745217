import { StyledTableHeaderRow } from '../../../../../components/common/rowFreeze';
import TableCompHead from '../../../../../components/ui/TableCompHead';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'SR. NO.',
  'PART NO.',
  'ERP No. / Drawing No.',
  'Fox Pro No. / Ref. Drg. No.',
  'MAX QTY IN STORE',
  'MIN QTY IN STORE',
  'MAX QTY IN BIN',
  'ACTION',
];

const THead = ({ moduleData, isView }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={8}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="STORE PART LIST"
      />
      <StyledTableHeaderRow>
        {theadData.map((heading, index) => (
          <th key={heading + index}>{heading}</th>
        ))}
      </StyledTableHeaderRow>
    </thead>
  );
};

export default THead;
