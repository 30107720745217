import { TableCell, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CustomPagination,
  SearchBar,
  TableCustom,
} from '../../../components/common';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import formatDate from '../../../components/common/formatDate';
import useDebounce from '../../../hooks/useDebounce';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import { IData } from '../helpers/interfaces';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'STANDARD NAME', rowspan: 1, colspan: 1 },
    { text: 'STD NO.', rowspan: 1, colspan: 1 },
    { text: 'REV NO.', rowspan: 1, colspan: 1 },
    { text: 'REVISION DATE', rowspan: 1, colspan: 1 },
    { text: 'IAI REVIEW', rowspan: 1, colspan: 1 },
    { text: 'NEXT REVIEW', rowspan: 1, colspan: 1 },
    { text: 'HANDOVER SIGN', rowspan: 1, colspan: 1 },
    { text: 'DATE', rowspan: 1, colspan: 1 },
    { text: 'REMARKS ', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];
interface IProps {
  setId: React.Dispatch<React.SetStateAction<string | null>>;
  isOpenPopup: boolean;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFileOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
}
const CustomerStandardTable = ({
  setId,
  isOpenPopup,
  setIsOpenPopup,
  setIsFileOpenPopup,
}: IProps) => {
  const [data, setData] = useState([]);
  const [render, setRender] = useState(false);
  const { customerId } = useParams();
  const { state } = useAuth();
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search');
  const page = useQuery('page') || 1;
  const fetchPartData = () => {
    axios
      .get(
        `/api/customerStandards/list/${customerId}?search=${search}&page=${page}`
      )
      .then((res) => {
        if (res.data) {
          setData(res.data?.results);
          setPerPage(res.data?.perPage);
          setTotalPages(res.data?.pages);
        }
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, [page, render, isOpenPopup, search]);
  const deleteDocument = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?'
    );
    if (isDeleted) {
      axios
        .delete(`/api/customerStandards/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  const calcutateRed = (item: any) => {
    const iai_reviewed_date = new Date(item?.iai_reviewed_date).getTime();
    const next_reviewed_date = new Date(item?.next_reviewed_date).getTime();
    const currenDate = Date.now();

    if (
      currenDate > next_reviewed_date &&
      iai_reviewed_date < next_reviewed_date
    )
      return true;

    return false;
  };

  return (
    <Box>
      <Box m={2}>
        <SearchBar label="Search Standard Name" />
      </Box>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        {data.map((document: IData, index: number) => (
          <TableRow
            key={document._id}
            style={{
              background: `${calcutateRed(document) ? '#ffe3e3' : '#fff'}`,
            }}
          >
            <TableCell align="center">{index + 1}</TableCell>
            <TableCell align="center">{document.standard_name}</TableCell>
            <TableCell align="center">{document.std_no}</TableCell>
            <TableCell align="center">{document.rev_no}</TableCell>
            <TableCell align="center">{document.revision_date}</TableCell>
            <TableCell align="center">
              {formatDate(document.iai_reviewed_date)}
            </TableCell>
            <TableCell align="center">
              {formatDate(document.next_reviewed_date)}
            </TableCell>
            <TableCell align="center">{document.hand_over}</TableCell>
            <TableCell align="center">
              {formatDate(document?.customer_standard_date?.toString() || '')}
            </TableCell>
            <TableCell align="center">{document.remarks}</TableCell>
            <TableCell align="center">
              <ViewIconButton
                tooltipTitle="view"
                onClick={() => {
                  setId(document._id || null);
                  setIsFileOpenPopup(true);
                }}
              />
              {state.user?.userType === 2 && (
                <>
                  <EditIconButton
                    tooltipTitle="EDIT"
                    onClick={() => {
                      setId(document?._id! || null);
                      setIsOpenPopup(true);
                    }}
                  />
                  <DeleteIconButton
                    tooltipTitle="DELETE"
                    onClick={() => {
                      deleteDocument(document._id || '');
                    }}
                  />
                </>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
    </Box>
  );
};

export default CustomerStandardTable;
