import { Grid } from '@mui/material';
import { Box, Stack } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { PersitedDatePicker } from '../../components/common';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';
import formatDate from '../../components/common/formatDate';
import InputMuiCustom from '../../components/common/inputs/InputMuiCustom';
import ChildHeader from '../../components/ui/ChildHeader';
import { useQuery } from '../../hooks/UseQuery';
import MilliporeGraphTable from './MilliporeGraphTable';
import MultipleLineChart from './MulitipleLineChart';

const MilliPoreRunChart = () => {
  const date = useQuery('date');
  const [customers, setCustomers] = useState([]);
  const [customerObj, setCustomerObj] = useState<any>(null);
  const [specificationAutoComplete, setSpecificationAutoComplete] =
    useState<any>('');
  const [ucl, setUcl] = useState(0);
  const [lcl, setLcl] = useState(0);
  const [cl, setCl] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [contaminant, setContaminant] = useState([]);
  const [specification, setSpecificaton] = useState([]);
  const [labels, setLabels] = useState([]);
  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomers(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    setContaminant([]);
    setLabels([]);
    setSpecificaton([]);
    if (customerObj && date) {
      axios
        .get(
          `/api/milliporeRoute/generateGraphData/${date}/${customerObj._id}?specification=${specificationAutoComplete?.specification}`
        )
        .then((res) => {
          setGraphData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [customerObj, specificationAutoComplete, date]);
  useEffect(() => {
    if (graphData.length) {
      const values1: any = [...contaminant];
      const values2: any = [...specification];
      const values3: any = [...labels];
      graphData.forEach((data: any) => {
        if (data.weight_contaminats) {
          let number = parseFloat(
            data.weight_contaminats.replace(/[^\d.]/g, '')
          );
          values1.push(number);
        }
        if (data.specificaton) {
          let number = parseFloat(data.specificaton.replace(/[^\d.]/g, ''));
          values2.push(number);
        }
        values3.push(new Date(data.created_date).getDate());
      });
      setContaminant(values1);
      setSpecificaton(values2);
      setLabels(values3);
    }
  }, [graphData]);
  const getUniqueSpecifications = (graphData: any) => {
    const unique = [
      ...new Set(graphData.map((item: any) => item.specificaton)),
    ];

    const arrayOfObj = unique.map((data: any, index: any) => {
      return { specification: data };
    });
    return arrayOfObj;
  };

  return (
    <Box>
      <ChildHeader text="MILLIPORE RUN CHART" />
      <Stack direction={'row'} alignItems="center" ml={7} mt={1} width={800}>
        <div style={{ flex: 0.6 }}>
          <AutocompleteMuiCustom
            id={'customer_name'}
            label={'Customer Name'}
            option_name={'customer_name'}
            arrayofObj={customers}
            value={customerObj || ''}
            onChange={(e, value) => {
              setCustomerObj(value);
            }}
          />
        </div>
        <div style={{ margin: 20, flex: 0.6 }}>
          <AutocompleteMuiCustom
            id={'specification'}
            label={'Specification'}
            option_name={'specification'}
            arrayofObj={getUniqueSpecifications(graphData)}
            value={specificationAutoComplete || ''}
            onChange={(e, value) => {
              setSpecificationAutoComplete(value);
            }}
          />
        </div>
        <Box sx={{ margin: 2 }}>
          <PersitedDatePicker
            label="Search By Month"
            views={['month', 'year']}
          />
        </Box>
      </Stack>
      <Grid container spacing={2} ml={5} mt={0.5}>
        <Grid item xs={3}>
          <InputMuiCustom
            variant={'outlined'}
            type="number"
            name="ucl"
            placeholder={'UCL'}
            onChange={(e) => {
              setUcl(e.target.valueAsNumber);
            }}
            value={ucl}
          />
        </Grid>

        <Grid item xs={3}>
          <InputMuiCustom
            variant={'outlined'}
            type="number"
            name="lcl"
            placeholder={`LCL`}
            onChange={(e) => {
              setLcl(e.target.valueAsNumber);
            }}
            value={lcl}
          />
        </Grid>
        <Grid item xs={3}>
          <InputMuiCustom
            variant={'outlined'}
            type="number"
            name="lc"
            placeholder={`CL`}
            onChange={(e) => {
              setCl(e.target.valueAsNumber);
            }}
            value={cl}
          />
        </Grid>
      </Grid>
      <MultipleLineChart
        title1="WEIGHT OF CONTAMINANT V/S CL"
        title2="WEIGHT OF CONTAMINANT"
        milliporeData={contaminant}
        {...{ ucl, lcl, cl, labels }}
      />
      <MultipleLineChart
        title1="SPECIFICATION V/S CL"
        title2="SPECIFICATION"
        milliporeData={specification}
        {...{ ucl, lcl, cl, labels }}
      />
      <MilliporeGraphTable {...{ graphData }} />
    </Box>
  );
};

export default MilliPoreRunChart;
