import { GrUser } from 'react-icons/gr';
import { HiDocumentReport } from 'react-icons/hi';
import { TbReportAnalytics } from 'react-icons/tb';
import { GrDatabase } from 'react-icons/gr';
import { SiGooglesheets } from 'react-icons/si';
import { TbFileSpreadsheet } from 'react-icons/tb';
import { RiDeleteBin4Line } from 'react-icons/ri';
import { TbNotebook } from 'react-icons/tb';
import { MdPlaylistAdd } from 'react-icons/md';
import { CgPlayListSearch } from 'react-icons/cg';
import { ImStatsDots } from 'react-icons/im';
import { BsCardChecklist } from 'react-icons/bs';
import { BsUiChecks } from 'react-icons/bs';
import { SiTestcafe } from 'react-icons/si';
import { MdAir } from 'react-icons/md';
import { MdRoomPreferences } from 'react-icons/md';

import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { CiBarcode } from 'react-icons/ci';
const tiles = [
  // rejection note common for all
  {
    sNo: 1,
    link: '/common/firstFloorList',
    isLink: true,
    icon: <TbNotebook size="5rem" color="#a12222" />,
    p: 'FIRST',
    span: 'FLOOR',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/common/groundFloorList',
    isLink: true,
    icon: <TbNotebook size="5rem" color="#a12222" />,
    p: 'GROUND',
    span: 'FLOOR',
    isIcon: true,
  },
];

const RejectionNoteHome = () => {
  return (
    <>
      <ChildHeader text="REJECTION NOTE" />
      <RenderTiles tilesObj={tiles} />
    </>
  );
};

export default RejectionNoteHome;
