import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  apiData: any;
  year: number;
  totalProducedQty: number;
  target: number;
  average: number;
}
const TController = ({
  apiData,
  year,
  totalProducedQty,
  target,
  average,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <>
          <THead year={year} />
          <TBody apiData={apiData} totalProducedQty={totalProducedQty} />
          {apiData.rejectionMonthlyCounts.length > 0 && (
            <TFoot
              apiData={apiData}
              totalProducedQty={totalProducedQty}
              target={target}
              average={average}
            />
          )}
        </>
      </RenderTable>
    </Box>
  );
};

export default TController;
