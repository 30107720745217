import { useState } from 'react';
import { TableInput } from '../../../../../../../components/common';
import DatePickerMui from '../../../../../../../components/common/DatePickerMui';
import moment from 'moment';
import formatDate from '../../../../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  isView: boolean;
  category: any;
  area: any;
}
const TFoot = ({ moduleData, isView, category, area }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <th colSpan={4} align="left">
          {area === '3rd Layer' && 'Manager: '}
          {category === 'Dev ' && 'Prod. Manager: '}
          {category === 'QA Manager / Section Head (QA)' &&
            'Quality Assurance Manager: '}
          {category === 'Plant Head' && 'Operation Head: '}
          <TableInput
            isView={isView}
            name="category"
            type="text"
            value={moduleData.category}
            onChange={(e: any) => {
              moduleData.category = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={4} align="left">
          Date:{' '}
          {isView ? (
            formatDate(moduleData.d2)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d2)}
              onChange={(date: any) => {
                moduleData.d2 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
