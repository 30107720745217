import { Stack } from '@mui/material';
import {
  CustomButton,
  UpdateButton,
} from '../../../components/common/button/CustomButtons';
import { PersitedDatePicker } from '../../../components/common';
import { MdDeleteForever } from 'react-icons/md';
import useConfirm from '../../../components/common/useConfirm';
import moment from 'moment';
import { useQuery } from '../../../hooks/UseQuery';

const HeaderCreate = ({
  handleUpdate,
  moduleData,
  setModuleData,
  handleReportDelete,
}: any) => {
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const date = useQuery('date');

  const isAddRowDisable = () => {
    if (
      !moduleData.change_freq_days ||
      !moduleData.change_freq ||
      moduleData.starting_qty === undefined ||
      moduleData.starting_qty === null ||
      moduleData.starting_qty === '' ||
      isNaN(moduleData.starting_qty)
    ) {
      return true;
    }

    if (moduleData?.list?.length === 0) {
      return false;
    }

    const list = moduleData?.list?.[moduleData.list.length - 1];
    const { checked_qty, action_taken, date } = list;
    console.log(checked_qty);
    if (
      checked_qty === null ||
      checked_qty === undefined ||
      checked_qty === '' ||
      date === null ||
      date._isValid === false
    ) {
      return true;
    }

    if (action_taken === 'CHANGE PENDING') {
      return true;
    }

    return false;
  };

  return (
    <>
      <Stack
        direction="row"
        sx={{
          justifyContent: 'space-between',
          alignItems: 'center',
          pl: 2,
          pb: 1,
          pt: 2,
          gap: 2,
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: 'start',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <PersitedDatePicker
            label="Search By Year"
            views={['year', 'month']}
          />
        </Stack>

        <Stack
          direction="row"
          sx={{
            gap: 2,
          }}
        >
          <CustomButton
            sx={{
              backgroundColor: '#c92a2a',
              color: '#e7f5ff',
            }}
            icon={<MdDeleteForever />}
            size="large"
            disabled={!moduleData._id}
            onClick={() => handleReportDelete()}
          >
            RESET REPORT
          </CustomButton>

          <CustomButton
            sx={{
              backgroundColor: '#1c7ed6',
            }}
            disabled={isAddRowDisable()}
            onClick={async () => {
              const ans = await confirmAddRow();
              if (!ans) return;
              setModuleData((prev: any) => {
                const { list } = prev;
                const listLength: number = list.length;
                const lastItem = list[listLength - 1];

                // Determine previous cumulative quantity
                let prev_cumm_qty =
                  listLength > 0 ? lastItem.cumm_qty : moduleData.starting_qty;

                // Reset previous cumulative quantity based on last item's action taken
                if (
                  lastItem?.action_taken === 'CHANGE PENDING' ||
                  lastItem?.action_taken === 'CHANGE COMPLETED'
                ) {
                  prev_cumm_qty = 0;
                }

                const calculateMinDate = () => {
                  if (listLength === 0) {
                    return moment(date)?.startOf('month');
                  }

                  const prevRowDate = list[listLength - 1]?.date;
                  return prevRowDate
                    ? moment(prevRowDate)
                    : moment(date).startOf('month');
                };
                const startOfMonth = calculateMinDate();
                const endOfMonth = moment(date)?.endOf('month');

                return {
                  ...prev,
                  list: [
                    ...prev.list,
                    {
                      date: null,
                      remarks: null,
                      checked_qty: listLength === 0 ? 0 : null,
                      operator_name: null,
                      status: null,
                      action_taken: null,
                      cumm_qty: prev_cumm_qty,
                      startOfMonth: startOfMonth,
                      endOfMonth: endOfMonth,
                    },
                  ],
                };
              });
            }}
          >
            ADD ROWS
          </CustomButton>

          <UpdateButton
            label={moduleData._id ? 'update' : 'save'}
            sx={{ mr: '37px', fontSize: '2rem' }}
            size="large"
            onClick={() => {
              handleUpdate();
            }}
          />
        </Stack>
      </Stack>
      <DialogAddRow />
    </>
  );
};

export default HeaderCreate;
