import { useState, useEffect } from 'react';
import axios from 'axios';
import { Button } from '@mui/material';
import { useAuth } from '../../services/auth/AuthProvider';
import { images, textInputs } from '../../utils/constants/constant';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import {
  LoginContainer,
  LoginBox,
  LeftSideContainer,
  Form,
  RightSideContainer,
  StyledLabel,
} from './login.styled';

const Login = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [username, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string | null>(null);
  const { state, login } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  let fromForAdmin = location.state?.from?.pathname+location.state?.from?.search || '/admin/home';
  let fromForSupervisor = location.state?.from?.pathname +location.state?.from?.search || '/supervisor/home';
  if (state.user?.userType === 2) {
    fromForAdmin.includes('/supervisor/home') && (fromForAdmin = '/admin/home');
    navigate(fromForAdmin, { replace: true });
  }
  if (state.user?.userType === 3) {
    fromForSupervisor.includes('/admin/home') &&
      (fromForSupervisor = '/supervisor/home');
    navigate(fromForSupervisor, { replace: true });
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  function validateForm() {
    return username.length > 0 && password.length > 0;
  }

  const handleLogin = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateForm()) {
      axios
        .post('/api/userRoutes/login', {
          username: username,
          password: password,
        })
        .then((res) => {
          login();

          if (state.user?.userType === 2) {
            navigate('/admin/home');
          }
          if (state.user?.userType === 3) {
            navigate('/supervisor/home');
          }
        })
        .catch((error) => {
          console.log(error);
          setError('Invalid Credentials');
        });
    }
  };

  return (
    <LoginContainer>
      <LeftSideContainer>
        <LoginBox>
          <h1
            style={{
              fontWeight: '400',
            }}
          >
            Welcome to Mushin™ AQUA
          </h1>
          <Form onSubmit={(e) => handleLogin(e)}>
            <StyledLabel>Email/Username</StyledLabel>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              autoComplete="email"
              value={username}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
              sx={{
                mb: 2,
                mt: 0,
                '.MuiInputBase-input': { fontSize: '1.3rem' },
              }}
            />
            <StyledLabel>Password</StyledLabel>
            <FormControl
              variant="outlined"
              sx={{
                mb: 1,
                '.MuiInputBase-input': { fontSize: '1.3rem' },
              }}
            >
              <OutlinedInput
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {error && (
                <>
                  <small
                    style={{
                      color: 'red',
                      textAlign: 'center',
                      display: 'block',
                      fontSize: '1.6rem',
                      marginTop: '1rem',
                      marginBottom: '0.8rem',
                    }}
                  >
                    {error}
                  </small>
                </>
              )}
            </FormControl>
            <Button
              variant="contained"
              style={{
                backgroundColor: '#c80202',
                color: 'white',
                width: '100%',
                height: '50px',
                fontSize: '1.4rem',
                fontWeight: '500',
              }}
              type="submit"
              // disabled={!validateForm()}
              //   value={state.loading ? "Loading..." : "Login"}
              startIcon={<LockOpenIcon />}
            >
              Login
            </Button>
          </Form>
        </LoginBox>
      </LeftSideContainer>
      <RightSideContainer>
        <img
          src={images.companyImg}
          alt={textInputs.companyName}
          height={'auto'}
          width={'300'}
        />
        <img width="300px" height="300px" src={images.companyLogo} alt="logo" />
      </RightSideContainer>
    </LoginContainer>
  );
};

export default Login;
