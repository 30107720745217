import moment from 'moment';

export const processes = [
  {
    date_diesel_received: moment(),
    received_qty: 0,
    remark: '',
  },
];

export const processesTwo = [
  {
    date_issue: moment(),
    consumed_qty: 0,
    dg_detail: '',
    remark: '',
  },
];
