import { useState } from 'react';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import TableHeaderHtmlCustom from '../../../../components/common/customTable/TableHeaderHtmlCustom';
import { useQuery } from '../../../../hooks/UseQuery';
import { moduleConstant } from '../constant';
interface IProps {
  moduleData: any;
  setModuleData: any;
  isView: boolean;
  machineData: any;
  setRenderforUpdate: any;
}
const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '100px',
  height: '50px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
  minWidth: '80px',
};

const tableHeaderObj = [
  [
    { text: 'S.NO', style: style1 },
    { text: 'DATE', style: style1 },
    { text: 'LEAKAGE SAMPLE PART', style: style1 },
    { text: 'PIPE SIZE', style: style1 },
    { text: 'TESTING PRESSURE REQUIRED', style: style1 },
    { text: 'TESTING TIME REQUIRED', style: style1 },
    { text: 'LEAKAGE SAMPLE TOTAL CYCLE TIME', style: style1 },
    { text: 'STATUS OK/NOT OK', style: style1 },
    { text: 'OPERATOR NAME', style: style1 },
    { text: 'CHECKING TIME', style: style1 },
    { text: 'REMARKS', style: style1, colspan: 2 },
  ],
];

const THead = ({ moduleData, machineData, setRenderforUpdate }: IProps) => {
  const date = useQuery('date');
  const year = date && new Date(date).getFullYear();

  return (
    <thead>
      <tr>
        <th style={{ background: '#495057', color: '#f8f9fa' }} colSpan={12}>
          {`${moduleConstant?.heading} - ${year}`}
        </th>
      </tr>
      <tr>
        <th colSpan={2}>Machine No.</th>
        <td colSpan={3}>
          <AutocompleteMuiCustom
            id="machine_no"
            option_name="machine_no"
            arrayofObj={machineData || []}
            value={moduleData?.machine_id || ''}
            onChange={(e, value) => {
              moduleData.machine_id = value;
              setRenderforUpdate((prev: any) => !prev);
            }}
          />
        </td>
        <td colSpan={7}></td>
      </tr>
      <TableHeaderHtmlCustom tableHeaderObj={tableHeaderObj} />
    </thead>
  );
};

export default THead;
