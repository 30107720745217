import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';

// const shiftResult = {
//   true: '✓',
//   false: '⨯',
// };

interface IProps {
  moduleData: any;
  isView: boolean;
  process: any;
  index: number;
  item: any;
  idx: number;
  // handelDeleteRows: any;
  // setIsOpenPopup: any;
  // setPartIdx: any;
}
const TBodyRow = ({
  moduleData,
  isView,
  process,
  index,
  item,
  idx,
}: // handelDeleteRows,
// setIsOpenPopup,
// setPartIdx,
IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tr key={idx + 33.33 + index + 'row'}>
      {idx === 0 && (
        <>
          <th
            rowSpan={process?.applicable_ppe?.length}
            style={{ minWidth: '50px', textAlign: 'center' }}
          >
            {index + 1}
          </th>
          <td
            rowSpan={process?.applicable_ppe?.length}
            style={{ minWidth: '200px', textAlign: 'center' }}
          >
            {process.process_name}
          </td>
        </>
      )}
      <td style={{ minWidth: '120px', textAlign: 'left' }}>{item?.name}</td>
      {Array.from({ length: 31 }, (_, indx) => (
        <td
          style={{
            minWidth: '100px',
            textAlign: 'center',
            fontSize: '2rem',
          }}
          key={index + 2.232323 + indx + 'after' + 111.33}
        >
          {isView ? (
            (item.results[indx] === 'true' && '✓') ||
            (item.results[indx] === 'false' && '⨯')
          ) : (
            <CustomSelect
              name="results[indx]"
              value={item.results[indx]}
              onChange={(e) => {
                item.results[indx] = e.target.value;
                setRender((prev) => !prev);
              }}
            >
              <MenuItem value="true">✓</MenuItem>
              <MenuItem value="false">⨯</MenuItem>
            </CustomSelect>
          )}
        </td>
      ))}
      <td style={{ minWidth: '150px', textAlign: 'center' }}>
        <TableInput
          isView={isView}
          name="remarks"
          type="text"
          value={item.remarks}
          onChange={(e) => {
            handleChangeModuleData(e, item);
            setRender((prev) => !prev);
          }}
        />
      </td>
    </tr>
  );
};

export default TBodyRow;
