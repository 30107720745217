import { useState } from 'react';
import { SearchBar } from '../../../components/common/SearchBar';
import FilterBy from '../../../components/common/FilterBy';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import ChildHeader from '../../../components/ui/ChildHeader';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import ModalCustom from '../../../components/common/Modal';
import {
  AddButton,
  CustomButton,
} from '../../../components/common/button/CustomButtons';
import TableCustom from '../../../components/common/customTable/TableCustom';
import useDebounce from '../../../hooks/useDebounce';
import React from 'react';
import formatDate from '../../../components/common/formatDate';
import {
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';

import { useQuery } from '../../../hooks/UseQuery';
import CustomPagination from '../../../components/common/CustomPagination';
import useConfirm from '../../../components/common/useConfirm';
import { useNavigate } from 'react-router-dom';
import AddEditProcess, { IProcess } from './components/AddEditProcess';

const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Process Name', rowspan: 1, colspan: 1 },
    { text: 'Process Number', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Edit Process', rowspan: 1, colspan: 1 },
    { text: 'PFMEA REPORT', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'A-Z' },
  { value: 'process_name', name: 'Process Name' },
  { value: 'process_no', name: 'Process Number' },
];

const List = () => {
  const [data, setData] = useState<IProcess[]>([]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState<string | null>(null);
  const navigate = useNavigate();
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to DELETE this Document?',
  );
  const search = useQuery('search');
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const fetchData = async () => {
    const res: any = await axios
      .get(
        `/api/pfmea?page=${page}&sortBy=${
          filter || 'part_number'
        }&search=${search}&perPage=${10}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  const deletePart = async (id: string) => {
    const isDelete = await confirmDelete();
    if (!isDelete) {
      return;
    }
    await axios.delete(`/api/pfmea/${id}`);
    setRender((prev) => !prev);
  };

  useDebounce(fetchData, 1000, [page, search, render, filter, isOpenPopup]);

  return (
    <>
      <ChildHeader text="PFMEA LIST">
        <AddButton onClick={() => setIsOpenPopup(true)} label="New PFMEA" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search Process" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IProcess, index: number) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.process_name}</TableCell>
                  <TableCell align="center">{item?.process_no}</TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>
                  <TableCell align="center">
                    <EditIconButton
                      tooltipTitle="EDIT"
                      onClick={() => {
                        setId(item?._id!);
                        setIsOpenPopup(true);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <CustomButton
                        sx={{ margin: '10px' }}
                        size="small"
                        onClick={() => {
                          navigate(
                            `/common/editPfmea/${item._id}?isView=true&pfmea=true`,
                          );
                        }}
                      >
                        GENERATE PFMEA
                      </CustomButton>
                    </Stack>
                  </TableCell>

                  <TableCell align="center">
                    <EditIconButton
                      tooltipTitle="EDIT"
                      onClick={() => {
                        navigate(`/common/editPfmea/${item._id}?isView=false`);
                      }}
                    />
                    <ViewIconButton
                      tooltipTitle="VIEW"
                      onClick={() => {
                        navigate(`/common/editPfmea/${item._id}?isView=true`);
                      }}
                    />
                    <DeleteIconButton
                      tooltipTitle="DELETE"
                      onClick={() => {
                        if (item._id) {
                          deletePart(item._id);
                        }
                      }}
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />

      <ModalCustom
        openModal={isOpenPopup}
        closeModal={() => {
          setId(null);
          setIsOpenPopup(false);
        }}
        title="CREATE PFMEA"
      >
        <AddEditProcess {...{ id, setId, setIsOpenPopup }} />
      </ModalCustom>
      <DialogDelete />
    </>
  );
};

export default List;
