import React, { useState } from 'react';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../components/common';
import { RenderTable } from '../../../../components/ui';
import { Table } from '../../../../components/ui/renderTable/styled';

const RejectionTable = (props: any) => {
  const { process, isView } = props;
  const [render, setRender] = useState(false);

  return (
    <Table style={{ width: '80vh' }}>
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Reasons</th>
          <th>Rejection Qty</th>
        </tr>
      </thead>
      <tbody>
        {process?.reasons_for_rej?.map((item: any, index: number) => (
          <tr>
            <th>{index + 1}</th>
            <td style={{ minWidth: '300px', textAlign: 'center' }}>
              {isView ? (
                item.reason
              ) : (
                <CustomSelect
                  name="reason"
                  value={item.reason}
                  onChange={(e) => {
                    item.reason = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {[
                    'Bead Over Size',
                    'Bead Under Size',
                    'Bend',
                    'Bending NG',
                    'Black Spot',
                    'Blockage',
                    'Barcode  NG',
                    'Bracket NG',
                    'Burr/ Chips',
                    'Coating  NG',
                    'Clamp Out',
                    'Collapses',
                    'Connector Damage',
                    'Crack',
                    'Damage',
                    'Dent',
                    'Double Punching',
                    'Extra Material',
                    'Face Dent',
                    'Face Taper',
                    'Face Uneven',
                    'Fitment Out',
                    'Flaring NG',
                    'Flaring Short/ Over Size/Crack',
                    'Gap',
                    'No Go Pass',
                    'Hose Cut',
                    'Hose Leakage',
                    'Hose NG',
                    'Hose Shift',
                    'Leakage',
                    'Leakage From Brazing',
                    'Leakage Near Clamp',
                    'Lining',
                    'Line On Pipe',
                    'Miss Printing/NG',
                    'Miss Brazing',
                    'Nut Damage',
                    'Nut Dent',
                    'Nut Jaam',
                    'Nut Tight',
                    'Orientation Out',
                    'Oring Area Damage',
                    'Ovality',
                    'Over Brazing',
                    'Over Length',
                    'Over Size',
                    'Paraflaring NG',
                    'Paraflaring Over Size',
                    'Pin Hole',
                    'Pin Out',
                    'Pipe Damage',
                    'Poor Plating',
                    'Punching NG',
                    'Reverse Nut',
                    'Rubber Cut',
                    'Rust',
                    'Short Brazing',
                    'Short Length',
                    'Sleeve Loose',
                    'Sleeve NG',
                    'Small Hole',
                    'Taper',
                    'Tapper',
                    'Thread Tight',
                    'T-Marking Missing',
                    'Tool Mark',
                    'Under Size',
                    'Welding NG',
                    'With Out Bracket',
                    'Wrinkle',
                    'Wrong Nut',
                  ].map((reason: string) => (
                    <MenuItem value={reason}>{reason}</MenuItem>
                  ))}
                </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="rej_qty"
                key="rej_qty"
                type="number"
                value={item.rej_qty}
                onChange={(e) => {
                  item.rej_qty = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={2}>Total Rejction</th>
          <th>{process.rejection_qty}</th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default RejectionTable;
