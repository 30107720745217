
import React, { useState } from 'react';
import TableCustom from '../../components/common/customTable/TableCustom';
import { Stack, TableBody, TableCell, TableRow  } from '@mui/material';
import { SearchBar } from '../../components/common/SearchBar';
import FilterBy from '../../components/common/FilterBy';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import ChildHeader from '../../components/ui/ChildHeader';
import axios from 'axios';
import useDebounce from '../../hooks/useDebounce';
import formatDate from '../../components/common/formatDate';
import {
  ViewIconButton,
} from '../../components/common/button/CustomIconButton';
import { useQuery } from '../../hooks/UseQuery';
import { useNavigate } from 'react-router-dom';
import { CustomPagination } from '../../components/common';
import { IPart } from '../addparts/AddEditPart';
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Item Number', rowspan: 1, colspan: 1 },
    { text: 'Item Description', rowspan: 1, colspan: 1 },
    { text: 'Customer', rowspan: 1, colspan: 1 },
    { text: 'Supplier', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'part_number', name: 'A-Z' },
  { value: 'createdAt', name: 'Most Recent First' },
  { value: 'createdAt_old', name: 'Oldest First' },
];

const SupervisorViewPpap = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<IPart[]>([]);
  const [perPage, setPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;

  const fetchData = async () => {
    const res: any = await axios
      .get(
        `/api/addPartRoute/byDocType/publish?page=${page}&sortBy=${filter}&search=${search}&perPage=${10}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res.data?.result);
    setPerPage(res.data?.perPage);
    setTotalPages(res.data?.pages);
  };

  useDebounce(fetchData, 1000, [
    page,
    search,
    filter,
  ]);


  return (
    <div>
      <ChildHeader text="PART LIST"></ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search Part" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IPart, index: number) => (
              <React.Fragment key={index}>
                <TableRow>
                  <TableCell align="center" width={10}>
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.part_number}</TableCell>
                  <TableCell align="center">{item?.part_name}</TableCell>
                  <TableCell align="center">
                    {item?.customer_id &&
                      typeof item?.customer_id !== 'string' &&
                      item.customer_id.customer_name}
                  </TableCell>
                  <TableCell align="center">
                    {item?.supplier_id &&
                      typeof item?.supplier_id !== 'string' &&
                      item.supplier_id.supplier_name}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(item?.createdAt || '')}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <ViewIconButton
                        tooltipTitle="VIEW"
                        onClick={() => {
                          navigate(
                            `/supervisor/ppapTiles/${item?._id}?part_name=${item.part_name}&part_number=${item.part_number}`
                          );
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
    </div>
  );
};

export default SupervisorViewPpap;
