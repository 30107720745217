import React from 'react';
import formatDate from '../../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
}
const TFoot = ({ moduleData }: IProps) => {
  return (
    <tfoot>
      <tr style={{ fontWeight: 'bold' }} data-height="35">
        <td colSpan={2} align="center" rowSpan={2}>
          REVISION NO.
        </td>
        <td colSpan={2} align="center" rowSpan={2}>
          REVISION DATE
        </td>
        <td colSpan={6} align="center" rowSpan={2}>
          CHANGE DESCRIPTION
        </td>
        <td colSpan={2} align="center" rowSpan={2}>
          REVISED BY
        </td>
        <td colSpan={3} align="center" rowSpan={2}>
          APPROVED BY
        </td>
      </tr>
      <tr style={{ height: '60px' }}></tr>
      {moduleData?.revisionData?.map((item: any, index: number) => {
        return (
          <React.Fragment key={index}>
            <tr>
              <td colSpan={2} align="center">
                {item?.rev_no}
              </td>
              <td colSpan={2} align="center">
                {formatDate(item?.rev_date)}
              </td>
              <td colSpan={6} align="center">
                {item?.summary}
              </td>
              <td colSpan={2} align="center">
                {item?.revised_by}
              </td>
              <td colSpan={3} align="center">
                {item?.approved_by}
              </td>
            </tr>
          </React.Fragment>
        );
      })}

      {/* <tr>
        <th colSpan={7} align="left">
          Prepared By:{' '}
        </th>
        <th colSpan={7} align="left">
          Approved By:
        </th>
      </tr> */}
    </tfoot>
  );
};

export default TFoot;
