import React from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { TableCustom } from '../../../components/common';
import {
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import { useAuth } from '../../../services/auth/AuthProvider';

interface IProps {
  deleteDocument: (id: string) => void;
  tableData: {
    [key: string]: string;
  }[];
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'REPORT',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'MESSAGE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'VIEW',
      colspan: 1,
      rowspan: 1,
    },
  ],
];
const NotificationTable = ({ deleteDocument, tableData }: IProps) => {
  const navigate = useNavigate();
  const { state } = useAuth();
  const { user } = state;
  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                <TableCell align="center">{document.title}</TableCell>
                <TableCell align="center">{document.body}</TableCell>
                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() => navigate(document.link)}
                  />
                  <DeleteIconButton
                    tooltipTitle="delete"
                    onClick={() => deleteDocument(document._id)}
                  />
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </TableCustom>
    </React.Fragment>
  );
};

export default NotificationTable;
