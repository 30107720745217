import React, { useRef, useState } from 'react';
import { TableBody, TableCell, TableRow } from '@mui/material';
import { TableCustom } from '../../../../../components/common';
import formatDate from '../../../../../components/common/formatDate';
import {
  CustomIconButton,
  DeleteIconButton,
  EditIconButton,
  ViewIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { useNavigate } from 'react-router-dom';
import CustomUploadButton from '../../../../../components/common/button/CustomUploadButton';
import {
  AIButton,
  UpdateButton,
} from '../../../../../components/common/button/CustomButtons';
import Add from '@mui/icons-material/Add';
import ModalCustom from '../../../../../components/common/Modal';
import RIPartList from '../../../../../components/ui/RIPartList';
import axios from 'axios';
import useConfirm from '../../../../../components/common/useConfirm';
import Aimodal from '../Aimodal';

interface IProps {
  deleteDocument: (id: string) => void;
  uploadImage: (id: string) => void;
  setImages: React.Dispatch<React.SetStateAction<null>>;
  setRender: React.Dispatch<React.SetStateAction<boolean>>;
  tableData: {
    [key: string | number]: any;
  }[];
}

const Header = [
  [
    {
      text: '#',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Fox Pro No. / Ref. Drg. No.',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ERP No. / Drawing No.',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'Description',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'INSPECTION STANDARD DATE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'CHOOSE IMAGE',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'CHOOSE RI PART',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'AI ANALYSIS',
      colspan: 1,
      rowspan: 1,
    },
    {
      text: 'ACTION',
      colspan: 1,
      rowspan: 1,
    },
  ],
];

const InspectionStandardList = ({
  uploadImage,
  deleteDocument,
  tableData,
  setImages,
  setRender,
}: IProps) => {
  const navigate = useNavigate();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const inspectionIdRef = useRef(null);
  const [riPartIds, setRiPartIds] = useState<string[]>([]);
  const [partId, setPartId] = useState<string | null>(null);
  const [DialogConfirmAi, confirmSubmitAi] = useConfirm(
    'AI ✨',
    'Do you want to confirm using AI for analysis?'
  );
  const [aiModal, setAiModal] = useState(false);

  const handleUpdatePart = (id: string) => {
    const riIndex = riPartIds.indexOf(id);
    if (riIndex === -1) {
      riPartIds.push(id);
    } else {
      riPartIds.splice(riIndex, 1);
    }
    if (inspectionIdRef && riPartIds.length) {
      axios
        .patch(
          `/api/inspectionStandard/riId/${inspectionIdRef.current}`,
          riPartIds
        )
        .then((res) => {
          if (res.data) {
            alert('updated successfull');
            setRender((prev) => !prev);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  return (
    <React.Fragment>
      <TableCustom tableHeaderObj={Header}>
        <TableBody>
          {tableData.map(
            (document: { [key: string]: any }, documentIndex: number) => (
              <TableRow>
                <TableCell align="center">{documentIndex + 1}</TableCell>
                <TableCell align="center">{document.ref_drg_no}</TableCell>
                <TableCell align="center">{document.drawing_no}</TableCell>
                <TableCell align="center">{document.description}</TableCell>
                <TableCell align="center">
                  {formatDate(document.insp_standard_date)}
                </TableCell>
                <TableCell align="center">
                  <CustomUploadButton
                    lable={'UPLOAD DRAWING'}
                    accept="image/png,image/jpeg,image/jpg,application/pdf"
                    onChange={(e) => setImages(e.target.files)}
                  />
                </TableCell>
                <TableCell align="center">
                  <CustomIconButton
                    tooltipTitle="view"
                    onClick={() => {
                      inspectionIdRef.current = document._id;
                      setRiPartIds(document.ri_part_ids);
                      setIsOpenPopup(true);
                    }}
                  >
                    <Add />
                  </CustomIconButton>
                </TableCell>

                <TableCell align="center">
                  <AIButton
                    label="AI"
                    onClick={async () => {
                      const ans = await confirmSubmitAi();
                      if (!ans) return;
                      setPartId(document?._id!);
                      setAiModal(true);
                    }}
                  />
                </TableCell>

                <TableCell align="center">
                  <ViewIconButton
                    tooltipTitle="view"
                    onClick={() =>
                      navigate(
                        `/common/inspectionStandard/${document._id}?isView=true`
                      )
                    }
                  />
                  <EditIconButton
                    tooltipTitle="edit"
                    onClick={() =>
                      navigate(
                        `/common/inspectionStandard/${document._id}?isView=false`
                      )
                    }
                  />
                  <DeleteIconButton
                    tooltipTitle="delete"
                    onClick={() => deleteDocument(document._id)}
                  />
                  <UpdateButton
                    label="update"
                    onClick={() => uploadImage(document._id)}
                  />
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </TableCustom>
      <ModalCustom
        title={'AI ANALYSIS'}
        dialogTitleCustomBackground={'#f8f9fa'}
        dialogTitleCustomColor={'#343a40'}
        isRequireCloseButton={false}
        openModal={aiModal}
        closeModal={() => {
          setAiModal(false);
        }}
      >
        <Aimodal partId={partId} setAiModal={setAiModal} />
      </ModalCustom>
      <ModalCustom
        title="RI PART LIST"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <RIPartList type="choose" {...{ handleUpdatePart, riPartIds }} />
      </ModalCustom>
      <DialogConfirmAi />
    </React.Fragment>
  );
};

export default InspectionStandardList;
