import { TableCell } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

const NpdPartCountTableCell = ({
  projectId,
}: {
  projectId: string | undefined;
}) => {
  const [data, setData] = useState<any>({});
  useEffect(() => {
    if (projectId) {
      axios
        .get(
          `/api/masterListOfProjectRoute/count/npdPartbyProject/${projectId}`,
        )
        .then((res) => {
          if (res.data) {
            setData(res.data);
          }
        });
    }
  }, [projectId]);
  return (
    <>
      <TableCell align="center">
        <span
          style={{
            background: data.countNpdPart === 0 ? '#f03e3e' : '#37b24d',
            padding: '10px 14px',
            borderRadius: '50%',
            color: 'white',
          }}
        >
          {data.countNpdPart}
        </span>
      </TableCell>
    </>
  );
};

export default NpdPartCountTableCell;
