import { TableCompHead } from '../../../../components/ui';
interface IProps {
  moduleData: any;
  isView: boolean;
}

const THead = () => {
  return (
    <thead
      style={{
        position: 'sticky',
        width: '100%',
        top: '-.5rem',
        border: '1px solid black',
        zIndex: '1',
        backgroundColor: 'rgb(79, 129, 189)',
        // background: '#fff',
        color: '#fff',
      }}
    >
      <TableCompHead
        colSpan={22}
        rowSpan={3}
        labels={['Doc No', 'Rev', 'Eff. Date']}
        values={['FRM-CMT-02', '00', '01-04-2023']}
        heading="4M CHANGE  TRACKING   SHEET"
      />
      <tr style={{ border: '1px solid black' }}>
        <th rowSpan={2} style={{ minWidth: '100px' }}>
          SR NO.
        </th>
        <th rowSpan={2} style={{ minWidth: '150px' }}>
          TYPE OF CHANGE
        </th>
        <th rowSpan={2} style={{ minWidth: '150px' }}>
          DATE
        </th>
        <th rowSpan={1} colSpan={3} style={{ minWidth: '150px' }}>
          Breakdown
        </th>
        <th rowSpan={2} style={{ minWidth: '250px' }}>
          MACHINE NO.
        </th>
        <th rowSpan={2}>PART NAME</th>
        <th rowSpan={2}>OPERATION</th>
        <th colSpan={3}>NATURE OF CHANGE</th>
        <th colSpan={2}>MAN</th>
        <th rowSpan={2}>DETAIL OF CHANGE</th>
        <th rowSpan={2}>SUSPECTED QTY</th>
        <th rowSpan={2}>ACTION TAKEN</th>
        <th rowSpan={2}>OK</th>
        <th rowSpan={2}>NG</th>
        <th rowSpan={2} style={{ minWidth: '100px' }}>
          REMARKS STATUS C / NC
        </th>
        <th rowSpan={2} style={{ minWidth: '100px' }}>
          PROCEDURE
        </th>
        <th rowSpan={2}>ACTION</th>
      </tr>
      <tr
      // style={{ textTransform: 'uppercase' }}
      >
        {[
          'From',
          'To',
          'Hours',
          'PLANNED',
          'UNPLANNED',
          'ABNORMALITY',
          'CURRENT LEVEL',
          'NEW LEVEL',
        ].map((head) => (
          <th style={{ textTransform: 'uppercase', minWidth: '120px' }}>
            {head}
          </th>
        ))}
      </tr>
      <tr></tr>
    </thead>
  );
};

export default THead;
