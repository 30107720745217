// import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'Sr No.',
  'Date',
  'Abnormal Situation',
  'Part Name',
  'Part Number',
  'Process  Name',
  'Type of Change',
  'Lot Qty',
  'Ok Qty (after insp.)',
  'Rej.Qty',
  'Parameter',
  'Before',
  'After',
  'Insp. by',
  'Remarks',
];

function calcColSpan(heading: string) {
  if (heading === 'Before' || heading === 'After') return 5;
  if (heading === 'Remarks') return 2;
  return 1;
}

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={24}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="Abnormal Situation check sheet"
      />
      <tr>
        <th colSpan={2} align="left">
          Date :{' '}
        </th>
        <th colSpan={22} align="left">
          {isView ? (
            formatDate(moduleData.dailyRejMon_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.dailyRejMon_date)}
              onChange={(date) => {
                moduleData.dailyRejMon_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th
            key={heading + index}
            colSpan={calcColSpan(heading)}
            rowSpan={heading === 'Before' || heading === 'After' ? 1 : 2}
          >
            {heading}
          </th>
        ))}
      </tr>
      <tr>
        {[1, 2, 3, 4, 5].map((heading, index) => (
          <th key={heading + index + 'before'}>{heading}</th>
        ))}
        {[1, 2, 3, 4, 5].map((heading, index) => (
          <th key={heading + index + 'after'}>{heading}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
