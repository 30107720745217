import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  apiData: any;
  average: number;
  target: number;
}
const BarChart = ({ apiData, average, target }: IProps) => {
  const options = {
    plugins: {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'MONTHLY PPM AVERAGE AND TARGET',
      },
    },
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: '',
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          //stepSize: 5,
        },
        title: {
          display: true,
          text: '',
        },
      },
    },
  };

  const backgroundColors = ['#fab005', '#40c057'];
  const datasets = [
    {
      label: 'Average',
      data: [average, target],
      backgroundColor: backgroundColors,
      borderWidth: 1,
      barThickness: 50,
    },
    {
      label: 'Target',
      data: [],
      backgroundColor: backgroundColors[1],
      borderWidth: 1,
      barThickness: 50,
    },
  ];
  const data = {
    labels: ['Average', 'Target'],
    datasets: datasets,
  };
  return <Bar options={options} data={data} />;
};

export default BarChart;
