import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../components/ui';
import { createConstant } from '../../constant';
import { useQuery } from '../../../../../hooks/UseQuery';

const style1 = {
  backgroundColor: '#4F81BD',
  color: '#fff',
  minWidth: '150px',
};
const style2 = {
  backgroundColor: '#00B050',
  color: '#fff',
};
const stickStylesRow = {
  position: 'sticky',
  width: '100%',
  top: '-.5rem',
  border: '1px solid black',
  zIndex: '2',
  height: '50px',
} as const;

const stickStylesColumn = {
  position: 'sticky',
  border: '1px solid black',
  zIndex: '1',
  height: '50px',
  backgroundColor: '#4F81BD',
  color: '#fff',
} as const;
interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const yearRange = useQuery('yearRange');
  const date = new Date(moduleData?.date_range)
    ?.getFullYear()
    .toString()
    .slice(-2);
  return (
    <thead
      style={{
        position: 'sticky',
        width: '100%',
        top: '-.5rem',
        border: '1px solid black',
        zIndex: '1',
        backgroundColor: 'rgb(79, 129, 189)',
        color: '#fff',
      }}
    >
      <TableCompHead
        colSpan={15 + createConstant.extracolSpan}
        rowSpan={3}
        labels={['DOC NO-', 'REV. NO -', 'REV. DATE -']}
        values={['M/XLV/SAP/017', '', '']}
        heading={`${createConstant.theadHeader} (FY : ${yearRange})`}
      />

      <tr style={{ ...stickStylesRow }}>
        <th
          style={{
            ...stickStylesColumn,
            left: 0,
            width: '50px',
            minWidth: '50px',
          }}
        >
          S. No.
        </th>
        <th
          style={{
            ...stickStylesColumn,
            left: 50,
            width: '150px',
            minWidth: '150px',
          }}
        >
          SUPPLIER NAME
        </th>
        <th
          style={{
            ...stickStylesColumn,
            left: 200,
            width: '150px',
            minWidth: '150px',
          }}
        >
          SUPPLIER CODE
        </th>
        <th
          style={{
            ...stickStylesColumn,
            left: 350,
            width: '150px',
            minWidth: '150px',
          }}
        >
          LOCATION
        </th>
        <th
          style={{
            ...stickStylesColumn,
            left: 500,
            width: '150px',
            minWidth: '150px',
          }}
        >
          SUPPLIER COMMODITY
        </th>
        {[
          'QMS STATUS (ISO 9001:2015)',
          'QMS STATUS (ISO 14001:2015)',
          'STATUS',
        ].map((el, index) => (
          <th
            key={`${index}_head`}
            align="center"
            style={{
              textAlign: 'center',
              ...style1,
              minWidth: `${style1.minWidth}`,
            }}
          >
            {el}
          </th>
        ))}
        {[
          'APR',
          'MAY',
          'JUN',
          'JUL',
          'AUG',
          'SEP',
          'OCT',
          'NOV',
          'DEC',
          'JAN',
          'FEB',
          'MAR',
        ].map((el, index) => (
          <th
            key={`${index}_headcol`}
            align="center"
            style={{ textAlign: 'center', ...style2 }}
          >
            {el}-{index > 8 ? +date + 1 : date}
          </th>
        ))}
        <th style={{ textAlign: 'center', ...style1 }}>Remarks</th>
        <th style={{ textAlign: 'center', ...style1 }}></th>
      </tr>
    </thead>
  );
};

export default THead;
