import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import GridDatePicker from '../../components/common/inputs/GridDatePicker';
import { apiConstant } from './constant';
import moment from 'moment';
import AutocompleteGridmui from '../../components/common/autocomplete/AutocompleteGridmui';

const initialData = {
  targets: [0, 0, 0, 0, 0, 0, 0],
  list: [
    {
      item: 'Customer Complaints',
      uom: 'Nos',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Customer PPM',
      uom: 'PPM',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'COPQ (To Total Sales)',
      uom: '% of sales',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Safety incidents (near miss)',
      uom: 'qty',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'On Time Delivery',
      uom: '%',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'ITR',
      uom: 'Nos.',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Material to sales (on purchase + job work)',
      uom: '%',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Manpower/ Sale % (including piece rate contractual)',
      uom: '%',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Power & Fuel Cost/ Sale',
      uom: '%',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Productivity: Units/Man-hr',
      uom: 'Nos',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },

    {
      item: 'CI Saving',
      uom: '% of sales',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'OEE (Total Plant)',
      uom: '%',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'QC Circles formed',
      uom: 'Nos.',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Premium Freight',
      uom: '% of sales',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'In-House PPM',
      uom: 'PPM',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'No. of accidents (pm)',
      uom: 'Nos',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: '5S score',
      uom: 'Score %',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Kaizens/ person/ month',
      uom: 'Nos',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'ERP vs physical material variance',
      uom: '%',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Mean Time Between Failures (MTBF)',
      uom: '%',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
    {
      item: 'Mean Time To Repair (MTTR)',
      uom: '%',
      target: 0,
      april: 0,
      may: 0,
      june: 0,
      july: 0,
      august: 0,
      september: 0,
      october: 0,
      november: 0,
      december: 0,
      january: 0,
      february: 0,
      march: 0,
      remarks: '',
    },
  ],
};

const ModalInput = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [moduleData, setMoudleData] = useState<any>(initialData);
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update KRA' : 'Create KRA'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/${apiConstant.getPlanByid}/${id}`)
        .then((res) => setMoudleData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setMoudleData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/${apiConstant.getPlanByid}/${id}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId('');
        })
        .catch((err) => console.log(err));
    } else {
      console.log('hhhhhhhhhhhhhhhhh', moduleData);
      await axios
        .post(`/api/${apiConstant.createPlanByid}`, moduleData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId('');
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };
  //console.log('ggggggggggggggggggggggggg', tableData);
  return (
    <>
      <GridInputs
        id={'title'}
        name={'title'}
        html_for={'title'}
        label_name={'Title'}
        input_type={'text'}
        focusType={'onblur'}
        value={moduleData?.title || ''}
        onChange={handleData}
      />
      <GridDatePicker
        label_name="Date"
        html_for={'date_range'}
        views={['year']}
        value={moduleData?.date_range || null}
        onChange={(date) => {
          moduleData.date_range = moment(date._d).add(10, 'hours');
          setRender((prev) => !prev);
        }}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default ModalInput;
