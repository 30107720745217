import { useState } from 'react';
import { TableInput } from '../../../../components/common';

import CustomSelect from '../../../../components/common/SelectField';
import { IconButton, MenuItem, Tooltip } from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import { useQuery } from '../../../../hooks/UseQuery';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import moment from 'moment';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
}
const TBody = ({ moduleData, tabValue, isView }: IProps) => {
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const [render, setRender] = useState(false);

  return (
    <tbody>
      <tr>
        <td>1</td>
        <td>PART NAME/PART NUMBER</td>
        <td style={{ height: '55px' }}>{partNumber}</td>
      </tr>
      <tr>
        <td>2</td>
        <td>LENGTH :-</td>
        <td>
          <TableInput
            isView={isView}
            name="length"
            type="text"
            value={moduleData.length}
            onChange={(e) => {
              moduleData.length = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td>3</td>
        <td>I.D</td>
        <td>
          <TableInput
            isView={isView}
            name="id"
            type="text"
            value={moduleData.id}
            onChange={(e) => {
              moduleData.id = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td>4</td>
        <td>FLUSHING M/C</td>
        <td>
          <TableInput
            isView={isView}
            name="flushing_mc"
            type="text"
            value={moduleData.flushing_mc}
            onChange={(e) => {
              moduleData.flushing_mc = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td>5</td>
        <td>FLUSHING TIME</td>
        <td>
          <TableInput
            isView={isView}
            name="flushing_time"
            type="text"
            value={moduleData.flushing_time}
            onChange={(e) => {
              moduleData.flushing_time = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td>6</td>
        <td>FILTER PAPER TYPE</td>
        <td>
          <TableInput
            isView={isView}
            name="filter_paper_type"
            type="text"
            value={moduleData.filter_paper_type}
            onChange={(e) => {
              moduleData.filter_paper_type = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td>7</td>
        <td>SOLVENT</td>
        <td>
          <TableInput
            isView={isView}
            name="solvent"
            type="text"
            value={moduleData.solvent}
            onChange={(e) => {
              moduleData.solvent = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td>8</td>
        <td>QTY. OF SOLVENT </td>
        <td>
          <TableInput
            isView={isView}
            name="qty_of_solvent"
            type="text"
            value={moduleData.qty_of_solvent}
            onChange={(e) => {
              moduleData.qty_of_solvent = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td>9</td>
        <td>TARE WEIGHT OF FILTER PAPER</td>
        <td>
          <TableInput
            isView={isView}
            name="tw_filter_paper"
            type="text"
            value={moduleData.tw_filter_paper}
            onChange={(e) => {
              moduleData.tw_filter_paper = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td>10</td>
        <td>GROSS WEIGHT OF FILTER PAPER</td>
        <td>
          <TableInput
            isView={isView}
            name="gw_filter_paper"
            type="text"
            value={moduleData.gw_filter_paper}
            onChange={(e) => {
              moduleData.gw_filter_paper = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td>11</td>
        <td>WEIGHT OF CONTAMINATS</td>
        <td>
          <TableInput
            isView={isView}
            name="weight_contaminats"
            type="text"
            value={moduleData.weight_contaminats}
            onChange={(e) => {
              moduleData.weight_contaminats = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td>12</td>
        <td>SPECIFICATION</td>
        <td>
          <TableInput
            isView={isView}
            name="specificaton"
            type="text"
            value={moduleData.specificaton}
            onChange={(e) => {
              moduleData.specificaton = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td>13</td>
        <td>RESULT</td>
        <td>
          <TableInput
            isView={isView}
            name="result"
            type="text"
            value={moduleData.result}
            onChange={(e) => {
              moduleData.result = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
    </tbody>
  );
};

export default TBody;
