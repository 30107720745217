import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import { IModule } from '../../interface';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  isView: boolean;
  moduleData: IModule;
  setModuleData: any;
}
const TController = ({ moduleData, setModuleData, isView }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            isView,
            moduleData,
          }}
        />
        <TBody
          {...{
            isView,
            moduleData,
          }}
        />
        <tr style={{ height: '40px' }}>
          <td colSpan={9}></td>
        </tr>
        <TFoot
          {...{
            isView,
            moduleData,
            setModuleData,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
