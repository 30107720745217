import { useState } from 'react';
//mport ObservationInput from './ObservationInput';
import CustomSelect from '../../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../../components/common';

interface IProps {
  apiData: any;
  totalProducedQty: number;
}
const TBody = ({ apiData, totalProducedQty }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <tbody>
      {apiData.map((data: any, index: number) => {
        const { defectName, frequency, cumulativeFrequency, percentage } = data;
        const ppm = ((frequency * 1000000) / totalProducedQty).toFixed(0);
        return (
          <tr>
            <td>{index + 1}</td>
            <td>{defectName}</td>
            <td>{frequency}</td>
            <td>{ppm}</td>
            <td>{cumulativeFrequency}</td>
            <td>{percentage}</td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default TBody;
