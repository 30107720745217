import { TbAspectRatio } from 'react-icons/tb';
import { MdOutlineCalendarMonth } from 'react-icons/md';
import { BsReverseListColumnsReverse } from 'react-icons/bs';
import { RenderTiles } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';

const tiles = [
  {
    sNo: 1,
    link: '/common/instrumentMsaPlanList',
    isLink: true,
    icon: <MdOutlineCalendarMonth size="5rem" color="#343a40" />,
    p: 'INSTRUMENT MSA',
    span: 'PLAN',
    isIcon: true,
  },

  {
    sNo: 2,
    link: '/common/instrumentMsaPlanChecklist',
    isLink: true,
    icon: <BsReverseListColumnsReverse size="5rem" color="#087f5b" />,
    p: 'INSTRUMENT MSA',
    span: 'STATUS',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/common/instrumentMsaMasterList',
    isLink: true,
    icon: <TbAspectRatio size="5rem" color="#343a40" />,
    p: 'Master list of',
    span: 'Instrument MSA',
    isIcon: true,
  },
];

const Tiles = () => {
  return (
    <>
      <ChildHeader text="INSTRUMENT MSA PLAN" />
      <RenderTiles
        tilesObj={tiles}
        height="100vh"
        justify="start"
        columns="0"
      />
    </>
  );
};

export default Tiles;
