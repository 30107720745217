import { createConstant } from '../../constant';

interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ preparedBy, approvedBy }: Ifoot) => {
  return (
    <tfoot>
      <tr>
        <th
          style={{ textAlign: 'left' }}
          colSpan={13 + createConstant.extracolSpan}
        >
          Remarks : &emsp; child parts material test will be conducted as per
          schedule (Frequency Yearly Plan) from out side NABL approved lab
          <br /> &emsp; &emsp; &emsp; &emsp; : For rubber components , supplier
          report will be collected for record , in case of 3rd party material
          test report , same will be done on demand of customer.
        </th>
      </tr>

      <tr>
        <th colSpan={createConstant.extracolSpan} rowSpan={2}>
          LEGENDS
        </th>
        <th rowSpan={2}>PLAN</th>
        <th rowSpan={1} style={{ background: '#ffd43b' }}>
          P
        </th>
        <th colSpan={2} rowSpan={2}>
          DONE
        </th>
        <th rowSpan={1} style={{ background: '#ffd43b' }}>
          P
        </th>
        <th rowSpan={2}></th>
        <th colSpan={2} rowSpan={2}>
          RE-PLAN
        </th>
        <th rowSpan={1} style={{ background: '#00B0F0' }}>
          P
        </th>
        <th rowSpan={2}></th>
        <th colSpan={2} rowSpan={2}>
          RE-PLAN DONE
        </th>
        <th rowSpan={1} style={{ background: '#00B0F0' }}>
          P
        </th>
      </tr>
      <tr>
        <th rowSpan={1}></th>
        <th rowSpan={1} style={{ background: '#00B050' }}>
          A
        </th>
        <th colSpan={2} rowSpan={1}></th>
        <th rowSpan={1} style={{ background: '#00B050' }}>
          A
        </th>
      </tr>

      <tr>
        <th colSpan={(13 + createConstant.extracolSpan) / 2} align="left">
          Prepared By: {preparedBy}
        </th>
        <th colSpan={(13 + createConstant.extracolSpan) / 2} align="left">
          Approved By: {approvedBy}
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
