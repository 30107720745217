import PIS from '../img/pis.jpg';
import cp1 from '../img/cp1.png';
import cp2 from '../img/cp2.png';
import cp3 from '../img/cp3.png';
import cp4 from '../img/cp4.png';
interface SpclProp {
  [key: string]: JSX.Element;
}
export const spclChar: SpclProp = {
  CC: <img src={cp1} alt="critical" height={'auto'} width={30} />,
  MC: (
    <img
      src={cp2}
      alt="significant characteristics"
      height={'auto'}
      width={30}
    />
  ),
  FF: <img src={cp3} alt="pis characteristics" height={'auto'} width={30} />,
  EP: <img src={cp4} alt="pis characteristics" height={'auto'} width={30} />,
};
