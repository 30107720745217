import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';

interface IProps {
  isView: boolean;
  moduleData: any;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={9}
        rowSpan={2}
        labels={['Insp. Std. Rev No', 'Date']}
        values={['00', '15.06.2023']}
        heading="INSPECTION STANDARDS"
      />
      <tr>
        <th colSpan={9} align="left">
          Date:
          {isView ? (
            formatDate(moduleData.moduleDate)
          ) : (
            <DatePickerMui
              value={moment(moduleData.moduleDate)}
              onChange={(date) => {
                moduleData.moduleDate = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          ERP PART NO./Drawing no.
        </th>
        <th colSpan={2} align="left">
          {moduleData?.pipe_id?.part_number || ''}
        </th>
        <th colSpan={2} align="left">
          Rev no:
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="revision_no"
            type="number"
            value={moduleData.revision_no}
            onChange={(e) => {
              moduleData.revision_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={3} align="left">
          Description:
        </th>
        <th colSpan={2} align="left">
          <TableInput
            name="desc"
            type="text"
            value={moduleData.desc}
            onChange={(e) => {
              moduleData.desc = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={2} align="left">
          Fox Pro Part no./ Ref. Drg. no.:
        </th>
        <th colSpan={2} align="left">
          {moduleData?.pipe_id?.part_name}
        </th>
      </tr>
      <tr>
        {[
          'S.No.',
          'Parameter',
          'Cof C',
          'Specification',
          'InstrumentType',
          'Frequency',
          'FormatNo.',
          'Responsibility',
          'Action',
        ].map((heading) => (
          <th key={heading} style={{ textTransform: 'uppercase' }}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
