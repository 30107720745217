import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  {
    h: 'Histroy / change authorization (As applicable (Optional)',
    bgc: 'yellow',
  },
  {
    h: '1. Process Item (System/Sub system / part element or name of the process).',
    bgc: 'white',
  },
  {
    h: '2. Process Step (Station No. and name of the focus element)',
    bgc: '#89b6c7',
  },
  { h: '3. Process Work Element (4M type)', bgc: '#a980c4' },
  {
    h: '1. Function of the Process item (Function of system/sub system, part element or process)',
    bgc: 'white',
  },
  {
    h: '2. Function of the process step and product characteristics (quantitative value is optional)',
    bgc: '#89b6c7',
  },
  {
    h: '3. Function of the process work element and process characteristics',
    bgc: '#a980c4',
  },
  { h: '1. Failure effects (FE)', bgc: 'white' },
  { h: 'Severity(s) of FE', bgc: 'white' },
  { h: '2. Failure Mode (FM) of the process step', bgc: '#89b6c7' },
  { h: 'Failure Causes (FC) of the Work Element', bgc: '#a980c4' },
  { h: 'Current Prevention Control (PC) of FC', bgc: '#98b388' },
  { h: 'Occurrence (O) of FC', bgc: '#98b388' },
  { h: 'Current Detection Controls (DC) of FC or FM', bgc: '#98b388' },
  { h: 'Detection (D) of FC/FM', bgc: '#98b388' },
  { h: 'FMEA AP (Action Priority)', bgc: '#98b388' },
  { h: 'Special Characteristics', bgc: '#89b6c7' },
  { h: 'Filter Code (Optional)', bgc: 'white' },
  { h: 'Preventive Actions', bgc: 'white' },
  { h: 'Detective Action', bgc: 'white' },
  { h: "Responsible Person's Name", bgc: 'white' },
  { h: 'Target Completion date', bgc: 'white' },
  { h: 'Status', bgc: 'white' },
  { h: 'Action Taken (with poniter to evidence)', bgc: 'white' },
  { h: 'Completion date', bgc: 'white' },
  { h: 'Severity (s)', bgc: 'white' },
  { h: 'Occurrence (O)', bgc: 'white' },
  { h: 'Detection (D)', bgc: 'white' },
  { h: 'Special Product Characteristic', bgc: '#89b6c7' },
  { h: 'PFMEA AP', bgc: 'white' },
  { h: 'Action', bgc: 'white' },
];

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={32}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="PROCESS FAILURE MODE AND EFFECT ANALYSIS (Process FMEA)"
      />
      <tr>
        <th colSpan={32} align="left">
          PLANNING & PREPARATION (STEP1)
        </th>
      </tr>
      <tr>
        <th colSpan={10} align="left">
          Company Name: Imperial Auto Industries
        </th>
        <th colSpan={10} align="left">
          Subject: Tube Parts
        </th>
        <th colSpan={12} align="left">
          PFMEA ID Number: PFMEA /TA/ 001
        </th>
      </tr>
      <tr>
        <th colSpan={10} align="left">
          Plant location_ Plant XLV (IMT Faridabad)
        </th>
        <th colSpan={10} align="left">
          PFMEA Start Date: 11-02-2020
        </th>
        <th colSpan={12} align="left">
          Process Responsibility: Amit/Manoj
        </th>
      </tr>
      <tr>
        <th colSpan={10} align="left">
          Customer Name: Tube Assembly
        </th>
        <th colSpan={10} align="left">
          PFMEA Revision Date_ 30-09-2022
        </th>
        <th colSpan={12} align="left">
          Confidentiality Level: Bussiness Use
        </th>
      </tr>
      <tr>
        <th colSpan={10} align="left">
          Model/Year/Platform:
        </th>
        <th colSpan={22} align="left">
          Cross Functional Team: Mr. Tejveer/ Mr. Sunil/ Mr. Sandeep/Mr.
          Manoj/Mr. Bharat/Mr. /Mr. Randhir Singha/Mr.Deepak/Mr. Prashant/Mr.
          Sanjay/Mr. Ravinder/Mr. Vikram/Mr. Naresh/Mr. Parmesh/Mr. Vishnu/Mr.
          Prakash Saini/Mr. Manish Varshnay/Mr. Pawan Kumar
        </th>
      </tr>
      <tr>
        <th rowSpan={3}>#ISSUE</th>
        <th>CONTINUOUS IMPROVEMENT</th>
        <th colSpan={3}>STRUCTURE ANALYSIS (STEP 2)</th>
        <th colSpan={3}>FUNCTION ANALYSIS (STEP3)</th>
        <th colSpan={4}>FAILURE ANALYSIS (STEP 4)</th>
        <th colSpan={5}>FAILURE ANALYSIS (STEP 4)</th>
        <th colSpan={2}></th>
        <th colSpan={14}>OPTIMINATION (STEP 6)</th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th
            key={heading.h + index}
            colSpan={1}
            style={{ minWidth: '150px', backgroundColor: heading.bgc }}
          >
            {heading.h}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
