import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
// import { IData } from '../../helpers/interface';
import { useEffect, useState } from 'react';
import axios from 'axios';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  setPartIdx: any;
  setIsOpenPopup: any;
}

const TController = ({
  moduleData,
  isView,
  handelDeleteRows,
  setPartIdx,
  setIsOpenPopup,
}: IProps) => {
  const [machineData, setMachineData] = useState([]);

  useEffect(() => {
    axios
      .get('/api/machineRoutes/')
      .then((res) => {
        setMachineData(res.data.results);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            isView,
            machineData,
            handelDeleteRows,
            setPartIdx,
            setIsOpenPopup,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
