import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { MenuItem } from '@mui/material';

const Create = ({
  id,
  setIsModal,
  setListId,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setListId: any;
}) => {
  const [data, setData] = useState<any>({});
  const [categoryData, setCategoryData] = useState([]);
  const [isSubmit, setIsSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer' : 'Create Customer'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  const [department, setDepartment] = useState([]);
  const [customerData, setCustomerData] = useState([]);

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/SSTMasterlist/${id}`)
        .then((res) => setData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  useEffect(() => {
    axios
      .get(`/api/department`)
      .then((res) => setDepartment(res.data.results))
      .catch((err) => console.log(err));

    axios
      .get('/api/SSTMasterlist/instrumentcategory')
      .then((res) => {
        if (res.data.result) {
          setCategoryData(res.data.result);
        }
      })
      .catch((err) => console.log(err));

    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        setCustomerData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  function handleData(e: React.ChangeEvent<HTMLInputElement>) {
    setData((prev: any) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/SSTMasterlist/${id}`, data)
        .then((res) => {
          setIsSubmit(false);
          setListId(null);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/SSTMasterlist', data)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setListId(null);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };
  console.log(data);
  return (
    <>
      <GridInputs
        id={'part_no'}
        name={'part_no'}
        html_for={'part_no'}
        label_name={'Part No'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.part_no || ''}
        onChange={handleData}
      />
      {/* <AutocompleteGridmui
        label={'Fixture Name'}
        id={'name'}
        option_name={'name'}
        value={data?.category_id || ''}
        arrayofObj={categoryData}
        onChange={(e, value) => {
          data.category_id = value;
          setRender((prev) => !prev);
        }}
        last_child={2}
      /> */}

      {/* <GridInputs
        id={'type_of_industry'}
        name={'type_of_industry'}
        html_for={'type_of_industry'}
        label_name={'Type Of Industry:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.type_of_industry || ''}
        onChange={handleData}
      /> */}
      {/* <GridInputs
        id={'id_no'}
        name={'id_no'}
        html_for={'id_no'}
        label_name={'UNIQUE ID NO.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.id_no || ''}
        onChange={handleData}
      /> */}
      {/* <AutocompleteGridmui
        label={'Department Name'}
        id="Departmtn"
        option_name={'department_name'}
        value={data?.department_id || ''}
        arrayofObj={department}
        onChange={(e, value) => {
          data.department_id = value;
          setRender((prev) => !prev);
        }}
      /> */}

      {/* <SelectGridMui
        name="frequency_cycle"
        value={data?.frequency_cycle || ''}
        lable={'Frequency:'}
        onChange={(e: any) => handleData(e)}
      >
        <MenuItem value="">
          <em>NONE</em>
        </MenuItem>
        <MenuItem value={'1 Month'}>1 Month</MenuItem>
        <MenuItem value={'2 Month'}>2 Month</MenuItem>
        <MenuItem value={'3 Month'}>3 Month</MenuItem>
        <MenuItem value={'6 Month'}>6 Month</MenuItem>
        <MenuItem value={'12 Month'}>12 Month</MenuItem>
      </SelectGridMui> */}

      <AutocompleteGridmui
        id={'customer_name'}
        label={'Customer Name'}
        option_name={'customer_name'}
        arrayofObj={customerData}
        value={data.customer_id || ''}
        onChange={(e, value) => {
          data.customer_id = value;
          setRender((prev) => !prev);
        }}
      />

      <GridInputs
        id={'type_of_plating'}
        name={'type_of_plating'}
        html_for={'type_of_plating'}
        label_name={'Type of plating'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.type_of_plating || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'plating_supplier_name'}
        name={'plating_supplier_name'}
        html_for={'plating_supplier_name'}
        label_name={'Plating Supplier Name'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.plating_supplier_name || ''}
        onChange={handleData}
      />
      <GridInputs
        id={'sst_req'}
        name={'sst_req'}
        html_for={'sst_req'}
        label_name={'SST Requirement'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.sst_req || ''}
        onChange={handleData}
      />

      {/* <GridInputs
        id={'function'}
        name={'function'}
        html_for={'function'}
        label_name={'Function:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.function || ''}
        onChange={handleData}
        last_child={2}
      /> */}

      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.remarks || ''}
        onChange={handleData}
        last_child={2}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
