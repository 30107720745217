import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody1 from './TBody1';
import TBody2 from './TBody2';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  phase?: any;
  title?: string;
  uploadImageFile: any;
  employee: any;
}

const TController = ({
  uploadImageFile,
  moduleData,
  isView,
  phase,
  title,
  employee,
}: IProps) => {
  const handleRemarks = (
    completeDate_p: any,
    completeDate_a: any,
    activity: any,
    rowIndex: number,
  ) => {
    if (!completeDate_p || !completeDate_a) return;
    const planDate = new Date(
      new Date(completeDate_p).setHours(0, 0, 0, 0),
    ).getTime();
    const actualDate = new Date(
      new Date(completeDate_a).setHours(0, 0, 0, 0),
    ).getTime();

    if (!moduleData[`${phase}_remarks`]) moduleData[`${phase}_remarks`] = [];
    if (planDate < actualDate) {
      let apqpIndex = moduleData[`${phase}_remarks`].findIndex(
        (item: any) => item.s_no === rowIndex,
      );
      moduleData[`${phase}`][rowIndex].remarks = 'DELAYED';
      if (apqpIndex != -1) {
        moduleData[`${phase}_remarks`][apqpIndex].activity = activity;
        return;
      }
      moduleData[`${phase}_remarks`].push({
        s_no: rowIndex,
        activity: activity,
      });
    } else {
      let apqpIndex = moduleData[`${phase}_remarks`].findIndex(
        (item: any) => item.s_no === rowIndex,
      );

      moduleData[`${phase}`][rowIndex].remarks = 'ON TRACK';
      if (apqpIndex != -1) {
        moduleData[`${phase}_remarks`].splice(apqpIndex, 1);
        return;
      }
    }
  };
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            isView,
            phase,
          }}
        />
        <TBody1
          {...{
            handleRemarks,
            uploadImageFile,
            title,
            moduleData,
            phase,
            isView,
          }}
        />
        <TBody2
          {...{
            employee,
            title,
            moduleData,
            phase,
            isView,
          }}
        />
        <TFoot
          {...{
            moduleData,
            phase,
            isView,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
