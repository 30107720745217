import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import lodash from 'lodash';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './components/create/TController';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import DatePickerMui from '../../../components/common/DatePickerMui';
import moment from 'moment';
import { Stack } from '@mui/material';
const Create = () => {
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const pfmea = useQuery('pfmea') === 'true' ? true : false;
  const [render, setRender] = useState(false);
  const [ppm, setPpm] = useState({});
  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?'
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?'
  );
  const [from, setFrom] = useState(null);
  const [to, setTo] = useState(null);
  const [moduleData, setModuleData] = useState<any>({ processes: [] });
  useEffect(() => {
    if (pfmea && from && to) {
      axios
        .get(
          `/api/pfmea/calculatePpm/fromProductionLog/${id}?from=${from}&to=${to}`
        )
        .then((res) => {
          if (res.data) {
            setPpm(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [pfmea, from, to]);
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/pfmea/${id}`)
        .then((res) => {
          if (res.data) {
            // console.log(res.data);

            setModuleData(res.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async () => {
    // console.log(moduleData);
    await axios
      .put(`/api/pfmea/${moduleData._id}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('document updated successfully');
        }
      })
      .catch((err) => console.log(err));
  };
  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };
  const [loader, setLoader] = useState<boolean>(false);

  const upload = async (e: any) => {
    setLoader(true);
    const formData = new FormData();
    formData.append(
      'file',
      e.target.files[0],
      'VDA FMEA_14.01.2023( IMT ).xlsx'
    );
    axios
      .post('/api/excelUpload/pfmea', formData)
      .then((res) => {
        // console.log(res.data.data);

        moduleData.processes = [...moduleData.processes, ...res.data.processes];
        setLoader(false);
        setRender((prev) => !prev);
        window.alert(res.data.msg);
      })
      .catch((err) => {
        setLoader(false);
        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else {
          window.alert(
            'Something went wrong. Please check if you are uploading the correct excel sheet or the excel sheet might be corrupted.'
          );
        }
      });
  };

  return (
    <Box>
      <Box>
        <ChildHeader text="CREATE LINE INSPECTION">
          {!isView && (
            <>
              <CustomUploadButton lable={'UPLOAD'} onChange={upload} />
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  moduleData.processes.push(lodash.cloneDeep({}));
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton>
              <SubmitButton label="Update" onClick={() => handleSumbit()} />
            </>
          )}
        </ChildHeader>
      </Box>
      {pfmea && (
        <Box m={1}>
          <Stack direction={'row'} gap={2}>
            <DatePickerMui
              label="From"
              onChange={(date) => {
                setFrom(date);
                setRender((prev) => !prev);
              }}
              value={moment(from || null)}
            />
            <DatePickerMui
              label="To"
              onChange={(date) => {
                setTo(date);
                setRender((prev) => !prev);
              }}
              value={moment(to || null)}
            />
          </Stack>
        </Box>
      )}
      <TController
        {...{
          moduleData,
          ppm,
          handelDeleteRows,
          isView,
        }}
      />
      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
    </Box>
  );
};

export default Create;
