import { Box } from '@mui/material';
import { RenderTable } from '../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  isView: boolean;
  setRowIdx: any;
  setIsOpenPopup: any;
}

const allmonth = [
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'january',
  'february',
  'march',
];

const TController = ({
  moduleData,
  isView,
  setRowIdx,
  setIsOpenPopup,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead {...{ moduleData, isView }} />
        <TBody
          {...{ moduleData, isView, allmonth, setRowIdx, setIsOpenPopup }}
        />
        <TFoot {...{ moduleData, isView }} />
      </RenderTable>
    </Box>
  );
};

export default TController;
