import axios from 'axios';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../../components/common/AutocompleteMuiCustom';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';
import { headFirst, headSecond, headThird } from '../../helpers/headData';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const [designationData, setDesignationtData] = useState([]);
  useEffect(() => {
    axios
      .get(`/api/department/department/departmentForAutoComplete`)
      .then((res) => {
        setDepartmentData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get(`/api/autocompleteRoute/getDesignation`)
      .then((res) => {
        setDesignationtData(res?.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <thead>
      <TableCompHead
        colSpan={6}
        rowSpan={2}
        labels={['Doc No', 'Issued On :']}
        values={['FM/XLV/TR026', '06.01.2022']}
        heading="JOB DESCRIPTION"
      />
      <tr>
        <th colSpan={3}>NAME</th>
        <th align="left">DATE:</th>
        <th colSpan={2} align="left">
          {isView ? (
            formatDate(moduleData.date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.date)}
              onChange={(date) => {
                moduleData.date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th align="left">Man Profile</th>
        <th align="left">Designation</th>
        <th align="left">
          <AutocompleteMuiCustom
            id="Designation"
            label=""
            option_name="designation"
            arrayofObj={designationData}
            value={moduleData?.designation_id || ''}
            onChange={(e: any, value: any) => {
              moduleData.designation_id = value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left">Qualification</th>
        <th align="left" colSpan={2}></th>
      </tr>
      <tr>
        <th align="left">Department</th>
        <th colSpan={2} align="left">
          <AutocompleteMuiCustom
            id="department"
            label=""
            option_name="department_name"
            arrayofObj={departmentData}
            value={moduleData?.department_id || ''}
            onChange={(e: any, value: any) => {
              moduleData.department_id = value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left">Experience</th>
        <th align="left" colSpan={2}></th>
      </tr>
      <tr>
        <th align="left">Level</th>
        <th colSpan={2} align="left"></th>
        <th align="left">Preferred age</th>
        <th align="left" colSpan={2}></th>
      </tr>
      <tr>
        <th colSpan={6}>Reporting to whom: </th>
      </tr>
      <tr style={{ height: '40px' }}>
        <th colSpan={6}></th>
      </tr>
    </thead>
  );
};

export default THead;
