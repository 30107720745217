import { Box } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { PersitedDatePicker } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { useQuery } from '../../hooks/UseQuery';
import { useAuth } from '../../services/auth/AuthProvider';

import TableList from './components/TableList';
const ApprovalList = () => {
  const { state } = useAuth();
  const date = useQuery('date');
  const [tableData, setTableData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  useEffect(() => {
    // fetch data here
    axios
      .get(`/api/pendingForApprovalRoute/monthly/${date}/${state.user?._id}`)
      .then((res) => {
        if (res.data) setTableData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render, date]);

  const updateStatus = async (id: string, documentIndex: number) => {
    let isConfirm = window.confirm('Are you sure you want to update status');
    if (isConfirm && tableData.length > 0) {
      axios
        .patch(`/api/pendingForApprovalRoute/updateStatus/${id}`, {
          status: tableData[documentIndex].status,
          model: tableData[documentIndex].model,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Status Change successfully');
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleChangeStatus = (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number,
  ) => {
    const values = [...tableData];
    values[documentIndex].status = e.target.value;
    setTableData(values);
  };
  return (
    <Box>
      <ChildHeader text="PENDING FOR APPROVAL"></ChildHeader>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box>
      <TableList
        {...{
          handleChangeStatus,
          updateStatus,
          tableData,
        }}
      />
    </Box>
  );
};

export default ApprovalList;
