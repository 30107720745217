import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import { IData } from './helpers/Interface';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [customerData, setCustomerData] = useState<IData>({
    item_name: null,
    id_no: null,
    location: null,
    planned_qty_in_location: null,
    actual_qty_in_location: null,
  });
  const [isSubmit, setIsSubmit] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Customer' : 'Create Customer'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/listOfFireExtinguisherRoute/${id}`)
        .then((res) => setCustomerData(res.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleCustomerData(e: React.ChangeEvent<HTMLInputElement>) {
    setCustomerData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/listOfFireExtinguisherRoute/${id}`, customerData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/listOfFireExtinguisherRoute', customerData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'item_name'}
        name={'item_name'}
        html_for={'item_name'}
        label_name={'Item Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={customerData?.item_name || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'id_no'}
        name={'id_no'}
        html_for={'id_no'}
        label_name={'ID No.:'}
        input_type={'text'}
        focusType={'onblur'}
        value={customerData?.id_no || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'location'}
        name={'location'}
        html_for={'location'}
        label_name={'Location:'}
        input_type={'text'}
        focusType={'onblur'}
        value={customerData?.location || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'planned_qty_in_location'}
        name={'planned_qty_in_location'}
        html_for={'planned_qty_in_location'}
        label_name={'Planned Qty in Location:'}
        input_type={'number'}
        focusType={'onblur'}
        value={customerData?.planned_qty_in_location || ''}
        onChange={handleCustomerData}
        last_child={2}
      />
      <GridInputs
        id={'actual_qty_in_location'}
        name={'actual_qty_in_location'}
        html_for={'actual_qty_in_location'}
        label_name={'Actual Qty in Location:'}
        input_type={'number'}
        focusType={'onblur'}
        value={customerData?.actual_qty_in_location || ''}
        onChange={handleCustomerData}
        last_child={2}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
