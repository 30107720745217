import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { createConstant } from '../../constant';
import TBodyRight from './TBodyRight';
import formatDate from '../../../../components/common/formatDate';
import { DeleteIconButton } from '../../../../components/common/button/CustomIconButton';
import CustomSelect from '../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { Stack } from '@mui/material';

interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView, allmonth }: IProps) => {
  const [render, setRender] = useState(false);
  const handelDeleteRows = async (index: number) => {
    const ans = window.confirm('Are you sure you want to delete this row');
    if (!ans) return;
    moduleData?.list.splice(index, 1);
    setRender((prev) => !prev);
  };
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <>
          <tr key={`${index}_table_row`}>
            <td rowSpan={2}>{index + 1}</td>

            {Array(createConstant.extracolSpan - 1)
              .fill(0)
              .map((_, pIdx) => (
                <td rowSpan={2}>{item?.[`a${pIdx + 1}`]}</td>
              ))}
            <td rowSpan={2}>
              <CustomSelect
                name="dropdown"
                value={item.a2 || ''}
                onChange={(e) => {
                  item.a2 = e.target.value;
                  setRender((prev) => !prev);
                }}
              >
                <MenuItem value={'A'}>A</MenuItem>
                <MenuItem value={'B'}>B</MenuItem>
                <MenuItem value={'AB'}>AB</MenuItem>
              </CustomSelect>
            </td>
            <td>P</td>
            {/* always constant ⬇️*/}
            <TBodyRight
              item={item}
              isView={isView}
              index={index}
              moduleData={moduleData}
            />
            {!isView && (
              <td rowSpan={2} style={{ minWidth: '50px' }}>
                <DeleteIconButton
                  tooltipTitle={'delete'}
                  onClick={() => handelDeleteRows(index)}
                />
              </td>
            )}
          </tr>
          <tr>
            <td>A</td>
            {allmonth.map((month: any, mIdx: number) => (
              <td width="80px" height="45px" style={{ padding: '0' }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-around"
                  style={{ height: '100%' }}
                >
                  {item[month].isTask && (
                    <div
                      style={{
                        justifyContent: 'center',
                        backgroundColor: '#37b24d',
                        color: 'white',
                        textTransform: 'capitalize',
                        fontSize: '1.6rem',
                        width: '50%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {item[month].isTask && 'A'}
                    </div>
                  )}
                  {item[month].shift && (
                    <div
                      style={{
                        justifyContent: 'center',
                        fontSize: '1.6rem',
                        padding: '10px',
                        color: '#e7f5ff',
                        backgroundColor: '#228be6',
                        width: '50%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      {item[month].shift}
                    </div>
                  )}
                </Stack>
              </td>
            ))}
          </tr>
          {/* ---x---- */}
        </>
      ))}
    </tbody>
  );
};

export default TBody;
