import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';
import { convToTime } from '../../../../../../utils/helpers';

interface IProps {
  defectsName: [];
}
const THead = ({ defectsName }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead style={{ background: '#9775fa', color: 'white' }}>
      <tr>
        {defectsName?.map((name: any) => (
          <th>{name}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
