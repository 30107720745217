import { useState } from 'react';
import styled, { keyframes } from 'styled-components';

interface MySelectProps {
  value: string;
  onChange: any;
  statusOptions: {
    value: string;
    label: string;
  }[];
}

interface StyledSelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  item: string;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const StyledSelect = styled.select<StyledSelectProps>`
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 500;
  outline: none;
  border: none;
  border-radius: 5px;
  padding: 5px;
  margin-left: 0.5rem;
  width: 14rem;
  text-align: center;
  text-transform: capitalize;
  background-color: ${({ item }) =>
    item === 'PENDING' ? '#FDD835' : '#57cc99'};
  animation: ${fadeIn} 0.5s ease;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
`;

export const SelectStatus = ({
  value,
  onChange,
  statusOptions,
}: MySelectProps) => {
  return (
    <StyledSelect
      name="isSubmitted"
      item={value}
      value={value}
      onChange={onChange}
    >
      {statusOptions.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </StyledSelect>
  );
};
