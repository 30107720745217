import { useState } from 'react';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
}
const TFoot = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tfoot>
      <tr>
        <th colSpan={7} align="left">
          4M Change If Any:
          <TableInput
            isView={isView}
            name="s11"
            type="text"
            value={moduleData.s11}
            onChange={(e) => {
              moduleData.s11 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={7} align="left">
          CUSTOMER SPECIFIC REQUIREMENT:
          <TableInput
            isView={isView}
            name="s12"
            type="text"
            value={moduleData.s12}
            onChange={(e) => {
              moduleData.s12 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={7} align="left">
          CUSTOMER SPECIFIC REQUIREMENT:
          <TableInput
            isView={isView}
            name="s13"
            type="text"
            value={moduleData.s13}
            onChange={(e) => {
              moduleData.s13 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          Auditee Sign:
          <TableInput
            isView={isView}
            name="s14"
            type="text"
            value={moduleData.s14}
            onChange={(e) => {
              moduleData.s14 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={3} align="left">
          Auditor Sign:
          <TableInput
            isView={isView}
            name="s15"
            type="text"
            value={moduleData.s15}
            onChange={(e) => {
              moduleData.s15 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
