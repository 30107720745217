import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../../components/common/button/CustomButtons';
import formatDate from '../../../../components/common/formatDate';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
import { RiFileExcel2Line } from 'react-icons/ri';
import moment from 'moment';
interface MyData {
  moduleData: any; // replace PdirType with the type of your `pdir` data
  isLoading: boolean;
  error?: string | null;
}
const Create = () => {
  const { partId, pdirId } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const { moduleData, isLoading, error }: MyData = useGetData(partId, pdirId);

  const onSubmit = async (isSubmitted: boolean) => {
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;
    let response: any;
    if (moduleData._id) {
      response = await axios
        .put(`/api/pdirFinal/${moduleData._id}`, moduleData)
        .catch((err) => console.log(err));
    } else {
      response = await axios
        .post(`/api/pdirFinal`, moduleData)
        .catch((err) => console.log(err));
    }
    if (response.data && state.user?.userType === 3 && isSubmitted) {
      const messageData = {
        category: 'PDIR',
        title: 'PDIR CREATED',
        user: 'admin',
        body: `PDIR of "${partName}" ${
          moduleData._id ? 'updated' : 'submitted'
        } by "${state.user?.name}" on ${formatDate(new Date())}`,
        link: `/admin/Pdirfinalcreate/${partId}/${response.data._id}?partNumber=${partNumber}&partName=${partName}&isView=true`,
      };
      axios
        .post(`/api/notification`, messageData)
        .then(() => {
          if (moduleData._id) {
            alert('updated successfully ');
            return;
          }
          alert('created successfully');
          navigate(-1);
        })
        .catch((err) => console.log(err));
      return;
    }
    if (moduleData._id && !isSubmitted) {
      alert('updated successfully ');
      return;
    }
    alert('created successfully');
    navigate(-1);
  };

  function excelDownload() {
    const data = document.getElementById('tableDownloadId')?.innerHTML;
    // console.log(data);
    axios
      .post(
        `/api/createExcelRoute`,
        { table: data, name: 'PDIR-AMG' },
        { responseType: 'blob' },
      )
      .then((response) => {
        const blob = new Blob([response.data]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `PRE DESPATCH INSPECTION REPORT - AMG ${partNumber} ${moment().format(
            'dddd, MMMM Do YYYY, h:mm:ss a',
          )}.xlsx`,
        );
        // document.body.appendChild(link);
        link.click();
        URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }
  return (
    <Box>
      <Box>
        <ChildHeader text="PRE DESPATCH INSPECTION REPORT - AMG">
          <CustomButton
            color="primary"
            sx={{ margin: '10px' }}
            icon={<RiFileExcel2Line />}
            disabled={false}
            size="large"
            onClick={() => excelDownload()}
          >
            Excel Download
          </CustomButton>

          {!isView && (
            <>
              {state.user?.userType === 3 && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => onSubmit(false)}
                />
              )}
              <SubmitButton
                label={state.user?.userType === 3 ? 'Submit' : 'Save'}
                onClick={() => onSubmit(true)}
              />
            </>
          )}
        </ChildHeader>
      </Box>
      <Paper elevation={2}>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          textColor="secondary"
        >
          {moduleData &&
            moduleData.pdirs.map((pdir: any) => (
              <Tab label={`${pdir?.drawing_no} / ${pdir?.quality_plan_no}`} />
            ))}
        </Tabs>
      </Paper>
      <TController {...{ moduleData, tabValue, isView }} />
    </Box>
  );
};

export default Create;
