//import Masterlistofmachine from '../../pages/masterlistofMachine/List';
//import MasterlistOfCustomer from '../../pages/department/List';
import {
  Clause8,
  CustomerComplaintList,
  ListOfDefects,
  QualityAlertHome,
  CustomerConcernDashboardTabs,
  InHouseComplaintRegisterList,
} from '../../pages/clause8Page';
import { CustomerComplaintRegisterExcelUpload } from '../../pages/clause8Page/customerComplaintExcelUpload';
//import CreateMachineHistory from '../../pages/masterlistofMachine/machineHistory/CreateMachineHistory';

// main route of clause 10

export const clause8 = [
  {
    path: 'clause8',
    element: <Clause8 />,
  },
  {
    path: 'qualityAlert',
    element: <QualityAlertHome />,
  },
  {
    path: 'CustomerComplaintRegister',
    element: <CustomerComplaintList />,
  },
  {
    path: 'customerComplaintRegisterExcelUpload',
    element: <CustomerComplaintRegisterExcelUpload />,
  },
  {
    path: 'defect_list',
    element: <ListOfDefects />,
  },
  {
    path: 'customerConcernDashboard',
    element: <CustomerConcernDashboardTabs />,
  },
  {
    path: 'inHouseComplaintRegisterList',
    element: <InHouseComplaintRegisterList />,
  },
];
