import { TbReport } from 'react-icons/tb';
import { FiCrosshair } from 'react-icons/fi';
import { MdAnalytics } from 'react-icons/md';

import ChildHeader from '../../../components/ui/ChildHeader';
import { RenderTiles } from '../../../components/common';

const tiles = [
  {
    sNo: 1,
    link: '/common/pipe',
    isLink: true,
    icon: <FiCrosshair size="5rem" color="#003566" />,
    p: 'PIPE',
    span: 'LIST',
    isIcon: true,
  },
  {
    sNo: 4,
    link: `/common/pipeInspectionStdList`,
    isLink: true,
    icon: <TbReport size="5rem" color="#a12222" />,
    p: 'PIPE INSPECTION',
    span: 'STANDARD',
    isIcon: true,
  },
  {
    sNo: 4,
    link: `/common/pipeInspectionReportList`,
    isLink: true,
    icon: <MdAnalytics size="5rem" color="#a12222" />,
    p: 'PIPE INSPECTION',
    span: 'REPORT',
    isIcon: true,
  },
];

const Home = () => {
  return (
    <>
      <ChildHeader text="RI DEPARTMENT" />
      <RenderTiles height="100%" tilesObj={tiles} />
    </>
  );
};

export default Home;
