import React, { useState } from 'react';
import Heading from './moduleBody/Heading';
import useConfirm from '../../../../../../components/common/useConfirm';
import InputMuiCustom from '../../../../../../components/common/inputs/InputMuiCustom';
import { FlexBox } from '../../../../../../components/common';
import ExpandLess from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { DivStyled } from '../../questionPaperStyle';
import CustomSelect from '../../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';

interface IProps {
  moduleData: any;
  setModuleData: React.Dispatch<React.SetStateAction<any>>;
}

const ModuleBody = ({ moduleData, setModuleData }: IProps) => {
  const [expandState, setExpandState] = useState<any>([]);
  const [render, setRender] = useState(false);
  const [DialogRemoveProcess, confirmRemoveProcess] = useConfirm(
    'REMOVE',
    'Are you sure you want to remove this question?',
  );

  const removeProcess = async (index: number) => {
    const isDelete = await confirmRemoveProcess();
    if (!isDelete) return;
    const values = { ...moduleData };
    values.processes && values.levels.splice(index, 1);
    setModuleData(values);
  };
  const sortQuestionPaper = (e: any) => {
    moduleData.topics.s_no = +e.target.value;
    moduleData.topics.sort((a: any, b: any) => Number(a.s_no) - Number(b.s_no));
    setRender((prev) => !prev);
  };

  return (
    <>
      <Heading
        {...{
          setExpandState,
          moduleData,
          setRender,
          setModuleData,
        }}
      />
      {moduleData &&
        moduleData?.topics.map((item: any, index: any) => (
          <div
            style={{
              marginTop: '0.5rem',
              marginLeft: '2rem',
              marginRight: '2rem',
            }}
          >
            <div
              style={{
                marginBottom: '0.3rem',
                marginTop: '0.6rem',
                padding: '0rem',
                border: '1px solid #f0f2f0',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  background: '#fafafa',
                  alignItems: 'center',
                }}
                className="spec-header"
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '2rem',
                  }}
                >
                  <FlexBox>
                    <InputMuiCustom
                      type="number"
                      name="s_no"
                      placeholder=""
                      onBlur={(e) => sortQuestionPaper(e)}
                      defaultValue={item?.s_no}
                      sx={{ width: '70px' }}
                    />
                  </FlexBox>
                  <div style={{ fontSize: '1rem' }}>{item?.name}</div>
                </div>

                <FlexBox>
                  {expandState[index] ? (
                    <FlexBox
                      style={{
                        height: '45px',
                        width: '130px',
                        backgroundColor: '#003566',
                        cursor: 'pointer',
                        color: 'white',
                        alignItems: 'center',
                        gap: '0.5rem',
                      }}
                      onClick={() =>
                        setExpandState((prev: any) => {
                          prev[index] = false;
                          return [...prev];
                        })
                      }
                    >
                      <ExpandLess style={{ marginLeft: '0.5rem' }} />
                      <h1>Collapse</h1>
                    </FlexBox>
                  ) : (
                    <FlexBox
                      style={{
                        height: '45px',
                        width: '130px',
                        backgroundColor: '#003566',
                        cursor: 'pointer',
                        color: 'white',
                        gap: '0.5rem',
                      }}
                      onClick={() =>
                        setExpandState((prev: any) => {
                          prev[index] = true;
                          return [...prev];
                        })
                      }
                    >
                      <ExpandMore style={{ marginLeft: '0.5rem' }} />
                      <h1>Expand</h1>
                    </FlexBox>
                  )}

                  <FlexBox
                    style={{
                      height: '45px',
                      width: '45px',
                      color: '#f1f3f5',
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      gap: '0.5rem',
                      backgroundColor: '#D70012',
                    }}
                    justify="center"
                    onClick={() => removeProcess(index)}
                  >
                    <DeleteIcon style={{ color: '#f8f9fa' }} />
                  </FlexBox>
                </FlexBox>
              </div>
            </div>
            {expandState[index] && (
              <DivStyled>
                <FlexBox>
                  <CustomSelect
                    name="topic_type"
                    value={item.topic_type || ''}
                    onChange={(e: any) => {
                      item[e.target.name] = e.target.value;
                      setRender((prev) => !prev);
                    }}
                    label="Required Skill Level"
                    sx={{
                      fontSize: '1rem',
                      width: '200px',
                    }}
                  >
                    <MenuItem>None</MenuItem>
                    <MenuItem value="L1">L1 - ◔</MenuItem>
                    <MenuItem value="L2">L2 - ◑</MenuItem>
                    <MenuItem value="L3">L3 - ◕</MenuItem>
                    <MenuItem value="L4">L4 - ●</MenuItem>
                  </CustomSelect>
                  <InputMuiCustom
                    // width="95%"
                    sx={{
                      width: '70vw',
                      marginBottom: '15px',
                      marginTop: '15px',
                      height: '60px',
                      marginLeft: '10px',
                    }}
                    type="text"
                    name="name"
                    placeholder="Description"
                    defaultValue={item?.name}
                    onBlur={(e) => {
                      item[e.target.name] = e.target.value;
                    }}
                  />
                </FlexBox>
              </DivStyled>
            )}
          </div>
        ))}
      <DialogRemoveProcess />
    </>
  );
};

export default ModuleBody;
