import { useState } from 'react';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import moment from 'moment';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { Grid, MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';
import { CustomButton } from '../../../../../components/common/button/CustomButtons';
import { newSubProcesses } from '../../helpers/initialState';
import lodash from 'lodash';
import useConfirm from '../../../../../components/common/useConfirm';

interface IProps {
  moduleData: any;
  isView: boolean;
  machineData: any;
  handelDeleteRows: any;
  setPartIdx: any;
  setIsOpenPopup: any;
}

const getAdheranceMarks = (process: any) => {
  return +((process.delivery_adherance_perc * 15) / 100).toFixed(2) || 0;
};
const getMessageMarks = (process: any) => {
  if (process.delivery_customer_message === 'A') return 5;
  if (process.delivery_customer_message === 'B') return 0;
  if (process.delivery_customer_message === 'C') return -30;
  return '';
};
const getOtdMarks = (process: any) => {
  if (process.delivery_otd_days == 0) return 10;
  if (process.delivery_otd_days > 0 && process.delivery_otd_days <= 4) return 5;
};
const getTotalDeliveryMarks = (process: any) => {
  return (
    process.delivery_adherance_marks +
    process.delivery_customer_marks +
    process.delivery_otd_marks
  );
};
const getQualityeMarks = (process: any) => {
  return +((process.quality_lot_rejection_perc * 20) / 100).toFixed(2) || 0;
};
const getQualityStatusMarks = (process: any) => {
  if (process.quality_customer_status_qty == 0) return 5;
  if (process.quality_customer_status_qty > 0) return -25;
};
const getQualityDisruptionMarks = (process: any) => {
  if (process.quality_customer_disruption_qty == 0) return 5;
  if (process.quality_customer_disruption_qty > 0) return -25;
};
const getQualityRecallMarks = (process: any) => {
  if (process.quality_recall_qty == 0) return 5;
  if (process.quality_recall_qty > 0) return -35;
};
const getTotalQualityMarks = (process: any) => {
  return (
    process.quality_lot_rejection_marks +
    process.quality_customer_status_marks +
    process.quality_customer_disruption_marks +
    process.quality_recall_marks
  );
};
const getPremiumFreightMarks = (process: any) => {
  if (process.premium_freight_qty == 0) return 5;
  if (process.premium_freight_qty == 1) return 3;
  return 0;
};
const getCustomerComplaintMarks = (process: any) => {
  if (process.customer_complaint_qty == 0) return 10;
  if (process.customer_complaint_qty == 1) return 5;
  if (process.customer_complaint_qty > 1) return -10;
  return 0;
};
const getLineRejectionMarks = (process: any) => {
  if (process.line_rejection_qty == 0) return 10;
  if (process.line_rejection_qty >= 1 && process.line_rejection_qty <= 10)
    return 5;
  return 0;
};
const getOverallRating = (process: any) => {
  return (
    getTotalDeliveryMarks(process) +
    getTotalQualityMarks(process) +
    process.premium_freight_marks +
    process.customer_complaint_marks +
    process.line_rejection_marks +
    process.warranty_marks
  );
};
const getWarantyMarks = (process: any) => {
  if (process.warranty_qty == 0) return 10;
  if (process.warranty_qty == 1) return 5;
  if (process.warranty_qty > 1) return -10;
  return 0;
};
const getOverAllGrade = (process: any) => {
  const marks = getOverallRating(process);
  if (marks >= 90) return 'A';
  if (marks >= 80) return 'B';
  if (marks >= 60) return 'C';

  return 'D';
};
const getTotalTime = (subProcesses: any) => {
  return subProcesses.reduce(
    (sum: any, curr: any) =>
      sum +
      +curr.total_shift_duration -
      +curr.break_times -
      +curr.prev_aut_maint_time -
      +curr.changeover_time,
    0
  );
};
const getTotalLossTime = (subProcess: any) => {
  const {
    set_up_time,
    break_down_time,
    material_not_avl_time,
    manpower_not_avl_time,
    power_failure_time,
    process_time,
    others_time,
  } = subProcess;
  return (
    +set_up_time +
    +break_down_time +
    +material_not_avl_time +
    +manpower_not_avl_time +
    +power_failure_time +
    +process_time +
    +others_time
  );
};

const getWholeLossTime = (subProcesses: any) => {
  return subProcesses.reduce(
    (sum: any, curr: any) => sum + getTotalLossTime(curr),
    0
  );
};
const getPartWisePerformance = (subProcess: any) => {
  return (
    ((+subProcess.total_production /
      (+subProcess.break_up_time - getTotalLossTime(subProcess))) *
      100) /
    +subProcess.ideal_run_rate
  );
};
const getTotalPerformance = (subProcesses: any) => {
  return (
    subProcesses.reduce(
      (sum: any, curr: any) => sum + getPartWisePerformance(curr),
      0
    ) / subProcesses.length
  );
};
const getPartWiseQuality = (subProcess: any) => {
  return (
    ((+subProcess.total_production - +subProcess.no_rejection) * 100) /
    +subProcess.total_production
  );
};
const getTotalQuality = (subProcesses: any) => {
  return (
    subProcesses.reduce(
      (sum: any, curr: any) => sum + getPartWiseQuality(curr),
      0
    ) / subProcesses.length
  );
};
const getTotalAvailability = (subProcesses: any) => {
  return (
    ((getTotalTime(subProcesses) - getWholeLossTime(subProcesses)) * 100) /
    getTotalTime(subProcesses)
  );
};
const TBody = ({
  moduleData,
  isView,
  machineData,
  handelDeleteRows,
  setPartIdx,
  setIsOpenPopup,
}: IProps) => {
  const [render, setRender] = useState(false);
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Part Row',
    'Are you sure you want to Add Row?'
  );

  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, processIndex: number) => (
            <>
              <tr key={processIndex + 'module data'}>
                <td width={'100px'} rowSpan={1 + process?.subProcesses.length}>
                  {processIndex + 1}
                </td>
                <td
                  style={{ minWidth: '200px' }}
                  rowSpan={1 + process?.subProcesses.length}
                >
                  {isView ? (
                    process?.machine_id?.machine_no
                  ) : (
                    <AutocompleteMuiCustom
                      id={'machine_no'}
                      label={''}
                      option_name={'machine_no'}
                      arrayofObj={machineData}
                      value={process.machine_no || process.machine_id || ''}
                      onChange={(e: any, value: any) => {
                        if (value) {
                          process.machine_id = value._id;
                          process.machine_no = value;
                        }
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </td>
                <td
                  style={{ minWidth: '200px' }}
                  rowSpan={1 + process?.subProcesses.length}
                >
                  {process?.machine_no?.machine_name ||
                    process?.machine_id?.machine_name ||
                    ''}
                </td>
                <td
                  style={{
                    minWidth: '100px',
                    textAlign: 'left',
                    background: '#a9e34b',
                  }}
                  colSpan={30}
                >
                  {!isView && (
                    <CustomButton
                      sx={{
                        backgroundColor: '#1c7ed6',
                      }}
                      onClick={async () => {
                        const ans = await confirmAddRow();
                        if (!ans) return;

                        process.subProcesses.push(
                          lodash.cloneDeep(newSubProcesses[0])
                        );
                      }}
                    >
                      ADD PART ROWS
                    </CustomButton>
                  )}
                </td>
                <td
                  style={{ minWidth: '250px' }}
                  rowSpan={1 + process?.subProcesses.length}
                >
                  <TableInput
                    isView={isView}
                    name="remark"
                    type="text"
                    value={process.remark}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
                {!isView && (
                  <td
                    style={{ minWidth: '100px' }}
                    rowSpan={1 + process?.subProcesses.length}
                  >
                    <DeleteIconButton
                      tooltipTitle={'delete'}
                      onClick={() => handelDeleteRows(processIndex)}
                    />
                  </td>
                )}
              </tr>
              {process?.subProcesses.map(
                (subProcess: any, subProcessIndex: any) => (
                  <tr>
                    <td style={{ minWidth: '200px' }}>
                      {isView ? (
                        subProcess?.part_id && subProcess.part_id?.part_number
                      ) : (
                        <Grid
                          container
                          xs={12}
                          direction="row"
                          alignItems={'center'}
                        >
                          <Grid item xs={10}>
                            <p
                              style={{
                                fontSize: '1.2rem',
                              }}
                            >
                              {typeof subProcess?.part_id === 'object' &&
                              subProcess?.part_id !== null
                                ? subProcess.part_id?.part_number
                                : (subProcess?.partNumber &&
                                    subProcess?.partNumber) ||
                                  ''}
                              {!subProcess?.part_id && 'Pick Part No.'}
                            </p>
                          </Grid>

                          {subProcess.part_id ? (
                            <Grid item xs={2}>
                              <RemoveIconButton
                                tooltipTitle="remove part"
                                onClick={() => {
                                  subProcess.part_id = null;
                                  subProcess.partNumber = null;
                                  subProcess.partName = null;
                                  subProcess.customerName = null;
                                  setRender((prev) => !prev);
                                }}
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={2}>
                              <AddIconButton
                                tooltipTitle="add part"
                                onClick={() => {
                                  setIsOpenPopup(true);
                                  setPartIdx({
                                    processIndex: processIndex,
                                    subProcessIndex: subProcessIndex,
                                  });
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      )}
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="no_of_bend"
                        type="number"
                        value={subProcess.no_of_bend}
                        onChange={(e) => {
                          subProcess.no_of_bend = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '200px' }}>
                      <TableInput
                        isView={isView}
                        name="pipe_dia"
                        type="text"
                        value={subProcess.pipe_dia}
                        onChange={(e) => {
                          subProcess.pipe_dia = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="no_shift_planned"
                        type="number"
                        value={subProcess.no_shift_planned}
                        onChange={(e) => {
                          subProcess.no_shift_planned = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="total_shift_duration"
                        type="number"
                        value={subProcess.total_shift_duration}
                        onChange={(e) => {
                          subProcess.total_shift_duration = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="break_times"
                        type="number"
                        value={subProcess.break_times}
                        onChange={(e) => {
                          subProcess.break_times = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="prev_aut_maint_time"
                        type="number"
                        value={subProcess.prev_aut_maint_time}
                        onChange={(e) => {
                          subProcess.prev_aut_maint_time = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="changeover_time"
                        type="number"
                        value={subProcess.changeover_time}
                        onChange={(e) => {
                          subProcess.changeover_time = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    {subProcessIndex === 0 && (
                      <td
                        style={{ minWidth: '100px' }}
                        rowSpan={process.subProcesses.length}
                      >
                        {getTotalTime(process.subProcesses)}
                      </td>
                    )}
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="break_up_time"
                        type="number"
                        value={subProcess.break_up_time}
                        onChange={(e) => {
                          subProcess.break_up_time = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="set_up_time"
                        type="number"
                        value={subProcess.set_up_time}
                        onChange={(e) => {
                          subProcess.set_up_time = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="break_down_time"
                        type="number"
                        value={subProcess.break_down_time}
                        onChange={(e) => {
                          subProcess.break_down_time = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="material_not_avl_time"
                        type="number"
                        value={subProcess.material_not_avl_time}
                        onChange={(e) => {
                          subProcess.material_not_avl_time = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="manpower_not_avl_time"
                        type="number"
                        value={subProcess.manpower_not_avl_time}
                        onChange={(e) => {
                          subProcess.manpower_not_avl_time = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="power_failure_time"
                        type="number"
                        value={subProcess.power_failure_time}
                        onChange={(e) => {
                          subProcess.power_failure_time = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="process_time"
                        type="number"
                        value={subProcess.process_time}
                        onChange={(e) => {
                          subProcess.process_time = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="others_time"
                        type="number"
                        value={subProcess.others_time}
                        onChange={(e) => {
                          subProcess.others_time = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      {getTotalLossTime(subProcess)}
                    </td>
                    {subProcessIndex === 0 && (
                      <td
                        style={{ minWidth: '100px' }}
                        rowSpan={process.subProcesses.length}
                      >
                        {getTotalTime(process.subProcesses) -
                          getWholeLossTime(process.subProcesses)}
                      </td>
                    )}

                    <td style={{ minWidth: '100px' }}>
                      {+subProcess.break_up_time - getTotalLossTime(subProcess)}
                    </td>

                    {subProcessIndex === 0 && (
                      <td
                        style={{ minWidth: '100px' }}
                        rowSpan={process.subProcesses.length}
                      >
                        {getTotalAvailability(process.subProcesses).toFixed(2)}%
                      </td>
                    )}
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="ideal_run_rate"
                        type="number"
                        step={0.1}
                        value={subProcess.ideal_run_rate}
                        onChange={(e) => {
                          subProcess.ideal_run_rate = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="total_production"
                        type="number"
                        value={subProcess.total_production}
                        onChange={(e) => {
                          subProcess.total_production = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      <TableInput
                        isView={isView}
                        name="no_rejection"
                        type="number"
                        value={subProcess.no_rejection}
                        onChange={(e) => {
                          subProcess.no_rejection = e.target.value;
                          setRender((prev) => !prev);
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      {+subProcess.total_production - +subProcess.no_rejection}
                    </td>
                    <td style={{ minWidth: '100px' }}>
                      {getPartWisePerformance(subProcess).toFixed(2)}%
                    </td>
                    {subProcessIndex === 0 && (
                      <td
                        style={{ minWidth: '100px' }}
                        rowSpan={process.subProcesses.length}
                      >
                        {getTotalPerformance(process.subProcesses).toFixed(2)}%
                      </td>
                    )}
                    <td style={{ minWidth: '100px' }}>
                      {getPartWiseQuality(subProcess).toFixed(2)}%
                    </td>
                    {subProcessIndex === 0 && (
                      <td
                        style={{ minWidth: '100px' }}
                        rowSpan={process.subProcesses.length}
                      >
                        {getTotalQuality(process.subProcesses).toFixed(2)}%
                      </td>
                    )}
                    {subProcessIndex === 0 && (
                      <td
                        style={{ minWidth: '100px' }}
                        rowSpan={process.subProcesses.length}
                      >
                        {(
                          (getTotalAvailability(process.subProcesses) *
                            getTotalPerformance(process.subProcesses) *
                            getTotalQuality(process.subProcesses)) /
                          10000
                        ).toFixed(2)}
                        %
                      </td>
                    )}
                  </tr>
                )
              )}
            </>
          ))}
      </tbody>
      <DialogAddRow />
    </>
  );
};

export default TBody;
