import {
  AiOutlineDeliveredProcedure,
  AiOutlinePartition,
} from 'react-icons/ai';
import { AiOutlineFundView } from 'react-icons/ai';
import { BsFillPeopleFill } from 'react-icons/bs';
import { TbReport } from 'react-icons/tb';
import { ImWarning } from 'react-icons/im';
import { FaCloudUploadAlt } from 'react-icons/fa';

const tiles = [
  {
    sNo: 23,
    link: `/common/listOfProcedures/?documentType=wiUpload&heading=LIST OF PROCEDURES`,
    isLink: true,
    icon: <AiOutlineDeliveredProcedure size="5rem" color="#003566" />,
    p: 'LIST OF PROCEDURES',
    span: '',
    isIcon: true,
  },
  {
    sNo: 24,
    link: `/common/listOfProcedures/?documentType=clause4Imds&heading=IMDS`,
    isLink: true,
    icon: <FaCloudUploadAlt size="5rem" color="#003566" />,
    p: 'IMDS',
    span: '',
    isIcon: true,
  },
  {
    sNo: 1,
    link: '/admin/part_list?filter=part_number',
    isLink: true,
    clauseNo: 4,
    icon: <AiOutlinePartition size="5rem" color="#a12222" />,
    p: 'ADD',
    span: 'PART',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/admin/ppap_documents?filter=part_number',
    isLink: true,
    clauseNo: 4,
    p: 'VIEW',
    span: 'PPAP DOCUMENTS',
    icon: <AiOutlineFundView size="5rem" color="#343a40" />,
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/admin/customer_list?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    p: 'MASTER LIST OF',
    span: 'CUSTOMER',
    icon: <BsFillPeopleFill size="5rem" color="#135089" />,
    isIcon: true,
  },
  // {
  //   sNo: 5,
  //   link: '/admin/pirList',
  //   isLink: true,
  //   icon: <VscServerProcess size="5rem" color="#a12222" />,
  //   p: 'LINE',
  //   span: 'INSPECTION',
  //   isIcon: true,
  // },
  {
    sNo: 6,
    link: '/admin/pdirList',
    isLink: true,
    icon: <TbReport size="5rem" color="#a12222" />,
    p: 'JOB',
    span: 'CARD',
    isIcon: true,
  },
  // {
  //   sNo: 7,
  //   link: '/admin/pdirTiles',
  //   isLink: true,
  //   icon: <VscServerProcess size="5rem" color="#135089" />,
  //   p: 'PDIR',
  //   span: 'REPORT',
  //   isIcon: true,
  // },
  {
    sNo: 8,
    link: '/common/customerList',
    isLink: true,
    icon: <BsFillPeopleFill size="5rem" color="#135089" />,
    p: 'CUSTOMER STANDARDS',
    span: 'MODULE',
    isIcon: true,
  },

  // {
  //   sNo: 7,
  //   link: '/admin/setupList',
  //   isLink: true,
  //   icon: <MdApproval size="5rem" color="#a12222" />,
  //   p: 'FPA/',
  //   span: 'LPA',
  //   isIcon: true,
  // },
  // {
  //   sNo: 8,
  //   link: '/admin/incomingReportList',
  //   isLink: true,
  //   icon: <FcInspection size="5rem" color="#a12222" />,
  //   p: 'INCOMING INSPECTION',
  //   span: 'STANDARD',
  //   isIcon: true,
  // },
  // {
  //   sNo: 9,
  //   link: '/admin/materialIncomingList',
  //   isLink: true,
  //   icon: <FcInspection size="5rem" color="#a12222" />,
  //   p: 'INCOMING MATERIAL',
  //   span: 'INSPECTION',
  //   isIcon: true,
  // },
  {
    sNo: 23,
    link: `/common/contingencyPlan/?documentType=wiUpload&heading=CONTINGENCY PLAN`,
    isLink: true,
    icon: <ImWarning size="5rem" color="#003566" />,
    p: 'CONTINGENCY PLAN',
    span: '',
    isIcon: true,
  },
];

export default tiles;
