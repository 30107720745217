import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../../components/common/button/CustomButtons';
import formatDate from '../../../../components/common/formatDate';
import ChildHeader from '../../../../components/ui/ChildHeader';
import { useQuery } from '../../../../hooks/UseQuery';
import { useAuth } from '../../../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
import { apiConstant, createConstant } from './constant';
import { GoSync } from 'react-icons/go';
import useConfirm from '../../../../components/common/useConfirm';
import { Loader } from '../../../../components/common/Loader';
import BarLineChart from './components/Graph/BarLineChart';

interface MyData {
  moduleData: any;
  Sync: any;
  isLoading: boolean;
  error?: string | null;
  handelSync: any;
}

const Create = () => {
  const { id } = useParams();
  const date = useQuery('date');
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?'
  );
  // const { /*moduleData isLoading, error,*/ handelSync, Sync }: MyData =
  //   useGetData(id);
  const [moduleData, setModuleData] = useState<any[]>([{}]);
  const [breakDownTime, setBreakDownTime] = useState({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/${apiConstant.getPlanByid}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
      axios
        .get(`/api/maintenaceLogRoute/getByYear/?date=${date}`)
        .then((res) => {
          setBreakDownTime(res.data.totalBreakDownTime);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, []);

  const onSubmit = async (isSubmitted: boolean) => {
    // moduleData.isSubmitted = isSubmitted;
    // moduleData.report_prepared_by = state.user?.name;
    // console.log('GGGGGGGGGGGGGGGGG', moduleData);
    const ans = await confirmSave();
    if (!ans) return;

    axios
      .put(`/api/${apiConstant.updatePlanByid}/${id}`, moduleData)
      .then((res) => alert('updated successfully '))
      .catch((err) => console.log(err));
  };

  // console.log('hgjgjgjgjgjgjgjgjgjgjgjgjgjgjgjgjgjgjgjgjgjgj', moduleData);
  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text={`${createConstant.childHeader}`}>
          <SubmitButton label="Submit" onClick={() => onSubmit(true)} />
        </ChildHeader>
      </Box>
      <TController {...{ moduleData, isView, breakDownTime }} />
      <Box style={{ paddingBottom: '5rem', width: '50vw' }}>
        <BarLineChart moduleData={moduleData} />
      </Box>
      <DialogSave isSubmitContent={false} />
    </Box>
  );
};

export default Create;
