// import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const theadData = [
  'SR. NO.',
  'MACHINE NAME',
  'MACHINE NO',
  'PIPE SPEC.',
  'PROCESS STAGE',
  'CUSTOMER NAME',
  'PART NAME',
  'OPERATOR NAME',
  'QUALITY INSPECTOR',
  'PRODUCTION INCHARGE',
  'PROD. QTY.',
  'REJ. QTY',
  'REASON OF REJECTION',
  'REMARKS',
];
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={15}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="DAILY LINE SET - UP APPROVAL"
      />
      <tr>
        <th colSpan={2} align="left">
          Date :{' '}
        </th>
        <th colSpan={6} align="left">
          {isView ? (
            formatDate(moduleData.setupAppr_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.setupAppr_date)}
              onChange={(date) => {
                moduleData.setupAppr_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={2} align="left">
          Shift :{' '}
        </th>
        <th colSpan={5} align="left">
          <TableInput
            isView={isView}
            name="shift"
            type="text"
            value={moduleData.shift}
            onChange={(e) => {
              moduleData.shift = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading + index} colSpan={heading === 'REMARKS' ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
