import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import { IData } from './helpers/Interface';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';
import AutocompleteGridmui from '../../../components/common/autocomplete/AutocompleteGridmui';
import SelectGridMui from '../../../components/common/selectscustom/SelectGridMui';
import { Checkbox, MenuItem } from '@mui/material';
import GridDatePicker from '../../../components/common/inputs/GridDatePicker';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import moment from 'moment';

const Create = ({
  id,
  setIsModal,
  setVendorId,
}: {
  id?: string | null;
  setVendorId: any;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [vendorData, setVendorData] = useState<IData>({
    vender_name: '',
    vender_code: '',
    address: '',
    contact_person: '',
    contact_no: 0,
    email_id: '',
    remarks: '',
    iso_9001: '',
    iso_9001_expiry_date: moment(),
    iso_14001: '',
    iso_14001_expiry_date: moment(),
    iatf: '',
    cert_exp_date: moment(),
    state: '',
    district: '',
    pincode: '',
    commodity: '',
    classification: '',
  });
  const [files, setFiles] = useState<any>(null);
  const [isSubmit, setIsSubmit] = useState(false);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Vendor' : 'Create Ventor'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/vendorRoute/${id}`)
        .then((res) => {
          setVendorData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleCustomerData(e: React.ChangeEvent<HTMLInputElement>) {
    setVendorData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const formData = new FormData();

    for (const key in vendorData) {
      if (
        (key !== 'files' && key !== 'images') ||
        typeof vendorData[key] == 'boolean'
      )
        formData.append(key, vendorData[key]);
    }

    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }

    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/vendorRoute/${id}`, formData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setVendorId(null);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/vendorRoute', formData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
          setVendorId(null);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'vender_name'}
        name={'vender_name'}
        html_for={'vender_name'}
        label_name={'Supplier Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.vender_name || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'vender_code'}
        name={'vender_code'}
        html_for={'vender_code'}
        label_name={'Supplier Code:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.vender_code || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'address'}
        name={'address'}
        html_for={'address'}
        label_name={'Address:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.address || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'contact_person'}
        name={'contact_person'}
        html_for={'contact_person'}
        label_name={'Contact Person:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.contact_person || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'contact_no'}
        name={'contact_no'}
        html_for={'contact_no'}
        label_name={'Contact No.:'}
        input_type={'number'}
        focusType={'onblur'}
        value={vendorData?.contact_no || ''}
        onChange={handleCustomerData}
      />
      <SelectGridMui
        name="classification"
        value={vendorData?.classification || ''}
        lable={'Classification'}
        onChange={(e: any) => handleCustomerData(e)}
      >
        <MenuItem value="">NONE</MenuItem>
        <MenuItem value="A">A</MenuItem>
        <MenuItem value="B">B</MenuItem>
        <MenuItem value="C">C</MenuItem>
      </SelectGridMui>
      <GridInputs
        id={'email_id'}
        name={'email_id'}
        html_for={'email_id'}
        label_name={'Email ID:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.email_id || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'iso_9001'}
        name={'iso_9001'}
        html_for={'iso_9001'}
        label_name={'ISO 9001:2015'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.iso_9001 || ''}
        onChange={handleCustomerData}
      />
      <GridDatePicker
        label_name="ISO 9001:2015 Certificate Expiry Date"
        html_for={'issue_reported_date'}
        value={vendorData?.iso_9001_expiry_date || null}
        onChange={(date) => {
          vendorData.iso_9001_expiry_date = date;
        }}
      />
      <GridInputs
        id={'iso_14001'}
        name={'iso_14001'}
        html_for={'iso_14001'}
        label_name={'ISO 14001:2015'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.iso_14001 || ''}
        onChange={handleCustomerData}
      />
      <GridDatePicker
        label_name="ISO 14001:2015 Certificate Expiry Date"
        html_for={'iso_14001_expiry_date'}
        value={vendorData?.iso_14001_expiry_date || null}
        onChange={(date) => {
          vendorData.iso_14001_expiry_date = date;
        }}
      />
      <GridInputs
        id={'iatf'}
        name={'iatf'}
        html_for={'iatf'}
        label_name={'IATF'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.iatf || ''}
        onChange={handleCustomerData}
      />
      <GridDatePicker
        label_name="IATF Certificate Expiry Date"
        html_for={'cert_exp_date'}
        value={vendorData?.cert_exp_date || null}
        onChange={(date) => {
          vendorData.cert_exp_date = date;
        }}
      />
      <GridInputs
        id={'state'}
        name={'state'}
        html_for={'state'}
        label_name={'State'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.state || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'district'}
        name={'district'}
        html_for={'district'}
        label_name={'District'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.district || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'pincode'}
        name={'pincode'}
        html_for={'pincode'}
        label_name={'Pincode'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.pincode || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'commodity'}
        name={'commodity'}
        html_for={'commodity'}
        label_name={'commodity'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.commodity || ''}
        onChange={handleCustomerData}
      />
      <GridInputs
        id={'remarks'}
        name={'remarks'}
        html_for={'remarks'}
        label_name={'Remarks:'}
        input_type={'text'}
        focusType={'onblur'}
        value={vendorData?.remarks || ''}
        onChange={handleCustomerData}
        last_child={2}
      />

      <CustomUploadButton
        lable={'Attach Supporting Doucments'}
        multiple
        accept="application/pdf,application/doc,application/excel/,application/ppt/image/png,image/jpeg,image/jpg"
        onChange={(e) => {
          if (files) {
            setFiles([...files, ...e.target.files]);
          } else {
            setFiles(e.target.files);
          }
        }}
      />
      <div>
        <Checkbox
          name="exclude_report"
          checked={vendorData?.exclude_report || ''}
          onChange={(e) => {
            setVendorData((prev: any) => ({
              ...prev,
              [e.target.name]: e.target.checked,
            }));
          }}
        />

        <span
          style={{
            fontWeight: '500',
            fontSize: '20px',
          }}
        >
          Exclude from report
        </span>
      </div>

      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
