import React from 'react';
import Domestic from './Domestic';
import Exports from './Exports';
import { Box } from '@mui/material';
interface IProps {
  apiData: any;
  year: number;
}
const Type = ({ apiData, year }: IProps) => {
  return (
    <div style={{ display: 'flex' }}>
      <Domestic
        totalOfDomesticPerMonth={apiData?.totalOfDomesticPerMonth}
        year={year}
      />
      <Exports
        totalOfExportsPerMonth={apiData?.totalOfExportsPerMonth}
        year={year}
      />
    </div>
  );
};

export default Type;
