import TBodyRow from './TBodyRow';

interface IProps {
  moduleData: any;
  handleDelete: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView, handleDelete }: IProps) => {
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <TBodyRow
          {...{
            item,
            index,
            isView,
            handleDelete,
          }}
        />
      ))}
    </tbody>
  );
};

export default TBody;
