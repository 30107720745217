import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
} from '../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';

import { IData } from './Interface';

const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'Party Name', rowspan: 1, colspan: 1 },
    { text: 'Material', rowspan: 1, colspan: 1 },
    { text: 'Grade	', rowspan: 1, colspan: 1 },
    { text: 'Address', rowspan: 1, colspan: 1 },
    { text: 'Contact Person', rowspan: 1, colspan: 1 },
    { text: 'Phone', rowspan: 1, colspan: 1 },
    { text: 'ISO Certified/Trader	', rowspan: 1, colspan: 1 },
    { text: 'Remarks', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'createdAt', name: 'A-Z' }];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [customerId, setCustomerId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);

  const search = useQuery('search');
  const filter = useQuery('filter');
  const page = useQuery('page') || 1;

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE SUPPLIER',
    'Are you sure you want to delete this?'
  );
  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/supplierRoutes/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomer = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/supplierRoutes/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal]);

  return (
    <>
      <ChildHeader text="SUPPLIER LIST">
        <AddButton onClick={() => setIsModal(true)} label="New Supplier" />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IData, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">{item?.supplier_name}</TableCell>
                  <TableCell align="center">{item?.material}</TableCell>
                  <TableCell align="center">{item?.rm_grade}</TableCell>
                  <TableCell align="center">{item?.address}</TableCell>
                  <TableCell align="center">{item?.contact_person}</TableCell>
                  <TableCell align="center">{item.contact_number}</TableCell>
                  <TableCell align="center">
                    {item.iso_certified_trader}
                  </TableCell>
                  <TableCell align="center">{item.remark}</TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setCustomerId(item?._id || '');
                          setIsModal(true);
                        }}
                      />

                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteCustomer(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setCustomerId(null);
          setIsModal(false);
        }}
        title="SUPPLIER"
      >
        <Create id={customerId} setIsModal={setIsModal} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
