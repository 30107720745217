import React, { useEffect, useState } from 'react';
import TController from './components/view/TController';
import { air_under_water } from './helpers/airUnderWater';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useAuth } from '../../../services/auth/AuthProvider';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import moment from 'moment';

const View = () => {
  const navigate = useNavigate();
  const { state } = useAuth();
  const { id } = useParams();
  const isView = useQuery('isView') === 'true' ? true : false;
  const checkSheetType = useQuery('checkSheetType');
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?',
  );
  const initialState: any = {
    air_under_water: air_under_water,
  };

  const showHeading: any = {
    air_under_water:
      '(प्रतिदिन LEICAROOM STARTUP चैक शीट)  DAILY LEICAROOM STARTUP CHECK SHEET  ',
  };
  const headerTitle: string = `${showHeading[checkSheetType as string]} `;
  const [moduleData, setModuleData] = useState<any>({
    isSubmitted: false,
    is_admin_request_no: null,
    is_admin_request: null,
    report_prepared_by: null,
    report_approved_by: null,
    checkSheetType: checkSheetType,
    checkSheet_date: moment(),
    [checkSheetType as string]: lodash.cloneDeep(
      initialState[checkSheetType as string],
    ),
  });

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/leicaRoomChkShtQtyDeptRoute/${id}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id]);

  const handleSumbit = async (isSubmitted: boolean) => {
    const ans = await confirmSubmit();
    if (!ans) return;
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;
    if (id) {
      await axios
        .put(`/api/leicaRoomChkShtQtyDeptRoute/${id}`, moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Updated Successfully');
          }
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/leicaRoomChkShtQtyDeptRoute', moduleData)
        .then((res) => {
          if (res.data.status === 'success') {
            alert('created Successfully');
            navigate(-1);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  return (
    <>
      <Box>
        <ChildHeader text={'DAILY LEICAROOM STARTUP CHECK SHEET  '}>
          {!isView && (
            <>
              {state.user?.userType === state.IS.Supervisor && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => handleSumbit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => handleSumbit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handleSumbit,
          headerTitle,
        }}
      />

      <DialogSubmit />
    </>
  );
};

export default View;
