import React, { useEffect, useState } from 'react';
import GridInputs from '../../../components/common/inputs/GridInputs';
import { IData } from './helpers/Interface';
import axios from 'axios';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import useConfirm from '../../../components/common/useConfirm';

const Create = ({
  id,
  setIsModal,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [defectData, setDefectData] = useState<IData>({
    defect_name: null,
    // product: null,
    //type_of_industry: null,
    //remarks: null,
  });

  const [isSubmit, setIsSubmit] = useState(false);

  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Defect' : 'Create Defect'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/qaulityDefectsRoute/${id}`)
        .then((res) => setDefectData(res?.data))
        .catch((err) => console.log(err));
    }
  }, [id]);

  function handleDefectData(e: React.ChangeEvent<HTMLInputElement>) {
    setDefectData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    setIsSubmit(true);
    const ans = await confirmSubmit();
    if (!ans) return;
    if (id) {
      await axios
        .put(`/api/qaulityDefectsRoute/${id}`, defectData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/qaulityDefectsRoute', defectData)
        .then((res) => {
          setIsSubmit(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsSubmit(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <GridInputs
        id={'defect_name'}
        name={'defect_name'}
        html_for={'defect_name'}
        label_name={'Defect Name:'}
        input_type={'text'}
        focusType={'onblur'}
        value={defectData?.defect_name || ''}
        onChange={handleDefectData}
      />
      <div
        className="btn_save"
        style={{
          position: 'absolute',
          right: '140px',
          top: '-0.6px',
        }}
      >
        <SubmitButton
          label={`${id ? 'update' : 'submit'}`}
          type="button"
          onClick={handleSumbit}
        />
      </div>
      <DialogSubmit />
    </>
  );
};

export default Create;
