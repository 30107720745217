import { MdEmojiTransportation } from 'react-icons/md';

const tiles = [
  {
    sNo: 1,
    link: '/admin/supplier_list?filter=createdAt',
    isLink: true,
    p: 'MASTER LIST OF',
    span: 'SUPPLIER',
    isIcon: true,
    icon: <MdEmojiTransportation size="5rem" color="#135089" />,
  },
];

export default tiles;
