import { TableInput } from '../../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';

interface IProps {
  process: any;
  isView: boolean;
  obsIndex: number;
  setRender: any;
}
const ObservationInput = ({ process, isView, obsIndex, setRender }: IProps) => {
  return (
    <td style={{ backgroundColor: isView ? process['colors'][obsIndex] : '' }}>
      <TableInput
        name="observation"
        type="number"
        value={process[`values`][obsIndex] || ''}
        onChange={(e) => {
          console.log(process);
          handleChangeModuleData(e, process, obsIndex);
          setRender((prev: any) => !prev);
        }}
        style={{
          backgroundColor: process['colors'][obsIndex],
          textAlign: 'center',
          width: '100%',
        }}
        isView={isView}
      />
    </td>
  );
};
export default ObservationInput;
