import moment from 'moment';
const row_qty = {
  inco_qty: 0,
  rej_qty: 0,
  ok_qty: 0,
  ppm_qty: 0,
};
const total_row_qty = {
  total_inco_qty: 0,
  total_rej_qty: 0,
  total_ok_qty: 0,
  total_ppm_qty: 0,
}
export const list = {
  process_date: moment(),
  vendor_id: null,
  total_inco_qty:0,
  total_rej_qty:0,
  total_ok_qty:0,
  total_ppm_qty:0,
  apr: {
    ...row_qty,
  },
  may: {
    ...row_qty,
  },
  jun: {
    ...row_qty,
  },
  jul: {
    ...row_qty,
  },
  aug: {
    ...row_qty,
  },
  sep: {
    ...row_qty,
  },
  oct: {
    ...row_qty,
  },
  nov: {
    ...row_qty,
  },
  dec: {
    ...row_qty,
  },
  jan: {
    ...row_qty,
  },
  feb: {
    ...row_qty,
  },
  mar: {
    ...row_qty,
  },
};

export const module_data = {
  total_inco_qty:0,
  total_rej_qty:0,
  total_ok_qty:0,
  total_ppm_qty:0,
  list:[],
  apr: {
    ...total_row_qty,
  },
  may: {
    ...total_row_qty,
  },
  jun: {
    ...total_row_qty,
  },
  jul: {
    ...total_row_qty,
  },
  aug: {
    ...total_row_qty,
  },
  sep: {
    ...total_row_qty,
  },
  oct: {
    ...total_row_qty,
  },
  nov: {
    ...total_row_qty,
  },
  dec: {
    ...total_row_qty,
  },
  jan: {
    ...total_row_qty,
  },
  feb: {
    ...total_row_qty,
  },
  mar: {
    ...total_row_qty,
  },
}