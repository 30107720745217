import ChildHeader from '../../../../components/ui/ChildHeader';
import PartDetailsHeader from '../../../../components/ui/PartDeatailsHeader';
import { useQuery } from '../../../../hooks/UseQuery';

const HeaderTabs = () => {
  const partName = useQuery('part_name');
  const partNumber = useQuery('part_number');

  return (
    <div>
      <ChildHeader text="DOCUMENT LIST" />

      <PartDetailsHeader
        partName={partName || ''}
        partNumber={partNumber || ''}
      />
    </div>
  );
};

export { HeaderTabs };
