import { MenuItem, Paper, SelectChangeEvent, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import formatDate from '../../components/common/formatDate';
import ChildHeader from '../../components/ui/ChildHeader';
import { useQuery } from '../../hooks/UseQuery';
import { useAuth } from '../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
import { apiConstant, createConstant } from './constant';
import { GoSync } from 'react-icons/go';
import useConfirm from '../../components/common/useConfirm';
import { Loader } from '../../components/common/Loader';
import BarLineChart from './components/Graph/BarLineChart';
import GridSelect from '../../components/common/inputs/GridSelect';

interface MyData {
  moduleData: any;
  Sync: any;
  isLoading: boolean;
  error?: string | null;
  handelSync: any;
}
const parameters = [
  'Customer Complaints',
  'Customer PPM',
  'COPQ (To Total Sales)',
  'Safety incidents (near miss)',
  'On Time Delivery',
  'ITR',
  'Material to sales (on purchase + job work)',
  'Manpower/ Sale % (including piece rate contractual)',
  'Power & Fuel Cost/ Sale',
  'Productivity: Units/Man-hr',
  'CI Saving',
  'OEE (Total Plant)',
  'QC Circles formed',
  'Premium Freight',
  'In-House PPM',
  'No. of accidents (pm)',
  '5S score',
  'Kaizens/ person/ month',
  'ERP vs physical material variance',
  'Mean Time Between Failures (MTBF)',
  'Mean Time To Repair (MTTR)',
];
const Create = () => {
  const [parameter, setParameter] = useState('');
  const { id } = useParams();
  const date = useQuery('date');
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const [DialogSave, confirmSave] = useConfirm(
    'SAVE',
    'Are you sure you want to Update this?'
  );
  // const { /*moduleData isLoading, error,*/ handelSync, Sync }: MyData =
  //   useGetData(id);
  const [moduleData, setModuleData] = useState<any[]>([{}]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    console.log('gjjjjjjjjj');
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/${apiConstant.getPlanByid}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    }
  }, []);

  const onSubmit = async (isSubmitted: boolean) => {
    // moduleData.isSubmitted = isSubmitted;
    // moduleData.report_prepared_by = state.user?.name;
    const ans = await confirmSave();
    if (!ans) return;

    axios
      .put(`/api/${apiConstant.updatePlanByid}/${id}`, moduleData)
      .then((res) => alert('updated successfully '))
      .catch((err) => console.log(err));
  };

  return (
    <Box>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text={`${createConstant.childHeader}`}>
          <SubmitButton label="Submit" onClick={() => onSubmit(true)} />
        </ChildHeader>
      </Box>
      <TController {...{ moduleData, isView }} />
      <Box style={{ marginTop: '20px', marginLeft: '20px' }}>
        <GridSelect
          name="parameter"
          label_name="Parameter"
          html_for={'parameter'}
          value={parameter || ''}
          onChange={(e: any) => setParameter(e.target.value)}
        >
          {parameters.map((item) => {
            return <MenuItem value={item}>{item}</MenuItem>;
          })}
        </GridSelect>
      </Box>
      {parameter && (
        <Box style={{ paddingBottom: '5rem', width: '100vw' }}>
          <BarLineChart
            moduleData={moduleData}
            ParameterIndex={parameters.indexOf(parameter)}
          />
        </Box>
      )}
      <DialogSave isSubmitContent={false} />
    </Box>
  );
};

export default Create;
