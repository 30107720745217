import { useState } from 'react';
import CustomSelect from '../../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../../components/common';

interface IProps {
  defectsCountResult: any;
  defectsCountColumnResult: any;
}
const TBody = ({ defectsCountResult, defectsCountColumnResult }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {defectsCountResult?.map((data: any) => {
        return (
          <tr>
            <td style={{ fontWeight: 'bold' }}>{data.customerName}</td>
            {data &&
              data.defectsCountRow &&
              data.defectsCountRow?.map((count: number) => (
                <td style={{ background: count > 0 ? '#ffc078' : '#69db7c' }}>
                  {count}
                </td>
              ))}

            <>
              <td
                style={{
                  background:
                    data.defectsCountRowTotal > 0 ? '#ffc078' : '#69db7c',
                }}
              >
                {data.defectsCountRowTotal}
              </td>
              <td
                style={{
                  background:
                    data.defectsCountRowTotalContribution > 0
                      ? '#ffc078'
                      : '#69db7c',
                }}
              >
                {data.defectsCountRowTotalContribution}
              </td>
            </>
          </tr>
        );
      })}

      {
        <tr>
          <td style={{ fontWeight: 'bold' }}>Total</td>
          {defectsCountColumnResult &&
            defectsCountColumnResult.TotalColumn &&
            defectsCountColumnResult?.TotalColumn?.map((count: number) => (
              <td>{count}</td>
            ))}
        </tr>
      }

      {
        <tr>
          <td style={{ fontWeight: 'bold' }}>% Contribution</td>
          {defectsCountColumnResult &&
            defectsCountColumnResult.contributionColumn &&
            defectsCountColumnResult?.contributionColumn?.map(
              (percentage: number) => <td>{percentage}</td>
            )}
          <td></td>
          <td></td>
        </tr>
      }
      {
        <tr>
          <td style={{ fontWeight: 'bold' }}>% Cumulative Contribution</td>
          {defectsCountColumnResult &&
            defectsCountColumnResult.CumulativeContribution &&
            defectsCountColumnResult?.CumulativeContribution?.map(
              (CumulativePercentage: number) => <td>{CumulativePercentage}</td>
            )}
          <td></td>
          <td></td>
        </tr>
      }
    </tbody>
  );
};

export default TBody;
