export const listConstant = {
  childHeader: 'INSTRUMENT MSA plan list',
  addButtonLable: 'New INSTRUMENT MSA',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'INSTRUMENT MSA plan list',
  theadHeader: 'INSTRUMENT Plan for MSA',
  extracolSpan: 5,
};

export const checkListList = {
  childHeader: 'INSTRUMENT MSA STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'instrumentMsaPlanRoute',
  gettableDataurl: 'instrumentMsaPlanRoute/monthly',
  getPlanByid: 'instrumentMsaPlanRoute',
  createPlanByid: 'instrumentMsaPlanRoute',
  updatePlanByid: 'instrumentMsaPlanRoute',
  sync: 'instrumentMsaPlanRoute/handleSync',
  getAllCheckList: 'instrumentMsaPlanRoute/getAllCheckList',
  checkListStatus: 'instrumentMsaPlanRoute/handleStatus',
  checklistUpload: 'instrumentMsaPlanRoute/handleUpload',
  checklistDeleteImage: 'instrumentMsaPlanRoute/handleDeleteImage',
};

export const navigationConstant = {
  createPlanSchedule: '/common/instrumentMsaPlanCreate',
};
