import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';

interface IProps {
  moduleData: any;
  machineData: any;
  customerData: any;
  calculateCumulative: any;
  setModuleData: any;
  isView: boolean;
}

const THead = ({
  moduleData,
  isView,
  machineData,
  customerData,
  calculateCumulative,
  setModuleData,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="HISTORY-CARD FOR TOOLINGS"
      />
      <tr>
        <th colSpan={2} align="left">
          CARD NO
        </th>
        <th colSpan={2}>
          <TableInput
            name="card_no"
            type="text"
            value={moduleData.card_no}
            onChange={(e) => {
              moduleData.card_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th colSpan={1} align="left">
          ISSUE DATE
        </th>
        <th colSpan={2}>
          {isView ? (
            formatDate(moduleData.issue_date)
          ) : (
            <DatePickerMui
              value={moment(moduleData.issue_date)}
              onChange={(date) => {
                moduleData.issue_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>

      <tr>
        <th align="left" colSpan={2}>
          TOOLING NAME
        </th>
        <th align="left" colSpan={1}>
          <TableInput
            name="tooling_name"
            type="text"
            value={moduleData.tooling_name}
            onChange={(e) => {
              moduleData.tooling_name = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={1}>
          TOOLING NO
        </th>
        <th align="left" colSpan={1}>
          <TableInput
            name="tooling_no"
            type="text"
            value={moduleData.tooling_no}
            onChange={(e) => {
              moduleData.tooling_no = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={1}>
          Machine
        </th>
        <th align="left" colSpan={1} style={{ width: '200px' }}>
          {isView ? (
            moduleData?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="Machine"
              label=""
              option_name="machine_no"
              arrayofObj={machineData}
              value={moduleData?.machine_id || ''}
              onChange={(e, value) => {
                moduleData.machine_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
      <tr>
        <th align="left" colSpan={2}>
          TOOLING OTHER DETAILS
        </th>
        <th align="left" colSpan={1}>
          <TableInput
            name="tooling_other_detail"
            type="text"
            value={moduleData.tooling_other_detail}
            onChange={(e) => {
              moduleData.tooling_other_detail = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={1}>
          CUSTOMER
        </th>
        <th align="left" colSpan={1} style={{ width: '200px' }}>
          {isView ? (
            moduleData?.machine_id?.machine_no
          ) : (
            <AutocompleteMuiCustom
              id="customer_name"
              label=""
              option_name="customer_name"
              arrayofObj={customerData}
              value={moduleData.customer_id || ''}
              onChange={(e, value) => {
                moduleData.customer_id = value;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th align="left" colSpan={1}></th>
      </tr>

      <tr>
        <th align="left" colSpan={2}>
          TOOL STATUS
        </th>
        <th align="left" colSpan={1}>
          <TableInput
            name="tooling_status"
            type="text"
            value={moduleData.tooling_status}
            onChange={(e) => {
              moduleData.tooling_status = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={1}>
          INSPECTION FREQUNCY
        </th>
        <th align="left" colSpan={1}>
          <TableInput
            name="inspection_frequncy"
            type="number"
            value={moduleData.inspection_frequncy}
            onChange={(e) => {
              moduleData.inspection_frequncy = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
        <th align="left" colSpan={1}>
          CHANGE FREUENCY
        </th>
        <th align="left" colSpan={1}>
          <TableInput
            name="changed_frequncy"
            type="number"
            value={moduleData.changed_frequncy}
            onChange={(e) => {
              moduleData.changed_frequncy = +e.target.value;
              // setRender((prev) => !prev);
              calculateCumulative();
              // setModuleData(updatedData);
            }}
            isView={isView}
          />
        </th>
      </tr>

      <tr style={{ textTransform: 'uppercase' }}>
        {['S.NO', 'DATE', 'QTY', 'CUMULATIVE', 'STATUS', 'REASON'].map(
          (head) => (
            <th
              style={{
                textTransform: 'uppercase',
                minWidth: `${head === 'REASON' && '500px'}`,
              }}
              colSpan={head === 'REASON' ? 2 : 1}
            >
              {head}
            </th>
          )
        )}
      </tr>
      <tr></tr>
    </thead>
  );
};

export default THead;
