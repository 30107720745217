import { useState } from 'react';
import { TableInput } from '../../../../components/common';

import { useQuery } from '../../../../hooks/UseQuery';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import moment from 'moment';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
}
const TBody = ({ moduleData, tabValue, isView }: IProps) => {
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const [render, setRender] = useState(false);

  return (
    <tbody>
      <tr>
        <td>1</td>
        <td>Part No.</td>
        <td style={{ height: '55px' }}>{partNumber}</td>
        <td rowSpan={6}>
          <img height="100%" width="800px" src={moduleData.image} alt="image" />
        </td>
      </tr>
      <tr>
        <td>2</td>
        <td>Date</td>
        <td>
          {isView ? (
            formatDate(moduleData.date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.date)}
              onChange={(date) => {
                moduleData.date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </td>
      </tr>
      <tr>
        <td>3</td>
        <td>Leak Rate</td>
        <td>
          <TableInput
            isView={isView}
            name="leakrate"
            type="text"
            value={moduleData.leakrate}
            onChange={(e) => {
              moduleData.leakrate = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td>4</td>
        <td>S.No.</td>
        <td>
          <TableInput
            isView={isView}
            name="sno"
            type="text"
            value={moduleData.sno}
            onChange={(e) => {
              moduleData.sno = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td>5</td>
        <td>Grade Pass</td>
        <td>
          <TableInput
            isView={isView}
            name="grade_pass"
            type="text"
            value={moduleData.grade_pass}
            onChange={(e) => {
              moduleData.grade_pass = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td>6</td>
        <td>Character Length</td>
        <td>
          <TableInput
            isView={isView}
            name="charecter_lenght"
            type="text"
            value={moduleData.charecter_lenght}
            onChange={(e) => {
              moduleData.charecter_lenght = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
    </tbody>
  );
};

export default TBody;
