import { Box } from '@mui/material';
import { useState } from 'react';
import { AddButton } from '../../components/common/button/CustomButtons';
import ModalCustom from '../../components/common/Modal';
import ChildHeader from '../../components/ui/ChildHeader';
import { useAuth } from '../../services/auth/AuthProvider';
import CreateDrawing from './components/CreateDrawing';
import DrawingManagementList from './components/DrawingManagementList';
import ViewFile from './components/ViewFile';

const TableList = () => {
  const { state } = useAuth();
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);

  return (
    <Box>
      <ChildHeader text="Drawing Management">
        {state.user?.userType === 2 && (
          <AddButton label="New Drawing" onClick={() => setIsOpenPopup(true)} />
        )}
      </ChildHeader>
      <DrawingManagementList
        {...{ isOpenPopup, setIsFileOpenPopup, setIsOpenPopup, setId }}
      />
      <ModalCustom
        title="CREATE NEW DRAWING"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
          setId(null);
        }}
      >
        <CreateDrawing {...{ id, setId, setIsOpenPopup }} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} />
      </ModalCustom>
    </Box>
  );
};

export default TableList;
