import { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { Loader } from '../../../components/common/Loader';
import ModalCustom from '../../../components/common/Modal';
import PickStorePart from './components/PickStorePart';
import {
  ClearButton,
  SearchButton,
} from '../../../components/common/button/CustomButtons';
import Overview from './components/overview/create/Overview';
import OverviewReport from './components/overviewReport/create/OverviewReport';
const Create = () => {
  const [moduleData, setModuleData] = useState<any>({});
  const [Data, setData] = useState<any>({});

  const [render, setRender] = useState(false);
  const isView = useQuery('isView') === 'true' ? true : false;
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const [display, setDisplay] = useState<any>([]);
  const overview = () => {
    if (Data?.partNumber) {
      const searchPartNumber = Data?.partNumber;

      const dis = moduleData.filter(
        (part: any) =>
          searchPartNumber === part?.store_part_id?.ri_part_id?.part_number
      );
      setDisplay(dis);
    }
  };
  useEffect(() => {
    // const wings = ['A', 'B', 'C'];
    axios
      .get(`/api/storeSetup`)
      .then(async (res) => {
        const data = res.data;
        if (data) {
          setModuleData(data);
        }
      })
      .catch((err) => console.log(err));
  }, [render, tabValue]);

  // const onSumbit = async () => {
  //   const ans = await confirmSubmit();
  //   if (!ans) return;
  //   setIsLoading(true);
  //   await axios
  //     .put(`/api/storeSetup`, moduleData)
  //     .then((res) => {
  //       if (res.data.status === 'success') {
  //         setRender((prev) => !prev);
  //         setIsLoading(false);
  //         alert('Updated Successfully');
  //       }
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       console.log(err);
  //     });
  // };

  return (
    <>
      <Loader loader={isLoading} />
      <Box>
        <ChildHeader text="STORE OVERVIEW">
          <ClearButton
            label="CLEAR ALL"
            onClick={async () => {
              setDisplay([]);
              setData({});
            }}
          />
          <SearchButton label="SEARCH" onClick={() => setIsOpenPopup(true)} />
        </ChildHeader>
      </Box>

      <Overview Data={display} />
      <OverviewReport Data={display} />
      <ModalCustom
        title="STORE PART LIST"
        openModal={isOpenPopup}
        closeModal={() => {
          overview();
          setIsOpenPopup(false);
        }}
      >
        <PickStorePart moduleData={Data} />
      </ModalCustom>
    </>
  );
};

export default Create;
