// import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../components/common/formatDate';
import { TableInput } from '../../../../../components/common';
import AutocompleteMuiCustom from '../../../../../components/common/AutocompleteMuiCustom';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const numberCol = Array.from({ length: 31 }, (_, index) => index + 1);

const theadData = [
  'Sr.No.',
  'POKA YOKE NAME',
  'MACHINE NAME & (LOCATION)',
  'METHOD OF VERIFICATION',
  'PHOTOGRAPH  OF POKA YOKE ',
  ...numberCol,
  'Remarks',
];
// colspan 38

const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const annual_month = useQuery('annual_date') || '';
  return (
    <thead>
      <TableCompHead
        colSpan={38}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="POKA YOKE VERIFICATION CHECK SHEET"
      />
      <tr>
        <th colSpan={2} align="left">
          Date :{' '}
        </th>
        <th colSpan={5} align="left">
          {isView ? (
            formatDate(moduleData.pokeYoke_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.pokeYoke_date)}
              onChange={(date) => {
                moduleData.pokeYoke_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={24}></th>
        <th colSpan={2} align="left">
          Month:
        </th>
        <th colSpan={5} align="left">
          {moduleData.annual_month || annual_month}
        </th>
      </tr>
      <tr>
        {theadData.map((heading, index) => (
          <th key={heading} colSpan={heading === 'Remarks' ? 2 : 1}>
            {heading}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
