import { Box } from '@mui/material';
import { RenderTable } from '../../../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';
interface IProps {
  apiData: any;
  year: number;
  month: number;
  target: number;
}
const TController = ({ apiData, year, month, target }: IProps) => {
  return (
    <Box>
      <RenderTable>
        <>
          <THead year={year} month={month} />
          <TBody apiData={apiData} target={target} />
        </>
      </RenderTable>
    </Box>
  );
};

export default TController;
