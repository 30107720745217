import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import ObservationInput from './ObservationInput';
import CustomSelect from '../../../../../../components/common/SelectField';
import { Checkbox, IconButton, MenuItem, Tooltip } from '@mui/material';
import { handleChangeColorOnRemarks } from '../../helpers/handleChangeColorOnRemarks';
import DeleteIcon from '@mui/icons-material/Delete';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
}
const TBody = ({ moduleData, tabValue, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData.pdirs[tabValue] &&
        moduleData.pdirs[tabValue].pdir_list &&
        moduleData.pdirs[tabValue].pdir_list.map((spec: any, index: number) => (
          <tr key={index + 'specs'}>
            <td>{index + 1}</td>
            <td style={{ minWidth: '200px' }}>{spec.parameter}</td>
            <td style={{ minWidth: '300px' }}>{spec.specification}</td>
            <td style={{ minWidth: '150px' }}>{spec.methods}</td>
            <td style={{ minWidth: '250px' }}>{spec.methods_by}</td>
            {Array.from({ length: 5 }, (_, obsIndex) => (
              <td
                key={obsIndex + 'obs'}
                style={{ textTransform: 'uppercase', minWidth: '100px' }}
              >
                <ObservationInput
                  {...{ spec, setRender, isView, obsIndex }}
                  obsNumber={0}
                  checkType={spec.appearance}
                />
              </td>
            ))}
            <td style={{ textTransform: 'uppercase', minWidth: '150px' }}>
              {spec.appearance === 'check3' ? (
                isView ? (
                  spec.result
                ) : (
                  <CustomSelect
                    name="dropdown"
                    value={spec.result || ''}
                    onChange={(e) => {
                      spec.result = e.target.value;
                      setRender((prev) => !prev);
                    }}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="ok">OK</MenuItem>
                    <MenuItem value="ng">NG</MenuItem>
                  </CustomSelect>
                )
              ) : (
                spec.result
              )}
            </td>
            <th
              colSpan={isView ? 2 : 1}
              style={{ textTransform: 'uppercase', minWidth: '150px' }}
            >
              {isView && spec.remarks}
              {!isView && (
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={spec.remarks}
                  onChange={(e) => {
                    spec.remarks = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </th>
            {!isView && (
              <td>
                <Checkbox
                  name="changeCheckbox"
                  checked={spec.appearance === 'check3'}
                  onChange={() => {
                    if (spec.prev_appearance) {
                      spec.appearance = spec.prev_appearance;
                      spec.prev_appearance = null;
                    } else {
                      spec.prev_appearance = spec.appearance;
                      spec.appearance = 'check3';
                    }
                    setRender((prev) => !prev);
                  }}
                  style={{ color: '#064a29' }}
                />
              </td>
            )}
          </tr>
        ))}
      <tr>
        <th colSpan={13} align="left">
          Supplier Remarks :-
          <TableInput
            name="supplier_remarks"
            type="text"
            value={moduleData?.pdirs[tabValue]?.supplier_remarks}
            onChange={(e) => {
              moduleData.pdirs[tabValue].supplier_remarks = e.target.value;
              setRender((prev) => !prev);
            }}
            isView={isView}
          />
        </th>
      </tr>
    </tbody>
  );
};

export default TBody;
