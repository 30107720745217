import React, { useState } from 'react';
import { TableInput } from '../../../../../components/common';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../../components/common/SelectField';

// const shiftResult = {
//   true: '✓',
//   false: '⨯',
// };

interface IProps {
  moduleData: any;
  isView: boolean;
  process: any;
  index: number;
  // handelDeleteRows: any;
  // setIsOpenPopup: any;
  // setPartIdx: any;
}
const TBodyRow = ({
  moduleData,
  isView,
  process,
  index,
}: // handelDeleteRows,
// setIsOpenPopup,
// setPartIdx,
IProps) => {
  const [render, setRender] = useState(false);

  return (
    <React.Fragment key={index + 10.1 + 'heading '}>
      <tr>
        <th rowSpan={2} style={{ minWidth: '50px', textAlign: 'center' }}>
          {index + 1}
        </th>
        <td rowSpan={2} style={{ minWidth: '200px', textAlign: 'center' }}>
          {process.point}
        </td>
        <td rowSpan={2} style={{ minWidth: '200px', textAlign: 'center' }}>
          {process.checking_method}
        </td>
        <td style={{ minWidth: '100px', textAlign: 'center' }}>
          {process.shift_A}
        </td>
        {Array.from({ length: 31 }, (_, indx) =>
          process.point === 'VERIFIED BY' ? (
            <td key={index + 2.2 + indx + 'after' + 111.33}>
              <TableInput
                isView={isView}
                name="verified_by"
                type="text"
                value={process.shift_A_results[indx]}
                onChange={(e) => {
                  process.shift_A_results[indx] = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          ) : (
            <td
              style={{
                minWidth: '100px',
                textAlign: 'center',
                fontSize: '2rem',
              }}
              key={index + 2.2 + indx + 'after' + 111.33}
            >
              {isView ? (
                (process.shift_A_results[indx] === 'true' && '✓') ||
                (process.shift_A_results[indx] === 'false' && '⨯')
              ) : (
                <CustomSelect
                  name="shift_A_results[indx]"
                  value={process.shift_A_results[indx]}
                  onChange={(e) => {
                    process.shift_A_results[indx] = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="true">✓</MenuItem>
                  <MenuItem value="false">⨯</MenuItem>
                </CustomSelect>
              )}
            </td>
          ),
        )}

        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="remarks_1"
            type="text"
            value={process.remarks_1}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
      <tr>
        <td style={{ minWidth: '100px', textAlign: 'center' }}>
          {process.shift_B}
        </td>
        {Array.from({ length: 31 }, (_, indx) =>
          process.point === 'VERIFIED BY' ? (
            <td key={index + 2.2 + indx + 'after' + 111.33}>
              <TableInput
                isView={isView}
                name="verified_by"
                type="text"
                value={process.shift_B_results[indx]}
                onChange={(e) => {
                  process.shift_B_results[indx] = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          ) : (
            <td
              style={{
                minWidth: '100px',
                textAlign: 'center',
                fontSize: '2rem',
              }}
              key={index + 2.2 + indx + 'after' + 1111.33}
            >
              {isView ? (
                (process.shift_B_results[indx] === 'true' && '✓') ||
                (process.shift_B_results[indx] === 'false' && '⨯')
              ) : (
                <CustomSelect
                  name="shift_B_results[indx]"
                  value={process.shift_B_results[indx]}
                  onChange={(e) => {
                    process.shift_B_results[indx] = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="true">✓</MenuItem>
                  <MenuItem value="false">⨯</MenuItem>
                </CustomSelect>
              )}
            </td>
          ),
        )}
        <td style={{ minWidth: '150px', textAlign: 'center' }}>
          <TableInput
            isView={isView}
            name="remarks_2"
            type="text"
            value={process.remarks_2}
            onChange={(e) => {
              handleChangeModuleData(e, process);
              setRender((prev) => !prev);
            }}
          />
        </td>
      </tr>
    </React.Fragment>
  );
};

export default TBodyRow;
