import {
  Box,
  MenuItem,
  Paper,
  SelectChangeEvent,
  Tab,
  Tabs,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { PersitedDatePicker } from '../../../components/common';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ModalCustom from '../../../components/common/Modal';
import { SupervisorPartTable } from '../../../components/ui';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import DefectWiseQty from './components/dashboard/DefectWiseQty/DefectWiseQty';
import Type from './components/dashboard/typeOfComplaint/Type';
import DefectWisePpm from './components/dashboard/defectWisePPM/DefectWisePpm';
import CustomerPpm from './components/dashboard/customerPPM/CustomerPpm';
import PartWiseTop from './components/dashboard/partWiseTop/PartWiseTop';
import MonthlyPpm from './components/dashboard/monthlyPPM/MonthlyPpm';
import GridInputs from '../../../components/common/inputs/GridInputs';
import CustomSelect from '../../../components/common/SelectField';
const statusObj = {
  pending: 1,
  accepted: 2,
  rejected: 3,
};

const RejectionAnalysisDashboardTabs = () => {
  const { state } = useAuth();
  const { user } = state;
  const date = useQuery('date') || '';
  const [tabValue, setTabValue] = useState(0);
  const [tableData, setTableData] = useState<any[]>([]);
  const [data, setData] = useState<any>({});
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [defectsFrequency, seteDefectsFrequency] = useState([]);
  const [type, setType] = useState({
    totalOfDomesticPerMonth: {},
    totalOfExportsPerMonth: {},
  });
  const [defects, setDefects] = useState({
    defectsQtyArr: [],
    customerRejectionQtyArr: [],
  });
  const [reasonCount, setReasonCount] = useState({ reasonCounts: [] });
  const [rejectionMonthlyCount, setRejectionMonthlyCount] = useState({
    rejectionMonthlyCounts: [],
  });
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState('');
  const [reason, setReason] = useState('');
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];
  const fetchData = async () => {
    if (date) {
      if (tabValue == 0) {
        const res = await axios
          .get(
            `/api/rejectionNoteRoute/getByDefectsFrequencyMonthly/?date=${date}`
          )
          .catch((err) => {
            console.log(err);
          });
        setDefects(res?.data);
        setYear(new Date(date).getFullYear());
        setMonth(monthNames[new Date(date).getMonth()]);
      } else if (tabValue == 2) {
        const res = await axios
          .get(
            `/api/rejectionNoteRoute/dashboard/getByReasonMonthly/?date=${date}&reason=${reason}`
          )
          .catch((err) => {
            console.log(err);
          });
        console.log('HHHHHHHHHHHHHHHHH', res?.data);
        setReasonCount(res?.data);
        setYear(new Date(date).getFullYear());
      } else if (tabValue == 4) {
        const res = await axios
          .get(
            `/api/rejectionNoteRoute/dashboard/getByReasonFinancialYear/?date=${date}`
          )
          .catch((err) => {
            console.log(err);
          });
        console.log('HHHHHHHHHHHHHHHHH', res?.data);
        setRejectionMonthlyCount(res?.data);
        setYear(new Date(date).getFullYear());
      } /*else {
        const res = await axios
          .get(`/api/customerConcernRoutes/getByDefects/?date=${date}`)
          .catch((err) => {
            console.log(err);
          });
        console.log('res', res);
        setDefects(res?.data);
        setYear(new Date(date).getFullYear());
      }*/
    }
    //setPerPage(res?.data?.perPage);
    //setTotalPages(res?.data?.pages);
  };
  useEffect(() => {
    fetchData();
  }, [date, tabValue, reason]);

  /*useEffect(() => {
    // fetch data here
    let userType = '';
    if (state.user?.userType === 2) {
      userType = 'admin';
    } else {
      userType = 'supervisor';
    }
    axios
      .get(`/api/pir/monthly/${userType}/${date}`)
      .then((res) => {
        if (res.data) setTableData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render, date]);*/

  /*const deleteDocument = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?'
    );
    if (isDeleted) {
      axios
        .delete(`/api/pir/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  const updateStatus = async (id: string, documentIndex: number) => {
    let isConfirm = window.confirm('Are you sure you want to update status');
    if (isConfirm && tableData.length > 0) {
      axios
        .patch(`/api/pir/updateStatus/${id}`, {
          is_admin_request_no: tableData[documentIndex].is_admin_request_no,
          report_approved_by: state.user?.name,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Status Change successfully');
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleChangeStatus = (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number
  ) => {
    const values = [...tableData];
    values[documentIndex].is_admin_request = null;
    values[documentIndex].is_admin_request_no = e.target.value;
    setTableData(values);
  };*/
  console.log('year', year);
  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }
  const { defectsQtyArr, customerRejectionQtyArr } = defects;
  console.log('%%%%%%%%%%%%%%%%%%', data);
  return (
    <Box>
      <ChildHeader text="REJECTION ANALYSIS DASHBOARD"></ChildHeader>
      <Paper elevation={2}>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          scrollButtons="auto"
          textColor="secondary"
          variant="fullWidth"
        >
          <Tab label="Defects Wise -Qty" />
          <Tab label="Defects Wise -PPM" />
          <Tab label="Part Wise -TOP" />
          <Tab label="Customer PPM" />
          <Tab label="Monthly PPM" />
        </Tabs>
      </Paper>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker
          label="Search By Month"
          views={tabValue <= 3 ? ['month', 'year'] : ['year']}
        />
      </Box>
      {
        <section>
          {(tabValue === 1 || tabValue === 3 || tabValue === 4) && (
            <Box sx={{ margin: 2 }}>
              <GridInputs
                id={'total_produced_qty'}
                name={'total_produced_qty'}
                html_for={'total_produced_qty'}
                label_name={'Total Produced Qty.'}
                input_type={'number'}
                focusType={'onblur'}
                value={data?.total_produced_qty || ''}
                onChange={handleCustomerData}
              />
            </Box>
          )}
          {tabValue === 4 && (
            <Box sx={{ margin: 2 }}>
              <GridInputs
                id={'target'}
                name={'target'}
                html_for={'target'}
                label_name={'Target'}
                input_type={'number'}
                focusType={'onblur'}
                value={data?.target || ''}
                onChange={handleCustomerData}
              />
            </Box>
          )}
          {tabValue === 2 && (
            <Box sx={{ margin: 2, maxWidth: '300px' }}>
              <h3>Reasons</h3>
              <CustomSelect
                name="reason"
                value={reason}
                onChange={(e: any) => {
                  setReason(e.target.value);
                  //setRender((prev) => !prev);
                }}
              >
                <MenuItem value="">None</MenuItem>
                {[
                  'Bead Over Size',
                  'Bead Under Size',
                  'Bend',
                  'Bending NG',
                  'Black Spot',
                  'Blockage',
                  'Barcode  NG',
                  'Bracket NG',
                  'Burr/ Chips',
                  'Coating  NG',
                  'Clamp Out',
                  'Collapses',
                  'Connector Damage',
                  'Crack',
                  'Damage',
                  'Dent',
                  'Double Punching',
                  'Extra Material',
                  'Face Dent',
                  'Face Taper',
                  'Face Uneven',
                  'Fitment Out',
                  'Flaring NG',
                  'Flaring Short/ Over Size/Crack',
                  'Gap',
                  'No Go Pass',
                  'Hose Cut',
                  'Hose Leakage',
                  'Hose NG',
                  'Hose Shift',
                  'Leakage',
                  'Leakage From Brazing',
                  'Leakage Near Clamp',
                  'Lining',
                  'Line On Pipe',
                  'Miss Printing/NG',
                  'Miss Brazing',
                  'Nut Damage',
                  'Nut Dent',
                  'Nut Jaam',
                  'Nut Tight',
                  'Orientation Out',
                  'Oring Area Damage',
                  'Ovality',
                  'Over Brazing',
                  'Over Length',
                  'Over Size',
                  'Paraflaring NG',
                  'Paraflaring Over Size',
                  'Pin Hole',
                  'Pin Out',
                  'Pipe Damage',
                  'Poor Plating',
                  'Punching NG',
                  'Reverse Nut',
                  'Rubber Cut',
                  'Rust',
                  'Short Brazing',
                  'Short Length',
                  'Sleeve Loose',
                  'Sleeve NG',
                  'Small Hole',
                  'Taper',
                  'Tapper',
                  'Thread Tight',
                  'T-Marking Missing',
                  'Tool Mark',
                  'Under Size',
                  'Welding NG',
                  'With Out Bracket',
                  'Wrinkle',
                  'Wrong Nut',
                ].map((reason: string) => (
                  <MenuItem value={reason}>{reason}</MenuItem>
                ))}
              </CustomSelect>
            </Box>
          )}
          {tabValue === 0 && (
            <DefectWiseQty apiData={defectsQtyArr} year={year} month={month} />
          )}
          {tabValue === 1 && (
            <DefectWisePpm
              apiData={defectsQtyArr}
              year={year}
              month={month}
              data={data}
            />
          )}
          {tabValue === 2 && (
            <PartWiseTop
              reason={reason}
              apiData={reasonCount}
              year={year}
              month={''}
              data={undefined}
            />
          )}
          {tabValue === 3 && (
            <CustomerPpm
              apiData={customerRejectionQtyArr}
              year={year}
              month={month}
              data={data}
            />
          )}
          {tabValue === 4 && (
            <MonthlyPpm
              apiData={rejectionMonthlyCount}
              year={year}
              data={data}
            />
          )}
        </section>
      }
    </Box>
  );
};

export default RejectionAnalysisDashboardTabs;
