interface Ifoot {
  preparedBy: string | null | undefined;
  approvedBy: string | null | undefined;
}
const TFoot = ({ preparedBy, approvedBy }: Ifoot) => {
  return (
    <tfoot>
      <tr>
        <th>Operator Sign</th>
        <th></th>
        <th>Verified Sign</th>
        <th></th>
      </tr>
    </tfoot>
  );
};

export default TFoot;
