import React, { useState } from 'react';
import { HeaderTabs } from './components/create/HeaderTabs';
import { BodyContent } from './components/create/BodyContent';

const Create = () => {
  const [process, setProcess] = useState('');

  return (
    <div>
      <HeaderTabs />
      <BodyContent />
    </div>
  );
};

export default Create;
