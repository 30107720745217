import { Box } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';

import { PersitedDatePicker } from '../../../components/common';
import { AddButton } from '../../../components/common/button/CustomButtons';
import ModalCustom from '../../../components/common/Modal';
import { SupervisorPartTable } from '../../../components/ui';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import { useAuth } from '../../../services/auth/AuthProvider';
import AdminTable from './components/tableList/AdminTable';
import SupervisorTable from './components/tableList/SupervisorTable';

const statusObj = {
  pending: 1,
  accepted: 2,
  rejected: 3,
};

const TableList = () => {
  const { state } = useAuth();
  const { user } = state;
  const date = useQuery('date');
  const [tableData, setTableData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  useEffect(() => {
    // fetch data here
    let userType = '';
    if (state.user?.userType === 2) {
      userType = 'admin';
    } else {
      userType = 'supervisor';
    }
    axios
      .get(`/api/pir/monthly/${userType}/${date}`)
      .then((res) => {
        if (res.data) setTableData(res.data);
      })
      .catch((err) => console.log(err));
  }, [render, date]);

  const deleteDocument = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?'
    );
    if (isDeleted) {
      axios
        .delete(`/api/pir/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  const updateStatus = async (id: string, documentIndex: number) => {
    let isConfirm = window.confirm('Are you sure you want to update status');
    if (isConfirm && tableData.length > 0) {
      axios
        .patch(`/api/pir/updateStatus/${id}`, {
          is_admin_request_no: tableData[documentIndex].is_admin_request_no,
          report_approved_by: state.user?.name,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            alert('Status Change successfully');
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleChangeStatus = (
    e: React.ChangeEvent<HTMLSelectElement>,
    documentIndex: number
  ) => {
    const values = [...tableData];
    values[documentIndex].is_admin_request = null;
    values[documentIndex].is_admin_request_no = e.target.value;
    setTableData(values);
  };
  return (
    <Box>
      <ChildHeader text="PIR REPORT">
        <AddButton
          label="NEW PIR REPORT"
          onClick={() => setIsOpenPopup(true)}
        />
      </ChildHeader>
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box>
      {state.user?.userType === 2 && (
        <AdminTable
          {...{
            handleChangeStatus,
            updateStatus,
            deleteDocument,
            statusObj,
            tableData,
          }}
        />
      )}
      {state.user?.userType === 3 && (
        <SupervisorTable {...{ deleteDocument, tableData }} />
      )}
      <ModalCustom
        title="Choose Part"
        openModal={isOpenPopup}
        closeModal={() => {
          setIsOpenPopup(false);
        }}
      >
        <SupervisorPartTable link={`/common/qualityPir`} />
      </ModalCustom>
    </Box>
  );
};

export default TableList;
