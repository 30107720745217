export const listConstant = {
  childHeader: 'RAW MATERIAL TEST PLAN list',
  addButtonLable: 'New TEST PLAN',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'RAW MATERIAL TEST PLAN list',
  theadHeader: 'RAW MATERIAL TEST PLAN - BOP',
  extracolSpan: 2,
};

export const checkListList = {
  childHeader: 'Fixtures STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'rawMaterialTestPlan',
  gettableDataurl: 'rawMaterialTestPlan/monthly',
  getPlanByid: 'rawMaterialTestPlan',
  createPlanByid: 'rawMaterialTestPlan',
  updatePlanByid: 'rawMaterialTestPlan',
  sync: 'rawMaterialTestPlan/handleSync',
  getAllCheckList: 'rawMaterialTestPlan/getAllCheckList',
  checkListStatus: 'rawMaterialTestPlan/handleStatus',
  checklistUpload: 'rawMaterialTestPlan/handleUpload',
  checklistDeleteImage: 'rawMaterialTestPlan/handleDeleteImage',
};

export const tilesConstant = {
  childHeaderText: 'RAW MATERIAL TEST PLAN',
};

export const navigationConstant = {
  createPlanSchedule: '/common/RawMaterialPlanPlanCreate',
};
