import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import { SubmitButton } from '../../../components/common/button/CustomButtons';
import InputMuiCustom from '../../../components/common/inputs/InputMuiCustom';
import FormControlMui, {
  StyledField,
} from '../../../components/common/FormControlMui';
import useConfirm from '../../../components/common/useConfirm';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';
import { useQuery } from '../../../hooks/UseQuery';
interface Iprop {
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>;
  id?: string | object | null;
  setId: React.Dispatch<React.SetStateAction<string | null>>;
}

interface IData {
  _id?: string;
  title?: string;
  createdAt?: Date;
}
const CreateDocument = ({ setIsOpenPopup, id, setId }: Iprop) => {
  const [data, setData] = useState<IData>({});
  const [isLoading, setIsLoading] = useState(false);
  const [files, setFiles] = useState(null);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'SUBMIT DOCUMENT',
    `Are you sure you want to ${id ? 'update' : 'save'} this document?`,
  );

  const handlePartData = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (id) {
      axios
        .get(`/api/listOfSlideShow/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      setData((prev) => prev);
    }
  }, [id]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const ans = await confirmSubmit();
    if (!ans) return;
    let formData = new FormData();
    setIsLoading(true);
    if (data.title) formData.append('title', data.title);
    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }
    if (id) {
      await axios
        .put(`/api/listOfSlideShow/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsOpenPopup(false);
          setId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    } else {
      await axios
        .post('/api/listOfSlideShow', formData)
        .then((res) => {
          setIsLoading(false);
          setIsOpenPopup(false);
          setId(null);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />
      <Box>
        <FormControlMui handleSubmit={onSubmit} style={{ height: '200px' }}>
          <div
            className="btn_save"
            style={{
              position: 'absolute',
              right: '140px',
              top: '-0.6px',
            }}
          >
            <SubmitButton
              label={`${id ? 'update' : 'submit'}`}
              type="submit"
              disabled={isLoading}
            />
          </div>
          <StyledField>
            <InputMuiCustom
              type="text"
              name="title"
              placeholder="Title"
              onChange={handlePartData}
              value={data?.title || ''}
            />
            <CustomUploadButton
              lable={'UPLOAD IMAGE'}
              multiple
              accept="image/png,image/jpeg,image/jpg"
              onChange={(e) => setFiles(e.target.files)}
            />
          </StyledField>
        </FormControlMui>
      </Box>
      <DialogSubmit />
    </>
  );
};

export default CreateDocument;
