export const listConstant = {
  childHeader: 'ATTRIBUTE plan list',
  addButtonLable: 'New ATTRIBUTE',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'ATTRIBUTE plan list',
  theadHeader: 'Attribute Gauges Plan for MSA',
  extracolSpan: 6,
};

export const checkListList = {
  childHeader: 'ATTRIBUTE STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'attributeGaugesRoute',
  gettableDataurl: 'attributeGaugesRoute/monthly',
  getPlanByid: 'attributeGaugesRoute',
  createPlanByid: 'attributeGaugesRoute',
  updatePlanByid: 'attributeGaugesRoute',
  sync: 'attributeGaugesRoute/handleSync',
  getAllCheckList: 'attributeGaugesRoute/getAllCheckList',
  checkListStatus: 'attributeGaugesRoute/handleStatus',
  checklistUpload: 'attributeGaugesRoute/handleUpload',
  checklistDeleteImage: 'attributeGaugesRoute/handleDeleteImage',
};

export const navigationConstant = {
  createPlanSchedule: '/common/attributeGaugescreate',
};
