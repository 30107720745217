import React, { useEffect, useState } from 'react';
import GridInputs from '../../components/common/inputs/GridInputs';
import axios from 'axios';
import { SubmitButton } from '../../components/common/button/CustomButtons';
import useConfirm from '../../components/common/useConfirm';
import GridDatePicker from '../../components/common/inputs/GridDatePicker';
import AutocompleteMuiCustom from '../../components/common/AutocompleteMuiCustom';
import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import GridSelect from '../../components/common/inputs/GridSelect';
import CustomUploadButton from '../../components/common/button/CustomUploadButton';
import { Loader } from '../../components/common/Loader';
import moment from 'moment';
import formatDate from '../../components/common/formatDate';
import DatePickerMui from '../../components/common/DatePickerMui';
import NotesTextfield from '../../components/common/NotesTextfield';
import { StyledNotesField } from '../../components/common/FormControlMui';
import { Description } from '@mui/icons-material';
import DescriptionTextfield from '../../components/common/DescriptionNotes';
import { useAuth } from '../../services/auth/AuthProvider';
import MushinRemarksTextField from '../../components/common/MushinRemarksTextFieldNotes';
const Create = ({
  id,
  setIsModal,
  disable,
}: {
  id?: string | null;
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>;
  disable: boolean;
}) => {
  const { state } = useAuth();
  // console.log(state,'dfdfgdfh')
  const [data, setData] = useState<any>({});

  const [render, setRender] = useState(false);

  const [files, setFiles] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [DialogSubmit, confirmSubmit] = useConfirm(
    `${id ? 'Update Vendor' : 'Create Ventor'}`,
    `Are you sure you want to ${id ? 'update' : 'save'} this?`,
  );
  useEffect(() => {
    if (id) {
      axios
        .get(`/api/mushinHelpDesk/${id}`)
        .then((res) => {
          setData(res.data);
        })
        .catch((err) => console.log(err));
    } else {
      data.user_id = state?.user?.username;
      data.date_of_complaint = moment().format('DD-MM-YYYY');
      data.status = 'OPEN';
    }
  }, [id]);

  function handleCustomerData(
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent<unknown>,
  ) {
    setData((prev: any) => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleSumbit = async () => {
    const ans = await confirmSubmit();
    if (!ans) return;
    setIsLoading(true);
    const formData = new FormData();

    for (const key in data) {
      if (data[key]) {
        if (key !== 'files') formData.append(key, data[key]);
      }
    }
    if (files) {
      for (const key of Object.keys(files)) {
        formData.append('files', files[key]);
      }
    }
    if (id) {
      await axios
        .put(`/api/mushinHelpDesk/${id}`, formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => console.log(err));
    } else {
      await axios
        .post('/api/mushinHelpDesk', formData)
        .then((res) => {
          setIsLoading(false);
          setIsModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  return (
    <>
      <Loader loader={isLoading} />

      <GridInputs
        id={'user_id'}
        name={'user_id'}
        html_for={'user_id'}
        label_name={'USER ID:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.user_id || ''}
        // onChange={handleCustomerData}
        disabled={true}
      />

      <GridInputs
        id={'date_of_complaint'}
        name={'date_of_complaint'}
        html_for={'date_of_complaint'}
        label_name={'Date Of Complaint:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.date_of_complaint}
        // onChange={handleCustomerData}
        disabled={true}
      />

      <GridInputs
        id={'problem'}
        name={'problem'}
        html_for={'problem'}
        label_name={'Problem Claue/Document:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.problem || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />
      <GridInputs
        id={'url'}
        name={'url'}
        html_for={'url'}
        label_name={'URL Address:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.url || ''}
        onChange={handleCustomerData}
        disabled={disable}
      />

      <StyledNotesField>
        <DescriptionTextfield
          onChange={handleCustomerData}
          value={data.description || ''}
        />
      </StyledNotesField>
      <StyledNotesField>
        <MushinRemarksTextField
          onChange={handleCustomerData}
          value={data?.mushin_remarks || ''}
        />
      </StyledNotesField>
      <GridSelect
        name="status"
        label_name="STATUS (OPEN/WORK IN PROGRESS/CLOSED):"
        html_for={'status'}
        value={data.status || ''}
        onChange={(e) => {
          data.status = e.target.value;
          if (data.status === 'CLOSED') {
            data.closure_date = moment().format('DD-MM-YYYY');
            data.closed_by = state?.user?.username;
          } else {
            data.closure_date = '';
            data.closed_by = '';
          }
          setRender((prev) => !prev);
        }}
        disabled={disable}
      >
        <MenuItem disabled={disable} value="OPEN">
          OPEN
        </MenuItem>
        <MenuItem disabled={disable} value="WORK IN PROGRESS">
          WORK IN PROGRESS
        </MenuItem>
        <MenuItem disabled={disable} value="UNDER REVIEW">
          UNDER REVIEW
        </MenuItem>
        <MenuItem disabled={disable} value="CLOSED">
          CLOSED
        </MenuItem>
      </GridSelect>
      <GridInputs
        id={'closed_by'}
        name={'closed_by'}
        html_for={'closed_by'}
        label_name={'CLOSED BY:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.closed_by || ''}
        // onChange={handleCustomerData}
        disabled={true}
      />
      <GridInputs
        id={'closure_date'}
        name={'closure_date'}
        html_for={'closure_date'}
        label_name={'Closure Date:'}
        input_type={'text'}
        focusType={'onblur'}
        value={data?.closure_date}
        // onChange={handleCustomerData}
        disabled={true}
      />

      {!disable && (
        <Grid item xs={3}>
          <CustomUploadButton
            lable={'UPLOAD DOCUMENT'}
            multiple
            accept="image/png,image/jpeg,image/jpg,application/pdf"
            onChange={(e) => setFiles(e.target.files)}
            // disabled={disable}
          />{' '}
        </Grid>
      )}
      {!disable && (
        <div
          className="btn_save"
          style={{
            position: 'absolute',
            right: '140px',
            top: '-0.6px',
          }}
        >
          <SubmitButton
            label={`${id ? 'update' : 'submit'}`}
            type="button"
            onClick={handleSumbit}
          />
        </div>
      )}
      <DialogSubmit />
    </>
  );
};

export default Create;
