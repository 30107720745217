import React, { useEffect, useState } from 'react';
import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import {
  CustomPagination,
  PersitedDatePicker,
  SearchBar,
  TableCustom,
} from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  AddButton,
  CustomButton,
} from '../../../components/common/button/CustomButtons';
import axios from 'axios';
import ModalCustom from '../../../components/common/Modal';
import useConfirm from '../../../components/common/useConfirm';
import CreateDocument from './components/CreateDocument';
import { useQuery } from '../../../hooks/UseQuery';
import formatDate from '../../../components/common/formatDate';
import {
  DeleteIconButton,
  EditIconButton,
} from '../../../components/common/button/CustomIconButton';
import { MdAssignmentTurnedIn } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import useDebounce from '../../../hooks/useDebounce';

interface IData {
  _id?: string;
  store_part_id?: any;
  date_of_issue?: Date;
  qty: number;
  issue_area: string[];
  isCompleted: boolean;
}
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'PART NO.', rowspan: 1, colspan: 1 },
    { text: 'DATE OF ISSUE', rowspan: 1, colspan: 1 },
    { text: 'QTY', rowspan: 1, colspan: 1 },
    { text: 'ISSUE AREA', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const [render, setRender] = useState(false);
  const [id, setId] = useState<string | null>(null);
  const page = useQuery('page') || 1;
  const date = useQuery('date');
  const search = useQuery('search');
  const [totalPages, setTotalPages] = useState(0);
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE ',
    'Are you sure you want to delete this document?',
  );
  const navigate = useNavigate();
  const fetchData = () => {
    axios
      .get(`/api/storeIssueRegister?page=${page}&search=${search}&date=${date}`)
      .then((res) => {
        setData(res?.data?.result);
        setTotalPages(res?.data?.pages);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useDebounce(fetchData, 500, [render, page, date, isOpenPopup, search]);
  const deleteDocument = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    axios
      .delete(`/api/storeIssueRegister/${id}`)
      .then((res) => {
        if (res.data) {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Box>
      <ChildHeader text="ISSUE REGISTER">
        <AddButton label="New Entry" onClick={() => setIsOpenPopup(true)} />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search" />
        <PersitedDatePicker label="Search by month" views={['year', 'month']} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((document: IData, index: number) => (
              <React.Fragment key={document?._id}>
                <TableRow>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">
                    {document.store_part_id?.ri_part_id?.part_number}
                  </TableCell>
                  <TableCell align="center">
                    {formatDate(document?.date_of_issue || '')}
                  </TableCell>
                  <TableCell align="center">{document?.qty}</TableCell>
                  <TableCell align="center">
                    {document?.issue_area.join('__')}
                  </TableCell>
                  <TableCell align="center">
                    <CustomButton
                      color="primary"
                      size={'small'}
                      disabled={document.isCompleted}
                      sx={{
                        margin: '10px',
                      }}
                      icon={<MdAssignmentTurnedIn />}
                      onClick={() =>
                        navigate(
                          `/common/storeSetupCreate?storeIssueId=${document._id}&partNo=${document.store_part_id?.ri_part_id?.part_number}&riPartId=${document.store_part_id?.ri_part_id?._id || ''}`,
                        )
                      }
                    >
                      ISSUE
                    </CustomButton>
                    <EditIconButton
                      tooltipTitle="edit"
                      onClick={() => {
                        setId(document._id || '');
                        setIsOpenPopup(true);
                      }}
                    />
                    <DeleteIconButton
                      tooltipTitle="delete"
                      onClick={() => deleteDocument(document._id || '')}
                    />
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <ModalCustom
        openModal={isOpenPopup}
        closeModal={() => {
          setId(null);
          setIsOpenPopup(false);
        }}
        title="CREATE NEW ENTRY"
      >
        <CreateDocument setIsOpenPopup={setIsOpenPopup} id={id} setId={setId} />
      </ModalCustom>
      <CustomPagination totalPage={totalPages} />
      <DialogDelete isSubmitContent={false} />
    </Box>
  );
};

export default List;
