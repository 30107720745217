import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import { TableCompHead } from '../../../../components/ui';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import { MenuItem } from '@mui/material';
import CustomSelect from '../../../../components/common/SelectField';

interface IProps {
  moduleData: any;
  machineData: any;
  isView: boolean;
}
const THead = ({ moduleData, isView, machineData }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={3}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="MILLIPORE TEST"
      />
      <tr>
        <th colSpan={2}>Date</th>
        <th>
          {isView ? (
            formatDate(moduleData.created_date)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.created_date)}
              onChange={(date) => {
                moduleData.created_date = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
      </tr>
    </thead>
  );
};

export default THead;
