import axios from 'axios';
import { useState, useEffect } from 'react';
import { moduleConstant } from './constant';
import { useQuery } from '../../../../../hooks/UseQuery';

const useGetData = (moduleId: string | undefined) => {
  const designation_id = useQuery('designation_id') || null;
  const department_id = useQuery('department_id') || null;
  const designation = useQuery('designation');
  const departmentName = useQuery('departmentName');
  const title = useQuery('title') || '';

  const [moduleData, setModuleData] = useState<any>({
    title: title,
    designation_id: { _id: designation_id, designation: designation },
    department_id: { _id: department_id, department_name: departmentName },
    process_1: [
      {
        responsibility: '',
      },
      {
        responsibility: '',
      },
      {
        responsibility: '',
      },
    ],
    process_2: [
      {
        responsibility: '',
      },
      {
        responsibility: '',
      },
      {
        responsibility: '',
      },
    ],
    process_3: [
      {
        responsibility: '',
      },
      {
        responsibility: '',
      },
      {
        responsibility: '',
      },
    ],

    process_4: [
      {
        technical_competencies: '',
        proficiency_level: '',
      },
      {
        technical_competencies: '',
        proficiency_level: '',
      },
      {
        technical_competencies: '',
        proficiency_level: '',
      },
    ],
    process_5: [
      {
        technical_competencies: '',
        proficiency_level: '',
      },
      {
        technical_competencies: '',
        proficiency_level: '',
      },
      {
        technical_competencies: '',
        proficiency_level: '',
      },
    ],
  });
  const [error, setError] = useState(null);
  useEffect(() => {
    if (moduleId) {
      axios
        .get(`/api/${moduleConstant.api}/${moduleId}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => {
          setError(err);
        });
    }
  }, []);
  return { moduleData, error, setModuleData };
};

export default useGetData;
