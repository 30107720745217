import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { createConstant } from '../../constant';
import TBodyRight from './TBodyRight';
import formatDate from '../../../../components/common/formatDate';

interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
}
const TBody = ({ moduleData, isView, allmonth }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <>
          <tr key={`${index}_table_row`}>
            <td rowSpan={2}>{index + 1}</td>

            {Array(createConstant.extracolSpan - 1)
              .fill(0)
              .map((_, pIdx) => (
                <td rowSpan={2}>{item?.[`a${pIdx + 1}`]}</td>
              ))}

            <td>P</td>
            {/* always constant ⬇️*/}
            <TBodyRight
              item={item}
              isView={isView}
              index={index}
              allmonth={allmonth}
            />
            <td rowSpan={2}>
              <TableInput
                isView={isView}
                style={{ height: '80px' }}
                name="remarks"
                type="text"
                value={item.remarks}
                onChange={(e) => {
                  item.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
          <tr>
            <td>A</td>
            {allmonth.map((month: any, mIdx: number) => (
              <td
                width="80px"
                height="45px"
                style={{
                  backgroundColor: item[month].doneDate && '#37b24d',
                  color: 'white',
                  textTransform: 'capitalize',
                  fontSize: '1.6rem',
                }}
              >
                {item[month].isTask && 'A'}
                {/* {formatDate(item[month].doneDate) || null} */}
              </td>
            ))}
          </tr>
          {/* ---x---- */}
        </>
      ))}
    </tbody>
  );
};

export default TBody;
