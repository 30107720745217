import moment from 'moment';
import { useState } from 'react';

import { TableCompHead } from '../../../../../components/ui';
import formatDate from '../../../../../components/common/formatDate';
import DatePickerMui from '../../../../../components/common/DatePickerMui';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  isView: boolean;
  customer: any;
}

const heading = [
  'S. NO.',
  'HEADING',
  'CHECK POINTS',
  'SPECIFICATION',
  '(ADEQUACY) OBSERVATION',
  'RESULTS/OK/NC/SO',
  'REMARKS',
];
const THead = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);

  return (
    <thead>
      <TableCompHead
        colSpan={7}
        rowSpan={2}
        labels={['Rev. No.:', 'Rev. Date:']}
        values={['03', '00', '17-04-2021']}
        heading="Process Audit Report"
      />
      <tr>
        <th colSpan={4} align="left">
          Date of audit :{' '}
          {isView ? (
            formatDate(moduleData.d1)
          ) : (
            <DatePickerMui
              label=""
              value={moment(moduleData.d1)}
              onChange={(date) => {
                moduleData.d1 = date;
                setRender((prev) => !prev);
              }}
            />
          )}
        </th>
        <th colSpan={3} align="left">
          Shift :{' '}
          <TableInput
            isView={isView}
            name="s1"
            type="text"
            value={moduleData.s1}
            onChange={(e) => {
              moduleData.s1 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>

      <tr>
        <th colSpan={2} align="left">
          Plant. :
          <TableInput
            isView={isView}
            name="s2"
            type="text"
            value={moduleData.s2 || ''}
            onChange={(e) => {
              moduleData.s2 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          Area/Line :
          <TableInput
            isView={isView}
            name="s3"
            type="text"
            value={moduleData.s3 || ''}
            onChange={(e) => {
              moduleData.s3 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left">
          Process:
          <TableInput
            isView={isView}
            name="s4"
            type="text"
            value={moduleData.s4 || ''}
            onChange={(e) => {
              moduleData.s4 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th>All Process</th>
        <th align="left">
          Mode:
          <TableInput
            isView={isView}
            name="s5"
            type="text"
            value={moduleData.s5 || ''}
            onChange={(e) => {
              moduleData.s5 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Assembly:
          <TableInput
            isView={isView}
            name="s6"
            type="text"
            value={moduleData.s6 || ''}
            onChange={(e) => {
              moduleData.s6 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={2} align="left">
          Part No. :
          <TableInput
            isView={isView}
            name="s7"
            type="text"
            value={moduleData.s7 || ''}
            onChange={(e) => {
              moduleData.s7 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th align="left">
          Part Name:
          <TableInput
            isView={isView}
            name="s8"
            type="text"
            value={moduleData.s8 || ''}
            onChange={(e) => {
              moduleData.s8 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th></th>
        <th align="left"></th>
      </tr>

      <tr>
        <th colSpan={4} align="left">
          Auditor:
          <TableInput
            isView={isView}
            name="s9"
            type="text"
            value={moduleData.s9 || ''}
            onChange={(e) => {
              moduleData.s9 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
        <th colSpan={3} align="left">
          Part No. :
          <TableInput
            isView={isView}
            name="s10"
            type="text"
            value={moduleData.s10 || ''}
            onChange={(e) => {
              moduleData.s10 = e.target.value;
              setRender((prev) => !prev);
            }}
          />
        </th>
      </tr>
      <tr>
        <th colSpan={7} align="left">
          Refer FMEA for all processes.
        </th>
      </tr>
      <tr>
        <th colSpan={4} align="left">
          FMEA No: FMEA / AMG / TA / 001
        </th>
        <th colSpan={3} align="left">
          Rev.No.- 00
        </th>
      </tr>
      <tr>
        {heading.map((header) => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
