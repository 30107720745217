import React, { useEffect, useRef, useState } from 'react';
import TController from './components/view/TController';
import { IData } from './helpers/interface';
import { initialState } from './helpers/initialState';
import lodash from 'lodash';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import Box from '@mui/material/Box/Box';
import ChildHeader from '../../../components/ui/ChildHeader';
import {
  CustomButton,
  SaveButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import { useAuth } from '../../../services/auth/AuthProvider';
import { useQuery } from '../../../hooks/UseQuery';
import { processes } from './helpers/initialState';
import useConfirm from '../../../components/common/useConfirm';
import { Loader } from '../../../components/common/Loader';
import { CopyAll } from '@mui/icons-material';
import { BiPaste } from 'react-icons/bi';

const View = () => {
  const [moduleData, setModuleData] = useState<IData>(
    lodash.cloneDeep(initialState),
  );
  const [render, setRender] = useState(false);
  const navigate = useNavigate();
  const { state, clipData, setClipData } = useAuth();
  const { id } = useParams();
  const annual_month = useQuery('annual_date') || '';
  const isView = useQuery('isView') === 'true' ? true : false;
  const [isLoading, setIsLoading] = useState(false);
  const [useEffectRender, setUseEffectRender] = useState(false);

  const [DialogDeleteRow, confirmDeleteRow] = useConfirm(
    'DELETE',
    'Are you sure you want to delete Row?',
  );
  const [DialogAddRow, confirmAddRow] = useConfirm(
    'Add Row',
    'Are you sure you want to Add Row?',
  );
  const [DialogSubmit, confirmSubmit] = useConfirm(
    'Submit',
    'Are you sure you want to save this document?',
  );

  useEffect(() => {
    if (annual_month) {
      axios
        .get(`/api/pokeYokeCheckSheetQtyDept/${annual_month}`)
        .then((res) => {
          setModuleData(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [id, useEffectRender]);

  const handleSumbit = async (isSubmitted: boolean) => {
    return new Promise(async (resolve, reject) => {
      const ans = await confirmSubmit();
      if (!ans) return;
      moduleData.isSubmitted = isSubmitted;
      moduleData.is_admin_request = 'pending';
      moduleData.report_prepared_by = state.user?.name;
      if (moduleData._id) {
        await axios
          .put(`/api/pokeYokeCheckSheetQtyDept/${moduleData._id}`, moduleData)
          .then((res) => {
            if (res.data.status === 'success') {
              alert('Updated Successfully');

              resolve(1);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      } else {
        if (!annual_month) {
          return alert('Please pick annual year?');
        }
        moduleData.annual_month = annual_month;
        await axios
          .post('/api/pokeYokeCheckSheetQtyDept', moduleData)
          .then((res) => {
            if (res.data.status === 'success') {
              alert('created Successfully');
              resolve(1);
              navigate(-1);
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      }
    });
  };
  const uploadImageFile = async (e: any, index: number) => {
    await handleSumbit(true);
    const files = e.target.files;
    if (!files) return;
    let formData = new FormData();
    formData.append('file', files[0]);
    setIsLoading(true);
    axios
      .patch(
        `/api/pokeYokeCheckSheetQtyDept/uploadFile/${index}/${moduleData?._id}
      `,
        formData,
      )
      .then((res) => {
        if (res.data) {
          alert('document upload successly');
          setIsLoading(false);
          setUseEffectRender((prev: any) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handelDeleteRows = async (rowIdx: number) => {
    const ans = await confirmDeleteRow();
    if (!ans) return;
    let data = { ...moduleData };
    data.processes.splice(rowIdx, 1);
    setModuleData(data);
  };
  const handleCopy = () => {
    const process = moduleData.processes.map((process: any) => ({
      poke_yoke_name: process.poke_yoke_name,
      machine_name_and_location: process.machine_name_and_location,
      method_of_verification: process.method_of_verification,
      files: process.files,
    }));
    setClipData(
      moduleData.processes.map((process: any) => ({
        poke_yoke_name: process.poke_yoke_name,
        machine_name_and_location: process.machine_name_and_location,
        method_of_verification: process.method_of_verification,
        files: process.files,
        results: new Array(31).fill(''),
      })),
    );
  };

  return (
    <>
      <Box>
        <Loader loader={isLoading} />
        <ChildHeader text="POKA YOKE VERIFICATION CHECK SHEET">
          {!isView && (
            <>
              {clipData.length ? (
                <CustomButton
                  sx={{
                    backgroundColor: '#1c7ed6',
                    marginRight: '1rem',
                  }}
                  icon={<BiPaste />}
                  onClick={async () => {
                    console.log(clipData);
                    setModuleData((prev) => ({
                      ...prev,
                      processes: clipData,
                    }));
                    setClipData([]);
                  }}
                >
                  PASTE DATA
                </CustomButton>
              ) : (
                <CustomButton
                  sx={{
                    backgroundColor: '#1c7ed6',
                    marginRight: '1rem',
                  }}
                  icon={<CopyAll />}
                  onClick={handleCopy}
                >
                  COPY DATA
                </CustomButton>
              )}
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={async () => {
                  const ans = await confirmAddRow();
                  if (!ans) return;
                  moduleData.processes.push(lodash.cloneDeep(processes[0]));
                  setRender((prev) => !prev);
                }}
              >
                ADD ROWS
              </CustomButton>
              {state.user?.userType === state.IS.Supervisor && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => handleSumbit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => handleSumbit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController
        {...{
          moduleData,
          isView,
          handleSumbit,
          uploadImageFile,
          handelDeleteRows,
        }}
      />

      <DialogDeleteRow isSubmitContent={false} />
      <DialogAddRow />
      <DialogSubmit />
    </>
  );
};

export default View;
