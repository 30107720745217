import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import { CustomPagination, TableCustom } from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  CustomIconButton,
} from '../../../components/common/button/CustomIconButton';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import InputMuiCustom from '../../../components/common/inputs/InputMuiCustom';
import { AddButton } from '../../../components/common/button/CustomButtons';
import { MdUpdate } from 'react-icons/md';

const tableHeaderObj = [
  [
    { text: 'S.No.', rowspan: 1, colspan: 1 },
    { text: 'CATEGORY NAME', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'A-Z' },
  { value: 'customer_name', name: 'Customer Name' },
];

const CategoryList = () => {
  const [data, setData] = useState<any[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const [inputValue, setInputValue] = useState<any>('');
  const [isEdible, setIsEdible] = useState<boolean[]>([]);
  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?'
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/masterListproductAuditRoute/instrumentcategory?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}`
      )
      .catch((err) => {
        console.log(err);
      });
    setIsEdible(Array(res?.data?.result.length).fill(false));
    setData(res?.data?.result);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const handleDelete = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/masterListproductAuditRoute/instrumentcategory/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, render]);

  const handleSubmit = () => {
    if (!inputValue) return;
    axios
      .post('/api/masterListproductAuditRoute/instrumentcategory', {
        name: inputValue,
      })
      .then((res) => {
        if (res.data) {
          alert('document added successfully');
          setInputValue('');
          setRender((prev) => !prev);
        }
      });
  };

  const handleEdible = (indexEdit: number) => {
    setIsEdible((prev) => prev.map((_, i) => i === indexEdit));
  };

  const handleUpdate = (id: any, value: any) => {
    if (!value) return;
    if (!window.confirm('Are you sure you want to update this document?'))
      return;
    axios
      .put(`/api/masterListproductAuditRoute/instrumentcategory/${id}`, {
        name: value,
      })
      .then((res) => {
        if (res.data) {
          alert('document update successfully');
          handleEdible(data.length + 1);
          setRender((prev) => !prev);
        }
      });
  };

  return (
    <>
      <Stack
        direction={'row'}
        justifyContent="space-between"
        spacing={2}
        sx={{
          width: '500px',
          marginBottom: '20px',
          marginLeft: '30px',
        }}
      >
        <InputMuiCustom
          type="text"
          name="name"
          variant="outlined"
          placeholder="CATEGORY"
          sx={{ width: '300px' }}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          value={inputValue}
        />
        <AddButton label={'ADD CATEGORY'} onClick={() => handleSubmit()} />
      </Stack>

      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: any, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">
                    <InputMuiCustom
                      type="text"
                      variant="outlined"
                      name="name"
                      placeholder=""
                      sx={{ width: '100px' }}
                      defaultValue={item.name || ''}
                      onBlur={(e) => {
                        item.name = e.target.value;
                      }}
                      InputProps={{
                        readOnly: !isEdible[index],
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          handleEdible(index);
                        }}
                      />
                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          handleDelete(item?._id || '');
                        }}
                      />
                      <CustomIconButton
                        sx={{ p: 0, ml: 1 }}
                        tooltipTitle="Update"
                        onClick={() => handleUpdate(item?._id, item?.name)}
                      >
                        <MdUpdate color="#2f9e44" size={'2.5rem'} />
                      </CustomIconButton>
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default CategoryList;
