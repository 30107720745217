import React, { useState } from 'react';
import { Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import moment from 'moment';

import { AddButton } from '../../components/common/button/CustomButtons';
import ChildHeader from '../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../components/common/FilterBy';
import { SearchBar } from '../../components/common/SearchBar';
import { CustomPagination, TableCustom } from '../../components/common';
import useDebounce from '../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
  ListIconButton,
} from '../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../components/common/Modal';
import { useQuery } from '../../hooks/UseQuery';
import useConfirm from '../../components/common/useConfirm';
import { IData } from './helpers/interfaces';
import ViewFile from './components/ViewFile';
import formatDate from '../../components/common/formatDate';
import { BsFileEarmarkTextFill } from 'react-icons/bs';

const tableHeaderObj = [
  [
    { text: 'S.NO.', rowspan: 1, colspan: 1 },
    { text: 'SUPPLIER NAME', rowspan: 1, colspan: 1 },
    { text: 'DEFECT OCCURENCE DATE', rowspan: 1, colspan: 1 },
    { text: 'ITEM CODE', rowspan: 1, colspan: 1 },
    { text: 'DEFECT DESCRIPTION', rowspan: 1, colspan: 1 },
    { text: 'INVOICE NO.', rowspan: 1, colspan: 1 },
    { text: '8D CLOSURE DATE', rowspan: 1, colspan: 1 },
    { text: '8D CLOSURE STATUS', rowspan: 1, colspan: 1 },
    { text: 'ACTION', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [
  { value: 'createdAt', name: 'A-Z' },
  { value: 'defect_occ_dateDes', name: 'Most recent defect occurenece date' },
  { value: 'defect_occ_dateAsc', name: 'Oldest defect occurenece date' },
];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [complaintId, setComplaintId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [isDisable, setDisable] = useState(false);
  const [all, setAll] = useState(false);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?',
  );

  const fetchData = async () => {
    const res = await axios
      .get(
        `/api/suppplierComplaint/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&all=${all}`,
      )
      .catch((err) => {
        console.log(err);
      });
    setData(res?.data?.results);
    setPerPage(res?.data?.perPage);
    setTotalPages(res?.data?.pages);
  };

  const deleteCustomer = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/suppplierComplaint/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [page, search, render, isModal, filter, all]);
  function get8DClosure(item: any) {
    if (!item.defect_occ_date || !item.target_date_7) return 'PENDING';

    const defectDateAfter7Days = moment(item.defect_occ_date).add(7, 'days');
    const targetDate = moment(item.target_date_7);
    if (targetDate.isAfter(defectDateAfter7Days)) {
      return 'OVERDUE';
    }
    return 'ONTIME';
  }

  return (
    <>
      <ChildHeader text="SUPPLIER COMPLAINT REGISTER">
        <AddButton
          onClick={() => {
            setAll((value) => !value);
          }}
          label={all ? 'Hide' : 'Save'}
        />
        <AddButton
          onClick={() => setIsModal(true)}
          label="NEW SUPPLIER COMPLAINT"
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <SearchBar label="Search Defect Description" />
        <FilterBy options={filterOptions} />
      </SearchFilterContainer>
      <TableCustom tableHeaderObj={tableHeaderObj}>
        <TableBody>
          {data &&
            data.map((item: IData, index: number) => (
              <React.Fragment key={item._id}>
                <TableRow>
                  <TableCell align="center">
                    {perPage * (+page - 1) + index + 1}
                  </TableCell>
                  <TableCell align="center">
                    {item.vendor_id?.vender_name}
                  </TableCell>
                  <TableCell align="center">
                    {item.defect_occ_date && formatDate(item?.defect_occ_date)}
                  </TableCell>
                  <TableCell align="center">{item.item_code}</TableCell>
                  <TableCell align="center">{item?.defect_desc}</TableCell>
                  <TableCell align="center">{item?.invoice_no}</TableCell>
                  <TableCell align="center">
                    {item.target_date_7 && formatDate(item.target_date_7)}
                  </TableCell>
                  <TableCell
                    style={{
                      background:
                        get8DClosure(item) === 'ONTIME'
                          ? 'green'
                          : get8DClosure(item) === 'PENDING'
                          ? 'yellow'
                          : 'red',
                      color:
                        get8DClosure(item) === 'PENDING' ? 'black' : 'white',
                    }}
                    align="center"
                  >
                    {get8DClosure(item)}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" sx={{ justifyContent: 'center' }}>
                      <ListIconButton
                        tooltipTitle="FILE LIST"
                        onClick={() => {
                          setId(item._id || null);
                          setIsFileOpenPopup(true);
                        }}
                      />
                      <ViewIconButton
                        tooltipTitle="VIEW"
                        onClick={() => {
                          setComplaintId(item?._id || '');
                          setDisable(true);
                          setIsModal(true);
                        }}
                      />
                      <EditIconButton
                        tooltipTitle="EDIT"
                        onClick={() => {
                          setComplaintId(item?._id || '');
                          setDisable(false);
                          setIsModal(true);
                        }}
                      />

                      <DeleteIconButton
                        tooltipTitle="DELETE"
                        onClick={() => {
                          deleteCustomer(item?._id || '');
                        }}
                      />
                    </Stack>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableCustom>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setComplaintId(null);
          setIsModal(false);
        }}
        title="SUPPLIER COMPLAINT REGISTER"
      >
        <Create id={complaintId} setIsModal={setIsModal} disable={isDisable} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
    </>
  );
};

export default List;
