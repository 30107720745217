export const listConstant = {
  childHeader: 'Internal Quality Audit Plan',
  addButtonLable: 'New Plan',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE Plan',
};

export const createConstant = {
  childHeader: 'Internal Quality Audit Plan ',
  theadHeader: 'Internal Quality Plan ',
  extracolSpan: 5,
};

export const checkListList = {
  childHeader: 'Internal Quality Plan STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'internalQualityAuditPlanRoute',
  gettableDataurl: 'internalQualityAuditPlanRoute/monthly',
  getPlanByid: 'internalQualityAuditPlanRoute',
  createPlanByid: 'internalQualityAuditPlanRoute',
  updatePlanByid: 'internalQualityAuditPlanRoute',
  sync: 'internalQualityAuditPlanRoute/handleSync',
  getAllCheckList: 'internalQualityAuditPlanRoute/getAllCheckList',
  checkListStatus: 'internalQualityAuditPlanRoute/handleStatus',
  checklistUpload: 'internalQualityAuditPlanRoute/handleUpload',
  checklistDeleteImage: 'internalQualityAuditPlanRoute/handleDeleteImage',
  checklistData: 'internalQualityAuditPlanRoute/checklistRoute',
};

export const navigationConstant = {
  createPlanSchedule: '/common/internalQualityAuditPlanCreate',
  createChecklist: '/common/internalQualityAuditPlanChecklistCreate',
};
