import { GrUser } from 'react-icons/gr';
import {
  TbAlertTriangleFilled,
  TbCornerRightDownDouble,
  TbFaceIdError,
} from 'react-icons/tb';
import { MdDashboard } from 'react-icons/md';
import { AiOutlineDeliveredProcedure } from 'react-icons/ai';

const tiles = [
  {
    sNo: 1,
    link: `/common/pastTroubleDatabaseList`,
    isLink: true,
    clauseNo: 8,
    icon: <TbCornerRightDownDouble size="5rem" color="#003566" />,
    p: 'PAST TROUBLE',
    span: 'DATABASE',
    isIcon: true,
  },
  {
    sNo: 1,
    link: `/admin/CustomerComplaintRegister`,
    isLink: true,
    clauseNo: 8,
    icon: <GrUser size="5rem" color="#003566" />,
    p: 'CUSTOMER COMPLAINT',
    span: 'REGISTER',
    isIcon: true,
  },
  {
    sNo: 1,
    link: `/admin/customerComplaintRegisterExcelUpload`,
    isLink: true,
    clauseNo: 8,
    icon: <GrUser size="5rem" color="#003566" />,
    p: 'CUSTOMER COMPLAINT REGISTER',
    span: 'EXCEL UPLOAD',
    isIcon: true,
  },
  {
    sNo: 2,
    link: '/admin/defect_list?filter=createdAt',
    isLink: true,
    clauseNo: 4,
    //target: '_blank',
    icon: <TbFaceIdError size="5rem" color="#a12222" />,
    p: 'List of',
    span: 'DEFECTS',
    isIcon: true,
  },
  {
    sNo: 3,
    link: '/admin/qualityAlert?documentType=qualityAlertModule&heading=QUALITY ALERT',
    isLink: true,
    clauseNo: 8,
    icon: <TbAlertTriangleFilled size="5rem" color="#135089" />,
    p: 'QUALITY',
    span: 'ALERT',
    isIcon: true,
  },
  {
    sNo: 4,
    link: '/admin/customerConcernDashboard',
    isLink: true,
    clauseNo: 8,
    icon: <MdDashboard size="5rem" color="#135089" />,
    p: 'CUSTOMER CONCERN',
    span: 'DASHBOARD',
    isIcon: true,
  },
  {
    sNo: 23,
    link: '/common/customerSatisfactionSurveyList',
    isLink: true,
    icon: <AiOutlineDeliveredProcedure size="5rem" color="#003566" />,
    p: 'Customer Satisfaction',
    span: 'Survey',
    isIcon: true,
  },
  {
    sNo: 24,
    link: '/admin/inHouseComplaintRegisterList',
    isLink: true,
    clauseNo: 8,
    icon: <GrUser size="5rem" color="#003566" />,
    p: 'IN-HOUSE COMPLAINT',
    span: 'REGISTER',
    isIcon: true,
  },
  {
    sNo: 25,
    link: '/common/customerSatisfactionSurveyPlanTiles',
    isLink: true,
    clauseNo: 8,
    icon: <GrUser size="5rem" color="#003566" />,
    p: 'Customer Satisfaction Survey',
    span: 'Plan',
    isIcon: true,
  },
];

export default tiles;
