import { useState } from 'react';

interface IProps {
  year: number;
  month: number;
}
const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
const THead = ({ year, month }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead style={{ background: '#9775fa', color: 'white' }}>
      <tr>
        <th
          colSpan={5}
          style={{ background: 'orange', color: 'black', fontWeight: 'bold' }}
        >
          Supplier PPM Month Wise {monthNames[month]}-{year}
        </th>
      </tr>
      <tr>
        {['S. NO.', 'VENDOR NAME', 'PPM', 'TARGET'].map((headingData) => (
          <th>{headingData}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
