import axios from 'axios';
import { useState, useEffect } from 'react';

const useGetData = (partId: string | undefined, pdirId: string | undefined) => {
  const [moduleData, setModuleData] = useState<any>({
    date: new Date(),
  });
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [machineData, setMachineData] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    if (pdirId) {
      axios
        .get(`/api/milliporeRoute/${pdirId}`)
        .then((res) => setModuleData(res.data))
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      axios
        .get(`/api/milliporeRoute/getPart/${partId}`)
        .then((res) => {
          const response = res.data;
          setModuleData((item: any) => {
            item.part_id = response;
            return { ...item };
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setError(err);
        });
    }
  }, []);
  return { moduleData, isLoading, error, machineData };
};

export default useGetData;
