import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

interface IProps {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  label: string;
  disabled?: boolean;
}

const StateSearchBar = ({ label, search, setSearch, disabled }: IProps) => {
  return (
    <Paper
      component="form"
      sx={{
        p: '9px 4px',
        display: 'flex',
        alignItems: 'center',
        background: '#f1f3f5',
      }}
    >
      <IconButton
        type="button"
        sx={{ p: '5px' }}
        aria-label="search"
        disabled={disabled || false}
      >
        <SearchIcon sx={{ fontSize: '2.6rem' }} />
      </IconButton>
      <InputBase
        sx={{ ml: 1, flex: 1, fontSize: '1.6rem' }}
        placeholder={label}
        onChange={(e) => setSearch(e.target.value)}
        value={search}
        disabled={disabled || false}
      />
    </Paper>
  );
};

export default StateSearchBar;
