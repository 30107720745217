import React, { useState, useEffect, useRef } from 'react';
import { Box, Stack, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';

import {
  AddButton,
  CustomButton,
} from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { SearchFilterContainer } from '../../../assets/styles/layout/searchFilter.styled';
import FilterBy from '../../../components/common/FilterBy';
import {
  CustomPagination,
  PersitedDatePicker,
  SearchBar,
  TableCustom,
  TextAreaInput,
} from '../../../components/common';
import useDebounce from '../../../hooks/useDebounce';
import {
  EditIconButton,
  DeleteIconButton,
  ViewIconButton,
} from '../../../components/common/button/CustomIconButton';
import Create from './Create';
import ModalCustom from '../../../components/common/Modal';
import { useQuery } from '../../../hooks/UseQuery';
import useConfirm from '../../../components/common/useConfirm';
import { IData } from './helpers/interfaces';
import ViewFile from './components/ViewFile';
import formatDate from '../../../components/common/formatDate';
import TableScroll from '../../../components/ui/renderTable/TableScroll';
import { BiSend } from 'react-icons/bi';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';
import { Loader } from '../../../components/common/Loader';

const tableHeaderObj = [
  [
    {
      text: 'S.NO.',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'UID',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'ISSUE REPORTED DATE',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '150px' },
    },
    {
      text: 'CUSTOMER NAME',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '150px' },
    },
    {
      text: 'PART NUMBER',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'PART NAME',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'REASON OF REJECTION/ DEFECT REPORTED',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '250px' },
    },
    {
      text: 'DEFECT CATEGORY',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'CUSTOMER END QTY.',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: '1ST TIME/REPEATED/NDP',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '130px' },
    },
    {
      text: 'BATCH CODE/BAR CODE DATE ',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '140px' },
    },
    {
      text: 'DEFECTIVE PART STATUS ',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '140px' },
    },
    {
      text: 'DEFECT CATEGORY (4M TYPE )',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '130px' },
    },
    {
      text: 'SHORT TERM ACTION',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '250px' },
    },
    {
      text: 'ROOT CAUSE',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '250px' },
    },
    {
      text: 'ACTION TAKEN',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '250px' },
    },
    {
      text: 'TARGET DATE',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'ACTUAL CLOSURE DATE',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '150px' },
    },
    {
      text: 'STATUS',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'KEY LESSON LEARNT ',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '120px' },
    },
    {
      text: 'CONTROL PLAN',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'PFMEA',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'QUALITY ALERT',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'WI',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'APR',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'MAY',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'JUN',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'JUL',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'AUG',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'SEP',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'OCT',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'NOV',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'DEC',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'JAN',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'FEB',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'MAR',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'DEFECT PHOTO',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'SUPPORTING DOCUMENTS',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '100px' },
    },
    {
      text: 'ACTION',
      rowspan: 2,
      colspan: 1,
      style: { background: '#38d9a9', minWidth: '300px' },
    },
  ],
];

const List = () => {
  const [data, setData] = useState<IData[]>([]);
  const [isModal, setIsModal] = useState<boolean>(false);
  const [render, setRender] = useState(false);
  const [complaintId, setComplaintId] = useState<string | null>(null);
  const [perPage, setPerPage] = useState<number>(10);
  const [totalPages, setTotalPages] = useState(0);
  const [id, setId] = useState<string | null>(null);
  const [isFileOpenPopup, setIsFileOpenPopup] = useState(false);
  const [contentType, setContentType] = useState('');
  const [filterCustomer, setFilterCustomer] = useState<any>([]);
  const search = useQuery('search') || '';
  const filter = useQuery('filter') || 'createdAt';
  const page = useQuery('page') || 1;
  const customer = useQuery('customer');
  const [all, setAll] = useState(false);
  const date = useQuery('date');
  const [isLoading, setIsLoading] = useState(false);

  const [DialogDelete, confirmDelete] = useConfirm(
    'DELETE',
    'Are you sure you want to delete this?',
  );
  const [DialogSend, confirmSend] = useConfirm(
    'PAST TROUBLE DATABASE',
    'Are you sure you want to create this?',
  );

  useEffect(() => {
    axios
      .get('/api/customerRoutes/customerList/customerForAutoComplete')
      .then((res) => {
        if (res?.data) {
          const formattedData = res.data.map((customer: any) => ({
            name: customer.customer_name,
            value: customer._id,
          }));
          setFilterCustomer([...formattedData]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const fetchData = async () => {
    if (date) {
      const res = await axios
        .get(
          `/api/customerComplaintExcelUploadRoute/${date}/?page=${page}&sortBy=${filter}&search=${search}&perPage=${perPage}&customerId=${customer || ''}&all=${all}`,
        )
        .catch((err) => {
          console.log(err);
        });

      setData(res?.data?.results);
      setPerPage(res?.data?.perPage);
      setTotalPages(res?.data?.pages);
    }
  };

  const deleteCustomerConcern = async (id: string) => {
    const ans = await confirmDelete();
    if (!ans) return;
    await axios
      .delete(`/api/customerComplaintExcelUploadRoute/${id}`)
      .then((res) => {
        alert('Document deleted successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };
  const handlePastTroubleData = async (item: any) => {
    const ans = await confirmSend();
    if (!ans) return;
    item.category = 'Customer Concern';
    delete item._id;
    await axios
      .post(`/api/pastTroubleDatabaseRoutes`, item)
      .then((res) => {
        alert('Document created successfully');
        setRender((prev) => !prev);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchData, 500, [
    page,
    search,
    render,
    isModal,
    filter,
    customer,
    all,
    date,
  ]);
  const handleExcelImport = (e: any) => {
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    e.target.value = '';
    setIsLoading(true);
    axios
      .post(`/api/customerComplaintExcelUploadRoute/importExcel/data`, formData)
      .then((res) => {
        if (res.data) {
          window.alert('successfully uploaded');
          setIsLoading(false);
          setRender((prev) => !prev);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else if (
          err.response.data.error.message
            .split(' ')
            .join('')
            .toLowerCase()
            .includes('duplicatekeyerror')
        ) {
          window.alert(
            'Successfully uploaded. All the include parts has been skipped.',
          );
        } else {
          window.alert(
            'Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.',
          );
        }
      });
  };
  return (
    <>
      <Loader loader={isLoading} />
      <ChildHeader text="CUSTOMER CONCERN REGISTER EXCEL UPLOAD">
        <CustomUploadButton
          lable={'IMPORT DATA'}
          multiple
          accept=".xls,.xlsx"
          onChange={(e) => handleExcelImport(e)}
        />
        <AddButton
          onClick={() => {
            setAll((value) => !value);
          }}
          label={all ? 'Hide' : 'Save'}
        />
        <AddButton
          onClick={() => setIsModal(true)}
          label="NEW CUSTOMER CONCERN"
        />
      </ChildHeader>
      <SearchFilterContainer filter={true}>
        <Box style={{ display: 'flex', margin: 2 }}>
          <PersitedDatePicker
            label={`Search by Year`}
            views={['year']}
            sx={{ minWidth: 300 }}
          />

          <FilterBy
            sx={{ minWidth: 300, marginLeft: 2 }}
            name="customer"
            label="Filter By Customer"
            options={filterCustomer}
          />
          <SearchBar
            sx={{ width: '100%', marginLeft: 2 }}
            label="Search By UID"
          />
        </Box>
      </SearchFilterContainer>

      <TableScroll>
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {data &&
              data.map((item: IData, index: number) => (
                <React.Fragment key={item._id}>
                  <TableRow>
                    <TableCell align="center">
                      {perPage * (+page - 1) + index + 1}
                    </TableCell>
                    <TableCell align="center">{item.uid}</TableCell>
                    <TableCell align="center">
                      {item.issue_reported_date &&
                        formatDate(item.issue_reported_date)}
                    </TableCell>
                    <TableCell align="center">
                      {item.part_id?.customer_id?.customer_name}
                    </TableCell>
                    <TableCell align="center">
                      {item.part_id?.part_number}
                    </TableCell>
                    <TableCell align="center">
                      {item.part_id?.part_name}
                    </TableCell>
                    <TableCell align="center">
                      <TextAreaInput
                        value={item.defect_name}
                        style={{ minWidth: '200px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell align="center">{item.defect_category}</TableCell>
                    <TableCell align="center">
                      {item.customer_end_qty}
                    </TableCell>
                    <TableCell align="center">
                      {item.firstTime_repeated_ndp}
                    </TableCell>
                    <TableCell align="center">
                      {item.bar_code_date && formatDate(item.bar_code_date)}
                    </TableCell>
                    <TableCell align="center">
                      {item.defective_part_status}
                    </TableCell>
                    <TableCell align="center">
                      {item.defect_category_4m_type}
                    </TableCell>
                    <TableCell align="center">
                      <TextAreaInput
                        value={item.short_term_action}
                        style={{ minWidth: '250px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextAreaInput
                        value={item.root_cause}
                        style={{ minWidth: '250px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <TextAreaInput
                        value={item.action_taken}
                        style={{ minWidth: '250px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      {item.target_date && formatDate(item.target_date)}
                    </TableCell>
                    <TableCell align="center">
                      {item.actual_closure_date &&
                        formatDate(item.actual_closure_date)}
                    </TableCell>
                    <TableCell align="center">{item.status}</TableCell>
                    <TableCell align="center">
                      <TextAreaInput
                        value={item.key_lesson_learnt}
                        style={{ minWidth: '200px', minHeight: '50px' }}
                      />
                    </TableCell>
                    <TableCell align="center">{item.control_plan}</TableCell>
                    <TableCell align="center">{item.pfmea}</TableCell>
                    <TableCell align="center">{item.quality_alert}</TableCell>
                    <TableCell align="center">{item.wi}</TableCell>
                    {[
                      'apr',
                      'may',
                      'jun',
                      'jul',
                      'aug',
                      'sep',
                      'oct',
                      'nov',
                      'dec',
                      'jan',
                      'feb',
                      'mar',
                    ].map((month: any) => {
                      return (
                        <TableCell
                          style={{
                            ...(item[month] &&
                              item[month].toLowerCase() === 'repeated' && {
                                background: '#e03131',
                              }),
                            ...(item[month] &&
                              item[month].toLowerCase() === 'not repeated' && {
                                background: '#2f9e44',
                              }),
                            width: '50px',
                          }}
                          align="center"
                        ></TableCell>
                      );
                    })}
                    <TableCell align="center">
                      {
                        <ViewIconButton
                          tooltipTitle="view"
                          onClick={() => {
                            setId(item._id || null);
                            setIsFileOpenPopup(true);
                            setContentType('image');
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell align="center">
                      {
                        <ViewIconButton
                          tooltipTitle="view"
                          onClick={() => {
                            setId(item._id || null);
                            setIsFileOpenPopup(true);
                            setContentType('file');
                          }}
                        />
                      }
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" sx={{ justifyContent: 'center' }}>
                        <CustomButton
                          sx={{
                            backgroundColor: '#228be6',
                            color: '#e7f5ff',
                            mr: 2,
                          }}
                          icon={<BiSend />}
                          size="small"
                          onClick={() => handlePastTroubleData(item)}
                        >
                          SEND TO PTD
                        </CustomButton>
                        <EditIconButton
                          tooltipTitle="EDIT"
                          onClick={() => {
                            setComplaintId(item?._id || '');
                            setIsModal(true);
                          }}
                        />

                        <DeleteIconButton
                          tooltipTitle="DELETE"
                          onClick={() => {
                            deleteCustomerConcern(item?._id || '');
                          }}
                        />
                      </Stack>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
      </TableScroll>
      <CustomPagination totalPage={totalPages} />
      <ModalCustom
        openModal={isModal}
        closeModal={() => {
          setComplaintId(null);
          setIsModal(false);
        }}
        title="CUSTOMER CONCERN REGISTER"
      >
        <Create id={complaintId} setIsModal={setIsModal} />
      </ModalCustom>
      <ModalCustom
        title="FILE LIST"
        openModal={isFileOpenPopup}
        closeModal={() => {
          setId(null);
          setIsFileOpenPopup(false);
        }}
      >
        <ViewFile id={id} contentType={contentType} />
      </ModalCustom>
      <DialogDelete isSubmitContent={false} />
      <DialogSend isSubmitContent={false} />
    </>
  );
};

export default List;
