import { TimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../../../components/common';
import DatePickerMui from '../../../../../../components/common/DatePickerMui';
import formatDate from '../../../../../../components/common/formatDate';
import { TableCompHead } from '../../../../../../components/ui';
import { convToTime } from '../../../../../../utils/helpers';

interface IProps {
  year: number;
  month: string;
}
const THead = ({ year, month }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <thead style={{ background: '#9775fa', color: 'white' }}>
      <tr>
        <th
          colSpan={5}
          style={{ background: 'orange', color: 'black', fontWeight: 'bold' }}
        >
          Defects Detail's of Month {month}-{year}
        </th>
      </tr>
      <tr>
        {[
          'Sr No.',
          'Defects',
          'Frequency',
          'Cumm. Frequency',
          'Cumm. Percentage',
        ].map((headingData) => (
          <th>{headingData}</th>
        ))}
      </tr>
    </thead>
  );
};

export default THead;
