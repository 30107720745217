import { Box, Paper, Tab, Tabs } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { PersitedDatePicker } from '../../../components/common';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import ParetoChart from './components/dashboard/monthlyPpmDashboard/ParetoChart';
import SupplierPpmDashboard from './components/dashboard/supplierPpmDahboard/supplierPpmDashboard';
import InputMuiCustom from '../../../components/common/inputs/InputMuiCustom';
import { UpdateButton } from '../../../components/common/button/CustomButtons';
const SupplierPpmDashboardTabs = () => {
  const date = useQuery('date') || '';
  const [tabValue, setTabValue] = useState(0);
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [ppmByMonth, setPpmByMonth] = useState({ _id: '', target: 0 });
  const [ppmBySupplier, setPpmBySupplier] = useState({});
  const [target, setTarget] = useState(0);
  const [render, setRender] = useState(false);

  const fetchData = async () => {
    if (date) {
      if (tabValue == 0) {
        const res = await axios
          .get(`/api/supplierPpmRoute/dashboard/getPpmByMonth?date=${date}`)
          .catch((err) => {
            console.log(err);
          });
        setPpmByMonth(res?.data);
        setTarget(0);
      } else if (tabValue == 1) {
        const res = await axios
          .get(`/api/supplierPpmRoute/dashboard/getPpmBySupplier/?date=${date}`)
          .catch((err) => {
            console.log(err);
          });
        setPpmBySupplier(res?.data);
        setMonth(new Date(date).getMonth());
      }
      setYear(new Date(date).getFullYear());
    }
  };
  useEffect(() => {
    fetchData();
  }, [date, tabValue]);
  const updateTarget = async () => {
    let isConfirm = window.confirm('Are you sure you want to update target');
    if (isConfirm) {
      axios
        .patch(`/api/supplierPpmRoute/updateTarget/${ppmByMonth?._id}`, {
          target: target,
        })
        .then((res) => {
          if (res.data.status === 'success') {
            alert('target Change successfully');
          }
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <Box>
      <ChildHeader text="SUPPLIER PPM DASHBOARD">
        {tabValue === 0 && ppmByMonth?._id && (
          <>
            <InputMuiCustom
              type="number"
              name="target"
              placeholder="Target"
              autoComplete="true"
              required={true}
              onChange={(e: any) => {
                setTarget(e.target.value);
              }}
              value={target || ppmByMonth?.target || 0}
              sx={{ width: 300, marginLeft: 2, background: 'white' }}
            />
            <UpdateButton
              label="UPDATE TARGET "
              size="large"
              onClick={() => updateTarget()}
              sx={{ marginLeft: 2 }}
            />
          </>
        )}
      </ChildHeader>
      <Paper elevation={2}>
        <Tabs
          value={tabValue}
          onChange={(_, newValue) => setTabValue(+newValue)}
          indicatorColor="primary"
          scrollButtons="auto"
          textColor="secondary"
          variant="fullWidth"
        >
          <Tab label="PPM Month Wise" />
          <Tab label="Supplier PPM Month Wise" />
        </Tabs>
      </Paper>
      <Box sx={{ margin: 2, display: 'flex' }}>
        {tabValue === 0 && (
          <PersitedDatePicker label={'Search By Year'} views={['year']} />
        )}
        {tabValue === 1 && (
          <PersitedDatePicker
            label={'Search By Month'}
            views={['month', 'year']}
          />
        )}
      </Box>

      <section>
        {tabValue === 0 && (
          <ParetoChart
            apiData={ppmByMonth}
            year={year}
            target={target || ppmByMonth?.target || 0}
          />
        )}
        {tabValue === 1 && (
          <SupplierPpmDashboard
            apiData={ppmBySupplier}
            year={year}
            month={month}
            target={target || ppmByMonth?.target || 0}
          />
        )}
      </section>
    </Box>
  );
};

export default SupplierPpmDashboardTabs;
