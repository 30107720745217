import { Box } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { PersitedDatePicker } from '../../components/common';
import ChildHeader from '../../components/ui/ChildHeader';
import { useQuery } from '../../hooks/UseQuery';
import { useAuth } from '../../services/auth/AuthProvider';
import NotificationTable from './components/NotificationTable';

const TableList = () => {
  const { state } = useAuth();
  const date = useQuery('date');
  const [tableData, setTableData] = useState<any[]>([]);
  const [render, setRender] = useState(false);
  const { user } = state;
  useEffect(() => {
    axios
      .get(`/api/notification/getAll/${state.user?.userType}?date=${date}`)
      .then((res) => {
        if (res.data) {
          setTableData(res.data);
        }
      })
      .catch((err) => console.log(err));
  }, [render, date]);

  const deleteDocument = async (id: string) => {
    let isDeleted = window.confirm(
      'Are you sure you want to delete this document?',
    );
    if (isDeleted) {
      axios
        .delete(`/api/notification/${id}`)
        .then(() => {
          alert('document deleted successfully');
          setRender((prev) => !prev);
        })
        .catch((err) => {
          console.log(err);
        });
      return;
    }
  };

  return (
    <Box>
      <ChildHeader text="Notifications" />
      <Box sx={{ margin: 2 }}>
        <PersitedDatePicker label="Search By Month" views={['month', 'year']} />
      </Box>
      <NotificationTable {...{ deleteDocument, tableData }} />
    </Box>
  );
};

export default TableList;
