import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import THead from './THead';

interface IProps {
  moduleData: any;
  isView: boolean;
  setIsOpenPopup: any;
  setPartIdx: any;
  assignData: any;
  tabValue: number;
  assignRegister: (document: any, index: number) => void;
  issueRegister: (document: any, index: number) => void;
  undoData: { [key: string]: any };
  undoAssign: (index: number) => void;
  undoIssue: (index: number) => void;
  storeIssueId: string;
}

const TController = ({
  moduleData,
  isView,
  setIsOpenPopup,
  setPartIdx,
  assignData,
  tabValue,
  assignRegister,
  issueRegister,
  undoData,
  undoAssign,
  undoIssue,
  storeIssueId,
}: IProps) => {
  return (
    <Box>
      <RenderTable>
        <THead
          {...{
            moduleData,
            assignData,
            isView,
          }}
        />
        <TBody
          {...{
            moduleData,
            tabValue,
            isView,
            undoData,
            undoAssign,
            undoIssue,
            assignData,
            assignRegister,
            issueRegister,
            setIsOpenPopup,
            setPartIdx,
            storeIssueId,
          }}
        />
      </RenderTable>
    </Box>
  );
};

export default TController;
