import TableCompHead from '../../../../../../components/ui/TableCompHead';

const THead = () => {
  return (
    <thead>
      <TableCompHead
        colSpan={8}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="STORE OVERVIEW"
      />
    </thead>
  );
};

export default THead;
