import Partlist from '../../pages/addparts/Partlist';
import ViewAdminPpapDoc from '../../pages/viewAdminPpapDocument/ViewAdminPpapDoc';
import UnderConstructionPage from '../../underconstruction-page';
import List from '../../pages/customer/List';
import SpcList from '../../pages/spc/List';
import SpcTiles from '../../pages/spc/Tiles';
import CreateEditSpc from '../../pages/spc/CreateEdit';
// cp
import { View, Create } from '../../pages/cp/Index';
import { ProcesscontroldiagramCreate } from '../../pages/processcontroldiagram';

// ppap
import {
  ModuleTableList,
  TilesPpap,
} from '../../pages/viewAdminPpapDocument/Index';
import QuickView from '../../pages/addparts/quickView/QuickView';
//pir
import { Pir, PirTableList } from '../../pages/pir';

//pdir
import PdirList from '../../pages/pdir';
import Pdir from '../../pages/pdir/Create';
//setup
import { Setup, SetupList } from '../../pages/setup';
//incomingReport
import { IncomingReport, IncomingReportList } from '../../pages/incomingReport';
//material incoming
import {
  MaterialIncomingList,
  MaterialIncomingReport,
} from '../../pages/materialIncoming';
import { Clause4 } from '../../pages/clause4Page';
import ChemicalCompositionEdit from '../../pages/materialIncoming/ChemicalCompositionEdit';

import {
  PdirFinalCreate,
  PdirFinalList,
} from '../../pages/pdiDepartment/pdirIAI/pdirFinalList';
import {
  PdirGeneralCreate,
  PdirGeneralList,
} from '../../pages/pdiDepartment/pdirIAI/pdirGneneral';
import PdirTiles from '../../pages/pdiDepartment/pdirIAI/PdirTiles';
import { BarcodeCreate, BarcodeList } from '../../pages/Barcode';

// clause 4 all routes should come here

const controlPanRoute = [
  {
    path: 'controlplan/:partId/:controlPlanId?',
    element: <Create />,
  },
  {
    path: 'viewControlplan/:partId/:controlPlanId?',
    element: <View />,
  },
];

const pfdRoute = [
  {
    path: 'pfd',
    element: <UnderConstructionPage />,
  },
];

const fmeaRoute = [
  {
    path: 'fmea',
    element: <UnderConstructionPage />,
  },
];

const ppapDocRoute = [
  {
    path: 'ppap_documents',
    element: <ViewAdminPpapDoc />,
  },
  {
    path: 'ppapTiles/:partId',
    element: <TilesPpap />,
  },
  {
    path: 'revisionTable/:revisedType/:partId',
    element: <ModuleTableList />,
  },
  {
    path: 'spc/:partId',
    element: <SpcTiles />,
  },

  // ******** spc *******
  {
    path: 'newSpc/:partId',
    element: <SpcList />,
  },
  {
    path: 'createEditSpc/:partId/:id?',
    element: <CreateEditSpc />,
  },
  {
    path: 'spcFromInspection/:partId',
    element: <UnderConstructionPage />,
  },
  //**************
  {
    path: 'pcd/:partId',
    element: <ProcesscontroldiagramCreate />,
  },
];

const pirRoute = [
  {
    path: 'pirList',
    element: <PirTableList />,
  },
  {
    path: 'pir/:partId/:pirId?',
    element: <Pir />,
  },
];
const materialIncomingRoute = [
  {
    path: 'materialIncomingList',
    element: <MaterialIncomingList />,
  },
  {
    path: 'materialIncoming/:partId/:incomingId?',
    element: <MaterialIncomingReport />,
  },
  {
    path: 'chemicalCompositionIncomingMaterial/:materialId/:ccId?',
    element: <ChemicalCompositionEdit />,
  },
];
const incomingRoute = [
  {
    path: 'incomingReportList',
    element: <IncomingReportList />,
  },
  {
    path: 'incomingReport/:partId/:incomingId?',
    element: <IncomingReport />,
  },
];
const pdirRoute = [
  {
    path: 'pdirList',
    element: <PdirList />,
  },
  {
    path: 'pdir/:partId/:pdirId?',
    element: <Pdir />,
  },
];
const setupRoute = [
  {
    path: 'setupList',
    element: <SetupList />,
  },
  {
    path: 'setup/:partId/:setupId?',
    element: <Setup />,
  },
];

// main route of clause 4
export const clause4 = [
  {
    path: 'clause4',
    element: <Clause4 />,
  },
  {
    path: 'part_list',
    element: <Partlist />,
  },
  {
    path: 'part_list/quick_view_documents/:partId',
    element: <QuickView />,
  },

  ...controlPanRoute,
  ...pfdRoute,
  ...fmeaRoute,
  ...ppapDocRoute,
  ...pirRoute,
  ...pdirRoute,
  ...setupRoute,
  ...incomingRoute,
  ...materialIncomingRoute,

  {
    path: 'customer_list',
    element: <List />,
  },
  {
    path: 'pdirTiles',
    element: <PdirTiles />,
  },
  {
    path: 'pdirfinallist',
    element: <PdirFinalList />,
  },
  {
    path: 'Pdirfinalcreate/:partId/:pdirId?',
    element: <PdirFinalCreate />,
  },

  {
    path: 'pdirGeneralList',
    element: <PdirGeneralList />,
  },
  {
    path: 'PdirGeneralcreate/:partId/:pdirId?',
    element: <PdirGeneralCreate />,
  },

  {
    path: 'barcodeList',
    element: <BarcodeList />,
  },
  {
    path: 'barcodeCreate/:partId/:pdirId?',
    element: <BarcodeCreate />,
  },
];
