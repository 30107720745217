import { Grid, MenuItem } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import AutocompleteMuiCustom from '../../../../components/common/AutocompleteMuiCustom';
import {
  AddIconButton,
  RemoveIconButton,
} from '../../../../components/common/button/CustomIconButton';
import DatePickerMui from '../../../../components/common/DatePickerMui';
import formatDate from '../../../../components/common/formatDate';
import CustomSelect from '../../../../components/common/SelectField';
import { TableHeading } from '../../../../components/ui';
import { useQuery } from '../../../../hooks/UseQuery';
import ReasonOfRejection from './ReasonOfRejection';

interface IProps {
  moduleData: any;
  isView: boolean;
  customerData: any;
  toolsData: any;
  setIsOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setPartIdx: React.Dispatch<React.SetStateAction<number>>;
  setHeadingType: React.Dispatch<React.SetStateAction<string>>;
  documentType?: any;
  processes: any;
}
const TBody2 = ({
  setIsOpenPopup,
  setPartIdx,
  setHeadingType,
  customerData,
  moduleData,
  isView,
  documentType,
  toolsData,
  processes,
}: IProps) => {
  const [render, setRender] = useState(false);
  const test = useQuery('test') === 'true' ? true : false;
  return (
    <>
      <TableHeading colSpan={test ? 24 : 20} heading="Production Status" />
      <tr style={{ textTransform: 'uppercase' }}>
        <th>S.NO</th>
        <th colSpan={documentType === 'groundFloor' ? 1 : 3}>PART NO.</th>
        {documentType === 'groundFloor' &&
          Array.from({ length: 5 }, (_, index) => (
            <th colSpan={1}>{`Tool ${index + 1}`}</th>
          ))}
        {documentType === 'firstFloor' && <th>DATE</th>}
        <th colSpan={documentType === 'groundFloor' ? 2 : 1}>JOB CARD NO.</th>
        <th colSpan={documentType === 'groundFloor' ? 2 : 1}>LOT QTY.</th>
        {/* TEST FMEA */}
        {test && <th>PROCESS NAME</th>}
        {test && <th>PRODUCED QTY.</th>}
        {test && <th>REJECTED QTY.</th>}
        {test && <th>REASON OF REJECTION</th>}
        {/* TEST FMEA */}
        <th colSpan={documentType === 'groundFloor' ? 1 : 4}>CUSTOMER</th>
        <th colSpan={documentType === 'groundFloor' ? 2 : 1}>BATCH</th>
        {documentType === 'firstFloor' && <th>Leakage Testing</th>}
        <th colSpan={documentType === 'groundFloor' ? 2 : 1}>
          QUALITY APPROVAL
        </th>
        {documentType !== 'groundFloor' && <th colSpan={1}>FITMENT</th>}
        {documentType !== 'groundFloor' && <th colSpan={1}>GAUGING</th>}
        <th colSpan={documentType === 'groundFloor' ? 4 : 1}>VISUAL</th>
        {documentType !== 'groundFloor' && <th colSpan={1}>CCDC</th>}
        {documentType !== 'groundFloor' && <th colSpan={2}>PACKING</th>}
      </tr>
      {moduleData.production_status.map((item: any, index: number) => (
        <tr>
          <td>{index + 1}</td>
          <td
            colSpan={documentType === 'groundFloor' ? 1 : 3}
            style={{ width: '350px', minWidth: '350px' }}
          >
            {typeof moduleData.operation_list[index]?.part_id === 'object' &&
            moduleData.operation_list[index]?.part_id !== null
              ? moduleData.operation_list[index].part_id?.part_number
              : (moduleData.operation_list[index]?.partNumber &&
                  moduleData.operation_list[index]?.partNumber) ||
                ''}
          </td>
          {documentType === 'firstFloor' && (
            <td align="left">
              {isView ? (
                formatDate(item.date)
              ) : (
                <DatePickerMui
                  value={moment(item.date || null)}
                  sx={{ width: '140px' }}
                  onChange={(date) => {
                    item.date = date;
                    setRender((prev) => !prev);
                  }}
                />
              )}
            </td>
          )}

          {documentType === 'groundFloor' &&
            ['tool_1', 'tool_2', 'tool_3', 'tool_4', 'tool_5'].map(
              (head, index) => (
                <td
                  style={{
                    textTransform: 'uppercase',
                    width: '250px',
                    minWidth: '250px',
                  }}
                >
                  {isView ? (
                    item?.tools?.[index]?.tool_id?.tool_details
                  ) : (
                    <AutocompleteMuiCustom
                      id="tool_details"
                      option_name="tool_details"
                      arrayofObj={toolsData}
                      value={
                        item?.tools[index]?.tool_autocomplete ||
                        item.tools[index]?.tool_id ||
                        ''
                      }
                      onChange={(e, value) => {
                        // console.log(item);
                        item.tools[index].tool_id = value?._id;
                        item.tools[index].tool_autocomplete = value;
                        setRender((prev) => !prev);
                      }}
                    />
                  )}
                </td>
              ),
            )}
          <td colSpan={documentType === 'groundFloor' ? 2 : 1}>
            <TableInput
              isView={isView}
              name="job_card_no"
              type="text"
              value={item.job_card_no}
              onChange={(e) => {
                item.job_card_no = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          <td colSpan={documentType === 'groundFloor' ? 2 : 1}>
            <TableInput
              isView={isView}
              name="lot_qty"
              type="number"
              value={item.lot_qty}
              onChange={(e) => {
                item.lot_qty = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          {test && (
            <td>
              {isView ? (
                item.process_id
              ) : (
                <CustomSelect
                  name="process_id"
                  value={item.process_id || ''}
                  onChange={(e) => {
                    item.process_id = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">NONE</MenuItem>

                  {processes.map((process: any) => (
                    <MenuItem value={process._id}>
                      {process.process_name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            </td>
          )}
          {test && (
            <td>
              <TableInput
                isView={isView}
                name="produced_qty"
                type="number"
                value={item.produced_qty}
                onChange={(e) => {
                  item.produced_qty = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          )}
          {test && (
            <td>
              <TableInput
                isView={isView}
                name="rejected_qty"
                type="number"
                value={item.rejected_qty}
                onChange={(e) => {
                  item.rejected_qty = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          )}
          {test && (
            <td>
              <ReasonOfRejection
                {...{ isView }}
                process={item}
                partId={
                  typeof item.process_id === 'string'
                    ? item?.process_id
                    : item?.process_id?._id
                }
              />
            </td>
          )}
          <td colSpan={documentType === 'groundFloor' ? 1 : 4}>
            {isView ? (
              item.customer_id?.customer_name
            ) : (
              <AutocompleteMuiCustom
                id="customer_name"
                option_name="customer_name"
                arrayofObj={customerData}
                value={item.customer_autocomplete || item.customer_id || ''}
                onChange={(e, value) => {
                  item.customer_id = value?._id;
                  item.customer_autocomplete = value;
                  setRender((prev) => !prev);
                }}
              />
            )}
          </td>
          <td colSpan={documentType === 'groundFloor' ? 2 : 1}>
            <TableInput
              isView={isView}
              name="batch"
              type="text"
              value={item.batch}
              onChange={(e) => {
                item.batch = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          {documentType === 'firstFloor' && (
            <td>
              <TableInput
                isView={isView}
                name="leakage_test"
                type="text"
                value={item.leakage_test}
                onChange={(e) => {
                  item.leakage_test = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          )}
          <td colSpan={documentType === 'groundFloor' ? 2 : 1}>
            <TableInput
              isView={isView}
              name="quality_id"
              type="text"
              value={item.quality_id}
              onChange={(e) => {
                item.quality_id = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          {documentType !== 'groundFloor' && (
            <>
              <td>
                <TableInput
                  isView={isView}
                  name="assy_1"
                  type="number"
                  value={item.assy_1}
                  onChange={(e) => {
                    item.assy_1 = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="assy_2"
                  type="text"
                  value={item.assy_2}
                  onChange={(e) => {
                    item.assy_2 = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            </>
          )}
          <td colSpan={documentType === 'groundFloor' ? 4 : 1}>
            <TableInput
              isView={isView}
              name="assy_3"
              type="text"
              value={item.assy_3}
              onChange={(e) => {
                item.assy_3 = e.target.value;
                setRender((prev) => !prev);
              }}
            />
          </td>
          {documentType !== 'groundFloor' && (
            <>
              <td>
                <TableInput
                  isView={isView}
                  name="ccdc"
                  type="text"
                  value={item.ccdc}
                  onChange={(e) => {
                    item.ccdc = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="packing_1"
                  type="number"
                  value={item.packing_1}
                  onChange={(e) => {
                    item.packing_1 = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td>
                <TableInput
                  isView={isView}
                  name="packing_2"
                  type="text"
                  value={item.packing_2}
                  onChange={(e) => {
                    item.packing_2 = e.target.value;
                    setRender((prev) => !prev);
                  }}
                />
              </td>
            </>
          )}
        </tr>
      ))}
    </>
  );
};

export default TBody2;
