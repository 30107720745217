import { useState } from 'react';
import { TableInput } from '../../../../components/common';
import { createConstant } from '../../constant';
import formatDate from '../../../../components/common/formatDate';
import InputMuiCustom from '../../../../components/common/inputs/InputMuiCustom';
import { SelectChangeEvent } from '@mui/material';
interface IProps {
  moduleData: any;
  allmonth: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView, allmonth }: IProps) => {
  const [render, setRender] = useState(false);

  const getAverage = (item: any, d: any, index: any) => {
    let start = 0;
    let end = 0;
    if (d === 'all') {
      start = 0;
      end = 12;
    } else if (d === 'Q1') {
      start = 0;
      end = 3;
    } else if (d === 'Q2') {
      start = 3;
      end = 6;
    } else if (d === 'Q3') {
      start = 6;
      end = 9;
    } else if (d === 'Q4') {
      start = 9;
      end = 12;
    } else if (d === 'H1') {
      start = 0;
      end = 6;
    } else if (d === 'H2') {
      start = 6;
      end = 12;
    }
    let result = 0;
    for (let i = start; i < end; i++) {
      result += +item[allmonth[i]];
    }
    if (index === 0 || index === 3 || index === 15) return +result.toFixed(2);
    const avg = result / 12;
    return +avg.toFixed(2);
  };
  const getTarget = (item: any, d: any, index: any) => {
    let i = 0;
    if (d === 'all') {
      i = 0;
    } else if (d === 'Q1') {
      i = 1;
    } else if (d === 'Q2') {
      i = 2;
    } else if (d === 'Q3') {
      i = 3;
    } else if (d === 'Q4') {
      i = 4;
    } else if (d === 'H1') {
      i = 5;
    } else if (d === 'H2') {
      i = 6;
    }
    if (index === 0 || index === 3 || index === 15)
      return +((item.target * moduleData.targets[i]) / 12).toFixed(2);
    return item.target;
  };
  const getAchievement = (item: any, d: any, index: any) => {
    const result =
      (1 -
        (getAverage(item, d, index) -
          getTarget(item, d, index) / getTarget(item, d, index))) *
      100;
    if (result + '' === 'NaN') return 0;
    return +result.toFixed(2);
  };
  return (
    <tbody>
      {moduleData?.list?.map((item: any, index: number) => (
        <>
          <tr key={`${index}_table_row`}>
            <td>{index + 1}</td>
            <th>{item?.item}</th>
            <th>{item?.uom}</th>
            <td>
              <TableInput
                isView={false}
                style={{
                  height: '80px',
                  textAlign: 'center',
                  paddingLeft: '10px',
                }}
                name=""
                type="number"
                step={index === 0 || index === 3 || index === 15 ? 1 : 0.01}
                value={item?.target}
                onChange={(e: any) => {
                  item.target = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
            {allmonth.map((monthName: string) => {
              return (
                <td>
                  <TableInput
                    isView={false}
                    style={{
                      height: '80px',
                      textAlign: 'center',
                      paddingLeft: '10px',
                    }}
                    name=""
                    type="number"
                    step={index === 0 || index === 3 || index === 15 ? 1 : 0.01}
                    value={item[monthName] || 0}
                    onChange={(e: any) => {
                      item[monthName] = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              );
            })}
            {['all', 'Q1', 'Q2', 'Q3', 'Q4', 'H1', 'H2'].map((d: any) => {
              return (
                <>
                  <td>{getTarget(item, d, index)}</td>
                  <td>{getAverage(item, d, index)}</td>
                  <td>{getAchievement(item, d, index) + '%'}</td>
                </>
              );
            })}
          </tr>
        </>
      ))}
    </tbody>
  );
};

export default TBody;
