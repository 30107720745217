import { Box } from '@mui/material';
import { RenderTable } from '../../../../../components/ui';
import TBody from './TBody';
import TFoot from './TFoot';
import THead from './THead';
interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
}

const TController = ({ moduleData, tabValue, isView }: IProps) => {
  return (
    <Box>
      <RenderTable>
        {moduleData.pirs[tabValue] && moduleData.pirs[tabValue].pir_list && (
          <>
            <THead {...{ moduleData, tabValue, isView }} />
            <TBody {...{ moduleData, tabValue, isView }} />
            <TFoot
              preparedBy={moduleData.report_prepared_by}
              approvedBy={moduleData.report_approved_by}
            />
          </>
        )}
      </RenderTable>
    </Box>
  );
};

export default TController;
