import { useState } from 'react';
import { TableInput, TextAreaInput } from '../../../../../components/common';
import { DeleteIconButton } from '../../../../../components/common/button/CustomIconButton';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  ppm:any;
}
const TBody = ({ ppm,moduleData, handelDeleteRows, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const handleChange = (process: any, e: any) => {
    const { name, value } = e.target;
    process[name] = value;
    setRender((prev) => !prev);
  };
  // console.log(moduleData);
  return (
    <tbody>
      {moduleData.processes.map((process: any, index: number) => (
        <tr>
          <td></td>
          <td>
            <TextAreaInput
              isView={isView}
              name="process_history"
              value={process.process_history}
              onChange={(e: any) => {
                handleChange(process, e);
              }}
            />
          </td>
          <td>{moduleData.process_name}</td>
          <td>{moduleData.process_no}</td>
          <td>
            <TextAreaInput
              isView={isView}
              name="process_work_element"
              value={process.process_work_element}
              onChange={(e: any) => {
                handleChange(process, e);
              }}
            />
          </td>
          <td>
            <TextAreaInput
              isView={isView}
              name="process_item"
              value={process.process_item}
              onChange={(e: any) => {
                handleChange(process, e);
              }}
            />
          </td>
          <td>
            <TextAreaInput
              isView={isView}
              name="process_product_char"
              value={process.process_product_char}
              onChange={(e: any) => {
                handleChange(process, e);
              }}
            />
          </td>
          <td>
            <TextAreaInput
              isView={isView}
              name="process_char"
              value={process.process_char}
              onChange={(e: any) => {
                handleChange(process, e);
              }}
            />
          </td>
          <td>
            <TextAreaInput
              isView={isView}
              name="failure_effect"
              value={process.failure_effect}
              onChange={(e: any) => {
                handleChange(process, e);
              }}
            />
          </td>
          <td>
            <TableInput
              isView={isView}
              name="severity"
              type="number"
              value={process.severity}
              onChange={(e: any) => {
                handleChange(process, e);
              }}
            />
          </td>
          <td>
            <TextAreaInput
              isView={isView}
              name="failure_process_step"
              value={process.failure_process_step}
              onChange={(e: any) => {
                handleChange(process, e);
              }}
            />
          </td>
          <td>
            <TextAreaInput
              isView={isView}
              name="work_element"
              value={process.work_element}
              onChange={(e: any) => {
                handleChange(process, e);
              }}
            />
          </td>
          <td>
            <TextAreaInput
              isView={isView}
              name="current_prevent_control_fc"
              value={process.current_prevent_control_fc}
              onChange={(e: any) => {
                handleChange(process, e);
              }}
            />
          </td>
          <td>{ppm[`${process.failure_effect}/${process.failure_process_step}`]}</td>
          <td>
            <TextAreaInput
              isView={isView}
              name="current_detection_control_fc"
              value={process.current_detection_control_fc}
              onChange={(e: any) => {
                handleChange(process, e);
              }}
            />
          </td>
          <td>
            <TextAreaInput
              isView={isView}
              name="detection_fc_fm"
              value={process.detection_fc_fm}
              onChange={(e: any) => {
                handleChange(process, e);
              }}
            />
          </td>
          <td>
            <TextAreaInput
              isView={isView}
              name="fmea_ap"
              value={process.fmea_ap}
              onChange={(e: any) => {
                handleChange(process, e);
              }}
            />
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td style={{ minWidth: '100px', textAlign: 'center' }}>
            {!isView && (
              <DeleteIconButton
                tooltipTitle={'delete'}
                onClick={() => handelDeleteRows(index)}
              />
            )}
          </td>
        </tr>
      ))}
    </tbody>
  );
};

export default TBody;
