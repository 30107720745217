import axios from 'axios';
import { useState, useEffect } from 'react';
import { apiConstant } from '../../constant';
import lodash from 'lodash';
import { ImtWork, IMT } from './checklist';

const useGetData = (
  id: string | undefined,
  checkSheetType: string | undefined
) => {
  const [moduleData, setModuleData] = useState<any>({});
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const checkSheetdata: any = {
    c1: lodash.cloneDeep(ImtWork),
    c2: lodash.cloneDeep(IMT),
  };

  useEffect(() => {
    setIsLoading(true);
    if (id) {
      axios
        .get(`/api/${apiConstant.checklistData}/${id}`)
        .then((res) => {
          setModuleData(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });
    } else {
      setModuleData({
        checkSheetType: checkSheetType,
        c: lodash.cloneDeep(checkSheetdata[checkSheetType as string]),
      });
    }
  }, [checkSheetType]);

  return { moduleData, isLoading, error };
};

export default useGetData;
