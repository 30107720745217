import { useState } from 'react';
import { Grid, MenuItem } from '@mui/material';
import { handleChangeModuleData } from '../../helpers/handleChangeModuleData';
import { TableInput } from '../../../../../components/common';
import {
  AddIconButton,
  DeleteIconButton,
  RemoveIconButton,
} from '../../../../../components/common/button/CustomIconButton';
import { convToTime } from '../../../../../utils/helpers';
import CustomMobileTimePickerMui from '../../../../../components/common/CustomMobileTimePickerMui';
import totalRej from '../../helpers/totalRej';
import CustomSelect from '../../../../../components/common/SelectField';

interface IProps {
  moduleData: any;
  isView: boolean;
  handelDeleteRows: any;
  setIsOpenPopup: any;
  setPartIdx: any;
}

const TBody = ({
  moduleData,
  isView,
  handelDeleteRows,
  setIsOpenPopup,
  setPartIdx,
}: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <>
      <tbody>
        {moduleData &&
          moduleData.processes.map((process: any, index: number) => (
            <tr key={index + 10.1 + 'heading '}>
              <th style={{ minWidth: '50px', textAlign: 'center' }}>
                {index + 1}
              </th>
              <td
                style={{
                  width: '280px',
                  minWidth: '280px',
                  textAlign: 'center',
                }}
              >
                {isView ? (
                  process?.part_id && process?.part_id?.part_name
                ) : (
                  <Grid container xs={12} direction="row" alignItems={'center'}>
                    <Grid item xs={10}>
                      <p
                        style={{
                          fontSize: '1.2rem',
                        }}
                      >
                        {typeof process?.part_id === 'object' &&
                        process?.part_id !== null
                          ? process?.part_id?.part_name
                          : process?.partName || ''}
                        {!process?.part_id && 'Pick Part Name'}
                      </p>
                    </Grid>

                    {process.part_id ? (
                      <Grid item xs={2}>
                        <RemoveIconButton
                          tooltipTitle="remove part"
                          onClick={() => {
                            process.part_id = null;
                            process.partNumber = null;
                            process.partName = null;
                            process.operations = [];
                            setRender((prev) => !prev);
                          }}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <AddIconButton
                          tooltipTitle="add part"
                          onClick={() => {
                            setIsOpenPopup(true);
                            setPartIdx(index);
                          }}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              </td>
              <td style={{ minWidth: '220px', textAlign: 'center' }}>
                {process?.part_id?.part_number || process.partNumber}
              </td>
              <td style={{ minWidth: '110px' }}>
                {isView ? (
                  convToTime(process.time_from)
                ) : (
                  <CustomMobileTimePickerMui
                    value={process.time_from}
                    onChange={(date: any) => {
                      process.time_from = date;
                      setRender((prev) => !prev);
                    }}
                    ampm={true}
                    openTo="hours"
                    views={['hours', 'minutes']}
                  />
                )}
              </td>
              <td style={{ minWidth: '110px' }}>
                {isView ? (
                  convToTime(process.time_to)
                ) : (
                  <CustomMobileTimePickerMui
                    value={process.time_to}
                    onChange={(date: any) => {
                      process.time_to = date;
                      setRender((prev) => !prev);
                    }}
                    ampm={true}
                    openTo="hours"
                    views={['hours', 'minutes']}
                  />
                )}
              </td>
              <td style={{ minWidth: '200px', textAlign: 'center' }}>
                {/* <TableInput
                  isView={isView}
                  name="pipe_spec"
                  type="text"
                  value={process.pipe_spec}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                /> */}
                {isView ? (
                  process.operation
                ) : (
                  <CustomSelect
                    name="operation"
                    value={process.operation}
                    onChange={(e) => {
                      handleChangeModuleData(e, process);
                      setRender((prev) => !prev);
                    }}
                  >
                    {process.operations?.map((operation: any, ind: number) => (
                      <MenuItem value={operation.process_name}>
                        {operation.process_name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                )}
              </td>
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="total_qty"
                  type="number"
                  value={process.total_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="ok_qty"
                  type="number"
                  value={process.ok_qty}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '100px', textAlign: 'center' }}>
                {totalRej(process.total_qty, process.ok_qty) || null}
              </td>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((defect, indx) => (
                <td
                  style={{ minWidth: '100px', textAlign: 'center' }}
                  key={defect + (index + 100) + indx + 'def' + 333.33}
                >
                  <TableInput
                    isView={isView}
                    key={defect + (index + 100) + indx + 'def' + 433.33}
                    name="rej_qty_defect_wise[indx]"
                    type="number"
                    value={process.rej_qty_defect_wise[indx]}
                    onChange={(e) => {
                      process.rej_qty_defect_wise[indx] = e.target.value;
                      setRender((prev) => !prev);
                    }}
                  />
                </td>
              ))}
              <td style={{ minWidth: '200px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="any_other_obs"
                  type="text"
                  value={process.any_other_obs}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td style={{ minWidth: '200px', textAlign: 'center' }}>
                <TableInput
                  isView={isView}
                  name="action_taken"
                  type="text"
                  value={process.action_taken}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              <td
                style={{ minWidth: '100px', textAlign: 'center' }}
                colSpan={isView ? 2 : 1}
              >
                <TableInput
                  isView={isView}
                  name="remarks"
                  type="text"
                  value={process.remarks}
                  onChange={(e) => {
                    handleChangeModuleData(e, process);
                    setRender((prev) => !prev);
                  }}
                />
              </td>
              {!isView && (
                <td style={{ minWidth: '100px', textAlign: 'center' }}>
                  <DeleteIconButton
                    tooltipTitle={'delete'}
                    onClick={() => handelDeleteRows(index)}
                  />
                </td>
              )}
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default TBody;
