import { useState } from 'react';
import ObservationInput from './ObservationInput';
import CustomSelect from '../../../../../components/common/SelectField';
import { MenuItem } from '@mui/material';
import { TableInput } from '../../../../../components/common';

interface IProps {
  moduleData: any;
  tabValue: number;
  isView: boolean;
}
const TBody = ({ moduleData, tabValue, isView }: IProps) => {
  const [render, setRender] = useState(false);
  return (
    <tbody>
      {
        moduleData.pirs[tabValue].pir_list.map((spec: any, index: number) => (
          <tr key={index + 'specs'}>
            <td>{index + 1}</td>
            <td>{spec.parameter}</td>
            <td>{spec.specification}</td>
            <td>{spec.methods}</td>
            {Array.from({ length: 15 }, (_, obsIndex) => (
              <td
                key={obsIndex + 'obs'}
                style={{
                  textTransform: 'uppercase',
                }}
              >
                <ObservationInput
                  {...{ spec, setRender, isView, obsIndex }}
                  obsNumber={0}
                  checkType={spec.appearance}
                />
              </td>
            ))}
            <td style={{ textTransform: 'uppercase' }}>
              {spec.appearance === 'check3' ? (
                isView ? (
                  spec.result
                ) : (
                  <CustomSelect
                    name="dropdown"
                    value={spec.result || ''}
                    onChange={(e) => {
                      spec.result = e.target.value;
                      setRender((prev) => !prev);
                    }}
                    sx={{
                      textAlign: 'center',
                    }}
                  >
                    <MenuItem value="">NONE</MenuItem>
                    <MenuItem value="ok">OK</MenuItem>
                    <MenuItem value="ng">NG</MenuItem>
                  </CustomSelect>
                )
              ) : (
                spec.result
              )}
            </td>
            <td style={{ textTransform: 'uppercase', width: '13%' }}>
              <TableInput
                isView={isView}
                name="remarks"
                type="text"
                value={spec.remarks}
                onChange={(e) => {
                  spec.remarks = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
    </tbody>
  );
};

export default TBody;
