export const checklistData = [
  {
    check_point: 'No. of  VCI inside the box (As per Packing Standard)',
    mode_of_inspection: 'Visual',
    obs: '',
    status: '',
  },
  {
    check_point: 'VCI packing should be proper and should not be cut or torn.',
    mode_of_inspection: 'Visual',
    obs: '',
    status: '',
  },
  {
    check_point: 'No Loose sealing inside the box.',
    mode_of_inspection: 'Visual',
    obs: '',
    status: '',
  },
  {
    check_point: 'No loose and Broken cap should be not allowed',
    mode_of_inspection: 'Visual',
    obs: '',
    status: '',
  },
  {
    check_point:
      'Box Condition should be proper and should not be cut or torn.',
    mode_of_inspection: 'Visual',
    obs: '',
    status: '',
  },
  {
    check_point:
      'Master Sticker and label sticker should be as per part. also check part qty and weight.',
    mode_of_inspection: 'Visual',
    obs: '',
    status: '',
  },
  {
    check_point:
      'For Customer Compaints: Is certified sticker found on box ? (Mention Details)',
    mode_of_inspection: 'Visual',
    obs: '',
    status: '',
  },
  {
    check_point: 'Fumigation stamp should be on wooden pallet ',
    mode_of_inspection: 'Visual',
    obs: '',
    status: '',
  },
  {
    check_point:
      'Corrugated box and wooden pallet should be tight with plastic strip.',
    mode_of_inspection: 'Visual',
    obs: '',
    status: '',
  },
];
