import { Paper, Tab, Tabs } from '@mui/material';
import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  SaveButton,
  SubmitButton,
} from '../../components/common/button/CustomButtons';
import formatDate from '../../components/common/formatDate';
import ChildHeader from '../../components/ui/ChildHeader';
import { useQuery } from '../../hooks/UseQuery';
import { useAuth } from '../../services/auth/AuthProvider';
import TController from './components/create/TController';
import useGetData from './helpers/useGetData';
interface MyData {
  moduleData: any; // replace PdirType with the type of your `pdir` data
  isLoading: boolean;
  machineData: any;
  error?: string | null;
}
const Create = () => {
  const { partId, pdirId } = useParams();
  const [tabValue, setTabValue] = useState(0);
  const { state } = useAuth();
  const navigate = useNavigate();
  const isView = useQuery('isView') === 'true' ? true : false;
  const partName = useQuery('partName');
  const partNumber = useQuery('partNumber');
  const { moduleData, isLoading, error, machineData }: MyData = useGetData(
    partId,
    pdirId
  );

  const onSubmit = async (isSubmitted: boolean) => {
    moduleData.isSubmitted = isSubmitted;
    moduleData.is_admin_request = 'pending';
    moduleData.report_prepared_by = state.user?.name;
    let response: any;
    if (moduleData._id) {
      response = await axios
        .put(`/api/milliporeRoute/${moduleData._id}`, moduleData)
        .catch((err) => console.log(err));
    } else {
      response = await axios
        .post(`/api/milliporeRoute`, moduleData)
        .catch((err) => console.log(err));
    }
    if (moduleData._id && !isSubmitted) {
      alert('updated successfully ');
      return;
    }
    alert('created successfully');
    navigate(-1);
  };

  return (
    <Box>
      <Box>
        <ChildHeader text="MILLIPORE TEST REPORT">
          {!isView && (
            <>
              {state.user?.userType === 3 && (
                <SaveButton
                  label="Save & Continue"
                  onClick={() => onSubmit(false)}
                />
              )}
              <SubmitButton label="Submit" onClick={() => onSubmit(true)} />
            </>
          )}
        </ChildHeader>
      </Box>
      <TController {...{ moduleData, tabValue, isView, machineData }} />
    </Box>
  );
};

export default Create;
