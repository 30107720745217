export const moduleConstant = {
  api: 'employeeRoute',
  createRoute: '/common/JdCreate',
  label: 'JD',
  tilesPHeading: 'Create',
  tilesSpanHeading: 'JD',
};

export const moduleListHeader = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Title', rowspan: 1, colspan: 1 },
    { text: 'Designation', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

export const renderData = [null, 'title', 'designation_id'];
