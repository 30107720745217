import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AiFillEye } from 'react-icons/ai';
import {
  CustomButton,
  SubmitButton,
} from '../../../components/common/button/CustomButtons';
import ChildHeader from '../../../components/ui/ChildHeader';
import { useQuery } from '../../../hooks/UseQuery';
import TController from './components/create/TController';
import { IModule } from './interface';
import CustomUploadButton from '../../../components/common/button/CustomUploadButton';

const Create = () => {
  const { moduleId } = useParams();
  const [moduleData, setModuleData] = useState<IModule>({
    moduleData_date: new Date(),
    specs: [],
    image: [],
    revisionData:[],
  });
  const [loader, setLoader] = useState<boolean>(false);
  const isView = useQuery('isView') === 'true' ? true : false;
  useEffect(() => {
    if (!moduleId) return;
    axios
      .get(`/api/pdiStandardRoute/${moduleId}`)
      .then((res) => {
        setModuleData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const addRows = () => {
    const values = { ...moduleData };
    values.specs.push({ s_no: moduleData.specs.length + 1 });
    setModuleData(values);
  };
  const onSubmit = async () => {
    axios
      .put(`/api/pdiStandardRoute/${moduleId}`, moduleData)
      .then((res) => {
        if (res.data) {
          alert('updated successfull');
        }
      })
      .catch((err) => console.log(err));
  };

  const handlePartImport = (e: any) => {
    if (!e.target.files.length) return;
    setLoader(true);
    const formData = new FormData();
    formData.append('file', e.target.files[0]);
    axios
      .post(`/api/pdiStandardRoute/uploadPart`, formData)
      .then((res) => {
        const uploadData = res.data.partsArray;

        moduleData.specs = [...moduleData.specs, ...uploadData];
        e.target.value = '';

        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        if (err.response.data.err) {
          window.alert(err.response.data.err);
        } else {
          window.alert(
            'Something thing went wrong. Please check if you are uplaoding the correct excel sheet or the excel sheet might be currupted.',
          );
        }
      });
  };
  return (
    <Box>
      <Box>
        <ChildHeader text="PDI STANDARD">
          <a target={'_blank'} href={moduleData.image && moduleData.image[0]}>
            <CustomButton icon={<AiFillEye />}>VIEW DRAWING</CustomButton>
          </a>
          {!isView && (
            <>
              <CustomUploadButton
                lable={'Upload PDI STANDARD'}
                onChange={handlePartImport}
              />
              <CustomButton
                sx={{
                  backgroundColor: '#1c7ed6',
                }}
                onClick={addRows}
              >
                ADD ROWS
              </CustomButton>

              <SubmitButton label="Submit" onClick={() => onSubmit()} />
            </>
          )}
        </ChildHeader>
        <TController {...{ moduleData, isView }} />
      </Box>
    </Box>
  );
};

export default Create;
