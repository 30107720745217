export const listConstant = {
  childHeader: 'SST PLAN List',
  addButtonLable: 'New SST PLAN',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'SST PLAN',
  theadHeader: 'PLAN FOR SST',
  extracolSpan: 6,
};

export const checkListList = {
  childHeader: 'SST PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'SSTPlan',
  gettableDataurl: 'SSTPlan/monthly',
  getPlanByid: 'SSTPlan',
  createPlanByid: 'SSTPlan',
  updatePlanByid: 'SSTPlan',
  sync: 'SSTPlan/handleSync',
  getAllCheckList: 'SSTPlan/getAllCheckList',
  checkListStatus: 'SSTPlan/handleStatus',
  checklistUpload: 'SSTPlan/handleUpload',
  checklistDeleteImage: 'SSTPlan/handleDeleteImage',
};

export const tilesConstant = {
  childHeaderText: 'SST PLAN',
};

export const navigationConstant = {
  createPlanSchedule: '/common/SSTPlanPlanCreate',
};
