import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { PersitedDatePicker } from '../../../../../../components/common';
import { useQuery } from '../../../../../../hooks/UseQuery';
import axios from 'axios';
import BarChart from './BarChart';
interface IProps {
  totalOfExportsPerMonth: any;
  year: number;
}
const Exports = ({ totalOfExportsPerMonth, year }: IProps) => {
  const options = {
    plugins: {
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'Exports (JD, AMG, Cummins, DAF etc..)',
      },
    },
    responsive: true,
    scales: {
      /*x: {
        stacked: true,
      },*/
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 5,
        },
      },
    },
  };
  const monthNames = [
    `Jan-${year % 100}`,
    `Feb-${year % 100}`,
    `Mar-${year % 100}`,
    `Apr-${year % 100}`,
    `May-${year % 100}`,
    `Jun-${year % 100}`,
    `Jul-${year % 100}`,
    `Aug-${year % 100}`,
    `Sep-${year % 100}`,
    `Oct-${year % 100}`,
    `Nov-${year % 100}`,
    `Dec-${year % 100}`,
  ];
  const backgroundColors = ['#fd7e14'];

  let exportsCountPerMonth = [];
  for (const monthIndex in totalOfExportsPerMonth) {
    if (totalOfExportsPerMonth?.[+monthIndex - 1] > 0)
      exportsCountPerMonth.push(totalOfExportsPerMonth?.[+monthIndex - 1]);
    else exportsCountPerMonth.push(0);
  }

  const datasets = [
    {
      data: exportsCountPerMonth,
      backgroundColor: backgroundColors,
      label: 'My First Dataset(Exports)',
      borderWidth: 1,
      barThickness: 50,
    },
  ];
  return (
    <div>
      <Box style={{ paddingBottom: '3rem', height: '50vh', width: '50vw' }}>
        <BarChart
          datasets={datasets}
          monthNames={monthNames}
          options={options}
        />
      </Box>
    </div>
  );
};

export default Exports;
