import React, { useState } from 'react';
import { Box, TableBody, TableCell, TableRow } from '@mui/material';
import axios from 'axios';
import Add from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import useDebounce from '../../../../hooks/useDebounce';
import { TableCustom, StatePagination } from '../../../../components/common';
import formatDate from '../../../../components/common/formatDate';
import { CustomIconButton } from '../../../../components/common/button/CustomIconButton';
import StateCustomTable from '../../../../components/common/StateCustomTable';

interface IPart {
  _id?: string;
  part_number: string | null;
  part_name: string | null;
  ri_part_id?: any;
  max_bin_qty?: number;
  createdAt?: Date;
}
const tableHeaderObj = [
  [
    { text: '#', rowspan: 1, colspan: 1 },
    { text: 'Item Number', rowspan: 1, colspan: 1 },
    { text: 'Item Name/Description', rowspan: 1, colspan: 1 },
    { text: 'Vendors', rowspan: 1, colspan: 1 },
    { text: 'ERP No. / Drawing No.', rowspan: 1, colspan: 1 },
    { text: 'Fox Pro No. / Ref. Drg. No.', rowspan: 1, colspan: 1 },
    { text: 'Created At', rowspan: 1, colspan: 1 },
    { text: 'Action', rowspan: 1, colspan: 1 },
  ],
];

const filterOptions = [{ value: 'createAt', name: 'RECENT FIRST' }];
const TableCellSupplier = ({ item }: { item: any }) => {
  const vendorIds = [
    'vendor_id',
    'vendor_id_two',
    'vendor_id_three',
    'vendor_id_four',
    'vendor_id_five',
  ];
  const vendorNames = vendorIds
    .map((id) => item?.ri_part_id?.[id] && item?.ri_part_id[id]?.vender_name)
    .filter(Boolean)
    .join(',');
  return <TableCell align="center">{vendorNames}</TableCell>;
};

const PickStorePart = ({ moduleData }: { moduleData: any }) => {
  const [page, setPage] = useState(1);
  const [partData, setPartData] = useState<IPart[]>([]);
  const [perPage, setPerPage] = useState();
  const [totalPages, setTotalPages] = useState(0);
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<string>('createdAt');
  const [render, setRender] = useState(false);

  const fetchPartData = () => {
    axios
      .get(
        `/api/storePart/getStorePartForAutoComplete?page=${page}&search=${search}&perPage=${10}`,
      )
      .then((res) => {
        setPerPage(res.data?.perPage);
        setPartData(res.data?.result);
        setTotalPages(res.data?.pages);
      })
      .catch((err) => console.log(err));
  };

  useDebounce(fetchPartData, 300, [page, filter, search]);
  return (
    <Box width={1200}>
      <StateCustomTable
        filterOptions={filterOptions}
        {...{ search, setSearch, filter, setFilter }}
        label="Search Part No."
      >
        <TableCustom tableHeaderObj={tableHeaderObj}>
          <TableBody>
            {partData &&
              partData.map((item: IPart, index: number) => (
                <React.Fragment key={item?._id}>
                  <TableRow>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">
                      {item.ri_part_id?.part_number}
                    </TableCell>
                    <TableCell align="center">
                      {item.ri_part_id?.part_name}
                    </TableCell>
                    <TableCellSupplier {...{ item }} />
                    <TableCell align="center">
                      {item.ri_part_id?.erp_drawing_no}
                    </TableCell>
                    <TableCell align="center">
                      {item.ri_part_id?.fox_pro_drg_no}
                    </TableCell>
                    <TableCell align="center">
                      {formatDate(item?.createdAt || '')}
                    </TableCell>
                    <TableCell align="center">
                      {moduleData.store_part_id === item._id ? (
                        <CustomIconButton
                          sx={{ p: 0 }}
                          tooltipTitle={'remove'}
                          onClick={() => {
                            moduleData.store_part_id = null;
                            moduleData.partName = null;
                            moduleData.partNumber = null;
                            moduleData.erp_drawing_no = null;
                            moduleData.fox_pro_drg_no = null;
                            setRender((prev) => !prev);
                          }}
                        >
                          <RemoveIcon style={{ color: '#c92a2a' }} />
                        </CustomIconButton>
                      ) : (
                        <CustomIconButton
                          sx={{ p: 0 }}
                          tooltipTitle={'add'}
                          onClick={() => {
                            moduleData.store_part_id = item?._id;
                            moduleData.partName = item?.ri_part_id?.part_name;
                            moduleData.max_bin_qty = item?.max_bin_qty;
                            moduleData.partNumber =
                              item?.ri_part_id?.part_number;
                            moduleData.erp_drawing_no =
                              item?.ri_part_id?.erp_drawing_no;
                            moduleData.fox_pro_drg_no =
                              item?.ri_part_id?.fox_pro_drg_no;
                            setRender((prev) => !prev);
                          }}
                        >
                          <Add style={{ color: '#343a40' }} />
                        </CustomIconButton>
                      )}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
          </TableBody>
        </TableCustom>
        <StatePagination page={page} totalPage={totalPages} setPage={setPage} />
      </StateCustomTable>
    </Box>
  );
};

export default PickStorePart;
