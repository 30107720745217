import { Grid } from '@mui/material';
import TableCompHead from '../../../../../components/ui/TableCompHead';
import { cpImg } from './imgConstant';

interface IProps {
  moduleData: any;
}
const THead = ({ moduleData }: IProps) => {
  return (
    <thead>
      <TableCompHead
        colSpan={16}
        rowSpan={1}
        labels={[]}
        values={[]}
        heading="Manufacturing  control plan"
      />
      <tr>
        <th colSpan={2} align="left">
          Supplier
        </th>
        <th colSpan={2} align="left">
          {moduleData?.part_id?.supplier_id?.supplier_name}
        </th>
        <th colSpan={12} rowSpan={4}>
          <Grid container xs={12} alignItems="center" spacing={2}>
            <Grid item xs={5}>
              <img
                src={cpImg.cp1}
                alt="key to prcess flow symbol"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </Grid>
            <Grid item xs={3}>
              <img
                src={cpImg.cp2}
                alt="character type symbols"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </Grid>
            <Grid item xs={4}>
              <img
                src={cpImg.cp3}
                alt="confirm level symbols"
                style={{ maxWidth: '100%', height: 'auto' }}
              />
            </Grid>
          </Grid>
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Part name
        </th>
        <th colSpan={2} align="left">
          {moduleData?.part_id?.part_name}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Part number
        </th>
        <th colSpan={2} align="left">
          {moduleData?.part_id?.part_name}
        </th>
      </tr>
      <tr>
        <th colSpan={2} align="left">
          Vehicle
        </th>
        <th colSpan={2} align="left">
          {moduleData?.vehicle}
        </th>
      </tr>
    </thead>
  );
};

export default THead;
