import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface IProps {
  apiData: any;
  totalProducedQty: number;
}
const LineChart = ({ apiData, totalProducedQty }: IProps) => {
  console.log('hgffffffffff', apiData);
  const { rejectionMonthlyCounts } = apiData;
  const monthNames = rejectionMonthlyCounts.map((data: any) => data.monthName);
  const ppmData = rejectionMonthlyCounts.map(
    (item: { rejectionCount: number }) => {
      return ((item.rejectionCount * 1000000) / totalProducedQty).toFixed(0);
    }
  );
  console.log('ppm', ppmData);
  const options = {
    plugins: {
      /*legend: {
        position: 'top' as const,
      },*/
      maintainAspectRatio: false,
      title: {
        display: true,
        text: 'Monthly PPM',
      },
    },
    responsive: true,
    scales: {
      x: {
        title: {
          display: true,
          text: 'Month',
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          //stepSize: 5,
        },
        title: {
          display: true,
          text: 'PPM',
        },
      },
    },
  };

  const backgroundColors = ['#4c6ef5', 'red'];
  const datasets = [
    {
      label: '',
      data: ppmData,
      backgroundColor: backgroundColors,
      borderWidth: 1,
      barThickness: 50,
      borderDash: [10, 5],
      borderColor: 'red',
    },
  ];
  const data = {
    labels: monthNames,
    datasets: datasets,
  };
  return <Line options={options} data={data} />;
};

export default LineChart;
