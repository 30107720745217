export const listConstant = {
  childHeader: 'Material Testing Plan (HOSE) List',
  addButtonLable: 'New Material TEST PLAN',
  datePickerLabel: 'Search By Year',
  modalTile: 'CREATE NEW PLAN',
};

export const createConstant = {
  childHeader: 'Material Testing Plan (HOSE)',
  theadHeader: 'Material Testing Plan - HOSE',
  extracolSpan: 6,
};

export const checkListList = {
  childHeader: 'MATERIAL TEST PLAN STATUS',
  addButtonLable: '',
  datePickerLabel: 'Search By Year',
  modalTile: 'UPDATE STATUS',
};

export const apiConstant = {
  deleteurl: 'materialTestPlanHose',
  gettableDataurl: 'materialTestPlanHose/monthly',
  getPlanByid: 'materialTestPlanHose',
  createPlanByid: 'materialTestPlanHose',
  updatePlanByid: 'materialTestPlanHose',
  sync: 'materialTestPlanHose/handleSync',
  getAllCheckList: 'materialTestPlanHose/getAllCheckList',
  checkListStatus: 'materialTestPlanHose/handleStatus',
  checklistUpload: 'materialTestPlanHose/handleUpload',
  checklistDeleteImage: 'materialTestPlanHose/handleDeleteImage',
};

export const tilesConstant = {
  childHeaderText: 'Material Testing Plan (HOSE)',
};

export const navigationConstant = {
  createPlanSchedule: '/common/materialTestPlanHoseCreate',
};
