import React, { useState } from 'react';
import TBodyRow from './TBodyRow';
import { useQuery } from '../../../../../hooks/UseQuery';

interface IProps {
  moduleData: any;
  isView: boolean;
}

const TBody = ({ moduleData, isView }: IProps) => {
  const [render, setRender] = useState(false);
  const checkSheetType = useQuery('checkSheetType');
  return (
    <>
      <tbody>
        {moduleData &&
          checkSheetType &&
          moduleData?.[checkSheetType]?.main_data?.map(
            (process: any, index: number) => (
              <React.Fragment key={index + 1 + 'process'}>
                <TBodyRow
                  {...{
                    moduleData,
                    process,
                    index,
                    isView,
                  }}
                />
              </React.Fragment>
            )
          )}
      </tbody>
    </>
  );
};

export default TBody;
