import { useEffect, useState } from 'react';
import { MenuItem } from '@mui/material';
import axios from 'axios';
import CustomSelect from '../../../../components/common/SelectField';
import { TableInput } from '../../../../components/common';
import { Table } from '../../../../components/ui/renderTable/styled';

const RejectionTable = (props: any) => {
  const { process, isView } = props;
  const [_, setRender] = useState(false);
  const [rejectionReasons, setRejectionReasons] = useState([]);

  useEffect(() => {
    axios
      .get('/api/qaulityDefectsRoute/defectList/defectForAutoComplete')
      .then((res) => {
        if (res?.data) {
          setRejectionReasons(res?.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const calProcessRejQty = () => {
    let rej_qty = 0;
    if (process?.rejection_details?.length > 0) {
      process.rejection_details.forEach((item: any) => {
        rej_qty = +rej_qty + +item.rej_qty;
      });
    }
    process.rej_qty_rejection = rej_qty;
  };
  return (
    <Table style={{ width: '80vh' }}>
      <thead>
        <tr>
          <th>S.NO.</th>
          <th>REASON FOR REJECTION</th>
          <th>STAGE</th>
          <th>Qty</th>
          <th>ACTION PLAN</th>
        </tr>
      </thead>
      <tbody>
        {process?.rejection_details?.map((item: any, index: number) => (
          <tr>
            <th>{index + 1}</th>
            <td style={{ minWidth: '250px', textAlign: 'center' }}>
              {isView ? (
                item.reason
              ) : (
                <CustomSelect
                  name="reason"
                  value={item.reason}
                  onChange={(e) => {
                    item.reason = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="">None</MenuItem>
                  {rejectionReasons.map((reason: any) => (
                    <MenuItem value={reason.defect_name}>
                      {reason.defect_name}
                    </MenuItem>
                  ))}
                </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              {isView ? (
                item.stage
              ) : (
                <CustomSelect
                  name="state"
                  value={item.stage}
                  onChange={(e) => {
                    item.stage = e.target.value;
                    setRender((prev) => !prev);
                  }}
                >
                  <MenuItem value="Assembly">Assembly</MenuItem>
                  <MenuItem value="Orientation">Orientation</MenuItem>
                  <MenuItem value="Clamping">Clamping</MenuItem>
                  <MenuItem value="Leakage Testing">Leakage Testing</MenuItem>
                  <MenuItem value="Final Inspection">Final Inspection</MenuItem>
                </CustomSelect>
              )}
            </td>
            <td style={{ minWidth: '100px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="rej_qty"
                key="rej_qty"
                type="number"
                value={item.rej_qty}
                onChange={(e) => {
                  item.rej_qty = e.target.value;

                  // updateQuantities(rejectionsIndx);
                  calProcessRejQty();
                  setRender((prev) => !prev);
                }}
              />
            </td>
            <td style={{ minWidth: '150px', textAlign: 'center' }}>
              <TableInput
                isView={isView}
                name="action_plan"
                key="action_plan"
                type="text"
                value={item.action_plan}
                onChange={(e) => {
                  item.action_plan = e.target.value;
                  setRender((prev) => !prev);
                }}
              />
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th colSpan={2}>Total Rejction</th>
          <th>{process.rej_qty_rejection}</th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default RejectionTable;
