import { Box } from '@mui/system';
import axios from 'axios';
import { useEffect, useState } from 'react';
import ChildHeader from '../../components/ui/ChildHeader';
import UserManagementList from './components/tableList/UserManagementList';

const UserManagement = () => {
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    axios
      .get('/api/userRoutes/allUser')
      .then((res) => {
        setTableData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);
  return (
    <Box>
      <ChildHeader text="User Management" />
      <UserManagementList {...{ tableData }} />
    </Box>
  );
};

export default UserManagement;
